export const SET_OPERATION_PROMISE_OF = 'flightSelect/setOperationPromiseOf';
export const INCREMENT_LOADING_COUNTER_OF = 'flightSelect/incrementLoadingCounterOf';
export const DECREMENT_LOADING_COUNTER_OF = 'flightSelect/decrementLoadingCounterOf';
export const ADD_ERROR_OF = 'flightSelect/addErrorOf';
export const RESET_ERRORS_OF = 'flightSelect/resetErrorsOf';
export const RESET_ERRORS = 'flightSelect/resetErrors';
export const INITIALIZE = 'flightSelect/initialize';
export const OPEN_FLIGHT = 'flightSelect/openFlight';
export const SELECT_FARE = 'flightSelect/selectFare';
export const REMOVE_FARE = 'flightSelect/removeFare';
export const SET_IS_WDC_FARE_TYPE_SELECTED_FLAG =
  'flightSelect/setIsWdcFareTypeSelectedFlag';
export const SET_IS_WDC_FARE_TYPE_PREFERRED_FLAG =
  'flightSelect/setIsWdcFareTypePreferredFlag';
export const SET_IS_WDC_FARE_TYPE_PREFERRED = 'flightSelect/setIsWdcFareTypePreferred';
export const UNSET_IS_WDC_FARE_TYPE_PREFERRED =
  'flightSelect/unsetIsWdcFareTypePreferred';
export const TOGGLE_FARE_TYPE = 'flightSelect/toggleFareType';
export const SELECT_STANDARD_WDC_MEMBERSHIP = 'flightSelect/selectStandardWdcMembership';
export const SELECT_GROUP_WDC_MEMBERSHIP = 'flightSelect/selectGroupWdcMembership';
export const SET_IS_EMAIL_SUBSCRIPTION_CHECKED_FLAG =
  'flightSelect/setIsEmailSubscriptionCheckedFlag';
export const SET_IS_WDC_TERMS_AGREED_FLAG = 'flightSelect/setIsWdcTermsAgreedFlag';
export const UNSET_IS_WDC_TERMS_AGREED = 'flightSelect/unsetIsWdcTermsAgreed';
export const SET_IS_WDC_HIGHLIGHTED = 'flightSelect/setIsWdcHighlighted';
export const SET_IS_OUTBOUND_BASIC_PRESELECTED =
  'flightSelect/setIsOutboundBasicPreselected';
export const SET_IS_RETURN_BASIC_PRESELECTED = 'flightSelect/setIsReturnBasicPreselected';
export const SET_SELECTED_FLIGHT_DATE = 'flightSelect/setSelectedFlightDate';
export const SET_SELECTED_OUTBOUND_FLIGHT_DATE =
  'flightSelect/setSelectedOutboundFlightDate';
export const SET_SELECTED_RETURN_FLIGHT_DATE = 'flightSelect/setSelectedReturnFlightDate';
export const RESET_OUTBOUND_FLIGHT_DATES = 'flightSelect/resetOutboundFlightDates';
export const RESET_RETURN_FLIGHT_DATES = 'flightSelect/resetReturnFlightDates';
export const RESET_FLIGHT_DATES = 'flightSelect/resetFlightDates';
export const SET_OUTBOUND_FLIGHT_DATES = 'flightSelect/setOutboundFlightDates';
export const SET_RETURN_FLIGHT_DATES = 'flightSelect/setReturnFlightDates';
export const SET_FLIGHT_DATES = 'flightSelect/setFLightDates';
export const SET_FLIGHT_DATES_DIFFERENCE = 'flightSelect/setFlightDatesDifference';
export const UPDATE_ON_FLIGHT_SEARCH = 'flightSelect/updateOnFlightSearch';
export const SET_SYNC_DATA = 'flightSelect/setSyncData';
export const SELECT_GROUP_REQUESTED_FLIGHT = 'flightSelect/selectGroupRequestedFlight';
export const UPDATE_WDC_DATA = 'flightSelect/updateWdcData';
export const INIT_OFFERED_WDC_MEMBERSHIP = 'flightSelect/initOfferedWdcMembership';
export const RESET_FLIGHTS = 'flightSelect/resetFlights';
export const SET_IMPORTANT_INFORMATION = 'flightSelect/setImportantInformation';
export const SET_HAS_PURCHASED_WDC_MEMBERSHIP =
  'flightSelect/setHasPurchasedWdcMembership';
export const SELECT_WDC_MEMBERSHIP = 'flightSelect/selectWdcMembership';
export const RESET_SELECTED_WDC_MEMBERSHIP = 'flightSelect/resetSelectedWdcMembership';
export const UNSET_HAS_PURCHASED_WDC_MEMBERSHIP =
  'flightSelect/unsetHasPurchasedWdcMembership';
export const SET_IS_SUBMITTED = 'flightSelect/setIsSubmitted';
export const UNSET_IS_SUBMITTED = 'flightSelect/unsetIsSubmitted';
export const SET_IS_SUBMIT_IN_PROGRESS = 'flightSelect/setIsSubmitInProgress';
export const UNSET_IS_SUBMIT_IN_PROGRESS = 'flightSelect/unsetIsSubmitInProgress';
export const SET_IS_SUBMIT_ATTEMPTED = 'flightSelect/setIsSubmitAttempted';
export const UNSET_IS_SUBMIT_ATTEMPTED = 'flightSelect/unsetIsSubmitAttempted';
export const SET_SHOW_WDC_MEMBERSHIP_DETAILS_FLAG =
  'flightSelect/setShowWdcMembershipDetailsFlag';
export const RESET_GROUP_REQUESTED_FLIGHT = 'flightSelect/resetGroupRequestedFlight';
export const SET_IS_INITIALIZED = 'flightSelect/setIsInitialized';
export const SET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION =
  'flightSelect/setIsPostSelectCalledAfterFlightAndFareSelection';
export const UNSET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION =
  'flightSelect/unsetIsPostSelectCalledAfterFlightAndFareSelection';
export const SET_IS_ANCILLARIES_GET_CALLED = 'flightSelect/setIsAncillariesGetCalled';
export const UNSET_IS_ANCILLARIES_GET_CALLED = 'flightSelect/unsetIsAncillariesGetCalled';
export const TOGGLE_IS_BUNDLE_UPGRADED = 'flightSelect/setIsBundleUpgraded';
export const SET_IS_OUTBOUND_FARE_UPGRADED = 'flightSelect/setIsOutboundFareUpgraded';
export const UNSET_IS_OUTBOUND_FARE_UPGRADED = 'flightSelect/unsetIsOutboundFareUpgraded';
export const SET_IS_RETURN_FARE_UPGRADED = 'flightSelect/setIsReturnFareUpgraded';
export const UNSET_IS_RETURN_FARE_UPGRADED = 'flightSelect/unsetIsReturnFareUpgraded';
export const UPDATE_FARE_UPGRADE_DETAILS = 'flightSelect/updateFareUpgradeDetails';
export const SET_WDC_ADDED_ON_TARGETED_MODAL = 'flightSelect/setWdcAddedOnTargetedModal';
export const SET_PRICE_VISIBLE_ON_FARE_CHART = 'flightSelect/setPriceVisibleOnFareChart';
export const SHOW_FARE_BENEFITS = 'flightSelect/showFareBenefits';
export const HIDE_FARE_BENEFITS = 'flightSelect/hideFareBenefits';
export const SET_IS_INIT_PASSENGERS_LOADING = 'flightSelect/setIsInitPassengerLoading';
export const SET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP =
  'flightSelect/setContinueWithCurrentWdcMembership';
export const RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP =
  'flightSelect/resetContinueWithCurrentWdcMembership';
