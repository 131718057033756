import isFunction from '~/utils/object/is-function';
import curry from '../curry';

/**
 * @typedef {<T, U>(fn: (acc: U, current: T) => U, initialAcc: U, arr: T[]) => U} ReduceFn
 */

/**
 * @type ReduceFn
 */
export const _reduce = (fn, initialAcc, arr) => {
  if (isFunction(initialAcc)) initialAcc = initialAcc();
  return arr.reduce((acc, current) => fn(acc, current), initialAcc);
};

/**
 * Returns a single item by iterating through the list, successively calling
 * the iterator function and passing it an accumulator value and the current
 * value from the array, and then passing the result to the next call.
 *
 * The iterator function receives two values: *(acc, value)*.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/reduce.js
 *
 * @type ReduceFn
 * @example
 *
 *      reduce(subtract, 0, [1, 2, 3, 4]) // => ((((0 - 1) - 2) - 3) - 4) = -10
 *                -               -10
 *               / \              / \
 *              -   4           -6   4
 *             / \              / \
 *            -   3   ==>     -3   3
 *           / \              / \
 *          -   2           -1   2
 *         / \              / \
 *        0   1            0   1
 */
const reduce = curry(_reduce);

export default reduce;
