import Vue from 'vue';
import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
  ANCILLARY_AIRPORT_PARKING,
  SERVICE_NAME_WIZZ_FLEX,
  SERVICE_NAME_SEATING_TOGETHER_GUARANTEE,
  SERVICE_NAME_AIRPORT_TRANSFERS,
  KOBALOS_ONE_MINUTE_FLASH_PROMO,
} from '~/constants';
import deepMerge from '~/utils/fp/deep-merge';
import propEq from '~/utils/fp/prop-eq';
import append from '~/utils/fp/append';
import adjust from '~/utils/fp/adjust';
import alter from '~/utils/fp/alter';
import reject from '~/utils/fp/reject';
import compose from '~/utils/fp/compose';
import evolve from '~/utils/fp/evolve';
import adjustWhereEq from '~/utils/fp/adjust-where-eq';
import assoc from '~/utils/fp/assoc';
import whereEq from '~/utils/fp/where-eq';
import ifElse from '~/utils/fp/if-else';
import find from '~/utils/fp/find';
import pathOr from '~/utils/fp/path-or';
import always from '~/utils/fp/always';
import isEmpty from '~/utils/object/is-empty';
import hevolve from '~/utils/object/hevolve';
import hassocPath from '~/utils/object/hassoc-path';
import isNumber from '~/utils/object/is-number';
import isNil from '~/utils/object/is-nil';
import isNotNil from '~/utils/object/is-not-nil';
import isNotEmpty from '~/utils/object/is-not-empty';
import { capitalize } from '~/utils/string';
import { assignSeat } from '~/utils/booking/seat-selection';
import {
  seatPricesFrom,
  seatAssignmentsFrom,
  promotionSeatPricesFrom,
} from '~/utils/booking/seat-selection/convert';

import { addMinutes, currentDateAndTime } from '~/utils/date';
import { getLocalItemRaw } from '~/utils/storage';
import equals from '~/utils/fp/equals';
import {
  getDefaultState,
  getDefaultUnsavedAncillaries,
  ANCILLARY_TYPE_RESET_SEATS,
  ANCILLARY_TYPE_SELECT_SEAT,
} from './internal';
import {
  FLASH_PROMO_STATUS_ACTIVATED,
  FLASH_PROMO_STATUS_DISABLED,
  FLASH_PROMO_STATUS_INACTIVE,
  FLASH_PROMO_STATUS_RUNNING,
  FLASH_PROMO_DURATION_IN_MINUTES,
} from './constants';
import * as m from './mutation-types';

/**
 * @type {(isSelected: boolean) => (state: State) => void}
 */
const addOrRemoveWizzFlex = (isSelected) => (state) => {
  const unsavedTransform = {
    services: assoc(SERVICE_NAME_WIZZ_FLEX, { selected: isSelected }),
  };

  hevolve(
    {
      services: {
        bookingLevelServices: {
          [SERVICE_NAME_WIZZ_FLEX]: { selected: always(isSelected) },
        },
      },
      unsavedAncillaries: unsavedTransform,
      allUnsavedAncillaries: unsavedTransform,
    },
    state
  );
};

const addWizzFlex = addOrRemoveWizzFlex(true);

const removeWizzFlex = addOrRemoveWizzFlex(false);

const resetState = (state) => {
  Object.assign(state, getDefaultState());
};

const updateServices = (payload, state) => {
  const {
    bookingLevelServices = {},
    outboundFlightServices = {},
    returnFlightServices = {},
  } = payload;

  if (!equals(state.services.bookingLevelServices, bookingLevelServices)) {
    Object.assign(state.services, {
      bookingLevelServices,
    });
  }

  if (!equals(state.services.outboundFlightServices, outboundFlightServices)) {
    Object.assign(state.services, {
      outboundFlightServices,
    });
  }

  if (!equals(state.services.returnFlightServices, returnFlightServices)) {
    Object.assign(state.services, {
      returnFlightServices,
    });
  }
};

const updateFlights = (outboundFlight = {}, returnFlight = {}, state) => {
  if (!equals(state.outboundFlight, outboundFlight)) {
    Object.assign(state, {
      outboundFlight,
    });
  }

  if (!equals(state.returnFlight, returnFlight)) {
    Object.assign(state, {
      returnFlight,
    });
  }
};

const updatePassengers = (passengersPayload, state) => {
  if (isEmpty(passengersPayload)) {
    return Object.assign(state, {
      passengers: [],
    });
  }

  if (isEmpty(state.passengers)) {
    return Object.assign(state, {
      passengers: passengersPayload,
    });
  }

  state.passengers.forEach((passenger, index) => {
    if (!equals(passenger, passengersPayload[index])) {
      Vue.set(state.passengers, index, passengersPayload[index]);
    }
  });
};

const updateWizzSafeBanners = (banners = [], state) => {
  if (!equals(state.wizzSafeBanners, banners)) {
    Object.assign(state, {
      wizzSafeBanners: banners,
    });
  }
};

export default {
  [m.RESET]: resetState,

  [m.INITIALIZE_ANCILLARIES](state, data) {
    let {
      bookingLevelServices,
      outboundFlightServices,
      returningFlightServices: returnFlightServices,
      passengers = [],
      priceDetails,
      wizzSafeBanners,
      activatedMemberships,
    } = data || {};

    bookingLevelServices ||= {};
    outboundFlightServices ||= {};
    returnFlightServices ||= {};

    const outboundFlight = getInitialFlight(OUTBOUND_FLIGHT_NAME, data);
    const returnFlight = getInitialFlight(RETURN_FLIGHT_NAME, data);

    bookingLevelServices = Object.entries(bookingLevelServices).reduce(
      (acc, [key, value]) => {
        if (isNotNil(value)) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    Object.assign(state, {
      priceDetails: priceDetails || {},
      activatedMemberships,
    });

    updateServices(
      {
        bookingLevelServices,
        outboundFlightServices: outboundFlightServices || {},
        returnFlightServices: returnFlightServices || {},
      },
      state
    );

    updateFlights(outboundFlight, returnFlight, state);
    updatePassengers(passengers, state);
    updateWizzSafeBanners(wizzSafeBanners, state);
  },

  [m.SET_DEBOUNCED_POST_ANCILLARIES_PROMISE](state, value) {
    hassocPath('debouncedPostAncillariesPromise', value, state);
  },

  [m.RESET_DEBOUNCED_POST_ANCILLARIES_PROMISE](state) {
    hassocPath('debouncedPostAncillariesPromise', Promise.resolve(), state);
  },

  [m.SET_UNSAVED_ANCILLARIES_TO_ALL_UNSAVED_ANCILLARIES](state) {
    hassocPath('unsavedAncillaries', state.allUnsavedAncillaries, state);
  },

  [m.TOGGLE_WIZZ_FLEX](state) {
    const isSelected = pathOr(
      false,
      `outboundFlight.${SERVICE_NAME_WIZZ_FLEX}.selected`,
      state.passengers[0]
    );

    if (isSelected) {
      removeWizzFlex(state);
    } else {
      addWizzFlex(state);
    }
  },

  [m.SET_WIZZ_FLEX](state, payload) {
    const isSelected = pathOr(false, `outboundFlight.${SERVICE_NAME_WIZZ_FLEX}`, payload);

    const unsavedTransform = {
      passengers: append(payload),
    };

    hassocPath('outboundFlight.wizzFlex.selected', isSelected, state.passengers[0]);

    hevolve(
      {
        unsavedAncillaries: unsavedTransform,
        allUnsavedAncillaries: unsavedTransform,
      },
      state
    );
  },

  [m.ADD_WIZZ_FLEX]: addWizzFlex,

  [m.REMOVE_WIZZ_FLEX]: removeWizzFlex,

  [m.ADD_SELECT_SEAT](state, payload = {}) {
    const {
      flightName,
      passengerId,
      seatCode,
      previouslySelectedSeatCodes = [],
    } = payload;
    const type = ANCILLARY_TYPE_SELECT_SEAT;

    const transform = {
      seatSelection: ifElse(
        hasSelectSeatAncillary(flightName, passengerId),
        adjustWhereEq(assoc('seatCode', seatCode), {
          type,
          flightName,
          passengerId,
          previouslySelectedSeatCodes,
        }),
        append({
          type,
          seatCode,
          flightName,
          passengerId,
          previouslySelectedSeatCodes,
        })
      ),
    };

    hevolve(
      {
        unsavedAncillaries: transform,
        allUnsavedAncillaries: transform,
      },
      state
    );
  },

  [m.ADD_RESET_FLIGHT_SEATS](state, flightName) {
    const transform = {
      seatSelection: compose(
        append({ type: ANCILLARY_TYPE_RESET_SEATS, flightName }),
        reject(propEq('flightName', flightName))
      ),
    };

    return hevolve(
      {
        unsavedAncillaries: transform,
        allUnsavedAncillaries: transform,
      },
      state
    );
  },

  [m.SET_UNSAVED_ANCILLARIES](state, data) {
    // NOTE: we need to mirror the changes in both `unsavedAncillaries` and `allUnsavedAncillaries`
    //  because the enqueuing of ancillaries POST relies on it when an error happens.
    //  it is easier to mirror the changes instead of calculating a diff between unsaved
    //  and pending ancillaries (where merging the remove operation would cause extra headache)
    if (isNotNil(data.passengerNumber)) {
      state.unsavedAncillaries = handleUnsavedPassengerAncillaries(
        state.unsavedAncillaries,
        data
      );
      state.allUnsavedAncillaries = handleUnsavedPassengerAncillaries(
        state.allUnsavedAncillaries,
        data
      );
    } else {
      state.unsavedAncillaries = deepMerge(
        splitAncillariesByDirection(state.unsavedAncillaries),
        data
      );
      state.allUnsavedAncillaries = deepMerge(
        splitAncillariesByDirection(state.allUnsavedAncillaries),
        data
      );
    }
  },

  [m.CLEAR_UNSAVED_ANCILLARIES](state) {
    state.unsavedAncillaries = getDefaultUnsavedAncillaries();
  },

  [m.CLEAR_ALL_UNSAVED_ANCILLARIES](state) {
    state.allUnsavedAncillaries = getDefaultUnsavedAncillaries();
  },

  [m.MODIFY_PRIVILEGE_PASS_ANCILLARIES](state, data) {
    const passenger = state.passengers.find(
      propEq('passengerNumber', data.passengerNumber)
    );
    passenger.outboundFlight = deepMerge(
      passenger.outboundFlight,
      data.flights.outboundFlight
    );

    if (data.flights.returnFlight) {
      passenger.returnFlight = deepMerge(
        passenger.returnFlight,
        data.flights.returnFlight
      );
    }
  },

  [m.SET_IS_ANCILLARY_POST_PENDING](state, value) {
    state.isAncillaryPostPending = Boolean(value);
  },

  [m.SET_IS_RAW_ANCILLARY_POST_PENDING](state, value) {
    state.isRawAncillaryPostPending = Boolean(value);
  },

  [m.UPDATE_PAID_SEAT_ASSIGNMENT](state, payload) {
    const { flightName, passengerId, seatCode } = payload;
    hevolve(
      {
        [`${flightName}Flight`]: {
          paidSeatAssignments: assignSeat(passengerId, seatCode),
        },
      },
      state
    );
  },

  [m.COPY_PASSENGER_ANCILLARIES_TO_ALL_PASSENGERS](state, payload) {
    const { sourcePassengerNumber, targetPassengerNumbers } = payload;
    const sourcePassenger = state.passengers[sourcePassengerNumber];

    state.passengers.forEach((passenger) => {
      if (targetPassengerNumbers.includes(passenger.passengerNumber)) {
        const grantedDueInfant =
          passenger.outboundFlight.priorityBoarding.grantedDueInfant;
        const returnFlight = passenger.returnFlight;
        if (returnFlight) {
          passenger.returnFlight.baggage.selected =
            sourcePassenger.returnFlight.baggage.selected;
          passenger.returnFlight.sportsEquipment.selected =
            sourcePassenger.returnFlight.sportsEquipment.selected;
          if (!grantedDueInfant) {
            passenger.returnFlight.priorityBoarding.selected =
              sourcePassenger.returnFlight.priorityBoarding.selected;
          }
        }

        passenger.outboundFlight.baggage.selected =
          sourcePassenger.outboundFlight.baggage.selected;
        passenger.outboundFlight.sportsEquipment.selected =
          sourcePassenger.outboundFlight.sportsEquipment.selected;

        if (!grantedDueInfant) {
          passenger.outboundFlight.priorityBoarding.selected =
            sourcePassenger.outboundFlight.priorityBoarding.selected;
        }
      }
    });
  },

  [m.SET_SELECTED_OPTION](state, payload) {
    const { passengerNumber, services } = payload;

    if (isNumber(passengerNumber)) {
      // TODO add support for global pax ancillaries
      [OUTBOUND_FLIGHT, RETURN_FLIGHT].forEach((direction) => {
        Object.keys(payload[direction] || {}).forEach((ancillaryName) => {
          const passenger = state.passengers.find(
            propEq('passengerNumber', passengerNumber)
          );
          if (!passenger) return;
          passenger[direction][ancillaryName].selected =
            payload[direction][ancillaryName];
        });
      });
      return;
    }

    Object.entries(services || {}).forEach(([serviceName, service]) => {
      const { direction } = service;
      const hasNoDirection = isNil(direction);

      if (serviceName === SERVICE_NAME_AIRPORT_TRANSFERS) {
        const leg = `${direction}StationAirportTransfers`;
        state.services.bookingLevelServices[serviceName][leg].selected = service.option;
        state.services.bookingLevelServices[serviceName][leg].selectedCount =
          service.taxiCount;
        return;
      }

      if (serviceName === ANCILLARY_AIRPORT_PARKING) {
        state.services.outboundFlightServices[serviceName].selected = service.option;
        return;
      }

      if (serviceName === SERVICE_NAME_SEATING_TOGETHER_GUARANTEE) {
        state.services.bookingLevelServices[serviceName].selected =
          service.option || service.selected;
        return;
      }

      if (hasNoDirection) {
        state.services.bookingLevelServices[serviceName].selected =
          service.option || service.selected;
        return;
      }

      state.services[`${service.direction}FlightServices`][serviceName].selected =
        service.selected;
    });
  },

  [m.SET_PRIORITY_BOARDING_ADDED](state) {
    state.isPriorityBoardingAlreadyAdded = true;
  },

  [m.SET_CAR_RENTAL_UNSUPPORTED_CURRENCIES](state, payload) {
    state.unsupportedCarRentalCurrencies = payload.notSupportedCurrencies;
  },

  [m.CAR_RENTAL_RESET_UNSUPPORTED_CURRENCIES](state) {
    state.unsupportedCarRentalCurrencies = [];
  },

  [m.SET_CAR_TRAWLER_UNSUPPORTED_CURRENCIES](state, payload) {
    state.unsupportedCarTrawlerCurrencies = payload.notSupportedCurrencies;
  },

  [m.CAR_TRAWLER_RESET_UNSUPPORTED_CURRENCIES](state) {
    state.unsupportedCarTrawlerCurrencies = [];
  },

  [m.SET_IS_FORCED_CURRENCY_CHANGE](state, value) {
    state.isForcedCurrencyChange = value;
  },

  [m.UPDATE_AIRPORT_PARKING](state, payload) {
    const { options, accessibility } = payload;
    const lowestOption = options.reduce(
      (acc, curr) => (!acc || curr.price.amount < acc.price.amount ? curr : acc),
      null
    );
    state.airportParking = {
      ...state.airportParking,
      ...payload,
      ...(lowestOption && {
        lowestPrice: lowestOption.price,
        lowestPricePerDay: lowestOption.pricePerDay || {},
      }),
    };
    state.airportParking.tempOptions = options;
    if (accessibility) {
      hassocPath(
        'services.outboundFlightServices.airportParking.accessibility',
        accessibility,
        state
      );
    }
  },

  [m.SET_SELECTED_AIRPORT_PARKING](state, payload = {}) {
    const { selectedDropOffDateTime, selectedPickUpDateTime, selectedOperator } = payload;
    state.airportParking = {
      ...state.airportParking,
      ...payload,
      startDate: selectedDropOffDateTime,
      endDate: selectedPickUpDateTime,
    };
    state.airportParking.options = state.airportParking.tempOptions;
    state.services.outboundFlightServices.airportParking.selected =
      isNotEmpty(selectedOperator);
  },

  [m.UPDATE_AIRPORT_PARKING_PLATE_NUMBER](state, payload) {
    state.airportParking.plateNumber = payload;
  },

  [m.SET_AIRPORT_PARKING_IS_LOADING](state) {
    state.airportParking.isLoading = true;
  },

  [m.UNSET_AIRPORT_PARKING_IS_LOADING](state) {
    state.airportParking.isLoading = false;
  },

  [m.UPDATE_AIRPORT_PARKING_OPTIONS](state, payload) {
    const { options } = payload;
    const lowestOption = options.reduce(
      (acc, curr) => (!acc || curr.price.amount < acc.price.amount ? curr : acc),
      null
    );

    state.airportParking.tempOptions = options;
    if (lowestOption) {
      state.airportParking.lowestPrice = lowestOption.price;
      state.airportParking.lowestPricePerDay = lowestOption.pricePerDay || {};
    }
  },

  [m.UPDATE_AIRPORT_PARKING_DATES](state, payload) {
    const { startDate, endDate } = payload;
    state.airportParking.startDate = startDate;
    state.airportParking.endDate = endDate;
  },

  [m.UPDATE_AIRPORT_PARKING_ERRORS](state, error) {
    state.airportParking.errors.push(error);
  },

  [m.CLEAR_AIRPORT_PARKING_ERRORS](state) {
    state.airportParking.errors = [];
  },

  [m.SET_AIRPORT_PARKING_ACCESSIBILITY](state, value) {
    state.airportParking.accessibility = value;
  },

  [m.UPDATE_AIRPORT_PARKING_TEMP_OPTIONS](state) {
    state.airportParking.tempOptions = state.airportParking.options;
  },

  [m.SET_BUNDLES](state, payload = []) {
    state.bundles = payload;
  },

  [m.SET_LOUNGE_ACCESS_V2_DATA](state, payload) {
    state.loungeAccessV2.data = payload;
  },

  [m.SET_LOUNGE_ACCESS_V2_LOADING](state, value) {
    state.loungeAccessV2.isLoading = value;
  },

  [m.SET_FAST_TRACK_SECURITY_V2_DATA](state, payload) {
    state.fastTrackSecurityV2.data = payload;
  },

  [m.SET_FAST_TRACK_SECURITY_V2_LOADING](state, value) {
    state.fastTrackSecurityV2.isLoading = value;
  },

  [m.SET_WDC_SAVING](state) {
    state.isWdcSavingSet = true;
  },

  [m.SET_FLASH_PROMO_VALID_UNTIL](state) {
    // flash promo validity and countdown timer is handled by FE.
    const flashPromoDuration = getLocalItemRaw(KOBALOS_ONE_MINUTE_FLASH_PROMO)
      ? 1
      : FLASH_PROMO_DURATION_IN_MINUTES;
    const validUntil = addMinutes(flashPromoDuration, currentDateAndTime());

    state.flashPromo.validUntil = validUntil;
  },

  [m.RESET_FLASH_PROMO_VALID_UNTIL](state) {
    state.flashPromo.validUntil = null;
  },

  [m.SET_FLASH_PROMO_STATUS_RUNNING](state) {
    state.flashPromo.status = FLASH_PROMO_STATUS_RUNNING;
  },

  [m.SET_FLASH_PROMO_STATUS_DISABLED](state) {
    state.flashPromo.status = FLASH_PROMO_STATUS_DISABLED;
  },

  [m.SET_FLASH_PROMO_STATUS_INACTIVE](state) {
    state.flashPromo.status = FLASH_PROMO_STATUS_INACTIVE;
  },

  [m.SET_FLASH_PROMO_STATUS_ACTIVATED](state) {
    state.flashPromo.status = FLASH_PROMO_STATUS_ACTIVATED;
  },

  [m.SET_FLASH_PROMOS](state, integrationIds) {
    state.flashPromo.promotionIds = integrationIds;
  },

  [m.RESET_FLASH_PROMOS](state) {
    state.flashPromo.promotionIds = [];
    state.flashPromo.status = FLASH_PROMO_STATUS_DISABLED;
    state.flashPromo.validUntil = null;
  },

  [m.ADD_PREVIOUSLY_SELECTED_OUTBOUND_SEAT_CODE](state, data) {
    const { passengerNumber, seatIdentifier } = data;
    if (
      state.previouslySelectedSeats.outboundSeats.some(
        whereEq({ passengerNumber, seatIdentifier })
      )
    )
      return;

    state.previouslySelectedSeats.outboundSeats.push(data);
  },

  [m.ADD_PREVIOUSLY_SELECTED_RETURN_SEAT_CODE](state, data) {
    const { passengerNumber, seatIdentifier } = data;
    if (
      state.previouslySelectedSeats.returnSeats.some(
        whereEq({ passengerNumber, seatIdentifier })
      )
    )
      return;

    state.previouslySelectedSeats.returnSeats.push(data);
  },

  [m.SET_SELECTED_CAR_TRAWLER_PRODUCT](state, payload) {
    state.selectedCarTrawler = payload;
  },

  [m.RESET_SELECTED_CAR_TRAWLER_PRODUCT](state) {
    state.selectedCarTrawler = {};
  },
};

const splitAncillariesByDirection = (unsavedAncillaries) => {
  if (!unsavedAncillaries.services) return unsavedAncillaries;

  const services = {};
  Object.entries(unsavedAncillaries.services).forEach(([key, value]) => {
    if (value.direction && !value.processed) {
      services[`${key}${capitalize(value.direction)}`] = {
        ...value,
        processed: true, // hard code some spam into the object to avoid infinite loops
      };
      delete services[key];
    } else {
      services[key] = value;
    }
  });

  return { ...unsavedAncillaries, services };
};

const hasSelectSeatAncillary = (flightName, passengerId) =>
  compose(
    Boolean,
    find(whereEq({ type: ANCILLARY_TYPE_SELECT_SEAT, flightName, passengerId }))
  );

const getInitialFlight = (flightName, data) => {
  let { passengers, seatingDetails, seatingResults } = data || {};
  const paidSeatAssignments = seatAssignmentsFrom(flightName, 'sold', passengers);
  const currentSeatAssignments = seatAssignmentsFrom(flightName, 'current', passengers);
  seatingDetails = seatingDetails || {};
  seatingResults = seatingResults || {};

  return {
    paidSeatAssignments,
    currentSeatAssignments,
    seatPrices: seatPricesFrom({
      flightName,
      seatingDetails,
      ancillariesPassengers: passengers,
      paidSeatAssignments,
    }),
    promotionSeatPrices: promotionSeatPricesFrom({
      flightName,
      seatingDetails,
      ancillariesPassengers: passengers,
      paidSeatAssignments,
    }),
    seatAssignmentResult: seatingResults[`${flightName}SeatAssignmentResult`] || '',
  };
};

const handleUnsavedPassengerAncillaries = (unsavedAncillaries, data) => {
  if (isEmpty(unsavedAncillaries.passengers))
    return assoc('passengers', [data], unsavedAncillaries);

  const affectedPaxIndex = unsavedAncillaries.passengers.findIndex(
    propEq('passengerNumber', data.passengerNumber)
  );

  // passenger already added something
  if (affectedPaxIndex !== -1)
    return evolve(
      { passengers: adjust(alter(data), affectedPaxIndex) },
      unsavedAncillaries
    );

  // passenger not yet added
  return evolve({ passengers: append(data) }, unsavedAncillaries);
};
