import arity from '../arity';

/**
 * @typedef {<T extends any[], U>(fn: (...args: T) => U) => (...args: T) => U } ComplementFn
 */

/**
 * @type ComplementFn
 */
export const _complement =
  (fn) =>
  (...args) =>
    !fn(...args);

/**
 * Takes a function `f` and returns a function `g` such that if called with the same arguments
 * when `f` returns a truthy value, `g` returns `false` and when `f` returns a falsy value `g` returns `true`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/complement.js
 *
 * @type ComplementFn
 * @example
 *
 *      let isNotEmpty = complement(isEmpty);
 *      isEmpty(''); //=> true
 *      isNotEmpty(''); //=> false
 *      isEmpty([1, 2, 3]); //=> false
 *      isNotEmpty([1, 2, 3]); //=> true
 */
const complement = (fn) => arity(fn.length, (...args) => !fn(...args));

export default complement;
