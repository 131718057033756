import curry from '../curry';

/**
 * @typedef {<T>(depth: number = 1, arr: T[]) => T[]} FlatFn
 */

/**
 * @type FlatFn
 */
export const _flat = (depth, arr) => arr.flat(depth || 1);

/**
 * @type FlatFn
 * @example
 *
 *      flat([1, [2], [[3]]]); //=> [1, 2, [3]]
 *      flat([[1, 2], [3, 4], [5, 6]]); //=> [1, 2, 3, 4, 5, 6]
 */
const flat = curry(_flat);

export default flat;
