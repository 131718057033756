import compose from '~/utils/fp/compose';
import isNotString from '../object/is-not-string';
import isEmptyString from '../object/is-empty-string';
import { ALLOWED_ACCENTS, ACCENTS_MAP } from './constants';

export {
  camelCase as toCamelCase,
  paramCase as toKebabCase,
  sentenceCase as toSentenceCase,
  capitalCase as toCapitalCase,
  constantCase as toConstantCase,
  pascalCase as toPascalCase,
  snakeCase as toSnakeCase,
} from 'change-case';
export { titleCase as toTitleCase } from 'title-case';

/**
 * @type {(str: string) => string}
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * @type {(str: string) => string}
 */
export const decapitalize = (str) => str.charAt(0).toLowerCase() + str.slice(1);

/**
 * @param {string} string to be changed
 * @param {Array} values values to be inserted into the string
 * @param {string} regExp regular expression describing the pattern to be replaced in the string
 * @returns {string} string containing real values instead of param patterns
 */
export const replaceParamsByPattern = (str, values = [], regExp = /{(\d)}/g) =>
  str.replace(regExp, (_, i) => values[i] || '');

export const hasNumericCharacter = (value) => /\d/g.test(value);

export const hasAlphanumericCharacter = (value) =>
  new RegExp(`[A-Za-z${ALLOWED_ACCENTS}]`, 'g').test(value);

export const notContainsWhiteSpace = (value) => !/\s/g.test(value);

export const highlight = (str, color = 'pink') =>
  `<span class="highlight--${color}">${str}</span>`;

/**
 * @param {String} value a string in which every '-' must be removed
 * @returns {String}
 */
export const prettifyPhoneNumber = (value) => (value ?? '').replace(/-/gi, '');

/**
 * @param {String} value   a string that will be transformed by source
 * @param {String} source  value will be replaced with this string
 * @returns {String}
 */
export const matchCaps = (value, source) => {
  source = source || '';
  const result = value || '';
  const regex = new RegExp(escapeStringRegexp(source), 'i');
  return result.replace(regex, source);
};

/**
 * based on: https://github.com/sindresorhus/escape-string-regexp/blob/v4.0.0/index.js
 *   copied here because babel preset-env `useBuiltIns: 'usage'` expects es modules
 *   as inputs. at least it expected a `default` export which wasn't the case
 */
export const escapeStringRegexp = (str) => {
  if (isNotString(str)) throw new TypeError('Expected a string');

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a \unnnn escape
  //  when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return str.replace(/[$()*+.?[\\\]^{|}]/g, '\\$&').replaceAll('-', '\\x2d');
};

/**
 * @param {String|Number} value   to be changed
 * @returns {String}              hyphen separated card number
 */
export const cardNumber = (value) => {
  if (!value) return value;

  return String(value)
    .replace(/\D/g, '')
    .replace(/((.){4})/g, '$1-')
    .replace(/-$/, '');
};

export const zeroPad = (num, places = 2) => String(num).padStart(places, '0');

/** @type {(str: string) => string} */
export const normalize = (str) => {
  if (isNotString(str) || isEmptyString(str)) return '';
  return str.toLowerCase().trim();
};

/** @type {(str: string) => string} */
export const foldAccents = (str) => {
  if (isNotString(str) || isEmptyString(str)) return str;
  return Object.entries(ACCENTS_MAP).reduce(
    (acc, [char, accentsArr]) =>
      acc.replaceAll(new RegExp(`[${accentsArr.join('')}]`, 'gi'), char),
    str
  );
};

/** @type {(str: string) => string} */
export const normalizeAndFoldAccents = compose(foldAccents, normalize);
