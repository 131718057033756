import log from 'loglevel';
import {
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  WDC_MAX_PASSENGER_COUNT_STANDARD,
  DISCOUNT_TYPE_NONE,
  DEFAULT_ERROR_LABEL,
  FARE_DISCOUNT_TYPE_NONE,
} from '~/constants';
import inc from '~/utils/fp/inc';
import dec from '~/utils/fp/dec';
import compose from '~/utils/fp/compose';
import max from '~/utils/fp/max';
import concat from '~/utils/fp/concat';
import flip from '~/utils/fp/flip';
import __ from '~/utils/fp/__';
import curry from '~/utils/fp/curry';
import uniq from '~/utils/fp/uniq';
import hevolve from '~/utils/object/hevolve';
import isEmpty from '~/utils/object/is-empty';
import halter from '~/utils/object/halter';
import hassocPath from '~/utils/object/hassoc-path';
import isNotEmpty from '~/utils/object/is-not-empty';
import { formatCurrency } from '~/utils/currency';
import { differenceInDays } from '~/utils/date';
import { apiErrors } from '~/utils/services';
import { captureException } from '~/utils/logging';
import { getDefaultState, getDefaultErrors } from './internal';
import * as m from './mutation-types';

/**
 * @type {(direction: String, state: State) => Object<string, any>}
 */
const selectionByDirection = (direction, state) =>
  direction === DIRECTION_OUTBOUND ? state.outboundSelection : state.returnSelection;

/**
 * @type {(state: State, value: boolean) => void}
 */
const setIsWdcTermsAgreed = (state, value) => {
  state.isWdcTermsAgreed = value;
};

/**
 * @type {(value: boolean) => (state: State) => void}
 */
const setHasPurchasedWdcMembership = (value) => (state) => {
  state.isWdcMembershipAddedToCart = value;
};

const resetGroupRequest = (state, direction) => {
  const selection = selectionByDirection(direction, state);

  selection.groupRequestedFlightId = null;
};

const setIsWdcFareTypeSelectedFlag = (state, value) => {
  state.isWdcFareTypeSelected = value;
};

/**
 * @type {(direction: string) => (state: State, value: string) => void}
 */
const setSelectedFlightDateBy = (direction) => (state, value) =>
  setSelectedFlightDate(state, { direction, value });

const setSelectedFlightDate = (state, { direction, value } = {}) => {
  const selection = selectionByDirection(direction, state);

  selection.selectedFlightDate = value;
};

/**
 * @type {(state: State, membership: string) => void}
 */
const selectWdcMembership = (state, membership) => {
  hassocPath('selectedWdcMembership', membership, state);
};

/**
 * @type {(state: State, membership: string) => void}
 */
const resetSelectedWdcMembership = (state) =>
  hassocPath('selectedWdcMembership', '', state);

/**
 * @type {(state: State) => void}
 */
const selectGroupWdcMembership = flip(selectWdcMembership)('GROUP');

/**
 * @type {(state: State) => void}
 */
const selectStandardWdcMembership = flip(selectWdcMembership)('STANDARD');

const setSelectedFare = (state, payload = {}) => {
  const { direction, fareId, flightId } = payload;
  [DIRECTION_OUTBOUND, DIRECTION_RETURN].forEach((direction) => {
    const selection = selectionByDirection(direction, state);

    selection.groupRequestedFlightId = null;
  });

  const selection = selectionByDirection(direction, state);

  const selectedFlightId = flightId || selection.openFlightId;
  selection.selectedFareId = fareId;
  selection.selectedFlightId = selectedFlightId;

  if (
    direction === DIRECTION_OUTBOUND
      ? state.outboundSelection.selectedFareId && !state.isOutboundBasicPreselected
      : state.returnSelection.selectedFareId && !state.isReturnBasicPreselected
  )
    selection.openFlightId = null;
};

const unsetIsSubmitAttempted = hassocPath('isSubmitAttempted', false);

const setIsWdcFareTypePreferredFlag = (state, value) => {
  state.isWdcFareTypePreferred = value;
};

/**
 * @type {(state: State, direction: Direction) => void}
 */
const resetFlightDates = curry((state, direction) => {
  const selection = selectionByDirection(direction, state);
  selection.flightDatePicker.dates = [];
});

/**
 * @type {(state: State, direction: Direction, value: Object<string, any>[]) => void}
 */
const setFlightDates = (state, { direction, value } = {}) => {
  const selection = selectionByDirection(direction, state);
  selection.flightDatePicker.dates = value;
};

/**
 * @type {(direction: Direction) => (state: State, value: Object<string, any>[]) => void}
 */
const setFlightDatesBy = (direction) => (state, value) =>
  setFlightDates(state, { direction, value });

const setOutboundFlightDates = setFlightDatesBy(DIRECTION_OUTBOUND);

const setReturnFlightDates = setFlightDatesBy(DIRECTION_RETURN);

export default {
  [m.INITIALIZE](state, payload = {}) {
    const {
      departureStationIata,
      arrivalStationIata,
      departureDate,
      returnDate,
      numberOfAdultPassengers,
      numberOfChildPassengers,
      numberOfInfantPassengers,
      rescueFareCode,
      isAccessibilityEnabled,
      isWdcFareTypePreferred = false,
    } = payload;

    Object.assign(state, getDefaultState(), {
      departureStationIata,
      arrivalStationIata,
      numberOfAdultPassengers,
      numberOfChildPassengers,
      numberOfInfantPassengers,
      rescueFareCode,
      isWdcFareTypePreferred,
    });

    unsetIsSubmitAttempted(state);
    state.outboundSelection.selectedFlightDate = departureDate;
    state.outboundSelection.isAccessibilityEnabled = isAccessibilityEnabled;
    state.returnSelection.selectedFlightDate = returnDate;
  },

  [m.SET_IS_ANCILLARIES_GET_CALLED]: hassocPath('isAncillariesGetCalled', true),

  [m.UNSET_IS_ANCILLARIES_GET_CALLED]: hassocPath('isAncillariesGetCalled', false),

  [m.SET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION]: hassocPath(
    'isPostSelectCalledAfterFlightAndFareSelection',
    true
  ),

  [m.UNSET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION]: hassocPath(
    'isPostSelectCalledAfterFlightAndFareSelection',
    false
  ),

  [m.SET_IS_WDC_FARE_TYPE_PREFERRED]: flip(setIsWdcFareTypePreferredFlag)(true),

  [m.UNSET_IS_WDC_FARE_TYPE_PREFERRED]: flip(setIsWdcFareTypePreferredFlag)(false),

  [m.SET_IS_WDC_FARE_TYPE_PREFERRED_FLAG]: setIsWdcFareTypePreferredFlag,

  [m.UPDATE_ON_FLIGHT_SEARCH](state, payload = {}) {
    const {
      discountType = FARE_DISCOUNT_TYPE_NONE,
      discountPercentage = 0,
      wdcRenewalPrice = null,
      isBundleCompositionWarningTextVisible = false,
      isDomestic = false,
      isWdcPremiumEnabled = false,
      isGroupBooking = false,
      outboundFlights = [],
      returnFlights = [],
      outboundCo2Emission = null,
      returnCo2Emission = null,
    } = payload;

    halter(
      {
        discountType,
        discountPercentage,
        isBundleCompositionWarningTextVisible,
        isDomestic,
        isWdcPremiumEnabled,
        isGroupBooking,
        wdcRenewalPrice,
        outboundSelection: { flights: outboundFlights },
        returnSelection: { flights: returnFlights },
        outboundCo2Emission,
        returnCo2Emission,
      },
      state
    );
  },

  [m.RESET_FLIGHTS](state) {
    state.discountType = DISCOUNT_TYPE_NONE;
    state.discountPercentage = 0;
    state.outboundSelection.flights = [];
    state.returnSelection.flights = [];
  },

  [m.OPEN_FLIGHT](state, payload = {}) {
    const { direction, flightId } = payload;
    const selection = selectionByDirection(direction, state);

    selection.openFlightId = selection.openFlightId === flightId ? null : flightId;
  },

  [m.TOGGLE_FARE_TYPE](state) {
    [DIRECTION_OUTBOUND, DIRECTION_RETURN].forEach((direction) => {
      const selection = selectionByDirection(direction, state);

      selection.selectedFlightId = null;
      selection.selectedFareId = null;
      selection.groupRequestedFlightId = null;
    });

    state.isWdcFareTypeSelected = !state.isWdcFareTypeSelected;
    state.isWdcFareTypePreferred = state.isWdcFareTypeSelected;
  },

  [m.SELECT_FARE]: setSelectedFare,

  [m.REMOVE_FARE](state, direction) {
    halter(
      {
        [`${direction}Selection`]: {
          selectedFlightId: null,
          selectedFareId: null,
          openFlightId: null,
        },
      },
      state
    );
  },

  [m.SET_IS_WDC_FARE_TYPE_SELECTED_FLAG]: setIsWdcFareTypeSelectedFlag,

  [m.SET_IS_EMAIL_SUBSCRIPTION_CHECKED_FLAG](state, value) {
    state.isEmailSubscriptionChecked = value;
  },

  [m.SET_IS_WDC_TERMS_AGREED_FLAG]: setIsWdcTermsAgreed,

  [m.UNSET_IS_WDC_TERMS_AGREED]: flip(setIsWdcTermsAgreed)(false),

  [m.SELECT_WDC_MEMBERSHIP]: selectWdcMembership,

  [m.RESET_SELECTED_WDC_MEMBERSHIP]: resetSelectedWdcMembership,

  [m.SELECT_STANDARD_WDC_MEMBERSHIP]: selectStandardWdcMembership,

  [m.SELECT_GROUP_WDC_MEMBERSHIP]: selectGroupWdcMembership,

  [m.SET_HAS_PURCHASED_WDC_MEMBERSHIP]: setHasPurchasedWdcMembership(true),

  [m.UNSET_HAS_PURCHASED_WDC_MEMBERSHIP]: setHasPurchasedWdcMembership(false),

  [m.SET_SELECTED_FLIGHT_DATE]: setSelectedFlightDate,

  [m.SET_SELECTED_OUTBOUND_FLIGHT_DATE]: setSelectedFlightDateBy(DIRECTION_OUTBOUND),

  [m.SET_SELECTED_RETURN_FLIGHT_DATE]: setSelectedFlightDateBy(DIRECTION_RETURN),

  [m.RESET_OUTBOUND_FLIGHT_DATES]: resetFlightDates(__, DIRECTION_OUTBOUND),

  [m.RESET_RETURN_FLIGHT_DATES]: resetFlightDates(__, DIRECTION_RETURN),

  [m.RESET_FLIGHT_DATES]: resetFlightDates,

  [m.SET_OUTBOUND_FLIGHT_DATES]: setOutboundFlightDates,

  [m.SET_RETURN_FLIGHT_DATES]: setReturnFlightDates,

  [m.SET_FLIGHT_DATES]: setFlightDates,

  [m.SET_FLIGHT_DATES_DIFFERENCE](state) {
    const outboundFlightDate = state.outboundSelection.selectedFlightDate;
    const returnFlightDate = state.returnSelection.selectedFlightDate;
    if (!returnFlightDate) return;

    state.flightDatesDifference = differenceInDays(outboundFlightDate, returnFlightDate);
  },

  [m.SET_SYNC_DATA](state, payload = {}) {
    const { outboundFlights, returnFlights } = payload;
    state.sync.outboundFlights = outboundFlights;
    state.sync.returnFlights = returnFlights;
  },

  [m.SELECT_GROUP_REQUESTED_FLIGHT](state, payload = {}) {
    const { direction, flightId } = payload;
    const selection = selectionByDirection(direction, state);

    selection.groupRequestedFlightId = flightId;
    selection.selectedFlightId = flightId;
  },

  [m.RESET_GROUP_REQUESTED_FLIGHT]: resetGroupRequest,

  [m.UPDATE_WDC_DATA](state, response) {
    let { wdcMemberships } = response || {};
    wdcMemberships = wdcMemberships || [];

    const standardMembershipPrice =
      (wdcMemberships.find(({ membership }) => membership.includes('STANDARD')) || {})
        .membershipPrice || null;

    const standardPromotedMembershipPrice =
      (wdcMemberships.find((item) => item.membership.includes('STANDARD')) || {})
        .promotedMembershipPrice || null;

    const groupMembershipPrice =
      (wdcMemberships.find(({ membership }) => membership.includes('GROUP')) || {})
        .membershipPrice || null;

    const premiumMembershipPrice =
      (wdcMemberships.find(({ membership }) => membership.includes('PREMIUM')) || {})
        .membershipPrice || null;

    const premiumPlusMembershipPrice =
      (wdcMemberships.find(({ membership }) => membership.includes('PREMIUM_PLUS')) || {})
        .membershipPrice || null;

    const groupPromotedMembershipPrice =
      (wdcMemberships.find((item) => item.membership.includes('GROUP')) || {})
        .promotedMembershipPrice || null;

    const discounts = wdcMemberships[0]?.minimumDiscounts;

    // todo we don't want to format stuff here we want the base data, getters
    //  should calculate and add everything else
    const minimumBaggageDiscount = formatCurrency(discounts?.baggage);
    const minimumFareAmount = formatCurrency(discounts?.fares[0]?.minimumFare);
    const minimumFareDiscount = formatCurrency(discounts?.fares[0]?.discount);
    const minimumFareAmountSecondary = formatCurrency(discounts?.fares[1]?.minimumFare);
    const minimumFareDiscountSecondary = formatCurrency(discounts?.fares[1]?.discount);
    const minimumPrbDiscount = formatCurrency(discounts?.prb);

    halter(
      {
        wdcMemberships,

        wdcMembershipPrices: {
          standard: standardMembershipPrice ?? {},
          group: groupMembershipPrice ?? {},
          premium: premiumMembershipPrice ?? {},
          premiumPlus: premiumPlusMembershipPrice ?? {},
        },

        wdcPromotedMembershipPrices: {
          standard: standardPromotedMembershipPrice
            ? formatCurrency(standardPromotedMembershipPrice)
            : '',
          group: groupPromotedMembershipPrice
            ? formatCurrency(groupPromotedMembershipPrice)
            : '',
        },

        wdcPromotedMembershipPercentages: {
          standard: standardPromotedMembershipPrice
            ? Math.round(
                100 -
                  standardPromotedMembershipPrice.amount /
                    (standardMembershipPrice.amount / 100)
              )
            : 0,
          group: groupPromotedMembershipPrice
            ? Math.round(
                100 -
                  groupPromotedMembershipPrice.amount /
                    (groupMembershipPrice.amount / 100)
              )
            : 0,
        },

        wdcDiscounts: {
          minimumBaggageDiscount,
          minimumPrbDiscount,
          minimumFareAmount,
          minimumFareDiscount,
          minimumFareAmountSecondary,
          minimumFareDiscountSecondary,
        },
      },
      state
    );
  },

  [m.INIT_OFFERED_WDC_MEMBERSHIP](state, payload = {}) {
    const { hasStandardWdcMembership, numberOfIndependentPassengers } = payload;
    if (
      hasStandardWdcMembership ||
      numberOfIndependentPassengers > WDC_MAX_PASSENGER_COUNT_STANDARD
    ) {
      selectGroupWdcMembership(state);
    } else {
      selectStandardWdcMembership(state);
    }
  },

  [m.SET_IMPORTANT_INFORMATION](state, payload = {}) {
    const { direction, labels } = payload;
    const selection = selectionByDirection(direction, state);

    selection.importantInformation = labels;
  },

  [m.SET_IS_SUBMITTED]: hassocPath('isSubmitted', true),

  [m.UNSET_IS_SUBMITTED]: hassocPath('isSubmitted', false),

  [m.SET_IS_SUBMIT_IN_PROGRESS]: hassocPath('isSubmitInProgress', true),

  [m.UNSET_IS_SUBMIT_IN_PROGRESS]: hassocPath('isSubmitInProgress', false),

  [m.SET_SHOW_WDC_MEMBERSHIP_DETAILS_FLAG]: hassocPath(
    'isWdcPromotionDetailsVisible',
    true
  ),

  [m.SET_IS_INITIALIZED]: hassocPath('isInitialized', true),

  [m.SET_IS_SUBMIT_ATTEMPTED]: hassocPath('isSubmitAttempted', true),

  [m.UNSET_IS_SUBMIT_ATTEMPTED]: unsetIsSubmitAttempted,

  [m.INCREMENT_LOADING_COUNTER_OF]: (state, loadingCounter) => {
    hevolve({ loadingCounters: { [loadingCounter]: inc } }, state);
  },

  [m.DECREMENT_LOADING_COUNTER_OF]: (state, loadingCounter) => {
    hevolve(
      {
        loadingCounters: {
          [loadingCounter]: compose(max(0), dec),
        },
      },
      state
    );
  },

  [m.ADD_ERROR_OF]: (state, { type, error } = {}) => {
    error = error || {};
    captureException(error);
    let validationCodes = [];
    const isProperResponse = Boolean(error.headers && error.status);
    if (
      isProperResponse &&
      error.status !== 404 &&
      isEmpty(error.data?.validationCodes)
    ) {
      validationCodes.push(DEFAULT_ERROR_LABEL);
    } else {
      validationCodes = apiErrors(error);
      if (error.status >= 500) validationCodes.push(DEFAULT_ERROR_LABEL);
    }

    log.error(isNotEmpty(validationCodes) ? validationCodes : error);

    hassocPath(
      `errors.${type}`,
      uniq(concat(validationCodes, state.errors[type])),
      state
    );
  },

  [m.RESET_ERRORS_OF]: (state, errorType) => {
    hassocPath(`errors.${errorType}`, [], state);
  },

  [m.RESET_ERRORS](state) {
    state.errors = getDefaultErrors();
  },

  [m.SET_OPERATION_PROMISE_OF](state, { operation, promise } = {}) {
    hassocPath(`operationPromises.${operation}`, promise, state);
  },

  [m.SET_IS_OUTBOUND_FARE_UPGRADED]: hassocPath('upsell.isOutboundFareUpgraded', true),

  [m.UNSET_IS_OUTBOUND_FARE_UPGRADED]: hassocPath('upsell.isOutboundFareUpgraded', false),

  [m.SET_IS_RETURN_FARE_UPGRADED]: hassocPath('upsell.isReturnFareUpgraded', true),

  [m.UNSET_IS_RETURN_FARE_UPGRADED]: hassocPath('upsell.isReturnFareUpgraded', false),

  [m.UPDATE_FARE_UPGRADE_DETAILS]: (state, payload = {}) => {
    const { direction, upsellPrice, bundle } = payload;

    halter(
      {
        upsell: {
          [`${direction}FareUpgradePrice`]: upsellPrice,
          [`${direction}FareUpgradeBundle`]: bundle,
        },
      },
      state
    );
  },

  [m.TOGGLE_IS_BUNDLE_UPGRADED](state) {
    state.isBundleUpgraded = !state.isBundleUpgraded;
  },

  [m.SET_WDC_ADDED_ON_TARGETED_MODAL](state, value) {
    state.isWdcAddedOnTargetedModal = value;
  },

  [m.SET_IS_WDC_HIGHLIGHTED](state, value) {
    state.isWdcHighlighted = value;
  },

  [m.SET_IS_OUTBOUND_BASIC_PRESELECTED](state, value) {
    state.isOutboundBasicPreselected = value;
  },

  [m.SET_IS_RETURN_BASIC_PRESELECTED](state, value) {
    state.isReturnBasicPreselected = value;
  },

  [m.SET_PRICE_VISIBLE_ON_FARE_CHART](state, value) {
    state.isPriceVisibleOnFareChart = value;
  },

  [m.SHOW_FARE_BENEFITS]: hassocPath('isAllFareBenefitsVisible', true),
  [m.HIDE_FARE_BENEFITS]: hassocPath('isAllFareBenefitsVisible', false),

  [m.SET_IS_INIT_PASSENGERS_LOADING](state, value) {
    state.isInitPassengersLoading = value;
  },

  [m.SET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP]: hassocPath(
    'isContinueWithCurrentWdcMembership',
    true
  ),

  [m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP]: hassocPath(
    'isContinueWithCurrentWdcMembership',
    false
  ),
};
