/**
 * Takes a list of predicates and returns a predicate that returns true for a
 * given list of arguments if at least one of the provided predicates is
 * satisfied by those arguments.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/anyPass.js
 *
 * @type {(...predicates: Function[]) => (...args: any) => boolean}
 * @example
 *
 *      let isClub = propEq('suit', '♣');
 *      let isSpade = propEq('suit', '♠');
 *      let isBlackCard = anyPass(isClub, isSpade);
 *
 *      isBlackCard({rank: '10', suit: '♣'}); //=> true
 *      isBlackCard({rank: 'Q', suit: '♠'}); //=> true
 *      isBlackCard({rank: 'Q', suit: '♦'}); //=> false
 */
const anyPass =
  (...predicates) =>
  (...args) =>
    predicates.some((predicate) => predicate(...args));

export default anyPass;
