export const RESET = 'ancillaries/reset';
export const INITIALIZE_ANCILLARIES = 'ancillaries/setAncillaries';
export const SET_UNSAVED_ANCILLARIES = 'ancillaries/setUnsavedAncillaries';
export const SET_UNSAVED_ANCILLARIES_TO_ALL_UNSAVED_ANCILLARIES =
  'ancillaries/setUnsavedAncillariesToAllUnsavedAncillaries';
export const CLEAR_UNSAVED_ANCILLARIES = 'ancillaries/clearUnsavedAncillaries';
export const CLEAR_ALL_UNSAVED_ANCILLARIES = 'ancillaries/clearAllUnsavedAncillaries';
export const MODIFY_PRIVILEGE_PASS_ANCILLARIES = 'ancillaries/modifyPrivilegePass';
export const SET_CAR_RENTAL_UNSUPPORTED_CURRENCIES =
  'ancillaries/setUnsupportedCurrencies';
export const CAR_RENTAL_RESET_UNSUPPORTED_CURRENCIES =
  'ancillaries/carRentalResetUnsupportedCurrencies';
export const ADD_SELECT_SEAT = 'ancillaries/selectSeat';
export const ADD_RESET_FLIGHT_SEATS = 'ancillaries/resetFlightSeats';
export const SET_IS_ANCILLARY_POST_PENDING = 'ancillaries/isAncillaryPostPending';
export const SET_IS_RAW_ANCILLARY_POST_PENDING = 'ancillaries/isRawAncillaryPostPending';
export const UPDATE_PAID_SEAT_ASSIGNMENT = 'ancillaries/updatePaidSeatAssignment';
export const COPY_PASSENGER_ANCILLARIES_TO_ALL_PASSENGERS =
  'ancillaries/copyPassengerAncillariesToAllPassengers';
export const UPDATE_UPSELL_BANNER = 'ancillaries/updateUpsellBanner';
export const SET_SELECTED_OPTION = 'ancillaries/setSelectedOption';
export const SET_PRIORITY_BOARDING_ADDED = 'ancillaries/setPriorityBoardingAdded';
export const SET_DEBOUNCED_POST_ANCILLARIES_PROMISE =
  'ancillaries/setDebouncedPostAncillariesPromise';
export const RESET_DEBOUNCED_POST_ANCILLARIES_PROMISE =
  'ancillaries/resetDebouncedPostAncillariesPromise';
export const SET_IS_FORCED_CURRENCY_CHANGE = 'ancillaries/setIsForcedCurrencyChange';
export const UPDATE_AIRPORT_PARKING = 'ancillaries/updateAirportParking';
export const SET_SELECTED_AIRPORT_PARKING = 'ancillaries/setSelectedAirportParkingData';
export const UPDATE_AIRPORT_PARKING_PLATE_NUMBER =
  'ancillaries/updateAirportParkingPlateNumber';
export const SET_AIRPORT_PARKING_IS_LOADING = 'ancillaries/setAirportParkingIsLoading';
export const UNSET_AIRPORT_PARKING_IS_LOADING =
  'ancillaries/unsetAirportParkingIsLoading';
export const UPDATE_AIRPORT_PARKING_OPTIONS = 'ancillaries/updateAirportParkingOptions';
export const UPDATE_AIRPORT_PARKING_DATES = 'ancillaries/updateAirportParkingDates';
export const UPDATE_AIRPORT_PARKING_ERRORS = 'ancillaries/updateAirportParkingErrors';
export const CLEAR_AIRPORT_PARKING_ERRORS = 'ancillaries/clearAirportParkingErrors';
export const SET_AIRPORT_PARKING_ACCESSIBILITY =
  'ancillaries/setAirportParkingAccessibility';
export const UPDATE_AIRPORT_PARKING_TEMP_OPTIONS =
  'ancillaries/updateAirportParkingTempOptions';
export const TOGGLE_WIZZ_FLEX = 'ancillaries/toggleWizzFlex';
export const SET_WIZZ_FLEX = 'ancillaries/setWizzFlex';
export const ADD_WIZZ_FLEX = 'ancillaries/addWizzFlex';
export const REMOVE_WIZZ_FLEX = 'ancillaries/removeWizzFlex';

export const SET_BUNDLES = 'ancillaries/setBundles';
export const SET_LOUNGE_ACCESS_V2_DATA = 'ancillaries/setLoungeAccessV2Data';
export const SET_LOUNGE_ACCESS_V2_LOADING = 'ancillaries/setLoungeAccessV2Loading';
export const SET_FAST_TRACK_SECURITY_V2_DATA = 'ancillaries/setFastTrackSecurityV2Data';
export const SET_FAST_TRACK_SECURITY_V2_LOADING =
  'ancillaries/setFastTrackSecurityV2Loading';
export const SET_WDC_SAVING = 'ancillaries/setWdcSaving';
export const SET_FLASH_PROMO_VALID_UNTIL = 'ancillaries/setFlashPromoValidUntil';
export const RESET_FLASH_PROMO_VALID_UNTIL = 'ancillaries/resetFlashPromoValidUntil';
export const SET_FLASH_PROMO_STATUS_RUNNING = 'ancillaries/setFlashPromoStatusRunning';
export const SET_FLASH_PROMO_STATUS_DISABLED = 'ancillaries/setFlashPromoStatusDisabled';
export const SET_FLASH_PROMO_STATUS_INACTIVE = 'ancillaries/setFlashPromoStatusInactive';
export const SET_FLASH_PROMO_STATUS_ACTIVATED =
  'ancillaries/setFlashPromoStatusActivated';
export const SET_FLASH_PROMOS = 'ancillaries/setFlashPromos';
export const RESET_FLASH_PROMOS = 'ancillaries/resetFlashPromos';
export const ADD_PREVIOUSLY_SELECTED_OUTBOUND_SEAT_CODE =
  'ancillaries/addPreviouslySelectedOutboundSeatCode';
export const ADD_PREVIOUSLY_SELECTED_RETURN_SEAT_CODE =
  'ancillaries/addPreviouslySelectedReturnSeatCode';
export const SET_CAR_TRAWLER_UNSUPPORTED_CURRENCIES =
  'ancillaries/carTrawlerSetUnsupportedCurrencies';
export const CAR_TRAWLER_RESET_UNSUPPORTED_CURRENCIES =
  'ancillaries/carTrawlerResetUnsupportedCurrencies';
export const SET_SELECTED_CAR_TRAWLER_PRODUCT =
  'ancillaries/setSelectedCarTrawlerProduct';
export const RESET_SELECTED_CAR_TRAWLER_PRODUCT =
  'ancillaries/resetSelectedCarTrawlerProduct';
