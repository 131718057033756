import curry from '../curry';

/**
 * @typedef {(comparator: (a: T, B: T) => number, arr: T[]) => T[]} SortFn
 */

/**
 * @type SortFn
 */
export const _sort = (fn, arr) => arr.slice().sort(fn);

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/sort.js
 *
 * @type SortFn
 */
const sort = curry(_sort);

export default sort;
