export const SET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE =
  'successfulBooking/setIsSuccessfulBookingAffiliateModalVisible';
export const UNSET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE =
  'successfulBooking/unsetIsSuccessfulBookingAffiliateModalVisible';
export const RESET_AVAILABLE_FEEDBACK_STAGES =
  'successfulBooking/resetAvailableFeedbackStages';
export const SET_ERRORS = 'successfulBooking/setErrors';
export const INITIALIZE = 'successfulBooking/initialize';
export const RESET = 'successfulBooking/reset';
export const SET_IS_NEW_BOOKING = 'successfulBooking/setIsNewBooking';
export const SET_IS_NEW_SUCCESSFUL_BOOKING_INITIALIZED =
  'successfulBooking/setIsSuccessfulBookingInitialized';
