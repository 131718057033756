/* eslint import/no-cycle: "warn" */
import {
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  WDC_MAX_PASSENGER_COUNT_GROUP,
  MIN_INTERVAL_BEFORE_RETURN_FLIGHT,
  WDC_MAX_PASSENGER_COUNT_STANDARD,
  BUNDLE_SMART,
  BUNDLE_MIDDLE,
  BUNDLE_PLUS,
  FALLBACK_CURRENCY_CODE,
  CARRIER_WIZZ_HUN,
} from '~/constants';
import both from '~/utils/fp/both';
import path from '~/utils/fp/path';
import compose from '~/utils/fp/compose';
import propEq from '~/utils/fp/prop-eq';
import propEqTrue from '~/utils/fp/prop-eq-true';
import propIsNotEmpty from '~/utils/fp/prop-is-not-empty';
import reduce from '~/utils/fp/reduce';
import add from '~/utils/fp/add';
import values from '~/utils/fp/values';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import orElse from '~/utils/fp/or-else';
import equals from '~/utils/fp/equals';
import map from '~/utils/fp/map';
import complement from '~/utils/fp/complement';
import either from '~/utils/fp/either';
import prop from '~/utils/fp/prop';
import chain from '~/utils/fp/chain';
import filter from '~/utils/fp/filter';
import some from '~/utils/fp/some';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import allPass from '~/utils/fp/all-pass';
import includes from '~/utils/fp/includes';
import pathEq from '~/utils/fp/path-eq';
import isEmpty from '~/utils/object/is-empty';
import isNil from '~/utils/object/is-nil';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import {
  isBasicBundle,
  isPricePerPersonVisible as _isPricePerPersonVisible,
  isNotPlusBundle,
  isPlusBundle,
  isMiddleBundle,
  isSmartBundle,
  isMiddleTwoBundle,
} from '~/utils/booking/booking';
import { getShortName, isFakeStation, getCountryCodeByIata } from '~/utils/resource';
import {
  addDays,
  addYears,
  currentDateAndTime,
  toDefaultFormat,
  differenceInMinutes,
  formatDuration,
  isSameDay,
  isDayBefore,
} from '~/utils/date';
import { getPrice } from '~/utils/price';
import { isCountryCodeItaly } from '~/utils/booking/wdc';
import * as ancillariesGetters from '../ancillaries/getters';
import * as userGetters from '../user/getters';
import * as bookingGetters from '../booking/getters';
import * as coreBookingGetters from '../core-booking/getters';
import * as resourcesGetters from '../resources/getters';
import * as summaryGetters from '../summary/getters';
import * as itineraryGetters from '../itinerary/getters';
import * as featureGetters from '../feature/getters';
import { flightSelectProp, flightSelectPropEq } from './internal';
import { LoadingCounter, ErrorType, Operation } from './constants';

/**
 * @type {(errorType: string) => (state: State) => Object<string, any>[]}
 */
const errorsOf = (errorType) => flightSelectProp(`errors.${errorType}`);

/**
 * @type {(state: State) => Object<string, any>[]}
 */
const selectFareErrors = errorsOf(ErrorType.selectFare);

/**
 * @type {(state: State) => boolean}
 */
const hasSelectFareErrors = compose(isNotEmpty, selectFareErrors);

/**
 * @type {(state: State) => boolean}
 */
const hasNoSelectFareErrors = complement(hasSelectFareErrors);

/**
 * @type {(operation: string) => (state: State) => Promise}
 */
const operationPromiseOf = (operation) =>
  flightSelectProp(`operationPromises.${operation}`);

export const selectFarePromise = operationPromiseOf(Operation.selectFare);

export const changeFlightPromise = operationPromiseOf(Operation.changeFlight);

export const toggleWizzFlexPromise = operationPromiseOf(Operation.toggleWizzFlex);

export const toggleFareLockPromise = operationPromiseOf(Operation.toggleFareLock);

/**
 * @type {(state: State) => boolean}
 */
export const isAncillariesGetCalled = flightSelectProp('isAncillariesGetCalled');

/**
 * @type {(state: State) => boolean}
 */
export const isAncillariesGetNotCalled = complement(isAncillariesGetCalled);

/**
 * @type {(state: State) => boolean}
 */
export const shouldCallPostSelectAtSubmit = (state) => {
  if (featureGetters.isSplitPaxEnabled(state) && isGroupBooking(state)) return true;
  return (
    !isPostSelectCalledAfterFlightAndFareSelection(state) ||
    summaryGetters.isFareLockAdded(state)
  );
};

/**
 * @type {(state: State) => boolean}
 */
export const isPostSelectCalledAfterFlightAndFareSelection = flightSelectProp(
  'isPostSelectCalledAfterFlightAndFareSelection'
);

/**
 * @type {(state: State) => boolean}
 */
export const canInitializeFareTypeWithWdcFareType = (state) => {
  if (!isWdcFareTypeAvailable(state)) return false;
  if (isWdcFareTypePreferred(state) || isOnlyWdcFareTypeAvailable(state)) return true;
  return (
    !isWdcPremiumEnabled(state) &&
    userGetters.isLoggedIn(state) &&
    (hasStandardWdcMembershipAndStandardIsRecommended(state) ||
      hasWdcGroupMembershipAndStandardOrGroupIsRecommended(state))
  );
};

/**
 * @type {(state: State) => boolean}
 */
const isOnlyWdcFareTypeAvailable = (state) => {
  return !isRegularFareTypeAvailable(state) && isWdcFareTypeAvailable(state);
};

/**
 * @type {(state: State) => boolean}
 */
export const isWdcFareTypePreferred = flightSelectProp('isWdcFareTypePreferred');

/**
 * @type {(state: State) => boolean}
 */
export const isSubmitInProgress = flightSelectProp('isSubmitInProgress');

/**
 * @type {(state: State) => boolean}
 */
export const isUserActionAllowed = (state) =>
  [isLoading(state), isSubmitted(state)].every(equals(false));

/**
 * @type {(state: State) => boolean}
 */
export const isUserActionNotAllowed = complement(isUserActionAllowed);

/**
 * @type {(state: State) => boolean}
 */
export const isSubmitted = flightSelectProp('isSubmitted');

/**
 * @type {(propName: string) => (state: State) => boolean}
 */
const isLoadingOf = (propName) =>
  compose(gt(__, 0), flightSelectProp(`loadingCounters.${propName}`));

/**
 * @type {(state: State) => boolean}
 */
export const isDatePickerLoading = (state) => {
  return isInitialized(state) && isLoadingOf(LoadingCounter.datePicker)(state);
};

/**
 * @type {(state: State) => boolean}
 */
export const isFlightsLoading = (state) => {
  return isInitialized(state) && isLoadingOf(LoadingCounter.getFlights)(state);
};

/**
 * @type {(state: State) => boolean}
 */
export const isSelectFareLoading = isLoadingOf(LoadingCounter.selectFare);

/**
 * @type {(state: State) => boolean}
 */
export const isSelectFareNotLoading = complement(isSelectFareLoading);

/**
 * @type {(state: State) => Object<string, any>}
 */
const loadingCounters = flightSelectProp('loadingCounters');

/**
 * @type {(state: State) => boolean}
 */
export const isLoading = compose(gt(__, 0), reduce(add, 0), values, loadingCounters);

/**
 * @type {(state: State) => Price}
 */
export const wdcRenewalPrice = flightSelectProp('wdcRenewalPrice');

/**
 * @type {(state: State) => boolean}
 */
export const isAddReturnFlightVisible = (state) =>
  !isRoundTrip(state) &&
  !hasSelectedReturnFlightDate(state) &&
  !coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
  !hasSelectedOutboundFare(state);

/**
 * @type {(state: State) => boolean}
 */
export const isSecondaryAddReturnFlightVisible = (state) =>
  hasSelectedOutboundFare(state) &&
  !isRoundTrip(state) &&
  !hasSelectedReturnFlightDate(state) &&
  !coreBookingGetters.isFlightChangeOrRebookFlow(state);

/**
 * @type {(state: State) => string}
 */
export const rescueFareCode = flightSelectProp('rescueFareCode');

/**
 * @type {(state: State) => boolean}
 */
export const hasRescueFareCode = compose(isNotEmpty, rescueFareCode);

/**
 * @type {(state: State) => boolean}
 */
export const hasNoRescueFareCode = complement(hasRescueFareCode);

/**
 * @type {(state: State) => number}
 */
export const numberOfIndependentPassengers = (state) =>
  numberOfAdultPassengers(state) + numberOfChildPassengers(state);

/**
 * @type {(state: State) => number}
 */
export const numberOfAdultPassengers = flightSelectProp('numberOfAdultPassengers');

/**
 * @type {(state: State) => number}
 */
export const numberOfChildPassengers = flightSelectProp('numberOfChildPassengers');

/**
 * @type {(state: State) => number}
 */
export const numberOfInfantPassengers = flightSelectProp('numberOfInfantPassengers');

/**
 * @type {(state: State) => boolean}
 */
export const isPricePerPersonVisible = compose(
  _isPricePerPersonVisible,
  numberOfIndependentPassengers
);

/**
 * @type {(state: State) => []}
 */
export const errors = compose(
  reduce(
    (acc, currentErrors) => acc.concat(currentErrors),
    () => []
  ),
  values,
  flightSelectProp('errors')
);

export const validationResult = (state) => {
  const status = {
    isValid: true,
    message: '',
  };

  const isOutboundFlightSelected = isFlightSelected(
    bookingGetters.fares(state)?.outbound
  );
  const isReturnFlightSelected = isFlightSelected(bookingGetters.fares(state)?.return);

  if (
    coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
    !isOutboundFlightSelected &&
    !isReturnFlightSelected
  ) {
    status.isValid = false;
    status.message = 'summary-cart-change-a-flight';
    return status;
  }

  if (
    !coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
    !isOutboundFlightSelected
  ) {
    status.isValid = false;
    status.message = 'summary-cart-choose-an-outbound-flight';
    return status;
  }

  if (
    !coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
    isRoundTrip(state) &&
    !isReturnFlightSelected
  ) {
    status.isValid = false;
    status.message = 'summary-cart-choose-a-return-flight';
    return status;
  }

  return status;
};

const isFlightSelected = (fare) =>
  fare ? Boolean(fare.fareSellKey || fare.isGroupSeatRequest) : false;

/**
 * @type {(state: State) => boolean}
 */
// invalid market is the case where the user messed up
// the iata codes in the url (like BUD-BUD for example)
export const isInvalidMarket = compose(includes('InvalidMarket'), errors);

/**
 * @type {(state: State) => boolean}
 */
export const isNotInvalidMarket = complement(isInvalidMarket);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const fareLockPrice = (state) => {
  if (!isFareLockAvailable(state)) return null;

  const selectedCurrency = isWdcFareTypeSelected(state)
    ? selectedOutboundFare(state).wdcPriceDetails.fareLockPrice.currencyCode || ''
    : selectedOutboundFare(state).regularPriceDetails.fareLockPrice.currencyCode || '';
  const outboundFareLock = outboundFareLockPrice(state);
  const returnFareLock = returnFareLockPrice(state);
  const amount =
    outboundFareLock.amount + (isRoundTrip(state) ? returnFareLock.amount : 0);
  return {
    amount: amount * numberOfIndependentPassengers(state),
    currencyCode: selectedCurrency,
  };
};

/**
 * @type {(state: State) => string}
 */
export const fareLockFeeCode = (state) => {
  const selectedFare = selectedOutboundFare(state);
  return selectedFare ? selectedFare.bundle.fareLockFeeCode : null;
};

/**
 * @type {(state: State) => boolean}
 */
export const isWdcFareTypeSelected = flightSelectProp('isWdcFareTypeSelected');

/**
 * @type {(state: State) => string}
 */
export const selectedOutboundFlightDate = flightSelectProp(
  'outboundSelection.selectedFlightDate'
);

/**
 * @type {(state: State) => string}
 */
export const selectedReturnFlightDate = flightSelectProp(
  'returnSelection.selectedFlightDate'
);

/**
 * @type {(state: State) => boolean}
 */
export const hasSelectedReturnFlightDate = compose(isNotEmpty, selectedReturnFlightDate);

export const isRoundTrip = hasSelectedReturnFlightDate;

/**
 * @type {(selectedFlightDateGetter: (state: State) => string) => (state: State) => string}
 */
export const nextFlightDate = (selectedFlightDateGetter) => (state) => {
  if (!selectedFlightDateGetter(state)) return null;
  return compose(toDefaultFormat, addDays(1))(selectedFlightDateGetter(state));
};

/**
 * @type {(selectedFlightDateGetter: (state: State) => string) => (state: State) => string}
 */
export const previousFlightDate = (selectedFlightDateGetter) => (state) => {
  if (!selectedFlightDateGetter(state)) return null;
  const prevDate = compose(addDays(-1))(selectedFlightDateGetter(state));
  return isDayBefore(prevDate, currentDateAndTime()) ? null : toDefaultFormat(prevDate);
};

export const nextOutboundFlightDate = nextFlightDate(selectedOutboundFlightDate);

export const previousOutboundFlightDate = previousFlightDate(selectedOutboundFlightDate);

export const nextReturnFlightDate = nextFlightDate(selectedReturnFlightDate);

export const previousReturnFlightDate = previousFlightDate(selectedReturnFlightDate);

/**
 * @type {(state: State) => string}
 */

export const minReturnFlightDate = selectedOutboundFlightDate;

/**
 * @type {(state: State) => string}
 */
export const maxReturnFlightDate = (state) =>
  compose(toDefaultFormat, addYears(2))(minReturnFlightDate(state));

/**
 * @type {(state: State) => string}
 */
export const departureStationIata = flightSelectProp('departureStationIata');

/**
 * @type {(state: State) => string}
 */
export const arrivalStationIata = flightSelectProp('arrivalStationIata');

/**
 * @type {(state: State) => boolean}
 */
export const isCountryCodeHungary = (state) => {
  return [departureStationIata(state), arrivalStationIata(state)].some(
    (iata) =>
      getCountryCodeByIata(resourcesGetters.stationsWithFakes(state ?? {}), iata) === 'HU'
  );
};

/**
 * @type {(state: State) => boolean}
 */
export const isCountryCodePoland = (state) => {
  return [departureStationIata(state), arrivalStationIata(state)].some(
    (iata) =>
      getCountryCodeByIata(resourcesGetters.stationsWithFakes(state ?? {}), iata) === 'PL'
  );
};

/**
 * @type {(stations: Object<string, any>[], iata: string) => {}}
 */
const getStation = curry((stations, iata) => {
  return iata
    ? {
        iata,
        name: getShortName(stations, iata),
        shortName: getShortName(stations, iata), // the fare-finder depends on this
        isFakeStation: isFakeStation(stations, iata),
      }
    : null;
});

/**
 * @type {(state: State) => {}}
 */
export const departureStation = (state) => {
  return getStation(
    resourcesGetters.stationsWithFakes(state),
    departureStationIata(state)
  );
};

/**
 * @type {(state: State) => {}}
 */
export const arrivalStation = (state) => {
  return getStation(resourcesGetters.stationsWithFakes(state), arrivalStationIata(state));
};

/**
 * @type {(state: State) => boolean}
 */
const isDepartureStationFake = compose(propEqTrue('isFakeStation'), departureStation);

/**
 * @type {(state: State) => boolean}
 */
const isArrivalStationFake = compose(propEqTrue('isFakeStation'), arrivalStation);

/**
 * @type {(state: State) => boolean}
 */
export const isFakeStationSelected = either(isDepartureStationFake, isArrivalStationFake);

/**
 * @type {(state: State) => boolean}
 */
export const hasDepartureStation = compose(isNotEmpty, departureStation);

/**
 * @type {(state: State) => boolean}
 */
export const hasArrivalStation = compose(isNotEmpty, arrivalStation);

/**
 * @type {(state: State) => boolean}
 */
export const isBundleCompositionWarningTextVisible = flightSelectProp(
  'isBundleCompositionWarningTextVisible'
);

/**
 * @type {(state: State) => boolean}
 */
export const isDomestic = flightSelectProp('isDomestic');

/**
 * @type {(state: State) => boolean}
 */
export const isWdcPremiumEnabled = flightSelectProp('isWdcPremiumEnabled');

/**
 * @type {(state: State) => boolean}
 */
export const isContinueWithCurrentWdcMembership = flightSelectProp(
  'isContinueWithCurrentWdcMembership'
);

/*
 * @type {(state: State) => boolean}
 */
export const isWdcPremiumPanelVisible = (state) =>
  isWdcPremiumEnabled(state) &&
  !userGetters.hasPremiumPlusWdcMembership(state) &&
  !isDomestic(state) &&
  !isContinueWithCurrentWdcMembership(state);

/**
 * @type {(state: State) => string}
 */
export const departureStationNameWithIata = (state) => {
  if (!hasDepartureStation(state)) return '';
  const { iata, name, isFakeStation } = departureStation(state);
  const _iata = isFakeStation ? '' : ` (${iata})`;
  return `${name}${_iata}`;
};

/**
 * @type {(state: State) => string}
 */
export const arrivalStationNameWithIata = (state) => {
  if (!hasArrivalStation(state)) return '';
  const { iata, name, isFakeStation } = arrivalStation(state);
  const _iata = isFakeStation ? '' : ` (${iata})`;
  return `${name}${_iata}`;
};

/**
 * @type {(state: State) => string}
 */
export const discountType = flightSelectProp('discountType');

/**
 * @type {(state: State) => number}
 */
export const discountPercentage = flightSelectProp('discountPercentage');

/**
 * @type {(direction: Direction) => (state: object) => []}
 */
const _flights = (direction) => (state) => {
  // WARN following will cause infinite loop :(
  // const { flights } = outboundSelection(state);
  const { flights } = flightSelectProp(`${direction}Selection`, state);
  const stations = resourcesGetters.stationsWithFakes(state);
  return flights.map((flight) => mapFlightProperties(stations, state, flight, direction));
};

export const outboundFlights = _flights(DIRECTION_OUTBOUND);

export const returnFlights = _flights(DIRECTION_RETURN);

/**
 * @type {(state: State) => Object<string, any>}
 */
const outboundSelection = flightSelectProp('outboundSelection');

/**
 * @type {(state: State) => Object<string, any>}
 */
const returnSelection = flightSelectProp('returnSelection');

/**
 * @type {(fareType: string) => (selectionGetter: (state: State) => Object<string, any>) => (state: State) => boolean}
 */
const hasFare = (fareType) => (selectionGetter) =>
  compose(
    some(prop(`${fareType}PriceDetails`)),
    chain(prop('fares')),
    prop('flights'),
    selectionGetter
  );

const hasRegularFareFare = hasFare('regular');

const hasWdcFare = hasFare('wdc');

const hasOutboundRegularFare = hasRegularFareFare(outboundSelection);

export const hasOutboundWdcFare = hasWdcFare(outboundSelection);

export const hasReturnRegularFare = hasRegularFareFare(returnSelection);

const hasReturnWdcFare = hasWdcFare(returnSelection);

/**
 * @type {(state: State) => boolean}
 */
export const isRegularFareTypeAvailable = either(
  hasOutboundRegularFare,
  hasReturnRegularFare
);

/**
 * @type {(state: State) => boolean}
 */
export const isWdcFareTypeAvailable = (state) => {
  if (
    isDomestic(state) &&
    isWdcPremiumEnabled(state) &&
    (!userGetters.hasAnyDomesticMembership(state) ||
      (userGetters.hasStandardItalyMembership(state) &&
        numberOfIndependentPassengers(state) > WDC_MAX_PASSENGER_COUNT_STANDARD))
  )
    return false;

  return (
    (hasOutboundWdcFare(state) || hasReturnWdcFare(state)) &&
    numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_GROUP
  );
};

/**
 * @type {(state: State) => string}
 */
const flightSelectPriceNotAvailableLabel = (state) => {
  return !coreBookingGetters.isFlightChangeOrRebookFlow(state)
    ? 'flight-select-flight-price-wdc-not-available-above-7-passengers-redesign'
    : 'flight-select-flight-price-wdc-not-available-above-7-passengers';
};

/**
 * @type {(state: State) => string}
 */
export const secondaryFareTypeUnavailableReason = (state) => {
  if (numberOfIndependentPassengers(state) > WDC_MAX_PASSENGER_COUNT_GROUP) {
    return flightSelectPriceNotAvailableLabel(state);
  }

  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) {
    if (itineraryGetters.isWdcBooking(state)) return 'wdc-fare-difference';
    return 'fare-difference';
  }
  if (!isRegularFareTypeAvailable(state)) return 'regular-price-not-available';

  return '';
};

/**
 * @type {(state: State) => boolean}
 */
export const isGroupBooking = flightSelectProp('isGroupBooking');

/**
 * @type {(state: State) => boolean}
 */
export const isNotGroupBooking = complement(isGroupBooking);

/**
 * @type {(state: State) => boolean}
 */
export const isOutboundGroupRequestWarningVisible = (state) => {
  return outboundFlights(state).some(
    (flight) => flight.isGroupSeatRequest && !flight.isDisabled
  );
};

/**
 * @type {(state: State) => boolean}
 */
export const isReturnGroupRequestWarningVisible = (state) => {
  return returnFlights(state).some(
    (flight) => flight.isGroupSeatRequest && !flight.isDisabled
  );
};

/**
 * @type {(state: object) => string}
 */
export const outboundGroupRequestedFlightId = flightSelectProp(
  'outboundSelection.groupRequestedFlightId'
);

/**
 * @type {(state: object) => string}
 */
export const returnGroupRequestedFlightId = flightSelectProp(
  'returnSelection.groupRequestedFlightId'
);

const mapFlightProperties = (stations, state, flight, direction) => {
  const selectedOutboundFlight =
    outboundSelection(state).flights.find(
      propEq('id', selectedOutboundFlightId(state))
    ) || {};
  const outboundArrivalTime = selectedOutboundFlight.arrivalDateTime || null;
  const returnDepartureTime = flight.departureDateTime;
  const isThereEnoughTimeBeforeReturn =
    direction === DIRECTION_RETURN
      ? isThereEnoughTimeBeforeReturnFlight(outboundArrivalTime, returnDepartureTime)
      : true;

  const isPreviouslyBooked =
    bookingGetters[`${direction}ChangedFromFlightId`](state) === flight.id; // flightChange

  const isDisabled =
    Object.values(flight.blockingIssues).some(Boolean) ||
    !isThereEnoughTimeBeforeReturn ||
    isPreviouslyBooked;

  const isPromoPriceEnabledOnAllFares = isPromoPriceEnabledOnAll(flight);
  const isWdcPromoPriceEnabledOnAllFares = isWdcPromoPriceEnabledOnAll(
    isWdcFareTypeAvailable(state),
    isDisabled,
    flight
  );

  const { price, originalPrice, wdcPrice, wdcOriginalPrice } =
    calculateFlightDisplayPrices(
      state,
      isPromoPriceEnabledOnAllFares,
      isWdcPromoPriceEnabledOnAllFares,
      flight,
      direction
    );

  const baseFare = coreBookingGetters.isFlightChangeOrRebookFlow(state)
    ? flight.fares.find((fare) => fare)
    : flight.fares.find(({ soldOut }) => !soldOut);

  const discountDetails = calculateFlightDiscountDetails(
    state,
    isPromoPriceEnabledOnAllFares,
    isWdcPromoPriceEnabledOnAllFares,
    baseFare
  );

  // only Go/Plus supports this but BE returns the same value for all fares plus
  //  we handle this globally not per fare
  const isFamily = baseFare ? baseFare.isFamily : false;

  const fares = mapFareProperties(state, direction, flight.fares);

  const isOpen =
    direction === DIRECTION_OUTBOUND
      ? flight.id === openedOutboundFlightId(state)
      : flight.id === openedReturnFlightId(state);

  const isGroupSeatRequest = flight.isRequest || calculateGroupSeatRequest(state, flight);

  const isGroupSeatRequested =
    direction === DIRECTION_OUTBOUND
      ? outboundGroupRequestedFlightId(state) === flight.id
      : returnGroupRequestedFlightId(state) === flight.id;

  const changedDepartureStationName = getChangedDepartureStationName(
    state,
    stations,
    flight.departureStationIata,
    direction
  );

  const changedArrivalStationName = getChangedArrivalStationName(
    state,
    stations,
    flight.arrivalStationIata,
    direction
  );

  const isMac =
    Boolean(changedDepartureStationName) || Boolean(changedArrivalStationName);

  const isOvernightFlight = !isSameDay(flight.departureDateTime, flight.arrivalDateTime);

  const durationLabel = formatDuration(flight.duration);

  const flightChangeFareId =
    coreBookingGetters.isFlightChangeOrRebookFlow(state) && isNotEmpty(flight.fares)
      ? flight.fares[0].id
      : '';

  const _arrivalStation = {
    iata: flight.arrivalStationIata,
    name: getShortName(stations, flight.arrivalStationIata) || '',
  };

  const _departureStation = {
    iata: flight.departureStationIata,
    name: getShortName(stations, flight.departureStationIata) || '',
  };

  const wdcFare = fares.find(propIsNotEmpty('wdcPriceDetails'));
  const wdc = wdcFare?.wdcPriceDetails?.wdc;

  return {
    ...flight,
    departureStation: _departureStation,
    arrivalStation: _arrivalStation,
    fares,
    direction,
    isPromoPriceEnabledOnAllFares,
    isWdcPromoPriceEnabledOnAllFares,
    isDisabled,
    price,
    originalPrice,
    wdcPrice,
    wdcOriginalPrice,
    discountDetails,
    isFamily,
    isOpen,
    isThereEnoughTimeBeforeReturn,
    isPreviouslyBooked,
    isGroupSeatRequest,
    isGroupSeatRequested,
    isMac,
    changedDepartureStationName,
    changedArrivalStationName,
    isOvernightFlight,
    durationLabel,
    flightChangeFareId,
    wdc,
  };
};

const mapFareProperties = (state, direction, fares) => {
  const selectedFareId =
    direction === DIRECTION_OUTBOUND
      ? selectedOutboundFareId(state)
      : selectedReturnFareId(state);
  return fares.map(mapFare(state, direction, selectedFareId));
};

const mapFare = curry((state, direction, selectedFareId, fare) => {
  const selected = selectedFareId ? fare.id === selectedFareId : false;
  const isPromoEnabled = fare.regularPriceDetails
    ? isNotEmpty(fare.regularPriceDetails.promotionPrice)
    : false;
  const isWdcPromoEnabled = fare.wdcPriceDetails
    ? isNotEmpty(fare.wdcPriceDetails.promotionPrice)
    : false;
  const arePricesHidden =
    isEmpty(fare.regularPriceDetails) && isEmpty(fare.wdcPriceDetails);

  const { price, wdcPrice, originalPrice, wdcOriginalPrice } = calculateFareDisplayPrices(
    state,
    fare,
    direction,
    isPromoEnabled,
    isWdcPromoEnabled
  );

  const discountDetails = calculateFareDiscountDetails(
    state,
    fare,
    isPromoEnabled,
    isWdcPromoEnabled
  );
  const isPercentageDiscount = discountDetails
    ? discountDetails.isPercentageDiscount
    : false;

  return {
    ...fare,
    selected,
    isPromoEnabled,
    isWdcPromoEnabled,
    arePricesHidden,
    price,
    originalPrice,
    wdcPrice,
    wdcOriginalPrice,
    discountDetails,
    isPercentageDiscount,
  };
});

const calculateFareDisplayPrices = (
  state,
  fare,
  direction,
  isPromoEnabled,
  isWdcPromoEnabled
) => {
  // todo: refact, too many similar conditions
  const previouslyBookedPrice =
    bookingGetters[`${direction}ChangedFromFlightFarePrice`](state);
  let price, wdcPrice;
  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) {
    price = fare.regularPriceDetails
      ? calculatePriceDifference(
          fare.regularPriceDetails.farePrice,
          previouslyBookedPrice
        )
      : null;

    wdcPrice = fare.wdcPriceDetails
      ? calculatePriceDifference(fare.wdcPriceDetails.farePrice, previouslyBookedPrice)
      : null;
  } else {
    price = fare.regularPriceDetails
      ? isPromoEnabled
        ? fare.regularPriceDetails.promotionPrice
        : fare.regularPriceDetails.originalPrice
      : null;

    wdcPrice = fare.wdcPriceDetails
      ? isWdcPromoEnabled
        ? fare.wdcPriceDetails.promotionPrice
        : fare.wdcPriceDetails.originalPrice
      : null;
  }

  const originalPrice =
    isPromoEnabled && fare.regularPriceDetails
      ? fare.regularPriceDetails.originalPrice
      : null;
  const wdcOriginalPrice =
    isWdcPromoEnabled && fare.wdcPriceDetails ? fare.wdcPriceDetails.originalPrice : null;

  return {
    price,
    wdcPrice,
    originalPrice,
    wdcOriginalPrice,
  };
};

const calculateFareDiscountDetails = (state, fare, isPromoEnabled, isWdcPromoEnabled) => {
  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) return null;

  let discountDetails = null;

  if (isPromoEnabled) {
    discountDetails = fare.regularPriceDetails.discountDetails;
  } else if (isWdcPromoEnabled) {
    discountDetails = fare.wdcPriceDetails.discountDetails;
  }

  return discountDetails;
};

const calculateFlightDiscountDetails = (
  state,
  isPromoPriceEnabledOnAllFares,
  isWdcPromoPriceEnabledOnAllFares,
  baseFare
) => {
  let discountDetails = null;

  if (isPromoPriceEnabledOnAllFares && isNotEmpty(baseFare)) {
    discountDetails = baseFare.regularPriceDetails?.discountDetails;
  } else if (isWdcPromoPriceEnabledOnAllFares && baseFare) {
    discountDetails = baseFare.wdcPriceDetails?.discountDetails;
  }

  return discountDetails;
};

const calculateFlightDisplayPrices = (
  state,
  isPromoPriceEnabledOnAllFares,
  isWdcPromoPriceEnabledOnAllFares,
  flight,
  direction
) => {
  const lowestPromoPrice = isPromoPriceEnabledOnAllFares
    ? calculateLowestPromoPrice(flight.fares)
    : null;
  const lowestWdcPromoPrice = isWdcPromoPriceEnabledOnAllFares
    ? calculateLowestWdcPromoPrice(flight.fares)
    : null;
  const lowestRegularPrice = hasRegularFares(flight)
    ? calculateLowestRegularPrice(flight.fares)
    : null;
  const lowestWdcPrice =
    isWdcFareTypeAvailable(state) && hasWdcFares(flight)
      ? calculateLowestWdcPrice(flight.fares)
      : null;

  let price, wdcPrice;

  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) {
    const previouslyBookedPrice =
      bookingGetters[`${direction}ChangedFromFlightFarePrice`](state);
    const lowestRegularFarePrice = hasRegularFares(flight)
      ? calculateLowestRegularFarePrice(flight.fares)
      : null;
    price = calculatePriceDifference(lowestRegularFarePrice, previouslyBookedPrice);
    const lowestWdcFarePrice =
      isWdcFareTypeAvailable(state) && hasWdcFares(flight)
        ? calculateLowestWdcFarePrice(flight.fares)
        : null;
    wdcPrice = calculatePriceDifference(lowestWdcFarePrice, previouslyBookedPrice);
  } else {
    price = isPromoPriceEnabledOnAllFares ? lowestPromoPrice : lowestRegularPrice;
    wdcPrice = isWdcPromoPriceEnabledOnAllFares ? lowestWdcPromoPrice : lowestWdcPrice;
  }

  const originalPrice = isPromoPriceEnabledOnAllFares ? lowestRegularPrice : null;
  const wdcOriginalPrice = isWdcPromoPriceEnabledOnAllFares ? lowestWdcPrice : null;

  return {
    price,
    wdcPrice,
    originalPrice,
    wdcOriginalPrice,
  };
};

/**
 * @type {(_path: string) => (fares: Object<string, any>[]) => Price | null}
 */
const calculateLowestPriceOf = (_path) =>
  compose(
    reduce((acc, price) => (!acc || price.amount < acc.amount ? price : acc), null),
    filter(isNotEmpty),
    map(path(_path))
  );

const calculateLowestPromoPrice = calculateLowestPriceOf(
  'regularPriceDetails.promotionPrice'
);

const calculateLowestWdcPromoPrice = calculateLowestPriceOf(
  'wdcPriceDetails.promotionPrice'
);

const calculateLowestRegularPrice = calculateLowestPriceOf(
  'regularPriceDetails.originalPrice'
);

const calculateLowestWdcPrice = calculateLowestPriceOf('wdcPriceDetails.originalPrice');

const calculateLowestRegularFarePrice = calculateLowestPriceOf(
  'regularPriceDetails.farePrice'
);

const calculateLowestWdcFarePrice = calculateLowestPriceOf('wdcPriceDetails.farePrice');

export const isThereEnoughTimeBeforeReturnFlight = (
  outboundArrivalTime,
  returnDepartureTime
) => {
  return outboundArrivalTime
    ? differenceInMinutes(returnDepartureTime, outboundArrivalTime) >=
        MIN_INTERVAL_BEFORE_RETURN_FLIGHT
    : true;
};

// GROUP SEAT REQUEST DISPLAY CALCULATION
// needed to be able to show both requests and prices as options for the user
const calculateGroupSeatRequest = (state, flight) => {
  if (shouldDisplayOutboundGroupRequest(state, flight)) {
    return true;
  } else if (shouldDisplayReturnGroupRequest(state, flight)) {
    return true;
  } else if (shouldRemainOutboundGroupRequest(state, flight)) {
    return true;
  } else if (shouldRemainReturnGroupRequest(state, flight)) {
    return true;
  } else {
    return false;
  }
};

const shouldDisplayOutboundGroupRequest = (state, flight) => {
  return (
    flight.direction === DIRECTION_OUTBOUND &&
    selectedReturnFlightId(state) === returnGroupRequestedFlightId(state) &&
    isNotNil(selectedReturnFlightId(state)) &&
    isNotNil(returnGroupRequestedFlightId(state)) &&
    !outboundGroupRequestedFlightId(state)
  );
};

const shouldDisplayReturnGroupRequest = (state, flight) => {
  return (
    flight.direction === DIRECTION_RETURN &&
    selectedOutboundFlightId(state) === outboundGroupRequestedFlightId(state) &&
    isNotNil(selectedOutboundFlightId(state)) &&
    isNotNil(outboundGroupRequestedFlightId(state)) &&
    !returnGroupRequestedFlightId(state)
  );
};

const shouldRemainOutboundGroupRequest = (state, flight) => {
  return (
    flight.direction === DIRECTION_OUTBOUND &&
    flight.id === selectedOutboundFlightId(state) &&
    flight.id === outboundGroupRequestedFlightId(state) &&
    isNotNil(selectedOutboundFlightId(state)) &&
    isNotNil(outboundGroupRequestedFlightId(state))
  );
};

const shouldRemainReturnGroupRequest = (state, flight) => {
  return (
    flight.direction === DIRECTION_RETURN &&
    flight.id === selectedReturnFlightId(state) &&
    flight.id === returnGroupRequestedFlightId(state) &&
    isNotNil(selectedReturnFlightId(state)) &&
    isNotNil(returnGroupRequestedFlightId(state))
  );
};

const calculatePriceDifference = (newPrice, oldPrice) => {
  if (!newPrice) return {};
  const difference = (newPrice?.amount ?? 0) - (oldPrice?.amount ?? 0);
  return {
    amount: difference < 0 ? 0 : difference,
    currencyCode: oldPrice?.currencyCode ?? FALLBACK_CURRENCY_CODE,
  };
};

const isPromoPriceEnabledOnAll = (flight) => {
  return hasRegularFares(flight)
    ? flight.fares
        .filter((fare) => !fare.soldOut)
        .every((fare) => isNotNil(fare?.regularPriceDetails?.promotionPrice))
    : false;
};

const isWdcPromoPriceEnabledOnAll = (wdcFareTypeAvailable, isDisabled, flight) => {
  return wdcFareTypeAvailable && !isDisabled && hasWdcFares(flight)
    ? flight.fares
        .filter((fare) => !fare.soldOut)
        .every((fare) => isNotNil(fare?.wdcPriceDetails?.promotionPrice))
    : false;
};

const hasRegularFares = (flight) => {
  return (
    isNotEmpty(flight.fares) &&
    Object.values(flight.blockingIssues).every((issue) => !issue) &&
    flight.fares.some((fare) => fare.regularPriceDetails)
  );
};

const hasWdcFares = (flight) => {
  return (
    isNotEmpty(flight.fares) &&
    Object.values(flight.blockingIssues).every((issue) => !issue) &&
    flight.fares.some((fare) => fare.wdcPriceDetails)
  );
};

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcDiscounts = flightSelectProp('wdcDiscounts');

/**
 * @type {(state: State) => string}
 */
export const selectedOutboundFlightId = flightSelectProp(
  'outboundSelection.selectedFlightId'
);

/**
 * @type {(state: State) => string}
 */
export const selectedOutboundFareId = flightSelectProp(
  'outboundSelection.selectedFareId'
);

/**
 * @type {(state: State) => string}
 */
export const selectedReturnFlightId = flightSelectProp(
  'returnSelection.selectedFlightId'
);

/**
 * @type {(state: State) => string}
 */
export const selectedReturnFareId = flightSelectProp('returnSelection.selectedFareId');

/**
 * @type {(state: State) => string}
 */
export const openedOutboundFlightId = flightSelectProp('outboundSelection.openFlightId');

/**
 * @type {(state: State) => string}
 */
export const openedReturnFlightId = flightSelectProp('returnSelection.openFlightId');

/**
 * @type {(state: State) => Object<Direction, any>}
 */
export const selectedFlights = (state) => {
  return {
    [DIRECTION_OUTBOUND]: selectedOutboundFlight(state),
    ...(isRoundTrip(state) ? { [DIRECTION_RETURN]: selectedReturnFlight(state) } : {}),
  };
};

/**
 * @type {(flightsGetter: (state: State) => [], selectedFlightIdGetter: (state: State) => string) => (state: State) => Object<string, any>}
 */
const _flight = (flightsGetter, selectedFlightIdGetter) => (state) => {
  const flights = flightsGetter(state);
  const selectedFlightId = selectedFlightIdGetter(state);
  return flights.find(propEq('id', selectedFlightId)) || null;
};

export const selectedOutboundFlight = _flight(outboundFlights, selectedOutboundFlightId);

export const selectedReturnFlight = _flight(returnFlights, selectedReturnFlightId);

/**
 * @type {(state: state) => boolean}
 */
export const hasSelectedOutboundFlight = compose(isNotEmpty, selectedOutboundFlight);

/**
 * @type {(state: state) => boolean}
 */
export const hasSelectedReturnFlight = compose(isNotEmpty, selectedReturnFlight);

export const openedOutboundFlight = _flight(outboundFlights, openedOutboundFlightId);

export const openedReturnFlight = _flight(returnFlights, openedReturnFlightId);

export const selectedOutboundFlightArrivalStationName = (state) =>
  selectedOutboundFlight(state)?.arrivalStation?.name ?? '';

export const selectedOutboundFlightDepartureStationNameWithIata = (state) =>
  selectedOutboundFlight(state)
    ? `${selectedOutboundFlight(state).departureStation.name} (${
        selectedOutboundFlight(state).departureStation.iata
      })`
    : '';

export const selectedOutboundFlightArrivalStationNameWithIata = (state) =>
  selectedOutboundFlight(state)
    ? `${selectedOutboundFlight(state).arrivalStation.name} (${
        selectedOutboundFlight(state).arrivalStation.iata
      })`
    : '';

export const selectedReturnFlightDepartureStationNameWithIata = (state) =>
  selectedReturnFlight(state)
    ? `${selectedReturnFlight(state).departureStation.name} (${
        selectedReturnFlight(state).departureStation.iata
      })`
    : '';

export const selectedReturnFlightArrivalStationNameWithIata = (state) =>
  selectedReturnFlight(state)
    ? `${selectedReturnFlight(state).arrivalStation.name} (${
        selectedReturnFlight(state).arrivalStation.iata
      })`
    : '';

/**
 * @type {(state: State) => boolean}
 */
export const isGroupSeatRequested = (state) => {
  const isOutboundRequested = Boolean(outboundGroupRequestedFlightId(state));
  const isReturnRequested = Boolean(returnGroupRequestedFlightId(state));
  return isRoundTrip(state)
    ? isOutboundRequested || isReturnRequested
    : isOutboundRequested;
};

/**
 * @type {(selectedFlightGetter: (state: State) => Object<string, any>) => (state: State) => []}
 */
const _selectedFlightFares = (selectedFlightGetter) => (state) => {
  const selectedFlight = selectedFlightGetter(state);
  return (selectedFlight || {}).fares || [];
};

export const selectedOutboundFlightFares = _selectedFlightFares(selectedOutboundFlight);

export const selectedReturnFlightFares = _selectedFlightFares(selectedReturnFlight);

/**
 * @type {(selectedFlightFaresGetter: (state: State) => [], selectedFareIdGetter: (state: State) => string) => (state: State) => Object<string, any>}
 */
const _selectedFare = (selectedFlightFaresGetter, selectedFareIdGetter) => (state) => {
  const selectedFlightFares = selectedFlightFaresGetter(state);
  const selectedFareId = selectedFareIdGetter(state);
  return selectedFlightFares.find(propEq('id', selectedFareId)) || null;
};

export const selectedOutboundFare = _selectedFare(
  selectedOutboundFlightFares,
  selectedOutboundFareId
);

export const selectedReturnFare = _selectedFare(
  selectedReturnFlightFares,
  selectedReturnFareId
);

export const outboundUpsellToBundle = (state) => {
  if (
    hasSelectedOutboundFare(state) &&
    isBasicBundle(selectedOutboundFare(state)?.bundle?.code)
  ) {
    return featureGetters.isSmartBundleEnabled(state) ? BUNDLE_SMART : BUNDLE_MIDDLE;
  } else if (
    hasSelectedOutboundFare(state) &&
    (isSmartBundle(selectedOutboundFare(state)?.bundle?.code) ||
      isMiddleBundle(selectedOutboundFare(state)?.bundle?.code) ||
      isMiddleTwoBundle(selectedOutboundFare(state)?.bundle?.code))
  ) {
    return BUNDLE_PLUS;
  } else {
    return '';
  }
};

export const returnUpsellToBundle = (state) => {
  if (
    hasSelectedReturnFare(state) &&
    isBasicBundle(selectedReturnFare(state)?.bundle?.code)
  ) {
    return featureGetters.isSmartBundleEnabled(state) ? BUNDLE_SMART : BUNDLE_MIDDLE;
  } else if (
    hasSelectedReturnFare(state) &&
    (isSmartBundle(selectedReturnFare(state)?.bundle?.code) ||
      isMiddleBundle(selectedReturnFare(state)?.bundle?.code) ||
      isMiddleTwoBundle(selectedReturnFare(state)?.bundle?.code))
  ) {
    return BUNDLE_PLUS;
  } else {
    return '';
  }
};

export const outboundFareToUpgrade = (state, bundleCode = '') =>
  selectedOutboundFlightFares(state).find(
    pathEq(
      'bundle.code',
      isNotEmpty(bundleCode) ? bundleCode : outboundUpsellToBundle(state)
    )
  );
export const returnFareToUpgrade = (state, bundleCode = '') =>
  selectedReturnFlightFares(state).find(
    pathEq(
      'bundle.code',
      isNotEmpty(bundleCode) ? bundleCode : returnUpsellToBundle(state)
    )
  );

export const outboundUpsellToPriceDifference = (state) => {
  if (
    !hasSelectedOutboundFare(state) ||
    isEmpty(outboundUpsellToBundle(state)) ||
    coreBookingGetters.isFlightChangeOrRebookFlow(state)
  ) {
    return {};
  }
  const fareToUpgrade = outboundFareToUpgrade(state);
  if (!fareToUpgrade || fareToUpgrade.soldOut) return {};
  return getUpsellPriceDifferences(
    isWdcFareTypeSelected(state),
    fareToUpgrade,
    selectedOutboundFare(state)
  );
};

export const returnUpsellToPriceDifference = (state) => {
  if (
    !hasSelectedReturnFare(state) ||
    isEmpty(returnUpsellToBundle(state)) ||
    coreBookingGetters.isFlightChangeOrRebookFlow(state)
  ) {
    return {};
  }
  const fareToUpgrade = returnFareToUpgrade(state);
  if (!fareToUpgrade || fareToUpgrade.soldOut) return {};
  return getUpsellPriceDifferences(
    isWdcFareTypeSelected(state),
    fareToUpgrade,
    selectedReturnFare(state)
  );
};

const getUpsellPriceDifferences = (
  isWdcFareTypeSelected,
  fareToUpgrade,
  selectedFlightFares
) => {
  const selectedFarePrice =
    selectedFlightFares[`${isWdcFareTypeSelected ? 'wdcPrice' : 'price'}`];
  const fareToUpgradePrice =
    fareToUpgrade[`${isWdcFareTypeSelected ? 'wdcPrice' : 'price'}`];
  const selectedFareOriginalPrice =
    selectedFlightFares[
      `${isWdcFareTypeSelected ? 'wdcOriginalPrice' : 'originalPrice'}`
    ];
  const fareToUpgradeOriginalPrice =
    fareToUpgrade[`${isWdcFareTypeSelected ? 'wdcOriginalPrice' : 'originalPrice'}`];
  return {
    discountedPriceDifference: getPrice(
      fareToUpgradePrice.amount - selectedFarePrice.amount,
      fareToUpgradePrice.currencyCode
    ),
    ...(fareToUpgradeOriginalPrice && {
      originalPriceDifference: getPrice(
        fareToUpgradeOriginalPrice.amount -
          (selectedFareOriginalPrice?.amount ?? selectedFarePrice.amount),
        fareToUpgradeOriginalPrice.currencyCode
      ),
    }),
  };
};

export const outboundFareUpgradePrice = flightSelectProp(
  'upsell.outboundFareUpgradePrice'
);
export const returnFareUpgradePrice = flightSelectProp('upsell.returnFareUpgradePrice');
export const outboundFareUpgradeBundle = flightSelectProp(
  'upsell.outboundFareUpgradeBundle'
);
export const returnFareUpgradeBundle = flightSelectProp('upsell.returnFareUpgradeBundle');
export const isOutboundFareUpgraded = flightSelectProp('upsell.isOutboundFareUpgraded');
export const isReturnFareUpgraded = flightSelectProp('upsell.isReturnFareUpgraded');

/**
 * @type {(selectedFareGetter: (state: State) => Object<string, any>) => (state: State) => object}
 */
const _fareLockPrice = (selectedFareGetter) => (state) => {
  const selectedFare = selectedFareGetter(state);
  if (!selectedFare) return null;

  return isWdcFareTypeSelected(state)
    ? selectedFare.wdcPriceDetails.fareLockPrice || null
    : selectedFare.regularPriceDetails.fareLockPrice || null;
};

const outboundFareLockPrice = _fareLockPrice(selectedOutboundFare);

const returnFareLockPrice = _fareLockPrice(selectedReturnFare);

/**
 * @type {(state: State) => boolean}
 */
const hasOutboundFareLockPrice = compose(isNotEmpty, outboundFareLockPrice);

/**
 * @type {(state: State) => boolean}
 */
const hasReturnFareLockPrice = compose(isNotEmpty, returnFareLockPrice);

/**
 * @type {(selectedFareGetter: (state: State) => Object<string, any>) => (state: State) => string}
 */
const _selectedFareSellKey = (selectedFareGetter) => (state) => {
  const selectedFare = selectedFareGetter(state);
  if (isNil(selectedFare)) return '';

  return isWdcFareTypeSelected(state)
    ? selectedFare.wdcPriceDetails.fareSellKey
    : selectedFare.regularPriceDetails.fareSellKey;
};

export const selectedOutboundFareSellKey = _selectedFareSellKey(selectedOutboundFare);

export const selectedReturnFareSellKey = _selectedFareSellKey(selectedReturnFare);

/**
 * @type {(fareGetter: (state: State) => Object<string, any>) => (state: State) => string}
 */
const _selectedBundleCode = (selectedFareGetter) =>
  compose(pathOr('', 'bundle.code'), orElse({}), selectedFareGetter);

export const selectedOutboundBundleCode = _selectedBundleCode(selectedOutboundFare);

export const selectedReturnBundleCode = _selectedBundleCode(selectedReturnFare);

/**
 * @type {(state: State) => Boolean}
 */
export const isOutboundBasicPreselected = flightSelectProp('isOutboundBasicPreselected');

/**
 * @type {(state: State) => Boolean}
 */
export const isReturnBasicPreselected = flightSelectProp('isReturnBasicPreselected');

/**
 * @type {(state: State) => boolean}
 */
export const hasSelectedOutboundFare = (state) =>
  isNotNil(selectedOutboundFareId(state)) && !isOutboundBasicPreselected(state);

/**
 * @type {(state: State) => boolean}
 */
export const hasSelectedReturnFare = (state) =>
  isNotNil(selectedReturnFareId(state)) && !isReturnBasicPreselected(state);

/**
 * @type {(state: State) => boolean}
 */
export const isFareLockAvailable = (state) => {
  if (bookingGetters.isWdcAdded(state)) return false;
  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) return false;
  if (coreBookingGetters.isConnectedBookingFlow(state)) return false;
  if (!hasSelectedOutboundFare(state)) return false;
  if (!hasOutboundFareLockPrice(state)) return false;
  if (isWdcMembershipAddedToCart(state)) return false;
  if (isRoundTrip(state) && !hasReturnFareLockPrice(state)) return false;

  if (
    (hasApplicableStandardWdcMembership(state) && hasToBuyWdcMembership(state)) ||
    (hasApplicableGroupWdcMembership(state) && hasToBuyWdcMembership(state)) ||
    hasToBuyWdcMembership(state)
  ) {
    return false;
  }

  return isRoundTrip(state)
    ? hasSelectedOutboundFlight(state) &&
        hasSelectedOutboundFare(state) &&
        hasSelectedReturnFlight(state) &&
        hasSelectedReturnFare(state)
    : hasSelectedOutboundFlight(state) && hasSelectedOutboundFare(state);
};

export const isSinglePassenger = (state) => numberOfIndependentPassengers(state) === 1;

/**
 * @type {(state: State) => boolean}
 */
export const hasToBuyWdcMembership = (state) => {
  const isWdcSelected = isWdcFareTypeSelected(state);
  if (isEmpty(wdcMemberships(state))) return false;

  if (
    isWdcPremiumEnabled(state) &&
    !isDomestic(state) &&
    !userGetters.hasPremiumPlusWdcMembership(state) &&
    isWdcSelected &&
    !isWdcMembershipAddedToCart(state) &&
    !isContinueWithCurrentWdcMembership(state)
  ) {
    return true;
  }

  return (
    (isWdcSelected &&
      !hasApplicableWdcMembership(state) &&
      !isWdcMembershipAddedToCart(state)) ||
    (isWdcSelected &&
      hasApplicableStandardWdcMembership(state) &&
      numberOfIndependentPassengers(state) > WDC_MAX_PASSENGER_COUNT_STANDARD &&
      numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_GROUP &&
      !isWdcMembershipAddedToCart(state))
  );
};

export const hasApplicableStandardWdcMembership = (state) => {
  if (isWddcItalyFareAvailable(state))
    return userGetters.hasStandardItalyMembership(state);
  if (!isDomestic(state)) return userGetters.hasStandardWdcMembership(state);
  return false;
};

export const hasApplicableGroupWdcMembership = (state) => {
  if (isWddcItalyFareAvailable(state)) return userGetters.hasGroupItalyMembership(state);
  if (!isDomestic(state)) return userGetters.hasGroupWdcMembership(state);
  return false;
};

export const hasApplicablePremiumWdcMembership = (state) => {
  if (isDomestic(state)) return false;
  return userGetters.hasPremiumWdcMembership(state);
};

export const hasApplicablePremiumPlusWdcMembership = (state) => {
  if (isDomestic(state)) return false;
  return userGetters.hasPremiumPlusWdcMembership(state);
};

export const hasApplicableStandardCobrandedWdcMembership = (state) => {
  if (isDomestic(state)) return false;
  return userGetters.hasStandardCobrandedWdcMembership(state);
};

export const hasApplicableWdcMembership = (state) =>
  isNotEmpty(applicableWdcMembership(state));

export const applicableWdcMembership = (state) => {
  if (isWddcItalyFareAvailable(state)) {
    return (
      userGetters
        .activeWdcMemberships(state)
        .find(({ countryCode }) => isCountryCodeItaly(countryCode)) || {}
    );
  }
  if (!isDomestic(state)) {
    return (
      userGetters.activeWdcMemberships(state).find(({ isDomestic }) => !isDomestic) || {}
    );
  }
  return {};
};

/**
 * @type {(state: State) => boolean}
 */
const isStandardWdcMembershipRecommended = (state) => {
  return numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_STANDARD;
};

/**
 * @type {(state: State) => boolean}
 */
export const isGroupWdcMembershipRecommended = (state) => {
  return (
    numberOfIndependentPassengers(state) > WDC_MAX_PASSENGER_COUNT_STANDARD &&
    numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_GROUP
  );
};

/**
 * @type {(state: State) => boolean}
 */
const isStandardOrGroupWdcMembershipRecommended = either(
  isStandardWdcMembershipRecommended,
  isGroupWdcMembershipRecommended
);

/**
 * @type {(state: State) => boolean}
 */
const hasStandardWdcMembershipAndStandardIsRecommended = both(
  hasApplicableStandardWdcMembership,
  isStandardWdcMembershipRecommended
);

/**
 * @type {(state: State) => boolean}
 */
const hasWdcGroupMembershipAndStandardOrGroupIsRecommended = both(
  hasApplicableGroupWdcMembership,
  isStandardOrGroupWdcMembershipRecommended
);

/**
 * @type {(state: State) => []}
 */
export const wdcMemberships = flightSelectProp('wdcMemberships');

/**
 * @type {(state: State) => string}
 */
export const selectedWdcMembership = flightSelectProp('selectedWdcMembership');

/**
 * @type {(state: State) => boolean}
 */
export const isGroupWdcMembershipSelected = flightSelectPropEq(
  'selectedWdcMembership',
  'GROUP'
);

/**
 * @type {(state: State) => boolean}
 */
export const isWdcTermsAgreed = flightSelectProp('isWdcTermsAgreed');

/**
 * @type {(state: State) => boolean}
 */
export const isEmailSubscriptionChecked = flightSelectProp('isEmailSubscriptionChecked');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcMembershipPrices = flightSelectProp('wdcMembershipPrices');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcPromotedMembershipPrices = flightSelectProp(
  'wdcPromotedMembershipPrices'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcPromotedMembershipPercentages = flightSelectProp(
  'wdcPromotedMembershipPercentages'
);

/**
 * @type {(state: State) => boolean}
 */
export const isWdcMembershipAddedToCart = flightSelectProp('isWdcMembershipAddedToCart');

/**
 * @type {(state: State) => boolean}
 */
export const isWdcPromotionAvailable = (state) => {
  if (coreBookingGetters.isFlightChangeOrRebookFlow(state)) return false;
  if (!isWdcFareTypeSelected(state)) return false;
  if (isEmpty(wdcMemberships(state))) return false;

  if (isWdcPremiumEnabled(state)) {
    return (
      (isWdcFareTypeSelected(state) ||
        (userGetters.isLoggedIn(state) && userGetters.hasFreeWdc(state))) &&
      selectedOutboundFareId(state) &&
      !isDomestic(state) &&
      !userGetters.hasPremiumPlusWdcMembership(state) &&
      (isRoundTrip(state) ? selectedReturnFareId(state) : true) &&
      !hasReasonNotShowWdcPremium(state)
    );
  }

  return (
    (isWdcFareTypeSelected(state) ||
      (userGetters.isLoggedIn(state) &&
        ((userGetters.hasFreeWdc && !userGetters.hasAnyWdcMembership(state)) ||
          (numberOfIndependentPassengers(state) > 2 &&
            hasApplicableStandardWdcMembership(state))))) &&
    selectedOutboundFareId(state) &&
    (isRoundTrip(state) ? selectedReturnFareId(state) : true) &&
    !hasReasonNotShowWdcPromotion(state)
  );
};

export const hasReasonNotShowWdcPromotion = (state) => {
  return (
    (numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_STANDARD &&
      hasApplicableStandardWdcMembership(state)) ||
    numberOfIndependentPassengers(state) > WDC_MAX_PASSENGER_COUNT_GROUP ||
    coreBookingGetters.isFlightChangeOrRebookFlow(state) ||
    hasApplicableGroupWdcMembership(state) ||
    !hasSelectedOutboundFare(state) ||
    (returnFlights(state).length > 0 && !hasSelectedReturnFare(state)) ||
    (summaryGetters.hasWdcMembershipFee(state) && isWdcAddedOnTargetedModal(state)) ||
    (summaryGetters.hasWdcRenewalFee(state) && isWdcAddedOnTargetedModal(state))
  );
};

export const hasReasonNotShowWdcPremium = (state) => {
  return (
    coreBookingGetters.isFlightChangeOrRebookFlow(state) ||
    !hasSelectedOutboundFare(state) ||
    (returnFlights(state).length > 0 && !hasSelectedReturnFare(state))
  );
};

export const isWdcAddedOnTargetedModal = flightSelectProp('isWdcAddedOnTargetedModal');

/**
 * @type {(selectedFareGetter: (state: State) => Object<string, any>) => (state: State) => Object<string, any>}
 */
const _wdcDiscountPrice = (selectedFareGetter) => (state) => {
  const selectedFare = selectedFareGetter(state);
  if (isNil(selectedFare) || isNil(selectedFare.price)) return null;

  // discount = original price - selected wdcPrice
  const originalPrice = selectedFare.price;
  const wdcPrice = selectedFare.wdcPrice;
  const different = originalPrice.amount - wdcPrice.amount;

  return {
    amount: different,
    currencyCode: originalPrice.currencyCode,
  };
};

export const outboundWdcDiscountPrice = _wdcDiscountPrice(selectedOutboundFare);

export const returnWdcDiscountPrice = _wdcDiscountPrice(selectedReturnFare);

export const totalWdcDiscount = (state) => {
  const numOfIndependentPax = numberOfIndependentPassengers(state);
  let total = outboundWdcDiscountPrice(state)
    ? outboundWdcDiscountPrice(state).amount * numOfIndependentPax
    : 0;
  if (isRoundTrip(state) && returnWdcDiscountPrice(state)) {
    total += returnWdcDiscountPrice(state).amount * numOfIndependentPax;
  }

  return {
    amount: total,
    currencyCode: outboundWdcDiscountPrice(state)
      ? outboundWdcDiscountPrice(state).currencyCode
      : '',
  };
};

/**
 * @type {(state: State) => boolean}
 */
export const hasW6CarriersInOutboundFlights = (state) =>
  state.flightSelect.outboundSelection?.flights.some(
    ({ carrierCode }) => carrierCode.toLowerCase() === CARRIER_WIZZ_HUN
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasWdcSaving = compose(gt(__, 0), prop('amount'), totalWdcDiscount);

export const outboundFlightDates = flightSelectProp(
  'outboundSelection.flightDatePicker.dates'
);

export const returnFlightDates = flightSelectProp(
  'returnSelection.flightDatePicker.dates'
);

/**
 * @type {(state: State) => boolean}
 */
const hasOutboundFlightDates = compose(isNotEmpty, outboundFlightDates);

/**
 * @type {(state: State) => boolean}
 */
const hasReturnFlightDates = compose(isNotEmpty, returnFlightDates);

/**
 * @type {(hasFlightDatesGetter: (state: State) => boolean) => (state: State) => boolean}
 */
const isFlightDatePickerVisible = (hasFlightDatesGetter) =>
  allPass(
    hasFlightDatesGetter,
    coreBookingGetters.isNotFlightChangeOrRebookFlow,
    coreBookingGetters.isNotConnectedBookingFlow,
    isNotInvalidMarket,
    hasNoRescueFareCode
  );

export const isOutboundFlightDatePickerVisible =
  isFlightDatePickerVisible(hasOutboundFlightDates);

export const isReturnFlightDatePickerVisible =
  isFlightDatePickerVisible(hasReturnFlightDates);

export const datePickedDates = (state) => {
  return {
    outbound: outboundFlightDates(state),
    return: returnFlightDates(state),
  };
};

export const flightDatesDifference = flightSelectProp('flightDatesDifference');

export const isWdcPromotionDetailsVisible = flightSelectProp(
  'isWdcPromotionDetailsVisible'
);

const isArrivalStationChanged = (state, stationIata) => {
  return stationIata !== arrivalStation(state).iata && !isArrivalStationFake(state);
};

const isDepartureStationChanged = (state, stationIata) => {
  return stationIata !== departureStation(state).iata && !isDepartureStationFake(state);
};

const getChangedDepartureStationName = (state, stations, stationIata, direction) => {
  return (direction === DIRECTION_OUTBOUND &&
    isDepartureStationChanged(state, stationIata)) ||
    (direction === DIRECTION_RETURN && isArrivalStationChanged(state, stationIata))
    ? getShortName(stations, stationIata)
    : null;
};

const getChangedArrivalStationName = (state, stations, stationIata, direction) => {
  return (direction === DIRECTION_OUTBOUND &&
    isArrivalStationChanged(state, stationIata)) ||
    (direction === DIRECTION_RETURN && isDepartureStationChanged(state, stationIata))
    ? getShortName(stations, stationIata)
    : null;
};

export const isSelectedOutboundFlightMac = (state) => {
  return (selectedOutboundFlight(state) || {}).isMac || false;
};

export const isSelectedReturnFlightMac = (state) => {
  return (selectedReturnFlight(state) || {}).isMac || false;
};

/**
 * @type {(state: State) => boolean}
 */
export const hasSelectedFareOnEitherFlight = either(
  hasSelectedOutboundFare,
  hasSelectedReturnFare
);

/**
 * @type {(state: State) => boolean}
 */
export const hasSelectedFareOnAllFlights = (state) => {
  return [
    hasSelectedOutboundFare(state),
    ...(isRoundTrip(state) ? [hasSelectedReturnFare(state)] : []),
  ].every(Boolean);
};

/**
 * @type {(state: State) => boolean}
 */
export const areSelectedFareBundlesBelowPlus = (state) =>
  isNotEmpty(selectedBundleCodes(state)) &&
  selectedBundleCodes(state).every(isNotPlusBundle);

/**
 * @type {(state: State) => boolean}
 */
export const isPlusFareBundleSelectedOnEitherFlight = (state) =>
  isNotEmpty(selectedBundleCodes(state)) && selectedBundleCodes(state).some(isPlusBundle);

/**
 * @type {(state: State) => string[]}
 */
const selectedBundleCodes = (state) => {
  return [
    ...(hasSelectedOutboundFare(state) ? [selectedOutboundBundleCode(state)] : []),
    ...(isRoundTrip(state) && hasSelectedReturnFare(state)
      ? [selectedReturnBundleCode(state)]
      : []),
  ];
};

/**
 * @type {(state: State) => boolean}
 */
export const isWizzFlexPossiblyAvailable = allPass(
  coreBookingGetters.isNotFlightChangeOrRebookFlow,
  isNotGroupBooking,
  hasSelectedFareOnAllFlights,
  areSelectedFareBundlesBelowPlus,
  complement(featureGetters.isSplitPaxEnabled)
);

/**
 * @type {(state: State) => boolean}
 */
export const isWizzFlexAvailable = allPass(
  isWizzFlexPossiblyAvailable,
  isSelectFareNotLoading,
  hasNoSelectFareErrors,
  ancillariesGetters.isWizzFlexSelectable
);

/**
 * @type {(state: State) => boolean}
 */
export const isStandardWdcMembershipDisabled = compose(
  gt(__, WDC_MAX_PASSENGER_COUNT_STANDARD),
  numberOfIndependentPassengers
);

/**
 * @type {(state: State) => string}
 */
export const outboundFlightChangeBundle = (state) => {
  if (!coreBookingGetters.isFlightChangeOrRebookFlow(state)) return '';
  const flight = state.flightSelect.outboundSelection.flights.find((flight) =>
    isNotEmpty(flight.fares)
  );
  return flight ? flight.fares.find((fare) => fare.bundle.code).bundle.code : '';
};

/**
 * @type {(state: State) => string}
 */
export const returnFlightChangeBundle = (state) => {
  if (!coreBookingGetters.isFlightChangeOrRebookFlow(state)) return '';
  const flight = state.flightSelect.returnSelection.flights.find((flight) =>
    isNotEmpty(flight.fares)
  );
  return flight ? flight.fares.find((fare) => fare.bundle.code).bundle.code : '';
};

export const isOutboundAccessibilityEnabled = flightSelectProp(
  'outboundSelection.isAccessibilityEnabled'
);

export const outboundImportantInformation = flightSelectProp(
  'outboundSelection.importantInformation'
);

export const returnImportantInformation = flightSelectProp(
  'returnSelection.importantInformation'
);

export const shouldDisplayHasToBuyWdcError = (state) => {
  return hasToBuyWdcMembership(state) && isSubmitInProgress(state);
};

/**
 * @type {(state: State) => boolean}
 */
const isWdcHighlighted = flightSelectProp('isWdcHighlighted');

/**
 * @type {(state: State) => boolean}
 */
export const isWdcRequired = both(shouldDisplayHasToBuyWdcError, isWdcHighlighted);

/**
 * @type {(state: State) => boolean}
 */
export const isInitialized = flightSelectProp('isInitialized');

/**
 * @type {(state: State) => boolean}
 */
export const isNotInitialized = complement(isInitialized);

export const isSubmitAttempted = flightSelectProp('isSubmitAttempted');

export const isBundleUpgraded = flightSelectProp('isBundleUpgraded');

/**
 * @type {(state: State) => Boolean}
 */
export const isFareChartPriceVisible = flightSelectProp('isPriceVisibleOnFareChart');

const isWddcItalyFareAvailable = (state) => {
  if (!isDomestic(state)) return false;
  return outboundFlights(state).some((flight) => {
    return flight.fares.some((fare) =>
      isCountryCodeItaly(fare.wdcPriceDetails?.wdc?.countryCode)
    );
  });
};

export const canInitializeFlashPromos = (state) =>
  (!summaryGetters.isFareLockAdded(state) &&
    coreBookingGetters.isNewBookingFlow(state) &&
    isBasicFareBundleSelectedOnAllFlights(state)) ||
  (coreBookingGetters.isFareLockFinalizeFlow(state) &&
    bookingGetters.isBasicFareBundleLockedOnAllFlights(state));

/**
 * @type {(state: State) => boolean}
 */
const isBasicFareBundleSelectedOnAllFlights = (state) =>
  isNotEmpty(selectedBundleCodes(state)) &&
  selectedBundleCodes(state).every(isBasicBundle);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const outboundCo2Emission = flightSelectProp('outboundCo2Emission');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const returnCo2Emission = flightSelectProp('returnCo2Emission');

/**
 * @type {(state: State) => Boolean}
 */
export const isAllFareBenefitsVisible = flightSelectProp('isAllFareBenefitsVisible');

const isFareSelected = (state) => {
  if (!isRoundTrip(state))
    return (
      hasSelectedOutboundFare(state) || isNotNil(outboundGroupRequestedFlightId(state))
    );

  return (
    (hasSelectedOutboundFare(state) || isNotNil(outboundGroupRequestedFlightId(state))) &&
    (hasSelectedReturnFare(state) || isNotNil(returnGroupRequestedFlightId(state)))
  );
};

/**
 * @type {(state: State) => Boolean}
 */
export const isContinueButtonVisible = (state) => {
  if (
    !featureGetters.isBundlePriceComparedToBasicEnabled(state) ||
    coreBookingGetters.isFlightChangeOrRebookFlow(state)
  )
    return true;

  return isFareSelected(state);
};

/**
 * @type {(state: State) => boolean}
 */
export const isInitPassengersLoading = flightSelectProp('isInitPassengersLoading');
