import path from '~/utils/fp/path';
import curry from '~/utils/fp/curry';
import both from '~/utils/fp/both';
import compose from '~/utils/fp/compose';
import includes from '~/utils/fp/includes';

const volatileProp = curry((prop, state) => path(prop, state.volatile));

export const isNewsletterConsentModalVisible = volatileProp(
  'user.isNewsletterConsentModalVisible'
);

export const isSeatSelectionModalVisible = volatileProp(
  'itinerary.isSeatSelectionModalVisible'
);

export const isSeatSelectionExitIntentModalVisible = volatileProp(
  'itinerary.isSeatSelectionExitIntentModalVisible'
);

export const isSeatSelectionHasUnconfirmedChangesFromPrevious = volatileProp(
  'itinerary.isSeatSelectionHasUnconfirmedChangesFromPrevious'
);

export const isBookingFlowVisible = volatileProp('states.isBookingFlowVisible');

export const isBookingFlowLoaded = volatileProp('states.isBookingFlowLoaded');

/**
 * @type {(state: State) => boolean}
 */
export const isBookingFlow = both(isBookingFlowVisible, isBookingFlowLoaded);

export const isResetPasswordForAgency = volatileProp('states.isResetPasswordForAgency');

export const isSummaryOnMobileVisible = volatileProp('states.isSummaryOnMobileVisible');

export const isAirportTransferModalVisible = volatileProp(
  'itineraryOverview.isAirportTransferModalVisible'
);

export const isAcordServiceModalVisible = volatileProp(
  'itineraryOverview.isAcordServiceModalVisible'
);

export const isAirportParkingModalVisible = volatileProp(
  'itineraryOverview.isAirportParkingModalVisible'
);

export const isAcordServiceComparisonModalVisible = volatileProp(
  'itineraryOverview.isAcordServiceComparisonModalVisible'
);

export const hasToReOpenUpsellModal = volatileProp(
  'itineraryOverview.hasToReOpenUpsellModal'
);

export const isModifyServicesModalVisible = volatileProp(
  'itineraryOverview.isModifyServicesModalVisible'
);

export const isWdcTargetedModalVisible = volatileProp(
  'booking.isWdcTargetedModalVisible'
);

export const isPaymentFailedModalVisible = volatileProp(
  'booking.isPaymentFailedModalVisible'
);

export const isPhoneNumberUpdateModalVisible = volatileProp(
  'states.isPhoneNumberUpdateModalVisible'
);

export const isMainPage = volatileProp('states.isMainPage');

export const navigationClassList = volatileProp('navigation.navigationClassList');

/**
 * @type {(state: State) => boolean}
 */
export const isHeadroomPinned = compose(
  includes('headroom--pinned'),
  navigationClassList
);

export const isCancellationInsuranceInfoModalVisible = volatileProp(
  'states.isCancellationInsuranceInfoModalVisible'
);

export const isTravelInsuranceInfoModalVisible = volatileProp(
  'states.isTravelInsuranceInfoModalVisible'
);

export const isTravelAndCancellationInsuranceInfoModalVisible = volatileProp(
  'states.isTravelAndCancellationInsuranceInfoModalVisible'
);

export const isCaptchaHandled = volatileProp('captcha.isCaptchaHandled');

export const isCaptchaModalVisible = volatileProp('captcha.isCaptchaModalVisible');

/**
 * @type {(state: State) => boolean}
 */
export const isUserLoading = compose(Boolean, volatileProp('user.isLoading'));

export const isPaymentExitIntentModalVisible = volatileProp(
  'booking.isPaymentExitIntentModalVisible'
);

export const isAddFlightExitIntentModalVisible = volatileProp(
  'itineraryOverview.isAddFlightExitIntentModalVisible'
);

export const heroActiveSlideId = volatileProp('states.heroBannerInfo.activeSlideId');

export const heroNumberOfSlides = volatileProp('states.heroBannerInfo.numberOfSlides');

export const isRescueFareCodeVisible = volatileProp('search.isRescueFareCodeVisible');

export const selectedSearchTab = volatileProp('search.selectedTab');

const isYellowRibbonDismissed = volatileProp('states.isYellowRibbonDismissed');

export const yellowRibbonMessage = volatileProp('states.yellowRibbonMessage');

export const isYellowRibbonVisible = (state) =>
  !isBookingFlowVisible(state) &&
  !isYellowRibbonDismissed(state) &&
  Boolean(yellowRibbonMessage(state));

export const isFlightAdditionRequested = volatileProp('states.isFlightAdditionRequested');

export const callCenterInformation = volatileProp('states.callCenterInformation')