import curry from '../curry';

/**
 * @typedef {<T>(fn: (a: T) => boolean, arr: T[]) => boolean} AllFn
 */

/**
 * @type AllFn
 */
export const _all = (fn, arr) => arr.every((el) => fn(el));

/**
 * Returns `true` if all elements of the array match the predicate, `false` if
 * there are any that don't.
 *
 * note: predicate fn will be called as fn(element)
 * note: calling this method on an empty array will return true for any condition!
 *
 * @type AllFn
 * @example
 *
 *      let equals3 = equals(3);
 *      all(equals3)([3, 3, 3, 3]); //=> true
 *      all(equals3)([3, 3, 1, 3]); //=> false
 */
const all = curry(_all);

export default all;
