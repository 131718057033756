import curry from '../curry';
import { _filter } from '../filter';
import includedIn from '../included-in';
import uniq from '../uniq';

/**
 * @typedef {<T>(arr1: T[], arr2: T[]) => T[]} IntersectionFn
 */

/**
 * @type IntersectionFn
 */
export const _intersection = (arr1, arr2) => {
  let lookupList, filteredList;

  if (arr1.length > arr2.length) {
    lookupList = arr1;
    filteredList = arr2;
  } else {
    lookupList = arr2;
    filteredList = arr1;
  }

  return uniq(_filter(includedIn(lookupList), filteredList));
};

/**
 * Combines two lists into a set (i.e. no duplicates) composed of those
 * elements common to both lists.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/intersection.js
 *
 * @type IntersectionFn
 * @example
 *
 *      intersection([1,2,3,4], [7,6,5,4,3]); //=> [4, 3]
 */
const intersection = curry(_intersection);

export default intersection;
