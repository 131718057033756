import to from 'await-to-js';
import log from 'loglevel';
import { LS_NO_TIMEOUT_MODAL_DEBUG_KEY } from '~/constants/local-storage';
import { isBlackListedUrl } from '~/utils/system';
import { sendGaEvent, setVar99Dimension } from '~/utils/analytics';
import { createAction } from '~/utils/store';
import { getLocalItemRaw } from '~/utils/storage';
import * as AssetService from '~/services/asset';
import * as getters from './getters';
import * as m from './mutation-types';

/**
 * @type {(store: Store) => Promise}
 */
export const fetchTimeouts = async ({ commit }) => {
  const [error, response] = await to(AssetService.timeOut());
  if (error) {
    commit(m.RESET_TIMEOUTS);
    throw error;
  }

  const {
    timeout: sessionTimeoutInMinutes,
    urgencyModalTimeout: inactivityTimeoutInSeconds,
    searchRedirectInMillisecs,
  } = response.data;

  commit(m.SET_TIMEOUTS, {
    sessionTimeoutInMinutes,
    inactivityTimeoutInSeconds,
    searchRedirectInMillisecs,
  });

  commit(m.SET_IS_TIMEOUTS_INITIALIZED);
};

export const activateBotBehaviorDetectedState = (store) => {
  const { commit } = store;
  setVar99Dimension();
  sendGaEvent({
    category: 'Bot detection modal',
    action: 'Impression',
    nonInteractive: true,
  });

  commit(m.SET_IS_BOT_BEHAVIOR_DETECTED);
  showBotDetectionModal(store);
};

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const updateWebsiteDownInfo = ({ commit }, payload = {}) => {
  if (isBlackListedUrl(payload.url)) return;
  commit(m.UPDATE_WEBSITE_DOWN_INFO, payload);
};

/**
 * @type {(store: Store, value: string) => void}
 */
export const setActiveElementId = createAction(m.SET_ACTIVE_ELEMENT_ID);

export const showBotDetectionModal = createAction(m.SHOW_BOT_DETECTION_MODAL);

export const hideBotDetectionModal = createAction(m.HIDE_BOT_DETECTION_MODAL);

/**
 * @type {store: Store, payload: Object<String, any>) => void}
 */
export const showChangePasswordModal = createAction(m.SHOW_CHANGE_PASSWORD_MODAL);

export const hideChangePasswordModal = createAction(m.HIDE_CHANGE_PASSWORD_MODAL);

export const showFindBookingModal = createAction(m.SHOW_FIND_BOOKING_MODAL);

export const hideFindBookingModal = createAction(m.HIDE_FIND_BOOKING_MODAL);

export const showFlowResetModal = createAction(m.SHOW_FLOW_RESET_MODAL);

export const hideFlowResetModal = createAction(m.HIDE_FLOW_RESET_MODAL);

/**
 * @type {(store: Store, payload: Object<String, any>) => void}
 */
export const showForgotPasswordModal = createAction(m.SHOW_FORGOT_PASSWORD_MODAL);

export const hideForgotPasswordModal = createAction(m.HIDE_FORGOT_PASSWORD_MODAL);

export const showInformationServices = createAction(m.SHOW_INFORMATION_SERVICES);

export const hideInformationServices = createAction(m.HIDE_INFORMATION_SERVICES);

export const toggleInformationServices = createAction(m.TOGGLE_INFORMATION_SERVICES);

export const setInformationServicesMenuItemVisible = createAction(
  m.IS_INFORMATION_SERVICES_MENU_ITEM_VISIBLE
);

export const setIsRouteLoadingState = createAction(m.SET_IS_ROUTE_LOADING_STATE);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setIsInputFocused = createAction(m.SET_IS_INPUT_FOCUSED);

// todo: rename to toggleLanguageSwitcher, note this affects CMS code
export const toggleLanguageSwitch = createAction(m.TOGGLE_LANGUAGE_SWITCH);

/**
 * @type {(store: Store, value: boolean) => void}
 */
// todo: replace with show/hide, note this affects CMS code
export const setLanguageSwitchVisibility = createAction(m.SET_LANGUAGE_SWITCH_VISIBILITY);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const showLocalesInsuranceModal = createAction(m.SHOW_LOCALES_INSURANCE_MODAL);

export const hideLocalesInsuranceModal = createAction(m.HIDE_LOCALES_INSURANCE_MODAL);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const showLoginModal = (store, payload) => {
  store.commit(m.SHOW_LOGIN_MODAL, payload);
  return getters.loginModalPromise(store.state);
};

export const setEmailVerificationErrors = createAction(m.SET_EMAIL_VERIFICATION_ERRORS);

export const setRegistrationEmail = createAction(m.SET_REGISTRATION_EMAIL);

export const hideLoginModal = createAction(m.HIDE_LOGIN_MODAL);

export const setLoginRedirectUrl = createAction(m.SET_LOGIN_REDIRECT_URL);

export const resetLoginRedirectUrl = createAction(m.RESET_LOGIN_REDIRECT_URL);

export const showLoginLogoutFeedbackModal = createAction(
  m.SHOW_LOGIN_LOGOUT_FEEDBACK_MODAL
);

export const hideLoginLogoutFeedbackModal = createAction(
  m.HIDE_LOGIN_LOGOUT_FEEDBACK_MODAL
);

export const showMobileNavigation = createAction(m.SHOW_MOBILE_NAVIGATION);

export const hideMobileNavigation = createAction(m.HIDE_MOBILE_NAVIGATION);

/**
 * @type {(store: Store, value: boolean) => void}
 */
// todo: replace with show/hide, note this affects CMS code
export const setMobileNavigationVisibility = createAction(
  m.SET_MOBILE_NAVIGATION_VISIBILITY
);

export const setNavigationLoadingState = createAction(m.SET_NAVIGATION_LOADING_STATE);

export const unsetNavigationLoadingState = createAction(m.UNSET_NAVIGATION_LOADING_STATE);

export const showNewPrivacyPolicyModal = createAction(m.SHOW_NEW_PRIVACY_POLICY_MODAL);

export const showInvalidDateOfBirthModal = createAction(
  m.SHOW_INVALID_DATE_OF_BIRTH_MODAL
);

export const hideInvalidDateOfBirthModal = createAction(
  m.HIDE_INVALID_DATE_OF_BIRTH_MODAL
);

export const hideNewPrivacyPolicyModal = createAction(m.HIDE_NEW_PRIVACY_POLICY_MODAL);

export const showPlan = createAction(m.SHOW_PLAN);

export const hidePlan = createAction(m.HIDE_PLAN);

export const showTopFlights = createAction(m.SHOW_TOP_FLIGHTS);

export const hideTopFlights = createAction(m.HIDE_TOP_FLIGHTS);

/**
 * @type {store: Store, payload: Object<String, any>) => void}
 */
export const showRegistrationModal = createAction(m.SHOW_REGISTRATION_MODAL);

export const hideRegistrationModal = createAction(m.HIDE_REGISTRATION_MODAL);

export const showRegistrationPendingModal = createAction(
  m.SHOW_REGISTRATION_PENDING_MODAL
);

export const hideRegistrationPendingModal = createAction(
  m.HIDE_REGISTRATION_PENDING_MODAL
);

export const showResendVerificationEmailModal = createAction(
  m.SHOW_RESEND_VERIFICATION_EMAIL_MODAL
);

export const hideResendVerificationEmailModal = createAction(
  m.HIDE_RESEND_VERIFICATION_EMAIL_MODAL
);

/**
 * @type {store: Store, payload: Object<String, any>) => void}
 */
export const showResetPasswordModal = createAction(m.SHOW_RESET_PASSWORD_MODAL);

export const hideResetPasswordModal = createAction(m.HIDE_RESET_PASSWORD_MODAL);

export const setStickyHeaderVisibility = createAction(m.SET_STICKY_HEADER_VISIBILITY);

export const unsetStickyHeaderVisibility = createAction(m.UNSET_STICKY_HEADER_VISIBILITY);

export const enableTimeoutModal = createAction(m.ENABLE_TIMEOUT_MODAL);

export const disableTimeoutModal = createAction(m.DISABLE_TIMEOUT_MODAL);

export const showTimeoutModal = ({ commit }) => {
  if (getLocalItemRaw(LS_NO_TIMEOUT_MODAL_DEBUG_KEY)) {
    log.error('!!! Session timeout. !!!');
    commit(m.HIDE_TIMEOUT_MODAL);
  } else {
    commit(m.SHOW_TIMEOUT_MODAL);
  }
};

export const hideTimeoutModal = createAction(m.HIDE_TIMEOUT_MODAL);

export const showCsrfErrorModal = createAction(m.SHOW_CSRF_ERROR_MODAL);

export const hideCsrfErrorModal = createAction(m.HIDE_CSRF_ERROR_MODAL);

/**
 * @type {(store: Storage, value: boolean) => void}
 */
export const setTimeoutModalVisibility = createAction(m.SET_TIMEOUT_MODAL_VISIBILITY);

export const addToActiveEndpoints = createAction(m.ADD_TO_ACTIVE_ENDPOINTS);

export const removeFromActiveEndpoints = createAction(m.REMOVE_FROM_ACTIVE_ENDPOINTS);
