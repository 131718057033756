import curry from '~/utils/fp/curry';
import { _evolve } from '~/utils/fp/evolve';
import isFunction from '../is-function';
import isObject from '../is-object';

/**
 * @type import('../../fp/evolve').EvolveFn
 */
export const _hevolve = (transformations, obj) =>
  mmapObj((value, key) => {
    const transformation = transformations[key];
    let newValue = value;

    if (isFunction(transformation)) {
      newValue = transformation(value);
    } else if (isObject(transformation)) {
      newValue = _evolve(transformation, value);
    }

    return newValue;
  }, obj);

/**
 * in place mapObj which mutates the object 😐
 */
const mmapObj = (fn, obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    obj[key] = fn(value, key, obj);
  });

  return obj;
};

/**
 * Hybrid evolve which does not create a new top level object but it will
 * create new sub objects along the path.
 *
 * @type import('../../fp/evolve').EvolveFn
 */
const hevolve = curry(_hevolve);

export default hevolve;
