import {
  CHECK_IN_STATUS_CHECKED_IN,
  DIRECTION_OUTBOUND,
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
} from '~/constants';
import whereEq from '~/utils/fp/where-eq';
import isNotNil from '~/utils/object/is-not-nil';
import { findAssignedSeatCode } from '~/components/booking-flow/seat-selection-base/internal';
import has from '~/utils/fp/has';
import alter from '~/utils/fp/alter';
import omit from '~/utils/fp/omit';
import pathOr from '~/utils/fp/path-or';
import { isInfant, isNotInfant, isReducedMobility, isRegularAdult } from '../passenger';
import getSeats from './get-seats';

/**
 * Returns the following structure:
 *
 *   {
 *     outboundFlight:
 *     {
 *       {
 *        '0': true   => key is a passenger id, value is true/false
 *        '1': false,
 *        ...
 *       }
 *     },
 *
 *     returnFlight:
 *     {
 *       ...
 *     }
 *   }
 */
export const checkInStatusesFrom = (itineraryCheckInPassengers) => {
  itineraryCheckInPassengers = itineraryCheckInPassengers || {};

  return [OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME].reduce((acc, flightName) => {
    acc[flightPropByDirection(flightName)] = checkInStatusFrom(
      itineraryCheckInPassengers[flightName]
    );
    return acc;
  }, {});
};

/**
 * Returns the following structure:
 *
 *   {
 *    '0': true   => key is a passenger id, value is true/false
 *    '1': false,
 *    ...
 *   }
 */
export const checkInStatusFrom = (checkInPassengers) =>
  (checkInPassengers || []).reduce((acc, cp) => {
    const { passengerNumber: passengerId, flightCheckinStatus } = cp;
    acc[passengerId] = flightCheckinStatus === CHECK_IN_STATUS_CHECKED_IN;
    return acc;
  }, {});

export const rawPassengersFromItinerary = (itineraryPassengers) => {
  let redmobCount = itineraryPassengers.filter(isReducedMobility).length;

  return itineraryPassengers.map((ip) => {
    const {
      passengerNumber: id,
      firstName,
      lastName,
      gender,
      passengerType: type,
      hasAppliedPrivilegePass: privilegePassActivated,
      infant,
    } = ip;
    const regularAdult = isRegularAdult(ip);
    const reducedMobility = isReducedMobility(ip);
    const hasInfant = isNotNil(infant);
    const reducedMobilityAssistant = redmobCount > 0 && regularAdult;
    redmobCount -= reducedMobilityAssistant ? 1 : 0;

    return {
      id,
      firstName,
      lastName,
      gender,
      type,

      hasInfant,
      reducedMobility,
      reducedMobilityAssistant,
      privilegePassActivated,
    };
  });
};

export const rawPassengersFromPassengers = (passengers) => {
  let redmobCount = passengers.filter(isReducedMobility).length;
  const infants = passengers.filter(isInfant);

  return passengers.filter(isNotInfant).map((passenger) => {
    const {
      passengerNumber: id,
      firstName,
      lastName,
      gender,
      passengerType: type,
      privilegePassActivated,
    } = passenger;
    const regularAdult = isRegularAdult(passenger);
    const reducedMobility = isReducedMobility(passenger);
    const hasInfant = infants.some(whereEq({ passengerAdultNumber: id }));
    const reducedMobilityAssistant = redmobCount > 0 && regularAdult;
    redmobCount -= reducedMobilityAssistant ? 1 : 0;

    return {
      id,
      firstName,
      lastName,
      gender,
      type,

      hasInfant,
      reducedMobility,
      reducedMobilityAssistant,
      privilegePassActivated,
    };
  });
};

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': {     => key is a seat code
 *      includedIn: 'middle'
 *      0: 1000,  => key (number) is a passenger id, value is the seat price
 *      1: 1500,
 *      ...
 *    },
 *    ...
 *   }
 *
 * @param {Object} params
 * @param {String} params.flightName
 * @param {Object} params.seatingDetails
 * @param {Array} params.ancillariesPassengers
 * @param {Object} params.paidSeatAssignments
 * @returns {Object}
 */
export const seatPricesFrom = ({
  flightName,
  seatingDetails,
  ancillariesPassengers,
  paidSeatAssignments,
} = {}) => {
  const flightSeatingDetails = seatingDetails[seatsPropByDirection(flightName)];
  const generalSeatPrices = seatPricesFromSeatingDetails(
    flightSeatingDetails,
    paidSeatAssignments
  );
  const passengerSeatPrices = currentSeatPricesFromAncillariesPassengers(
    flightName,
    ancillariesPassengers
  );
  return alter(passengerSeatPrices, generalSeatPrices);
};

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': {     => key is a seat code
 *      includedIn: 'middle'
 *      0: 1000,  => key (number) is a passenger id, value is the seat price
 *      1: 1500,
 *      ...
 *    },
 *    ...
 *   }
 */
const seatPricesFromSeatingDetails = (flightSeatingDetails, paidSeatAssignments) =>
  (flightSeatingDetails || []).reduce(
    (acc, priceGroup) =>
      alter(seatPriceEntriesFromSeatingDetails(priceGroup, paidSeatAssignments), acc),
    {}
  );

const seatPriceEntriesFromSeatingDetails = (priceGroup, paidSeatAssignments) => {
  const {
    passengerNumbers: passengerIds,
    unitDesignators: seatCodes,
    price,
    includedIn,
  } = priceGroup;
  return passengerIds.reduce(
    (acc, passengerId) =>
      seatCodes.reduce((acc, seatCode) => {
        if (!has(seatCode, acc)) {
          const lowerIncludedIn = (includedIn || '').toLowerCase();
          acc[seatCode] = { includedIn: lowerIncludedIn };
        }
        const paidSeatCode = findAssignedSeatCode(passengerId, paidSeatAssignments);
        const sameAsPaidSeatCode = seatCode === paidSeatCode;
        acc[seatCode][passengerId] = sameAsPaidSeatCode ? 0 : price;
        return acc;
      }, acc),
    {}
  );
};

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': {     => key is a seat code
 *      includedIn: 'middle'
 *      0: 1000,  => key (number) is a passenger id, value is the seat price
 *      1: 1500,
 *      ...
 *    },
 *    ...
 *   }
 *
 * @param {Object} params
 * @param {String} params.flightName
 * @param {Object} params.seatingDetails
 * @param {Array} params.ancillariesPassengers
 * @param {Object} params.paidSeatAssignments
 * @returns {Object}
 */
export const promotionSeatPricesFrom = ({
  flightName,
  seatingDetails,
  ancillariesPassengers,
  paidSeatAssignments,
} = {}) => {
  const flightSeatingDetails = seatingDetails[seatsPropByDirection(flightName)];
  const generalPromotionPrices = promotionSeatPricesFromSeatingDetails(
    flightSeatingDetails,
    paidSeatAssignments
  );
  const passengerPromotionPrices = currentPromotionSeatPricesFromAncillariesPassengers(
    flightName,
    ancillariesPassengers
  );
  return alter(passengerPromotionPrices, generalPromotionPrices);
};

const promotionSeatPricesFromSeatingDetails = (
  flightSeatingDetails,
  paidSeatAssignments
) =>
  (flightSeatingDetails || []).reduce(
    (acc, priceGroup) =>
      alter(
        promotionSeatPriceEntriesFromSeatingDetails(priceGroup, paidSeatAssignments),
        acc
      ),
    {}
  );

const promotionSeatPriceEntriesFromSeatingDetails = (priceGroup, paidSeatAssignments) => {
  const {
    passengerNumbers: passengerIds,
    unitDesignators: seatCodes,
    promotedPrice,
    includedIn,
  } = priceGroup;
  return passengerIds.reduce(
    (acc, passengerId) =>
      seatCodes.reduce((acc, seatCode) => {
        if (!has(seatCode, acc)) {
          const lowerIncludedIn = (includedIn || '').toLowerCase();
          acc[seatCode] = { includedIn: lowerIncludedIn };
        }
        const paidSeatCode = findAssignedSeatCode(passengerId, paidSeatAssignments);
        const sameAsPaidSeatCode = seatCode === paidSeatCode;
        acc[seatCode][passengerId] = sameAsPaidSeatCode ? 0 : promotedPrice;
        return acc;
      }, acc),
    {}
  );
};

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': {     => key is a seat code
 *      0: 1000,  => key (number) is a passenger id, value is the seat price
 *      1: 1500,
 *      ...
 *    },
 *    ...
 *   }
 *
 * @type {(priceProp: string) => (flightName: string, ancillariesPassengers: Record<String, any>[]) => Record<String, any>}
 */
const _currentSeatPricesFromAncillariesPassengers =
  (priceProp) => (flightName, ancillariesPassengers) =>
    (ancillariesPassengers || []).reduce((acc, passenger) => {
      const { passengerNumber: passengerId } = passenger;
      const { seatDesignator: seatCode, [priceProp]: price } = pathOr(
        { seatDesignator: null, price: 0 },
        `${flightPropByDirection(flightName)}.seatAssignment.current`,
        passenger
      );

      if (seatCode) {
        acc[seatCode] = { [passengerId]: price };
      }

      return acc;
    }, {});

const currentSeatPricesFromAncillariesPassengers =
  _currentSeatPricesFromAncillariesPassengers('price');

const currentPromotionSeatPricesFromAncillariesPassengers =
  _currentSeatPricesFromAncillariesPassengers('promotedPrice');

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': 0,     => key is a seat code, value is a passenger id
 *    '1C': 2,
 *     ...
 *   }
 *
 * NOTE: unassigned seat codes won't be included
 *
 * @type {(flightName: string, ancillariesPassengers: {}[]) => {}}
 */
export const seatAssignmentsFrom = (flightName, propName, ancillariesPassengers) =>
  (ancillariesPassengers || []).reduce((acc, passenger) => {
    const { passengerNumber: passengerId, [flightPropByDirection(flightName)]: flight } =
      passenger;
    const seatCode = pathOr(null, `seatAssignment.${propName}.seatDesignator`, flight);
    if (seatCode) acc[seatCode] = passengerId;
    return acc;
  }, {});

/**
 * Returns the following structure:
 *
 *   {
 *    '1A': {     => key is a seat code
 *      0: true,  => key is a passenger id, value is true if seat available, false otherwise
 *      1: false,
 *      ...
 *    },
 *
 *    '1B': {
 *      0: true,
 *      1: true,
 *      ...
 *    },
 *    ...
 *   }
 *
 * @param {Object} seatMapResponse
 * @returns {Object}
 */
export const seatAvailabilityFrom = (seatMapResponse) =>
  getSeats(seatMapResponse.seatGroups).reduce((acc, seat) => {
    const seatAvailability = seat.seatAvailabilityList.reduce((acc, sa) => {
      acc[sa.passengerNumber] = sa.isAvailable;
      return acc;
    }, {});

    acc[seat.code] = seatAvailability;
    return acc;
  }, {});

/**
 * Returns the following structure:
 *
 * [
 *   {
 *     id: 0,
 *     rows: [
 *       {
 *         rowNumber: 1,
 *         mixed: true,
 *         seats: [
 *           {
 *             rowNumber: 1,
 *             code: '1A',
 *             col: 'A',
 *             xl: true,
 *             aisle: false,
 *             premium: true,
 *             emergency: true,
 *             exitRow: true,
 *             benefitAttributes: ['extraLegroom', 'fastExit']
 *           },
 *
 *           {
 *             rowNumber: 1,
 *             code: '1B',
 *             col: 'B',
 *             xl: false,
 *             aisle: true,
 *             premium: false
 *             emergency: false
 *             exitRow: false,
 *             benefitAttributes: []
 *           },
 *           ...
 *         ]
 *       },
 *
 *       {
 *         rowNumber: 2,
 *         mixed: false,
 *         seats: [...]
 *       },
 *       ...
 *     ]
 *   },
 *
 *   {
 *     id: 1,
 *     rows: [...]
 *   },
 *   ...
 * ]
 *
 * @param {Object} seatMapResponse
 * @returns {Array}
 */
export const seatMapFrom = (seatMapResponse) => {
  let groupId = 0;

  return seatMapResponse.seatGroups.map((sGroup) => {
    const rows = sGroup.rows.map((sRow) => {
      const seats = sRow.seats.map((sSeat) => ({
        rowNumber: sRow.rowNumber,
        code: sSeat.code,
        col: sSeat.col,
        xl: sSeat.extl,
        window: sSeat.window,
        aisle: sSeat.aisle,
        premium: sSeat.isPremium,
        emergency: sSeat.isEmergency,
        exitRow: sSeat.exitRow,
        benefits: sSeat.benefitAttributes,
        zoneLabelKey: sSeat.zoneLabelKey,
      }));

      return {
        rowNumber: sRow.rowNumber,
        mixed: sRow.isMixedRow, // TODO can be calced too
        seats,
      };
    });

    return { id: groupId++, rows };
  });
};

export const convertRecommendedSeats = (seats) => {
  return seats.map((seat) => ({
    ...omit(['unitDesignator'], seat),
    seatCode: seat.unitDesignator,
  }));
};

const seatsPropByDirection = (direction) =>
  direction === DIRECTION_OUTBOUND ? 'outboundSeats' : 'returnSeats';

const flightPropByDirection = (direction) =>
  direction === DIRECTION_OUTBOUND ? 'outboundFlight' : 'returnFlight';
