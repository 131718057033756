/**
 * Returns an array of a given object's own enumerable property values, in the same
 * order as that provided by a for...in loop (the difference being that a for-in loop
 * enumerates properties in the prototype chain as well).
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/values.js
 *
 * @type {(obj: Object<string, any>) => any[]}
 */
const values = (obj) => Object.values(obj);

export default values;
