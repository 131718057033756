import axios from 'axios';
import { capitalize } from '~/utils/string';
import compose from '~/utils/fp/compose';
import entries from '~/utils/fp/entries';
import omit from '~/utils/fp/omit';
import reduce from '~/utils/fp/reduce';
import orElse from '~/utils/fp/or-else';
import isNotEmpty from '~/utils/object/is-not-empty';
import isBoolean from '~/utils/object/is-boolean';
import {
  ANCILLARY_TYPE_RESET_SEATS,
  ANCILLARY_TYPE_SELECT_SEAT,
} from '~/store/modules/ancillaries/internal';
import has from '~/utils/fp/has';
import isString from '~/utils/object/is-string';
import evolve from '~/utils/fp/evolve';
import map from '~/utils/fp/map';
import mapObj from '~/utils/fp/map-obj';
import {
  ANCILLARY_AIRPORT_PARKING,
  SERVICE_NAME_AIRPORT_TRANSFERS,
  BUNDLE_UPSELL_STEP_ADD_SERVICE,
  ANCILLARY_ACORD_INSURANCE_FISCAL_CODES,
  ANCILLARY_ACORD_SERVICE,
  ANCILLARY_ACORD_INSURANCE,
} from '~/constants';
import {
  ANCILLARY_FASTTRACK,
  ANCILLARY_LOUNGE,
  BOOKING_AIRPORTPARKING,
  BOOKING_ANCILLARIES,
  BOOKING_ANCILLARY_FLASH_PROMOS,
  BOOKING_BUNDLE_UPSELL_SELECT,
  BOOKING_CALCULATE_BUNDLE_UPSELL,
  BOOKING_INSURANCE,
} from '~/constants/api-endpoint';

const servicesNoWrapperObjectNeededKeys = [ANCILLARY_ACORD_INSURANCE_FISCAL_CODES];

export const getAncillaries = (params) => axios.get(BOOKING_ANCILLARIES, { params });

export const getAirportParking = () => axios.get(BOOKING_AIRPORTPARKING);

export const fetchLoungeAccessV2 = () => axios.get(ANCILLARY_LOUNGE);

export const getSecurityFastTrackV2 = () => axios.get(ANCILLARY_FASTTRACK);

export const initializeInsurance = () => axios.get(BOOKING_INSURANCE);

export const getAncillaryFlashPromos = () => axios.get(BOOKING_ANCILLARY_FLASH_PROMOS);

export const postAncillaryFlashPromos = (data) =>
  axios.post(BOOKING_ANCILLARY_FLASH_PROMOS, data);

export const changeAirportParking = (params) => {
  return axios.get(BOOKING_AIRPORTPARKING, { params });
};

export const postAncillaries = (data) => axios.post(BOOKING_ANCILLARIES, mapDataTo(data));

export const _fetchBundleUpsell = (params) =>
  axios.get(BOOKING_CALCULATE_BUNDLE_UPSELL, { params });

export const _fetchPostBookingBundleUpsell = () =>
  axios.get(BOOKING_CALCULATE_BUNDLE_UPSELL, {
    params: { page: BUNDLE_UPSELL_STEP_ADD_SERVICE },
  });

export const postBookingUpgrade = (params) =>
  axios.post(BOOKING_BUNDLE_UPSELL_SELECT, params);

/**
 * @type {(seatSelection: []) => {}}
 */
const mapSeatSelectionTo = (seatSelection) =>
  seatSelection.reduce((acc, ancillary) => {
    const { type, flightName } = ancillary;

    switch (type) {
      case ANCILLARY_TYPE_RESET_SEATS:
        acc[`resetAll${capitalize(flightName)}Seats`] = true;
        break;

      case ANCILLARY_TYPE_SELECT_SEAT: {
        const {
          passengerId: passengerNumber,
          seatCode,
          previouslySelectedSeatCodes,
        } = ancillary;

        const seatRequestsProp = `${flightName}SeatRequests`;
        const seatCodeHistoryProp = `${flightName}SeatProductViews`;

        if (!has(seatRequestsProp, acc)) {
          acc[seatRequestsProp] = [];
        }

        acc[seatRequestsProp].push({
          passengerNumber,
          seatIdentifier: seatCode,
        });

        if (previouslySelectedSeatCodes.length > 0) {
          acc[seatCodeHistoryProp] = [...previouslySelectedSeatCodes];
        }

        break;
      }

      // no default
    }

    return acc;
  }, {});

/**
 * @type {(value: any) => {}}
 */
const wrapValue = (value) => ({
  value,
});

/**
 * @type {(services: {}) => {}}
 */
const mapServicesTo = compose(
  mapObj((value, key) =>
    servicesNoWrapperObjectNeededKeys.includes(key) ? value : wrapValue(value)
  ),
  omit([SERVICE_NAME_AIRPORT_TRANSFERS]),
  reduce(
    (acc, [serviceName, service]) => {
      if (serviceName === ANCILLARY_ACORD_SERVICE) {
        acc[ANCILLARY_ACORD_INSURANCE] = service.option;
        acc[ANCILLARY_ACORD_INSURANCE_FISCAL_CODES] = service.fiscalCodes;
      } else if (serviceName === SERVICE_NAME_AIRPORT_TRANSFERS) {
        acc[
          `${serviceName.replace(/s$/, '')}For${capitalize(service.direction)}Station`
        ] = service.option;
      } else if (serviceName === ANCILLARY_AIRPORT_PARKING) {
        acc[ANCILLARY_AIRPORT_PARKING] = service.option;
      } else if (
        service.direction &&
        !serviceName.toLowerCase().includes(service.direction)
      ) {
        acc[`${serviceName}${capitalize(service.direction)}`] = service.selected;
      } else {
        acc[serviceName] = service.selected;
      }
      return acc;
    },
    () => ({})
  ),
  entries,
  orElse({})
);

/**
 * @type {(passengers: []) => []}
 */
const mapPassengersTo = compose(
  map(evolve({ outboundFlight: map(wrapValue), returnFlight: map(wrapValue) })),
  orElse([])
);

/**
 * @type {(data: {}) => {}}
 */
// We have to transform our data to be back-end compliant without
// hurting the other request fields so we make some mess. many mess.
const mapDataTo = (data) => {
  const {
    exchangeCurrencyCode,
    passengers,
    services,
    seatSelection,
    withSummary = true,
    forcePerPassengerPrice,
    resetAllOutboundSeats,
    resetAllReturnSeats,
  } = data;

  return {
    ...(isString(exchangeCurrencyCode) ? { exchangeCurrencyCode } : {}),
    ...(isBoolean(resetAllOutboundSeats) ? { resetAllOutboundSeats } : {}),
    ...(isBoolean(resetAllReturnSeats) ? { resetAllReturnSeats } : {}),
    ...(isNotEmpty(passengers) ? { passengers: mapPassengersTo(passengers) } : {}),
    ...(isNotEmpty(services) ? { services: mapServicesTo(services) } : {}),
    ...(isNotEmpty(seatSelection) ? mapSeatSelectionTo(seatSelection) : {}),
    ...(isBoolean(forcePerPassengerPrice) ? { forcePerPassengerPrice } : {}),
    withSummary,
  };
};
