import curry from '~/utils/fp/curry';
import compose from '~/utils/fp/compose';
import path from '~/utils/fp/path';
import find from '~/utils/fp/find';
import always from '~/utils/fp/always';
import ifElse from '~/utils/fp/if-else';
import equals from '~/utils/fp/equals';
import either from '~/utils/fp/either';
import both from '~/utils/fp/both';
import identity from '~/utils/fp/identity';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import includedIn from '~/utils/fp/included-in';
import { getConfigCatClient } from '~/configCat';
import {
  FEATURE_NAME_SEAT_MAP_UPSELL,
  SEAT_MAP_UPSELL_DEFAULT_VARIATION,
  FEATURE_NAME_LABEL_07,
  SEAT_RECOMMENDATION_ENABLED,
  FEATURE_NAME_SEAT_RECOMMENDATION,
  PAYMENT_EXIT_INTENT_MODAL_B,
  FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL,
  PAYMENT_EXIT_INTENT_MODAL_C,
  PAYMENT_EXIT_INTENT_MODAL_A,
  ITINERARY_NEW_BOOKING_COM_MODAL_VISIBLE,
  FEATURE_NAME_ITINERARY_NEW_BOOKING_COM_MODAL_VISIBILITY,
  GOOGLE_ADS_ENABLED,
  FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_PAYMENT,
  FEATURE_NAME_FLIGHT_SELECT_BUNDLE_ORDER,
  BUNDLE_ORDER_DEFAULT,
  FLIGHT_SELECT_BUNDLE_ORDER,
  FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_CHECK_IN,
  FEATURE_NAME_SEATING_TOGETHER,
  FEATURE_VALUE_SEATING_TOGETHER_ACTIVE,
  FEATURE_NAME_PROFILE_YOUR_CLAIMS,
  FEATURE_VALUE_PROFILE_YOUR_CLAIMS,
  FEATURE_NAME_CONTACT_DATA_VERSION,
  CONTACT_DATA_VERSION_A,
  FEATURE_NAME_CHATBOT_AUDIENCE_DISTRIBUTION,
  FEATURE_VALUE_CHATBOT_AUDIENCE_DISTRIBUTION,
  FEATURE_NAME_CHATBOT_PLACEHOLDER,
  FEATURE_VALUE_CHATBOT_PLACEHOLDER,
  FEATURE_NAME_CHATBOT_MAIN_PAGE,
  FEATURE_VALUE_CHATBOT_MAIN_PAGE,
  FEATURE_NAME_FARE_PRICING,
  FARE_PRICING_VARIANTS,
  FEATURE_NAME_EVERYMUNDO_PIXEL_FLIGHT_SEARCH,
  FEATURE_VALUE_EVERYMUNDO_PIXEL_FLIGHT_SEARCH,
  FEATURE_NAME_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING,
  FEATURE_VALUE_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING,
  FEATURE_NAME_STICKY_BASKET_WITHOUT_SIDEBAR,
  FEATURE_VALUE_STICKY_BASKET_WITHOUT_SIDEBAR_ENABLED,
  FEATURE_NAME_WDC_DISCOUNT_APPLIED_ON_BAGS,
  FEATURE_VALUE_WDC_DISCOUNT_APPLIED_ON_BAGS_ENABLED,
  FEATURE_NAME_IS_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING,
  FEATURE_VALUE_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING,
  FEATURE_NAME_PRM_SERVICE_ADDITION,
  FEATURE_VALUE_PRM_SERVICE_ADDITION_ENABLED,
  FEATURE_NAME_HERO_BANNER_VARIANTS,
  FEATURE_VALUE_HERO_BANNER_VARIANTS,
  FEATURE_NAME_AIRSHIP_WEB_PUSH_ENABLER,
  FEATURE_VALUE_AIRSHIP_WEB_PUSH_ENABLER,
  FEATURE_VALUE_CHECKED_IN_BAGGAGE_26_KG_ENABLED,
  FEATURE_NAME_CHECKED_IN_BAGGAGE_26_KG,
  FEATURE_NAME_LOGIN_AT_SERVICES_STEP,
  FEATURE_VALUE_LOGIN_AT_SERVICES_STEP_ENABLED,
  FEATURE_NAME_TILE_SERVICES,
  FEATURE_VALUE_TILE_SERVICES_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_ACCORDION,
  FEATURE_VALUE_SITTING_TOGETHER_ACCORDION_ENABLED,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_DELAY,
  AIRSHIP_NOTIFICATION_DELAY_DEFAULT_VARIATION,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_MOBILE_DELAY,
  AIRSHIP_NOTIFICATION_MOBILE_DELAY_DEFAULT_VARIATION,
  FEATURE_NAME_CHEAP_FLIGHT_TRACKING_ENABLER,
  FEATURE_VALUE_CHEAP_FLIGHT_TRACKING_ENABLER,
  FEATURE_NAME_HOTJAR_ENABLER,
  FEATURE_VALUE_HOTJAR_ENABLER,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT,
  INFORMATION_MENU_PLACEMENT_A_DEFAULT,
  INFORMATION_MENU_PLACEMENT_B_DEFAULT_TRACKING,
  INFORMATION_MENU_PLACEMENT_C_NEW_TRACKING,
  INFORMATION_MENU_PLACEMENT_D_BOTH_TRACKING,
  FEATURE_VALUE_E_SIM_DATA_ROAMING_ENABLED,
  FEATURE_NAME_E_SIM_DATA_ROAMING,
  FEATURE_NAME_CHECK_IN_TEMPORARY_MESSAGE,
  FEATURE_VALUE_CHECK_IN_TEMPORARY_MESSAGE_ENABLED,
  FEATURE_VALUE_INSURANCE_PRICING_ENABLED,
  FEATURE_VALUE_RECAPTCHA_ON_REGISTRATION_ENABLED,
  FEATURE_NAME_RECAPTCHA_ON_REGISTRATION,
  FEATURE_NAME_SUPPLEMENTAL_PAYMENT,
  FEATURE_VALUE_SUPPLEMENTAL_PAYMENT_ENABLED,
  FEATURE_NAME_SMART_BUNDLE,
  FEATURE_VALUE_SMART_BUNDLE_ENABLED,
  FEATURE_VALUE_WDC_LIGHT_ENABLED,
  FEATURE_NAME_WDC_LIGHT,
  FEATURE_NAME_BLUE_RIBBON_BAG_TRACKING,
  FEATURE_VALUE_BLUE_RIBBON_BAG_TRACKING_ENABLED,
  FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW,
  FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_ENABLED,
  FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_DISABLED,
  FEATURE_VALUE_SMALL_BAGS_ENABLED,
  FEATURE_NAME_SMALL_BAGS,
  FEATURE_VALUE_CHOOSE_SEAT_PROMO_PICTURE_ENABLED,
  FEATURE_NAME_CHOOSE_SEAT_PROMO_PICTURE,
  FEATURE_VALUE_PAY_BY_LINK_NAME_CHANGE_ENABLED,
  FEATURE_NAME_PAY_BY_LINK_NAME_CHANGE,
  FEATURE_VALUE_BUNDLE_PRICE_COMPARED_TO_BASIC_ENABLED,
  FEATURE_NAME_BUNDLE_PRICE_COMPARED_TO_BASIC,
  FEATURE_VALUE_FLIGHT_PAST_PRICE_ENABLED,
  FEATURE_NAME_FLIGHT_PAST_PRICE,
  FEATURE_VALUE_SHOW_SEATMAP_BUTTON_NEW,
  FEATURE_NAME_SHOW_SEATMAP_BUTTON,
  FEATURE_NAME_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT,
  FEATURE_VALUE_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT_ENABLED,
  FEATURE_VALUE_WIZZ_GO_PER_PERSON_FLIGHT_ENABLED,
  FEATURE_NAME_WIZZ_GO_PER_PERSON_FLIGHT,
  FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY,
  FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_B,
  FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_C,
  FEATURE_NAME_MOBILE_BOTTOM_MENU,
  FEATURE_VALUE_MOBILE_BOTTOM_MENU_ENABLED,
  FEATURE_NAME_CHECK_IN_LEGAL_INFORMATION,
  FEATURE_VALUE_CHECK_IN_LEGAL_INFORMATION_ENABLED,
  FEATURE_VALUE_SITTING_TOGETHER_PER_PERSON_FLIGHT_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_PER_PERSON_FLIGHT,
  FEATURE_NAME_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP,
  FEATURE_VALUE_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP,
  FEATURE_NAME_EXPEDIA_POPUNDER,
  FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_B,
  FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_C,
  FEATURE_NAME_CLOSE_PASSENGER_DETAILS,
  FEATURE_VALUE_CLOSE_PASSENGER_DETAILS_ENABLED,
  FEATURE_NAME_ADD_CORPORATE_CARD_FEE,
  FEATURE_VALUE_TOGGLE_ON,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN,
  FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_REDESIGN_ENABLED,
  FEATURE_NAME_FARE_FINDER_PAST_PRICE,
  FEATURE_VALUE_FARE_FINDER_PAST_PRICE,
  FEATURE_NAME_POPULAR_TAG,
  FEATURE_VALUE_POPULAR_TAG_VARIANT_B,
  FEATURE_VALUE_POPULAR_TAG_VARIANT_C,
  FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE_ENABLED,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE,
  FEATURE_VALUE_SPLIT_PAX_ENABLED,
  FEATURE_NAME_SPLIT_PAX,
  FEATURE_VALUE_OTA_FEE_WARNING_ENABLED,
  FEATURE_NAME_OTA_FEE_WARNING,
  FEATURE_NAME_NEW_PASSENGER_DETAILS,
  FEATURE_VALUE_NEW_PASSENGER_DETAILS_ENABLED,
  defaultFeatureValueMap,
  FEATURE_NAME_PROFILE_PRICE_ALERT,
  FEATURE_VALUE_PROFILE_PRICE_ALERT_ENABLED,
  FEATURE_VALUE_SITTING_TOGETHER_WITH_30_DAYS_CI_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_WITH_30_DAYS_CI,
  FEATURE_VALUE_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE_ENABLED,
  FEATURE_NAME_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE,
  FEATURE_NAME_NEW_CHECK_IN_ALL_DONE_SEATING,
  FEATURE_VALUE_NEW_CHECK_IN_ALL_DONE_SEATING_ENABLED,
  FEATURE_NAME_RECOMMEND_26KG_BAG,
  FEATURE_VALUE_RECOMMEND_26KG_BAG_ENABLED,
  FEATURE_VALUE_SMART_SEARCH_IS_ENABLED,
  FEATURE_FARE_FINDER_SMART_SEARCH,
  FEATURE_NAME_WIZZ_PRIO_MOBILE_VIEW,
  FEATURE_VALUE_WIZZ_PRIO_MOBILE_VIEW_ENABLED,
  FEATURE_NAME_FLIGHT_DETAILS_BLOCK_NEW_CTA,
  FEATURE_VALUE_FLIGHT_DETAILS_BLOCK_NEW_CTA_ENABLED,
} from './constants';
import { getFeatureValue as _getFeatureValue } from './internal';

const featureProp = curry((prop, state) => path(prop, state.feature));

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const features = featureProp('features');

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const originalFeatures = featureProp('originalFeatures');

/**
 * @type {(state: State) => FetchFeatureState}
 */
export const fetchFeaturesState = featureProp('fetchFeaturesState');

export const configCatSnapshot = featureProp('configCatSnapshot');

export const getConfigCatFeatureValue = (state, featureName) => {
  const defaultValue = defaultFeatureValueMap.get(featureName);

  const configCatClient = getConfigCatClient();
  if (!configCatClient) return defaultValue;

  const snapshot = configCatClient.snapshot();
  return snapshot?.getValue?.(featureName, defaultValue) ?? defaultValue;
};

/**
 * @type {<T, U>(fn: (value: T) => U, defaultValue: U, featureName: string) => (state: State) => U}
 */
const mappedFeatureValueOr = curry((fn, defaultValue, featureName) =>
  compose(
    ifElse(
      both(isNotNil, compose(isNotNil, _getFeatureValue)),
      compose(fn, _getFeatureValue),
      always(defaultValue)
    ),
    find((feature) => feature.name?.toLowerCase() === featureName.toLowerCase()),
    features
  )
);

/**
 * @type {<T>(defaultValue: T, value: T, featureName: string) => (state: State) => boolean}
 */
const featureValueEqOr = (defaultValue, value, featureName) =>
  mappedFeatureValueOr(equals(value), defaultValue, featureName);

/**
 * @type {<T>(defaultValue: T, featureName: string) => (state: State) => T}
 */
const featureValueOr = mappedFeatureValueOr(identity);

/**
 * @type {(state) => string}
 */
export const testId = featureProp('testId');

/**
 * @type {(state) => boolean}
 */
export const hasTestId = compose(isNotEmpty, testId);

/**
 * @type {(state: State) => string | null}
 */
export const label07 = featureValueOr(null, FEATURE_NAME_LABEL_07);

/**
 * @type {(state: State) => string}
 */
export const abTestlabel07 = compose(
  ifElse(
    isNotNil,
    (value) => `${FEATURE_NAME_LABEL_07}-wizz-flex-description-${value}`,
    always('wizz-flex-description')
  ),
  label07
);

/**
 * @type {(state: State) => string}
 */
export const seatMapUpsell = featureValueOr(
  SEAT_MAP_UPSELL_DEFAULT_VARIATION,
  FEATURE_NAME_SEAT_MAP_UPSELL
);

/**
 * @type {(state: State) => boolean}
 */
export const isSeatRecommendationFeatureEnabled = featureValueEqOr(
  false,
  SEAT_RECOMMENDATION_ENABLED,
  FEATURE_NAME_SEAT_RECOMMENDATION
);

/**
 * @type {(state: State) => boolean}
 */
// todo: needs to be replaced with actual implementation when the following PR gets merged:
//  https://devops.webdev-wizzair.local/DefaultCollection/WizzDev/_git/front-end/pullrequest/4196
export const isWdcTrialActive = () => false;

/**
 * @type {(state: State) => boolean}
 */
export const isPaymentExitIntentModalVersionA = featureValueEqOr(
  false,
  PAYMENT_EXIT_INTENT_MODAL_A,
  FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL
);

/**
 * @type {(state: State) => boolean}
 */
export const isPaymentExitIntentModalVersionB = featureValueEqOr(
  false,
  PAYMENT_EXIT_INTENT_MODAL_B,
  FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL
);

/**
 * @type {(state: State) => boolean}
 */
export const isPaymentExitIntentModalVersionC = featureValueEqOr(
  false,
  PAYMENT_EXIT_INTENT_MODAL_C,
  FEATURE_NAME_PAYMENT_EXIT_INTENT_MODAL
);

/**
 * @type {(state: State) => boolean}
 */
export const isPaymentExitIntentModalEnabled = either(
  isPaymentExitIntentModalVersionB,
  isPaymentExitIntentModalVersionC
);

/**
 * @type {(state: State) => boolean}
 */
export const isItineraryNewBookingDotComModalEnabled = featureValueEqOr(
  false,
  ITINERARY_NEW_BOOKING_COM_MODAL_VISIBLE,
  FEATURE_NAME_ITINERARY_NEW_BOOKING_COM_MODAL_VISIBILITY
);

export const isGoogleAdsOnSuccessfulPaymentEnabled = featureValueEqOr(
  false,
  GOOGLE_ADS_ENABLED,
  FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_PAYMENT
);

/**
 * @type {(state: State) => String}
 */
const flightSelectBundleOrderVariant = featureValueOr(
  BUNDLE_ORDER_DEFAULT,
  FEATURE_NAME_FLIGHT_SELECT_BUNDLE_ORDER
);

export const flightSelectBundleOrder = (state) =>
  FLIGHT_SELECT_BUNDLE_ORDER.get(flightSelectBundleOrderVariant(state));

/**
 * @type {(state: State) => boolean}
 */
export const isGoogleAdsOnSuccessfulCheckInEnabled = featureValueEqOr(
  false,
  GOOGLE_ADS_ENABLED,
  FEATURE_NAME_GOOGLE_ADS_SUCCESSFUL_CHECK_IN
);

export const isSeatingTogetherFeatureActive = featureValueEqOr(
  false,
  FEATURE_VALUE_SEATING_TOGETHER_ACTIVE,
  FEATURE_NAME_SEATING_TOGETHER
);

/**
 * @type {(state: State) => boolean}
 */
export const isProfileYourClaimsEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_PROFILE_YOUR_CLAIMS,
  FEATURE_NAME_PROFILE_YOUR_CLAIMS
);

/**
 * @type {(state: State) => boolean}
 */
export const isContactDataVersionA = featureValueEqOr(
  true,
  CONTACT_DATA_VERSION_A,
  FEATURE_NAME_CONTACT_DATA_VERSION
);

/**
 * @type {(state: State) => String}
 */
const farePricingVariant = featureValueOr(null, FEATURE_NAME_FARE_PRICING);

// for future reference: ga tracking was intentionally not implemented for this feature
/**
 * @type {(state: State) => Boolean}
 */
export const isFarePricingFeatureActive = compose(
  includedIn(FARE_PRICING_VARIANTS),
  farePricingVariant
);

/**
 * @type {(state: State) => boolean}
 */
export const chatbotAudienceDistribution = featureValueEqOr(
  false,
  FEATURE_VALUE_CHATBOT_AUDIENCE_DISTRIBUTION,
  FEATURE_NAME_CHATBOT_AUDIENCE_DISTRIBUTION
);

/**
 * @type {(state: State) => boolean}
 */
export const isChatbotPlaceholderEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHATBOT_PLACEHOLDER,
  FEATURE_NAME_CHATBOT_PLACEHOLDER
);

/**
 * @type {(state: State) => boolean}
 */
export const isChatbotEnabledOnMainPage = featureValueEqOr(
  false,
  FEATURE_VALUE_CHATBOT_MAIN_PAGE,
  FEATURE_NAME_CHATBOT_MAIN_PAGE
);

/**
 * @type {(state: State) => boolean}
 */
export const isEverymundoPixelFlightSearchEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_EVERYMUNDO_PIXEL_FLIGHT_SEARCH,
  FEATURE_NAME_EVERYMUNDO_PIXEL_FLIGHT_SEARCH
);

/**
 * @type {(state: State) => boolean}
 */
export const isEverymundoPixelSuccessfulBookingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING,
  FEATURE_NAME_EVERYMUNDO_PIXEL_SUCCESSFUL_BOOKING
);

/**
 * @type {(state: State) => boolean}
 */
export const isStickyBasketWithoutSidebarEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_STICKY_BASKET_WITHOUT_SIDEBAR_ENABLED,
  FEATURE_NAME_STICKY_BASKET_WITHOUT_SIDEBAR
);

/**
 * @type {(state: State) => boolean}
 */
export const isWdcDiscountAppliedOnBagsEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_WDC_DISCOUNT_APPLIED_ON_BAGS_ENABLED,
  FEATURE_NAME_WDC_DISCOUNT_APPLIED_ON_BAGS
);

export const isTravelPlanningMapSearchCountTrackingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING,
  FEATURE_NAME_IS_TRAVEL_PLANNING_MAP_SEARCH_COUNT_TRACKING
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPrmRedesignEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_PRM_SERVICE_ADDITION_ENABLED,
  FEATURE_NAME_PRM_SERVICE_ADDITION
);

/**
 * @type {(state: State) => String}
 */
export const heroBannerVariant = featureValueOr(null, FEATURE_NAME_HERO_BANNER_VARIANTS);

/**
 * @type {(state: State) => Boolean}
 */
export const isHeroBannerVariantsFeature = compose(
  includedIn(FEATURE_VALUE_HERO_BANNER_VARIANTS),
  heroBannerVariant
);

/**
 * @type {(state: State) => Boolean}
 */
export const isAirshipWebPushEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_AIRSHIP_WEB_PUSH_ENABLER,
  FEATURE_NAME_AIRSHIP_WEB_PUSH_ENABLER
);

/**
 * @type {(state: State) => Boolean}
 */
export const isCheckedinBag26kgEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHECKED_IN_BAGGAGE_26_KG_ENABLED,
  FEATURE_NAME_CHECKED_IN_BAGGAGE_26_KG
);

export const isLoginAtServicesStepEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_LOGIN_AT_SERVICES_STEP_ENABLED,
  FEATURE_NAME_LOGIN_AT_SERVICES_STEP
);

/**
 * @type {(state: State) => Boolean}
 */
export const isTileServicesEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_TILE_SERVICES_ENABLED,
  FEATURE_NAME_TILE_SERVICES
);

/**
 * @type {(state: State) => Boolean}
 */
export const isSmartBundleEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SMART_BUNDLE_ENABLED,
  FEATURE_NAME_SMART_BUNDLE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPayByLinkNameChangeEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_PAY_BY_LINK_NAME_CHANGE_ENABLED,
  FEATURE_NAME_PAY_BY_LINK_NAME_CHANGE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isSittingTogetherAccordionEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SITTING_TOGETHER_ACCORDION_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_ACCORDION
);

/**
 * @type {(state: State) => string}
 */
export const airshipNotificationDelay = featureValueOr(
  AIRSHIP_NOTIFICATION_DELAY_DEFAULT_VARIATION,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_DELAY
);

/**
 * @type {(state: State) => string}
 */
export const airshipNotificationMobileDelay = featureValueOr(
  AIRSHIP_NOTIFICATION_MOBILE_DELAY_DEFAULT_VARIATION,
  FEATURE_NAME_AIRSHIP_NOTIFICATION_MOBILE_DELAY
);

/**
 * @type {(state: State) => Boolean}
 */
export const isCheapFlightTrackingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHEAP_FLIGHT_TRACKING_ENABLER,
  FEATURE_NAME_CHEAP_FLIGHT_TRACKING_ENABLER
);

/**
 * @type {(state: State) => Boolean}
 */
export const isHotjarTrackingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_HOTJAR_ENABLER,
  FEATURE_NAME_HOTJAR_ENABLER
);

export const informationMenuPlacementVariant = featureValueOr(
  INFORMATION_MENU_PLACEMENT_A_DEFAULT,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacementVariantA = featureValueEqOr(
  true,
  INFORMATION_MENU_PLACEMENT_A_DEFAULT,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacementVariantB = featureValueEqOr(
  false,
  INFORMATION_MENU_PLACEMENT_B_DEFAULT_TRACKING,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacementVariantC = featureValueEqOr(
  false,
  INFORMATION_MENU_PLACEMENT_C_NEW_TRACKING,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacementVariantD = featureValueEqOr(
  false,
  INFORMATION_MENU_PLACEMENT_D_BOTH_TRACKING,
  FEATURE_NAME_INFORMATION_MENU_PLACEMENT
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacementTrackingEnabled = either(
  either(isInformationMenuPlacementVariantB, isInformationMenuPlacementVariantC),
  isInformationMenuPlacementVariantD
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacedOnTopNavigation = either(
  isInformationMenuPlacementVariantC,
  isInformationMenuPlacementVariantD
);

/**
 * @type {(state: State) => boolean}
 */
export const isInformationMenuPlacedOnLeftNavigation = either(
  either(isInformationMenuPlacementVariantA, isInformationMenuPlacementVariantB),
  isInformationMenuPlacementVariantD
);

export const isCheckInTemporaryMessageEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHECK_IN_TEMPORARY_MESSAGE_ENABLED,
  FEATURE_NAME_CHECK_IN_TEMPORARY_MESSAGE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isESimDataRoamingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_E_SIM_DATA_ROAMING_ENABLED,
  FEATURE_NAME_E_SIM_DATA_ROAMING
);

/*
 * @type {(state: State) => boolean}
 */
export const isRecaptchaOnRegistrationEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_RECAPTCHA_ON_REGISTRATION_ENABLED,
  FEATURE_NAME_RECAPTCHA_ON_REGISTRATION
);

/**
 * @type {(state: State) => Boolean}
 */
export const isWdcLightEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_WDC_LIGHT_ENABLED,
  FEATURE_NAME_WDC_LIGHT
);

/**
 * @type {(state: State) => Boolean}
 */
export const isClaimSupplementFormEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SUPPLEMENTAL_PAYMENT_ENABLED,
  FEATURE_NAME_SUPPLEMENTAL_PAYMENT
);

/**
 * @type {(state: State) => Boolean}
 */
export const isBlueRibbonBagTrackingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_BLUE_RIBBON_BAG_TRACKING_ENABLED,
  FEATURE_NAME_BLUE_RIBBON_BAG_TRACKING
);

export const isMandatoryPRMEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_ENABLED,
  FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW
);

export const mandatoryPrmValue = featureValueOr(
  FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_DISABLED,
  FEATURE_NAME_MANDATORY_PRM_ON_BOOKING_FLOW
);

/**
 * @type {(state: State) => boolean}
 */
export const isSmallBagEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SMALL_BAGS_ENABLED,
  FEATURE_NAME_SMALL_BAGS
);

/**
 * @type {(state: State) => boolean}
 */
export const isChooseSeatPromoPictureEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHOOSE_SEAT_PROMO_PICTURE_ENABLED,
  FEATURE_NAME_CHOOSE_SEAT_PROMO_PICTURE
);

/**
 * @type {(state: State) => boolean}
 */
export const isAddCorporateCardFeeEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_TOGGLE_ON,
  FEATURE_NAME_ADD_CORPORATE_CARD_FEE
);

/**
 * @type {(state: State) => boolean}
 */
export const isBundlePriceComparedToBasicEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_BUNDLE_PRICE_COMPARED_TO_BASIC_ENABLED,
  FEATURE_NAME_BUNDLE_PRICE_COMPARED_TO_BASIC
);

/**
 * @type {(state: State) => boolean}
 */
export const isFlightPastPriceEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_FLIGHT_PAST_PRICE_ENABLED,
  FEATURE_NAME_FLIGHT_PAST_PRICE
);

export const isShowSeatmapButtonEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SHOW_SEATMAP_BUTTON_NEW,
  FEATURE_NAME_SHOW_SEATMAP_BUTTON
);

/**
 * @type {(state: State) => boolean}
 */
export const isAirportCheckInComplianceOnFlightSelectEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT_ENABLED,
  FEATURE_NAME_AIRPORT_CHECK_IN_COMPLIANCE_ON_FLIGHT_SELECT
);

export const isWizzGoPerPersonFlightEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_WIZZ_GO_PER_PERSON_FLIGHT_ENABLED,
  FEATURE_NAME_WIZZ_GO_PER_PERSON_FLIGHT
);

export const isSittingTogetherPerPersonFlightEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SITTING_TOGETHER_PER_PERSON_FLIGHT_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_PER_PERSON_FLIGHT
);

export const isSittingTogetherWith30DaysCIEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SITTING_TOGETHER_WITH_30_DAYS_CI_ENABLED,
  FEATURE_NAME_SITTING_TOGETHER_WITH_30_DAYS_CI
);

/**
 * @type {(state: State) => boolean}
 */
export const isWizzPriorityBenefitsCopyVersionB = featureValueEqOr(
  false,
  FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_B,
  FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY
);

/**
 * @type {(state: State) => boolean}
 */
export const isWizzPriorityBenefitsCopyVersionC = featureValueEqOr(
  false,
  FEATURE_VALUE_WIZZ_PRIORITY_BENEFITS_COPY_VERSION_C,
  FEATURE_NAME_WIZZ_PRIORITY_BENEFITS_COPY
);

export const isMobileBottomMenuEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_MOBILE_BOTTOM_MENU_ENABLED,
  FEATURE_NAME_MOBILE_BOTTOM_MENU
);

/**
 * @type {(state: State) => boolean}
 */
export const isCheckInLegalInformationEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CHECK_IN_LEGAL_INFORMATION_ENABLED,
  FEATURE_NAME_CHECK_IN_LEGAL_INFORMATION
);

/**
 * @type {(state: State) => boolean}
 */
export const isFlightSearchEverywhereDesktopHidden = featureValueEqOr(
  false,
  FEATURE_VALUE_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP,
  FEATURE_NAME_HIDE_FLIGHT_SEARCH_EVERYWHERE_DESKTOP
);

/**
 * @type {(state: State) => boolean}
 */
export const isPartnerRedirectVariantB = featureValueEqOr(
  false,
  FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_B,
  FEATURE_NAME_EXPEDIA_POPUNDER
);
export const isPartnerRedirectVariantC = featureValueEqOr(
  false,
  FEATURE_VALUE_EXPEDIA_POPUNDER_VARIANT_C,
  FEATURE_NAME_EXPEDIA_POPUNDER
);

/**
 * @type {(state: State) => Boolean}
 */
export const isClosePassengerDetailsEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_CLOSE_PASSENGER_DETAILS_ENABLED,
  FEATURE_NAME_CLOSE_PASSENGER_DETAILS
);

/**
 * @type {(state: State) => Boolean}
 */
export const isFlightDetailBlockEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_REDESIGN_ENABLED,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_REDESIGN
);

export const isFareFinderPastPriceEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_FARE_FINDER_PAST_PRICE,
  FEATURE_NAME_FARE_FINDER_PAST_PRICE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPopularTagWithPicture = featureValueEqOr(
  false,
  FEATURE_VALUE_POPULAR_TAG_VARIANT_B,
  FEATURE_NAME_POPULAR_TAG
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPopularTagWithoutPicture = featureValueEqOr(
  false,
  FEATURE_VALUE_POPULAR_TAG_VARIANT_C,
  FEATURE_NAME_POPULAR_TAG
);

/**
 * @type {(state: State) => Boolean}
 */
export const isFlightDetailBlockCIComponentMobileEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE_ENABLED,
  FEATURE_NAME_FLIGHT_DETAIL_BLOCK_CI_COMPONENT_MOBILE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isSplitPaxEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SPLIT_PAX_ENABLED,
  FEATURE_NAME_SPLIT_PAX
);

export const initConfigCatCounter = featureProp('initConfigCatCounter');

/**
 * @type {(state: State) => Boolean}
 */
export const isNewPassengerDetailsEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_NEW_PASSENGER_DETAILS_ENABLED,
  FEATURE_NAME_NEW_PASSENGER_DETAILS
);

/**
 * @type {(state: State) => Boolean}
 */
export const isOtaFeeWarningEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_OTA_FEE_WARNING_ENABLED,
  FEATURE_NAME_OTA_FEE_WARNING
);

/**
 * @type {(state: State) => Boolean}
 */

export const isProfilePriceAlertEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_PROFILE_PRICE_ALERT_ENABLED,
  FEATURE_NAME_PROFILE_PRICE_ALERT
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPrivilegePassActivationNewBagPageEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE_ENABLED,
  FEATURE_NAME_PRIVILEGE_PASS_ACTIVATION_NEW_BAG_PAGE
);

/**
 * @type {(state: State) => Boolean}
 */
export const isNewCheckInAllDoneSeatingEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_NEW_CHECK_IN_ALL_DONE_SEATING_ENABLED,
  FEATURE_NAME_NEW_CHECK_IN_ALL_DONE_SEATING
);

/**
 * @type {(state: State) => Boolean}
 */
export const isRecommend26KgBagEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_RECOMMEND_26KG_BAG_ENABLED,
  FEATURE_NAME_RECOMMEND_26KG_BAG
);

/**
 * @type {(state: State) => Boolean}
 */
export const isSmartSearchEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_SMART_SEARCH_IS_ENABLED,
  FEATURE_FARE_FINDER_SMART_SEARCH
);

/**
 * @type {(state: State) => Boolean}
 */
export const isWizzPrioMobileViewEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_WIZZ_PRIO_MOBILE_VIEW_ENABLED,
  FEATURE_NAME_WIZZ_PRIO_MOBILE_VIEW
);

/**
 * @type {(state: State) => Boolean}
 */
export const isFlightDetailsBlockNewCtaEnabled = featureValueEqOr(
  false,
  FEATURE_VALUE_FLIGHT_DETAILS_BLOCK_NEW_CTA_ENABLED,
  FEATURE_NAME_FLIGHT_DETAILS_BLOCK_NEW_CTA
);
