import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import {
  moduleName,
  DEFAULT_SESSION_TIMEOUT_IN_MINUTES,
  DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS,
  DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS,
} from './constants';

export const getState = () => {
  const state = alter(getSessionItemOr({}, moduleName), getDefaultState());
  // note: once we bot detected a session we should keep popping up the modal on
  //  page reloads
  if (state.isBotBehaviorDetected) {
    state.volatile.isBotDetectionModalVisible = true;
  }
  return state;
};

export const getDefaultState = () => {
  return {
    isBotBehaviorDetected: false,
    isTimeoutsInitialized: false,
    isWebsiteDown: false,
    sessionTimeoutInMinutes: DEFAULT_SESSION_TIMEOUT_IN_MINUTES,
    inactivityTimeoutInSeconds: DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS,
    searchRedirectInMillisecs: DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS,
    websiteDownReason: '',
    loginRedirectUrl: null,
    isRouteLoading: false,

    volatile: {
      activeElementId: '',
      isBotDetectionModalVisible: false,
      isFindBookingModalVisible: false,
      isFlowResetModalVisible: false,
      isInformationAndServicesVisible: false,
      isInformationServicesMenuItemVisible: true,
      isInputFocused: false,
      isLanguageSwitcherVisible: false,
      isLoginLogoutFeedbackModalVisible: false,
      isInvalidDateOfBirthModalVisible: false,
      isMobileNavigationVisible: false,
      isNavigationButtonLoading: false,
      isNewPrivacyPolicyModalVisible: false,
      isPlanVisible: false,
      isTopFlightsVisible: false,
      isStickyHeaderVisible: false,
      isTimeoutModalDisabled: false,
      isTimeoutModalVisible: false,
      isCsrfErrorModalVisible: false,

      localesInsuranceModal: {
        isVisible: false,
        resolveCb: () => {},
      },

      changePasswordModal: {
        isVisible: false,
        isAgency: false,
        username: '',
      },

      forgotPasswordModal: {
        isVisible: false,
        username: '',
      },

      loginModal: {
        title: 'login',
        cancelButtonLabel: 'cancel',
        openingSource: '',
        deferred: null,
      },

      registrationModal: {
        isVisible: false,
        openingSource: '',
      },

      resetPasswordModal: {
        isVisible: false,
        encryptedText: null,
        extraParameter: null,
      },

      registrationPendingModal: {
        isVisible: false,
      },

      emailVerification: {
        isResendEmailVisible: false,
        errors: [],
        email: '',
      },
    },

    activeEndpoints: [],
  };
};
