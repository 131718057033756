import path from '~/utils/fp/path';
import {
  ACCESSIBILITY_READONLY,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
} from '~/constants';
import { isAdult, isChild } from '~/utils/booking/passenger';
import applySpec from '~/utils/fp/apply-spec';
import collect from '~/utils/fp/collect';
import compose from '~/utils/fp/compose';
import converge from '~/utils/fp/converge';
import flatten from '~/utils/fp/flatten';
import head from '~/utils/fp/head';
import orElse from '~/utils/fp/or-else';
import propOr from '~/utils/fp/prop-or';
import curry from '~/utils/fp/curry';
import find from '~/utils/fp/find';
import length from '~/utils/fp/length';
import filter from '~/utils/fp/filter';
import pathOr from '~/utils/fp/path-or';
import pick from '~/utils/fp/pick';
import prop from '~/utils/fp/prop';
import propEqTrue from '~/utils/fp/prop-eq-true';
import sortByProp from '~/utils/fp/sort-by-prop';
import sum from '~/utils/fp/sum';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import equals from '~/utils/fp/equals';
import { FLIGHT_CHANGE_STEP_PAYMENT_NAME } from '~/router/flight-change/constants';
import propEq from '~/utils/fp/prop-eq';
import map from '~/utils/fp/map';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import ifElse from '~/utils/fp/if-else';
import * as coreBookingGetters from '../core-booking/getters';
import * as bookingGetters from '../booking/getters';
import * as itineraryGetters from '../itinerary/getters';
import * as passengersGetters from '../passengers/getters';
import * as ancillariesGetters from '../ancillaries/getters';
import { hasItemsInCart } from '../summary/getters';

const flightChangeProp = curry((prop, state) => path(prop, state.flightChange));
const flightChangePropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, `flightChange.${prop}`, state)
);

export const currentStep = flightChangeProp('currentStep');

export const previousStepName = (state) => {
  const currentStepOrder = currentStep(state).order;
  const step = availableSteps(state)
    .reverse()
    .find((step) => step.order < currentStepOrder);
  return step?.name ?? '';
};

export const nextStepName = (state) => {
  const currentStepOrder = currentStep(state).order;
  const step = availableSteps(state).find((step) => step.order > currentStepOrder);
  return step?.name ?? '';
};

export const availableSteps = (state) => {
  const _hasItemsInCart = hasItemsInCart(state);

  return flightChangeProp('steps', state).reduce((acc, step) => {
    if (
      (step.name === FLIGHT_CHANGE_STEP_PAYMENT_NAME && _hasItemsInCart) ||
      step.name !== FLIGHT_CHANGE_STEP_PAYMENT_NAME
    ) {
      acc.push(step);
    }

    return acc;
  }, []);
};

export const isPassengerWithinSelectedPassengers = (state, passengerNumber) =>
  selectedPassengers(state).some(propEq('passengerNumber', passengerNumber));

export const passengers = (state) =>
  compose(
    map((passenger) => ({
      ...passenger,
      isSelected: isPassengerWithinSelectedPassengers(state, passenger.passengerNumber),
    })),
    flightChangePropWithFallback([], 'booking.passengerInformation')
  )(state);

/**
 * @type {(state: State) => boolean}
 */
export const isGroupBooking = flightChangeProp('booking.isGroupBooking');

export const outboundFlight = flightChangeProp('booking.outboundFlight');

export const returnFlight = flightChangeProp('booking.returnFlight');

export const flights = (state) => {
  return {
    [OUTBOUND_FLIGHT_NAME]: outboundFlight(state)?.value ?? null,
    [RETURN_FLIGHT_NAME]: returnFlight(state)?.value ?? null,
  };
};

const rebookDaysBeforeOriginalSTD = flightChangePropWithFallback(
  0,
  'booking.rebookDaysBeforeOriginalSTD'
);

const rebookDaysAfterOriginalSTD = flightChangePropWithFallback(
  0,
  'booking.rebookDaysAfterOriginalSTD'
);

export const rebookCalendarOffsets = (state) => [
  rebookDaysBeforeOriginalSTD(state),
  rebookDaysAfterOriginalSTD(state),
];

export const restrictions = flightChangeProp('booking.divideFlightChangeRestrictions');

export const isDisrupted = flightChangeProp('booking.isDisrupted');

export const selectedPassengers = compose(
  sortByProp('passengerNumber'),
  flightChangeProp('selectedPassengers')
);

const wizzFlexPassengersLength = compose(
  length,
  filter(propEqTrue('hasWizzFlex')),
  passengers
);

const selectedPassengersLength = compose(length, selectedPassengers);

const bookingPassengersLength = compose(length, passengers);

export const hasAnyPassengerWithWizzFlex = compose(gt(__, 0), wizzFlexPassengersLength);

export const areAllPassengersHaveWizzFlex = converge(equals, [
  wizzFlexPassengersLength,
  bookingPassengersLength,
]);

export const hasAnySelectedPassenger = compose(gt(__, 0), selectedPassengersLength);

export const areAllPassengersSelected = converge(equals, [
  selectedPassengersLength,
  bookingPassengersLength,
]);

export const outboundDepartureStation = compose(
  pathOr('', 'value.departureStation'),
  outboundFlight
);

export const canNotChangeDepartureStation = compose(
  equals(ACCESSIBILITY_READONLY),
  prop('accessibility'),
  outboundFlight
);

export const selectedAdultPassengersCount = compose(
  length,
  filter(isAdult),
  selectedPassengers
);

export const selectedChildrenPassengersCount = compose(
  length,
  filter(isChild),
  selectedPassengers
);

export const selectedInfantPassengerCount = compose(
  length,
  filter(propEqTrue('hasInfant')),
  selectedPassengers
);

export const hasInitializationError = compose(
  isNotEmpty,
  flightChangeProp('errors.initializationErrors')
);
export const initializationErrors = flightChangeProp('errors.initializationErrors');

export const hasPassengerError = compose(
  isNotEmpty,
  flightChangeProp('errors.passengerErrors')
);
export const passengerErrors = flightChangeProp('errors.passengerErrors');

export const hasSeatSelectionError = compose(
  isNotEmpty,
  flightChangeProp('errors.seatSelectionErrors')
);
export const seatSelectionErrors = flightChangeProp('errors.seatSelectionErrors');

export const paymentInitializationErrors = (state) => [
  ...flightChangeProp('errors.summaryErrors', state),
  ...flightChangeProp('errors.contactErrors', state),
];

export const hasPaymentInitializationError = compose(
  isNotEmpty,
  paymentInitializationErrors
);

export const services = flightChangeProp('services');

// Summary related stuff ⬇️

export const flightChangePassengers = (state) =>
  compose(map(passengerWithFee(state)), passengersGetters.passengersWithInfant)(state);

const passengerSpecificFeeByLeg = (passenger, direction, feeType) => [
  ...passenger.fees[direction].filter((fee) =>
    (fee?.specificType ?? '').includes(feeType)
  ),
];

const passengerWithSpecificFee = curry((feeType, passenger) => ({
  ...passenger,
  fees: {
    [DIRECTION_OUTBOUND]: passengerSpecificFeeByLeg(
      passenger,
      DIRECTION_OUTBOUND,
      feeType
    ),
    [DIRECTION_RETURN]: passengerSpecificFeeByLeg(passenger, DIRECTION_RETURN, feeType),
  },
}));

// note: Used at the additional costs step
export const passengersWithAdditionalCosts = compose(
  map(passengerWithSpecificFee('flight-change')),
  flightChangePassengers
);

// note: Used at the flight select step. since at this step the passengers
// store contains all of our passengers we should filter out those who selected
// it will don't any harm later because when going to next step (additional costs)
// a new booking will created and the passengers store will be filled the new passengers
// with the previously selected passengers. weak? yes. crazy? yes. tons of potential bugs? yes.
// welcome to world of session based development
export const flightChangeFlightSelectPassengers = (state) =>
  compose(
    sortByProp('passengerNumber'),
    map(passengerWithSpecificFee('fare-difference')),
    filter((passenger) =>
      selectedPassengers(state).some(propEq('passengerNumber', passenger.passengerNumber))
    ),
    flightChangePassengers
  )(state);

// notes it's safe to use head here since the seats does not have any other fee than
// it's price. the BE sends it in an array to keep a common price details structure
const seatFeeFrom = (state, { passengerNumber }, direction) =>
  compose(
    orElse({}),
    head,
    orElse([]),
    propOr([], direction),
    orElse({}),
    find(propEq('passengerNumber', passengerNumber)),
    pathOr([], 'seats.passengersList'),
    ancillariesGetters.priceDetails
  )(state);

const passengerFeesByDirection = (state, passenger, direction) =>
  [
    fareDifference(state, direction, passenger),
    ...(passengerFeePropOr(state, passenger.passengerNumber, `${direction}Fare`)?.fees ??
      []),
    seatFeeFrom(state, passenger, direction),
  ].filter(isNotEmpty);

const passengerWithFee = curry((state, passenger) => ({
  ...passenger,
  fees: {
    outbound: passengerFeesByDirection(state, passenger, DIRECTION_OUTBOUND),
    return: hasReturnLeg(state)
      ? passengerFeesByDirection(state, passenger, DIRECTION_RETURN)
      : [],
  },
}));

const fareDifference = (state, direction, { passengerNumber }) => {
  const fareDiffFromAncillaries = passengerFeePropOr(
    state,
    passengerNumber,
    `${direction}Fare`
  );
  const fareDiff = isNotEmpty(fareDiffFromAncillaries)
    ? fareDiffFromAncillaries
    : (
        passengerFarePrices(state).find(propEq('direction', direction))?.passengers || []
      ).find(propEq('passengerNumber', passengerNumber))?.[
        `${direction}FareDifference`
      ] || {};
  return {
    ...fareDiff,
    specificType: `divide-flight-summary-${direction}-fare-difference`,
  };
};

const fareByDirection = curry(
  (direction, state) =>
    bookingGetters.fares(state)?.[direction]?.passengerFlightPriceDetails ?? []
);

const outboundPassengerFlightPriceDetails = fareByDirection(DIRECTION_OUTBOUND);
const returnPassengerFlightPriceDetails = fareByDirection(DIRECTION_RETURN);

const passengerFarePrices = (state) =>
  [OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME].map((direction) => ({
    direction,
    passengers: fareByDirection(direction, state).map(({ passengerNumber, price }) => ({
      passengerNumber,
      [`${direction}FareDifference`]: { price },
    })),
  }));

export const totalFarePriceAmountFromBooking = compose(
  sum,
  map(pathOr(0, 'price.amount')),
  flatten,
  collect([outboundPassengerFlightPriceDetails, returnPassengerFlightPriceDetails])
);

export const passengerFeePropOr = (state, passengerNumber, prop) =>
  compose(
    pathOr({}, prop),
    find(propEq('passengerNumber', passengerNumber)),
    pathOr([], 'passengers.passengersList'),
    ancillariesGetters.priceDetails
  )(state);

const ancillariesPassengersWithExtraCosts = compose(
  pathOr([], 'passengerExtraCosts.passengersList'),
  ancillariesGetters.priceDetails
);

export const hasAnyPassengerWithExtraCost = compose(
  isNotEmpty,
  ancillariesPassengersWithExtraCosts
);

export const passengersWithExtraCosts = (state) =>
  compose(
    /**
     * @property {Number} originalPassengerNumber
     */
    map((extraCost) => ({
      ...itineraryGetters
        .passengers(state)
        .find(propEq('passengerNumber', extraCost.originalPassengerNumber)),
      fees: { ...pick(['outbound', 'return'], extraCost) },
    })),
    ancillariesPassengersWithExtraCosts
  )(state);

const totalsCurrencyCode = coreBookingGetters.bookingCurrencyCode;

const totalFlightsFee = compose(
  pathOr(0, 'totals.fees.amount'),
  ancillariesGetters.priceDetails
);

const totalFarePriceAmountFromAncillaries = compose(
  pathOr(0, 'totals.total.amount'),
  ancillariesGetters.priceDetails
);

export const additionalCostsSubtotal = applySpec({
  amount: totalFlightsFee,
  currencyCode: totalsCurrencyCode,
});

export const flightChangeSummaryTotal = applySpec({
  amount: ifElse(
    compose(gt(__, 0), totalFarePriceAmountFromAncillaries),
    totalFarePriceAmountFromAncillaries,
    totalFarePriceAmountFromBooking
  ),
  currencyCode: totalsCurrencyCode,
});

export const hasOutboundLeg = compose(
  isNotNil,
  path('value.flightSellKey'),
  outboundFlight
);

export const hasReturnLeg = compose(isNotNil, path('value.flightSellKey'), returnFlight);
