import _indexOf from '../_internal/_index-of';
import curry from '../curry';

// note: this is too loose we need a way to type function overloads
// note: this one almost works, but it still let this happen: `includes(1, 'foo bar')`
//   <T, U>(item: T, list: U extends string ? U : T[]): boolean
// note: this one works though if we want to
//   U extends { includes: (value: T) => boolean }
/**
 * @typedef {<T, U>(item: T, list: U) => boolean} IncludesFn
 */

/**
 * @type IncludesFn
 */
export const _includes = (item, list) => _indexOf(item, 0, list) >= 0;

/**
 * Returns `true` if the specified value is equal, in [`R.equals`](#equals)
 * terms, to at least one element of the given list; `false` otherwise.
 * Works also with strings.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/includes.js
 *
 * @type IncludesFn
 * @example
 *
 *      includes(3, [1, 2, 3]); //=> true
 *      includes(4, [1, 2, 3]); //=> false
 *      includes({ name: 'Fred' }, [{ name: 'Fred' }]); //=> true
 *      includes([42], [[42]]); //=> true
 *      includes('ba', 'banana'); //=>true
 */
const includes = curry(_includes);

export default includes;
