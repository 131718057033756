import { to } from 'await-to-js';
import log from 'loglevel';
import { LANGUAGE_CODE_EN_GB } from '~/constants';
import { languageCodeToLocaleCodeMap } from './constants';

export const init = async (languageCode) => {
  const locale = await loadLocalization(languageCode);
  languageCodeToLocaleMap.set(languageCode, locale);
};

export const getLocalization = (languageCode) => {
  if (!languageCodeToLocaleMap.has(languageCode)) {
    throw new Error(`Please initialize locale (${languageCode}) before using it.`);
  }
  return languageCodeToLocaleMap.get(languageCode);
};

const loadLocalization = async (languageCode) => {
  const [error, locale] = await to(_loadLocalization(languageCode));

  if (error && languageCode !== LANGUAGE_CODE_EN_GB) {
    log.error(`Failed to load locale (${languageCode}). Trying to load default locale.`);
    return loadLocalization(LANGUAGE_CODE_EN_GB);
  } else if (error) {
    log.error(`Failed to load locale (${languageCode}).`);
    throw error;
  }

  return locale;
};

const _loadLocalization = async (languageCode) => {
  // note: webpackInclude regexp is sensitive to unix/windows path separators
  return (
    await import(
      /* webpackInclude: /locale[/\\][-a-z]+[/\\]index\.js/i */ `date-fns/locale/${languageCodeToLocaleCodeMap.get(
        languageCode
      )}/index.js`
    )
  ).default;
};

const languageCodeToLocaleMap = new Map();
