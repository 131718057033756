import { to } from 'await-to-js';
import { createAction } from '~/utils/store';
import { captureAndLogException } from '~/utils/logging';
import * as PromotionService from '~/services/promotion';
import * as m from './mutation-types';

export const reset = createAction(m.RESET);

export const getPromotion = async ({ commit }) => {
  const [error, response] = await to(PromotionService.getAncillaryPromoDetails());
  if (error) return captureAndLogException(error);
  commit(m.SET_ANCILLARY_PROMO_DETAILS, response.data);
};

export const addPromotionToTrack = createAction(m.ADD_PROMOTION_TO_TRACK);
