import compose from '~/utils/fp/compose';
import prop from '~/utils/fp/prop';
import reduce from '~/utils/fp/reduce';
import chain from '~/utils/fp/chain';

/**
 * @param {Array} seatMap seat map to get the last row from
 * @returns {Array} array of seats from the last row
 */
const getLastRowSeats = compose(
  prop('seats'),
  reduce((pRow, cRow) => (cRow.rowNumber > pRow.rowNumber ? cRow : pRow), {
    rowNumber: -1,
    seats: [],
  }),
  chain((g) => g.rows)
);

export default getLastRowSeats;
