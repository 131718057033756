import isFunction from '~/utils/object/is-function';
import curry from '../curry';
import type from '../type';
import keys from '../keys';
import { _has } from '../has';
import _functionName from '../_internal/_function-name';
import _includesWith from '../_internal/_includes-with';

/**
 * @typedef {(a: any, b: any) => boolean} EqualsFn
 */

/**
 * @type EqualsFn
 */
export const _equals = (a, b) => loop(a, b, [], []);

const loop = (a, b, stackA, stackB) => {
  if (Object.is(a, b)) return true;

  const typeA = type(a);
  if (typeA !== type(b)) return false;

  if (a == null || b == null) return false;

  switch (typeA) {
    case 'Arguments':
    case 'Array':
    case 'Object':
      if (isFunction(a.constructor) && _functionName(a.constructor) === 'Promise')
        return a === b;
      break;
    case 'Boolean':
    case 'Number':
    case 'String':
      if (!(typeof a === typeof b && Object.is(a.valueOf(), b.valueOf()))) return false;
      break;
    case 'Date':
      if (!Object.is(a.valueOf(), b.valueOf())) return false;
      break;
    case 'Error':
      return a.name === b.name && a.message === b.message;
    case 'RegExp':
      if (
        !(
          a.source === b.source &&
          a.global === b.global &&
          a.ignoreCase === b.ignoreCase &&
          a.multiline === b.multiline &&
          a.sticky === b.sticky &&
          a.unicode === b.unicode
        )
      ) {
        return false;
      }
      break;
    // no default
  }

  let index = stackA.length - 1;
  while (index >= 0) {
    if (stackA[index] === a) return stackB[index] === b;
    index -= 1;
  }

  switch (typeA) {
    case 'Map':
      if (a.size !== b.size) return false;
      return _uniqContentEquals(
        a.entries(),
        b.entries(),
        stackA.concat([a]),
        stackB.concat([b])
      );

    case 'Set':
      if (a.size !== b.size) return false;
      return _uniqContentEquals(
        a.values(),
        b.values(),
        stackA.concat([a]),
        stackB.concat([b])
      );

    case 'Arguments':
    case 'Array':
    case 'Object':
    case 'Boolean':
    case 'Number':
    case 'String':
    case 'Date':
    case 'Error':
    case 'RegExp':
    case 'Int8Array':
    case 'Uint8Array':
    case 'Uint8ClampedArray':
    case 'Int16Array':
    case 'Uint16Array':
    case 'Int32Array':
    case 'Uint32Array':
    case 'Float32Array':
    case 'Float64Array':
    case 'ArrayBuffer':
      break;

    default:
      // Values of other types are only equal if identical.
      return false;
  }

  const keysA = keys(a);
  if (keysA.length !== keys(b).length) return false;

  const extendedStackA = stackA.concat([a]);
  const extendedStackB = stackB.concat([b]);

  index = keysA.length - 1;
  while (index >= 0) {
    const key = keysA[index];
    if (!(_has(key, b) && loop(b[key], a[key], extendedStackA, extendedStackB)))
      return false;
    index -= 1;
  }

  return true;
};

const _uniqContentEquals = (aIterator, bIterator, stackA, stackB) => {
  const a = [...aIterator];
  const b = [...bIterator];
  const eq = (_a, _b) => loop(_a, _b, stackA.slice(), stackB.slice());
  // if *a* array contains any element that is not included in *b*
  return !_includesWith((b, aItem) => !_includesWith(eq, aItem, b), b, a);
};

/**
 * Returns `true` if its arguments are equivalent, `false` otherwise. Handles
 * cyclical data structures.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/equals.js
 *
 * @type EqualsFn
 * @example
 *
 *      equals(1, 1); //=> true
 *      equals(1, '1'); //=> false
 *      equals([1, 2, 3], [1, 2, 3]); //=> true
 *
 *      let a = {}; a.v = a;
 *      let b = {}; b.v = b;
 *      equals(a, b); //=> true
 */
const equals = curry(_equals);

export default equals;
