export const RESET = 'summary/reset';
export const SET_MCP_SELECTION_SOURCE = 'summary/setMcpSelectionSource';
export const RESET_SUMMARY = 'summary/resetSummary';
export const UPDATE_FLIGHTS_FLIGHT = 'summary/updateFlightsFlight';
export const CLEAR_FLIGHTS_FLIGHT = 'summary/clearFlightsFlight';
export const UPDATE_FLIGHTS_FARE_LOCK = 'summary/updateFlightsFareLock';
export const CLEAR_FLIGHTS_FARE_LOCK = 'summary/clearFlightsFareLock';
export const UPDATE_FLIGHTS_WDC_MEMBERSHIP = 'summary/updateFlightsWdcMembership';
export const CLEAR_FLIGHTS_WDC_MEMBERSHIP = 'summary/clearFlightsWdcMembership';
export const UPDATE_FLIGHTS_WDC_RENEWAL = 'summary/updateFlightsWdcRenewal';
export const CLEAR_FLIGHTS_WDC_RENEWAL = 'summary/clearFlightsWdcRenewal';
export const UPDATE = 'summary/update';
export const INCREMENT_LOADING_COUNTER = 'summary/incrementLoadingCounter';
export const DECREMENT_LOADING_COUNTER = 'summary/decrementLoadingCounter';
export const SET_EXCHANGED_AMOUNT_DUE = 'summary/setExchangedAmountDue';
export const SET_EXCHANGED_AMOUNT_DUE_WITHOUT_THIRD_PARTY_SERVICES =
  'summary/setExchangedAmountDueWithoutThirdPartyServices';
export const UPDATE_PAST_PURCHASES = 'summary/setPastPurchases';
export const SET_PAYABLE_FLIGHT_AND_FARE = 'summary/setPayableFlightAndFare';
export const SET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT_FLAG =
  'summary/setIsWizzFlexSelectedAtFlightSelectFlag';
export const UNSET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT =
  'summary/unsetIsWizzFlexSelectedAtFlightSelect';
export const BACKUP_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT =
  'summary/backupIsWizzFlexSelectedAtFlightSelect';
export const RESTORE_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT =
  'summary/restoreIsWizzFlexSelectedAtFlightSelect';
export const SET_INSURANCE_ADD_TO_CART = 'summary/setInsuranceAddToCart';
export const RESET_TOTAL_AMOUNT = 'summary/resetTotalAmount';
