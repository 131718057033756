import log from 'loglevel';
import { _hassocPath } from '~/utils/object/hassoc-path';
import { _append } from '~/utils/fp/append';
import { _path } from '~/utils/fp/path';

/**
 * @type {(getters: Object<string, Function>) => (names: string[], state: State) => Object<string, any>}
 * @example
 *
 *   query(['getterName1', 'getterName2', 'getterName3'], state)
 *     => { getterName1: x, getterName2: y, getterName3: z}
 *
 *   query(['getterName1:targetName1', 'getterName2:targetName2', 'getterName3:targetName3'], state)
 *     => { targetName1: x, targetName2: y, targetName3: z}
 */
export const createQuery = (getters) => (names, state) =>
  names.reduce((acc, name) => {
    const [getterName, targetName = getterName] = name.split(':');
    acc[targetName] = getters[getterName](state);
    return acc;
  }, {});

/**
 * Simple action creator which creates an action which only dispatches the
 * specified mutation type.
 *
 * @param {String} mutationType
 * @returns {Function} an action function which accepts (store, ...rest) params
 */
export const createAction =
  (mutationType) =>
  (...args) => {
    const [store, ...rest] = args;
    if (rest.length > 1) {
      log.warn(
        `[WARN] Please check usage of '${mutationType}' and relying action, they should have a single payload argument beside store/state!`
      );
    }
    store.commit(mutationType, ...rest);
  };

/**
 * @type {(path: string) => (state: State, value: any) => void}
 */
export const mutationAssocPath = (path) => (state, value) =>
  _hassocPath(path, value, state);

/**
 * @type {(path: string) => (state: State, value: any) => void}
 */
export const mutationAppend = (path) => (state, value) =>
  _hassocPath(path, _append(value, _path(path, state)), state);

/**
 * @type {<T, U>(fn: (value: T) => U, path: string) => (state: State, value: T) => void}
 */
export const mutationAssocPathWith = (fn, path) => (state, value) =>
  _hassocPath(path, fn(value), state);
