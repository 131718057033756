export const round = (number, decimalPlaces = 2) => {
  number = Number(number);

  if (Number.isNaN(number)) {
    throw new Error('Expected number as the 1st parameter');
  }

  if (decimalPlaces < 0) {
    throw new Error('Expected positive number as the 2nd parameter');
  }

  const raised = Math.pow(10, decimalPlaces);
  return Math.round(Number(number) * raised) / raised;
};
