import curry from '../curry';
import always from '../always';
import { _adjust } from '../adjust';

/**
 * @typedef {<T>(index: number, x: T, arr: T[]) => T[]} UpdateFn
 */

/**
 * @type UpdateFn
 */
export const _update = (index, x, arr) => _adjust(always(x), index, arr);

/**
 * Returns a new copy of the array with the element at the provided index
 * replaced with the given value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/update.js
 *
 * @type UpdateFn
 * @example
 *
 *      update(1, 11, [0, 1, 2]);     //=> [0, 11, 2]
 *      update(1)(11)([0, 1, 2]);     //=> [0, 11, 2]
 */
const update = curry(_update);

export default update;
