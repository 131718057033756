import axios from 'axios';
import pathOr from '~/utils/fp/path-or';
import { http } from '~/utils/cache';
import equals from '~/utils/fp/equals';
import { PRICE_TYPE_CHECK_PRICE } from '~/constants';
import {
  SEARCH_SEARCH,
  SEARCH_TIMETABLE,
  SEARCH_ADDITIONAL_FEES,
  SEARCH_WIZZ_DISCOUNT_CLUB,
  SEARCH_FLIGHT_DATES,
  BOOKING_COM_HOTELS_SEARCH,
  CARS_LOCATION_SEARCH,
  CARS_LOCATION_SEARCH_BY_IATA,
} from '~/constants/api-endpoint';

// timetable responses are pretty big and kinda static, so we can store them in the memory
export const getTimetable = (data) =>
  http.post(SEARCH_TIMETABLE, data, prepTimetableData);

export const getFlights = (data) => {
  data = Object.assign(
    {
      isFlightChange: false,
    },
    data
  );

  return axios.post(SEARCH_SEARCH, data).then((response) => {
    // outbound flights may be missing (flight change, outbound dep date is in the past)
    // and since the backend sends nulls, let's replace them with empty arrays
    if (response && response.data) {
      const data = response.data;
      data.outboundFlights = pathOr([], 'data.outboundFlights', response);
      data.outboundBundles = pathOr([], 'data.outboundBundles', response);
      backupFlightIsRequestFlag(data.outboundFlights);
      if (Array.isArray(data.returnFlights)) {
        backupFlightIsRequestFlag(data.returnFlights);
      }
    }
    return response;
  });
};

export const getAdditionalFees = () => axios.get(SEARCH_ADDITIONAL_FEES);

export const getWdcMemberships = () => axios.get(SEARCH_WIZZ_DISCOUNT_CLUB);

export const getFlightDates = (departureStationIata, arrivalStationIata, from, until) =>
  http.get(SEARCH_FLIGHT_DATES, {
    departureStation: departureStationIata,
    arrivalStation: arrivalStationIata,
    from,
    to: until,
  });

export const getBookingComDestinations = (locale, term) => {
  return axios.post(BOOKING_COM_HOTELS_SEARCH, {
    language: locale,
    text: term,
  });
};

const backupFlightIsRequestFlag = (flights) =>
  flights.forEach((flight) => {
    flight.__isRequest = Boolean(flight.isRequest); // isReq is always a bool
  });

// throw away invalid results (mutates and returns)
const prepTimetableData = (data) => {
  data.outboundFlights = (data.outboundFlights || []).filter(
    (flight) =>
      flight?.price?.amount > 0 || equals(flight.priceType, PRICE_TYPE_CHECK_PRICE)
  );
  data.returnFlights = (data.returnFlights || []).filter(
    (flight) =>
      flight?.price?.amount > 0 || equals(flight.priceType, PRICE_TYPE_CHECK_PRICE)
  );
  return data;
};

export const getCarsLocations = (locale, term) =>
  axios.post(CARS_LOCATION_SEARCH, {
    language: locale,
    text: term,
  });

export const getCarsLocationByIata = (locale, iata) =>
  axios.post(CARS_LOCATION_SEARCH_BY_IATA, {
    language: locale,
    iata,
  });
