import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import { getDefaultState } from './internal';
import mutations from './mutations';

const moduleName = 'checkIn';
const state = alter(getSessionItemOr({}, moduleName), getDefaultState());

export default {
  state,
  mutations,
};
