import hassocPath from '~/utils/object/hassoc-path';
import * as m from './mutation-types';
import { getDefaultState } from './internal';

const savePnrInfo = (state, payload = {}) => {
  const { pnr, lastName, firstName } = payload;
  hassocPath(
    'pnrAndNames',
    {
      pnr: pnr.toLowerCase(),
      firstName: firstName.toLowerCase(),
      lastName: lastName.toLowerCase(),
    },
    state
  );
};

const reset = (state) => {
  Object.assign(state, getDefaultState());
};

export default {
  [m.SAVE_PNR_INFO]: savePnrInfo,
  [m.RESET_FIND_BOOKINGS]: reset,
};
