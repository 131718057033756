import curry from '../curry';
import { _defaultTo } from '../default-to';
import { _prop } from '../prop';

/**
 * @typedef {<T, K extends keyof T, U>(defaultValue: U, p: K, obj: T) => T[K] | U} PropOrFn
 */

/**
 * @type PropOrFn
 */
export const _propOr = (defaultValue, p, obj) => _defaultTo(defaultValue, _prop(p, obj));

/**
 * If the given, non-null object has an own property with the specified name,
 * returns the value of that property. Otherwise returns the provided default
 * value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/propOr.js
 *
 * @type PropOrFn
 * @example
 *
 *      let alice = {
 *        name: 'ALICE',
 *        age: 101
 *      };
 *      let favorite = prop('favoriteLibrary');
 *      let favoriteWithDefault = propOr('Ramda', 'favoriteLibrary');
 *
 *      favorite(alice);  //=> undefined
 *      favoriteWithDefault(alice);  //=> 'Ramda'
 */
const propOr = curry(_propOr);

export default propOr;
