import isFunction from '~/utils/object/is-function';
import keys from '../keys';
import curryN from '../curry-n';
import { _max } from '../max';
import values from '../values';
import { _pluck } from '../pluck';
import { _reduce } from '../reduce';

/**
 * Given a spec object recursively mapping properties to functions, creates a
 * function producing an object of the same structure, by mapping each property
 * to the result of calling its associated function with the supplied arguments.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/applySpec.js
 *
 * @type {(spec: Object<string, any>) => Object<string, any>}
 * @example
 *
 *      const getMetrics = applySpec({
 *        sum: add,
 *        nested: { mul: multiply }
 *      });
 *      getMetrics(2, 4); // => { sum: 6, nested: { mul: 8 } }
 */
const applySpec = (spec) => {
  spec = mapValues((v) => (isFunction(v) ? v : applySpec(v)), spec);
  return curryN(_reduce(_max, 0, _pluck('length', values(spec))), (...args) =>
    mapValues((f) => f(...args), spec)
  );
};

// note: use custom mapValues function to avoid issues with specs that include a "map" key and R.map
// delegating calls to .map
const mapValues = (fn, obj) =>
  keys(obj).reduce((acc, key) => {
    acc[key] = fn(obj[key]);
    return acc;
  }, {});

export default applySpec;
