import log from 'loglevel';
import * as accounting from 'accounting-js';
import {
  STRICTLY_ROUNDED_CURRENCIES,
  LOCALIZATION,
  ROUNDED_CURRENCIES,
} from '~/constants';
import { getI18n } from '~/i18n';
import { LOCALE } from './localization';
import isObject from './object/is-object';
import isNumber from './object/is-number';
import { escapeStringRegexp } from './string';

export function removeCurrencySymbol(s) {
  return s.replace(/[^\d',.·˙’]/g, '');
}

// locale is optional
export function unformatNumber(s, locale) {
  // technically it's either 0 or 2 with the languages we support, but
  // this is a currency related value, so we are going to cheat a bit
  const decimalPrecision = Math.pow(10, 2);

  // in case someone adds '.' as a currency symbol, which did happen
  // for a reason that is beyond my imagination
  if (s.split('.').length > 2 && s.startsWith('.')) {
    log.warn('unformat number error, invalid format detected.');
  }

  const localeData = LOCALIZATION.locales[locale || LOCALE];
  const thousandSeparator = localeData.thousand;
  const decimalSeparator = localeData.decimal;
  const thousandRex = new RegExp(escapeStringRegexp(thousandSeparator), 'g');

  s = s.replace(thousandRex, '').replace(decimalSeparator, '.');
  const decChunks = s.split(decimalSeparator);
  if (decChunks.length > 2) {
    s = decChunks.splice(0, 2).join(decimalSeparator);
  }
  s = removeCurrencySymbol(s.replace(/\s/g, ''));

  const val = Math.round(Number.parseFloat(s, 10) * decimalPrecision) / decimalPrecision;
  return val || 0;
}

// format number to currency
export function format(value, currencyCode, locale, asNumberOnly) {
  locale = locale || LOCALE;
  const localeData = LOCALIZATION.locales[locale];
  const currencyData = LOCALIZATION.currencies[currencyCode];

  if (!currencyCode)
    throw new Error(`Currency code missing from { amount, currencyCode }!`);
  if (!localeData) throw new Error(`Missing locale "${locale}", update localization!`);
  if (!currencyData)
    throw new Error(`Missing currency data for "${currencyCode}", update localization!`);

  let currencyFormat = localeData.currencyFormat;
  if (isObject(localeData.currencyFormat)) {
    currencyFormat =
      value < 0 ? localeData.currencyFormat.negative : localeData.currencyFormat.positive;
  }

  if (value < 0) {
    currencyFormat = `-${currencyFormat}`;
  }

  const isRounded = ROUNDED_CURRENCIES.includes(currencyCode);
  const isStrictlyRounded = STRICTLY_ROUNDED_CURRENCIES.includes(currencyCode);
  // the format is locale specific, but the currencyCode is the one specified in the param
  const options = {
    symbol: currencyData.symbol,
    format: currencyFormat,
    // if the number is whole && included in rounded currencies, we should lose the decimal
    decimal: isRounded && value % 1 === 0 ? '' : localeData.decimal,
    thousand: localeData.thousand,
    // if the number is whole && included in rounded currencies, we should lose the precision
    precision:
      (isRounded && value % 1 === 0) || isStrictlyRounded ? 0 : currencyData.precision,
  };

  // filter it through localization
  const locSymbolKey = `currency_symbol_${currencyCode}`; // for example "currency_symbol_RSD"
  const localizedSymbol = getI18n().t(locSymbolKey);
  if (localizedSymbol !== locSymbolKey) {
    options.symbol = localizedSymbol;
  }

  let result;
  if (asNumberOnly) {
    result = accounting.formatNumber(value, options);
  } else {
    value = Math.abs(value);
    result = accounting.formatMoney(value, options);
  }

  return result.replace(/\s/g, ' '); // accounting uses utf8 nbsp, we replace that to simple spaces
}

export const formatCurrency = (value, currency = '', params) => {
  // usable with one parameter too, in that case pass the usual price object
  if (isObject(value) && isNumber(value.amount)) {
    // quacks like a duck
    currency = value.currencyCode;
    value = value.amount;
  }

  let hide = false;
  if (currency === '' || (currency === null && value != null)) {
    currency = 'EUR'; // for the symbol substitution
    hide = true; // we could show the price, but that would hide deeper problems
  }

  if (value == null) return ''; // store is empty, view is still being rendered

  // add left-to-right mark in front of all currencies (so RTL languages would not mess it up)
  let result;
  const asNumberOnly = isObject(params) && params.omitCurrency;
  try {
    result = `\u200e${format(value, currency, null, asNumberOnly)}`;
  } catch (error) {
    log.error(error);
    result = String(value);
  }

  // replace all spaces (or everything) with non-breakable ones
  // but the question is: why everything? I don't remember, I don't know.
  return result.replace(hide ? /./g : /\s/g, '\u00A0');
};

export const localizedCurrency = (currencyCode) =>
  getI18n().t(`currency_symbol_${currencyCode}`);

export const isValidCurrency = (currencyCode) => {
  return Boolean(LOCALIZATION.currencies[currencyCode]);
};
