import pathOr from '~/utils/fp/path-or';
import { isServer } from '~/utils/ssr';
import { getLocale } from '~/utils/localization';
import { getUrls } from '~/utils/global-value/urls';
import { isSSRBuild } from '~/utils/ssr/build-type';

export const getPath = (name) => {
  if (!isSSRBuild) return pathOr('', name, window.urls);

  if (isServer()) {
    const store = require('~/store').getStore();
    return getPathWithoutDomainAndCultureCode(getUrls(store.state)[name] ?? name);
  }
  return getPathWithoutDomainAndCultureCode(getUrls()[name] ?? name);
};

export const getPathWithoutDomainAndCultureCode = (url) => {
  if (!url) return '';

  const { baseUrl } = require('../browser/base-url');

  return url.replace(new RegExp(`(${baseUrl}|${getLocale()}|^/)/?`, 'g'), '');
};
