import log from 'loglevel';

let i18n = null;

export const getInstance = () => {
  if (!i18n) log.error(`Please don't forget to init i18n before usage.`);
  return i18n;
};

export const setInstance = (instance) => (i18n = instance);
