import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => boolean} GtFn
 */

/**
 * @type GtFn
 */

export const _gt = (a, b) => a > b;

/**
 * Returns `true` if the first argument is greater than the second; `false`
 * otherwise.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/gt.js
 *
 * @type GtFn
 * @example
 *
 *      gt(2, 1); //=> true
 *      gt(2, 2); //=> false
 *      gt(2, 3); //=> false
 */
const gt = curry(_gt);

export default gt;
