import log from 'loglevel';
import { sendGaEvent } from '../analytics/send-ga-event';
import { shouldTrackOutboundLink } from '../analytics/outbound-link-tracker';
import parseUrl from './parse-url';

export const setHref = (href, replace = false) => {
  if (!href) {
    return log.error('setHref empty href');
  }
  if (shouldTrackOutboundLink(href, parseUrl)) {
    sendGaEvent({ category: 'Outbound Link', action: 'click', label: href });
  }
  if (replace) {
    return window.location.replace(href);
  }
  window.location.href = href;
};
