import { BUNDLE_MIDDLE } from '~/constants';
import equals from '~/utils/fp/equals';
import update from '~/utils/fp/update';
import findIndex from '~/utils/fp/find-index';
import isNil from '~/utils/object/is-nil';
import { getDefaultPrice } from '~/utils/price';
import {
  ANCILLARY_PREMIUM_SEAT_LABEL_KEY,
  ANCILLARY_REGULAR_SEAT_LABEL_KEY,
} from '../ancillaries/internal';
import * as m from './mutation-types';

const resetUpsellInfo = (state) => {
  state.upsellInfo = {
    isOverPriceMinimum: false,
    ancillaries: [],
    price: getDefaultPrice(),
    saving: getDefaultPrice(),
  };
};

const resetPostBookingUpsellInfo = (state) => {
  state.postBookingUpsellInfo.isFirstFetch = true;
  state.postBookingUpsellInfo.isUpsellAvailable = false;
  state.postBookingUpsellInfo.shouldFetchUpsell = true;
  state.postBookingUpsellInfo.originalPrice = null;
  state.postBookingUpsellInfo.price = getDefaultPrice();
};

export default {
  [m.UPDATE_UPSELL_INFO](state, payload) {
    const { bundleUpsells } = payload;

    if (isNil(bundleUpsells)) return resetUpsellInfo(state);

    let { ancillaries = [] } = bundleUpsells;
    if (isNil(ancillaries)) ancillaries = [];

    const {
      isOverPriceMinimum = false,
      saving = getDefaultPrice(),
      price = getDefaultPrice(),
      upsellToBundle,
    } = bundleUpsells;

    const seatSelectionLabelKeyIndex = findIndex(
      equals(ANCILLARY_PREMIUM_SEAT_LABEL_KEY),
      ancillaries
    );
    if (upsellToBundle === BUNDLE_MIDDLE && seatSelectionLabelKeyIndex > -1) {
      ancillaries = update(
        seatSelectionLabelKeyIndex,
        ANCILLARY_REGULAR_SEAT_LABEL_KEY,
        ancillaries
      );
    }

    state.upsellInfo.isOverPriceMinimum = isOverPriceMinimum;
    state.upsellInfo.ancillaries = ancillaries;
    state.upsellInfo.price = price;
    state.upsellInfo.saving = saving;
    state.upsellInfo.upsellToBundle = upsellToBundle;
  },

  [m.UPDATE_POST_BOOKING_UPSELL_INFO](state, payload) {
    const { bundleUpsells } = payload;

    if (isNil(bundleUpsells)) {
      // normally we fetch for bundle upsell info when the itinerary loads and after every ancillary call to
      // recalculate its values but if the first fetch is null, we don't want to make further unnecessary api calls,
      // as it means that the bundle upsell option is not available for this session at all.
      if (state.postBookingUpsellInfo.isFirstFetch) {
        state.postBookingUpsellInfo.shouldFetchUpsell = false;
      }
      state.postBookingUpsellInfo.isUpsellAvailable = false;
      state.postBookingUpsellInfo.price = getDefaultPrice();
      state.postBookingUpsellInfo.originalPrice = null;
    } else {
      state.postBookingUpsellInfo.isUpsellAvailable = true;
      state.postBookingUpsellInfo.price = bundleUpsells.price;
      state.postBookingUpsellInfo.originalPrice = bundleUpsells.originalPrice;
    }

    state.postBookingUpsellInfo.isFirstFetch = false;
  },

  [m.RESET_POST_BOOKING_UPSELL_INFO]: resetPostBookingUpsellInfo,

  [m.SET_POST_UPGRADE_INFO](state, payload = {}) {
    const { upgradeLocation, upgradePrice, upgradeBundle } = payload;
    state.upgradeLocation = upgradeLocation;
    state.upgradePrice = upgradePrice;
    state.upgradeBundle = upgradeBundle;
  },

  [m.UNSET_POST_UPGRADE_INFO](state) {
    state.upgradeLocation = null;
    state.upgradePrice = null;
    state.upgradeBundle = '';
  },

  [m.SET_IS_POST_BOOKING_UPGRADE_STARTED](state) {
    state.isPostBookingUpgradeStarted = true;
  },

  [m.UNSET_IS_POST_BOOKING_UPGRADE_STARTED](state) {
    state.isPostBookingUpgradeStarted = false;
  },
};
