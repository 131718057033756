import axios from 'axios';
import { BOOKING_CARFRAME } from '~/constants/api-endpoint';

export const submitCar = (payload) => {
  const data = {
    Product: payload.product,
    Visitor: payload.visitor,
    DriverAge: payload.driverAge,
    Name: payload.productName,
  };
  return axios.post(BOOKING_CARFRAME, data);
};

export const deleteCar = () => {
  return axios({
    url: BOOKING_CARFRAME,
    method: 'delete',
  });
};
