import curry from '../curry';

/**
 * @typedef {<T, U, V extends any []>(f: (...args: V) => T, g: (...args: V) => U) => (...args: V) => T | U} BothFn
 */

/**
 * @type BothFn
 */
export const _both =
  (f, g) =>
  (...args) =>
    f(...args) && g(...args);

/**
 * A function which calls the two provided functions and returns the `&&`
 * of the results.
 * It returns the result of the first function if it is false-y and the result
 * of the second function otherwise. Note that this is short-circuited,
 * meaning that the second function will not be invoked if the first returns a
 * false-y value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/both.js
 *
 * @type BothFn
 * @example
 *
 *      let gt10 = gt(__, 10)
 *      let lt20 = lt(__, 20)
 *      let f = both(gt10, lt20);
 *      f(15); //=> true
 *      f(30); //=> false
 */
const both = curry(_both);

export default both;
