import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  ACCESSIBILITY_DISABLED,
  ACCESSIBILITY_HIDDEN,
  SERVICE_NAME_AIRPORT_TRANSFERS,
  ANCILLARY_PRIORITY_BOARDING,
  ACCESSIBILITY_NOT_INITIALIZED,
} from '~/constants';
import pathOr from '../fp/path-or';
import has from '../fp/has';

export const isNotEnabledAccessibility = (prop) =>
  [ACCESSIBILITY_DISABLED, ACCESSIBILITY_HIDDEN, ACCESSIBILITY_NOT_INITIALIZED].includes(
    prop
  );

/**
 * combine services into a clean iterable for vm
 * (moved here from vm)
 *
 * @param {object} services
 * @param {Boolean} hasOutboundLeg
 * @returns {object} combinedServices
 */
export function combineServices(params) {
  const { services, hasOutboundLeg, wizzSafeBanners = [] } = params;

  const pullUpServices = (direction = OUTBOUND_FLIGHT_NAME, srcServices) => {
    Object.entries(srcServices || {}).forEach(([serviceName, service]) => {
      if (!service.isLost && isNotEnabledAccessibility(service.accessibility)) return;

      // todo: here we shouldn't mutate combined services, should be an input, and
      //  we should return a new object which can be merged into `combinedServices`
      if (!has(serviceName, combinedServices)) {
        combinedServices[serviceName] = {
          outbound: false,
          return: false,
        };
      }

      combinedServices[serviceName][direction] = service;
      combinedServices[serviceName].order = service.order;
      if (serviceName !== ANCILLARY_PRIORITY_BOARDING)
        combinedServices[serviceName].isOffered = service.isOffered;
    });
  };

  const combinedServices = {};
  const bookingLevelServices = services.bookingLevelServices || {};
  const outboundFlightServices = hasOutboundLeg
    ? services.outboundFlightServices || {}
    : {};
  const returnFlightServices = services.returnFlightServices || {};

  // pull up objects from outbound
  if (hasOutboundLeg) {
    pullUpServices(OUTBOUND_FLIGHT_NAME, outboundFlightServices);
  }

  pullUpServices(RETURN_FLIGHT_NAME, returnFlightServices);

  // process the rest
  Object.entries(bookingLevelServices).forEach(([serviceName, service]) => {
    const isAirportTransferHiddenOrDisabled =
      serviceName === SERVICE_NAME_AIRPORT_TRANSFERS &&
      isNotEnabledAccessibility(
        pathOr('', 'departureStationAirportTransfers.accessibility', service)
      ) &&
      isNotEnabledAccessibility(
        pathOr('', 'arrivalStationAirportTransfers.accessibility', service)
      );

    if (
      !service.isLost &&
      (isNotEnabledAccessibility(service.accessibility) ||
        isAirportTransferHiddenOrDisabled)
    ) {
      return;
    }

    // todo: consider extracting a pure version of this func
    combinedServices[serviceName] = service;

    if (
      serviceName === SERVICE_NAME_AIRPORT_TRANSFERS &&
      service.departureStationAirportTransfers
    ) {
      combinedServices[serviceName].order =
        service.departureStationAirportTransfers.order;
    } else {
      combinedServices[serviceName].order = service.order;
    }
  });

  // airport transfers should've been dealt with like outbound flight and return flight
  // maybe in the future we can refactor it to be like those (now its depth remains intact)
  disableAirportTransfers(combinedServices);

  // todo: should be done at store level, cause used elsewhere too
  // add names
  Object.entries(combinedServices).forEach(([serviceName, service]) => {
    service.isWizzSafeBannerVisible = wizzSafeBanners.includes(serviceName);
    service.name = serviceName;
  });

  return combinedServices;
}

/**
 * @param {object} services     will be mutated
 * @private
 */
function disableAirportTransfers(services) {
  let depRemoved = false;
  let arrRemoved = false;

  if (
    services?.airportTransfers?.departureStationAirportTransfers?.accessibility ===
    'disabled'
  ) {
    depRemoved = true;
    delete services.airportTransfers.departureStationAirportTransfers;
  }

  if (
    services?.airportTransfers?.arrivalStationAirportTransfers?.accessibility ===
    'disabled'
  ) {
    arrRemoved = true;
    delete services.airportTransfers.arrivalStationAirportTransfers;
  }

  if (depRemoved && arrRemoved) {
    delete services.airportTransfers;
  }
}
