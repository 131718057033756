import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => boolean} LteFn
 */

/**
 * @type LteFn
 */
export const _lte = (a, b) => a <= b;

/**
 * Returns `true` if the first argument is less than or equal to the second;
 * `false` otherwise.
 *
 * @type LteFn
 * @example
 *
 *      lte(2, 1); //=> false
 *      lte(2, 2); //=> true
 *      lte(2, 3); //=> true
 */
const lte = curry(_lte);

export default lte;
