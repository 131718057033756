import { _flat } from '../flat';

/**
 * Returns a new array by pulling every item out of it (and all its sub-arrays)
 * and putting them in a new array, depth-first.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/flatten.js
 *
 * @type {<T>(arr: T[]) => T[]}
 * @example
 *
 *      flatten([1, 2, [3, 4], 5, [6, [7, 8, [9, [10, 11], 12]]]]);
 *      //=> [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
 */
const flatten = (arr) => _flat(Number.POSITIVE_INFINITY, arr);

export default flatten;
