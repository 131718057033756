import { createDeferred } from '~/utils/promise';

/**
 * Async version of David Walsh's debounce function.
 *
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * Calling the returned debounced function will return a promise which will be
 * resolved when the passed in `fn` function is called and the returned promise
 * gets resolved (which means that `fn` should always return a promise).
 *
 * Note that the same promise will be returned until the passed in `fn` is called,
 * after that the next call of the debounced function will create a new promise
 * and will return that until `fn` gets called again. This allows us to distinguish
 * individual calls to `fn` and lets us to react to them separately.
 *
 * If you want to avoid calling stuff - after the awaited debounced function call -
 * multiple times when the debounced function called many times you can check if
 * the returned promise is the same as before if they are then you can skip
 * executing stuff after it.

 * (This solution is much more sophisticated than using promise cancellation
 * where every client would have to handle the cancellation error plus their call
 * of the debounced function which would have to be made unique so only their
 * promise would get cancelled on cancellation)
 *
 * @type {(fn: Function, wait: number, immediate: boolean) => Function}
 */
const asyncDebounce = (fn, wait, immediate = false) => {
  let timeout = null;
  let deferred = null;

  return (...args) => {
    const onTrailing = () => {
      timeout = null;
      // we can't call on trailing if leading set to true
      if (immediate) return;
      const { resolve, reject } = deferred;
      fn(...args).then(resolve, reject);
      deferred = null;
    };

    const canCallOnLeading = immediate && !timeout;
    clearTimeout(timeout);
    if (!deferred) deferred = createDeferred();
    timeout = setTimeout(onTrailing, wait);

    if (canCallOnLeading) {
      const { resolve, reject } = deferred;
      fn(...args).then(resolve, reject);
      deferred = null;
    }

    return deferred.promise;
  };
};

export default asyncDebounce;
