import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import * as gat from '~/store/action-types';
import { getDefaultState } from './internal';
import mutations from './mutations';
import * as actions from './actions';

const moduleName = 'search';
const state = () => alter(getSessionItemOr({}, moduleName), getDefaultState());

export default {
  state,
  mutations,
  actions: {
    [gat.RESET]: actions.reset,
    [gat.RESTART_BOOKING_RESET]: actions.reset,
    [gat.AFTER_LOGOUT]: actions.resetSearch,
  },
};
