import load from '@prepair/basic-loader';
import complement from '~/utils/fp/complement';
import isNotNil from '~/utils/object/is-not-nil';
import isNotEmpty from '~/utils/object/is-not-empty';

const TIK_TOK_PIXEL_ANALYTICS_URL = 'https://analytics.tiktok.com/i18n/pixel/events.js';

let isLoaded = false;

export const init = () => {
  window.TiktokAnalyticsObject = 'ttq';

  const ttq = (window.ttq = window.ttq || []);

  ttq.methods = [
    'page',
    'track',
    'identify',
    'instances',
    'debug',
    'on',
    'off',
    'once',
    'ready',
    'alias',
    'group',
    'enableCookie',
    'disableCookie',
  ];

  ttq.setAndDefer = function (ttq, method) {
    ttq[method] = function () {
      ttq.push([method].concat(Array.prototype.slice.call(arguments, 0)));
    };
  };

  ttq.methods.forEach((method) => ttq.setAndDefer(ttq, method));

  ttq.instance = (_ttq) => {
    const ttqInstance = ttq._i[_ttq] || [];

    ttq.methods.forEach((method) => ttq.setAndDefer(ttqInstance, method));
    return ttqInstance;
  };
};

const _load = () => {
  const url = TIK_TOK_PIXEL_ANALYTICS_URL;
  window.ttq._i = window.ttq._i || {};
  window.ttq._t = window.ttq._t || {};
  window.ttq._o = window.ttq._o || {};
  window.ttq._i[window.wizz.tikTokPixelId] = [];
  window.ttq._t[window.wizz.tikTokPixelId] = Number(Date.now());
  window.ttq._o[window.wizz.tikTokPixelId] = {};
  window.ttq._i[window.wizz.tikTokPixelId]._u = url;
  window.ttq.page();

  return load.js(`${url}?sdkid=${window.wizz.tikTokPixelId}&lib=ttq`);
};

export const ttqTrack = (...args) => {
  if (isTtqNotAvailable()) return;
  if (!isLoaded) {
    _load();
    isLoaded = true;
  }

  window.ttq.track(...args);
};

/**
 * @type {() => boolean}
 */
export const isTtqAvailable = () => {
  return isNotNil(window.ttq) && isNotEmpty(window.wizz.tikTokPixelId);
};

/**
 * @type {() => boolean}
 */
export const isTtqNotAvailable = complement(isTtqAvailable);
