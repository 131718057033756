import isNumber from '~/utils/object/is-number';

/**
 * Returns the number of elements in the array/string by returning `thing.length`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/length.js
 *
 * @type {<T>(arr: T[]|string) => number}
 * @example
 *
 *      length([]); //=> 0
 *      length([1, 2, 3]); //=> 3
 */
export default (thing) =>
  thing != null && isNumber(thing.length) ? thing.length : Number.NaN;
