import {
  ACCESSIBILITY_HIDDEN,
  ITINERARY_SECTION_BOOKING_DETAILS,
  RETURN_FLIGHT_NAME,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
  OUTBOUND_FLIGHT_NAME,
} from '~/constants';
import complement from '~/utils/fp/complement';
import either from '~/utils/fp/either';
import propEq from '~/utils/fp/prop-eq';
import hassocPath from '~/utils/object/hassoc-path';
import hevolve from '~/utils/object/hevolve';
import inc from '~/utils/fp/inc';
import dec from '~/utils/fp/dec';
import compose from '~/utils/fp/compose';
import max from '~/utils/fp/max';
import concat from '~/utils/fp/concat';
import uniq from '~/utils/fp/uniq';
import append from '~/utils/fp/append';
import deepClone from '~/utils/object/deep-clone';
import isNotEmptyArray from '~/utils/object/is-not-empty-array';
import { getDefaultPrice } from '~/utils/price';
import { getCheckInPassengerDetails, resetState } from './internal';
import * as m from './mutation-types';

const resetUpsoldBookings = (state) => {
  state.upsoldBookings = [];
};

export default {
  [m.RESET]: resetState,

  [m.PARTIALLY_RESET_ITINERARY](state) {
    state.isAddServiceVisitedOnce = false;
    state.booking = null;
    state.currentType = null;
    state.currentFlight = null;
    state.selectedPassenger = null;
    state.selectedCheckInPassenger = null;
    state.checkInPassengers.outbound = [];
    state.checkInPassengers.return = [];
    // state.firstNamesAndGenders = {};  do not reset this please
    state.invoices = [];
    state.loadingCounter = 0;
    state.errors = [];
    state.activeModal = null;
    state.activeSection = ITINERARY_SECTION_BOOKING_DETAILS;
    state.isSeatSelectionModalVisible = false;
    state.priceDetails = {
      [OUTBOUND_FLIGHT_NAME]: {
        fromIata: '',
        toIata: '',
        fees: [],
        total: getDefaultPrice(),
      },
      [RETURN_FLIGHT_NAME]: {
        fromIata: '',
        toIata: '',
        fees: [],
        total: getDefaultPrice(),
      },
      globals: {
        fees: [],
        total: getDefaultPrice(),
      },
    };
    state.priceDetailsErrors = [];

    state.flightStatusChangedState = null;
    state.flightStatusChangedErrors = [];
    state.refundGetErrors = [];
    state.seatRefundErrors = [];
    state.isWukGccAcceptedOnFlightStatusChange = false;
    state.isBookingDotComModalVisible = false;
    state.isFlightChangeWarningModalShown = false;
    state.divideKind = '';
  },

  [m.ADD_PNR_TO_UPSOLD_BOOKINGS](state, pnr) {
    state.upsoldBookings = append(pnr, state.upsoldBookings);
  },

  [m.RESET_UPSOLD_BOOKINGS]: resetUpsoldBookings,

  [m.SET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT]: hassocPath(
    'isLoginRequiredToContinueToPayment',
    true
  ),

  [m.UNSET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT]: hassocPath(
    'isLoginRequiredToContinueToPayment',
    false
  ),

  [m.SET_IS_ADD_SERVICE_VISITED_ONCE]: hassocPath('isAddServiceVisitedOnce', true),

  [m.UNSET_IS_ADD_SERVICE_VISITED_ONCE]: hassocPath('isAddServiceVisitedOnce', false),

  [m.UPDATE_ITINERARY_BOOKING](state, booking) {
    state.booking = booking;
  },

  [m.INCREMENT_ITINERARY_LOADING_COUNTER]: hevolve({
    loadingCounter: inc,
  }),

  [m.DECREMENT_ITINERARY_LOADING_COUNTER]: hevolve({
    loadingCounter: compose(max(0), dec),
  }),

  [m.ADD_ITINERARY_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    state.errors = uniq([...state.errors, ...errors]);
  },

  [m.ADD_SERVER_ERROR](state, error) {
    state.hasServerError = error;
  },

  [m.REMOVE_SERVER_ERRORS](state, error) {
    state.hasServerError = null;
  },

  [m.REMOVE_ITINERARY_ERRORS](state) {
    state.errors = [];
  },

  [m.UPDATE_ITINERARY_CHECK_IN_PASSENGERS](state, { direction, checkInPassengers } = {}) {
    state.checkInPassengers[direction] = checkInPassengers.map((checkInPassenger) => ({
      ...checkInPassenger,
      saveMobilePhoneToProfile: state.booking?.isMobilePhoneSaveToProfileChecked,
    }));
  },

  [m.UPDATE_ITINERARY_SERVICES](state, services) {
    state.booking.services = services;
  },

  [m.SET_PREFERRED_INVOICE_TAB](state, value) {
    state.preferredInvoiceTab = value;
  },

  [m.SET_ITINERARY_ACTIVE_MODAL](state, value) {
    state.activeModal = value;
  },

  [m.SET_ITINERARY_ACTIVE_SECTION](state, value) {
    state.activeSection = value;
  },

  [m.SET_CURRENT_CURRENCY](state, value) {
    state.currentCurrency = value;
  },

  [m.SET_IS_SEAT_SELECTION_NEEDED](state, value) {
    state.isSeatSelectionNeeded = value;
  },

  [m.UPDATE_SEAT_SELECTION_MODAL_VISIBILITY](state, value) {
    state.isSeatSelectionModalVisible = value;
  },

  [m.UPDATE_ITINERARY_SELECTED_PASSENGER](state, passenger) {
    state.selectedPassenger = passenger;
  },

  [m.ADD_CHECK_IN_PASSENGER](state, payload) {
    const { passengerNumber, direction } = payload;
    const isAlreadyAdded = state.checkInData.passengers.some(
      propEq('passengerNumber', passengerNumber)
    );
    if (isAlreadyAdded) return;

    const checkInPassenger = state.checkInPassengers[direction].find(
      propEq('passengerNumber', passengerNumber)
    );
    const { isPassportBiometric } = checkInPassenger;
    const isReturnFlight = direction === RETURN_FLIGHT_NAME;

    state.checkInData.passengers.push(
      Object.assign(
        {
          isReturnFlight,
          passengerNumber,
          isPassportBiometric,
          adultPassengerNumber: null,
        },
        getCheckInPassengerDetails(checkInPassenger)
      )
    );

    const hasInfant =
      checkInPassenger.infant &&
      checkInPassenger.infant.accessibility !== ACCESSIBILITY_HIDDEN;

    if (hasInfant) {
      state.checkInData.passengers.push(
        Object.assign(
          {
            isReturnFlight,
            passengerNumber: null,
            adultPassengerNumber: checkInPassenger.infant.value.passengerNumber,
            isPassportBiometric: checkInPassenger.infant.value.isPassportBiometric,
          },
          getCheckInPassengerDetails(checkInPassenger.infant.value)
        )
      );
    }
  },

  [m.REMOVE_CHECK_IN_PASSENGER](state, passengerNumber) {
    state.checkInData.passengers = state.checkInData.passengers.filter(
      complement(
        either(
          propEq('passengerNumber', passengerNumber),
          propEq('adultPassengerNumber', passengerNumber)
        )
      )
    );
  },

  [m.UPDATE_CHECK_IN_PASSENGER_PROP](state, data) {
    const { prop, value, passengerNumber, adultPassengerNumber } = data;
    let currentPassenger;
    if (adultPassengerNumber) {
      // infants only
      currentPassenger = state.checkInData.passengers.find(
        propEq('adultPassengerNumber', adultPassengerNumber)
      );
    } else {
      currentPassenger = state.checkInData.passengers.find(
        propEq('passengerNumber', passengerNumber)
      );
    }
    currentPassenger[prop] = value;
  },

  [m.HANDLE_CHECKED_IN_PASSENGERS](state, data) {
    data.passengers.forEach((passenger) => {
      const storedPassenger = state.checkInData.passengers.find(
        propEq('passengerNumber', passenger.passengerNumber)
      );
      storedPassenger.isReturnFlight = data.isReturnFlight;
      storedPassenger.seatUnitDesignator = passenger.seatUnitDesignator;
    });
  },

  [m.RESET_SELECTED_CHECK_IN_PASSENGERS](state) {
    state.checkInData.passengers = [];
  },

  [m.SET_RECENTLY_PURCHASED_ITEMS](state, cartItems) {
    state.checkInData.recentlyPurchasedItems.ancillaries = deepClone(
      cartItems.ancillaries
    );
    state.checkInData.recentlyPurchasedItems.services = deepClone(cartItems.services);
    state.checkInData.recentlyPurchasedItems.seats = deepClone(cartItems.seats);
  },

  [m.SET_EMPTY_PAYMENT_OCCURRED_STATUS](state, value) {
    state.checkInData.isEmptyPaymentOccurred = value;
  },

  [m.SET_RECENTLY_PAID_AMOUNT](state, value) {
    state.recentlyPaidAmount = value;
  },

  [m.SET_FLIGHTS_ON_ITINERARY](state, data = {}) {
    const { returnFlight } = data;

    [OUTBOUND_FLIGHT, ...(returnFlight ? [RETURN_FLIGHT] : [])]
      .map((flightProp) => {
        state.booking[flightProp] = state.booking[flightProp] || {};
        return {
          dataFlight: data[flightProp],
          flight: state.booking[flightProp],
        };
      })
      .forEach(({ dataFlight, flight }) => {
        flight.carrierCode = dataFlight.carrier;
        flight.flightNumber = dataFlight.flightNumber;
        flight.departureDateTime = dataFlight.departureDate;
        flight.arrivalDateTime = dataFlight.arrivalDate;
        flight.planeType = dataFlight.aircraftType;
        flight.departureStation = dataFlight.departureStation;
        flight.arrivalStation = dataFlight.arrivalStation;
      });
  },

  [m.UPDATE_PRICE_DETAILS](state, priceDetails) {
    state.priceDetails = priceDetails;
  },

  [m.ADD_PRICE_DETAILS_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    state.priceDetailsErrors = uniq([...errors, ...state.priceDetailsErrors]);
  },

  [m.RESET_PRICE_DETAILS_ERRORS](state) {
    state.priceDetailsErrors = [];
  },

  [m.SET_IS_BOOKING_DOT_COM_MODAL_VISIBLE](state) {
    state.isBookingDotComModalVisible = true;
  },

  [m.UNSET_IS_BOOKING_DOT_COM_MODAL_VISIBLE](state) {
    state.isBookingDotComModalVisible = false;
  },

  [m.UPDATE_CONTACT_PHONE_NUMBER](state, phoneNumber) {
    state.booking.contact.mobilePhone = phoneNumber;
    state.contact.isUpdated = true;
  },

  [m.SET_CHECK_IN_DOCUMENT_RULES_BY_COUNTRIES](state, rules) {
    state.checkInData.documentRulesByCountries = rules;
  },

  [m.INCREMENT_CONTACT_LOADING_COUNTER]: hevolve({
    contact: {
      loadingCounter: inc,
    },
  }),

  [m.DECREMENT_CONTACT_LOADING_COUNTER]: hevolve({
    contact: {
      loadingCounter: compose(max(0), dec),
    },
  }),

  [m.ADD_CONTACT_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    state.contact.errors = concat(errors, state.contact.errors);
  },

  [m.REMOVE_CONTACT_ERRORS](state) {
    state.contact.errors = [];
  },

  [m.RESET_CONTACT_UPDATE_STATUS](state) {
    state.contact.isUpdated = false;
  },

  [m.UPDATE_BANNED_PASSENGERS](state, passengers) {
    const { bannedPassengers } = state.checkInData;
    passengers.forEach((passenger) => {
      if (!bannedPassengers.includes(passenger)) {
        bannedPassengers.push(passenger);
      }
    });
  },

  [m.SET_BANNED_PASSENGER_SEEN](state, value) {
    hassocPath('checkInData.bannedPassengerWarningSeenBy', value, state);
  },

  [m.RESET_AVAILABLE_FEEDBACK_STAGES](state) {
    state.booking.availableFeedbackStages = [];
  },

  [m.SET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE](state) {
    state.isUpgradeBundleModalVisible = true;
  },

  [m.UNSET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE](state) {
    state.isUpgradeBundleModalVisible = false;
  },

  [m.SET_IS_FLIGHT_CHANGE_WARNING_MODAL_SHOWN](state, value) {
    state.isFlightChangeWarningModalShown = value;
  },

  [m.RESET_BANNED_PASSENGERS](state) {
    hassocPath('checkInData.bannedPassengerWarningSeenBy', 0, state);
    hassocPath('checkInData.bannedPassengers', [], state);
  },

  [m.SET_DIVIDE_KIND](state, value) {
    hassocPath('divideKind', value, state);
  },

  [m.SET_IS_SUCCESSFUL_BOOKING_LOADED](state) {
    state.isSuccessfulBookingLoaded = true;
  },

  [m.UNSET_IS_SUCCESSFUL_BOOKING_LOADED](state) {
    state.isSuccessfulBookingLoaded = false;
  },

  [m.RESET_CURRENT_TYPE_AND_SELECTED_PASSENGER](state) {
    state.currentType = null;
    state.selectedPassenger = null;
  },

  [m.SET_CURRENT_TYPE](state, value) {
    state.currentType = value;
  },

  [m.SET_SELECTED_CHECK_IN_PASSENGER](state, value) {
    state.selectedCheckInPassenger = value;
  },

  [m.RESET_SELECTED_CHECK_IN_PASSENGER](state, value) {
    state.selectedCheckInPassenger = null;
  },

  [m.SET_CURRENT_FLIGHT](state, value) {
    state.currentFlight = value;
  },

  [m.SET_IS_ADD_BAGS_FLOW](state, value) {
    state.isAddBagsFlow = value;
  },

  [m.SELECT_AUTO_CHECK_IN](state) {
    state.isAutoCheckInSelected = true;
  },

  [m.UNSELECT_AUTO_CHECK_IN](state) {
    state.isAutoCheckInSelected = false;
  },

  [m.SET_ADVERTISEMENT_BANNER](state, value) {
    const { alt: label, href: url, src: image } = value.linkInfo;
    state.advertisementBanner = { label, url, image };
  },

  [m.SET_AUTO_CALL_METHOD_NAME](state, value) {
    state.autoCallMethodName = value;
  },

  [m.SET_SELECTED_TAB_DIRECTION](state, value) {
    state.selectedTabDirection = value;
  },

  [m.SET_PASSENGER_SEAT_UNIT_DESIGNATOR](state, payload) {
    const { passengerNumber, direction, seat } = payload;
    const passenger = state.booking.passengers.find(
      propEq('passengerNumber', passengerNumber)
    );
    passenger[direction].seatUnitDesignator = seat;
  },

  [m.SET_IS_ADD_FLIGHT_EXIT_INTENT_MODAL_SEEN]: hassocPath(
    'isAddFlightExitIntentModalSeen',
    true
  ),

  [m.SET_OUTBOUND_FREE_CHECKIN_START_DATE](state, value) {
    state.outboundFreeCheckinStartDate = value;
  },

  [m.SET_RETURN_FREE_CHECKIN_START_DATE](state, value) {
    state.returnFreeCheckinStartDate = value;
  },

  [m.SET_IS_COMING_FROM_SEAT_MODIFICATION_CI](state, value) {
    state.isComingFromSeatModificationCI = value;
  },
};
