import path from '~/utils/fp/path';
import curry from '~/utils/fp/curry';
import ifElse from '~/utils/fp/if-else';

const upsellProp = curry((prop, state) => path(prop, state.upsell));

export const upsellInfo = upsellProp('upsellInfo');

export const upsellAncillaries = upsellProp('upsellInfo.ancillaries');

export const isUpsellOverPriceMinimum = upsellProp('upsellInfo.isOverPriceMinimum');

export const isPostBookingUpgradeStarted = upsellProp('isPostBookingUpgradeStarted');

export const shouldFetchPostBookingBundleUpsell = upsellProp(
  'postBookingUpsellInfo.shouldFetchUpsell'
);

export const isPostBookingBundleUpsellAvailable = upsellProp(
  'postBookingUpsellInfo.isUpsellAvailable'
);

export const postBookingBundleUpsellPrice = upsellProp('postBookingUpsellInfo.price');

const isPostBookingUpsellPriceAndOriginalPriceIdentical = (state) =>
  postBookingUpsellInfo(state).price?.amount ===
  postBookingUpsellInfo(state).originalPrice?.amount;

const postBookingUpsellInfo = upsellProp('postBookingUpsellInfo');

export const postBookingBundleUpsellOriginalPrice = ifElse(
  isPostBookingUpsellPriceAndOriginalPriceIdentical,
  () => null,
  upsellProp('postBookingUpsellInfo.originalPrice')
);

export const upsellPrice = upsellProp('upsellInfo.price');

export const upgradeLocation = upsellProp('upgradeLocation');

export const upgradePrice = upsellProp('upgradePrice');

export const upgradeBundle = upsellProp('upgradeBundle');
