/**
 * Returns a function that always returns the given value. Note that for
 * non-primitives the value returned is a reference to the original value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/always.js
 *
 * @type {<T>(value: T) => () => T}
 * @example
 *
 *      let t = always('Tee');
 *      t(); //=> 'Tee'
 */
const always = (value) => () => value;

export default always;
