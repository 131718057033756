import { mutationAssocPath } from '~/utils/store';
import * as m from './mutation-types';

export default {
  [m.UPDATE_ANALYTICS_EXCHANGE_RATE]: mutationAssocPath('exchangeRate'),
  [m.UPDATE_ANALYTICS_PRODUCT_MAP]: mutationAssocPath('map'),
  [m.SET_WIZZ_FLEX_PURCHASE_LOCATION]: mutationAssocPath('wizzFlexPurchaseLocation'),
  [m.SET_PRIORITY_BOARDING_PURCHASE_LOCATION]: mutationAssocPath(
    'priorityBoardingPurchaseLocation'
  ),
  [m.SET_SMS_PURCHASE_LOCATION]: mutationAssocPath('smsPurchaseLocation'),
  [m.SET_AIRPORT_CHECK_IN_PURCHASE_LOCATION]: mutationAssocPath(
    'airportCheckInPurchaseLocation'
  ),
  [m.SET_WDC_MEMBERSHIP_PURCHASE_LOCATION]: mutationAssocPath(
    'wdcMembershipPurchaseLocation'
  ),
  [m.SET_INSURANCE_PURCHASE_LOCATION]: mutationAssocPath('insurancePurchaseLocation'),
  [m.SET_WDC_DISCOUNT_SAVING]: mutationAssocPath('hasSavingOnWdc'),
  [m.SET_AUTO_CHECK_IN_PURCHASE_LOCATION]: mutationAssocPath(
    'autoCheckInPurchaseLocation'
  ),
  [m.SET_OUTBOUND_FARE_PURCHASE_LOCATION]: mutationAssocPath(
    'outboundFarePurchaseLocation'
  ),
  [m.SET_RETURN_FARE_PURCHASE_LOCATION]: mutationAssocPath('returnFarePurchaseLocation'),
  [m.SET_SEAT_PURCHASE_LOCATION]: mutationAssocPath('seatPurchaseLocation'),
  [m.SET_SITTING_TOGETHER_PURCHASE_LOCATION]: mutationAssocPath(
    'sittingTogetherPurchaseLocation'
  ),
  [m.SET_SELECTED_PAYMENT_OPTION]: mutationAssocPath('selectedPaymentOption'),
};
