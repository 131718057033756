import deepFreeze from '~/utils/object/deep-freeze';
import * as m from './mutation-types';

/**
 * @type {(state: State, value: Object<string, any>[]) => void}
 */
const setCountries = (state, value) => {
  state.countries = deepFreeze(value);
};

/**
 * @type {(state: State, value: Object<string, any>[]) => void}
 */
const setStations = (state, value) => {
  state.stations = deepFreeze(value);
};

/**
 * @type {(state: State, value: Object<string, any>[]) => void}
 */
const setServiceFees = (state, value) => {
  state.serviceFees = deepFreeze(value);
};

/**
 * @type {(state: State) => void}
 */
const resetServiceFees = (state) => {
  state.serviceFees = {};
};

/**
 * @type {(state: State, payload: Object<string, any>[]) => void}
 */
const setDisruptionCreditInformation = (state, value) => {
  state.disruptionCreditInformation = deepFreeze(value);
};

const setDisruptionCreditInformationErrors = (state, errors) => {
  state.disruptionCreditInformation.errors = errors;
};

export default {
  [m.SET_COUNTRIES]: setCountries,
  [m.SET_STATIONS]: setStations,
  [m.SET_SERVICE_FEES]: setServiceFees,
  [m.RESET_SERVICE_FEES]: resetServiceFees,
  [m.SET_DISRUPTION_CREDIT_INFORMATION]: setDisruptionCreditInformation,
  [m.SET_DISRUPTION_CREDIT_INFORMATION_ERRORS]: setDisruptionCreditInformationErrors,
};
