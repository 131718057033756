export const cultures = {
  'en-gb': 'English',
  'hu-hu': 'Magyar',
  'bg-bg': 'Български',
  'bs-latn-ba': 'Bosanski',
  'cs-cz': 'Česky',
  'de-de': 'Deutsch',
  'es-es': 'Español',
  'ca-es': 'Català',
  'el-gr': 'Ελληνικά',
  'fr-fr': 'Français',
  'ka-ge': 'ქართული',
  'he-il': 'עברית',
  'it-it': 'Italiano',
  'lv-lv': 'Latviešu',
  'lt-lt': 'Lietuvių',
  'mk-mk': 'Македонски',
  'nl-nl': 'Nederlands',
  'nb-no': 'Norsk',
  'pl-pl': 'Polski',
  'ro-ro': 'Română',
  'pt-pt': 'Português',
  'ru-ru': 'Русский',
  'sk-sk': 'Slovenský',
  'sq-al': 'Shqip',
  'sv-se': 'Svenska',
  'sr-cyrl-cs': 'Српски',
  'uk-ua': 'Українська',
  'ar-ae': 'العربية',
};
