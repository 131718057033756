import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => number} AddFn
 */

/**
 * @type AddFn
 */
export const _add = (a, b) => a + b;

/**
 * Adds two values.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/add.js
 *
 * @type AddFn
 * @example
 *
 *      add(2, 3);       //=>  5
 *      add(7)(10);      //=> 17
 */
const add = curry(_add);

export default add;
