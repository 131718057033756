import {
  BUNDLE_BASIC,
  BUNDLE_MIDDLE,
  BUNDLE_PLUS,
  MIN_GROUP_PASSENGERS_COUNT,
  VALIDATION_CODE_DEFINE_FIRST_NAME,
  CARRIER_WIZZ_HUN,
  CARRIER_WIZZ_UK,
  CARRIER_WIZZ_AD,
  OUTBOUND_FLIGHT_NAME,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  MINIMUM_PASSENGER_COUNT_FOR_PER_PERSON_VISIBILITY,
  CARRIER_WIZZ_MT,
  BUNDLE_MIDDLE_TWO,
  BUNDLE_SMART,
} from '~/constants';
import { CONNECTED_BOOKING_URL_HASH_SEARCH } from '~/router/booking/constants';
import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import toLowerCase from '~/utils/fp/to-lower-case';
import isNotEmptyString from '~/utils/object/is-not-empty-string';
import complement from '~/utils/fp/complement';
import gte from '~/utils/fp/gte';
import __ from '~/utils/fp/__';
import pathOr from '~/utils/fp/path-or';
import { getLocale } from '~/utils/localization';
// eslint-disable-next-line import/no-cycle
export { default as isFlownFlight } from './is-flown-flight';

export const getGccFile = (carrierCode) => {
  if (isOperatedByWizzUk(carrierCode)) {
    return pathOr('', 'generalConditionsOfCarriageWUK', window.files);
  }
  if (isOperatedByWizzAbuDhabi(carrierCode)) {
    return pathOr('', 'generalConditionsOfCarriageWAD', window.files);
  }
  if (isOperatedByWizzMalta(carrierCode)) {
    return pathOr('', 'generalConditionsOfCarriageWMT', window.files);
  }
  return pathOr('', 'generalConditionsOfCarriage', window.files);
};

/**
 * @type {(numberOfIndependentPassengers: number) => boolean}
 */
export const isPricePerPersonVisible = gte(
  __,
  MINIMUM_PASSENGER_COUNT_FOR_PER_PERSON_VISIBILITY
);

/**
 * @type {(value: string) => boolean}
 */
export const isOutboundDirection = compose(equals(DIRECTION_OUTBOUND), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isReturnDirection = compose(equals(DIRECTION_RETURN), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isBasicBundle = compose(equals(BUNDLE_BASIC), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isNotBasicBundle = complement(isBasicBundle);

/**
 * @type {(value: string) => boolean}
 */
export const isSmartBundle = compose(equals(BUNDLE_SMART), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isNotSmartBundle = complement(isSmartBundle);

/**
 * @type {(value: string) => boolean}
 */
export const isMiddleTwoBundle = compose(equals(BUNDLE_MIDDLE_TWO), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isNotMiddleTwoBundle = complement(isMiddleTwoBundle);

/**
 * @type {(value: string) => boolean}
 */
export const isMiddleBundle = compose(equals(BUNDLE_MIDDLE), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isNotMiddleBundle = complement(isMiddleBundle);

/**
 * @type {(value: string) => boolean}
 */
export const isPlusBundle = compose(equals(BUNDLE_PLUS), toLowerCase);

/**
 * @type {(value: string) => boolean}
 */
export const isNotPlusBundle = complement(isPlusBundle);

/**
 * @param {String} a - bundle name
 * @param {String} b - bundle name
 * @returns {boolean}
 */
export const isBundleHigher = (a, b) =>
  BUNDLE_ORDER[a.toLowerCase()] > BUNDLE_ORDER[b.toLowerCase()];

// isOperatedByWizzHungary :: String -> Bool
export const isOperatedByWizzHungary = compose(equals(CARRIER_WIZZ_HUN), toLowerCase);

// isOperatedByWizzUk :: String -> Bool
export const isOperatedByWizzUk = compose(equals(CARRIER_WIZZ_UK), toLowerCase);

// isOperatedByWizzAbuDhabi :: String -> Bool
export const isOperatedByWizzAbuDhabi = compose(equals(CARRIER_WIZZ_AD), toLowerCase);

// isOperatedByWizzMalta :: String -> Bool
export const isOperatedByWizzMalta = compose(equals(CARRIER_WIZZ_MT), toLowerCase);

export const isFirstNameRequired = (validationCodes) =>
  validationCodes.includes(VALIDATION_CODE_DEFINE_FIRST_NAME);

export const isGroupBooking = (numberOfIndependentPassengers = 1) =>
  numberOfIndependentPassengers >= MIN_GROUP_PASSENGERS_COUNT;

export const isConnectedFlightsZeroStep = () =>
  window.location.href.includes(CONNECTED_BOOKING_URL_HASH_SEARCH);

// hash checking remains only for local development
export const isBookingFlow = () =>
  window.location.pathname.startsWith(`/${getLocale()}/booking/`) ||
  (isNotEmptyString(window.location.hash) &&
    window.location.hash.slice(0, 10) === '#/booking/');

export const isItinerary = () => window.location.pathname.includes('itinerary');

export const isCheckIn = () => window.location.pathname.includes('check-in');

// use only for hibrid mode, after moving checkin to ssr it can be removed
export const isCheckInFlow = () =>
  isNotEmptyString(window.location.hash) &&
  window.location.hash.slice(0, 10) === '#/check-in';

export const isNewItinerary = () =>
  window.urls?.itineraryNew
    ? window.location.href.startsWith(window.urls.itineraryNew)
    : window.location.pathname.includes('itinerary-new');

export const getDirectionIndex = (direction) =>
  direction === OUTBOUND_FLIGHT_NAME ? 0 : 1;

/**
 * Find the complimenter fare in terms of WDC availability for a fare.
 * Eg. the BASIC WDC fare for a non-WDC BASIC fare, and vice versa.
 *
 * @param  {Object} fare     The fare which you want to find the complementer for.
 * @param  {Array}  allFares All the fares from which you want to find the complementer.
 * @return {Object}          The complementer fare.
 */
export const getComplementerFare = (fare, allFares = []) =>
  allFares.find((theFare) => theFare.wdc === !fare.wdc && theFare.bundle === fare.bundle);

/**
 * Get all fares that are/aren't WDC fares.
 *
 * @param  {Array}   fares   All the fares from which you want to find the matches.
 * @param  {Boolean} withWdc Whether we should check for WDC, or non-WDC fares.
 * @return {Array}
 */
export const getFaresWithWdc = (fares = [], withWdc = true) =>
  fares.filter((fare) => fare.wdc === withWdc);

const BUNDLE_ORDER = Object.freeze({
  [BUNDLE_PLUS]: 2,
  [BUNDLE_MIDDLE]: 1,
  [BUNDLE_BASIC]: 0,
});
