import to from 'await-to-js';
import {
  SEAT_SELECTION_NEEDED,
  FLIGHT_STATUS_STATE_ACCEPTED,
  FLIGHT_STATUS_STATE_REFUNDED,
} from '~/constants/flight-disruption';
import { createAction } from '~/utils/store';
import { sendGaEvent } from '~/utils/analytics';
import uniq from '~/utils/fp/uniq';
import * as BookingService from '~/services/booking';
import * as analyticsGetters from '~/store/modules/analytics/getters';
import { round } from '~/utils/number';
import { apiErrorsOr } from '~/utils/services';
import isNotEmpty from '~/utils/object/is-not-empty';
import { captureException } from '~/utils/logging';
import { rebookToSeatFlight } from './internal';

import * as m from './mutation-types';
import * as getters from './getters';

export const initialize = createAction(m.INITIALIZE);

export const addErrors = createAction(m.SET_ERRORS);

export const resetFlightDisruption = createAction(m.RESET_FLIGHT_DISRUPTION);

export const acceptChangedFlight = async (store) => {
  const { commit } = store;

  commit(m.INCREMENT_LOADING_COUNTER);
  commit(m.RESET_ERRORS);
  const [rebookError, rebookResponse] = await to(BookingService.rebook());
  const [acceptError, acceptResponse] = await to(BookingService.acceptChangedFlights());
  commit(m.DECREMENT_LOADING_COUNTER);

  if (rebookError) captureException(rebookError);
  if (acceptError) captureException(acceptError);

  const rebookData = rebookResponse?.data || null;
  const acceptData = acceptResponse?.data || null;
  const rebookErrors = rebookError?.data?.validationCodes ?? [];
  const acceptErrors = acceptError?.data?.validationCodes ?? [];
  const shouldReselectSeats = acceptData?.acceptResult === SEAT_SELECTION_NEEDED;

  commit(m.SET_SHOULD_RESELECT_SEATS_STATE, shouldReselectSeats);

  if (isNotEmpty(rebookErrors) || isNotEmpty(acceptError)) {
    const errors = uniq([...rebookErrors, ...acceptErrors]);
    return commit(m.SET_ERRORS, errors);
  }

  const isRescheduled = getters.isFlightRescheduled(store.state);

  sendGaEvent({
    category: isRescheduled ? 'Reschedule' : 'Schedule Change',
    action: 'Click',
    label: 'Accept',
    nonInteractive: true,
  });

  if (!shouldReselectSeats) {
    return commit(m.SET_FLIGHT_STATUS_CHANGED_STATE, FLIGHT_STATUS_STATE_ACCEPTED);
  }

  commit(m.SET_REBOOK_TO_SEAT_FLIGHT, rebookToSeatFlight(rebookData));
  commit(m.SET_SEAT_SELECTION_NEEDED_PASSENGER_LIST, {
    outboundSeatSelectionNeededPassengerList:
      acceptData?.outboundSeatSelectionNeededPassengerList || [],
    returnSeatSelectionNeededPassengerList:
      acceptData?.returnSeatSelectionNeededPassengerList || [],
  });
};

export const refundToWizzAccount = async (store) => {
  const { commit, state } = store;
  const isAircraftChanged = getters.isAircraftChanged(store.state);
  const isRescheduled = getters.isFlightRescheduled(store.state);

  commit(m.INCREMENT_LOADING_COUNTER);
  commit(m.RESET_ERRORS);
  const [error] = await to(
    isAircraftChanged
      ? BookingService.premiumSeatRefund({ premiumSeatRefundType: 'WizzAccount' })
      : BookingService.refund({
          isOutboundRefund: getters.isOutboundRefundOptionSelected(state),
          isReturnRefund: getters.isReturnRefundOptionSelected(state),
        })
  );
  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) return commit(m.SET_ERRORS, apiErrorsOr('InternalError', error));

  const category = isAircraftChanged
    ? 'Aircraft type change'
    : isRescheduled
    ? 'Reschedule'
    : 'Schedule Change';

  sendGaEvent({
    category,
    action: 'Click',
    label: 'Refund',
    value: round(
      (state.refundPrice?.amount ?? 0) * (analyticsGetters.exchangeRate(state) || 1)
    ),
    nonInteractive: true,
  });

  commit(m.SET_FLIGHT_STATUS_CHANGED_STATE, FLIGHT_STATUS_STATE_REFUNDED);
};

export const fetchRefundAmount = async (store) => {
  const { commit } = store;
  commit(m.INCREMENT_LOADING_COUNTER);
  commit(m.RESET_ERRORS);
  const [error, response] = await to(BookingService.getRefundAmount());
  commit(m.DECREMENT_LOADING_COUNTER);
  if (error) return commit(m.SET_ERRORS, apiErrorsOr('InternalError', error));

  commit(m.SET_REFUND_OPTIONS, response.data);
};

export const setSelectedRefundOption = createAction(m.SET_SELECTED_REFUND_OPTION);
