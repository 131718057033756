import curry from '../fp/curry';
import isArray from '../object/is-array';
import isNotEmptyArray from '../object/is-not-empty-array';

/**
 * @type { (fallback: string|string[], error: Object<string, any>) => string[] }
 */
export const apiErrorsOr = curry((fallback, error) => {
  const validationCodes = error.data?.validationCodes;
  if (isNotEmptyArray(validationCodes)) return validationCodes;
  if (isArray(fallback)) return fallback;
  return [fallback];
});

/**
 * @type { (error: Object<string, any>) => string[] }
 */
export const apiErrors = apiErrorsOr([]);

/**
 * @type { (error: Object<string, any>) => string[] }
 */
export const apiErrorsOrDefault = apiErrorsOr('500-page-description');
