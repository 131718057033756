import { isNotInfant } from '~/utils/booking/passenger';
import { INFANT_ASSIGNMENT_ERROR_CODE } from './constants';

export const getDefaultState = () => ({
  anyPassengerCheckedInToAnyFlight: false,
  infantsCanBeMoved: false,
  savedPassengerDetails: [],
  passengers: [],
});

export const createUniqueInfants = (passengers) => {
  let infantIndex = 0;
  return passengers.map((passenger) => {
    if (isNotInfant(passenger)) return passenger;

    return {
      ...passenger,
      infantPassengerNumber: infantIndex++,
    };
  });
};

export const internalInfantAssignmentErrorResponse = () => ({
  data: {
    validationCodes: [INFANT_ASSIGNMENT_ERROR_CODE],
  },
});

export const isWheelchairProp = (prop) => /^wheelchair\w+$/i.test(prop);

export const isWheelchairControlProp = (prop) => /^wheelchair\w+powered$/i.test(prop);
