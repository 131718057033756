import {
  PAYMENT_STATUS_ACCEPTED,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  STATION_ARRIVAL,
  STATION_DEPARTURE,
} from '~/constants';
import {
  SPECIFIC_TYPE_FARE_LOCK,
  SPECIFIC_TYPE_WDC_MEMBERSHIP,
  SPECIFIC_TYPE_WDC_RENEWAL,
  SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY,
} from '~/constants/specific-type';
import { getDefaultPrice } from '~/utils/price';
import isNotNil from '~/utils/object/is-not-nil';
import { getShortName } from '~/utils/resource';
import {
  mapSpecificTypeToSummaryLabel,
  mapSpecificTypeToSummaryIcon,
  mapPaymentSpecificTypeToSummaryLabel,
  mapSpecificTypeToSummaryDiscountLabel,
} from '~/utils/booking/summary';
import isEmpty from '~/utils/object/is-empty';
import isNumber from '~/utils/object/is-number';
import isNotUndefined from '~/utils/object/is-not-undefined';
import omit from '~/utils/fp/omit';
import propEq from '~/utils/fp/prop-eq';
import compose from '~/utils/fp/compose';
import map from '~/utils/fp/map';
import reject from '~/utils/fp/reject';
import includes from '~/utils/fp/includes';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import anyPass from '~/utils/fp/any-pass';
import propIsNotEmpty from '~/utils/fp/prop-is-not-empty';
import head from '~/utils/fp/head';
import sortByProp from '~/utils/fp/sort-by-prop';

export const getDefaultState = () => ({
  total: null,
  amountDue: null,
  amountDueWithoutThirdPartyServices: null,
  exchangedAmountDue: null,
  exchangedAmountDueWithoutThirdPartyServices: null,
  loadingCounter: 0,
  isWizzFlexSelectedAtFlightSelect: false,
  isWizzFlexSelectedAtFlightSelectBackup: false,
  mcpSelectionSource: null,

  pastPurchases: getPastPurchases(),
  hasPurchasedInsuranceAlready: {},

  flights: {
    total: null,
    outboundFlight: {},
    returnFlight: {},
    outboundPayableItem: null,
    returnPayableItem: null,
    fareLock: {},
    payableWdcMembership: {},
  },

  passengers: {
    total: null,
    ancillaries: [],
  },

  seats: {
    total: null,
    passengers: [],
  },

  services: {
    total: null,
    services: {
      globals: [],
      outboundFlight: [],
      returnFlight: [],
    },
  },

  payments: {
    total: null,
    coupons: [],
    payments: [],
  },

  departureStation: null,
  arrivalStation: null,
});

const getPastPurchases = () => ({
  fareLockFinalize: {
    lockedAtPrice: getDefaultPrice(),
    total: getDefaultPrice(),
  },
  [OUTBOUND_FLIGHT_NAME]: {
    fromIata: '',
    toIata: '',
    fees: [],
    total: getDefaultPrice(),
  },
  [RETURN_FLIGHT_NAME]: {
    fromIata: '',
    toIata: '',
    fees: [],
    total: getDefaultPrice(),
  },
  globals: {
    fees: [],
    total: getDefaultPrice(),
  },
});

export const resetState = (state) => Object.assign(state, getDefaultState());

export const calculateDiscountPrice = (promotedPrice, price) => promotedPrice - price;

export const isPaymentStatusAccepted = propEq('status', PAYMENT_STATUS_ACCEPTED);

export const flightsFrom = (bookingCurrencyCode, flights) => {
  const { total, outbound: outboundFlight, return: returnFlight } = flights || {};
  const newOutboundFlight = outboundFlight
    ? flightFrom(bookingCurrencyCode, outboundFlight)
    : {};
  const newReturnFlight = returnFlight
    ? flightFrom(bookingCurrencyCode, returnFlight)
    : {};
  const outboundFareLockPriceAmount = pathOr(
    0,
    'fareLockPrice.amount',
    newOutboundFlight
  );
  const returnFareLockPriceAmount = pathOr(0, 'fareLockPrice.amount', newReturnFlight);
  const outboundFlightTotalAmount = pathOr(0, 'total.amount', newOutboundFlight);
  const returnFlightTotalAmount = pathOr(0, 'total.amount', newReturnFlight);
  const lockedPrice = {
    amount: outboundFlightTotalAmount + returnFlightTotalAmount,
    currencyCode: bookingCurrencyCode,
  };
  const fareLock =
    outboundFareLockPriceAmount > 0 || returnFareLockPriceAmount > 0
      ? {
          price: {
            amount: outboundFareLockPriceAmount + returnFareLockPriceAmount,
            currencyCode: bookingCurrencyCode,
          },
          lockedPrice,
        }
      : {};
  const { wdcMembershipFee, wdcRenewalFee, wdcMembershipSpecificType } =
    newOutboundFlight;
  const newOutboundFlightWithoutWdc = omit(
    ['wdcMembershipFee', 'wdcRenewalFee', 'wdcMembershipSpecificType'],
    newOutboundFlight
  );

  return {
    total: total || getDefaultPrice(bookingCurrencyCode),
    outboundFlight: newOutboundFlightWithoutWdc,
    returnFlight: newReturnFlight,
    fareLock,
    ...(wdcMembershipFee && { wdcMembershipFee }),
    ...(wdcRenewalFee && { wdcRenewalFee }),
    ...(wdcMembershipSpecificType && { wdcMembershipSpecificType }),
  };
};

const flightFrom = (bookingCurrencyCode, flight) => {
  const {
    flightNumber,
    carrierCode,
    departureStation: departureStationIata,
    arrivalStation: arrivalStationIata,
    departureDate,
    arrivalDate,
    bundle,
    isFamilyBundle,
    fareDiscountType: discountType,
    price: ticketPrice,
    displayCount: ticketDisplayCount,
    discountRate,
    discountPrice,
    total,
    fees,
    promotedPrice,
    promotionValue,
    opSuffix,
  } = flight || {};

  const newFees = flightFeesFrom(bookingCurrencyCode, fees);
  const fareLockPrice = (fees.find(propEq('specificType', SPECIFIC_TYPE_FARE_LOCK)) || {})
    .price;
  const wdcMembership =
    fees.find(propEq('specificType', SPECIFIC_TYPE_WDC_MEMBERSHIP)) ||
    fees.find(propEq('specificType', SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY)) ||
    {};
  const wdcMembershipFee = !isEmpty(wdcMembership.promotedPrice)
    ? wdcMembership.promotedPrice
    : wdcMembership.price;
  const wdcRenewalFee = (
    fees.find(propEq('specificType', SPECIFIC_TYPE_WDC_RENEWAL)) || {}
  ).price;

  const wdcMembershipSpecificType = wdcMembership.specificType;

  return {
    flightNumber: flightNumber || '',
    opSuffix: opSuffix || '',
    carrierCode: carrierCode || '',
    departureStationIata: departureStationIata || '',
    arrivalStationIata: arrivalStationIata || '',
    departureDate: departureDate || '',
    arrivalDate: arrivalDate || '',
    bundle: bundle || '',
    isFamilyBundle: isFamilyBundle || false,
    discountType: discountType || '',
    ticketPrice: ticketPrice || getDefaultPrice(bookingCurrencyCode),
    ticketDisplayCount: ticketDisplayCount || 0,
    promotedPrice: promotedPrice || getDefaultPrice(bookingCurrencyCode),
    promotionValue: promotionValue || 0,
    discountRate: discountRate || 0,
    discountPrice: discountPrice || getDefaultPrice(bookingCurrencyCode),
    total: total || getDefaultPrice(bookingCurrencyCode),
    fees: newFees,
    fareLockPrice: fareLockPrice || getDefaultPrice(bookingCurrencyCode),
    ...(wdcMembershipFee && { wdcMembershipFee }),
    ...(wdcRenewalFee && { wdcRenewalFee }),
    ...(wdcMembershipSpecificType && { wdcMembershipSpecificType }),
  };
};

const flightFeesFrom = (bookingCurrencyCode, fees) =>
  compose(
    map(flightFeeFrom(bookingCurrencyCode)),
    reject((fee) =>
      includes(fee.specificType, [
        SPECIFIC_TYPE_FARE_LOCK,
        SPECIFIC_TYPE_WDC_MEMBERSHIP,
        SPECIFIC_TYPE_WDC_RENEWAL,
        SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY,
      ])
    )
  )(fees || []);

const flightFeeFrom = curry((bookingCurrencyCode, fee) => {
  const { price, discountPrice, displayCount, specificType } = fee || {};
  const label = mapSpecificTypeToSummaryLabel(specificType);
  const discountLabel = mapSpecificTypeToSummaryDiscountLabel(specificType);
  return {
    label,
    ...(discountLabel && { discountLabel }),
    price: price || getDefaultPrice(bookingCurrencyCode),
    ...(discountPrice && { discountPrice }),
    displayCount: displayCount || 0,
    specificType,
  };
});

export const passengersFrom = (bookingCurrencyCode, passengers) => {
  const { total, passengersList = [] } = passengers || {};
  const ancillaries = passengersList
    .map(passengersAncillaryFrom(bookingCurrencyCode))
    .filter(
      anyPass(
        propIsNotEmpty('generic'),
        propIsNotEmpty(OUTBOUND_FLIGHT),
        propIsNotEmpty(RETURN_FLIGHT)
      )
    );
  return {
    total: total || getDefaultPrice(bookingCurrencyCode),
    ancillaries,
  };
};

const passengersAncillaryFrom = curry((bookingCurrencyCode, passenger) => {
  const {
    passengerNumber: passengerId,
    globals,
    [OUTBOUND_FLIGHT_NAME]: outboundFlight,
    [RETURN_FLIGHT_NAME]: returnFlight,
  } = passenger || {};
  const generic = (globals || []).map(passengerAncillaryFrom(bookingCurrencyCode));
  const newOutboundFlight = (outboundFlight || []).map(
    passengerAncillaryFrom(bookingCurrencyCode)
  );
  const newReturnFlight = (returnFlight || []).map(
    passengerAncillaryFrom(bookingCurrencyCode)
  );
  return {
    passengerId: isNumber(passengerId) ? passengerId : -1,
    generic,
    [OUTBOUND_FLIGHT]: newOutboundFlight,
    [RETURN_FLIGHT]: newReturnFlight,
  };
});

const passengerAncillaryFrom = curry((bookingCurrencyCode, ancillary) => {
  const {
    price,
    specificType,
    displayCount,
    included,
    code: feeCode,
    promotedPrice,
    coupon,
  } = ancillary || {};
  const label = mapSpecificTypeToSummaryLabel(specificType);
  const icon = mapSpecificTypeToSummaryIcon(specificType);
  return {
    label,
    icon,
    specificType: specificType || '',
    price: price || getDefaultPrice(bookingCurrencyCode),
    promotedPrice,
    displayCount: displayCount || 0,
    included: included || false,
    feeCode,
    coupon,
  };
});

export const seatsFrom = (bookingCurrencyCode, seats) => {
  const { total, passengersList } = seats || {};
  const newPassengers = (passengersList || []).map(
    seatsPassengerFrom(bookingCurrencyCode)
  );
  return {
    total: total || getDefaultPrice(bookingCurrencyCode),
    passengers: newPassengers,
  };
};

const seatsPassengerFrom = curry((bookingCurrencyCode, passenger) => {
  const {
    passengerNumber: id,
    outbound: outboundFlight,
    return: returnFlight,
  } = passenger || {};
  const outboundSeat = seatsPassengerSeatFrom(bookingCurrencyCode, outboundFlight || []);
  const returnSeat = seatsPassengerSeatFrom(bookingCurrencyCode, returnFlight || []);
  return {
    id: isNumber(id) ? id : -1,
    outboundSeat,
    returnSeat,
  };
});

const seatsPassengerSeatFrom = curry((bookingCurrencyCode, flight) => {
  if (isEmpty(flight || [])) return {};
  const {
    unitDesignator: code,
    price,
    included,
    specificType,
    code: feeCode,
    promotedPrice,
  } = head(flight);
  const icon = mapSpecificTypeToSummaryIcon(specificType);

  return {
    code: code || '',
    icon,
    price: price || getDefaultPrice(bookingCurrencyCode),
    promotedPrice,
    included: included || false,
    feeCode,
  };
});

export const groupedServicesFrom = (
  resourcesStations,
  bookingCurrencyCode,
  services,
  stations = {}
) => {
  let {
    total,
    globals,
    [OUTBOUND_FLIGHT_NAME]: outboundFlight,
    [RETURN_FLIGHT_NAME]: returnFlight,
  } = services || {};
  globals = globals || [];
  outboundFlight = outboundFlight || [];
  returnFlight = returnFlight || [];
  const newServices = map(
    servicesFrom(resourcesStations, bookingCurrencyCode, stations),
    {
      globals,
      outboundFlight,
      returnFlight,
    }
  );

  return {
    total: total || getDefaultPrice(bookingCurrencyCode),
    services: newServices,
  };
};

const servicesFrom = curry((resourcesStations, bookingCurrencyCode, stations, services) =>
  compose(
    sortByProp('order'),
    map(serviceFrom(resourcesStations, bookingCurrencyCode, stations))
  )(services)
);

const serviceFrom = curry((resourcesStations, bookingCurrencyCode, stations, service) => {
  const {
    price,
    promotedPrice,
    specificType,
    displayCount,
    order,
    code: feeCode,
    isDepartureStation,
  } = service || {};
  const label = mapSpecificTypeToSummaryLabel(specificType);
  const icon = mapSpecificTypeToSummaryIcon(specificType);

  return {
    specificType,
    label,
    icon,
    order: isNumber(order) ? order : -1,
    price: price || getDefaultPrice(bookingCurrencyCode),
    displayCount: displayCount || 0,
    ...(isNotNil(promotedPrice) && promotedPrice.amount < price.amount
      ? {
          discount: true,
          discountPrice: {
            amount: calculateDiscountPrice(promotedPrice.amount, price.amount),
            currencyCode: price.currencyCode,
          },
        }
      : {}),
    feeCode,
    ...(isNotUndefined(isDepartureStation)
      ? {
          station: getShortName(
            resourcesStations,
            stations[isDepartureStation ? STATION_DEPARTURE : STATION_ARRIVAL]
          ),
        }
      : {}),
  };
});

export const paymentsFrom = (bookingCurrencyCode, payment) => {
  const { total, coupons, payments: _payments } = payment || {};
  return {
    total: total || getDefaultPrice(bookingCurrencyCode),
    coupons: (coupons || []).map(couponFrom(bookingCurrencyCode)),
    payments: (_payments || []).map(paymentFrom(bookingCurrencyCode)),
  };
};

const couponFrom = curry((bookingCurrencyCode, coupon) => {
  const { price } = coupon || {};
  return {
    // NOTE: we could generate different labels for the different coupons:
    //  "WDC", "Fare", "CabinBaggage", "Baggage", "SportsEquipment"
    label: 'summary-cart-coupon-discount',
    price: price || getDefaultPrice(bookingCurrencyCode),
  };
});

const paymentFrom = curry((bookingCurrencyCode, payment) => {
  const { specificType, status, price } = payment || {};
  const label = mapPaymentSpecificTypeToSummaryLabel(specificType);
  return {
    label,
    status: status ? status.toLowerCase() : '',
    price: price || getDefaultPrice(bookingCurrencyCode),
  };
});
