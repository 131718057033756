/**
 * Convert viewmodel banner data to analytics sendable objects
 * see: ecommerce.js
 *
 * @param banner         client side prepped banner data
 * @param rawPosition    0, 1, 2
 * @param type           hero, cf (cheap flights)
 */
export default function bannerToAnalytics(data) {
  const { banner, type, rawPosition, modifier = null } = data;
  const position = `${type}${rawPosition + 1}`;

  const bannerTypes = {
    hero: 'Promo',
  };
  const bannerType = `${bannerTypes[type] ?? `Unknown "${type}"`} banner`;

  const experiment = banner.experimentVariant
    ? `${banner.experimentVariant.toLowerCase()}_`
    : '';

  const id = `${experiment}${bannerType.replaceAll(' ', '-')}-${
    banner.bannerId
  }`.toLowerCase();

  const name = modifier
    ? `${experiment}${banner.bannerId}-${modifier.toUpperCase()}`
    : `${experiment}${banner.bannerId}`;

  const result = {
    id, // example: 'PROMO_1234',
    name, // example: 'Summer Sale',
    creative_name: `${type} banner`, // example: 'summer_banner2',
    creative_slot: position, // example: 'banner_slot1'
  };

  return result;
}
