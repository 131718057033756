export const ALLOWED_ACCENTS = 'áéíóúöőüűÁÉÍÓÚÖŐÜŰ';
export const ALLOWED_SPECIAL_CHARS = '~ˇ^˘°˛`˙˙´˝¨¸$ß¤\\|Ä€ÍŁłí][äđĐ<>#&@{};>*,.-?:_/*-+';

// based on https://github.com/aristus/accent-folding/blob/master/accent-fold.js
// prettier-ignore
export const ACCENTS_MAP = {
  'a': ['ẚ', 'á', 'à', 'ă', 'ắ', 'ằ', 'ẵ', 'ẳ', 'â', 'ấ', 'ầ', 'ẫ', 'ẩ', 'ǎ', 'å', 'ǻ', 'ä', 'ǟ', 'ã', 'ȧ', 'ǡ', 'ą', 'ā', 'ả', 'ȁ', 'ȃ', 'ạ', 'ặ', 'ậ', 'ḁ', 'ⱥ', 'ǽ', 'ǣ'],
  'b': ['ḃ', 'ḅ', 'ḇ', 'ƀ', 'ᵬ', 'ɓ', 'ƃ'],
  'c': ['ć', 'ĉ', 'č', 'ċ', 'ç', 'ḉ', 'ȼ', 'ƈ', 'ɕ'],
  'd': ['ď', 'ḋ', 'ḑ', 'ḍ', 'ḓ', 'ḏ', 'đ', 'ɖ', 'ɗ', 'ƌ', 'ð'],
  'e': ['é', 'è', 'ĕ', 'ê', 'ế', 'ề', 'ễ', 'ể', 'ě', 'ë', 'ẽ', 'ė', 'ȩ', 'ḝ', 'ę', 'ē', 'ḗ', 'ḕ', 'ẻ', 'ȅ', 'ȇ', 'ẹ', 'ệ', 'ḙ', 'ḛ', 'ɇ', 'ɚ', 'ɝ'],
  'f': ['ḟ', 'ƒ'],
  'g': ['ǵ', 'ğ', 'ĝ', 'ǧ', 'ġ', 'ģ', 'ḡ', 'ǥ', 'ɠ'],
  'h': ['ĥ', 'ȟ', 'ḧ', 'ḣ', 'ḩ', 'ḥ', 'ḫ', 'ẖ', 'ħ'],
  'i': ['í', 'ì', 'ĭ', 'î', 'ǐ', 'ï', 'ḯ', 'ĩ', 'i', 'į', 'ī', 'ỉ', 'ȉ', 'ȋ', 'ị', 'ḭ', 'ı', 'ɨ'],
  'j': ['ĵ', 'ʝ', 'ɉ', 'ʄ'],
  'k': ['ḱ', 'ǩ', 'ķ', 'ḳ', 'ḵ', 'ƙ'],
  'l': ['ĺ', 'ľ', 'ļ', 'ḷ', 'ḹ', 'ḽ', 'ḻ', 'ł', 'ł', 'ŀ', 'ƚ', 'ɫ', 'ɬ', 'ɭ'],
  'm': ['ḿ', 'ṁ', 'ṃ', 'ɱ'],
  'n': ['ń', 'ǹ', 'ň', 'ñ', 'ṅ', 'ņ', 'ṇ', 'ṋ', 'ṉ', 'ɲ', 'ƞ', 'ɳ', 'ȵ', 'n'],
  'o': ['ó', 'ò', 'ŏ', 'ô', 'ố', 'ồ', 'ỗ', 'ổ', 'ǒ', 'ö', 'ȫ', 'ő', 'õ', 'ṍ', 'ṏ', 'ȭ', 'ȯ', 'ȱ', 'ø', 'ǿ', 'ǫ', 'ǭ', 'ō', 'ṓ', 'ṑ', 'ỏ', 'ȍ', 'ȏ', 'ợ', 'ọ', 'ộ', 'ɵ', 'ɔ', 'ɒ'],
  'p': ['ṕ', 'ṗ', 'ᵽ', 'ƥ', 'p'],
  'q': ['ɋ', 'ʠ'],
  'r': ['ŕ', 'ř', 'ṙ', 'ŗ', 'ṛ', 'ṟ', 'ɍ'],
  's': ['ś', 'ŝ', 'š', 'ṡ', 'ş', 'ṣ', 'ṥ', 'ṧ', 'ṩ', 'ʂ'],
  't': ['ť', 'ṫ', 'ţ', 'ṭ', 'ț', 'ṱ', 'ṯ', 'ŧ', 'ƭ', 'ʈ'],
  'u': ['ú', 'ù', 'ŭ', 'û', 'ǔ', 'ů', 'ü', 'ǘ', 'ǜ', 'ǚ', 'ǖ', 'ű', 'ũ', 'ṹ', 'ų', 'ū', 'ṻ', 'ủ', 'ȕ', 'ȗ', 'ụ', 'ự', 'ṳ', 'ŭ', 'Ʉ', 'ʉ'],
  'v': ['ṽ', 'ṿ', 'ʋ', 'v'],
  'w': ['ŵ', 'ẁ', 'ẃ', 'Ŵ', 'Ẅ', 'ẅ', 'ẇ', 'ẉ', 'ẘ'],
  'x': ['ẍ', 'ẋ', 'x'],
  'y': ['ý', 'ỳ', 'ŷ', 'ẙ', 'ÿ', 'ỹ', 'ẏ', 'ȳ', 'ỷ', 'ẙ', 'ỵ', 'ƴ', 'ɏ'],
  'z': ['ź', 'ž', 'ẑ', 'ʑ', 'ⱬ', 'ż', 'ẓ', 'ȥ', 'ẕ', 'ᵶ', 'ᶎ', 'ʐ', 'ʒ', 'ƶ', 'ȥ', 'ʓ']
};
