/**
 * Returns a list containing the names of all the enumerable own properties of
 * the supplied object.
 *
 * note: that the order of the output array is not guaranteed to be consistent
 * across different JS platforms.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/keys.js
 *
 * @type {(obj: Object<string, any>) => string[]}
 * @example
 *
 *      keys({ a: 1, b: 2, c: 3 }); //=> ['a', 'b', 'c']
 */
const keys = (obj) => Object.keys(obj);

export default keys;
