import curry from '../curry';
import { _includes } from '../includes';

// note: this is too loose we need a way to type function overloads
// note: this one almost works, but it still let this happen: `includes(1, 'foo bar')`
//   <T, U>(item: T, list: U extends string ? U : T[]): boolean
// note: this one works though if we want to
//   U extends { includes: (value: T) => boolean }
/**
 * @typedef {<T, U>(item: T, list: U) => boolean} NotIncludesFn
 */

/**
 * @type NotIncludesFn
 */
export const _notIncludes = (item, list) => !_includes(item, list);

/**
 * @type NotIncludesFn
 */
const notIncludes = curry(_notIncludes);

export default notIncludes;
