import { getDefaultState } from './internal';
import * as m from './mutation-types';

export default {
  [m.SET_ERRORS](state, errors) {
    state.errors = errors;
  },

  [m.RESET_AVAILABLE_FEEDBACK_STAGES](state) {
    state.booking.availableFeedbackStages = [];
  },

  [m.INITIALIZE](state, { booking, operations }) {
    state.booking = booking;
    state.operations = operations;
  },

  [m.SET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE](state) {
    state.isSuccessfulBookingAffiliateModalVisible = true;
  },

  [m.UNSET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE](state) {
    state.isSuccessfulBookingAffiliateModalVisible = false;
  },

  [m.SET_IS_NEW_BOOKING](state, value) {
    state.isNewBooking = value;
  },

  [m.SET_IS_NEW_SUCCESSFUL_BOOKING_INITIALIZED](state, value) {
    state.isSuccessfulBookingInitialized = value;
  },

  [m.RESET](state) {
    Object.assign(state, getDefaultState());
  },
};
