import { isInfant, sortPassengers } from '~/utils/booking/passenger';
import propEq from '~/utils/fp/prop-eq';
import map from '~/utils/fp/map';
import always from '~/utils/fp/always';
import { SPECIAL_ASSISTANCE_VALUE_NO, SPECIAL_ASSISTANCE_VALUE_YES } from '~/constants';
import equals from '~/utils/fp/equals';
import pick from '~/utils/fp/pick';
import {
  createUniqueInfants,
  getDefaultState,
  isWheelchairControlProp,
  isWheelchairProp,
} from './internal';
import * as m from './mutation-types';

const reset = (state) => Object.assign(state, getDefaultState());

export default {
  [m.RESET]: reset,

  [m.SET_PASSENGERS](state, data) {
    state.anyPassengerCheckedInToAnyFlight = data.anyPassengerCheckedInToAnyFlight;
    state.infantsCanBeMoved = data.infantsCanBeMoved;
    state.passengers = sortPassengers(data.passengers || []).map((passenger) => {
      const { reducedMobility = {} } = passenger;
      const hasTrueValue = (prop) => Boolean(reducedMobility[prop]);

      const hasWheelchair = Object.keys(reducedMobility)
        .filter(isWheelchairProp)
        .some(hasTrueValue);

      const hasWheelchairControl = Object.keys(reducedMobility)
        .filter(isWheelchairControlProp)
        .some(hasTrueValue);

      return {
        ...passenger,
        reducedMobility: {
          ...reducedMobility,
          // pseudo props
          wheelchair: hasWheelchair || null,
          hasOwnWheelchair: !hasWheelchair ? null : hasWheelchairControl,
        },
        // privilege pass
        alreadyHavePrivilegePass: false,
        privilegePassActivated: false,
        privilegePassPrice: null,
        privilegePassEmail: '',
        privilegePassActivatedFor: {},
        isPanelExpanded: true,
        isPanelSummaryVisible: false,
        needSpecialAssistance: null,
      };
    });

    /**
     * Since in the Navitaire the infant is just a property the BE are not able to create fields on it.
     * This is why have to do nice things like this, because we modifying the old unique field of the infant
     * the `passengerAdultNumber`, we have to cook a new unique filed.
     */
    state.passengers = createUniqueInfants(state.passengers);
  },

  [m.UPDATE_INFANT_ADULT_PASSENGER_NUMBER](state, payload) {
    const {
      infantPassengerNumber,
      newAdultPassengerNumber,
      useSavedPassengers = false,
    } = payload;

    const dataSource = useSavedPassengers
      ? state.savedPassengerDetails
      : state.passengers;
    const infant = dataSource.find(
      propEq('infantPassengerNumber', infantPassengerNumber)
    );
    infant.passengerAdultNumber = newAdultPassengerNumber;
  },

  [m.SET_FAKE_GROUP_BOOKING_PASSENGER](state) {
    state.passengers = [
      {
        passengerNumber: 0,
        firstName: 'Lorem',
        lastName: 'Ipsum',
        gender: 'male',
        iamTravelling: true,
        isPanelExpanded: true,
      },
    ];
  },

  [m.RESTORE_PASSENGERS](state, canBeReset = true) {
    state.passengers.forEach((passenger) => {
      state.savedPassengerDetails.forEach((pax) => {
        const isInfantPax = isInfant(passenger);
        const passengerNumberProp = isInfantPax
          ? 'infantPassengerNumber'
          : 'passengerNumber';
        if (pax[passengerNumberProp] === passenger[passengerNumberProp]) {
          Object.assign(passenger, pax);
        }
      });
    });

    if (canBeReset) {
      state.savedPassengerDetails = [];
    }
  },

  [m.SET_SAVED_PASSENGER_DETAILS](state) {
    state.passengers.forEach((passenger) => {
      state.savedPassengerDetails.push(
        pick(
          [
            'gender',
            'lastName',
            'firstName',
            'dateOfBirth',
            'passengerType',
            'iamTravelling',
            'passengerNumber',
            'isTravelPartner',
            'reducedMobility',
            'canBeTravelPartner',
            'isPrmConsentAccepted',
            'passengerAdultNumber',
            'infantPassengerNumber',
            'needSpecialAssistance',
            'privilegePassActivated',
            'hasInfantAssignmentError',
          ],
          passenger
        )
      );
    });
  },

  [m.UPDATE_PASSENGER_REDUCED_MOBILITY](state, data) {
    const {
      passengerNumber,
      isPrmConsentAccepted,
      reducedMobility,
      useSavedPassengers = false,
    } = data;
    const dataSource = useSavedPassengers
      ? state.savedPassengerDetails
      : state.passengers;
    const passenger = dataSource.find(propEq('passengerNumber', passengerNumber));
    passenger.isPrmConsentAccepted = isPrmConsentAccepted;
    passenger.reducedMobility = reducedMobility;
  },

  [m.RESET_PASSENGER_REDUCED_MOBILITY](state, data) {
    const { passengerNumber } = data;
    const passenger = state.passengers.find(propEq('passengerNumber', passengerNumber));
    passenger.reducedMobility = map(always(null), passenger.reducedMobility);
    passenger.needSpecialAssistance = null;
  },

  [m.SET_I_AM_TRAVELLING](state, value) {
    state.passengers[0].iamTravelling = value;
  },

  /**
   * @type {(state: State, payload: { passenger: Object<string, any>, isInfant?: boolean }) => void}
   */
  [m.UPDATE_PASSENGER](state, payload = {}) {
    const { passenger, isInfant = false } = payload;
    const passengerNumber = isInfant
      ? passenger.infantPassengerNumber
      : passenger.passengerNumber;
    const passengerNumberProp = isInfant ? 'infantPassengerNumber' : 'passengerNumber';
    const passengerToUpdate =
      state.passengers.find(propEq(passengerNumberProp, passengerNumber)) || {};
    Object.assign(passengerToUpdate, passenger);
  },

  [m.UPDATE_PASSENGERS_ACCORDION_STATE](state, payload) {
    const { isFlightChangeOrRebookFlow, isPassengersAccordionEnabled } = payload;
    state.passengers.forEach((passenger) => {
      passenger.isPanelExpanded =
        isPassengersAccordionEnabled && !isFlightChangeOrRebookFlow
          ? passenger.passengerNumber === 0
          : !(isPassengersAccordionEnabled && isFlightChangeOrRebookFlow);
      passenger.isPanelSummaryVisible =
        isPassengersAccordionEnabled && isFlightChangeOrRebookFlow;
    });
  },

  [m.COPY_REDUCED_MOBILITY_TO_ALL_PASSENGERS](state, payload) {
    const { sourcePassengerNumber, targetPassengerNumbers } = payload;
    const sourcePassenger = state.passengers[sourcePassengerNumber];

    state.passengers.forEach((passenger) => {
      if (!targetPassengerNumbers.includes(passenger.passengerNumber)) return;
      if (equals(sourcePassenger.needSpecialAssistance, SPECIAL_ASSISTANCE_VALUE_YES))
        return;
      passenger.needSpecialAssistance = SPECIAL_ASSISTANCE_VALUE_NO;
    });
  },

  /**
   * @type {(state: State, payload: { passenger: Object<string, any>, isInfant?: boolean }) => void}
   */
  [m.UPDATE_SAVED_PASSENGER](state, payload = {}) {
    const { passenger, isInfant = false } = payload;
    const passengerNumber = isInfant
      ? passenger.infantPassengerNumber
      : passenger.passengerNumber;
    const passengerNumberProp = isInfant ? 'infantPassengerNumber' : 'passengerNumber';
    const passengerToUpdate =
      state.savedPassengerDetails.find(propEq(passengerNumberProp, passengerNumber)) ||
      {};
    Object.assign(passengerToUpdate, passenger);
  },
};
