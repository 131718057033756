import caseSensitiveStringSort from '~/utils/fp/case-sensitive-string-sort';
import { createAction } from '~/utils/store';
import * as AssetService from '~/services/asset';
import * as getters from './getters';
import * as m from './mutation-types';

/**
 * @type {(store: Store) => void}
 */
export const setLanguageCode = createAction(m.SET_LANGUAGE_CODE);

export const fetchCurrencies = async ({ commit, state }) => {
  if (getters.hasCurrencies(state)) return;
  const response = await AssetService.getCurrencies();
  const currencies = caseSensitiveStringSort(response.data?.currencies || []);
  commit(m.SET_CURRENCIES, currencies);
};

export const fetchCurrenciesOnActiveStations = async ({ commit, state }) => {
  if (getters.hasCurrenciesOnActiveStations(state)) return;
  const response = await AssetService.getCurrencies({ isActiveOnStation: true });
  const currenciesOnActiveStations = caseSensitiveStringSort(response.data?.currencies);
  commit(m.SET_CURRENCIES_ON_ACTIVE_STATIONS, currenciesOnActiveStations);
};

export const fetchAvailableLocales = async ({ commit, state }) => {
  if (getters.hasAvailableLocales(state)) return;
  const response = await AssetService.getAvailableLocales();
  const availableLocales = caseSensitiveStringSort(response.data?.cultures);
  commit(m.SET_AVAILABLE_LOCALES, availableLocales);
};

export const setIsGetAncillariesForLocaleChange = ({ commit }, flag) => {
  commit(m.SET_IS_GET_ANCILLARIES_FOR_LOCALE_CHANGE, flag);
};
