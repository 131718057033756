import isEmpty from '~/utils/object/is-empty';
import curry from '../curry';
import { _prop } from '../prop';

/**
 * @typedef {<T, K extends keyof T>(p: K, obj: T) => boolean} PropIsEmptyFn
 */

/**
 * @type PropIsEmptyFn
 */
export const _propIsEmpty = (p, obj) => isEmpty(_prop(p, obj));

/**
 * Returns `true` if the specified object property is empty, `false` otherwise.
 *
 * @type PropIsEmptyFn
 * @example
 *
 *      // pred :: Object -> Boolean
 *      var pred = propIsEmpty('a');
 *
 *      pred({a: []});              //=> true
 *      pred({a: null});            //=> true
 *      pred({a: undefined});       //=> true
 *      pred({a: ''});              //=> true
 *      pred({a: 0});               //=> true
 *      pred({a: {}});              //=> true
 */
const propIsEmpty = curry(_propIsEmpty);

export default propIsEmpty;
