export const WDC_CUT_STATUS_SUCCESS = 'success';
export const WDC_CUT_STATUS_FAILED = 'failed';

export const HTTP_500_ERROR_MESSAGE = '500-page-description';
export const HTTP_404_ERROR_MESSAGE = '404-page-description';
export const HTTP_400_ERROR_MESSAGE = '400-page-description';

export const HTTP_ERROR_MESSAGES = new Map()
  .set(500, HTTP_500_ERROR_MESSAGE)
  .set(404, HTTP_404_ERROR_MESSAGE)
  .set(400, HTTP_400_ERROR_MESSAGE);
