/**
 * The upper case version of a string.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/toUpper.js
 *
 * @type {(str: string) => string}
 * @example
 *
 *      toUpperCase('abc'); //=> 'ABC'
 */
const toUpperCase = (str) => str.toUpperCase();

export default toUpperCase;
