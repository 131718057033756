import type from '~/utils/fp/type';

/**
 * Creates a deep copy of the source that can be used in place of the source
 * object without retaining any references to it.
 * The source object may contain (nested) `Array`s and `Object`s,
 * `Number`s, `String`s, `Boolean`s and `Date`s.
 * `Function`s are assigned by reference rather than copied.
 *
 * Dispatches to a `clone` method if present.
 *
 * Note that if the source object has multiple nodes that share a reference,
 * the returned object will have the same structure, but the references will
 * be pointed to the location within the cloned value.
 *
 * based on:https://github.com/ramda/ramda/blob/v0.27.0/source/clone.js
 * based on: https://github.com/lukeed/klona/blob/master/src/index.js
 *
 * @type {<T>(a: T) => T}
 * @example
 *
 *      const objects = [{}, {}, {}];
 *      const objectsClone = clone(objects);
 *      objects === objectsClone; //=> false
 *      objects[0] === objectsClone[0]; //=> false
 */
const deepClone = (value) => _deepClone(value, [], [], true);

const _deepClone = (value, fromRefs, toRefs, deep) => {
  // note: this handles objects and arrays
  // note: this handles cyclic structures as well
  const copy = (copiedValue) => {
    let index = 0;
    while (index < fromRefs.length) {
      if (value === fromRefs[index]) return toRefs[index];
      index += 1;
    }

    fromRefs[index] = value;
    toRefs[index] = copiedValue;

    for (const key in value) {
      copiedValue[key] = deep
        ? _deepClone(value[key], fromRefs, toRefs, true)
        : value[key];
    }

    return copiedValue;
  };

  switch (type(value)) {
    case 'Object':
      return copy({});
    case 'Array':
      return copy([]);
    case 'Date':
      return new Date(value.valueOf());
    case 'RegExp':
      return _cloneRegExp(value);
    case 'Set':
      return new Set([...value].map(deepClone));
    case 'Map': {
      const map = new Map([...value].map((entry) => entry.map(deepClone)));
      return map;
    }
    default:
      return value;
  }
};

const _cloneRegExp = (pattern) => {
  return new RegExp(
    pattern.source,
    (pattern.global ? 'g' : '') +
      (pattern.ignoreCase ? 'i' : '') +
      (pattern.multiline ? 'm' : '') +
      (pattern.sticky ? 'y' : '') +
      (pattern.unicode ? 'u' : '')
  );
};

export default deepClone;
