import applySpec from '~/utils/fp/apply-spec';
import path from '~/utils/fp/path';
import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import either from '~/utils/fp/either';
import {
  FLOW_TYPE_NEW_BOOKING,
  FLOW_TYPE_CHANGE_FLIGHT,
  FLOW_TYPE_REBOOK,
  FLOW_TYPE_CHANGE_NAME,
  FLOW_TYPE_CHECK_IN,
  FLOW_TYPE_ADD_SERVICES,
  FLOW_TYPE_FARE_LOCK_FINALIZE,
  FLOW_TYPE_CONNECTED_BOOKING,
} from '~/constants';
import complement from '~/utils/fp/complement';
import { ANALYTICS_FLOW_TYPE_CHECKIN } from '~/services/ecommerce/constants';
import anyPass from '~/utils/fp/any-pass';

const coreProp = (prop) => path(`coreBooking.${prop}`);

export const paymentCurrencyCode = coreProp('payment.currency');

export const departureCurrencyCode = coreProp('currencies.departure');

export const arrivalCurrencyCode = coreProp('currencies.arrival');

export const bookingCurrencyCode = coreProp('currencies.booking');

export const isPaymentCurrencyChangedFromBookingCurrency = (state) =>
  paymentCurrencyCode(state) !== bookingCurrencyCode(state);

export const isDefaultCurrencySelected = (state) =>
  departureCurrencyCode(state) === paymentCurrencyCode(state);

export const hasExtendedAncillary = coreProp('hasExtendedAncillary');

export const flowType = coreProp('flowType');

export const isNewBookingFlow = compose(equals(FLOW_TYPE_NEW_BOOKING), flowType);

export const isFlightChangeFlow = compose(equals(FLOW_TYPE_CHANGE_FLIGHT), flowType);

export const isRebookFlow = compose(equals(FLOW_TYPE_REBOOK), flowType);

export const isFlightChangeOrRebookFlow = either(isFlightChangeFlow, isRebookFlow);

export const isNotFlightChangeOrRebookFlow = complement(isFlightChangeOrRebookFlow);

export const isNameChangeFlow = compose(equals(FLOW_TYPE_CHANGE_NAME), flowType);

export const isStandaloneCheckInFlow = compose(
  either(equals(FLOW_TYPE_CHECK_IN), equals(ANALYTICS_FLOW_TYPE_CHECKIN)),
  flowType
);

export const isCheckInFlow = either(coreProp('isCheckInFlow'), isStandaloneCheckInFlow);

export const isAddServicesFlow = compose(
  anyPass(
    equals(FLOW_TYPE_ADD_SERVICES),
    equals(ANALYTICS_FLOW_TYPE_CHECKIN),
    equals(FLOW_TYPE_CHECK_IN),
    equals(FLOW_TYPE_CHANGE_NAME)
  ),
  flowType
);

export const isConnectedBookingFlow = compose(
  equals(FLOW_TYPE_CONNECTED_BOOKING),
  flowType
);

export const isNotConnectedBookingFlow = complement(isConnectedBookingFlow);

export const isFareLockFinalizeFlow = compose(
  equals(FLOW_TYPE_FARE_LOCK_FINALIZE),
  flowType
);

export const pnr = coreProp('pnr');

export const lastName = coreProp('lastName');

export const pnrAndLastName = applySpec({
  pnr,
  lastName,
});
