import { HTTP_STATUS_SERVICE_UNAVAILABLE } from '~/constants/http-status';
import not from '~/utils/fp/not';
import hassocPath from '~/utils/object/hassoc-path';
import halter from '~/utils/object/halter';
import hevolve from '~/utils/object/hevolve';
import { createDeferred } from '~/utils/promise';
import go from '~/utils/router/go';
import { mutationAssocPath } from '~/utils/store';
import {
  DEFAULT_SESSION_TIMEOUT_IN_MINUTES,
  DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS,
  DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS,
} from './constants';
import * as m from './mutation-types';

const setIsBotBehaviorDetected = hassocPath('isBotBehaviorDetected', true);

/**
 * @type {(state: State, payload: { url: string, status: number, reason: string|null } => void}
 */
const updateWebsiteDownInfo = (state, payload = {}) => {
  state.isWebsiteDown = payload.status === HTTP_STATUS_SERVICE_UNAVAILABLE;
  state.websiteDownReason = payload.reason || '';
};

const setNavigationLoadingState = hassocPath('volatile.isNavigationButtonLoading', true);

const unsetNavigationLoadingState = hassocPath(
  'volatile.isNavigationButtonLoading',
  false
);

/**
 * @type {(state: State, value: string) => void}
 */
const setActiveElementId = mutationAssocPath('volatile.activeElementId');

/**
 * @type {(state: State, value: boolean) => void}
 */
const setIsInputFocused = mutationAssocPath('volatile.isInputFocused');

const setStickyHeaderVisibility = hassocPath('volatile.isStickyHeaderVisible', true);

const unsetStickyHeaderVisibility = hassocPath('volatile.isStickyHeaderVisible', false);

const showInformationServices = hassocPath(
  'volatile.isInformationAndServicesVisible',
  true
);

const hideInformationServices = hassocPath(
  'volatile.isInformationAndServicesVisible',
  false
);

const toggleInformationServices = hevolve({
  volatile: {
    isInformationAndServicesVisible: not,
  },
});

const setInformationServicesMenuItemVisible = (state, payload) => {
  state.volatile.isInformationServicesMenuItemVisible = payload;
};

const showPlan = hassocPath('volatile.isPlanVisible', true);

const hidePlan = hassocPath('volatile.isPlanVisible', false);

const togglePlan = hevolve({
  volatile: {
    isPlanVisible: not,
  },
});

const showTopFlights = hassocPath('volatile.isTopFlightsVisible', true);

const hideTopFlights = hassocPath('volatile.isTopFlightsVisible', false);

const toggleTopFlights = hevolve({
  volatile: {
    isTopFlightsVisible: not,
  },
});

const addToActiveEndpoints = (state, endpoint) => state.activeEndpoints.push(endpoint);

const removeFromActiveEndpoints = (state, endpoint) =>
  (state.activeEndpoints = state.activeEndpoints.filter((url) => url !== endpoint));

/**
 * @type {(state: State, payload: Object<string, any>) => void}
 */
const showLocalesInsuranceModal = (state, payload = {}) => {
  const { resolveCb = () => {} } = payload;

  halter(
    {
      volatile: {
        localesInsuranceModal: {
          isVisible: true,
          resolveCb,
        },
      },
    },
    state
  );
};

const hideLocalesInsuranceModal = hassocPath(
  'volatile.localesInsuranceModal.isVisible',
  false
);

const toggleLanguageSwitch = hevolve({
  volatile: {
    isLanguageSwitcherVisible: not,
  },
});

/**
 * @type {(state: State, value: boolean) => void}
 */
const setLanguageSwitchVisibility = mutationAssocPath(
  'volatile.isLanguageSwitcherVisible'
);

const showLoginLogoutFeedbackModal = hassocPath(
  'volatile.isLoginLogoutFeedbackModalVisible',
  true
);

const hideLoginLogoutFeedbackModal = hassocPath(
  'volatile.isLoginLogoutFeedbackModalVisible',
  false
);

const showFlowResetModal = hassocPath('volatile.isFlowResetModalVisible', true);

const hideFlowResetModal = hassocPath('volatile.isFlowResetModalVisible', false);

const showNewPrivacyPolicyModal = hassocPath(
  'volatile.isNewPrivacyPolicyModalVisible',
  true
);

const hideNewPrivacyPolicyModal = hassocPath(
  'volatile.isNewPrivacyPolicyModalVisible',
  false
);

const showMobileNavigation = hassocPath('volatile.isMobileNavigationVisible', true);

const hideMobileNavigation = hassocPath('volatile.isMobileNavigationVisible', false);

/**
 * @type {(state: State, value: boolean) => void}
 */
const setMobileNavigationVisibility = mutationAssocPath(
  'volatile.isMobileNavigationVisible'
);

const showFindBookingModal = (state) => {
  halter(
    {
      volatile: {
        isFindBookingModalVisible: true,
        isMobileNavigationVisible: false,
      },
    },
    state
  );
};

const hideFindBookingModal = hassocPath('volatile.isFindBookingModalVisible', false);

/**
 * @type {state: State, payload: Object<String, any>) => void}
 */
const showResetPasswordModal = (state, payload = {}) => {
  hideLoginModal(state);

  const { encryptedText = null, extraParameter = null } = payload;
  halter(
    {
      volatile: {
        isMobileNavigationVisible: false,
        resetPasswordModal: {
          isVisible: true,
          encryptedText,
          extraParameter,
        },
      },
    },
    state
  );
};

const hideResetPasswordModal = (state) => {
  hassocPath('volatile.resetPasswordModal.isVisible', false, state);
  go('/', true);
};

/**
 * @type {state: State, payload: Object<String, any>) => void}
 */
const showChangePasswordModal = (state, payload = {}) => {
  const { isAgency = false, username = '' } = payload;

  halter(
    {
      volatile: {
        isMobileNavigationVisible: false,
        changePasswordModal: {
          isVisible: true,
          isAgency,
          username,
        },
      },
    },
    state
  );

  hideLoginModal(state);
};

const hideChangePasswordModal = hassocPath(
  'volatile.changePasswordModal.isVisible',
  false
);

/**
 * @type {(state: State, payload: Object, any>) => void}
 */
const showLoginModal = (state, payload = {}) => {
  // note: if some reason show login modal called more than once, during login
  //  modal already opened state etc.
  if (state.volatile.loginModal.deferred) {
    hideLoginModal(state);
  }

  const {
    modalTitle = 'login',
    cancelButtonLabel = 'cancel',
    openingSource = '',
  } = payload;

  halter(
    {
      volatile: {
        loginModal: {
          deferred: createDeferred(),
          openingSource,
          title: modalTitle,
          cancelButtonLabel,
        },
        isMobileNavigationVisible: false,
      },
    },
    state
  );

  disableTimeoutModal(state);
};

const hideLoginModal = (state, action = null) => {
  state.volatile.loginModal.deferred?.resolve({ action });
  halter({ volatile: { loginModal: { deferred: null } } }, state);
  enableTimeoutModal(state);
};

const setLoginRedirectUrl = mutationAssocPath('loginRedirectUrl');

const resetLoginRedirectUrl = hassocPath('loginRedirectUrl', null);

/**
 * @type {(state: State, payload: Object<string, any>) => void}
 */
const showRegistrationModal = (state, payload = {}) => {
  const { openingSource = '' } = payload;

  halter(
    {
      volatile: {
        registrationModal: {
          isVisible: true,
          openingSource,
        },
        isMobileNavigationVisible: false,
      },
    },
    state
  );

  disableTimeoutModal(state);
};

const hideRegistrationModal = (state) => {
  hassocPath('volatile.registrationModal.isVisible', false, state);
  enableTimeoutModal(state);
};

const showRegistrationPendingModal = (state, payload = {}) => {
  hassocPath('volatile.registrationPendingModal.isVisible', true, state);
  disableTimeoutModal(state);
};

const hideRegistrationPendingModal = (state) => {
  hassocPath('volatile.registrationPendingModal.isVisible', false, state);
  enableTimeoutModal(state);
};

const showResendVerificationEmailModal = (state) => {
  hassocPath('volatile.emailVerification.isResendEmailVisible', true, state);
  disableTimeoutModal(state);
};

const hideResendVerificationEmailModal = (state) => {
  hassocPath('volatile.emailVerification.isResendEmailVisible', false, state);
  enableTimeoutModal(state);
};

const setRegistrationEmail = (state, email) => {
  hassocPath('volatile.emailVerification.email', email, state);
};

const setEmailVerificationErrors = (state, payload) => {
  hassocPath('volatile.emailVerification.errors', payload, state);
};

/**
 * @type {(store: Store, payload: Object<String, any>) => void}
 */
const showForgotPasswordModal = (state, payload = {}) => {
  const { username = '' } = payload;
  halter({ volatile: { forgotPasswordModal: { isVisible: true, username } } }, state);
  disableTimeoutModal(state);
};

const hideForgotPasswordModal = (state) => {
  hassocPath('volatile.forgotPasswordModal.isVisible', false, state);
  enableTimeoutModal(state);
};

const showBotDetectionModal = hassocPath('volatile.isBotDetectionModalVisible', true);

const hideBotDetectionModal = hassocPath('volatile.isBotDetectionModalVisible', false);

const enableTimeoutModal = hassocPath('volatile.isTimeoutModalDisabled', false);

const disableTimeoutModal = hassocPath('volatile.isTimeoutModalDisabled', true);

/**
 * @type {(state: State, value: boolean) => void}
 */
const setIsRouteLoadingState = (state, value) =>
  hassocPath('isRouteLoading', value, state);

const setIsTimeoutsInitialized = hassocPath('isTimeoutsInitialized', true);

const setTimeouts = (state, payload = {}) => {
  const {
    sessionTimeoutInMinutes = DEFAULT_SESSION_TIMEOUT_IN_MINUTES,
    inactivityTimeoutInSeconds = DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS,
    searchRedirectInMillisecs = DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS,
  } = payload;

  halter(
    {
      sessionTimeoutInMinutes,
      inactivityTimeoutInSeconds,
      searchRedirectInMillisecs,
    },
    state
  );
};

const resetTimeouts = (state) => {
  halter(
    {
      sessionTimeoutInMinutes: DEFAULT_SESSION_TIMEOUT_IN_MINUTES,
      inactivityTimeoutInSeconds: DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS,
      searchRedirectInMillisecs: DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS,
    },
    state
  );
};

const showInvalideDateOfBirthModal = hassocPath(
  'volatile.isInvalidDateOfBirthModalVisible',
  true
);

const hideInvalideDateOfBirthModal = hassocPath(
  'volatile.isInvalidDateOfBirthModalVisible',
  false
);

export default {
  [m.SET_IS_TIMEOUTS_INITIALIZED]: setIsTimeoutsInitialized,
  [m.SET_TIMEOUTS]: setTimeouts,
  [m.RESET_TIMEOUTS]: resetTimeouts,
  [m.SET_ACTIVE_ELEMENT_ID]: setActiveElementId,
  [m.SET_IS_BOT_BEHAVIOR_DETECTED]: setIsBotBehaviorDetected,
  [m.SHOW_BOT_DETECTION_MODAL]: showBotDetectionModal,
  [m.HIDE_BOT_DETECTION_MODAL]: hideBotDetectionModal,
  [m.SHOW_CHANGE_PASSWORD_MODAL]: showChangePasswordModal,
  [m.HIDE_CHANGE_PASSWORD_MODAL]: hideChangePasswordModal,
  [m.SHOW_FIND_BOOKING_MODAL]: showFindBookingModal,
  [m.HIDE_FIND_BOOKING_MODAL]: hideFindBookingModal,
  [m.SHOW_FLOW_RESET_MODAL]: showFlowResetModal,
  [m.HIDE_FLOW_RESET_MODAL]: hideFlowResetModal,
  [m.SHOW_FORGOT_PASSWORD_MODAL]: showForgotPasswordModal,
  [m.HIDE_FORGOT_PASSWORD_MODAL]: hideForgotPasswordModal,
  [m.SHOW_INFORMATION_SERVICES]: showInformationServices,
  [m.HIDE_INFORMATION_SERVICES]: hideInformationServices,
  [m.TOGGLE_INFORMATION_SERVICES]: toggleInformationServices,
  [m.IS_INFORMATION_SERVICES_MENU_ITEM_VISIBLE]: setInformationServicesMenuItemVisible,
  [m.SET_IS_INPUT_FOCUSED]: setIsInputFocused,
  [m.TOGGLE_LANGUAGE_SWITCH]: toggleLanguageSwitch,
  [m.SET_LANGUAGE_SWITCH_VISIBILITY]: setLanguageSwitchVisibility,
  [m.SHOW_LOCALES_INSURANCE_MODAL]: showLocalesInsuranceModal,
  [m.HIDE_LOCALES_INSURANCE_MODAL]: hideLocalesInsuranceModal,
  [m.SHOW_LOGIN_MODAL]: showLoginModal,
  [m.HIDE_LOGIN_MODAL]: hideLoginModal,
  [m.SET_LOGIN_REDIRECT_URL]: setLoginRedirectUrl,
  [m.RESET_LOGIN_REDIRECT_URL]: resetLoginRedirectUrl,
  [m.SHOW_LOGIN_LOGOUT_FEEDBACK_MODAL]: showLoginLogoutFeedbackModal,
  [m.HIDE_LOGIN_LOGOUT_FEEDBACK_MODAL]: hideLoginLogoutFeedbackModal,
  [m.SHOW_MOBILE_NAVIGATION]: showMobileNavigation,
  [m.HIDE_MOBILE_NAVIGATION]: hideMobileNavigation,
  [m.SET_MOBILE_NAVIGATION_VISIBILITY]: setMobileNavigationVisibility,
  [m.SET_NAVIGATION_LOADING_STATE]: setNavigationLoadingState,
  [m.UNSET_NAVIGATION_LOADING_STATE]: unsetNavigationLoadingState,
  [m.SHOW_NEW_PRIVACY_POLICY_MODAL]: showNewPrivacyPolicyModal,
  [m.HIDE_NEW_PRIVACY_POLICY_MODAL]: hideNewPrivacyPolicyModal,
  [m.SHOW_PLAN]: showPlan,
  [m.HIDE_PLAN]: hidePlan,
  [m.TOGGLE_PLAN]: togglePlan,
  [m.SHOW_TOP_FLIGHTS]: showTopFlights,
  [m.HIDE_TOP_FLIGHTS]: hideTopFlights,
  [m.TOGGLE_TOP_FLIGHTS]: toggleTopFlights,
  [m.SHOW_REGISTRATION_MODAL]: showRegistrationModal,
  [m.HIDE_REGISTRATION_MODAL]: hideRegistrationModal,
  [m.SHOW_REGISTRATION_PENDING_MODAL]: showRegistrationPendingModal,
  [m.HIDE_REGISTRATION_PENDING_MODAL]: hideRegistrationPendingModal,
  [m.SHOW_RESEND_VERIFICATION_EMAIL_MODAL]: showResendVerificationEmailModal,
  [m.HIDE_RESEND_VERIFICATION_EMAIL_MODAL]: hideResendVerificationEmailModal,
  [m.SET_EMAIL_VERIFICATION_ERRORS]: setEmailVerificationErrors,
  [m.SET_REGISTRATION_EMAIL]: setRegistrationEmail,
  [m.SHOW_RESET_PASSWORD_MODAL]: showResetPasswordModal,
  [m.HIDE_RESET_PASSWORD_MODAL]: hideResetPasswordModal,
  [m.SET_STICKY_HEADER_VISIBILITY]: setStickyHeaderVisibility,
  [m.UNSET_STICKY_HEADER_VISIBILITY]: unsetStickyHeaderVisibility,
  [m.ENABLE_TIMEOUT_MODAL]: enableTimeoutModal,
  [m.DISABLE_TIMEOUT_MODAL]: disableTimeoutModal,
  [m.SET_IS_ROUTE_LOADING_STATE]: setIsRouteLoadingState,
  [m.SHOW_TIMEOUT_MODAL]: hassocPath('volatile.isTimeoutModalVisible', true),
  [m.HIDE_TIMEOUT_MODAL]: hassocPath('volatile.isTimeoutModalVisible', false),
  [m.SHOW_CSRF_ERROR_MODAL]: hassocPath('volatile.isCsrfErrorModalVisible', true),
  [m.HIDE_CSRF_ERROR_MODAL]: hassocPath('volatile.isCsrfErrorModalVisible', false),
  [m.SET_TIMEOUT_MODAL_VISIBILITY]: mutationAssocPath('volatile.isTimeoutModalVisible'),
  [m.UPDATE_WEBSITE_DOWN_INFO]: updateWebsiteDownInfo,
  [m.ADD_TO_ACTIVE_ENDPOINTS]: addToActiveEndpoints,
  [m.REMOVE_FROM_ACTIVE_ENDPOINTS]: removeFromActiveEndpoints,
  [m.SHOW_INVALID_DATE_OF_BIRTH_MODAL]: showInvalideDateOfBirthModal,
  [m.HIDE_INVALID_DATE_OF_BIRTH_MODAL]: hideInvalideDateOfBirthModal,
};
