import arity from '../arity';

/**
 * @typedef {<T1 extends any[], T2 extends any[], T3 extends any[], U1, U2, U3>(condition: (...args: T1) => U1, onTrue: (...args: T2) => U2, onFalse: (...args: T3) => U3) => (...args: T1|T2|T3) => U2 | U3} IfElseFn
 */

/**
 * @type IfElseFn
 */
export const _ifElse =
  (condition, onTrue, onFalse) =>
  (...args) =>
    condition(...args) ? onTrue(...args) : onFalse(...args);

/**
 * Creates a function that will process either the `onTrue` or the `onFalse`
 * function depending upon the result of the `condition` predicate.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/ifElse.js
 *
 * @type IfElseFn
 * @example
 *
 *      let incCount = ifElse(
 *        has('count'),
 *        over(lensProp('count'), inc),
 *        assoc('count', 1)
 *      );
 *      incCount({});           //=> { count: 1 }
 *      incCount({ count: 1 }); //=> { count: 2 }
 */
const ifElse = (condition, onTrue, onFalse) =>
  arity(Math.max(condition.length, onTrue.length, onFalse.length), (...args) =>
    condition(...args) ? onTrue(...args) : onFalse(...args)
  );

export default ifElse;
