import {
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  FARE_CHART_DAY_INTERVAL,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
} from '~/constants';
import {
  addDays,
  currentDateAndTime,
  toDefaultFormat,
  differenceInDays,
  isDayBefore,
  isSameDay,
} from '~/utils/date';
import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import { isOutboundDirection, isReturnDirection } from './booking';

// store default values
export const isInvalidFlight = (flight) => {
  if (!flight) {
    return false;
  }

  const isArrivalInvalid = !flight.arrivalDateTime && !flight.arrivalStation;
  const isDeparturelInvalid = !flight.departureDateTime && !flight.departureStation;
  return isArrivalInvalid && isDeparturelInvalid;
};

/**
 * mutates fare chart's dates so that dates close to today
 * are shifted with a couple of days into the future
 *
 * @param flightList
 */
export const shiftFareChartMinDates = (flightList) => {
  const maxDays = FARE_CHART_DAY_INTERVAL;
  flightList.forEach((flight) => {
    const daysFromToday = differenceInDays(flight.date, currentDateAndTime());

    if (daysFromToday < maxDays) {
      flight.date = compose(toDefaultFormat, addDays(maxDays))(currentDateAndTime());
    }
  });
};

/**
 * decorate fare chart returnFlights only (no outbound anymore): mark
 * invalid dates (time travelling passenger should not return before departure)
 *
 * @param {string} departureDate     departure date in YYYY-MM-DD format
 * @param {object} fareChartData
 * @return {object}
 */
export const markBadDatesInFareChart = (departureDate, fareChartData = {}) => {
  (fareChartData.returnFlights || []).forEach((item) => {
    item.invalid = isDayBefore(item.date, departureDate);
  });

  return fareChartData;
};

/**
 * Merge/update full fare charts. Same dates are NOT overwritten.
 *
 * @param {object} original     original fare chart (merge target, will be mutated)
 * @param {object} [extra]      another fare chart (merge source)
 * @param {string} [direction]  outbound or return
 * @param {boolean} [type]      forward/back as tru/false
 * @return object
 */
export const mergeFlightDates = (original, extra, direction, type) => {
  if (!extra) return original;

  const outType = direction && isOutboundDirection(direction) ? type : null;
  const retType = direction && isReturnDirection(direction) ? type : null;
  // todo: please don't mutate input stuff
  original.outboundFlights = mergeFareChartChunk(
    original.outboundFlights,
    extra.outboundFlights,
    outType
  );
  original.returnFlights = mergeFareChartChunk(
    original.returnFlights,
    extra.returnFlights,
    retType
  );
  return original;
};

/**
 * Merges the current and the incoming farechart data (partial)
 *
 * @private
 * @param orig      original dataset (flight dates only)
 * @param extra     incoming dataset (flight dates only)
 * @param type      forward/back (1/-1), for marking the last visible item
 * @return {*}
 */
const mergeFareChartChunk = (orig, extra, type) => {
  orig = JSON.parse(JSON.stringify(orig)); // deep clone + deobserve
  if (!orig || !extra || extra.length === 0) {
    return orig;
  }

  [...orig, ...extra].forEach((item) => delete item.isLastSeen);
  if (typeof type === 'boolean') {
    const n = type ? orig.length - 1 : 0;
    orig[n].isLastSeen = true;
  }

  extra.forEach((item) => {
    if (!orig.some((origItem) => isSameDay(origItem.date, item.date))) {
      orig.push(item);
    }
  });

  orig.sort((a, b) => {
    a = toDefaultFormat(a.date);
    b = toDefaultFormat(b.date);
    return a < b ? -1 : a > b ? 1 : 0;
  });

  return orig;
};

/**
 * @type {(direction: string) => boolean}
 */

export const isOutboundFlight = equals(DIRECTION_OUTBOUND);
/**
 * @type {(direction: string) => boolean}
 */
export const isReturnFlight = equals(DIRECTION_RETURN);

/**
 * @type {(direction: string) => string}
 */
export const flightPropByDirection = (direction) =>
  isOutboundFlight(direction) ? OUTBOUND_FLIGHT : RETURN_FLIGHT;

/**
 * @type {(direction: string) => string}
 */
export const seatPropByDirection = (direction) =>
  isOutboundFlight(direction) ? 'outboundSeat' : 'returnSeat';
