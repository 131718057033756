import curry from '../curry';

/**
 * @typedef {<T, K extends keyof T>(names: K[], obj: T) => Partial<T>} PickFn
 */

/**
 * Returns a partial copy of an object containing only the keys specified. If
 * the key does not exist it will be attached as `key: undefined`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pick.js
 *  but we dropped the prop existence check (some of our code relies on this)
 *  so essentially we got pickAll. hopefully this won't hurt because usually we
 *  want to pick existing props
 *
 * @type PickFn
 * @example
 *
 *      pick(['a', 'd'], {a: 1, b: 2, c: 3, d: 4}); //=> {a: 1, d: 4}
 *      pick(['a', 'e', 'f'], {a: 1, b: 2, c: 3, d: 4}); //=> {a: 1, e: undefined, f: undefined
 */
export const _pick = (names, obj) =>
  names.reduce((acc, name) => {
    acc[name] = obj[name];
    return acc;
  }, {});

/**
 * @type PickFn
 */
const pick = curry(_pick);

export default pick;
