import { _nth } from '../nth';

/**
 * Returns the last element of the given array.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/last.js
 *
 * @type {<T>(arr: T[]) => T}
 * @example
 *
 *      last(['fi', 'fo', 'fum']); //=> 'fum'
 *      last([]); //=> throws Error
 */
const last = (arr) => _nth(-1, arr);

export default last;
