import propNotEq from '~/utils/fp/prop-not-eq';
import filter from '~/utils/fp/filter';
import append from '~/utils/fp/append';
import hevolve from '~/utils/object/hevolve';
import { mutationAssocPath } from '~/utils/store';
import { getDefaultState } from './internal';
import * as m from './mutation-types';

export default {
  [m.SET_INITIALIZATION_ERRORS]: mutationAssocPath('errors.initializationErrors'),
  [m.SET_SUMMARY_ERRORS]: mutationAssocPath('errors.summaryErrors'),
  [m.SET_CONTACT_ERRORS]: mutationAssocPath('errors.contactErrors'),
  [m.SET_PASSENGER_ERRORS]: mutationAssocPath('errors.passengerErrors'),
  [m.SET_SEAT_SELECTION_ERRORS]: mutationAssocPath('errors.seatSelectionErrors'),
  [m.SET_CURRENT_STEP]: mutationAssocPath('currentStep'),
  [m.SET_SERVICES]: mutationAssocPath('services'),
  [m.SET_BOOKING]: (state, { booking, canDivide = true } = {}) => {
    (booking.passengerInformation || []).forEach((passenger) => {
      const passengerCount = booking.passengerInformation.length;
      const passengerIsEditable = canDivide && passengerCount > 1;
      passenger.isEditable = passengerIsEditable;
      if (!passengerIsEditable) {
        state.selectedPassengers.push(passenger);
      }
    });
    state.booking = booking;
  },

  [m.ADD_SELECTED_PASSENGER]: (state, passenger) =>
    hevolve(
      {
        selectedPassengers: append(passenger),
      },
      state
    ),

  [m.REMOVE_SELECTED_PASSENGER]: (state, { passengerNumber }) =>
    hevolve(
      {
        selectedPassengers: filter(propNotEq('passengerNumber', passengerNumber)),
      },
      state
    ),

  [m.RESET_SELECTED_PASSENGERS]: hevolve({
    selectedPassengers: () => [],
  }),

  [m.RESET_ERRORS](state) {
    Object.values(state.errors).forEach((error) => {
      error.length = 0;
    });
  },

  [m.RESET_CURRENT_STEP](state) {
    const { currentStep } = getDefaultState();
    state.currentStep = currentStep;
  },
};
