import isNotEmpty from '~/utils/object/is-not-empty';
import curry from '../curry';
import { _intersection } from '../intersection';

/**
 * @typedef {<T>(arr1: T[], arr2: T[]) => boolean} HasIntersectionFn
 */

/**
 * @type HasIntersectionFn
 */
export const _hasIntersection = (arr1, arr2) => isNotEmpty(_intersection(arr1, arr2));

/**
 *
 * Returns whether the two arrays has unique common elements or not.
 *
 * @type HasIntersectionFn
 */
const hasIntersection = curry(_hasIntersection);

export default hasIntersection;
