import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import either from '~/utils/fp/either';
import curry from '~/utils/fp/curry';
import hasIntersection from '~/utils/fp/has-intersection';
import includes from '~/utils/fp/includes';
import path from '~/utils/fp/path';
import both from '~/utils/fp/both';
import complement from '~/utils/fp/complement';
import isNotNil from '~/utils/object/is-not-nil';
import {
  ASSET_FLIGHTINFORMATION,
  CHECK_IN_CONTACT_DATA_STEP,
  CHECK_IN_CONTACT_DATA_VALIDATION,
  CHECK_IN_CONFIRM_CHECK_IN,
  CHECK_IN_CONTACT_DATA_VERIFICATION,
  CHECK_IN_TRAVEL_DOCUMENTS_STEP,
  CHECK_IN_ALL_DONE,
  BOOKING_WIZZ_DISCOUNT_CLUB,
  BOOKING_NAME_CHANGE,
  PAYMENT_EMPTY,
  CUSTOMER_CUSTOMERACCOUNTHISTORY,
  SEARCH_CONNECTED_FLIGHTS,
} from '~/constants/api-endpoint';
import { WEBSITE_DOWN_REASON_MAINTENANCE } from './constants';

/**
 * @type {(_path: string, state: State) => any}
 */
const systemProp = curry((_path, state) => path(_path, state.system));

/**
 * @type {(_path: string, state: State) => any}
 */
const systemVolatileProp = curry((_path, state) => path(_path, state.system.volatile));

/**
 * @type {(state: State) => boolean}
 */
export const isTimeoutsInitialized = systemProp('isTimeoutsInitialized');

/**
 * @type {(state: State) => number}
 */
export const sessionTimeoutInMinutes = systemProp('sessionTimeoutInMinutes');

/**
 * @type {(state: State) => number}
 */
export const inactivityTimeoutInSeconds = systemProp('inactivityTimeoutInSeconds');

/**
 * @type {(state: State) => number}
 */
export const searchRedirectInMillisecs = systemProp('searchRedirectInMillisecs');

/**
 * @type {(state: State) => boolean}
 */
export const isBotBehaviorDetected = systemProp('isBotBehaviorDetected');

/**
 * @type {(state: State) => boolean}
 */
// note: bot behavior detection activates website down and offline state
export const isWebsiteDown = either(systemProp('isWebsiteDown'), isBotBehaviorDetected);

/**
 * @type {(state: State) => boolean}
 */
const websiteDownReason = systemProp('websiteDownReason');

/**
 * @type {(state: State) => boolean}
 */
export const isWebsiteDownReasonMaintenance = compose(
  equals(WEBSITE_DOWN_REASON_MAINTENANCE),
  websiteDownReason
);

/**
 * @type {(state: State) => boolean}
 */
export const isOffline = either(
  isBotBehaviorDetected,
  both(systemProp('isWebsiteDown'), complement(isWebsiteDownReasonMaintenance))
);

export const activeElementId = systemVolatileProp('activeElementId');

export const isBotDetectionModalVisible = systemVolatileProp(
  'isBotDetectionModalVisible'
);

export const isChangePasswordModalVisible = systemVolatileProp(
  'changePasswordModal.isVisible'
);

export const isChangePasswordModalForAgency = systemVolatileProp(
  'changePasswordModal.isAgency'
);

export const changePasswordModalUsername = systemVolatileProp(
  'changePasswordModal.username'
);

export const isFindBookingModalVisible = systemVolatileProp('isFindBookingModalVisible');

export const isFlowResetModalVisible = systemVolatileProp('isFlowResetModalVisible');

export const isForgotPasswordModalVisible = systemVolatileProp(
  'forgotPasswordModal.isVisible'
);

export const forgotPasswordModalUsername = systemVolatileProp(
  'forgotPasswordModal.username'
);

export const isInformationAndServicesVisible = systemVolatileProp(
  'isInformationAndServicesVisible'
);

export const isInformationServicesMenuItemVisible = systemVolatileProp(
  'isInformationServicesMenuItemVisible'
);

export const isLanguageSwitcherVisible = systemVolatileProp('isLanguageSwitcherVisible');

export const isLoginModalVisible = compose(
  isNotNil,
  systemVolatileProp('loginModal.deferred')
);

// note: here we need to delay the rejected promise creation to not blow up on a
//  simple import for these getters
export const loginModalPromise = (state) =>
  systemVolatileProp('loginModal.deferred.promise', state) ||
  Promise.reject(new Error('Login modal is not opened!'));

export const loginRedirectUrl = systemProp('loginRedirectUrl');

export const loginModalTitle = systemVolatileProp('loginModal.title');

export const loginModalCancelButtonLabel = systemVolatileProp(
  'loginModal.cancelButtonLabel'
);

export const isLocalesInsuranceModalVisible = systemVolatileProp(
  'localesInsuranceModal.isVisible'
);

export const localesInsuranceModalResolveCb = systemVolatileProp(
  'localesInsuranceModal.resolveCb'
);

export const loginModalOpeningSource = systemVolatileProp('loginModal.openingSource');

export const isInputFocused = systemVolatileProp('isInputFocused');

export const isLoginLogoutFeedbackModalVisible = systemVolatileProp(
  'isLoginLogoutFeedbackModalVisible'
);

export const isInvalidDateOfBirthModalVisible = systemVolatileProp(
  'isInvalidDateOfBirthModalVisible'
);

export const isMobileNavigationVisible = systemVolatileProp('isMobileNavigationVisible');

export const isNavigationButtonLoading = systemVolatileProp('isNavigationButtonLoading');

export const isNewPrivacyPolicyModalVisible = systemVolatileProp(
  'isNewPrivacyPolicyModalVisible'
);

export const isPlanVisible = systemVolatileProp('isPlanVisible');

export const isTopFlightsVisible = systemVolatileProp('isTopFlightsVisible');

export const isRegistrationModalVisible = systemVolatileProp(
  'registrationModal.isVisible'
);

export const isRegistrationPendingModalVisible = systemVolatileProp(
  'registrationPendingModal.isVisible'
);

export const isResendEmailVerificationModalVisible = systemVolatileProp(
  'emailVerification.isResendEmailVisible'
);

export const emailVerificationErrors = systemVolatileProp('emailVerification.errors');

export const registrationEmail = systemVolatileProp('emailVerification.email');

export const registrationModalOpeningSource = systemVolatileProp(
  'registrationModal.openingSource'
);

export const isResetPasswordModalVisible = systemVolatileProp(
  'resetPasswordModal.isVisible'
);

export const resetPasswordModalEncryptedText = systemVolatileProp(
  'resetPasswordModal.encryptedText'
);

export const resetPasswordModalExtraParameter = systemVolatileProp(
  'resetPasswordModal.extraParameter'
);

export const isTimeoutModalDisabled = systemVolatileProp('isTimeoutModalDisabled');

export const isTimeoutModalVisible = systemVolatileProp('isTimeoutModalVisible');

/**
 * @type {(state: State) => boolean}
 */
export const isCsrfErrorModalVisible = systemVolatileProp('isCsrfErrorModalVisible');

export const isStickyHeaderVisible = systemVolatileProp('isStickyHeaderVisible');

export const isFlightInformationLoading = compose(
  includes(`${ASSET_FLIGHTINFORMATION}/post`),
  systemProp('activeEndpoints')
);

export const isContactDataLoading = compose(
  hasIntersection([
    `${CHECK_IN_CONTACT_DATA_STEP}/post`,
    `${CHECK_IN_CONTACT_DATA_STEP}/get`,
  ]),
  systemProp('activeEndpoints')
);

export const isContactDataValidationLoading = compose(
  includes(`${CHECK_IN_CONTACT_DATA_VALIDATION}/post`),
  systemProp('activeEndpoints')
);

export const isCheckInPostLoading = compose(
  includes(`${CHECK_IN_CONFIRM_CHECK_IN}/post`),
  systemProp('activeEndpoints')
);

export const isContactDataVerificationLoading = compose(
  hasIntersection([
    `${CHECK_IN_CONTACT_DATA_VERIFICATION}/get`,
    `${CHECK_IN_CONTACT_DATA_VERIFICATION}/post`,
  ]),
  systemProp('activeEndpoints')
);

export const isTravelDocumentsStepLoading = compose(
  hasIntersection([
    `${CHECK_IN_TRAVEL_DOCUMENTS_STEP}/get`,
    `${CHECK_IN_TRAVEL_DOCUMENTS_STEP}/post`,
  ]),
  systemProp('activeEndpoints')
);

export const isAllDoneStepLoading = compose(
  hasIntersection([`${CHECK_IN_ALL_DONE}/get`]),
  systemProp('activeEndpoints')
);

export const isAccountHistoryLoading = compose(
  hasIntersection([`${CUSTOMER_CUSTOMERACCOUNTHISTORY}/get`]),
  systemProp('activeEndpoints')
);

export const isConfirmCheckInStepLoading = compose(
  hasIntersection([`${CHECK_IN_CONFIRM_CHECK_IN}/post`]),
  systemProp('activeEndpoints')
);

export const isWizzDiscountClubLoading = compose(
  includes(`${BOOKING_WIZZ_DISCOUNT_CLUB}/post`),
  systemProp('activeEndpoints')
);

export const isConnectedFlightsLoading = compose(
  includes(`${SEARCH_CONNECTED_FLIGHTS}/post`),
  systemProp('activeEndpoints')
);

export const isNameChangeLoading = compose(
  hasIntersection([
    `${BOOKING_NAME_CHANGE}/get`,
    `${BOOKING_NAME_CHANGE}/post`,
    `${PAYMENT_EMPTY}/post`,
  ]),
  systemProp('activeEndpoints')
);

export const isRouteLoading = systemProp('isRouteLoading');
