import axios from 'axios';
import {
  BOOKING_ANCILLARY_PROMO_DETAILS,
  BOOKING_ITINERARY_PAGE_PROMO_IMAGES,
} from '~/constants/api-endpoint';

export const getAncillaryPromoDetails = () => axios.get(BOOKING_ANCILLARY_PROMO_DETAILS);

export const getItineraryPagePromoImages = () =>
  axios.get(BOOKING_ITINERARY_PAGE_PROMO_IMAGES);
