import curry from '../curry';
import { _map } from '../map';
import prop from '../prop';

/**
 * @typedef {<T, K extends keyof T>(p: K, arr: T[]) => T[K][]} PluckFn
 */

/**
 * @type PluckFn
 */
export const _pluck = (p, arr) => _map(prop(p), arr);

/**
 * Returns a new array by plucking the same named property off all objects in
 * the array supplied.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pluck.js
 *
 * @type PluckFn
 * @example
 *
 *      pluck('a')([{a: 1}, {a: 2}]); //=> [1, 2]
 *      pluck(0)([[1, 2], [3, 4]]);   //=> [1, 3]
 */
const pluck = curry(_pluck);

export default pluck;
