import curry from '../curry';
import { _path } from '../path';

/**
 * @typedef {(p: string, obj: Object<string, any>) => boolean} PathEqTrue
 */

/**
 * @type PathEqTrue
 */
export const _pathEqTrue = (p, obj) => _path(p, obj) === true;

/**
 * Determines whether a nested path on an object has 'false' value. Most likely
 * used to filter a list.
 *
 * @type PathEqTrue
 * @example
 *
 *      let user1 = { address: { hasZipCode: true } };
 *      let user2 = { address: { hasZipCode: false } };
 *      let user3 = { name: 'Bob' };
 *      let users = [ user1, user2, user3 ];
 *      let hasZipCode = pathEqTrue('address.hasZipCode');
 *      filter(hasZipCode, users); //=> [ user1 ]
 */
const pathEqTrue = curry(_pathEqTrue);

export default pathEqTrue;
