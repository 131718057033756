import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => boolean} GteFn
 */

/**
 * @type GteFn
 */
export const _gte = (a, b) => a >= b;

/**
 * Returns `true` if the first argument is greater than or equal to the second;
 * `false` otherwise.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/gte.js
 *
 * @type GteFn
 * @example
 *
 *      gte(2, 1); //=> true
 *      gte(2, 2); //=> true
 *      gte(2, 3); //=> false
 */
const gte = curry(_gte);

export default gte;
