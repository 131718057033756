import {
  SERVICE_FEE_NAME_PRIORITY_BOARDING,
  SERVICE_FEE_NAME_AIRPORT_CHECKIN,
  SERVICE_FEE_LOCATION_AIRPORT,
} from '~/constants';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import path from '~/utils/fp/path';
import compose from '~/utils/fp/compose';
import whereEq from '~/utils/fp/where-eq';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import pluck from '~/utils/fp/pluck';
import { bookingCurrencyCode } from '../core-booking/getters';

const resourcesPropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, `resources.${prop}`, state)
);

const resourcesProp = curry((prop, state) => path(`resources.${prop}`, state));

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const stationsWithFakes = resourcesPropWithFallback([], 'stations');

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const countries = resourcesPropWithFallback([], 'countries');

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const serviceFees = resourcesPropWithFallback({}, 'serviceFees');

export const hasServiceFees = compose(isNotEmpty, serviceFees);

export const stationsWithoutFakes = (state) =>
  stationsWithFakes(state).filter((item) => !item.isFakeStation);

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const prbAtTheAirportFeePrice = (state) => {
  const fees = serviceFees(state).serviceFees?.serviceFeePriceBases ?? [];
  if (isEmpty(fees)) return {};
  const prbFee =
    fees.find(
      (fee) =>
        fee.name.includes(SERVICE_FEE_NAME_PRIORITY_BOARDING) &&
        fee.location.includes(SERVICE_FEE_LOCATION_AIRPORT)
    ) || {};
  if (isEmpty(prbFee)) return {};
  return (
    prbFee.price.find((price) => price.currencyCode === bookingCurrencyCode(state)) || {}
  );
};

export const airportCheckInAtTheAirportFeePrice = (state) => {
  const airportCheckIn = serviceFees(state).bookingFees?.serviceFeePriceBases.filter(
    whereEq({
      name: SERVICE_FEE_NAME_AIRPORT_CHECKIN,
      location: SERVICE_FEE_LOCATION_AIRPORT,
    })
  );

  return airportCheckIn?.[0]?.price?.[0];
};

export const travelDocumentsUrl = resourcesPropWithFallback('#', 'urls.travelDocuments');

export const covidMapConnections = resourcesPropWithFallback([], 'covidMapConnections');

export const covidMapAirports = compose(pluck('airports'), covidMapConnections);

export const disruptionCreditInformationErrors = resourcesPropWithFallback(
  [],
  'disruptionCreditInformation.errors'
);

export const hasAnyDisruptionCreditInformationErrors = compose(
  isNotEmpty,
  disruptionCreditInformationErrors
);

export const disruptionCreditInformation = resourcesProp('disruptionCreditInformation');
