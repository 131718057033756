import getLocale from '~/utils/localization/get-locale';
import { isNotServer } from '~/utils/ssr';
import { getCultures } from '~/utils/global-value/cultures';

const locale = getLocale();

export const getDefaultState = () => ({
  isInitialized: false,
  isEmergencyModeActive: false,
  selectedLanguageCode: locale,
  newsItems: [],
});

export const getLanguageName = (languageCode, state) => {
  return isNotServer()
    ? window.cultures[languageCode] || ''
    : getCultures(state)[languageCode];
};
