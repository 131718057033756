import { CURRENCY_CODE_EUR } from '~/constants';
import isNotObject from '../object/is-not-object';

/**
 * @type {(price: Price|T) => number|T }
 * @template T
 */
export const getPriceAmount = (price) => {
  if (isNotObject(price)) return price;
  return price?.amount ?? price;
};

export const getDefaultPrice = (currencyCode) => getPrice(0, currencyCode);

// note: we don't want to curry getPrice here for now
export const getPrice = (amount, currencyCode) => ({
  amount: amount || 0,
  currencyCode: currencyCode || CURRENCY_CODE_EUR,
});
