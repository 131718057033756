import { BUNDLE_BASIC } from '~/constants';
import toUpperCase from '~/utils/fp/to-upper-case';
import isNotEmpty from '~/utils/object/is-not-empty';
import * as BookingService from '~/services/booking';
import * as ancillariesActions from '~/store/modules/ancillaries/actions';
import * as promotionActions from '~/store/modules/promotion/actions';
import * as summaryGetters from '~/store/modules/summary/getters';
import * as getters from '../getters';
import * as m from '../mutation-types';

const ancillariesWizzFlexOptions = Object.freeze({
  withSummary: true,
  disallowAutoUpdateOfFlightsAndTotal: true,
});

export const getPayableFlight = (selectedFlight) => {
  // todo simple iata should be instead
  const departureStation = selectedFlight.departureStation.iata;
  const arrivalStation = selectedFlight.arrivalStation.iata;
  const {
    id: flightSellKey,
    flightNumber,
    carrierCode,
    departureDateTime,
    arrivalDateTime,
    opSuffix,
  } = selectedFlight;

  return {
    flightSellKey,
    flightNumber,
    carrierCode,
    departureStation,
    arrivalStation,
    departureDateTime,
    arrivalDateTime,
    opSuffix,
  };
};

export const getPayableFare = (selectedFare, selectedPriceDetails, isWdc) => {
  const fareSellKey = selectedPriceDetails.fareSellKey;
  const bundle = selectedFare.bundle.code.toUpperCase();
  const pastPrice = selectedPriceDetails.pastPrice;
  const isFamilyBundle = selectedFare.isFamily;
  const flightPriceDetail = getPayablePriceDetail(selectedPriceDetails);
  const discountType = selectedFare.discountType;
  const flightChangeFeePrice = selectedPriceDetails.flightChangeFeePrice;

  return {
    isWdc,
    fareSellKey,
    bundle,
    pastPrice,
    discountType,
    isFamilyBundle,
    flightPriceDetail,
    flightChangeFeePrice, // for ecommerce.js
  };
};

const getPayablePriceDetail = (selectedPriceDetails) => {
  const {
    ticketPrice: price,
    displayCount,
    discountType,
    discountRate,
    discountPrice,
    totalPrice: total,
    fees,
    promotion,
  } = selectedPriceDetails;

  return {
    price,
    displayCount,
    discountType,
    discountRate,
    discountPrice,
    total,
    fees,
    promotion,
  };
};

export const initWizzFlex = async (store) => {
  const { state } = store;
  // note: here we don't care about these errors they are handled in their own
  //  top level actions, here the user may already try to fix prev errors for
  //  example and we should let they do that
  await Promise.all(
    [
      getters.toggleWizzFlexPromise(state),
      getters.toggleFareLockPromise(state),
      getters.changeFlightPromise(state),
      getters.selectFarePromise(state),
    ].map((p) => p.catch(() => {}))
  );

  return postSelectAndFetchAncillaries(store);
};

/**
 * @type {(store: Store) => void}
 */
export const toggleAndPostWizzFlex = (store) => {
  return ancillariesActions.toggleAndPostWizzFlex(store, ancillariesWizzFlexOptions);
};

/**
 * @type {(store: Store) => void}
 */
export const setAndPostWizzFlex = (store, isSelected = false) => {
  const payload = {
    passengerNumber: 0,
    outboundFlight: { wizzFlex: isSelected },
    ...(getters.isRoundTrip(store.state) && { returnFlight: { wizzFlex: isSelected } }),
  };

  return ancillariesActions.setAndPostWizzFlex(store, payload);
};

/**
 * @type {(store: Store) => void}
 */
export const addAndPostWizzFlex = (store, force = false) => {
  return setAndPostWizzFlex(store, true);
};

/**
 * @type {(store: Store) => void}
 */
export const removeAndPostWizzFlex = (store) => {
  return setAndPostWizzFlex(store, false);
};

export const postSelectAndFetchAncillaries = async (store) => {
  const { commit, state } = store;
  await _postSelect(state);
  commit(m.SET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION);

  let promise = Promise.resolve();
  if (getters.isAncillariesGetNotCalled(state)) {
    promise = ancillariesActions.getAncillaries(store);
    promise.then(commit(m.SET_IS_ANCILLARIES_GET_CALLED));
    await promotionActions.getPromotion(store);
  }

  return promise;
};

export const _postSelect = (state) => {
  const isGroupSeatRequested = getters.isGroupSeatRequested(state);
  return BookingService.select(
    getSelectData({
      isRoundTrip: getters.isRoundTrip(state),
      isGroupSeatRequested,
      isFareLockAdded: summaryGetters.isFareLockAdded(state),
      selectedOutboundFlightId: isGroupSeatRequested
        ? getters.outboundGroupRequestedFlightId(state)
        : getters.selectedOutboundFlightId(state),
      selectedOutboundFareSellKey: getters.selectedOutboundFareSellKey(state),
      selectedOutboundBundleCode: getters.selectedOutboundBundleCode(state),
      selectedReturnFlightId: isGroupSeatRequested
        ? getters.returnGroupRequestedFlightId(state)
        : getters.selectedReturnFlightId(state),
      selectedReturnFareSellKey: getters.selectedReturnFareSellKey(state),
      selectedReturnBundleCode: getters.selectedReturnBundleCode(state),
    })
  );
};

const getSelectData = (params = {}) => {
  const {
    isRoundTrip,
    isGroupSeatRequested,
    isFareLockAdded,
    selectedOutboundFlightId,
    selectedOutboundFareSellKey,
    selectedOutboundBundleCode,
    selectedReturnFlightId,
    selectedReturnFareSellKey,
    selectedReturnBundleCode,
  } = params;

  return {
    isRequest: isGroupSeatRequested,
    ...(isFareLockAdded ? { isFareLock: true } : {}),

    ...(isNotEmpty(selectedOutboundFlightId)
      ? {
          outboundFlight: {
            flightSellKey: selectedOutboundFlightId,
            fareSellKey: selectedOutboundFareSellKey,
            bundle: isGroupSeatRequested
              ? toUpperCase(BUNDLE_BASIC)
              : toUpperCase(selectedOutboundBundleCode),
          },
        }
      : {}),

    ...(isRoundTrip && isNotEmpty(selectedReturnFlightId)
      ? {
          returnFlight: {
            flightSellKey: selectedReturnFlightId,
            fareSellKey: selectedReturnFareSellKey,
            bundle: isGroupSeatRequested
              ? toUpperCase(BUNDLE_BASIC)
              : toUpperCase(selectedReturnBundleCode),
          },
        }
      : {}),
  };
};
