import { OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME } from '~/constants';
import assoc from '~/utils/fp/assoc';
import map from '~/utils/fp/map';
import alter from '~/utils/fp/alter';
import hevolve from '~/utils/object/hevolve';
import hassocPath from '~/utils/object/hassoc-path';
import halter from '~/utils/object/halter';
import { mutationAssocPath } from '~/utils/store';
import isNotUndefined from '~/utils/object/is-not-undefined';
import * as m from './mutation-types';
import { resetFlight, getPaymentModel, resetState } from './internal';

export default {
  [m.RESET_BOOKING]: resetState,

  [m.RESET_OUTBOUND_FLIGHT](state) {
    resetFlight(OUTBOUND_FLIGHT_NAME, state);
  },

  [m.RESET_RETURN_FLIGHT](state) {
    resetFlight(RETURN_FLIGHT_NAME, state);
  },

  [m.SET_IS_PREMIUM_SEAT_LOADING](state, value) {
    state.isPremiumSeatRefundLoading = value;
  },

  [m.SET_PREMIUM_SEAT_REFUND](state, payload) {
    state.premiumSeatRefund = payload;
  },

  [m.SET_PREMIUM_SEAT_REFUND_ERRORS](state, payload) {
    state.premiumSeatRefundErrors = payload;
  },

  /**
   * @type {(state: State) => void}
   */
  [m.SET_WDC_IS_ADDED]: hassocPath('wdc.isAdded', true),

  /**
   * @type {(state: State) => void}
   */
  [m.UNSET_WDC_IS_ADDED]: hassocPath('wdc.isAdded', false),

  /**
   * @type {(state: State, value: Object<string, any>) => void}
   */
  [m.SET_WDC_REQUIRED_MEMBERSHIP]: mutationAssocPath('wdc.requiredMembership'),

  [m.UPDATE_WDC](state, value = {}) {
    halter({ wdc: value }, state);
  },

  [m.SET_IS_LOGGED_IN_AFTER_SELECT_FLAG](state, value) {
    state.isLoggedInAfterSelect = value;
  },

  [m.SET_WDC_AVAILABLE_MEMBERSHIPS](state, value) {
    hassocPath('wdc.availableMemberships', value || [], state);
  },

  [m.SET_IS_WDC_UPGRADED_FROM_SIDEBAR](state, value) {
    hassocPath('wdc.isUpgradedFromSidebar', value || false, state);
  },

  [m.SET_NEWSLETTER_SUBSCRIPTION](state, value) {
    hassocPath('preferences.newsletterSubscription', value || false, state);
  },

  [m.SET_NEWSLETTER_SUBSCRIPTION_LOCATION](state, value) {
    hassocPath('newsletterSubscriptionLocation', value || '', state);
  },

  [m.SET_NEW_BOOKING](state, isNewBooking) {
    state.isNewBooking = isNewBooking;
  },

  [m.SET_KAYAK_CLICK_ID](state, clickId) {
    state.kayakClickId = clickId;
  },

  [m.SET_WEGO_CLICK_ID](state, clickId) {
    state.wegoClickId = clickId;
  },

  [m.CHANGE_BANK_TRANSFER_ACCOUNT](state, bankTransferAccount) {
    state.payment.bankTransferAccount = bankTransferAccount;
  },

  [m.SET_SAME_FOR_BOTH_FLIGHTS_CHECKED](state, params) {
    const { areSameSettingsCheckedForReturnFlight, passengerNumber } = params;

    if (areSameSettingsCheckedForReturnFlight) {
      state.passengersWithDisabledSameCheckbox =
        state.passengersWithDisabledSameCheckbox.filter(
          (pNumber) => pNumber !== passengerNumber
        );
    } else {
      const isAlreadyDisabled =
        state.passengersWithDisabledSameCheckbox.includes(passengerNumber);

      if (!isAlreadyDisabled) {
        state.passengersWithDisabledSameCheckbox.push(passengerNumber);
      }
    }
  },

  [m.RESET_FLIGHT_CARRIERS_CONDITIONS_ACCEPTED]: hevolve({
    carrierCodes: map(assoc('accepted', false)),
  }),

  [m.SET_AIRPORT_TRANSFER_MODAL_VISIBILITY]: (state, isVisible) => {
    state.isAirportTransferModalVisible = isVisible;
  },

  [m.SET_AIRPORT_TRANSFER_MODAL_DIRECTION]: (state, direction) => {
    state.isAirportTransferModalDirection = direction;
  },

  [m.MARK_WDC_RENEWAL_REQUEST](state, shouldSendWdcRenewalRequest) {
    state.wdc.shouldSendWdcRenewalRequest = shouldSendWdcRenewalRequest;
  },

  [m.SET_CARRIER_CODES](state, carrierCodes) {
    state.carrierCodes = carrierCodes;
  },

  [m.SET_ACORD_SERVICE_INSURANCE_ACCEPTED](state, value) {
    state.acordService.isInsurancePolicyAccepted = value;
  },

  [m.SET_ACORD_SERVICE_INSURANCE_NONE_SELECTED](state, value) {
    state.acordService.isInsuranceNoneSelected = value;
  },

  [m.SET_ACORD_SERVICE_INSURANCE_FORCED](state, value) {
    state.acordService.isInsuranceForced = value;
  },

  [m.SET_HAS_SELECTED_CAR](state) {
    state.hasSelectedCar = true;
  },

  [m.UNSET_HAS_SELECTED_CAR](state) {
    state.hasSelectedCar = false;
  },

  [m.HANDLE_PAYMENT_OPTIONS](state, data) {
    state.payment.options = data;
  },
  [m.HANDLE_PAYMENT_SELECTED_OPTION](state, option) {
    state.payment.selected = option;
  },
  [m.HANDLE_PAYMENT_ADD_STORED_CREDIT_CARD](state, card) {
    state.payment.storedCreditCard = card;
  },
  [m.HANDLE_PAYMENT_ADD_RESIDUAL_AMOUNT](state, amount) {
    state.payment.residualAmount = amount;
  },
  [m.HANDLE_PAYMENT_ADD_RESIDUAL_AMOUNT](state, amount) {
    state.payment.residualAmount = amount;
  },
  [m.HANDLE_PAYMENT_DISABLE_PARTIAL_PAYMENT](state, value) {
    state.payment.groupPartialPayment.enabled = value;
  },
  [m.SET_PAYMENT_IS_CORPORATE_CARD_FEE_ACCEPTED](state, value) {
    state.payment.corporateCardFeeInfo.isCorporateCardFeeAccepted = value;
  },
  [m.SET_PAYMENT_CORPORATE_CARD_FEE_PERCENT](state, percent) {
    state.payment.corporateCardFeeInfo.corporateCardFeePercent = percent;
  },
  [m.SET_PAYMENT_CORPORATE_CARD_FEE_INFO](state, info) {
    if (isNotUndefined(info.isCorporateCardFeeAccepted))
      state.payment.corporateCardFeeInfo.isCorporateCardFeeAccepted =
        info.isCorporateCardFeeAccepted;

    state.payment.corporateCardFeeInfo.corporateCardFeePercent =
      info.corporateCardFeePercent;
    state.payment.corporateCardFeeInfo.originalAmount = info.originalAmount;
    state.payment.corporateCardFeeInfo.corporateCardFeeAmount =
      info.corporateCardFeeAmount;
    state.payment.corporateCardFeeInfo.newTotalAmount = info.newTotalAmount;
  },
  [m.SET_CVV]: mutationAssocPath('payment.cvv'),
  [m.HANDLE_PAYMENT_REMOVE_CONFIDENTIAL_CARD_DATA](state) {
    if (state.payment.creditCard) {
      state.payment.creditCard.cvv = null;
    }

    if (state.payment.storedCreditCard) {
      state.payment.storedCreditCard.cvv = null;
    }
  },

  [m.GET_CONTACT_DETAILS](state, data) {
    state.contact = alter(data, state.contact);
  },

  [m.SET_ALREADY_PAID_STATUS](state, value) {
    state.payment.isPaid = value;
  },

  [m.HANDLE_PAYMENT_REMOVE_CREDIT_CARD_DATA](state) {
    if (state.payment.creditCard) {
      const paymentModel = getPaymentModel();
      state.payment.creditCard = paymentModel.creditCard;
    }
  },

  [m.SET_RESTART_BOOKING_MODAL_VISIBILITY]: (state, value) => {
    state.isRestartBookingModalVisible = value;
  },

  [m.SET_FARE_LOCK_FINALIZE_DATA](state, value) {
    hassocPath('fareLockFinalizeData', value, state);
  },

  [m.SET_BUNDLE_UPSELL_ORIGINAL_COMMON_BUNDLE](state, value) {
    hassocPath('bundleUpsellOriginalCommonBundle', value, state);
  },

  [m.SET_FLIGHT](state, payload = {}) {
    const { direction, flight } = payload;
    state.flights[direction] = flight;
  },

  [m.SET_FARES](state, payload = {}) {
    const { direction, fare } = payload;
    state.fares[direction] = fare;
  },

  [m.SET_GROUP_SEAT_REQUEST](state, payload = {}) {
    const { direction, value } = payload;
    state[`${direction}GroupSeatRequest`] = value;
  },

  [m.SET_IS_DOMESTIC](state, value) {
    state.isDomestic = value;
  },

  [m.SET_IS_WDC_PREMIUM_ENABLED](state, value) {
    state.isWdcPremiumEnabled = value;
  },

  [m.SET_PASSENGER_NUMBERS_AND_COUNTS](state, payload = {}) {
    const {
      numberOfIndependentPassengers,
      numberOfAdultPassengers,
      numberOfChildPassengers,
      numberOfInfantPassengers,
    } = payload;

    state.numberOfIndependentPassengers = numberOfIndependentPassengers;
    state.numberOfInfants = numberOfInfantPassengers;
    state.adultCount = numberOfAdultPassengers;
    state.childCount = numberOfChildPassengers;
    state.infantCount = numberOfInfantPassengers;
  },

  [m.SET_INDEPENDENT_PASSENGERS](state, numberOfIndependentPassengers) {
    state.numberOfIndependentPassengers = numberOfIndependentPassengers;
  },

  [m.SET_IS_WDC_STEP_AVAILABLE](state) {
    state.isWdcStepAvailable = true;
  },

  [m.UNSET_IS_WDC_STEP_AVAILABLE](state) {
    state.isWdcStepAvailable = false;
  },

  [m.TOGGLE_CANCELLATION_INSURANCE](state) {
    state.isCancellationInsuranceSelected = !state.isCancellationInsuranceSelected;
  },

  [m.SET_CANCELLATION_INSURANCE](state, value) {
    state.isCancellationInsuranceSelected = value;
  },

  [m.TOGGLE_TRAVEL_INSURANCE](state) {
    state.isTravelInsuranceSelected = !state.isTravelInsuranceSelected;
  },

  [m.SET_TRAVEL_INSURANCE](state, value) {
    state.isTravelInsuranceSelected = value;
  },

  [m.TOGGLE_BAGGAGE_INSURANCE](state) {
    state.isBaggageInsuranceSelected = !state.isBaggageInsuranceSelected;
  },

  [m.SET_BAGGAGE_INSURANCE](state, value) {
    state.isBaggageInsuranceSelected = value;
  },

  [m.SHOW_URGENCY_MODAL]: hassocPath('isUrgencyModalVisible', true),
  [m.HIDE_URGENCY_MODAL]: hassocPath('isUrgencyModalVisible', false),
  [m.SET_URGENCY_MODAL_ALREADY_SHOWED]: hassocPath('isUrgencyModalShowed', true),

  [m.SET_WDC_SAVING_MODAL_VISIBILITY](state, isVisible) {
    state.isWdcSavingModalVisible = isVisible;
  },

  [m.SET_ACORD_SERVICE_INFO_MODAL_TRACKED](state, eventLocation) {
    state.isAcordServiceInfoModalTracked[eventLocation] = true;
  },

  [m.SET_BILLING_VALID](state, value) {
    state.contact.isBillingValid = value;
  },

  [m.SET_BOOKING_FLOW_STEP](state, step) {
    state.step = step;
  },

  [m.SET_WDC_STEP_NECESSARY](state, value) {
    state.isWdcStepNecessary = value;
  },

  [m.SET_WIZZ_FLEX_MODAL_VISIBILITY](state, value) {
    state.isWizzFlexModalVisible = value;
  },

  [m.SHOW_SINGLE_SERVICE_MODAL]: hassocPath('isSingleServiceModalVisible', true),

  [m.HIDE_SINGLE_SERVICE_MODAL]: hassocPath('isSingleServiceModalVisible', false),

  [m.SET_PREVIOUS_BOOKING_DETAILS](state, value) {
    state.flightChangedFrom = value;
  },

  [m.RESET_CAPTCHA_RESPONSE]: hassocPath('captchaResponse', ''),

  [m.SET_IS_PAYMENT_EXIT_INTENT_MODAL_SEEN]: hassocPath(
    'isPaymentExitIntentModalSeen',
    true
  ),

  [m.SET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE]: hassocPath(
    'isFlightSearchInSidebarVisible',
    true
  ),
  [m.UNSET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE]: hassocPath(
    'isFlightSearchInSidebarVisible',
    false
  ),

  [m.SET_IS_FLIGHT_SEARCH_MODAL_VISIBLE]: hassocPath('isFlightSearchModalVisible', true),
  [m.UNSET_IS_FLIGHT_SEARCH_MODAL_VISIBLE]: hassocPath(
    'isFlightSearchModalVisible',
    false
  ),

  [m.SET_DESTINATION_BASED_NOTIFICATION_ACCEPTED](state, value) {
    state.payment.isDestinationBasedNotificationAccepted = value;
  },

  [m.SET_PAYMENT_CURRENCY](state, value) {
    state.payment.currency = value;
  },

  [m.SET_GROUP_PARTIAL_PAYMENT_VALUES](state, payload) {
    const { key, value } = payload;
    state.payment.groupPartialPayment[key] = value;
  },

  [m.SET_SELECTED_IDEAL_ISSUER](state, value) {
    state.payment.selectedIdealIssuer = value;
  },

  [m.SET_IS_REGISTERED_FOR_NEWSLETTER](state, value) {
    state.contact.isRegisteredForNewsletter = value;
  },

  [m.SET_IS_CAPTCHA_REQUIRED](state, value) {
    state.contact.isCaptchaRequired = value;
  },

  [m.SET_CHANGE_FLIGHT_DISCLAIMER_TYPE](state, value) {
    state.changeFlightDisclaimerType = value;
  },

  [m.SET_PASSENGERS_STEP_PENDING](state, value) {
    state.volatile.isPassengerStepPending = value;
  },

  [m.SET_SELECTED_FLIGHT_PENDING](state, value) {
    state.volatile.isSelectedFlightPending = value;
  },

  [m.SET_PAYMENT_STEP_PENDING](state, value) {
    state.volatile.isPaymentStepPending = value;
  },

  [m.SET_FLIGHT_CARRIER_ACCEPTED](state, { carrierCode, accepted }) {
    state.carrierCodes.forEach((carrier) => {
      if (carrier.carrierCode === carrierCode) carrier.accepted = accepted;
    });
  },

  [m.SET_CURRENCY_CHANGE_WARNING_DISMISSED](state, value) {
    state.isCurrencyChangeWarningDismissed = value;
  },

  [m.SET_CHECK_IN_PARAMS](state, payload) {
    state.hoursUntilCheckInAvailable = payload;
  },

  [m.SET_CHECK_IN_PARAMS_ERRORS](state, payload) {
    state.checkInParamsErrors = payload;
  },

  [m.SET_OTA_FEE_WARNING_DISMISSED](state, payload) {
    state.isOtaFeeWarningDismissed = payload;
  },

  [m.SET_CARD_IN_GOOGLE_PAY_AVAILABLE](state, value) {
    state.payment.isCardInGooglePayAvailable = value;
  },
  [m.SET_GOOGLE_PAY_LOADING](state, value) {
    state.payment.isGooglePayLoading = value;
  },
  [m.SET_GOOGLE_PAY_READY](state, value) {
    state.payment.isGooglePayReady = value;
  },
};
