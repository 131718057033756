export const ANALYTICS_FLOW_TYPE_ADD_SERVICES = 'Add Service';
export const ANALYTICS_FLOW_TYPE_CHANGE_FLIGHT = 'Change flight';
export const ANALYTICS_FLOW_TYPE_CHANGE_NAME = 'Change name';
export const ANALYTICS_FLOW_TYPE_NEW_BOOKING = 'New booking';
export const ANALYTICS_FLOW_TYPE_FARE_LOCK_FINALIZE = 'Fare lock finalize';
export const ANALYTICS_FLOW_TYPE_CHECKIN = 'Checkin';
export const ANALYTICS_FLOW_TYPE_CONNECTED_BOOKING = 'Connected booking';
export const BUYER_TYPE_CUSTOMER = 'Customer';
export const BUYER_TYPE_AGENCY = 'Agency';
export const DIMENSION_FLOW_TYPE_NEW_BOOKING = 'New booking';
export const TRAVEL_TYPE_NORMAL = 'Normal';
export const TRAVEL_TYPE_GROUP = 'Group';
export const TRIP_TYPE_ONE_WAY = 'One-way';
export const TRIP_TYPE_RETURN = 'Return';

export const PRODUCT_CUSTOM_DIMENSIONS = {
  dimension1: 'buyerType',
  dimension2: 'travelType',
  dimension3: 'tripType',
  dimension4: 'passengerType',
  dimension5: 'airportCodes',
  dimension6: 'dateOfTravel',
  dimension7: 'flowType',
  dimension8: 'flightNumber',
  dimension9: 'daysToGo',
  dimension50: 'numberOfPassengers',
  dimension75: 'purchaseLocation',
};

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
 */
export const ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART = 'add_to_cart';
export const ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART = 'remove_from_cart';
export const ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM = 'view_item';
export const ENHANCED_ECOMMERCE_EVENT_TYPE_PURCHASE = 'purchase';
export const ENHANCED_ECOMMERCE_EVENT_TYPE_SELECT_CONTENT = 'select_content';
export const ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_PROMOTION = 'view_promotion';

export const ENHANCED_ECOMMERCE_EVENT_TRACKING_FLAG_MAP = {
  [ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM]: 2,
  [ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART]: 4,
  [ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART]: 8,
};
