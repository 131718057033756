import { CHECK_IN_STEP_FIRST_NAME_CONFIRM_PATH } from '~/router/check-in/constants';

export const CHECK_IN_STEP_ERROR = 'check-in-error';
export const CHECK_IN_STEP_WARNING = 'check-in-warning';
export const CHECK_IN_STEP_PASSENGERS = 'check-in-passengers';
export const CHECK_IN_STEP_CHANGES_TO_ACCEPT = 'check-in-changes-to-accept';
export const CHECK_IN_STEP_BAGGAGE = 'check-in-baggage';
export const CHECK_IN_STEP_SEAT_SELECTION = 'check-in-seat-selection';
export const CHECK_IN_STEP_SERVICES = 'check-in-services';
export const CHECK_IN_STEP_PAYMENT = 'check-in-payment';
export const CHECK_IN_STEP_PAYMENT_FAILED = 'check-in-failed';
export const CHECK_IN_STEP_PAYMENT_SUCCESS = 'check-in-success';
export const CHECK_IN_STEP_TRAVEL_DOCUMENTS = 'check-in-travel-documents';
export const CHECK_IN_STEP_CONTACT_DATA = 'check-in-contact-data';
export const CHECK_IN_STEP_CONFIRM_DATA = 'check-in-confirm-data';
export const CHECK_IN_STEP_PROHIBITED_ITEMS = 'check-in-prohibited-items';
export const CHECK_IN_STEP_ALL_DONE = 'check-in-all-done';

export const ERROR_STEP = {
  step: 'error',
  order: 0,
};

export const CONFIRM_FIRST_NAME_STEP = {
  // note: it's okay to use an empty string, the whole prop purpose is just to keep the prop validators' mouth shut.
  name: '',
  step: CHECK_IN_STEP_FIRST_NAME_CONFIRM_PATH,
  order: 0,
};
