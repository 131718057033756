import curry from '../curry';

/**
 * @typedef {<T>(fn: (item: T) => T, index: number, arr: T[]) => T[]} AdjustFn
 */

/**
 * @type AdjustFn
 */
export const _adjust = (fn, index, arr) => {
  if (index < 0 || index >= arr.length) return arr;

  const result = [...arr];
  result[index] = fn(result[index]);
  return result;
};

/**
 * Applies a function to the value at the given index of an array, returning a
 * new copy of the array with the element at the given index replaced with the
 * result of the function application.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/adjust.js
 *
 * @type AdjustFn
 * @example
 *
 *      adjust(add(10), 1, [1, 2, 3]);     //=> [1, 12, 3]
 *      adjust(add(10))(1)([1, 2, 3]);     //=> [1, 12, 3]
 */
const adjust = curry(_adjust);

export default adjust;
