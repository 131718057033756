import {
  STEP_SERVICES,
  STEP_PASSENGERS,
  STEP_SELECT_FLIGHT,
  STEP_SEAT_SELECTION,
} from '~/constants';

export const getDefaultState = () => ({
  exchangeRate: null,
  map: [],
  wizzFlexPurchaseLocation: '',
  wdcMembershipPurchaseLocation: '',
  priorityBoardingPurchaseLocation: STEP_PASSENGERS,
  smsPurchaseLocation: STEP_SERVICES,
  airportCheckInPurchaseLocation: STEP_SERVICES,
  insurancePurchaseLocation: STEP_SERVICES,
  hasSavingOnWdc: '',
  autoCheckInPurchaseLocation: '',
  outboundFarePurchaseLocation: STEP_SELECT_FLIGHT,
  returnFarePurchaseLocation: STEP_SELECT_FLIGHT,
  seatPurchaseLocation: STEP_SEAT_SELECTION,
  sittingTogetherPurchaseLocation: '',
  selectedPaymentOption: null,
});
