import isString from '../is-string';

/**
 * Checks if `value` is not string.
 *
 * @type {(value: any) => boolean}
 */
const isNotString = (value) => !isString(value);

export default isNotString;
