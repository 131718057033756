import log from 'loglevel';
import { to } from 'await-to-js';
import {
  WDC_MEMBERSHIP_NAME_MAP,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  WDC_RETARGETING_POPUP,
  ANCILLARY_WDC_MEMBERSHIP,
  ANCILLARY_WDDC_MEMBERSHIP,
  FEE_NAME_ADMINISTRATION,
  FEE_NAME_GROUP_ADMINISTRATION,
  FEE_NAME_FLIGHT_CHANGE,
  FEE_NAME_DISTRIBUTION,
  FEE_NAME_INFANT,
  FEE_CODES,
  WDC_MEMBERSHIP_NAME_MAP_REDESIGNED,
} from '~/constants';
import pick from '~/utils/fp/pick';
import curry from '~/utils/fp/curry';
import equalsAll from '~/utils/fp/equals-all';
import isNotNil from '~/utils/object/is-not-nil';
import isEmpty from '~/utils/object/is-empty';
import isNil from '~/utils/object/is-nil';
import { emit } from '~/utils/event-bus';
import { createAction } from '~/utils/store';
import * as EcommerceService from '~/services/ecommerce';
import * as AssetService from '~/services/asset';
import * as BookingService from '~/services/booking';
import { isDomesticWdc, ecommerceVariant } from '~/utils/booking/wdc';
import { ANALYTICS_CURRENCY_CODE, CATEGORY_ANCILLARY } from '~/constants/analytics';
import * as coreBookingGetters from '../core-booking/getters';
import * as coreBookingActions from '../core-booking/actions';
import * as itineraryGetters from '../itinerary/getters';
import * as resourcesGetters from '../resources/getters';
import * as analyticsGetters from '../analytics/getters';
import * as flightSelectGetters from '../flight-select/getters';
import * as getters from './getters';
import * as m from './mutation-types';
import { groupedServicesFrom, passengersFrom, seatsFrom } from './internal';
import {
  changedServicesFrom,
  changedAncillariesFrom,
  changedSeatsFrom,
  mapServiceToEcommerceItem,
  mapAncillaryToEcommerceItem,
  mapSeatToEcommerceItem,
  trackCartAction,
  mapFareToEcommerceItem,
} from './internal/ecommerce';

export const reset = createAction(m.RESET);

/**
 * @type {(store: {}, value: boolean) => void}
 */
export const setIsWizzFlexSelectedAtFlightSelectFlag = createAction(
  m.SET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT_FLAG
);

/**
 * @type {(store: {}) => void}
 */
export const unsetIsWizzFlexSelectedAtFlightSelect = createAction(
  m.UNSET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT
);

/**
 * @type {(store: {}) => void}
 */
export const backupIsWizzFlexSelectedAtFlightSelect = createAction(
  m.BACKUP_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT
);

/**
 * @type {(store: {}) => void}
 */
export const restoreIsWizzFlexSelectedAtFlightSelect = createAction(
  m.RESTORE_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT
);

/**
 * @type {(store: {}, payload: {}) => void}
 */
export const addPayableWdcMembership = (store, payload) => {
  const { commit, state } = store;
  const { membership } = payload;

  commit(m.UPDATE_FLIGHTS_WDC_MEMBERSHIP, {
    bookingCurrencyCode: bookingCurrencyCode(state),
    payableItem: payload,
  });
  exchangeAmountDues(store);

  const type = isDomesticWdc(membership)
    ? ANCILLARY_WDDC_MEMBERSHIP
    : ANCILLARY_WDC_MEMBERSHIP;

  const isWdcPremiumEnabled = flightSelectGetters.isWdcPremiumEnabled(state);
  const isStandardWdcUser = flightSelectGetters.hasApplicableStandardWdcMembership(state);
  const location = analyticsGetters.wdcMembershipPurchaseLocation(state);
  const hasSavingOnWdc = analyticsGetters.wdcDiscountSaving(state);
  const metadata = analyticsGetters.metadata(state);

  const variant = ecommerceVariant(isWdcPremiumEnabled, isStandardWdcUser, membership);

  const payableItem = {
    type,
    category: CATEGORY_ANCILLARY,
    metadata,
    data: {
      ...payload,
      analytics: {
        variant,
        location,
        hasSavingOnWdc,
      },
    },
  };

  EcommerceService.sendEcommerceAddToCartEvent(payableItem);
};

/**
 * @type {(store: {}) => void}
 */
export const removePayableWdcMembership = (store) => {
  const { commit, state } = store;
  const payableWdcMembership = getters.payableWdcMembership(state);
  commit(m.CLEAR_FLIGHTS_WDC_MEMBERSHIP, bookingCurrencyCode(state));
  exchangeAmountDues(store);
  const location = analyticsGetters.wdcMembershipPurchaseLocation(state);
  const hasSavingOnWdc = analyticsGetters.wdcDiscountSaving(state);

  if (isEmpty(payableWdcMembership)) return;
  const { membership: ecommerceVariant } = payableWdcMembership;
  const type = isDomesticWdc(ecommerceVariant)
    ? ANCILLARY_WDDC_MEMBERSHIP
    : ANCILLARY_WDC_MEMBERSHIP;

  const variant = flightSelectGetters.isWdcPremiumEnabled
    ? WDC_MEMBERSHIP_NAME_MAP_REDESIGNED.get(ecommerceVariant)
    : WDC_MEMBERSHIP_NAME_MAP.get(ecommerceVariant);

  const payableItem = {
    type,
    category: CATEGORY_ANCILLARY,
    metadata: analyticsGetters.metadata(state),
    data: {
      ...payableWdcMembership,
      analytics: {
        variant,
        quantity: 1,
        location,
        hasSavingOnWdc,
      },
    },
  };

  EcommerceService.sendEcommerceRemoveFromCartEvent(payableItem);
};

/**
 * @type {(store: {}, payload: {}) => void}
 */
export const addPayableWdcRenewal = (store, payload) => {
  const { commit, state } = store;
  commit(m.UPDATE_FLIGHTS_WDC_RENEWAL, {
    bookingCurrencyCode: bookingCurrencyCode(state),
    payableItem: payload,
  });
  exchangeAmountDues(store);
  const ecommerceVariant = WDC_MEMBERSHIP_NAME_MAP.get(payload.membership);

  const payableItem = {
    type: 'wdcRenewal',
    category: CATEGORY_ANCILLARY,
    metadata: analyticsGetters.metadata(state),
    data: {
      ...payload,
      analytics: {
        quantity: 1,
        location: WDC_RETARGETING_POPUP,
        variant: `${ecommerceVariant} (Renewal)`,
      },
    },
  };

  EcommerceService.sendEcommerceAddToCartEvent(payableItem);
};

/**
 * @type {(store: {}, payload: {}) => void}
 */
export const addPayableFareLock = (store, payload) => {
  const { commit, state } = store;
  const { price } = payload;
  commit(m.UPDATE_FLIGHTS_FARE_LOCK, {
    bookingCurrencyCode: bookingCurrencyCode(state),
    price,
  });
  exchangeAmountDues(store);
  ecommerceAddFareLockToCart(payload);
};

/**
 * @type {(store: {}, payload: {}) => void}
 */
export const removePayableFareLock = (store, payload) => {
  const { commit, state } = store;
  commit(m.CLEAR_FLIGHTS_FARE_LOCK, bookingCurrencyCode(state));
  exchangeAmountDues(store);
  ecommerceRemoveFareLockFromCart(payload);
};

/**
 * @type {(operation: string, payload: {}) => void}
 */
const ecommerceUpdateFareLockInCart = curry((operation, payload) => {
  const item = getFareLockCartItem(payload);
  EcommerceService[operation](item);
});

/**
 * @type {(payload: {}) => void}
 */
const ecommerceAddFareLockToCart = ecommerceUpdateFareLockInCart(
  'sendEcommerceAddToCartEvent'
);

/**
 * @type {(payload: {}) => void}
 */
const ecommerceRemoveFareLockFromCart = ecommerceUpdateFareLockInCart(
  'sendEcommerceRemoveFromCartEvent'
);

const getFareLockCartItem = ({ feeCode, price, metadata } = {}) => ({
  type: 'fareLock',
  metadata,
  data: {
    code: feeCode,
    price,
    name: 'fareLock',
    count: 1,
    flight: DIRECTION_OUTBOUND,
  },
});

/**
 * @type {(store: {}) => void}
 */
export const resetSummary = createAction(m.RESET_SUMMARY);

/**
 * @type {(store: Store) => void}
 */
export const resetTotalAmount = createAction(m.RESET_TOTAL_AMOUNT);

/**
 * @type {(store: Store) => void}
 */
export const fetchPastPurchases = async (store) => {
  const pastPurchases = await BookingService.getPriceDetails(
    resourcesGetters.stationsWithFakes(store.state)
  );
  updatePastPurchases(store, pastPurchases);
};

/**
 * @type {(store: {}, pastPurchases: {}) => void}
 */
export const updatePastPurchases = createAction(m.UPDATE_PAST_PURCHASES);

export const changePaymentCurrency = async (store, payload = {}) => {
  const { currencyCode, source, isSilent = true } = payload;

  const prevCurrencyCode = coreBookingGetters.paymentCurrencyCode(store.state);
  if (currencyCode === prevCurrencyCode) return;

  coreBookingActions.setPaymentCurrency(store, currencyCode);

  const [error] = await to(exchangeAmountDues(store));
  if (error) {
    coreBookingActions.setPaymentCurrency(store, prevCurrencyCode);
    if (isSilent) return;
    throw error;
  }

  store.commit(m.SET_MCP_SELECTION_SOURCE, source);
  emit('summary_paymentCurrencyChanged');
};

export const exchangeAmountDues = async (store) => {
  const { state } = store;
  const bookingCurrencyCode = coreBookingGetters.bookingCurrencyCode(state);
  const paymentCurrencyCode = coreBookingGetters.paymentCurrencyCode(state);
  const itineraryBookingCurrencyCode = itineraryGetters.bookingCurrencyCode(state);
  const validCurrencies = [
    paymentCurrencyCode,
    bookingCurrencyCode,
    itineraryBookingCurrencyCode,
  ].filter((currency) => isNotNil(currency));

  // we need to handle when payment currency changes TO booking currency
  if (equalsAll(...validCurrencies)) return;

  const [exchangedAmountDue, exchangedAmountDueWithoutThirdPartyServices] =
    await Promise.all([
      exchange(store, { currencyCode: paymentCurrencyCode, getter: getters.amountDue }),
      exchange(store, {
        currencyCode: paymentCurrencyCode,
        getter: getters.amountDueWithoutThirdPartyServices,
      }),
    ]);

  store.commit(m.SET_EXCHANGED_AMOUNT_DUE, exchangedAmountDue);
  store.commit(
    m.SET_EXCHANGED_AMOUNT_DUE_WITHOUT_THIRD_PARTY_SERVICES,
    exchangedAmountDueWithoutThirdPartyServices
  );
};

const exchange = async ({ commit, state }, { currencyCode, getter }) => {
  const { amount } = getter(state);

  commit(m.INCREMENT_LOADING_COUNTER);
  const [error, response] = await to(AssetService.exchange(amount, currencyCode));
  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) {
    log.error(`Unable to exchange: ${error}`);
    throw error;
  }

  return pick(['amount', 'currencyCode'], response.data.totalAmount);
};

// NOTE we still do this the old way and patch the summary manually :(
//  (BE `select` calls + ancillaries GET idea was pricey (select part))
export const addPayableFlightAndFare = async (store, payload = {}) => {
  const { commit, state } = store;

  const {
    type = '',
    flight = {},
    fare = {},
    fees = {},
    metadata,
    purchaseLocation,
    isGroupSeatRequest = false,
    isEcommerceTrackingDisabled = false,
  } = payload;

  const {
    flightSellKey = '',
    flightNumber = '',
    carrierCode = '',
    departureStation = '',
    arrivalStation = '',
    departureDateTime = '',
    arrivalDateTime = '',
    opSuffix = '',
  } = flight;

  const newFlight = {
    flightNumber,
    carrierCode,
    departureStation,
    arrivalStation,
    departureDateTime,
    arrivalDateTime,
    opSuffix,
  };

  const {
    bundle = '',
    isFamilyBundle = false,
    flightPriceDetail: priceDetail = {},
  } = fare;

  commit(m.UPDATE_FLIGHTS_FLIGHT, {
    type,
    isGroupSeatRequest: isGroupSeatRequest || false,
    bookingCurrencyCode: bookingCurrencyCode(state),
    flight: newFlight,
    fare: { bundle, isFamilyBundle, priceDetail },
    wdcMembershipFee: getters.wdcMembershipFee(state),
  });

  exchangeAmountDues(store);

  const prices = [
    FEE_NAME_ADMINISTRATION,
    FEE_NAME_GROUP_ADMINISTRATION,
    FEE_NAME_INFANT,
    FEE_NAME_DISTRIBUTION,
    FEE_NAME_FLIGHT_CHANGE,
  ];
  const newFees = Object.values(fees)
    .filter((fee) => prices.includes(fee.specificType) && isNotNil(fee.price.amount))
    .reduce((acc, fee) => {
      const {
        promotedPrice,
        price,
        displayCount: count,
        specificType,
        code,
        coupon,
      } = fee;
      acc.push({
        specificType,
        price: promotedPrice || price,
        count,
        code,
        bundle,
        coupon,
      });
      return acc;
    }, []);

  const newFare = {
    ...fare,
    id: `${flight.flightNumber}-${fare.bundle}`,
    bundle: { code: fare.bundle },
    direction: type,
    flightSellKey,
    fees: newFees,
  };

  const numberOfInfants = flightSelectGetters.numberOfInfantPassengers(state);
  infantFeeMeasureDetailsView(numberOfInfants, newFare.direction, metadata);

  let pastPrice;
  if (newFare?.pastPrice?.amount) {
    const [, response] = await to(
      AssetService.exchange(newFare.pastPrice.amount, ANALYTICS_CURRENCY_CODE)
    );
    pastPrice = response?.data?.totalAmount?.amount;
  }

  const ecommerceItem = mapFareToEcommerceItem(
    metadata,
    isEcommerceTrackingDisabled,
    newFare,
    purchaseLocation,
    pastPrice
  );

  commit(m.SET_PAYABLE_FLIGHT_AND_FARE, {
    type,
    ecommerceItem,
  });

  EcommerceService.sendEcommerceAddToCartEvent(ecommerceItem);
};

const infantFeeMeasureDetailsView = (infants, flight, metadata) => {
  [...new Array(infants).keys()].forEach(() => {
    EcommerceService.measureProductDetails({
      type: 'fee',
      metadata,
      data: {
        name: FEE_NAME_INFANT,
        code: FEE_CODES[FEE_NAME_INFANT],
        flight,
        analytics: {
          quantity: 1,
        },
      },
    });
  });
};

export const clearPayableFlightFare = (store, payload = {}) => {
  const { commit, state } = store;
  const { type = '', isExchangePrevented = false } = payload;
  commit(m.CLEAR_FLIGHTS_FLIGHT, {
    flightName: type,
    bookingCurrencyCode: bookingCurrencyCode(state),
  });

  if (!isExchangePrevented) {
    exchangeAmountDues(store);
  }

  const savedPayableItem = getters[`${type}PayableItem`](state);
  if (isEmpty(type) || isNil(savedPayableItem)) return;
  EcommerceService.sendEcommerceRemoveFromCartEvent(savedPayableItem);
};

export const resetPayableFlightAndFares = (store) => {
  clearPayableFlightFare(store, { type: DIRECTION_OUTBOUND, isExchangePrevented: true });
  clearPayableFlightFare(store, { type: DIRECTION_RETURN });
};

/**
 * @type {(state: {}) => string}
 */
const bookingCurrencyCode = coreBookingGetters.bookingCurrencyCode;

/**
 * @type {(store: {}, payload: {}) => void}
 */
export const update = (store, payload = {}) => {
  const { commit, state } = store;
  const {
    services,
    passengers,
    seats,
    bookingCurrencyCode,
    disallowAutoUpdateOfFlightsAndTotal = false,
  } = payload;
  const metadata = analyticsGetters.metadata(state);

  // Track ancillaries
  const currentAncillariesByPassenger = getters.passengersAncillaries(state);
  const newAncillariesByPassenger = passengersFrom(
    bookingCurrencyCode,
    passengers
  ).ancillaries;

  changedAncillariesFrom(currentAncillariesByPassenger, newAncillariesByPassenger)
    .map(mapAncillaryToEcommerceItem(metadata))
    .forEach(trackCartAction);

  // Track seats
  const currentSeatsByPassenger = getters.seatsPassengers(state);
  const newSeatsByPassenger = seatsFrom(bookingCurrencyCode, seats).passengers;

  changedSeatsFrom(currentSeatsByPassenger, newSeatsByPassenger)
    .map(mapSeatToEcommerceItem(metadata))
    .forEach(trackCartAction);

  // Track services
  const currentServices = getters.groupedServices(state);
  const stations = resourcesGetters.stationsWithFakes(state);
  const newServices = groupedServicesFrom(
    stations,
    bookingCurrencyCode,
    services
  ).services;

  changedServicesFrom(currentServices, newServices)
    .map(mapServiceToEcommerceItem(metadata))
    .forEach(trackCartAction);

  commit(m.UPDATE, { ...payload, resourcesStations: stations });

  // note: `disallowAutoUpdateOfFlightsAndTotal` flag is used in flight select step
  //  only where we mainly manually manage the cart + service selection like Wizz Flex
  //  is done by ancillaries POST which may rewrite our manually crafted cart so
  //  the flag is born to resolve this
  // note: because of manual monkey patching we need to recalc the exchanged amounts
  if (disallowAutoUpdateOfFlightsAndTotal) {
    exchangeAmountDues(store);
  }
};

export const setInsuranceAddToCart = (store, payload) => {
  const { commit } = store;
  commit(m.SET_INSURANCE_ADD_TO_CART, payload);
};
