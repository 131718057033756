import arity from '../arity';

/**
 * Accepts a function `fn` and returns a function that guards invocation of
 * `fn` such that `fn` can only ever be called once, no matter how many times
 * the returned function is invoked. The first value calculated is returned in
 * subsequent invocations.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/once.js
 *
 * @type {(fn: Function) => Function}
 * @example
 *
 *      let addOneOnce = once(x => x + 1);
 *      addOneOnce(10); //=> 11
 *      addOneOnce(addOneOnce(50)); //=> 11
 */
const once = (fn) => {
  let called = false;
  let result;
  return arity(fn.length, (...args) => {
    if (!called) {
      called = true;
      result = fn(...args);
    }

    return result;
  });
};

export default once;
