export const languageCodeToLocaleCodeMap = new Map()
  .set('hu-hu', 'hu')
  .set('ca-es', 'ca')
  .set('cs-cz', 'cs')
  .set('de-de', 'de')
  .set('en-gb', 'en-GB')
  .set('es-es', 'es')
  .set('fr-fr', 'fr')
  .set('he-il', 'he')
  .set('it-it', 'it')
  .set('lv-lv', 'lv')
  .set('lt-lt', 'lt')
  .set('nl-nl', 'nl')
  .set('nb-no', 'nb')
  .set('pl-pl', 'pl')
  .set('pt-pt', 'pt')
  .set('ro-ro', 'ro')
  .set('ru-ru', 'ru')
  .set('sk-sk', 'sk')
  .set('sv-se', 'sv')
  .set('uk-ua', 'uk')
  .set('ka-ge', 'ka')
  .set('el-gr', 'el')
  .set('bg-bg', 'bg')
  .set('sr-cyrl-cs', 'sr')
  .set('mk-mk', 'mk')
  .set('ar-ae', 'ar-DZ')
  // missing locales, falling back to enGB:
  .set('bs-latn-ba', 'en-GB')
  .set('sq-al', 'en-GB');
