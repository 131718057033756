import curry from '../curry';
import { _adjust } from '../adjust';
import { _findIndex } from '../find-index';
import whereEq from '../where-eq';

/**
 * @typedef {<T>(fn: (item: T) => T, spec: Object<string, any>, arr: T[]) => T[]} AdjustWhereEqFn
 */

/**
 * @type AdjustWhereEqFn
 */
export const _adjustWhereEq = (fn, spec, arr) => {
  const index = _findIndex(whereEq(spec), arr);
  return _adjust(fn, index, arr);
};

/**
 * @type AdjustWhereEqFn
 * @example
 *
 *      adjustWhereEq(alter({ a: 5 }), { a: 1, b: 2 }, [{ a: 1, b: 2 }, { a: 3, b: 2 }]);  //=> [{ a: 5, b: 2 }, { a: 3, b: 2 }]
 */
const adjustWhereEq = curry(_adjustWhereEq);

export default adjustWhereEq;
