import axios from 'axios';
import { BOOKING_CAR_TRAWLER_FRAME } from '~/constants/api-endpoint';

export const submitCarTrawlerCar = (payload) =>
  axios.post(BOOKING_CAR_TRAWLER_FRAME, payload);

export const deleteCarTrawler = () => {
  return axios({
    url: BOOKING_CAR_TRAWLER_FRAME,
    method: 'delete',
  });
};
