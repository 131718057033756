import curry from '../curry';
import { _includes } from '../includes';

/**
 * note: types could be
 *    list: T[], item: T
 *    list: string, item: string
 * @typedef {<T, U>(list: T, item: U) => boolean} IncludedInFn
 */

/**
 * @type IncludedInFn
 */
export const _includedIn = (list, item) => _includes(item, list);

/**
 * @type IncludedInFn
 */
const includedIn = curry(_includedIn);

export default includedIn;
