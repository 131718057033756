import {
  FLIGHT_CHANGE_PATH,
  FLIGHT_CHANGE_STEP_INFORMATION_PATH,
} from '~/router/flight-change/constants';
import { getPath } from '~/utils/router/get-path';
import { getHref, setHref } from './browser';
import getLocale from './localization/get-locale';
import path from './fp/path';
import { isSSRBuild } from './ssr/build-type';

export function getUrl(pageName) {
  const url = window.urls?.[pageName];
  const locale = (getLocale() || '').toLowerCase();
  return url || `/${locale}/${pageName}`; // no trailing slash
}

export const isUrlMatchesPartnerRedirectUrl = (url) =>
  (url || '').includes(getPath('partnerRedirect'));

export function goToPayment(isHybrid = false) {
  const url = getUrl('main-page');
  const targetHashlessUrl = url.replace('main-page', '');
  const path = [targetHashlessUrl, 'booking/payment'].join(
    isSSRBuild || isHybrid ? '' : '#/'
  );
  setHref(path);
}

export function getItineraryUrl(pnr, lastName) {
  const url = getUrl('itinerary');
  return isSSRBuild ? `${url}/${pnr}/${lastName}` : `${url}#${pnr}/${lastName}`;
}

export function getItineraryUrlWithFlow(
  pnr,
  lastName,
  flow,
  direction,
  passengerNumber = 0
) {
  const url = getUrl('itinerary');
  return isSSRBuild
    ? `${url}/${pnr}/${lastName}/${flow}/${direction}/${passengerNumber}`
    : `${url}#${pnr}/${lastName}/true/${flow}/${direction}/${passengerNumber}`;
}

export function goToItinerary(pnr, lastName, replace) {
  setHref(getItineraryUrl(pnr, lastName), replace);
}

export function goToItineraryAndStartFlow(
  pnr,
  lastName,
  replace,
  flow,
  direction,
  passengerNumber = 0
) {
  setHref(
    getItineraryUrlWithFlow(pnr, lastName, flow, direction, passengerNumber),
    replace
  );
}

export const goToSuccessfulBooking = (replace = true) =>
  setHref(getUrl('itineraryNew'), replace);

export function getCheckInFlowUrl(pnr, lastName, direction) {
  if (isSSRBuild) {
    const url = getUrl('check-in');
    return `${url}/${pnr}/${lastName}/${direction}`;
  } else {
    const url = getUrl('mainPage');
    return `${url}#/check-in/${pnr}/${lastName}/${direction}`;
  }
}

export function goToCheckInFlow(pnr, lastName, direction, replace) {
  setHref(getCheckInFlowUrl(pnr, lastName, direction), replace);
}

function getAddTravelDocumentsUrl(pnr, lastName, direction) {
  if (isSSRBuild) {
    const url = getUrl('check-in');
    return `${url}/${pnr}/${lastName}/${direction}?type=auto`;
  } else {
    const url = getUrl('mainPage');
    return `${url}#/check-in/${pnr}/${lastName}/${direction}?type=auto`;
  }
}

export function goToAddTravelDocuments(pnr, lastName, direction, replace) {
  setHref(getAddTravelDocumentsUrl(pnr, lastName, direction), replace);
}

export function goToMainPage() {
  setHref(window.urls.mainPage);
}

export function goToAgencyLogin() {
  setHref(window.urls.agencyLogin);
}

export function getAgencyCreditConversionUrl(pnr, lastName) {
  const urlSuffix = pnr ? `#${pnr}/${lastName ?? ''}` : '';
  return `${window.urls.agencyCreditConversionRequest}${urlSuffix}`;
}

export function getWizzCreditConversionUrl(pnr, lastName) {
  const urlSuffix = pnr ? `#${pnr}/${lastName ?? ''}` : '';
  return `${window.urls.creditConversionRequest}${urlSuffix}`;
}

export function goToAgencyCreditConversionPage(pnr, lastName) {
  setHref(getAgencyCreditConversionUrl(pnr, lastName), true);
}

export function goToWizzCreditConversionPage(pnr, lastName) {
  setHref(getWizzCreditConversionUrl(pnr, lastName), true);
}

export function goToProfile() {
  setHref(window.urls.profile);
}

export function goToDivideFlightChange() {
  const url = getUrl('modifyBooking');
  setHref(`${url}/${FLIGHT_CHANGE_PATH}/${FLIGHT_CHANGE_STEP_INFORMATION_PATH}`);
}

export function goToFlightChange(replace = false) {
  setHref(getUrl('flightChange') || FLIGHT_CHANGE_PATH, replace);
}

export function getFareFinderUrl(
  departureStation,
  arrivalStation,
  outboundDepartureDate = null,
  returnDepartureDate = null
) {
  const url = getUrl('fareFinder');
  return `${url}/${departureStation}/${arrivalStation}${
    outboundDepartureDate
      ? `/0/0/0/1/0/0/${outboundDepartureDate}/${returnDepartureDate}`
      : ''
  }`;
}

export function goToFareFinder(
  departureStation,
  arrivalStation,
  outboundDepartureDate,
  returnDepartureDate,
  replace = false
) {
  setHref(
    getFareFinderUrl(
      departureStation,
      arrivalStation,
      outboundDepartureDate,
      returnDepartureDate
    ),
    replace
  );
}

export function getTripPlannerUrl(iata) {
  const url = getUrl('tripPlanner');
  return `${url}#/${iata}`;
}

export const goToEmergencyPage = () => {
  setHref(getEmergencyPageUrl());
};

export const areWeOnEmergencyPage = () => {
  const currentUrl = getHref();
  const emergencyUrl = getEmergencyPageUrl();
  return currentUrl.includes(emergencyUrl);
};

const getEmergencyPageUrl = () => {
  return path('urls.emergency', window) || '/emergency';
};
