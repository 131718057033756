import { FALLBACK_LOCALE_CODE } from '~/constants';
import { isServer } from '~/utils/ssr';

/**
 * `LOCALE` and the result of `getLocale()` are the same,
 *  the latter is tested and lazy though
 *
 * @param context     optional, falls back to window.language
 * @returns string    language code, like "en-gb", "it-it" etc.
 */
const getLocale = (context) => {
  // eslint-disable-next-line no-undef
  if (isServer()) return context?.culture ?? global.CULTURE_CODE ?? FALLBACK_LOCALE_CODE;

  context = context ?? window.language;

  return context?.culture ?? FALLBACK_LOCALE_CODE;
};

export default getLocale;
