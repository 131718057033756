import isFunction from '~/utils/object/is-function';
import curry from '../curry';
import { _map } from '../map';

/**
 * note: here we are simplifying a lot
 * @typedef {<T, U>(fn: (a: T) => U[], monad: T[]) => U[]} ChainFn
 */

/**
 * @type ChainFn
 */
export const _chain = (fn, monad) => {
  if (isFunction(monad)) return (x) => fn(monad(x))(x);
  if (isFunction(monad.chain)) monad.chain(fn);
  return _map(fn, monad).flat();
};

/**
 * `chain` maps a function over a list and concatenates the results. `chain`
 * is also known as `flatMap` in some libraries
 *
 * Dispatches to the `chain` method of the second argument, if present,
 * according to the [FantasyLand Chain spec](https://github.com/fantasyland/fantasy-land#chain).
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/chain.js
 *
 * @type ChainFn
 * @example
 *
 *      let duplicate = n => [n, n];
 *      chain(duplicate, [1, 2, 3]); //=> [1, 1, 2, 2, 3, 3]
 *
 *      chain(append, head)([1, 2, 3]); //=> [1, 2, 3, 1]
 */
const chain = curry(_chain);

export default chain;
