import axios from 'axios';
import { LS_FORCE_CAPTCHA_DEBUG_KEY } from '~/constants/local-storage';
import identity from '~/utils/fp/identity';
import always from '~/utils/fp/always';
import ifElse from '~/utils/fp/if-else';
import evolve from '~/utils/fp/evolve';
import isEmptyString from '~/utils/object/is-empty-string';
import { getLocalItemRaw } from '~/utils/storage';
import {
  BOOKING_ACCEPT_CHANGE,
  BOOKING_ACCEPT_GCC,
  BOOKING_ANALYTICS,
  BOOKING_CANCELLATION,
  BOOKING_CANCELLATION_FLOW,
  BOOKING_CAPTCHA_CHECK,
  BOOKING_CHECK_IF_DOUBLE_BOOKING_EXIST,
  BOOKING_CONTACT,
  BOOKING_COUPON,
  BOOKING_DETAILED_REFUNDABLE_AMOUNT,
  BOOKING_MODIFY,
  BOOKING_DIVIDE_FLIGHT_CHANGE_SERVICES,
  BOOKING_DIVIDE_STATUS,
  BOOKING_FARELOCK_FINALIZE,
  BOOKING_FLIGHT_CHANGE_INFORMATION,
  BOOKING_GROUP_ITINERARY,
  BOOKING_INFORMATION_BANNER,
  BOOKING_ITINERARY,
  BOOKING_NAME_CHANGE,
  BOOKING_PHONE_CONTACT,
  BOOKING_PREMIUM_SEAT_REFUND,
  BOOKING_PRIVILEGE_PASS,
  BOOKING_REFUND,
  BOOKING_REMOVE_TRAVEL_PARTNER,
  BOOKING_SEATMAP,
  BOOKING_SELECT,
  BOOKING_SEND_GROUP_REQUEST,
  BOOKING_SUCCESSFUL_BOOKING,
  BOOKING_SUMMARY,
  BOOKING_WIZZ_DISCOUNT_CLUB,
  BOOKING_WIZZ_DISCOUNT_CLUB_RENEWAL,
  BOOKING_WDC_CUT_STATUS,
  SEARCH_CONNECTED_FLIGHTS,
} from '~/constants/api-endpoint';

import {
  BOOKING_MODIFY_TYPE_DIVIDE_FLIGHT_CHANGE,
  BOOKING_MODIFY_TYPE_FLIGHT_CHANGE,
  BOOKING_MODIFY_TYPE_REBOOK,
} from '~/constants';

export { getPriceDetails } from './get-price-details';

export const select = (flights) => axios.post(BOOKING_SELECT, flights);

export const rebook = () =>
  axios.post(BOOKING_MODIFY, {
    type: BOOKING_MODIFY_TYPE_REBOOK,
  });

export const summary = (data) => axios.post(BOOKING_SUMMARY, data);

export const getGroupItinerary = () => axios.get(BOOKING_GROUP_ITINERARY);

export const sendGroupRequest = () => axios.post(BOOKING_SEND_GROUP_REQUEST);

// cancel flight, only available with wizz flex service
// http://docs.wizzfront.apiary.io/#reference/booking/cancellation/apply-a-coupon
export const cancellation = (data) => axios.post(BOOKING_CANCELLATION, data);

export const getAmountOfRefund = () => axios.get(BOOKING_CANCELLATION);

export const seatMap = (data) => axios.get(BOOKING_SEATMAP, { params: data });

export const getPremiumSeatRefund = () => axios.get(BOOKING_PREMIUM_SEAT_REFUND);
export const premiumSeatRefund = (data) => axios.post(BOOKING_PREMIUM_SEAT_REFUND, data);

export const getContact = () => {
  return axios.get(BOOKING_CONTACT).then((response) => {
    if (getLocalItemRaw(LS_FORCE_CAPTCHA_DEBUG_KEY)) {
      response.data.isCaptchaRequired = true;
    }
    return response;
  });
};

export const contact = (data) => {
  data = evolve(
    { homePhone: replaceEmptyStringWithNull, mobilePhone: replaceEmptyStringWithNull },
    data
  );
  const saveAsDefault = data.saveAsDefault?.value ?? false;
  return axios.post(BOOKING_CONTACT, { ...data, saveAsDefault });
};

/**
 * @type {(str) => str | null}
 */
const replaceEmptyStringWithNull = ifElse(isEmptyString, always(null), identity);

export const coupon = (data) => axios.post(BOOKING_COUPON, data);

export const checkDoubleBooking = () => axios.get(BOOKING_CHECK_IF_DOUBLE_BOOKING_EXIST);

export const itinerary = (data) => axios.post(BOOKING_ITINERARY, data);

export const successfulBooking = (data) => axios.post(BOOKING_SUCCESSFUL_BOOKING, data);

export const getGroupBookingItinerary = () => axios.get(BOOKING_GROUP_ITINERARY);

export const getNameChange = (data) => axios.get(BOOKING_NAME_CHANGE, { params: data });

export const nameChange = (data) => axios.post(BOOKING_NAME_CHANGE, data);

export const startFlightChange = () =>
  axios.post(BOOKING_MODIFY, {
    type: BOOKING_MODIFY_TYPE_FLIGHT_CHANGE,
  });

export const startDivideFlightChange = (data) =>
  axios.post(BOOKING_MODIFY, {
    type: BOOKING_MODIFY_TYPE_DIVIDE_FLIGHT_CHANGE,
    ...data,
  });

export const getFlightChangeInformation = () =>
  axios.get(BOOKING_FLIGHT_CHANGE_INFORMATION);

export const getDivideFlightChangeServices = () =>
  axios.get(BOOKING_DIVIDE_FLIGHT_CHANGE_SERVICES);

export const startFareLockFinalize = () => axios.get(BOOKING_FARELOCK_FINALIZE);

// notes: this is used to ACCEPT the refund
export const refund = (data = {}) => axios.post(BOOKING_REFUND, data);

// notes: this is used to GET the refund amount
export const getRefundAmount = () => axios.get(BOOKING_REFUND);

// Accept changed flight on disrupted / moved booking
export const acceptChangedFlights = () => axios.get(BOOKING_ACCEPT_CHANGE);

export const getAnalytics = () => axios.get(BOOKING_ANALYTICS);

export const analytics = () => axios.post(BOOKING_ANALYTICS);

export const getWizzDiscountClub = () => axios.get(BOOKING_WIZZ_DISCOUNT_CLUB);

export const wizzDiscountClub = (data) => axios.post(BOOKING_WIZZ_DISCOUNT_CLUB, data);

export const wizzDiscountClubRenewal = () =>
  axios.post(BOOKING_WIZZ_DISCOUNT_CLUB_RENEWAL);

export const privilegePass = (data) => axios.post(BOOKING_PRIVILEGE_PASS, data);

export const removeTravelPartner = (passenger) =>
  axios.post(BOOKING_REMOVE_TRAVEL_PARTNER, passenger);

export const captchaCheck = (data) => axios.post(BOOKING_CAPTCHA_CHECK, data);

export const getCaptcha = () => axios.get(BOOKING_CAPTCHA_CHECK);

export const acceptChangedGccPolicy = () => axios.post(BOOKING_ACCEPT_GCC);

export const changeContactPhoneNumber = (data) => axios.post(BOOKING_PHONE_CONTACT, data);

export const initCancellationFlow = () => axios.get(BOOKING_CANCELLATION_FLOW);

export const getRefundDetails = (data) =>
  axios.post(BOOKING_DETAILED_REFUNDABLE_AMOUNT, data);

/**
 * @property {Boolean} divideInProgress
 */
export const checkDivideStatus = () => axios.get(BOOKING_DIVIDE_STATUS);

export const getExtraInformation = () => axios.get(BOOKING_INFORMATION_BANNER);

export const checkWdcCutStatus = () => axios.get(BOOKING_WDC_CUT_STATUS);

export const getConnectedFlights = (data) => axios.post(SEARCH_CONNECTED_FLIGHTS, data);
