export const GLOBAL_VALUE = 'globalValue';

export const SET_STATIC_ASSET_URL = `${GLOBAL_VALUE}/setSetStaticAssetUrl`;

export const SET_LANGUAGE = `${GLOBAL_VALUE}/setLanguage`;

export const SET_GLOBAL_URLS = `${GLOBAL_VALUE}/setGlobalUrls`;
export const RESET_GLOBAL_URLS = `${GLOBAL_VALUE}/resetGlobalUrls`;

export const SET_GLOBAL_FILES = `${GLOBAL_VALUE}/setGlobalFiles`;
export const RESET_GLOBAL_FILES = `${GLOBAL_VALUE}/resetGlobalFiles`;

export const SET_GLOBAL_WIZZ = `${GLOBAL_VALUE}/setGlobalWizz`;

export const SET_ASSET_MANIFEST = `${GLOBAL_VALUE}/setAssetManifest`;

export const SET_CULTURES = `${GLOBAL_VALUE}/setCultures`;

export const SET_PAGE = `${GLOBAL_VALUE}/setPage`;

export const SET_IS_RTL = `${GLOBAL_VALUE}/setIsRtl`;

export const SET_STORED_RESOURCE_NAMES = `${GLOBAL_VALUE}/setStoredResourceNames`;
export const RESET_STORED_RESOURCE_NAMES = `${GLOBAL_VALUE}/resetStoredResourceNames`;

export const SET_RESOURCE_SCRIPTS = `${GLOBAL_VALUE}/setResourceScripts`;
export const RESET_RESOURCE_SCRIPTS = `${GLOBAL_VALUE}/resetResourceScripts`;

export const SET_BANNER_URLS = `${GLOBAL_VALUE}/setBannerUrls`;

export const SET_HOME_PAGE_BANNERS_DATA = `${GLOBAL_VALUE}/setHomePageBannersData`;
