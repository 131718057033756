import { computed } from 'vue';
import isNotFunction from '~/utils/object/is-not-function';

const LegacyVuexOptionAdapter = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        vuexAdapterInit.call(this);
      },
    });

    // option merging
    const merge = Vue.config.optionMergeStrategies.computed;
    Vue.config.optionMergeStrategies.vuex = (toValue, fromValue) => {
      if (!toValue) return fromValue;
      if (!fromValue) return toValue;
      return {
        getters: merge(toValue.getters, fromValue.getters),
        actions: merge(toValue.actions, fromValue.actions),
      };
    };
  },
};

function vuexAdapterInit() {
  // note: store injection is done by the official Vuex plugin
  const options = this.$options;
  const { vuex } = options;

  // if no vuex option found nothing to do
  if (!vuex) return;

  // vuex option handling
  if (!this.$store) {
    // eslint-disable-next-line no-console
    console.warn(
      '[vuex] store not injected. make sure to ' +
        'provide the store option in your root component.'
    );
  }

  const { getters, actions } = vuex;

  if (getters) {
    Object.entries(getters).forEach(([key, getter]) => {
      defineVuexGetter(this, key, getter);
    });
  }

  if (actions) {
    const store = this.$store;
    options.methods ??= {};
    Object.entries(actions).forEach(([key, action]) => {
      options.methods[key] = makeBoundAction(store, action, key);
    });
  }
}

/**
 * Define a Vuex getter on an instance.
 *
 * @param {Vue} vm
 * @param {String} key
 * @param {Function} getter
 */
function defineVuexGetter(vm, key, getter) {
  if (isNotFunction(getter)) {
    // eslint-disable-next-line no-console
    console.warn(`[vuex] Getter bound to key 'vuex.getters.${key}' is not a function.`);
    return;
  }

  Object.defineProperty(vm, key, {
    enumerable: true,
    configurable: true,
    get: () => computed(() => getter(vm.$store.state)).value,
    set: setter,
  });
}

/**
 * Setter for all getter properties.
 */
function setter() {
  throw new Error('vuex getter properties are read-only.');
}

/**
 * Make a bound-to-store version of a raw action function.
 *
 * @param {Store} store
 * @param {Function} action
 * @param {String} key
 */
function makeBoundAction(store, action, key) {
  if (isNotFunction(action)) {
    // eslint-disable-next-line no-console
    console.warn(`[vuex] Action bound to key 'vuex.actions.${key}' is not a function.`);
  }

  return function vuexBoundAction(...args) {
    return action.call(this, store, ...args);
  };
}

export default LegacyVuexOptionAdapter;
