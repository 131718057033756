import to from 'await-to-js';
import { createAction } from '~/utils/store';
import * as AssetService from '~/services/asset';
import * as localeGetters from '../locale/getters';
import * as m from './mutation-types';

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise<string>}
 */
export const initialize = async (store) => {
  const { state, commit } = store;
  const languageCode = localeGetters.languageCode(state);
  const countriesPromise = AssetService.getCountries(languageCode);
  const stationsPromise = AssetService.getStations(languageCode);

  const [countriesError, countriesResponse] = await to(countriesPromise);
  if (!countriesError) {
    commit(m.SET_COUNTRIES, countriesResponse.data?.countries ?? []);
  }

  const [stationsError, stationsResponse] = await to(stationsPromise);
  if (!stationsError) commit(m.SET_STATIONS, stationsResponse.data?.cities ?? []);

  if (countriesError || stationsError) throw countriesError || stationsError;
};

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const fetchServiceFees = async ({ commit } = {}, payload) => {
  const response = await AssetService.serviceFees(payload);
  commit(m.SET_SERVICE_FEES, response.data ?? []);
};

export const resetServiceFees = createAction(m.RESET_SERVICE_FEES);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const fetchDisruptionCreditInformation = async ({ commit } = {}) => {
  const [error, response] = await to(AssetService.getDisruptionCreditInformation());

  if (error) {
    return commit(m.SET_DISRUPTION_CREDIT_INFORMATION_ERRORS, error);
  }

  commit(m.SET_DISRUPTION_CREDIT_INFORMATION, response.data);
};
