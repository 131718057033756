import curry from '../curry';
import { _equals } from '../equals';
import { _prop } from '../prop';

/**
 * @typedef {<T, K extends keyof T>(p: K, value: any, obj: T) => boolean} PropEqFn
 */

/**
 * @type PropEqFn
 */
export const _propEq = (p, value, obj) => _equals(_prop(p, obj), value);

/**
 * Returns `true` if the specified object property is equal, in
 * `equals` terms, to the given value; `false` otherwise.
 * You can test multiple properties with `where` / `whereEq`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/propEq.js
 *
 * @type PropEqFn
 * @example
 *
 *      let abby = {name: 'Abby', age: 7, hair: 'blond'};
 *      let fred = {name: 'Fred', age: 12, hair: 'brown'};
 *      let rusty = {name: 'Rusty', age: 10, hair: 'brown'};
 *      let alois = {name: 'Alois', age: 15, disposition: 'surly'};
 *      let kids = [abby, fred, rusty, alois];
 *      let hasBrownHair = propEq('hair', 'brown');
 *      filter(hasBrownHair, kids); //=> [fred, rusty]
 */
const propEq = curry(_propEq);

export default propEq;
