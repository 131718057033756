import getLocale from '~/utils/localization/get-locale';

export const getDefaultState = () => ({
  pnrAndNames: {
    pnr: '',
    firstName: '',
    lastName: '',
  },
  locale: getLocale(),
});
