import { isInfant } from '~/utils/booking/passenger';
import adjustWhereEq from '~/utils/fp/adjust-where-eq';
import mergeLeft from '~/utils/fp/merge-left';
import hevolve from '~/utils/object/hevolve';
import { toKebabCase } from '~/utils/string';
import { CHECK_IN_ROUTES, CHECK_IN_STEP_PAYMENT_NAME } from '~/router/check-in/constants';
import propEq from '~/utils/fp/prop-eq';
import * as gm from '~/store/action-types';
import isEmpty from '~/utils/object/is-empty';
import { parseNumberedLabel } from '~/utils/localization/parse-numbered-label';
import isNil from '~/utils/object/is-nil';
import isNotNil from '~/utils/object/is-not-nil';
import * as m from './mutation-types';
import { getDefaultState } from './internal';

export const resetState = (state) => Object.assign(state, getDefaultState());

export default {
  [gm.RESET]: resetState,

  [m.RESET_CHECK_IN_STORE]: resetState,

  [m.RESET_ERRORS](state) {
    state.errors = [];
  },

  [m.SET_ERRORS](state, errors) {
    state.errors = errors;
  },

  [m.SET_IS_AUTO_CHECK_IN](state, isAutoCheckIn) {
    state.isAutoCheckIn = isAutoCheckIn;
  },

  [m.SET_CURRENT_STEP](state, step) {
    state.currentStep = step;
  },

  [m.DISABLE_EDITABLE_STEPS](state) {
    state.steps = state.steps.map((step) => ({
      ...step,
      isEditable: false,
    }));
  },

  [m.DISABLE_EDITABLE_STEPS_BEFORE_PAYMENT_STEP](state) {
    const paymentStepIndex = state.steps.findIndex(
      propEq('name', CHECK_IN_STEP_PAYMENT_NAME)
    );
    for (let index = 0; index <= paymentStepIndex; index++) {
      state.steps[index].isEditable = false;
    }
  },

  [m.SET_STEPS](state, steps) {
    state.steps = steps.map((step) => {
      const path = toKebabCase(step.step);
      const name = CHECK_IN_ROUTES.get(path);
      return {
        ...step,
        name,
        path,
      };
    });
  },

  [m.SET_FLIGHT](state, flight) {
    state.flight = flight;
  },

  [m.SET_BOOKING_DETAILS](state, payload = {}) {
    const { pnr, lastName, direction, firstName } = payload;
    state.pnr = pnr;
    state.lastName = lastName;
    state.direction = direction;
    if (firstName) state.firstName = firstName;
  },

  [m.SET_ALREADY_PAYED_STATUS](state, isAlreadyPayed) {
    state.stepsData.payment.isAlreadyPayed = isAlreadyPayed;
  },

  [m.SET_WARNING_STEP](state, labels) {
    state.stepsData.warning.labels = labels;
  },

  [m.SET_PASSENGERS_STEP](state, data) {
    state.stepsData.passengers.availablePassengers = data.passengers;
    state.stepsData.passengers.needNationality = data.needNationality;
    state.stepsData.passengers.isSeatSelectionBlockedBySeatingTogether =
      data.isSeatSelectionBlockedBySeatingTogether;
    state.stepsData.passengers.selectedPassengers = [];
  },

  [m.SET_PASSENGERS](state, data) {
    state.stepsData.passengers.availablePassengers = data.passengers;
  },

  [m.SET_CHANGES_TO_ACCEPT_STEP](state, data) {
    state.stepsData.changesToAccept.changes = data.changes;
  },

  [m.SET_CHANGES_TO_ACCEPT_STEP_VALIDITY](state, data) {
    state.stepsData.changesToAccept.isStepValid = data;
  },

  [m.SET_PASSENGER_STEP_ERRORS](state, errors) {
    state.stepsData.passengers.errors = errors;
  },

  [m.SET_CHANGES_TO_ACCEPT_STEP_ERRORS](state, errors) {
    state.stepsData.changesToAccept.errors = errors;
  },

  [m.SET_SELECTED_PASSENGERS](state, passengers) {
    state.stepsData.passengers.selectedPassengers = passengers;
  },

  [m.SET_SELECTED_PASSENGERS_NATIONALITY](state, passengers) {
    state.stepsData.passengers.nationalities = passengers;
  },

  [m.REMOVE_CHECK_IN_PASSENGERS](state, passengerNumbers) {
    state.stepsData.passengers.selectedPassengers =
      state.stepsData.passengers.selectedPassengers.filter(
        (selectedPassengerNumber) => !passengerNumbers.includes(selectedPassengerNumber)
      );
  },

  [m.SET_PASSENGER_STEP_VALIDITY](state, value) {
    state.stepsData.passengers.isStepValid = value;
  },

  [m.SET_RECENTLY_PURCHASED_ITEMS](state, { ancillaries, services, seats }) {
    state.recentlyPurchasedItems = {
      ancillaries,
      services,
      seats,
    };
  },

  [m.SET_RECENTLY_PAID_AMOUNT](state, value) {
    state.recentlyPaidAmount = value;
  },

  [m.SET_EMPTY_PAYMENT_OCCURRED_STATUS](state, value) {
    state.isEmptyPaymentOccurred = value;
  },

  [m.SET_PAYMENT_FAILED_STATUS](state, value) {
    state.stepsData.payment.isPaymentFailed = value;
  },

  // Contact data
  [m.RESET_CONTACT_DATA_STEP_PASSENGER_ERRORS](state, errors) {
    state.stepsData.contactData.passengerErrors = [];
  },

  [m.RESET_CONTACT_DATA_STEP_ERRORS](state, errors) {
    state.stepsData.contactData.errors = [];
  },

  [m.SET_CONTACT_DATA_STEP_ERRORS](state, errors) {
    const parsedErrors = errors.map((error) =>
      parseNumberedLabel({
        labelKey: error,
        listPrefixAdd: 0,
        skipTranslation: true,
      })
    );
    state.stepsData.contactData.errors = parsedErrors.filter((error) =>
      isNil(error.number)
    );
  },

  [m.SET_CONTACT_DATA_STEP_PASSENGER_ERRORS](state, errors) {
    const parsedErrors = errors.map((error) =>
      parseNumberedLabel({
        labelKey: error,
        listPrefixAdd: 0,
        skipTranslation: true,
      })
    );
    state.stepsData.contactData.passengerErrors = parsedErrors.filter((error) =>
      isNotNil(error.number)
    );
  },

  [m.SET_CONTACT_DATA_STEP](state, passengers) {
    state.stepsData.contactData.passengers = isEmpty(
      state.stepsData.contactData.passengers
    )
      ? passengers
      : passengers.map((passenger) => {
          const originalPassenger = state.stepsData.contactData.passengers.find(
            propEq('passengerNumber', passenger.passengerNumber)
          );
          return {
            ...passenger,
            email: originalPassenger?.email || passenger.email,
            mobilePhoneNumber:
              originalPassenger?.mobilePhoneNumber || passenger.mobilePhoneNumber,
          };
        });
  },

  [m.UPDATE_PASSENGER_CONTACT_DATA](state, passenger) {
    const passengersUpdate = {
      contactData: {
        passengers: adjustWhereEq(mergeLeft(passenger), {
          passengerNumber: passenger.passengerNumber,
        }),
      },
    };

    hevolve(
      {
        stepsData: passengersUpdate,
      },
      state
    );
  },

  [m.UPDATE_PASSENGER_TRAVEL_DOCUMENT_PROP](state, passenger) {
    const propToUpdate = isInfant(passenger) ? 'adultPassengerNumber' : 'passengerNumber';

    const passengersUpdate = {
      travelDocuments: {
        passengers: adjustWhereEq(mergeLeft(passenger), {
          [propToUpdate]: passenger[propToUpdate],
        }),
      },
    };

    hevolve(
      {
        stepsData: passengersUpdate,
      },
      state
    );
  },

  [m.SET_TRAVEL_DOCUMENTS_STEP](state, payload) {
    state.stepsData.travelDocuments = {
      ...state.stepsData.travelDocuments,
      ...payload,
    };
  },

  [m.SET_TRAVEL_DOCUMENTS_STEP_ERRORS](state, payload) {
    state.stepsData.travelDocuments.errors = payload;
  },

  [m.SET_TRAVEL_DOCUMENTS_VALIDATION_MESSAGES](state, payload) {
    state.stepsData.travelDocuments.validationMessages = payload.map((passenger) => {
      const { travelDocValidationFailed, travelDocValidationMessages, passengerNumber } =
        passenger;

      return {
        travelDocValidationFailed,
        travelDocValidationMessages,
        passengerNumber,
      };
    });
  },
  [m.REMOVE_PASSENGER_TRAVEL_DOCUMENT_STEP_ERRORS](state, passengerNumber) {
    const errors = state.stepsData.travelDocuments.errors.filter((error) => {
      const [, errorPassengerNumber] = error.split('-');
      return errorPassengerNumber !== passengerNumber.toString();
    });
    state.stepsData.travelDocuments.errors = errors;
  },

  [m.SET_CHECK_IN_POST_ERRORS](state, payload) {
    state.stepsData.prohibitedItems.errors = payload;
  },

  [m.SET_AVAILABLE_OPERATIONS](state, payload) {
    state.availableOperations = payload;
  },

  [m.SET_ALL_DONE_STEP](state, payload) {
    state.stepsData.allDone = payload;
  },

  [m.SET_ALL_DONE_STEP_ERRORS](state, payload) {
    state.stepsData.allDone.errors = payload;
  },

  [m.RESET_AVAILABLE_FEEDBACK_STAGES](state) {
    state.stepsData.allDone.availableFeedbackStages = [];
  },

  [m.SET_CHECK_IN_PARAMS](state, payload) {
    state.hoursUntilCheckInAvailable = payload;
  },

  [m.SET_CHECK_IN_PARAMS_ERRORS](state, payload) {
    state.hoursUntilCheckInAvailable.errors = payload;
  },
};
