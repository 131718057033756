import to from 'await-to-js';
import { isInfant } from '~/utils/booking/passenger';
import compose from '~/utils/fp/compose';
import filter from '~/utils/fp/filter';
import ifElse from '~/utils/fp/if-else';
import map from '~/utils/fp/map';
import propEq from '~/utils/fp/prop-eq';
import { createAction } from '~/utils/store';
import omit from '~/utils/fp/omit';
import evolve from '~/utils/fp/evolve';
import trim from '~/utils/fp/trim';
import pick from '~/utils/fp/pick';
import { privilegePass } from '~/services/booking';
import * as PassengerService from '~/services/booking/passsenger';
import * as featureGetters from '../feature/getters';
import { isNewBookingFlow } from '../core-booking/getters';
import { isFareLockAdded } from '../summary/getters';
import { FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_DISABLED } from '../feature/constants';
import * as getters from './getters';
import { internalInfantAssignmentErrorResponse } from './internal';
import * as m from './mutation-types';

export const reset = createAction(m.RESET);

export const getPassengers = async ({ commit }) => {
  const response = await PassengerService.getPassengers();
  commit(m.SET_PASSENGERS, response.data);
};

export const postPassengers = ({ state }) => {
  const mandatoryPRM =
    isNewBookingFlow(state) && !isFareLockAdded(state)
      ? featureGetters.mandatoryPrmValue(state)
      : FEATURE_VALUE_MANDATORY_PRM_ON_BOOKING_FLOW_DISABLED;

  const passengers = getters
    .passengers(state)
    .map(
      omit([
        'namechangeRestrictions',
        'freeNamechangeAvailable',
        'canBeTravelPartner',
        'alreadyHavePrivilegePass',
        'privilegePassActivated',
        'privilegePassPrice',
        'privilegePassEmail',
        'privilegePassActivatedFor',
        'infantPassengerNumber',
        'isPanelExpanded',
        'isPanelSummaryVisible',
      ])
    )
    .map(
      evolve({
        firstName: trim,
        lastName: trim,
        reducedMobility: omit(['wheelchair', 'hasOwnWheelchair']),
      })
    );

  return PassengerService.postPassengers({ passengers, mandatoryPRM });
};

export const setFakeGroupBookingPassenger = createAction(
  m.SET_FAKE_GROUP_BOOKING_PASSENGER
);

export const restorePassengers = createAction(m.RESTORE_PASSENGERS);

/**
 * @type {(store: Store, payload: { passenger: Object<string, any>, isInfant?: boolean }) => void}
 */
export const updatePassenger = createAction(m.UPDATE_PASSENGER);

export const setSavedPassengerDetails = createAction(m.SET_SAVED_PASSENGER_DETAILS);

export const updateSavedPassenger = createAction(m.UPDATE_SAVED_PASSENGER);

export const updatePassengerReducedMobility = createAction(
  m.UPDATE_PASSENGER_REDUCED_MOBILITY
);

export const resetPassengerReducedMobility = createAction(
  m.RESET_PASSENGER_REDUCED_MOBILITY
);

export const updateInfantAdultPassengerNumber = async (store, payload) => {
  const { state, commit } = store;
  const {
    infantPassengerNumber,
    newAdultPassengerNumber,
    hasInternalError,
    useSavedPassengers = false,
  } = payload;
  let error;

  const dataSource = useSavedPassengers
    ? getters.savedPassengerDetails(state)
    : getters.passengers(state);

  if (hasInternalError) {
    error = internalInfantAssignmentErrorResponse();
  } else {
    [error] = await to(
      PassengerService.moveInfantToAdult({
        MoveInfants: compose(
          map(
            ifElse(
              propEq('infantPassengerNumber', infantPassengerNumber),
              () => ({ adultPassengerNumberTo: newAdultPassengerNumber }),
              (infant) => ({ adultPassengerNumberTo: infant.passengerAdultNumber })
            )
          ),
          filter(isInfant)
        )(dataSource),
      })
    );
  }

  commit(m.UPDATE_INFANT_ADULT_PASSENGER_NUMBER, payload);

  // these errors are hopefully not critical
  // so we let the component to deal with it
  if (error) throw error;
};

export const updatePassengersAccordionState = ({ commit } = {}, payload = {}) => {
  commit(m.UPDATE_PASSENGERS_ACCORDION_STATE, {
    isFlightChangeOrRebookFlow: payload.isFlightChangeOrRebookFlow,
    isPassengersAccordionEnabled: payload.isPassengersAccordionAvailable,
  });
};

export const copyReducedMobilityToAllPassengers = createAction(
  m.COPY_REDUCED_MOBILITY_TO_ALL_PASSENGERS
);

export const syncPassengersFromSavedData = ({ commit }) => {
  commit(m.RESTORE_PASSENGERS, false);
};

export const passengersPrivilegePassActivation = ({ state }) => {
  return state.passengers.savedPassengerDetails.forEach(async (passenger) => {
    if (!passenger.privilegePassActivated) return;
    const payload = {
      privilegePassAction: 'add',
      ...pick(
        ['firstName', 'lastName', 'gender', 'passengerNumber', 'privilegePassEmail'],
        passenger
      ),
    };
    await to(privilegePass(payload));
  });
};

export const setIAmTravelling = createAction(m.SET_I_AM_TRAVELLING);
