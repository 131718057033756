import {
  PLANETYPE_A320,
  PLANETYPE_A321,
  PLANETYPE_A321NEO,
  PLANETYPE_A320_FRONT_DOOR_THRESHOLD,
  PLANETYPE_A321_FRONT_DOOR_THRESHOLD,
  GENDER_FEMALE,
  GENDER_MALE,
  ADULT,
  CHILD,
} from '~/constants';
import map from '~/utils/fp/map';
import assoc from '~/utils/fp/assoc';
import curry from '~/utils/fp/curry';
import { getI18n } from '~/i18n';

export { default as getPassengerNameWithFallback } from './get-passenger-name-with-fallback';

export const assignSeat = curry((passengerId, seatCode, seatAssignments) => {
  seatAssignments = unassignSeat(passengerId, seatAssignments);
  return assoc(seatCode, passengerId, seatAssignments);
});

export const unassignSeat = curry((passengerId, seatAssignments) =>
  map((pid) => (pid === passengerId ? null : pid))(seatAssignments)
);

export const getPassengerKind = (passenger) => {
  let res = '';
  if (isAdult(passenger) && isFemale(passenger)) {
    res = 'woman';
  } else if (isAdult(passenger)) {
    res = 'man';
  } else if (isChild(passenger)) {
    res = 'child';
  } else {
    res = 'infant';
  }
  return res;
};

export const isFemale = (passenger) => passenger.gender === GENDER_FEMALE;

export const isMale = (passenger) => passenger.gender === GENDER_MALE;

export const isAdult = (passenger) => passenger.type === ADULT;

export const isChild = (passenger) => passenger.type === CHILD;

export const getSeatPosition = (seatCode) => {
  if (!seatCode) {
    return '';
  }

  const column = getSeatColumn(seatCode);
  const position = {
    A: 'window',
    B: 'middle',
    C: 'aisle',
    D: 'aisle',
    E: 'middle',
    F: 'window',
  }[column];

  return getI18n().t(`seat-${position}`) || '';
};

export const isFrontDoorBoarding = (planeType, seatCode) => {
  if (!seatCode) {
    return false;
  }

  let res = false;
  const row = getSeatRow(seatCode);
  if (isA320(planeType)) {
    res = row <= PLANETYPE_A320_FRONT_DOOR_THRESHOLD;
  } else if (isA321(planeType) || isA321Neo(planeType)) {
    res = row <= PLANETYPE_A321_FRONT_DOOR_THRESHOLD;
  }

  return res;
};

export const isA320 = (planeType) => planeType === PLANETYPE_A320;

export const isA321 = (planeType) => planeType === PLANETYPE_A321;

export const isA321Neo = (planeType) => planeType === PLANETYPE_A321NEO;

const getSeatColumn = (seatCode) =>
  seatCode.length === 3 ? seatCode.slice(2) : seatCode.slice(1);

const getSeatRow = (seatCode) =>
  seatCode.length === 3
    ? Number.parseInt(seatCode.slice(0, 2), 10)
    : Number.parseInt(seatCode.slice(0, 1), 10);
