import curry from '../curry';
import _Set from '../_internal/_set';

/**
 * @typedef {<T, U>(fn: (value: T) => U, arr: T[]) => T[]} UniqByFn
 */

/**
 * @type UniqByFn
 */
export const _uniqBy = (fn, arr) => {
  const set = new _Set();
  const result = [];
  let index = 0;
  let appliedItem, item;

  while (index < arr.length) {
    item = arr[index];
    appliedItem = fn(item);
    if (set.add(appliedItem)) {
      result.push(item);
    }
    index += 1;
  }
  return result;
};

/**
 * Returns a new list containing only one copy of each element in the original
 * list, based upon the value returned by applying the supplied function to
 * each list element. Prefers the first item if the supplied function produces
 * the same value on two items. [`equals`](#equals) is used for comparison.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/uniqBy.js
 *
 * @type UniqByFn
 * @example
 *
 *  uniqBy(Math.abs, [-1, -5, 2, 10, 1, 2]); //=> [-1, -5, 2, 10]
 */

const uniqBy = curry(_uniqBy);

export default uniqBy;
