/**
 * A function that returns the `!` of its argument. It will return `true` when
 * passed falsy value, and `false` when passed a truthy one.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/not.js
 *
 * @type {(a: any) => boolean}
 * @example
 *
 *      not(true); //=> false
 *      not(false); //=> true
 *      not(0); //=> true
 *      not(1); //=> false
 */
const not = (a) => !a;

export default not;
