import { isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';
import * as globalValueGetters from '~/store/modules/global-value/getters';
import * as globalValueActions from '~/store/modules/global-value/actions';
import isNotEmpty from '../object/is-not-empty';

export const getWizz = (state = {}) => {
  if (isSSRBuild && (isNotEmpty(state) || isServer())) {
    return globalValueGetters.getGlobalWizz(state);
  }

  return window.wizz || globalValueGetters.getGlobalWizz(state);
};

export const setWizz = (value, store = {}) => {
  if (isSSRBuild && isServer()) {
    globalValueActions.setGlobalWizz(store, value);
    return;
  }

  Object.assign(window.wizz, value);
};
