import load from '@prepair/basic-loader';
import complement from '~/utils/fp/complement';
import isNotNil from '~/utils/object/is-not-nil';
import isNotEmpty from '~/utils/object/is-not-empty';

let isLoaded = false;

export const init = () => {
  const pintrk = (window.pintrk = (...args) => pintrk.queue.push(args));

  pintrk.version = '3.0';
  pintrk.queue = [];
};

const _load = () => {
  window.pintrk('load', window.wizz.pinterestPixelId);
  window.pintrk('page');

  return load.js('https://s.pinimg.com/ct/core.js');
};

const pintrk = (...args) => {
  if (isPintrkNotAvailable()) return;
  if (!isLoaded) {
    _load();
    isLoaded = true;
  }

  window.pintrk(...args);
};

export const pintrkTrack = (...args) => pintrk('track', ...args);

/**
 * @type {() => boolean}
 */
export const isPintrkAvailable = () => {
  return isNotNil(window.pintrk) && isNotEmpty(window.wizz.pinterestPixelId);
};

/**
 * @type {() => boolean}
 */
export const isPintrkNotAvailable = complement(isPintrkAvailable);
