import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  BUNDLE_SMART,
  BUNDLE_MIDDLE,
  BUNDLE_MIDDLE_TWO,
  BUNDLE_PLUS,
  SEAT_ASSIGNMENT_RESULT_CHILD_RULE_VIOLATION,
  SEAT_ASSIGNMENT_RESULT_NO_SEAT_AVAILABLE,
  SEAT_ASSIGNMENT_RESULT_SERVICE_UNAVAILABLE,
} from '~/constants';
import getSeats from '~/utils/booking/seat-selection/get-seats';
import __ from '~/utils/fp/__';
import curry from '~/utils/fp/curry';
import find from '~/utils/fp/find';
import includes from '~/utils/fp/includes';
import invertObj from '~/utils/fp/invert-obj';
import reduce from '~/utils/fp/reduce';
import whereEq from '~/utils/fp/where-eq';
import prop from '~/utils/fp/prop';
import equals from '~/utils/fp/equals';
import compose from '~/utils/fp/compose';
import orElse from '~/utils/fp/or-else';
import findById from '~/utils/fp/find-by-id';
import complement from '~/utils/fp/complement';
import { toKebabCase } from '~/utils/string';
import { formatCurrency } from '~/utils/currency';
import pathOr from '~/utils/fp/path-or';
import isNotEmpty from '~/utils/object/is-not-empty';
import without from '~/utils/fp/without';
import either from '~/utils/fp/either';
import some from '~/utils/fp/some';
import toLowerCase from '~/utils/fp/to-lower-case';
import propEq from '~/utils/fp/prop-eq';
import { getI18n } from '~/i18n';
import {
  LABEL_PRICE_INCLUDED,
  LABEL_PRICE_INCLUDED_IN_PLUS,
  LABEL_PRICE_INCLUDED_IN_MIDDLE,
  LABEL_PRICE_FREE,
  errorsAffectingSingleFlight,
  LABEL_PRICE_INCLUDED_IN_SMART,
  SEAT_SELECTION_SPECIAL_SEAT_POSITION_MAP,
  DEFAULT_SPECIAL_SEAT_ID,
} from './constants';
import hasAssignedSeat from './has-assigned-seat';
import findAssignedSeatCode from './find-assigned-seat-code';

export { findAssignedSeatCode };
export { hasAssignedSeat };
export { default as areAdjacentSeatsByCode } from './are-adjacent-seats-by-code';
export { default as findAssignedSeat } from './find-assigned-seat';
export { default as isLastRowSeatByCode } from './is-last-row-seat-by-code';

export const hasErrorAffectingFlight = (flightName, errors) =>
  either(
    hasErrorAffectingBothFlight,
    some(compose(includes(flightName), toLowerCase))
  )(errors);

/**
 * @type {(errors: string[]) => boolean}
 */
export const hasErrorAffectingBothFlight = compose(
  isNotEmpty,
  without(errorsAffectingSingleFlight)
);

export const hasSelectedSeatNotAvailableForFlight = (flightName, errors) =>
  errors.includes(`Selected${flightName}SeatNotAvailable`);

export const areSameSeatsAvailable = (passengers, seatAvailability) =>
  passengers.every((passenger) => {
    if (!seatAvailability[passenger.outboundSeat.code]) return false;
    return seatAvailability[passenger.outboundSeat.code][passenger.id] === true;
  });

export const passengersHasNoSeatsOnReturnFlight = (passengers) =>
  passengers.every((passenger) => passenger.returnSeat.code === null);

export const allPassengersHasSeatOnOutboundFlight = (passengers) =>
  passengers.every((passenger) => passenger.outboundSeat.code !== null);

export const isPreviousSeatAssignmentsChangedFromPaid = (flight) =>
  !equals(flight.previousSeatAssignments, flight.paidSeatAssignments);

export const isSeatAssignmentsChangedFromPaid = (flight) =>
  !equals(flight.seatAssignments, flight.paidSeatAssignments);

export const isSeatAssignmentsChangedFromPrevious = (flight) =>
  !equals(flight.seatAssignments, flight.previousSeatAssignments);

export const getDefaultData = () => ({
  confirmed: false,
  oneWayFlight: false,
  isOutboundFlightFlown: false,
  forcedShowContinueToReturnFlight: false,
  mobileLanding: true, // true means selectable matrix + continue to seat map button,
  // false means non selectable matrix + confirm/continue buttons
  currencyCode: '',
  rawPassengers: [],
  selectedPassengerId: null,
  selectedFlightName: OUTBOUND_FLIGHT_NAME, // outbound|return
  selectedSeatCode: null,
  preselectedSeatCode: null,
  errorResponse: null,
  seatMapIsVisibleOnMobile: false,
  getAncillariesIsLoading: false,
  postAncillariesIsLoading: false,
  autoSelectNextPassengerOnMobile: true,
  keepAirplaneOpen: false,
  outboundFlight: getDefaultFlight(OUTBOUND_FLIGHT_NAME),
  returnFlight: getDefaultFlight(RETURN_FLIGHT_NAME),
  shouldShowRecommendedSeats: false,
  isRecommendedSeatsDismissed: false,
  isSeatingTogetherModalAlreadyOpened: false,
  isSeatingTogetherModalVisible: false,
  isNewSeatSelectorOnMobileVisible: false,
  hasSelectedPanel: false,
  isPanelSelectionInvalid: false,
});

export const getDefaultFlight = (name) => ({
  name,
  departureStationIata: '',
  arrivalStationIata: '',
  departureDateTime: '',
  carrierCode: '',
  flightNumber: '',
  flightTitle: '',
  planeType: '',
  planeTypeFileName: '',
  bundle: '',
  rawSeatMap: [],
  seatAssignments: {},
  paidSeatAssignments: {},
  previousSeatAssignments: {},
  // TODO this is seat sel modal specific
  checkInStatus: {},
  seatPrices: {},
  recommendedSeats: [],
  seatAssignmentResult: '',
  seatAvailability: null,
  seatMapIsLoading: false,
  unsuccessfulSeatAssignment: false,
  shouldShowFullyBookedFlightNotice: false,
  shouldShowNeighboringSeatsNotAvailableNotice: false,
  shouldShowSeatsNotAvailableNotice: false,
  shouldShowSeatSelectionNotAvailableNotice: false,
  hasSeriousSeatSelectionError: false,
  isSelectionBlockedBySeatingTogether: false,
});

/**
 * @type {(seatAssignmentResult: string) => boolean}
 */
export const shouldShowNeighboringSeatsNotAvailableNotice = equals(
  SEAT_ASSIGNMENT_RESULT_CHILD_RULE_VIOLATION
);

/**
 * @type {(seatAssignmentResult: string) => boolean}
 */
export const shouldShowSeatsNotAvailableNotice = equals(
  SEAT_ASSIGNMENT_RESULT_NO_SEAT_AVAILABLE
);

/**
 * @type {(seatAssignmentResult: string) => boolean}
 */
export const shouldShowSeatSelectionNotAvailableNotice = equals(
  SEAT_ASSIGNMENT_RESULT_SERVICE_UNAVAILABLE
);

/**
 * @type {(seatAssignmentResult: string) => boolean}
 */
export const hasSeriousSeatSelectionError = includes(__, [
  SEAT_ASSIGNMENT_RESULT_CHILD_RULE_VIOLATION,
  SEAT_ASSIGNMENT_RESULT_NO_SEAT_AVAILABLE,
  SEAT_ASSIGNMENT_RESULT_SERVICE_UNAVAILABLE,
]);

export const getPassengerSeatInfo = (currencyCode, flight, passengerId) => {
  const { seatAssignments } = flight;
  const seatCode = findAssignedSeatCode(passengerId, seatAssignments);
  const { price, formattedPrice } = getSeatPriceInfo({
    currencyCode,
    seatCode,
    passengerId,
    flight,
  });
  const { price: promotedPrice, formattedPrice: formattedPromotedPrice } =
    getSeatPriceInfo({
      currencyCode,
      seatCode,
      passengerId,
      flight,
      isPromoted: true,
    });

  return {
    code: seatCode,
    price,
    formattedPrice,
    promotedPrice: price !== promotedPrice ? promotedPrice : null,
    formattedPromotedPrice:
      price !== promotedPrice && formattedPrice !== formattedPromotedPrice
        ? formattedPromotedPrice
        : '',
    currencyCode,
  };
};

export const findSeatByCode = curry((code, seatMap) =>
  compose(find(whereEq({ code })), getSeats)(seatMap)
);

export const getSeatType = (rawSeat) =>
  rawSeat.xl ? getI18n().t('extra-legroom') : getI18n().t('seat-standard');

export const getSeatPosition = (rawSeat) => {
  let res = '';
  const { window, aisle } = rawSeat;

  if (window) {
    res = 'seat-caption-window';
  } else if (aisle) {
    res = 'seat-caption-aisle';
  } else {
    res = 'seat-caption-middle';
  }

  return getI18n().t(res);
};

/**
 * @param {Object} passenger
 * @returns {Boolean}
 */
export const isReducedMobilityAssistant = prop('reducedMobilityAssistant');

/**
 * @param {Object} passenger
 * @returns {Boolean}
 */
export const isNotReducedMobilityAssistant = complement(isReducedMobilityAssistant);

/**
 * @param {Object} passenger
 * @returns {Boolean}
 */
export const isReducedMobilityPassenger = prop('reducedMobility');

/**
 * @param {Object} passenger
 * @returns {Boolean}
 */
export const isNotReducedMobilityPassenger = complement(isReducedMobilityPassenger);

export const canPassengerSelectSeat = (rawPassenger) =>
  !rawPassenger.reducedMobility && !rawPassenger.reducedMobilityAssistant;

export const isPremiumSeat = prop('premium');

export const isFirstRowSeat = (seat) => seat.rowNumber === 1;

export const isExtraLegroomSeat = (seat) => seat.xl;

/**
 * @param {Number} passengerId
 * @param {Array} rawPassengers
 * @returns {Boolean}
 */
export const hasPassengerPrivilegePassActivatedById = compose(
  equals(true),
  prop('privilegePassActivated'),
  orElse({}),
  findById
);

/**
 * @param {Number} passengerId
 * @param {Array} rawPassengers
 * @returns {Boolean}
 */
export const canPassengerSelectSeatById = compose(canPassengerSelectSeat, findById);

export const hasInfant = (passenger) => passenger.hasInfant;

export const isDataInitialized = (data) => Boolean(data.outboundFlight.flightNumber);

export const isFlightDataInitialized = curry((flight, data) =>
  Boolean(data[`${flight}Flight`].bundle)
);

export const canFetchSeatMap = (flight) => {
  const { seatMapIsLoading, hasSeriousSeatSelectionError } = flight;
  return !seatMapIsLoading && !hasSeriousSeatSelectionError;
};

export const getSeatPriceInfo = ({
  currencyCode,
  seatCode,
  passengerId,
  flight,
  isPromoted = false,
}) => {
  const { seatPrices, promotionSeatPrices, paidSeatAssignments } = flight;
  const hasSeat = Boolean(seatCode);
  const paidSeatCode = findAssignedSeatCode(passengerId, paidSeatAssignments);
  const sameAsPaidSeat = seatCode === paidSeatCode;
  const price = getSeatPrice(
    seatCode,
    passengerId,
    isPromoted ? promotionSeatPrices : seatPrices
  );
  const includedInBundle = getSeatIncludedInBundle(
    seatCode,
    isPromoted ? promotionSeatPrices : seatPrices
  );

  let formattedPrice = '';
  const i18n = getI18n();

  if (canDisplayFormattedPrice(hasSeat, sameAsPaidSeat, includedInBundle, price)) {
    formattedPrice = formatCurrency(price, currencyCode);
  } else if (canDisplayFreePrice(hasSeat, includedInBundle, price)) {
    formattedPrice = i18n.t(LABEL_PRICE_FREE);
  } else if (canDisplayIncludedPrice(hasSeat, sameAsPaidSeat, includedInBundle)) {
    formattedPrice = i18n.t(getIncludedLabel(includedInBundle));
  }

  return {
    price,
    formattedPrice,
  };
};

const canDisplayFreePrice = (hasSeat, includedInBundle, price) =>
  hasSeat && !includedInBundle && price === 0;

const canDisplayIncludedPrice = (hasSeat, sameAsPaidSeat, includedInBundle) =>
  (hasSeat && !includedInBundle && sameAsPaidSeat) || !hasSeat || includedInBundle;

const canDisplayFormattedPrice = (hasSeat, sameAsPaidSeat, includedInBundle, price) =>
  hasSeat && !sameAsPaidSeat && !includedInBundle && price > 0;

export const getIncludedLabel = (bundle) => {
  switch (bundle) {
    case BUNDLE_SMART:
      return LABEL_PRICE_INCLUDED_IN_SMART;
    case BUNDLE_MIDDLE:
    case BUNDLE_MIDDLE_TWO:
      return LABEL_PRICE_INCLUDED_IN_MIDDLE;
    case BUNDLE_PLUS:
      return LABEL_PRICE_INCLUDED_IN_PLUS;
    default:
      return LABEL_PRICE_INCLUDED;
  }
};

export const getSeatPrice = (seatCode, passengerId, seatPrices) => {
  return pathOr(null, `${seatCode}.${passengerId}`, seatPrices || {});
};

export const getSeatIncludedInBundle = (seatCode, seatPrices) => {
  return pathOr('', `${seatCode}.includedIn`, seatPrices || {});
};

export const getSeatBenefits = (rawSeat) =>
  (rawSeat.benefits || []).map((benefit) =>
    getI18n().t(`seat-map-seat-tooltip-benefit-${toKebabCase(benefit)}`)
  );

export const getNonPrmSeatAssignments = (prmPassengers = [], seatAssignments = {}) =>
  Object.keys(seatAssignments).reduce((acc, seatCode) => {
    const passengerNumber = seatAssignments[seatCode];
    const isAPrmPassenger = prmPassengers.find(propEq('id', passengerNumber));
    if (!isAPrmPassenger) {
      acc[seatCode] = passengerNumber;
    }
    return acc;
  }, {});

// notes: what the heck is going on here? well... my friend fixing a design flaw
//  the assignments are coming in a form where the key is the seat code and the value
//  is the passenger number. obviously we are unable to unique them in that form
//  so at first we invert them, flip the key-values and then flip back, and then
//  convert the numbers back to numbers since the types are lost during the flipping
export const uniqueSeatAssignments = (current, paid) =>
  compose(
    reduce(
      (acc, [seat, pid]) => {
        acc[seat] = Number.isNaN(pid) ? pid : Number(pid);
        return acc;
      },
      () => ({})
    ),
    Object.entries,
    invertObj,
    (inverted) => ({ ...invertObj(paid), ...inverted }),
    invertObj
  )(current);

export const getSpecialSeatId = (planeType, planeSubType) => {
  return (
    SEAT_SELECTION_SPECIAL_SEAT_POSITION_MAP.get(`${planeType}-${planeSubType}`) ||
    DEFAULT_SPECIAL_SEAT_ID
  );
};
