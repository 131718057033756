import axios from 'axios';
import {
  BOOKING_GROUP_PASSENGER,
  BOOKING_MOVE_INFANT,
  BOOKING_PASSENGERS,
} from '~/constants/api-endpoint';

export const getPassengers = () => axios.get(BOOKING_PASSENGERS);

export const postPassengers = (data) => axios.post(BOOKING_PASSENGERS, data);

export const getGroupBookingPassengers = () => axios.get(BOOKING_GROUP_PASSENGER);

export const moveInfantToAdult = (data) => axios.post(BOOKING_MOVE_INFANT, data);
