import * as m from './mutation-types';
import { getDefaultState } from './internal';

export default {
  // todo: this one seems unused
  // do NOT implement a plain RESET, itinerary mainRoute calls it
  [m.RESET_EMERGENCY](state) {
    Object.assign(state, getDefaultState());
  },

  [m.SET_IS_EMERGENCY_MODE_ACTIVE](state) {
    state.isEmergencyModeActive = true;
  },

  [m.UNSET_IS_EMERGENCY_MODE_ACTIVE](state) {
    state.isEmergencyModeActive = false;
  },

  [m.SET_IS_INITIALIZED](state) {
    state.isInitialized = true;
  },

  [m.UNSET_IS_INITIALIZED](state) {
    state.isInitialized = false;
  },

  [m.SET_SELECTED_LANGUAGE_CODE](state, value) {
    state.selectedLanguageCode = value;
  },

  [m.SET_NEWS_ITEMS](state, value) {
    state.newsItems = value;
  },

  [m.RESET_NEWS_ITEMS](state) {
    state.newsItems = [];
  },
};
