import { getI18n } from '~/i18n';
import isUndefined from '~/utils/object/is-undefined';

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

function getBaseCardPaymentMethod() {
  return {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: getAllowedAuthMethods(),
      allowedCardNetworks: ['MASTERCARD', 'VISA'],
    },
  };
}

function getIsReadyToPayRequest() {
  return Object.assign({}, baseRequest, {
    allowedPaymentMethods: [getBaseCardPaymentMethod()],
    existingPaymentMethodRequired: true,
  });
}

function getCardPaymentMethod(adyenMerchantId) {
  return Object.assign({}, getBaseCardPaymentMethod(), {
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: adyenMerchantId,
      },
    },
  });
}

function getAllowedAuthMethods() {
  switch (window.wizz.googlePayEnvironment) {
    case 'TEST':
      return ['PAN_ONLY', 'CRYPTOGRAM_3DS'];
    case 'PRODUCTION':
      return ['CRYPTOGRAM_3DS'];
    default:
      return [];
  }
}

class GooglePaymentServiceType {
  paymentsClient = null;

  injectGooglePayScript(onLoadCallback) {
    if (isUndefined(window.google)) {
      // load google pay script
      const googlePayScript = document.createElement('script');
      googlePayScript.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js');
      googlePayScript.setAttribute('async', true);
      googlePayScript.setAttribute('defer', true);
      googlePayScript.addEventListener('load', onLoadCallback);
      document.head.append(googlePayScript);
    } else {
      onLoadCallback();
    }
  }

  isReadyToPay() {
    return this.getGooglePaymentsClient().isReadyToPay(getIsReadyToPayRequest());
  }

  getGooglePayButtonHtml(onClick, buttonColor, buttonType) {
    return this.getGooglePaymentsClient().createButton({
      onClick,
      buttonColor,
      buttonType,
      buttonSizeMode: 'fill',
    });
  }

  getPaymentData(transactionInfo, googlePayPaymentOption) {
    const paymentDataRequest = this.getPaymentDataRequest(
      transactionInfo,
      googlePayPaymentOption
    );
    return this.getGooglePaymentsClient().loadPaymentData(paymentDataRequest);
  }

  getPaymentDataRequest(transactionInfo, googlePayPaymentOption) {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [
      getCardPaymentMethod(googlePayPaymentOption.adyenMerchantId),
    ];

    transactionInfo.countryCode = googlePayPaymentOption.acquirerBankCountryCode;
    transactionInfo.totalPriceStatus = 'FINAL';
    transactionInfo.totalPrice = transactionInfo.totalPrice.toString();
    transactionInfo.checkoutOption = 'COMPLETE_IMMEDIATE_PURCHASE';

    paymentDataRequest.transactionInfo = transactionInfo;
    paymentDataRequest.merchantInfo = {
      merchantId: googlePayPaymentOption.googlePayMerchantId,
      merchantName: getI18n().t(googlePayPaymentOption.merchantNameLabel),
    };
    return paymentDataRequest;
  }

  getGooglePaymentsClient() {
    if (this.paymentsClient === null) {
      // eslint-disable-next-line no-undef
      this.paymentsClient = new google.payments.api.PaymentsClient({
        environment: window.wizz.googlePayEnvironment,
      });
    }
    return this.paymentsClient;
  }
}

export const GooglePaymentService = new GooglePaymentServiceType();
