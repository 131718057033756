import { getHref, setHref } from '~/utils/browser';

const go = (url, overwrite) => {
  // url has both url AND hash
  const startsWithHashAndLetter = /^\/[a-z]/.test(url);
  const containsHashSection = url.includes('#/');
  if (startsWithHashAndLetter && containsHashSection) {
    if (overwrite) {
      window.location.replace(url);
    } else {
      setHref(url);
    }
    return;
  }
  // overwrite history item
  if (overwrite) {
    const href = getHref();
    window.location.replace(
      href.includes('#') ? href.replace(/#.*$/, `#${url}`) : `${href}#${url}`
    );
    return;
  }
  // standard hashurl
  if (!/^#.*/.test(url)) {
    url = `#${url}`;
  }
  window.location.hash = url;
};

export default go;
