/**
 * @type {(obj: Object<string, any>) => Object<string, any>}
 */
// deep freeze by substack, cleaned up and changed to use Object.entries
const deepFreeze = (obj) => {
  Object.freeze(obj);
  Object.values(obj)
    .filter((value) => !Object.isFrozen(value))
    .forEach(deepFreeze);
  return obj;
};

export default deepFreeze;
