import compose from '~/utils/fp/compose';
import pick from '~/utils/fp/pick';
import sort from '~/utils/fp/sort';
import uniq from '~/utils/fp/uniq';
import propEqTrue from '~/utils/fp/prop-eq-true';
import {
  CO_BRANDED_CREDIT_CARD_TYPE_CODES,
  CREDIT_CARD_TYPE_CODE_BANCONTACT,
  PAYMENT_OPTION_BANK_TRANSFER,
  PAYMENT_OPTION_CREDIT_CARD,
  PAYMENT_OPTION_GOOGLE_PAY,
  PAYMENT_OPTION_I_DEAL,
  PAYMENT_OPTION_STORED_CREDIT_CARD,
  PAYMENT_OPTION_WIZZ_ACCOUNT,
  UNACCEPTED_CARDS,
} from '~/constants';
import {
  sendPaymentMethodEvent,
  sendInvoicingEvent,
  sendMcpEvent,
  sendPaymentInitiatedEvent,
  sendPartialPaymentMethodEvent,
} from './analytics';
import { getSelectedCreditCardType } from './internal';

export { determineCreditCardTypeCode } from './internal';

// returns an array of currency codes (or an empyt array if smg went wrong)
export const getSelectedPaymentCurrencies = (payment) => {
  let currencies = [];
  const paymentOptions = payment.options;
  const selectedPayment = payment.selected;

  // stored card
  if (selectedPayment === PAYMENT_OPTION_STORED_CREDIT_CARD) {
    const cardType = getStoredCreditCardType(payment);
    currencies = cardType.availableCurrencies ?? [];
  }

  // plain card
  if (selectedPayment === PAYMENT_OPTION_CREDIT_CARD) {
    const cardType = getSelectedCreditCardType(payment);
    if (UNACCEPTED_CARDS.includes(cardType.creditCardTypeCode)) return [];
    currencies = cardType.availableCurrencies ?? [];
  }

  // iDeal (dutch payment system)
  if (selectedPayment === PAYMENT_OPTION_I_DEAL) {
    const iDeal = paymentOptions.iDealPaymentOption;
    // be returns the first, but I can't trust 'em
    currencies = iDeal.availableCurrency
      ? [iDeal.availableCurrency]
      : iDeal.availableCurrencies ?? [];
  }

  // bank transfer
  if (selectedPayment === PAYMENT_OPTION_BANK_TRANSFER) {
    const accounts = paymentOptions.bankTransferPaymentOption.bankAccounts ?? [];
    currencies = accounts.reduce((acc, account) => {
      acc.push(...(account.availableCurrencies ?? []));
      return acc;
    }, []);
  }

  // wizz account (single currency only)
  if (selectedPayment === PAYMENT_OPTION_WIZZ_ACCOUNT) {
    const currencyCode =
      paymentOptions.customerAccountPaymentOption.availableAmount?.currencyCode;
    currencies = currencyCode ? [currencyCode] : [];
  }

  // google pay
  if (selectedPayment === PAYMENT_OPTION_GOOGLE_PAY) {
    currencies = paymentOptions.googlePayPaymentOption.availableCurrencies;
  }

  return compose(uniq, sort(undefined))(currencies);
};

// get the partial payment section for the currently selected/detected payment method
export const getGroupBookingPartialPayment = (payment) => {
  let parent;
  const paymentOptions = payment.options;
  const selectedPayment = payment.selected;
  const simplePayments = [
    'bankTransferPaymentOption',
    'customerAccountPaymentOption',
    'iDealPaymentOption',
  ];

  if (simplePayments.includes(selectedPayment)) {
    parent = paymentOptions[selectedPayment];
  }

  // plain card
  if (selectedPayment === 'creditCardPaymentOption') {
    parent = getSelectedCreditCardType(payment);
  }

  // stored card
  if (selectedPayment === 'storedCreditCardPaymentOption') {
    parent = getStoredCreditCardType(payment);
  }

  // voucher, coupon, farelock are skipped
  const partialData = (parent || {}).groupBookingPartialPayment;

  // schema: `{ minimumPercentageToPay: Number, minimumPriorDays: Number }`
  return partialData ? Object.assign({}, partialData) : null;
};

const getStoredCreditCardType = (payment) => {
  const paymentOptions = payment.options;
  const availableStoredCards =
    paymentOptions.storedCreditCardPaymentOption.availableStoredCreditCards;
  const storedCard =
    availableStoredCards.find((card) => card.isDefault) || availableStoredCards[0];
  return storedCard.cardType;
};

export const isCoBrandedCreditCardType = (creditCardType) =>
  CO_BRANDED_CREDIT_CARD_TYPE_CODES.includes(creditCardType.creditCardTypeCode);

export const isBancontactCreditCardType = (creditCardType) =>
  CREDIT_CARD_TYPE_CODE_BANCONTACT === creditCardType.creditCardTypeCode;

// NOTE: used only in gift voucher
export const getDefaultStoredCreditCard = (storedCreditCards) =>
  storedCreditCards.find(propEqTrue('isDefault')) || storedCreditCards[0];

/**
 * get the `{ amount, currencyCode }` data so that it can be injected
 * into the payment's bigger payload, will return empty object if not applicable
 *
 * @param paymentType             currently selected payment type, String
 * @param groupPartialPayment     partial payment schema from booking/payment store, Object
 *
 * returns empty object or object with amount and currencyCode
 */
export const getGroupBookingPartialPaymentPayload = (
  paymentType,
  groupPartialPayment
) => {
  const isRelevant = paymentType === groupPartialPayment.selectedPayment;
  const isInvalidPartialPayment =
    groupPartialPayment.enabled && !groupPartialPayment.valid;
  const isBroken = !isRelevant || isInvalidPartialPayment;
  const paymentAmount = pick(['amount', 'currencyCode'], groupPartialPayment);
  return isBroken ? {} : { paymentAmount };
};

export const trackPaymentEvent = (analyticsParams, context) => {
  if (!context.isPartialPayment) {
    sendPaymentMethodEvent(
      analyticsParams.paymentMethodAction,
      analyticsParams.paymentMethodLabel
    );
  } else {
    sendPartialPaymentMethodEvent(
      analyticsParams.paymentMethodAction,
      analyticsParams.paymentMethodLabel
    );
  }

  sendInvoicingEvent(context.isPrivate, context.isAddServicesFlow);
  sendMcpEvent(
    context.mcpSelectionSource,
    context.isDefaultCurrencySelected,
    context.currency
  );
  sendPaymentInitiatedEvent(analyticsParams.paymentInitiatedAction);
};

export const normailze3dsRedirectHtml = (html) =>
  html.replace(/target="(.*?)"/, 'target="_top"');

export const append3dsRedirectHtml = (html) => {
  html = normailze3dsRedirectHtml(html);
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  document.body.append(wrapper);
  const $form = wrapper.querySelector('form');
  if ($form) {
    $form.submit();
    $form.remove();
  }
};
