const getPassengerName = (passenger) => {
  let res = '';

  if (passenger.firstName && passenger.lastName) {
    res = `${passenger.firstName} ${passenger.lastName}`;
  }

  return res;
};

export default getPassengerName;
