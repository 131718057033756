import compose from '~/utils/fp/compose';
import curry from '~/utils/fp/curry';
import equals from '~/utils/fp/equals';
import path from '~/utils/fp/path';
import { isNotServer, isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';

const globalValueProp = curry((_path, state) => path(_path, state.globalValue));

export const getStaticAssetUrl = globalValueProp('staticAssetUrl');

export const getLanguage = globalValueProp('language');

export const isRtl = () => (isServer() ? globalValueProp('isRtl') : window.isRtl);

export const getUrls = globalValueProp('urls');

export const getFiles = globalValueProp('files');

export const getGlobalWizz = globalValueProp('globalWizz');

export const getAssetManifest = globalValueProp('assetManifest');

export const getCultures = globalValueProp('cultures');

export const getPage = globalValueProp('page');

export const getIsRtl = globalValueProp('isRtl');

export const getStoredResourceNames = globalValueProp('storedResourceNames');

export const getResourceScripts = globalValueProp('resourceScripts');

export const getBannerUrls = globalValueProp('bannerUrls');

export const getHomePageBannersData = globalValueProp('homePageBannersData');

const isRawHybridMode = compose(equals('true'), globalValueProp('globalWizz.hybridMode'));

export const isHybridMode = (state) => {
  if (isSSRBuild) {
    return isRawHybridMode(state);
  }
  return isNotServer() ? window.wizz?.hybridMode === 'true' : false;
};
