import curry from '../curry';
import { _prop } from '../prop';

/**
 * @typedef {<T, K extends keyof T>(p: K, obj: T) => boolean} PropEqFalseFn
 */

/**
 * @type PropEqFalseFn
 */
export const _propEqFalse = (p, obj) => _prop(p, obj) === false;

/**
 * Returns `true` if the specified object property is equal to false, `false` otherwise.
 *
 * @type PropEqFalseFn
 * @example
 *
 *      let abby = {hands: true, hair: false};
 *      let fred = {hands: true, hair: true};
 *      let infants = [abby, fred];
 *      let hasNoHair = propEqFalse('hair');
 *      filter(hasNoHair, infants); //=> [abby]
 */
const propEqFalse = curry(_propEqFalse);

export default propEqFalse;
