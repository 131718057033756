import { DISCOUNT_TYPE_NONE } from '~/constants';
import path from '~/utils/fp/path';
import compose from '~/utils/fp/compose';
import equals from '~/utils/fp/equals';
import orElse from '~/utils/fp/or-else';
import curry from '~/utils/fp/curry';
import { addDays, toDefaultFormat, differenceInDays } from '~/utils/date';
import { moduleName, ErrorType, LoadingCounter, Operation } from './constants';

export const getDefaultState = () => ({
  isAllFareBenefitsVisible: false,
  isBundleCompositionWarningTextVisible: false,
  isInitialized: false,
  isDomestic: false,
  isWdcPremiumEnabled: false,
  isContinueWithCurrentWdcMembership: false,
  isWdcFareTypeSelected: false,
  isWdcFareTypePreferred: false,
  isPostSelectCalledAfterFlightAndFareSelection: false,
  isAncillariesGetCalled: false,
  isWdcTermsAgreed: false,
  isEmailSubscriptionChecked: false,
  isGroupBooking: false,
  isSubmitInProgress: false,
  isSubmitAttempted: false,
  isSubmitted: false,
  isWdcHighlighted: false,
  isWdcPromotionDetailsVisible: false,
  // todo should be replaced by summary getters, we don't need to manage this
  isWdcMembershipAddedToCart: false,
  isBundleUpgraded: false,
  isWdcAddedOnTargetedModal: false,
  isPriceVisibleOnFareChart: false,
  isOutboundBasicPreselected: false,
  isReturnBasicPreselected: false,

  departureStationIata: '',
  arrivalStationIata: '',
  numberOfAdultPassengers: 0,
  numberOfChildPassengers: 0,
  numberOfInfantPassengers: 0,
  rescueFareCode: '',
  discountType: DISCOUNT_TYPE_NONE,
  discountPercentage: 0,
  flightDatesDifference: null,
  errors: getDefaultErrors(),
  loadingCounters: getDefaultLoadingCounters(),
  operationPromises: getDefaultOperationPromises(),
  selectedWdcMembership: '',
  wdcMemberships: [],
  isInitPassengersLoading: false,

  wdcMembershipPrices: {
    standard: '',
    group: '',
    premium: '',
    premiumPlus: '',
  },

  wdcPromotedMembershipPrices: {
    standard: '',
    group: '',
  },

  wdcPromotedMembershipPercentages: {
    standard: '',
    group: '',
  },

  wdcRenewalPrice: null,

  wdcDiscounts: {
    minimumBaggageDiscount: '',
    minimumFareAmount: '',
    minimumFareDiscount: '',
  },

  outboundSelection: getDefaultSelection(),
  returnSelection: getDefaultSelection(),

  sync: {
    outboundFlights: [],
    returnFlights: [],
  },

  upsell: {
    isOutboundFareUpgraded: false,
    isReturnFareUpgraded: false,
    outboundFareUpgradePrice: {},
    returnFareUpgradePrice: {},
    outboundFareUpgradeBundle: '',
    returnFareUpgradeBundle: '',
  },

  outboundCo2Emission: {
    emission: '',
    euAvgEmission: '',
    emissionDifference: '',
    emissionPercentage: '',
  },

  returnCo2Emission: {
    emission: '',
    euAvgEmission: '',
    emissionDifference: '',
    emissionPercentage: '',
  },
});

const getDefaultOperationPromises = () =>
  Object.values(Operation).reduce((acc, operation) => {
    acc[operation] = Promise.resolve();
    return acc;
  }, {});

const getDefaultLoadingCounters = () =>
  Object.values(LoadingCounter).reduce((acc, loadingCounter) => {
    acc[loadingCounter] = 0;
    return acc;
  }, {});

export const getDefaultErrors = () =>
  Object.values(ErrorType).reduce((acc, errorType) => {
    acc[errorType] = [];
    return acc;
  }, {});

const getDefaultSelection = () => ({
  selectedFlightDate: '',
  selectedFlightId: null,
  selectedFareId: null,
  openFlightId: null,
  groupRequestedFlightId: null,
  flights: [],
  isAccessibilityEnabled: true,
  importantInformation: [],

  flightDatePicker: {
    dates: [],
  },
});

/**
 * @type {(_path: string) => (state: State) => any}
 */
export const flightSelectProp = curry((_path, state) => path(_path, state[moduleName]));

/**
 * @type {(_path: string, value: any) => (state: State) => boolean}
 */
export const flightSelectPropEq = (_path, value) =>
  compose(equals(value), flightSelectProp(_path));

/**
 * @type {(defaultValue: any, _path: string) => (state: State) => any}
 */
export const flightSelectPropOr = (defaultValue, _path) =>
  compose(orElse(defaultValue), flightSelectProp(_path));

// todo rethink the name, here we are fixing the return date actually if ...
export const calculateSearchDates = (flightDatesDifference, selectedDates) => {
  const { outbound } = selectedDates;
  const difference = selectedDates.return
    ? differenceInDays(selectedDates.return, outbound)
    : null;
  if (difference && difference < 0) {
    return {
      outbound: toDefaultFormat(outbound),
      return: compose(
        toDefaultFormat,
        addDays(Math.abs(flightDatesDifference))
      )(outbound),
    };
  } else {
    return selectedDates;
  }
};

export const getClosestPossibleDateForFlight = (flightDatesList, flightlessDate) => {
  const closestFlightDate = flightDatesList.find(
    (date) => differenceInDays(date, flightlessDate) > 0
  );
  return toDefaultFormat(closestFlightDate);
};
