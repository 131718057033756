import curry from '../curry';

/**
 * @typedef {(fn: (el: T) => boolean, arr: T[]) => boolean} AnyFn
 */

/**
 * @type AnyFn
 */
export const _any = (fn, arr) => arr.some((el) => fn(el));

/**
 * Returns `true` if at least one of elements of the list match the predicate,
 * `false` otherwise.
 *
 * Note: predicate fn will be called as fn(element)
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/any.js
 *
 * @type AnyFn
 * @example
 *
 *      let lessThan0 = flip(lt)(0);
 *      let lessThan2 = flip(lt)(2);
 *      any(lessThan0)([1, 2]); //=> false
 *      any(lessThan2)([1, 2]); //=> true
 */
const any = curry(_any);

export default any;
