import axios from 'axios';
import { to } from 'await-to-js';
import {
  HEADER_NAME_IS_GROUP_BOOKING,
  VALIDATION_CODE_PAYMENT_DECLINED,
} from '~/constants';
import {
  PAYMENT_BANK_TRANSFER,
  PAYMENT_CREDIT_CARD,
  PAYMENT_CUSTOMER_ACCOUNT,
  PAYMENT_EMPTY,
  PAYMENT_FARELOCK,
  PAYMENT_IDEAL,
  PAYMENT_PAY_BY_LINK,
  PAYMENT_PAYMENT_OPTIONS,
  PAYMENT_STORED_CREDIT_CARD,
  PAYMENT_VOUCHER,
  PAYMENT_TRUSTLY,
  PAYMENT_GOOGLE_PAY,
  PAYMENT_CORPORATE_CARD_FEE_PERCENT,
  PAYMENT_CORPORATE_CARD_FEE_INFO,
  PAYMENT_STORED_CORPORATE_CARD_FEE_INFO,
} from '~/constants/api-endpoint';
import { GooglePaymentService } from '~/services/google-pay';
import { createBrowserInformation } from '~/utils/browser';

// group partial payments have a `paymentAmount` object, so we have to copy
// the currency code from there to MCPCurrency or collectedCurrency above, to
// avoid confusing the backend (while you are wondering why the heck do we need this,
// watch the Monty Python sketch a Confuse-A-Cat!)
const fixGroupPartialPayments = (data) => {
  const gppCurrency = data?.paymentAmount?.currencyCode;
  data = Object.assign({}, data);
  if (gppCurrency) {
    if (data.MCPCurrency) {
      // cc, stored cc
      data.MCPCurrency = gppCurrency;
    }
    if (data.collectedCurrency) {
      // bank transfer
      data.collectedCurrency = gppCurrency;
    }
  }
  return data;
};

const addBrowserInformation = (data) => ({
  ...data,
  browserInformation: createBrowserInformation(),
});

export const getOptions = () => axios.get(PAYMENT_PAYMENT_OPTIONS);

export const empty = (data = {}) =>
  axios.post(PAYMENT_EMPTY, { ...data, isPaymentNotificationAccepted: true });

export const bankTransfer = (data) => {
  data = addBrowserInformation(fixGroupPartialPayments(data));
  return axios.post(PAYMENT_BANK_TRANSFER, data);
};

export const creditCard = (data) => {
  data = addBrowserInformation(fixGroupPartialPayments(data));
  return axios.post(PAYMENT_CREDIT_CARD, data);
};

export const googlePay = async (data, paymentAmount, googlePayPaymentOption) => {
  // get token from google pay
  const transactionInfo = {
    currencyCode: paymentAmount.currencyCode,
    // transactionId: null, // TODO: how should we use this?
    totalPrice: paymentAmount.amount,
  };
  const paymentData = await GooglePaymentService.getPaymentData(
    transactionInfo,
    googlePayPaymentOption
  );

  data.googlePayToken = paymentData.paymentMethodData.tokenizationData.token;

  // send token to backend
  return axios.post(PAYMENT_GOOGLE_PAY, data);
};

export const storedCreditCard = (data) => {
  data = fixGroupPartialPayments(data);
  return axios.post(PAYMENT_STORED_CREDIT_CARD, data);
};

export const voucher = (data) => axios.post(PAYMENT_VOUCHER, data);

export const wizzAccount = (data) => axios.post(PAYMENT_CUSTOMER_ACCOUNT, data);

export const ideal = ({ isGroupBooking = false, data } = {}) =>
  axios.post(
    ...[
      PAYMENT_IDEAL,
      data,
      ...(isGroupBooking
        ? [{ headers: { [HEADER_NAME_IS_GROUP_BOOKING]: 'true' } }]
        : []),
    ]
  );

export const paybylink = ({ isGroupBooking = false, data } = {}) =>
  axios.post(
    ...[
      PAYMENT_PAY_BY_LINK,
      data,
      ...(isGroupBooking
        ? [{ headers: { [HEADER_NAME_IS_GROUP_BOOKING]: 'true' } }]
        : []),
    ]
  );

export const trustly = ({ isGroupBooking = false, data } = {}) =>
  axios.post(
    ...[
      PAYMENT_TRUSTLY,
      data,
      ...(isGroupBooking
        ? [{ headers: { [HEADER_NAME_IS_GROUP_BOOKING]: 'true' } }]
        : []),
    ]
  );

export const agencyFareLock = (data) => axios.post(PAYMENT_FARELOCK, data);

export const declinableEmpty = () => declineWrapper(empty());

// payment responses may return with status 200 and paymentDeclined:true
// since that is a 3rd party provider error, we know nothing about it
const declineWrapper = async (httpPromise) => {
  const response = (await httpPromise) || {};
  if (response.paymentDeclined) {
    response.data = response.data || {};
    response.data.validationCodes = [VALIDATION_CODE_PAYMENT_DECLINED];
    return Promise.reject(response);
  } else {
    return response;
  }
};

export const getCorporateCardFeePercent = async (cardNumber) => {
  if (cardNumber.length < 8) return 0;

  const bin = cardNumber.slice(0, 8);
  const [error, response] = await to(
    axios.get(PAYMENT_CORPORATE_CARD_FEE_PERCENT, {
      params: { bin },
    })
  );
  return error ? 0 : response.data.corporateCardFeePercent;
};

export const getStoredCorporateCardFeeInfo = async (fopId, currency, amount) => {
  const response = await axios.get(PAYMENT_STORED_CORPORATE_CARD_FEE_INFO, {
    params: { fopId, currency, amount },
  });
  const {
    corporateCardFeePercent,
    originalAmount,
    corporateCardFeeAmount,
    newTotalAmount,
  } = response.data;
  return {
    corporateCardFeePercent,
    originalAmount,
    corporateCardFeeAmount,
    newTotalAmount,
  };
};

export const getCorporateCardFeeInfo = async (cardNumber, currency, amount) => {
  if (cardNumber.length < 8)
    return {
      percent: 0,
      originalAmount: null,
      feeAmount: null,
      newTotalAmount: null,
    };

  const bin = cardNumber.slice(0, 8);
  const response = await axios.get(PAYMENT_CORPORATE_CARD_FEE_INFO, {
    params: { bin, currency, amount },
  });
  const {
    corporateCardFeePercent,
    originalAmount,
    corporateCardFeeAmount,
    newTotalAmount,
  } = response.data;
  return {
    corporateCardFeePercent,
    originalAmount,
    corporateCardFeeAmount,
    newTotalAmount,
  };
};
