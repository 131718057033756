import uniq from '../uniq';
import curry from '../curry';

/**
 * @typedef {<T>(arr1: T[], arr2: T[]) =>T[]} UnionFn
 */

/**
 * @type UnionFn
 */
export const _union = (arr1, arr2) => uniq(arr1.concat(arr2));

/**
 * Combines two lists into a set (i.e. no duplicates) composed of the elements
 * of each list.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/union.js
 *
 * @type UnionFn
 * @example
 *
 *      union([1,2,3], [1,4,5]);
 *      //=> [1,2,3,4,5]
 *
 *      union([1,2,"test",3], [1,2,3,"test"]);
 *      //=> [1,2,"test",3]
 *
 *      const obj = { a: 1 };
 *      union([1,2,3, obj], [3,4,5,obj]);
 *      //=> [1, 2, 3, { a: 1 }, 4, 5]
 */
const union = curry(_union);

export default union;
