import isNil from '../is-nil';

/**
 * Checks if the input value is `not null` nor `undefined`.
 *
 * @example
 *
 *      isNotNil(null);      // => false
 *      isNotNil(undefined); // => false
 *      isNotNil(0);         // => true
 *      isNotNil([]);        // => true
 */
const isNotNil = <T>(value: T): value is NonNullable<T> => !isNil(value);

export default isNotNil;
