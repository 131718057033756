import to from 'await-to-js';
import log from 'loglevel';
import { LS_WDC_MODAL_DISMISSED_KEY } from '~/constants/local-storage';
import { createAction } from '~/utils/store';
import { setLocalItem } from '~/utils/storage';
import { captureAndLogException } from '~/utils/logging';
import * as AssetService from '~/services/asset';
import { FLOW_TYPE_NEW_BOOKING } from '~/constants';
import getLocale from '~/utils/localization/get-locale';
import * as coreBookingActions from '../core-booking/actions';
import * as m from './mutation-types';

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setIsSeatSelectionHasUnconfirmedChangesFromPreviousFlag = createAction(
  m.SET_IS_SEAT_SELECTION_HAS_UNCONFIRMED_CHANGES_FROM_PREVIOUS_FLAG
);

export const showSeatSelectionModal = createAction(m.SHOW_SEAT_SELECTION_MODAL);

export const hideSeatSelectionModal = createAction(m.HIDE_SEAT_SELECTION_MODAL);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setSeatSelectionModalVisibility = createAction(
  m.SET_SEAT_SELECTION_MODAL_VISIBILITY
);

/**
 * @type {(store: Store) => void}
 */
export const showWdcTargetedModal = createAction(m.SHOW_WDC_TARGETED_MODAL);

/**
 * @type {(store: Store) => void}
 */
export const hideWdcTargetedModal = ({ commit }) => {
  commit(m.HIDE_WDC_TARGETED_MODAL);
  setLocalItem(LS_WDC_MODAL_DISMISSED_KEY, true);
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setWdcTargetedModalVisibility = createAction(
  m.SET_WDC_TARGETED_MODAL_VISIBILITY
);

export const toggleIsSummaryOnMobileVisible = createAction(
  m.TOGGLE_IS_SUMMARY_ON_MOBILE_VISIBLE
);

export const setBookingFlowVisibility = createAction(m.UPDATE_BOOKING_FLOW_VISIBILITY);

export const setBookingFlowLoadedState = createAction(m.UPDATE_BOOKING_FLOW_LOADED_STATE);

export const setAcordServiceModalVisibility = createAction(
  m.ACORD_SERVICE_MODAL_VISIBILITY
);

export const setAirportTransferModalVisibility = createAction(
  m.AIRPORT_TRANSFER_MODAL_VISIBILITY
);

export const setModifyServicesModal = createAction(m.MODIFY_SERVICES_MODAL_VISIBILITY);

export const showPaymentFailedModal = createAction(m.SHOW_PAYMENT_FAILED_MODAL);

export const hidePaymentFailedModal = createAction(m.HIDE_PAYMENT_FAILED_MODAL);

export const setSeatSelectionExitIntentModalVisible = createAction(
  m.SET_SEAT_SELECTION_EXIT_INTENT_VISIBILITY
);

export const showPhoneNumberUpdateModal = createAction(m.SHOW_PHONE_NUMBER_UPDATE_MODAL);

export const hidePhoneNumberUpdateModal = createAction(m.HIDE_PHONE_NUMBER_UPDATE_MODAL);

export const setPhoneNumberUpdateModalVisibility = createAction(
  m.SET_PHONE_NUMBER_UPDATE_MODAL_VISIBILITY
);

export const setTravelInsuranceModalVisibility = createAction(
  m.UPDATE_TRAVEL_INSURANCE_INFO_MODAL_VISIBILITY
);

export const setCancellationInsuranceModalVisibility = createAction(
  m.UPDATE_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY
);

export const setTravelAndCancellationInsuranceModalVisibility = createAction(
  m.UPDATE_TRAVEL_AND_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY
);

export const setIsMainPage = (store, isMainPage) => {
  if (isMainPage) {
    coreBookingActions.setFlowType(store, FLOW_TYPE_NEW_BOOKING);
  }
  store.commit(m.SET_IS_MAIN_PAGE, isMainPage);
};

export const setNavigationClassList = createAction(m.SET_NAVIGATION_CLASSLIST);

export const showCaptchaModal = createAction(m.SHOW_CAPTCHA_MODAL);

export const hideCaptchaModal = createAction(m.HIDE_CAPTCHA_MODAL);

export const setCaptchaModalVisibility = createAction(m.SET_CAPTCHA_MODAL_VISIBILITY);

/**
 * @type {(store: Store) => void}
 */
export const showPaymentExitIntentModal = createAction(m.SHOW_PAYMENT_EXIT_INTENT_MODAL);

export const hidePaymentExitIntentModal = createAction(m.HIDE_PAYMENT_EXIT_INTENT_MODAL);

export const showAddFlightExitIntentModal = createAction(
  m.SHOW_ADD_FLIGHT_EXIT_INTENT_MODAL
);

export const hideAddFlightExitIntentModal = createAction(
  m.HIDE_ADD_FLIGHT_EXIT_INTENT_MODAL
);

export const setHeroBannerInfo = createAction(m.SET_HERO_BANNER_INFO);

export const setIsCaptchaHandled = createAction(m.SET_IS_CAPTCHA_HANDLED);

export const unsetIsCaptchaHandled = createAction(m.UNSET_IS_CAPTCHA_HANDLED);

export const setIsRescueFareCodeVisible = createAction(m.SET_IS_RESCUE_FARE_CODE_VISIBLE);

export const setSelectedSearchTab = createAction(m.SET_SELECTED_SEARCH_TAB);

export const dismissYellowRibbon = createAction(m.DISMISS_YELLOW_RIBBON);

export const fetchYellowRibbonMessage = async ({ commit }) => {
  const [error, response] = await to(AssetService.yellowRibbon(getLocale()));

  if (error) return log.warn(`Unable to get yellow ribbon message: ${error}`);

  commit(m.SET_YELLOW_RIBBON_MESSAGE, response.data?.message);
};

export const setIsFlightAdditionRequested = createAction(
  m.SET_IS_FLIGHT_ADDITION_REQUESTED
);

export const fetchCallCenterInformation = async ({ commit }) => {
  const [error, response] = await to(AssetService.callCenterInformation());

  if (error) return captureAndLogException(error);

  commit(m.SET_CALL_CENTER_INFORMATION, response.data)
}