/**
 * Removes (strips) whitespace from both ends of the string.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/trim.js
 *
 * @type {(str: string) => string}
 * @example
 *
 *    trim('   xyz  '); //=> 'xyz'
 *
 */
const trim = (str) => (str || '').trim();

export default trim;
