import curry from '../curry';
import { _pathEq } from '../path-eq';

/**
 * @typedef {(p: string, value: any, obj: Object<string, any>) => boolean} PathNotEqFn
 */

/**
 * @type PathNotEqFn
 */
export const _pathNotEq = (p, value, obj) => !_pathEq(p, value, obj);

/**
 * Determines whether a nested path on an object has a specific value, in
 * `equals` terms. Most likely used to filter a list.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pathEq.js
 *
 * @type PathNotEqFn
 * @example
 *
 *      let user1 = { address: { zipCode: 90210 } };
 *      let user2 = { address: { zipCode: 55555 } };
 *      let user3 = { name: 'Bob' };
 *      let users = [user1, user2, user3];
 *      let isNotFamous = pathNotEq('address.zipCode', 90210);
 *      filter(isNotFamous, users); //=> [user2, user3]
 */
const pathNotEq = curry(_pathNotEq);

export default pathNotEq;
