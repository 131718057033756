import complement from '~/utils/fp/complement';
import isNotNil from '~/utils/object/is-not-nil';
import { isNotServer } from '~/utils/ssr';

export const gtag = (...args) => {
  if (isGtagNotAvailable()) return;
  window.gtag(...args);
};

/**
 * @type {() => boolean}
 */
export const isGtagAvailable = () => (isNotServer() ? isNotNil(window.gtag) : false);

/**
 * @type {() => boolean}
 */
export const isGtagNotAvailable = complement(isGtagAvailable);
