export const SET_ACTIVE_BIO_ARTICLE = 'cms/setActiveBioArticle';
export const RESET_BIO_ARTICLE = 'cms/resetBioArticle';

export const SET_INFORMATION_AND_SERVICES_LIST = 'cms/setInformationAndServicesList';
export const RESET_INFORMATION_AND_SERVICES_LIST = 'cms/resetInformationAndServicesList';

export const SET_TOP_FLIGHTS_LIST = 'cms/setTopFlightsList';
export const RESET_TOP_FLIGHTS_LIST = 'cms/resetTopFlightsList';

export const SET_PAGE_CONTENT = 'cms/setPageContent';
export const RESET_PAGE_CONTENT = 'cms/resetPageContent';

export const SET_HELP_CENTER_SIDE_NAVIGATION_ITEMS =
  'cms/setHelpCenterSideNavigationItems';

export const SET_CURRENT_PAGE = 'cms/setCurrentPage';

export const SET_DESTINATION_CONTENT = 'cms/setDestinationContent';
export const RESET_DESTINATION_CONTENT = 'cms/resetDestinationContent';

export const SET_NEWS = 'cms/setNews';

export const SET_NEWS_CONTENT = 'cms/setNewsContent';
export const RESET_NEWS_CONTENT = 'cms/resetNewsContent';

export const SET_FOOTER_NAVIGATION_ITEMS = 'cms/setFooterNavigationItems';
export const RESET_FOOTER_NAVIGATION_ITEMS = 'cms/resetFooterNavigationItems';

export const SET_PAGE_TRANSLATION_LINKS = 'cms/setPageTranslationLinks';
export const RESET_PAGE_TRANSLATION_LINKS = 'cms/resetPageTranslationLinks';
