import curry from '../curry';
import { _prop } from '../prop';

/**
 * @typedef {<T, K extends keyof T>(p: K, obj: T) => boolean} PropEqTrueFn
 */

/**
 * @type PropEqTrueFn
 */
export const _propEqTrue = (p, obj) => _prop(p, obj) === true;

/**
 * Returns `true` if the specified object property is equal to false, `false` otherwise.
 *
 * @type PropEqTrueFn
 * @example
 *
 *      let abby = {hands: true, hair: false};
 *      let fred = {hands: true, hair: true};
 *      let infants = [abby, fred];
 *      let hasHair = propEqTrue('hair');
 *      filter(hasHair, infants); //=> [fred]
 */
const propEqTrue = curry(_propEqTrue);

export default propEqTrue;
