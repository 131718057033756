import { DIRECTION_OUTBOUND, DIRECTION_RETURN } from '~/constants';
import dec from '~/utils/fp/dec';
import max from '~/utils/fp/max';
import compose from '~/utils/fp/compose';
import inc from '~/utils/fp/inc';
import hevolve from '~/utils/object/hevolve';
import { mutationAssocPath } from '~/utils/store';
import { convertChangedPassenger } from '~/utils/flight-disruption';
import { DISRUPTION_TYPE_NONE } from '~/constants/flight-disruption';
import { getDefaultState } from './internal';
import * as m from './mutation-types';

const REFUND_OPTION_REQUIRED = 'required';

export const mutations = {
  [m.RESET_FLIGHT_DISRUPTION](state) {
    Object.assign(state, getDefaultState());
  },

  [m.INITIALIZE](state, payload) {
    state.disruptionType = payload.disruptionType ?? DISRUPTION_TYPE_NONE;
    state.outboundFlight = payload.outboundFlight ?? {};
    state.returnFlight = payload.returnFlight ?? {};
    state.canRebook = payload.canRebook;
    state.canAcceptNewFlight = payload.canAcceptNewFlight;
    state.canRefund = payload.canRefund;
    state.changedPassengers[DIRECTION_OUTBOUND] = payload.outboundChangedPassengers.map(
      convertChangedPassenger(payload.passengers)
    );
    state.changedPassengers[DIRECTION_RETURN] = payload.returnChangedPassengers.map(
      convertChangedPassenger(payload.passengers)
    );
    state.shouldAcceptCarrierChange = payload.shouldAcceptCarrierChange ?? false;
    state.acceptanceCarrierCode = (payload.acceptanceCarrierCode ?? '').toLowerCase();
    state.refundPrice = payload.refundPrice ?? {};
    state.creditBackBonusPercent =
      Number.parseInt(payload.creditBackBonusPercent, 10) || null;
    state.thirdPartyNeedChange = payload.thirdPartyNeedChange;
  },

  [m.INCREMENT_LOADING_COUNTER]: hevolve({
    loadingCounter: inc,
  }),

  [m.DECREMENT_LOADING_COUNTER]: hevolve({
    loadingCounter: compose(max(0), dec),
  }),

  [m.RESET_ERRORS](state) {
    state.errors = [];
  },

  [m.SET_ERRORS]: mutationAssocPath('errors'),
  [m.SET_FLIGHT_STATUS_CHANGED_STATE]: mutationAssocPath('flightStatusChangedState'),
  [m.SET_SHOULD_RESELECT_SEATS_STATE]: mutationAssocPath('shouldReselectSeats'),
  [m.SET_REBOOK_TO_SEAT_FLIGHT]: mutationAssocPath('rebookToSeatFlight'),
  [m.SET_SEAT_SELECTION_NEEDED_PASSENGER_LIST]: mutationAssocPath(
    'seatSelectionNeededPassengerList'
  ),

  [m.SET_REFUND_OPTIONS](state, data) {
    state.refundOptions = data;

    state.selectedRefundOptions[DIRECTION_OUTBOUND] =
      data.outboundRefundOption === REFUND_OPTION_REQUIRED;
    state.selectedRefundOptions[DIRECTION_RETURN] =
      data.returnRefundOption === REFUND_OPTION_REQUIRED;
  },

  [m.SET_SELECTED_REFUND_OPTION](state, { direction, value }) {
    state.selectedRefundOptions[direction] = value;
  },
};
