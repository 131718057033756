import curry from '../curry';

/**
 * @typedef {<T, U>(a: T, b: U) => T | U} AndFn
 */

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/and.js
 *
 * @type AndFn
 */
export const _and = (a, b) => a && b;

/**
 * @type AndFn
 */
const and = curry(_and);

export default and;
