import {
  SEAT_ASSIGNMENT_ERROR_400_INVALID_OUTBOUND_SEAT_REQUEST,
  SEAT_ASSIGNMENT_ERROR_400_INVALID_RETURN_SEAT_REQUEST,
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_OUTBOUND_SEAT_NOT_AVAILABLE,
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_RETURN_SEAT_NOT_AVAILABLE,
  SEAT_ASSIGNMENT_ERROR_400_NOT_AUTHORIZED_FOR_THE_ACTION,
  SEAT_ASSIGNMENT_ERROR_400_SEAT_CANNOT_BE_MODIFIED_DURING_FARE_LOCKING,
  SEAT_ASSIGNMENT_ERROR_400_SEAT_RESET_ONLY_AVAILABLE_IN_ADD_SERVICES_FLOW,
} from '~/constants';

export const LABEL_PRICE_FREE = 'free';
export const LABEL_PRICE_INCLUDED = 'included';
export const LABEL_PRICE_INCLUDED_IN_SMART = 'included-in-wizz-smart';
export const LABEL_PRICE_INCLUDED_IN_MIDDLE = 'included-in-wizz-middle';
export const LABEL_PRICE_INCLUDED_IN_PLUS = 'included-in-wizz-plus';

export const PREMIUM_SEAT_REFUND_TYPE_CASH = 'Cash';
export const PREMIUM_SEAT_REFUND_TYPE_WIZZ_ACCOUNT = 'WizzAccount';

export const invalidSeatRequestErrors = [
  SEAT_ASSIGNMENT_ERROR_400_INVALID_OUTBOUND_SEAT_REQUEST,
  SEAT_ASSIGNMENT_ERROR_400_INVALID_RETURN_SEAT_REQUEST,
];
export const selectedSeatNotAvailableErrors = [
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_OUTBOUND_SEAT_NOT_AVAILABLE,
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_RETURN_SEAT_NOT_AVAILABLE,
];
export const errorsAffectingSingleFlight = [
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_OUTBOUND_SEAT_NOT_AVAILABLE,
  SEAT_ASSIGNMENT_ERROR_400_SELECTED_RETURN_SEAT_NOT_AVAILABLE,
  SEAT_ASSIGNMENT_ERROR_400_INVALID_OUTBOUND_SEAT_REQUEST,
  SEAT_ASSIGNMENT_ERROR_400_INVALID_RETURN_SEAT_REQUEST,
];
export const errorsAffectingBothFlight__ = [
  SEAT_ASSIGNMENT_ERROR_400_NOT_AUTHORIZED_FOR_THE_ACTION,
  SEAT_ASSIGNMENT_ERROR_400_SEAT_CANNOT_BE_MODIFIED_DURING_FARE_LOCKING,
  SEAT_ASSIGNMENT_ERROR_400_SEAT_RESET_ONLY_AVAILABLE_IN_ADD_SERVICES_FLOW,
];

export const SEAT_SELECTION_MOBILE_BODY_CLASS =
  'body--seat-selection-mobile-summary-visible';

export const SEAT_SELECTION_MOBILE_SELECTION_VISIBLE =
  'body--mobile-seat-selection-visible';

export const SEAT_SELECTION_AIRPLANES_TYPE_TO_FILENAME_MAP = new Map()
  .set('320', 'a320')
  .set('321', 'a321')
  .set('737', 'b737');

export const DEFAULT_SPECIAL_SEAT_ID = '1F';

export const SEAT_SELECTION_SPECIAL_SEAT_POSITION_MAP = new Map()
  .set('737-189', '2F');
