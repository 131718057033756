import curry from '../curry';
import { _path } from '../path';

/**
 * @typedef {(ps: string[], obj: Object<string, any>) => any} PathsFn
 */

/**
 * @type PathsFn
 */
export const _paths = (ps, obj) => {
  if (!obj) return [];
  return ps.map((p) => _path(p, obj));
};

/**
 * Retrieves the values at given paths of an object.
 *
 * @type PathsFn
 * @example
 *
 *      paths(['a.b', 'c'], { a: { b: 2 }, c: 3 }); //=> [2, 3]
 *      paths(['a.b', 'd'], { c: { b: 2 } }); //=> [undefined, undefined]
 *      // we can also use bracket notation (["a"], ['a'] forms are supported too)
 *      paths(['[a][b]', '[c]'], { a: { b: 2 }, c: 3 }); //=> [2, 3]
 *      // we can mix dot and bracket notation
 *      paths(['a[b].c', '[d]'], { a: { b: { c: 3 }, d: 4 } }); //=> [3, 4]
 *      // we can access elements of an array as well
 *      paths(['a.b[0]', 'a.b[2]'], { a: 1, b: [2, 3, 4]}); //=> [2, 4]
 *      // we can access first level array elements via passing in an index as paths
 *      paths([1, 3], [1, 2, 3, 4]) //=> [2, 4]
 */
const paths = curry(_paths);

export default paths;
