import curry from '../curry';
import { _propEq } from '../prop-eq';

/**
 * @typedef {<T, K extends keyof T>(p: K, value: any, obj: T) => boolean} PropNotEqFn
 */

/**
 * @type PropNotEqFn
 */
export const _propNotEq = (p, value, obj) => !_propEq(p, value, obj);

/**
 * Returns `true` if the specified object property is not equal, in
 * `equals` terms, to the given value; `false` otherwise.
 * You can test multiple properties with `where` / `whereEq`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/propEq.js
 *
 * @type PropNotEqFn
 * @example
 *
 *      let abby = {name: 'Abby', age: 7, hair: 'blond'};
 *      let fred = {name: 'Fred', age: 12, hair: 'brown'};
 *      let rusty = {name: 'Rusty', age: 10, hair: 'brown'};
 *      let alois = {name: 'Alois', age: 15, disposition: 'surly'};
 *      let kids = [abby, fred, rusty, alois];
 *      let hasNotBrownHair = propNotEq('hair', 'brown');
 *      filter(hasNotBrownHair, kids); //=> [abby, alois]
 */
const propNotEq = curry(_propNotEq);

export default propNotEq;
