import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import * as gat from '~/store/action-types';
import { getDefaultState } from './internal';
import mutations from './mutations';
import * as actions from './actions';

const moduleName = 'nextFlight';
const state = () => alter(getSessionItemOr({}, moduleName), getDefaultState());

const store = {
  state,
  mutations,
  actions: {
    [gat.AFTER_LOGOUT]: actions.reset,
  },
};

export default store;
