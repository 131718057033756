import curry from '../curry';
import { _sort } from '../sort';

/**
 * @typedef {<T, U>(fn: (a: T) => U, arr: T[]) => T[]} SortByFn
 */

/**
 * @type SortByFn
 */
export const _sortBy = (fn, arr) =>
  _sort((a, b) => {
    const aa = fn(a);
    const bb = fn(b);
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  }, arr);

/**
 * Sorts the list according to the supplied function.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/sortBy.js
 *
 * @type SortByFn
 * @example
 *
 *      let sortByFirstItem = sortBy(prop(0));
 *      let sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));
 *      let pairs = [[-1, 1], [-2, 2], [-3, 3]];
 *      sortByFirstItem(pairs); //=> [[-3, 3], [-2, 2], [-1, 1]]
 *      let alice = {
 *        name: 'ALICE',
 *        age: 101
 *      };
 *      let bob = {
 *        name: 'Bob',
 *        age: -10
 *      };
 *      let clara = {
 *        name: 'clara',
 *        age: 314.159
 *      };
 *      let people = [clara, bob, alice];
 *      sortByNameCaseInsensitive(people); //=> [alice, bob, clara]
 */
const sortBy = curry(_sortBy);

export default sortBy;
