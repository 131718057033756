import log from 'loglevel';
import isEmpty from '~/utils/object/is-empty';
import isNumber from '~/utils/object/is-number';
import { getLocalItemRaw } from '~/utils/storage';
import { LS_GA_DEBUG_KEY } from '~/constants/local-storage';
import { PLATFORM_EWIZZ } from '~/constants/analytics';
import { gtag, isGtagNotAvailable } from '../gtag';
import { getTrackingId } from '../get-tracking-id';

/**
 * @param category        string, optional
 * @param action          optional, defaults to current stripped url
 * @param label           string, some arbitrary text
 * @param nonInteractive  boolean
 * @param value           optional, monetary value of the conversion
 * @param dimensions      optional, custom dimensions
 * @param payload         optional, any additional params to the event
 */
export const sendGaEvent = ({
  category = '',
  action = '',
  label = '',
  nonInteractive = false,
  value = null,
  dimensions = {},
  payload = {},
}) => {
  if (isGtagNotAvailable()) return;
  const data = {
    send_to: getTrackingId(),
    platform: PLATFORM_EWIZZ,
    ...(category && { event_category: category }),
    ...(!isEmpty(label) && { event_label: label }),
    ...(isNumber(value) && { value }),
    ...(nonInteractive && { non_interaction: true }),
    ...(payload && { ...payload }),
  };
  const payLoad = !isEmpty(dimensions) ? { ...data, ...dimensions } : data;
  const args = ['event', action, payLoad];
  if (getLocalItemRaw(LS_GA_DEBUG_KEY)) {
    // do NOT use getLocalItem, this is a dumb switch only
    log.info('analytics', ...args);
  }
  gtag(...args);
};
