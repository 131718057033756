/**
 * @typedef {<T extends any[]>(...predicates: (value: T) => boolean) => (value: T) => boolean} AllPassFn
 */

/**
 * Takes a list of predicates and returns a predicate that returns true for a
 * given list of arguments if every one of the provided predicates is satisfied
 * by those arguments.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/allPass.js
 *
 * @type AllPassFn
 * @example
 *
 *      let isQueen = propEq('rank', 'Q');
 *      let isSpade = propEq('suit', '♠︎');
 *      let isQueenOfSpades = allPass(isQueen, isSpade);
 *
 *      isQueenOfSpades({rank: 'Q', suit: '♣︎'}); //=> false
 *      isQueenOfSpades({rank: 'Q', suit: '♠︎'}); //=> true
 */
const allPass =
  (...predicates) =>
  (...args) =>
    predicates.every((predicate) => predicate(...args));

export default allPass;
