import to from 'await-to-js';

import { createAction } from '~/utils/store';
import asyncDebounce from '~/utils/methods/async-debounce';
import { apiErrors } from '~/utils/services';
import { captureAndLogException, logNewRelicError } from '~/utils/logging';
import { updateBundleAncillaryServices } from '~/utils/booking/bundle';
import { getBundles } from '~/services/asset';
import { submitCar, deleteCar } from '~/services/booking/rentalcars';
import { submitCarTrawlerCar, deleteCarTrawler } from '~/services/booking/car-trawler';
import * as AncillaryService from '~/services/booking/ancillary';
import {
  ACCESSIBILITY_DISABLED,
  ACCESSIBILITY_ENABLED,
  STEP_SELECT_FLIGHT,
} from '~/constants';
import { setCookie, getCookie, getLocalItemRaw } from '~/utils/storage';
import { endOfDay, currentDateAndTime, differenceInSeconds } from '~/utils/date';
import pathOr from '~/utils/fp/path-or';
import isEmpty from '~/utils/object/is-empty';
import isUndefined from '~/utils/object/is-undefined';
import * as coreBookingGetters from '../core-booking/getters';
import * as summaryActions from '../summary/actions';
import * as analyticsActions from '../analytics/actions';
import * as featureGetters from '../feature/getters';
import { CC_FEATURE_NAME_FRONTEND_BACKEND_SYNC_TEST } from '../feature/constants';
import * as getters from './getters';
import { hasChangedAncillaries } from './internal';
import * as m from './mutation-types';

const ANCILLARIES_POST_WAIT_IN_MS = 400;

export const reset = createAction(m.RESET);

export const addSelectSeat = createAction(m.ADD_SELECT_SEAT);

export const addResetFlightSeats = createAction(m.ADD_RESET_FLIGHT_SEATS);

export const updatePaidSeatAssignment = createAction(m.UPDATE_PAID_SEAT_ASSIGNMENT);

export const setPriorityBoardingAdded = createAction(m.SET_PRIORITY_BOARDING_ADDED);

export const getAncillaries = (store, payload = {}) =>
  _ancillaries('getAncillaries', store, payload);

export const getSummaryOnly = (store, payload = {}) =>
  getAncillaries(store, { ...payload, summaryOnly: true });

export const resetAllFlightSeats = (store) =>
  _postAncillaries(store, {
    resetAllOutboundSeats: true,
    resetAllReturnSeats: true,
  });

export const resetOutboundFlightSeats = (store) =>
  _postAncillaries(store, {
    resetAllOutboundSeats: true,
  });

/**
 * @type {(store: Store) => void}
 */
export const toggleAndPostWizzFlex = (store, payload) => {
  const { commit, state } = store;
  if (isEmpty(payload)) payload = { withSummary: true };

  commit(m.TOGGLE_WIZZ_FLEX);

  if (getters.isWizzFlexSelected(state)) {
    analyticsActions.setWizzFlexPurchaseLocation(store, STEP_SELECT_FLIGHT);
  } else {
    analyticsActions.setWizzFlexPurchaseLocation(store, '');
  }
  return debouncedPostAncillariesIfNotPending(store, payload);
};

/**
 * @type {(store: Store) => void}
 */
export const setAndPostWizzFlex = (store, payload) => {
  const { commit, state } = store;
  if (isEmpty(payload)) payload = { withSummary: true };

  commit(m.SET_WIZZ_FLEX, payload);

  analyticsActions.setWizzFlexPurchaseLocation(
    store,
    getters.isWizzFlexSelected(state) ? STEP_SELECT_FLIGHT : ''
  );
  return debouncedPostAncillariesIfNotPending(store, {
    withSummary: true,
    disallowAutoUpdateOfFlightsAndTotal: true,
  });
};

/**
 * @type {(store: Store) => void}
 */
export const addAndPostWizzFlex = (store, payload) => {
  const { commit, state } = store;
  if (isEmpty(payload)) payload = { withSummary: true };
  const { force = false } = payload;
  if (getters.isWizzFlexSelected(state) && !force) return Promise.resolve();

  commit(m.ADD_WIZZ_FLEX);
  return debouncedPostAncillariesIfNotPending(store, payload);
};

/**
 * @type {(store: Store) => void}
 */
export const removeAndPostWizzFlex = (store, payload) => {
  const { commit, state } = store;
  if (isEmpty(payload)) payload = { withSummary: true };
  if (!getters.isWizzFlexSelected(state)) return Promise.resolve();
  commit(m.REMOVE_WIZZ_FLEX);
  return debouncedPostAncillariesIfNotPending(store, payload);
};

/**
 * @type {(store: Store, payload: Object<string, any>, debounced: Boolean) => void}
 */
export const handleAncillaries = (store, payload, debounced = true) => {
  const { commit } = store;
  commit(m.SET_UNSAVED_ANCILLARIES, payload);
  commit(m.SET_SELECTED_OPTION, payload);

  return debounced
    ? debouncedPostAncillariesIfNotPending(store)
    : postAncillaries(store, payload);
};

/**
 * @type {(store: Store) => void}
 */
const debouncedPostAncillariesIfNotPending = (store, payload) => {
  const { state } = store;
  if (getters.hasNoUnsavedAncillaries(state)) return Promise.resolve();
  if (getters.isRawAncillaryPostPending(state))
    return getters.debouncedPostAncillariesPromise(state);
  return debouncedPostAncillaries(store, payload);
};

export const restoreOriginalSeatSelection = async (store, payload) => {
  const { commit } = store;
  commit(m.SET_UNSAVED_ANCILLARIES, payload);
  await postAncillaries(store, { withSummary: true });
};

export const postAncillaries = async (store, payload) => {
  if (isEmpty(payload)) payload = { withSummary: true };
  const { state, commit } = store;
  const unsavedAncillaries = getters.unsavedAncillaries(state);
  commit(m.CLEAR_UNSAVED_ANCILLARIES);
  const [error] = await to(
    _postAncillaries(store, { ...unsavedAncillaries, ...payload })
  );
  const hasNewUnsavedAncillaries = getters.hasUnsavedAncillaries(state);

  if (error && !hasNewUnsavedAncillaries) throw error;

  if (error && hasNewUnsavedAncillaries)
    commit(m.SET_UNSAVED_ANCILLARIES_TO_ALL_UNSAVED_ANCILLARIES);

  if (!error && !hasNewUnsavedAncillaries) commit(m.CLEAR_ALL_UNSAVED_ANCILLARIES);

  if (!hasNewUnsavedAncillaries) return;

  return debouncedPostAncillaries(store, payload);
};

const debouncedPostAncillaries = (store, payload) => {
  const finallyAction = () => {
    commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
    commit(m.RESET_DEBOUNCED_POST_ANCILLARIES_PROMISE);
  };

  const { commit, state } = store;
  const prevPromise = getters.debouncedPostAncillariesPromise(state);
  const newPromise = _debouncedPostAncillaries(store, payload);
  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);

  if (newPromise !== prevPromise) {
    commit(m.SET_DEBOUNCED_POST_ANCILLARIES_PROMISE, newPromise);
    newPromise.then(finallyAction, finallyAction);
  }
  return newPromise;
};

const _debouncedPostAncillaries = asyncDebounce(
  postAncillaries,
  ANCILLARIES_POST_WAIT_IN_MS
);

const _postAncillaries = async (store, payload = {}) => {
  if (!hasChangedAncillaries(payload)) return;

  const { commit } = store;

  commit(m.SET_IS_RAW_ANCILLARY_POST_PENDING, true);
  const [error] = await to(_ancillaries('postAncillaries', store, payload));
  commit(m.SET_IS_RAW_ANCILLARY_POST_PENDING, false);
  if (error) throw error;
};

export const modifyPrivilegePassAncillaries = ({ commit }, data) => {
  commit(m.MODIFY_PRIVILEGE_PASS_ANCILLARIES, data);
};

export const submitCarRental = async (store, payload) => {
  const { commit, state } = store;
  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [submitCarError, submitCarResponse] = await to(submitCar(payload));
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (submitCarError) throw submitCarError;

  const shouldChangeCurrency = submitCarResponse.data.notSupportedCurrencies.includes(
    paymentCurrencyCode(state)
  );

  if (shouldChangeCurrency) {
    await summaryActions.changePaymentCurrency(store, {
      currencyCode: bookingCurrencyCode(state),
    });
  }

  commit(m.SET_IS_FORCED_CURRENCY_CHANGE, shouldChangeCurrency);

  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [getAncillariesError] = await to(getAncillaries(store, { withSummary: true }));
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (getAncillariesError) throw getAncillariesError;

  commit(m.SET_CAR_RENTAL_UNSUPPORTED_CURRENCIES, submitCarResponse.data);
};

export const submitCarTrawler = async (store, payload) => {
  const { commit, state } = store;
  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [submitCarTrawlerError, submitCarTrawlerResponse] = await to(
    submitCarTrawlerCar(payload)
  );
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (submitCarTrawlerError) throw submitCarTrawlerError;

  const shouldChangeCurrency =
    submitCarTrawlerResponse.data.notSupportedCurrencies.includes(
      paymentCurrencyCode(state)
    );

  if (shouldChangeCurrency) {
    await summaryActions.changePaymentCurrency(store, {
      currencyCode: bookingCurrencyCode(state),
    });
  }

  commit(m.SET_IS_FORCED_CURRENCY_CHANGE, shouldChangeCurrency);

  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [getAncillariesError] = await to(getAncillaries(store, { withSummary: true }));
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (getAncillariesError) throw getAncillariesError;

  commit(m.SET_CAR_TRAWLER_UNSUPPORTED_CURRENCIES, submitCarTrawlerResponse.data);
};

export const copyPassengerAncillariesToAllPassengers = createAction(
  m.COPY_PASSENGER_ANCILLARIES_TO_ALL_PASSENGERS
);

export const removeCarRental = async (store) => {
  const { commit } = store;
  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [removeRentalCarError] = await to(deleteCar());
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (removeRentalCarError) throw removeRentalCarError;

  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [getAncillariesError] = await to(getAncillaries(store, { withSummary: true }));
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (getAncillariesError) throw getAncillariesError;

  commit(m.CAR_RENTAL_RESET_UNSUPPORTED_CURRENCIES);
};

export const removeCarTrawler = async (store) => {
  const { commit } = store;
  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [removeCarTrawlerError] = await to(deleteCarTrawler());
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (removeCarTrawlerError) throw removeCarTrawlerError;

  commit(m.SET_IS_ANCILLARY_POST_PENDING, true);
  const [getAncillariesError] = await to(getAncillaries(store, { withSummary: true }));
  commit(m.SET_IS_ANCILLARY_POST_PENDING, false);
  if (getAncillariesError) throw getAncillariesError;

  commit(m.CAR_TRAWLER_RESET_UNSUPPORTED_CURRENCIES);
};

const _ancillaries = async (method, store, payload = {}) => {
  const { commit, state } = store;
  const {
    withSummary = false,
    summaryOnly = false,
    disallowAutoUpdateOfFlightsAndTotal = false,
  } = payload;
  const shouldUpdateSummary = withSummary || summaryOnly;

  // note: we will see if this is a good idea
  const exchangeCurrencyCode =
    paymentCurrencyCode(state) !== bookingCurrencyCode(state)
      ? paymentCurrencyCode(state)
      : null;

  const response =
    (await AncillaryService[method]({ exchangeCurrencyCode, ...payload })) || {};

  // note: possibly null data handled in called actions and mutations
  const { data } = response;
  if (shouldUpdateSummary) {
    summaryActions.update(store, {
      disallowAutoUpdateOfFlightsAndTotal,
      ...(data ? data.priceDetails || {} : {}),
      bookingCurrencyCode: bookingCurrencyCode(state),
      paymentCurrencyCode: paymentCurrencyCode(state),
    });
  }

  validateFrontendBackendSyncTest(store, data?.featureToggleSyncValue);

  if (summaryOnly) return;

  commit(m.INITIALIZE_ANCILLARIES, data);
};

const validateFrontendBackendSyncTest = ({ state }, backendFeatureValue) => {
  const frontendFeatureValue = featureGetters.getConfigCatFeatureValue(
    state,
    CC_FEATURE_NAME_FRONTEND_BACKEND_SYNC_TEST
  );

  if (frontendFeatureValue !== backendFeatureValue) {
    const { eTag, timeStamp } = JSON.parse(getLocalItemRaw('configcat-meta') || '{}');
    logNewRelicError(
      `CC Feature mismatch for ${CC_FEATURE_NAME_FRONTEND_BACKEND_SYNC_TEST}: FE: ${frontendFeatureValue} (eTag:${eTag}, timestamp: ${timeStamp}) BE: ${backendFeatureValue}`
    );
  }
};

export const getAirportParking = async ({ commit, state }, payload) => {
  if (
    (payload?.isForced && getters.isAirportParkingAlreadyPurchased(state)) ||
    (!payload?.isForced &&
      (getters.isAirportParkingAlreadyPurchased(state) ||
        getters.isAirportParkingDisabledInAncillaries(state)))
  ) {
    return;
  }

  const [error, response] = await to(AncillaryService.getAirportParking());
  // todo: move this up as much as possible
  if (error) return captureAndLogException(error);

  const airportParking = response.data.airportParking;
  commit(m.UPDATE_AIRPORT_PARKING, airportParking);
};

export const selectAirportParking = ({ commit }, payload = {}) => {
  commit(m.SET_SELECTED_AIRPORT_PARKING, payload);
};

export const fetchLoungeAccessV2 = async ({ commit }) => {
  commit(m.SET_LOUNGE_ACCESS_V2_LOADING, true);
  const [error, response] = await to(AncillaryService.fetchLoungeAccessV2());
  commit(m.SET_LOUNGE_ACCESS_V2_LOADING, false);

  // todo: move this up as much as possible
  if (error) return captureAndLogException(error);

  commit(m.SET_LOUNGE_ACCESS_V2_DATA, response.data);
};

export const fetchFastTrackSecurityV2 = async ({ commit }) => {
  commit(m.SET_FAST_TRACK_SECURITY_V2_LOADING, true);
  const [error, response] = await to(AncillaryService.getSecurityFastTrackV2());
  commit(m.SET_FAST_TRACK_SECURITY_V2_LOADING, false);

  // todo: move this up as much as possible
  if (error) return captureAndLogException(error);

  commit(m.SET_FAST_TRACK_SECURITY_V2_DATA, response.data);
};

export const removeAirportParkingService = ({ commit }) => {
  commit(m.SET_SELECTED_AIRPORT_PARKING, {
    selectedOperator: [],
    selectedDropOffDateTime: '',
    selectedPickUpDateTime: '',
    plateNumber: '',
  });
  commit(m.SET_AIRPORT_PARKING_ACCESSIBILITY, ACCESSIBILITY_DISABLED);
  commit(m.CLEAR_AIRPORT_PARKING_ERRORS);
};

export const updatePlateNumber = ({ commit }, payload) => {
  commit(m.UPDATE_AIRPORT_PARKING_PLATE_NUMBER, payload);
};

const _updateAirportParkingDateTime = async (store, payload = {}) => {
  const { commit } = store;
  const { startDate, endDate } = payload;

  commit(m.UPDATE_AIRPORT_PARKING_DATES, { startDate, endDate });

  commit(m.CLEAR_AIRPORT_PARKING_ERRORS);

  commit(m.SET_AIRPORT_PARKING_IS_LOADING);
  const [error, response] = await to(
    AncillaryService.changeAirportParking({ startDate, endDate })
  );
  commit(m.UNSET_AIRPORT_PARKING_IS_LOADING);

  if (error) {
    apiErrors(error).forEach((errorCode) => {
      commit(m.UPDATE_AIRPORT_PARKING_ERRORS, errorCode);
    });
    return;
  }

  const { options, accessibility } = response.data.airportParking;

  if (isEmpty(options) && accessibility === ACCESSIBILITY_DISABLED) {
    commit(m.UPDATE_AIRPORT_PARKING_ERRORS, 'AirportParkingNotAvailable');
  } else if (isEmpty(options) && accessibility === ACCESSIBILITY_ENABLED) {
    // todo: create or find suitable error message
    commit(m.UPDATE_AIRPORT_PARKING_ERRORS, 'AirportParkingNoResultsFound');
  }

  commit(m.UPDATE_AIRPORT_PARKING_OPTIONS, { options });
};

export const updateAirportParkingDateTime = asyncDebounce(
  _updateAirportParkingDateTime,
  500
);

export const updateAirportParkingTempOptions = ({ commit }) => {
  commit(m.UPDATE_AIRPORT_PARKING_TEMP_OPTIONS);
};

/**
 * @type {(state: {}) => string}
 */
const bookingCurrencyCode = coreBookingGetters.bookingCurrencyCode;

/**
 * @type {(state: {}) => string}
 */
const paymentCurrencyCode = coreBookingGetters.paymentCurrencyCode;

export const fetchBundlesForUpsell = async (store) => {
  const data = { params: { isPostBooking: false } };
  const response = await getBundles(data);
  const bundles = pathOr([], 'data.bundles', response);
  saveBundles(store, updateBundleAncillaryServices(bundles));
};

export const saveBundles = createAction(m.SET_BUNDLES);

export const setWdcSaving = createAction(m.SET_WDC_SAVING);

export const cleanLoungeAccessV2 = ({ commit }) =>
  commit(m.SET_LOUNGE_ACCESS_V2_DATA, null);

export const cleanFastTrackSecurityV2 = ({ commit }) =>
  commit(m.SET_FAST_TRACK_SECURITY_V2_DATA, null);

export const initializeFlashPromos = async (store) => {
  const { state, commit } = store;

  commit(m.RESET_FLASH_PROMOS);
  await fetchAncillaryFlashPromos(store);

  const flashPromos = getters.flashPromos(state);
  if (isEmpty(flashPromos)) {
    resetFlashPromoValidUntil(store);
    return disableFlashPromo(store);
  }

  // tell BE to activate flashPromos for this route in this session
  const [error] = await to(
    AncillaryService.postAncillaryFlashPromos({
      isPromoEnabled: true,
      integrationIds: flashPromos,
    })
  );

  if (error) {
    resetFlashPromoValidUntil(store);
    return disableFlashPromo(store);
  }

  setFlashPromoValidUntil(store);

  const secondsUntilEndOfDay = differenceInSeconds(
    endOfDay(currentDateAndTime()),
    currentDateAndTime()
  );

  flashPromos.forEach((integrationId) => {
    setCookie(`flashPromo-${integrationId}`, '1', { maxAge: secondsUntilEndOfDay });
  });
  startFlashPromo(store);
};

export const resetFlashPromos = createAction(m.RESET_FLASH_PROMOS);

export const invalidateFlashPromos = async (store) => {
  const { state } = store;

  await AncillaryService.postAncillaryFlashPromos({
    isPromoEnabled: false,
    integrationIds: getters.flashPromos(state),
  });

  resetFlashPromoValidUntil(store);
  inactivateFlashPromo(store);
};

const fetchAncillaryFlashPromos = async ({ commit }) => {
  const [error, response] = await to(AncillaryService.getAncillaryFlashPromos());
  if (error) return; // maybe log something?

  const integrationIds = response.data.integrationIds;
  if (isEmpty(integrationIds)) return;

  const flashPromosToActivate = integrationIds.filter((id) => {
    const promo = getCookie(`flashPromo-${id}`);
    return isUndefined(promo);
  });

  commit(m.SET_FLASH_PROMOS, flashPromosToActivate);
};

const setFlashPromoValidUntil = createAction(m.SET_FLASH_PROMO_VALID_UNTIL);
const resetFlashPromoValidUntil = createAction(m.RESET_FLASH_PROMO_VALID_UNTIL);

const startFlashPromo = createAction(m.SET_FLASH_PROMO_STATUS_RUNNING);

const disableFlashPromo = createAction(m.SET_FLASH_PROMO_STATUS_DISABLED);

const inactivateFlashPromo = createAction(m.SET_FLASH_PROMO_STATUS_INACTIVE);

export const activateFlashPromo = createAction(m.SET_FLASH_PROMO_STATUS_ACTIVATED);

export const addPreviouslySelectedOutboundSeatCode = ({ commit }, payload) => {
  commit(m.ADD_PREVIOUSLY_SELECTED_OUTBOUND_SEAT_CODE, payload);
};

export const addPreviouslySelectedReturnSeatCode = ({ commit }, payload) => {
  commit(m.ADD_PREVIOUSLY_SELECTED_RETURN_SEAT_CODE, payload);
};

export const setSelectedCarTrawler = ({ commit }, payload) => {
  commit(m.SET_SELECTED_CAR_TRAWLER_PRODUCT, payload);
};

export const resetSelectedCarTrawler = ({ commit }) => {
  commit(m.SET_SELECTED_CAR_TRAWLER_PRODUCT, {});
};
