import { getDefaultState } from './internal';
import { mutations } from './mutations';

const state = getDefaultState();

const store = {
  state,
  mutations,
};

export default store;
