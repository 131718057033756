import both from '~/utils/fp/both';
import areSeatsInTheSameRow from '../are-seats-in-the-same-row';
import areSeatsOnTheSameSide from '../are-seats-on-the-same-side';

/**
 * @param {Object} seatA
 * @param {Object} seatB
 * @returns {Boolean}
 */
const areAdjacentSeats = both(areSeatsInTheSameRow, areSeatsOnTheSameSide);

export default areAdjacentSeats;
