import curryN from '../curry-n';

/**
 * @typedef {{(fn: Function) => Function}} FlipFn
 */

/**
 * note: this one returns a func which is not curried automatically
 *
 * @type FlipFn
 */
export const _flip =
  (fn) =>
  (...args) => {
    const [a, b] = args;
    args[0] = b;
    args[1] = a;
    return fn(...args);
  };

/**
 * Returns a new function much like the supplied one, except that the first two
 * arguments' order is reversed.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/flip.js
 *
 * @type FlipFn
 * @example
 *
 *      let mergeThree = (a, b, c) => [].concat(a, b, c);
 *
 *      mergeThree(1, 2, 3); //=> [1, 2, 3]
 *
 *      flip(mergeThree)(1, 2, 3); //=> [2, 1, 3]
 */
const flip = (fn) =>
  curryN(fn.length, (...args) => {
    const [a, b] = args;
    args[0] = b;
    args[1] = a;
    return fn(...args);
  });

export default flip;
