import path from '~/utils/fp/path';
import curry from '~/utils/fp/curry';
import compose from '~/utils/fp/compose';
import filter from '~/utils/fp/filter';
import map from '~/utils/fp/map';
import pluck from '~/utils/fp/pluck';
import reject from '~/utils/fp/reject';
import length from '~/utils/fp/length';
import propEq from '~/utils/fp/prop-eq';
import some from '~/utils/fp/some';
import propEqTrue from '~/utils/fp/prop-eq-true';
import isNotEmpty from '~/utils/object/is-not-empty';
import { isInfant, isAdult, isNotInfant } from '~/utils/booking/passenger';

const passengersProp = curry((prop, state) => path(prop, state.passengers));

const isReducedMobility = ({ prm = false, reducedMobility = {} } = {}) => {
  return prm || Object.values(reducedMobility).some((option) => option);
};

export const infantsCanBeMoved = passengersProp('infantsCanBeMoved');

/**
 * @type {(state: State) => []}
 */
export const passengers = passengersProp('passengers');

export const passengersWithInfant = (state) =>
  compose(
    map((passenger) => ({
      ...passenger,
      infant:
        infants(state).find((infant) =>
          propEq('passengerNumber', infant.passengerAdultNumber)
        ) ?? null,
    })),
    filter(isNotInfant),
    passengers
  )(state);

/**
 * @type {(state: State) => Object<string, any>[]}
 */
export const passengersWithPrivilegePass = compose(
  filter(propEq('privilegePassActivated', true)),
  passengers
);

/**
 * @type {(state: State) => []}
 */
export const reducedMobilityPassengers = compose(filter(isReducedMobility), passengers);

/**
 * @type {(state: State) => number}
 */
export const numberOfPassengersWithPrivilegePass = compose(
  length,
  passengersWithPrivilegePass
);

/**
 * @type {(state: State) => []}
 */
export const independentPassengers = compose(reject(isInfant), passengers);

export const adultPassengers = compose(filter(isAdult), passengers);

export const infants = compose(filter(isInfant), passengers);

/**
 * @type {(state: State) => number}
 */
export const numberOfPassengers = compose(length, passengers);

/**
 * @type {(state: State) => number}
 */
export const numberOfIndependentPassengers = compose(length, independentPassengers);

export const independentPassengerNumbers = compose(
  pluck('passengerNumber'),
  independentPassengers
);

export const adultPassengerNumbers = compose(pluck('passengerNumber'), adultPassengers);

export const savedPassengerDetails = passengersProp('savedPassengerDetails');

export const hasAnyPassengerPrivilegePassActivated = compose(
  some(propEqTrue('privilegePassActivated')),
  passengersProp('passengers')
);

export const iamTravelling = (state) => {
  const passengers = passengersProp('passengers')(state);
  return isNotEmpty(passengers) ? passengers[0].iamTravelling : false;
};
