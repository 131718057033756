export const LS_FEATURE_FLAGS_DEBUG_KEY = 'featureFlags';
export const LS_FORCE_CAPTCHA_DEBUG_KEY = 'forceCaptcha';
export const LS_NO_TIMEOUT_MODAL_DEBUG_KEY = 'noTimeoutModal';
export const LS_WDC_MODAL_DISMISSED_KEY = 'wdcModalDismissed';
export const LS_WIZZ_OPENED_PAGES_COUNTER = 'wizz-pages-open';
export const LS_KOBALOS_KEEP_SESSION_ALIVE = 'kobalos_keepSessionAlive';
export const LS_BOOKING_COM_POPUNDER_DISABLED = 'kobalos_disableBookingComPopunder';
export const LS_GA_DEBUG_KEY = 'gaDebug';
export const LS_IS_LOYAL_CUSTOMER = 'isLoyalCustomer';
export const LS_GEOGA_DEBUG_KEY = 'noGeoBannerGa';
export const LS_EXPOSURE_DEBUG_KEY = 'exposeInternals';
export const LS_TIMEOUT_WARNING_DEBUG_KEY = 'noTimeoutWarning';
export const LS_DISABLE_CAR_RENTAL_DEBUG_KEY = 'noCarRental';
export const LS_DISABLE_CAR_TRAWLER_DEBUG_KEY = 'noCarTrawler';
export const LS_LOGIN_LOGOUT_FEEDBACK_MODAL_DATA_KEY = 'logoutProfileModalData';
export const LS_LOGIN_LOGOUT_FEEDBACK_RATING_KEY = 'login-logout-feedback-voted';
export const BOOKING_FLOW_FEEDBACK_MODAL_RATING_KEY = 'bookingFlowFeedbackRating';
export const CHECKIN_FLOW_FEEDBACK_MODAL_RATING_KEY = 'checkinFlowFeedbackRating';
export const LS_KOBALOS_LANGUAGES = 'kobalos_languages';
export const LS_COOKIE_POLICY_ACCEPTED = 'gdpr-cookie-policy-accepted';
export const LS_PREVIOUS_SEARCHES = 'previousSearches';
export const LS_RECENT_FLIGHT_SEARCHES = 'recentFlightSearches';
