import curry from '../curry';

/**
 * @typedef {<T, U>(a: T, b: T) => U } ConcatFn
 */

/**
 * @type ConcatFn
 */
export const _concat = (a, b) => a.concat(b);

/**
 * Returns the result of concatenating the given lists or strings.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/concat.js
 *
 * @type ConcatFn
 * @example
 *
 *
 *      concat('ABC', 'DEF'); // 'ABCDEF'
 *      concat([4, 5, 6], [1, 2, 3]); //=> [4, 5, 6, 1, 2, 3]
 *      concat([], []); //=> []
 */
const concat = curry(_concat);

export default concat;
