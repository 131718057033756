import isNotEmpty from '~/utils/object/is-not-empty';
import { DEFAULT_CURRENCY_CODE } from '../gift-voucher-selector/internal';
import { FLASH_PROMO_STATUS_DISABLED } from './constants';

export const ANCILLARY_TYPE_RESET_SEATS = 'resetSeats';
export const ANCILLARY_TYPE_SELECT_SEAT = 'selectSeat';

// (including front row and extra legroom seats)
export const ANCILLARY_PREMIUM_SEAT_LABEL_KEY = 'bundle-ancillary-seat-selection';
export const ANCILLARY_PREMIUM_SEAT_LABEL_KEY_SMART =
  'bundle-ancillary-seat-selection-smartab';
// (excluding front row and extra legroom seats)
export const ANCILLARY_REGULAR_SEAT_LABEL_KEY =
  'bundle-ancillary-seat-selection-excluding-premium-seats';
export const ANCILLARY_REGULAR_SEAT_LABEL_KEY_SMART =
  'bundle-ancillary-seat-selection-normal-smartab';
export const ANCILLARY_ULTRALIGHT_CHECKED_IN_LABEL_KEY =
  'bundle-ancillary-ultralight-checked-in-baggage';
export const ANCILLARY_HEAVY_CHECKED_IN_LABEL_KEY =
  'bundle-ancillary-heavy-checked-in-baggage';

export const getDefaultState = () => ({
  passengers: [],
  airportParking: {
    selectedOperator: [],
    selectedDropOffDateTime: '',
    selectedPickUpDateTime: '',
    plateNumber: '',
    options: [],
    isLoading: false,
    errors: [],
  },

  services: {
    bookingLevelServices: {},
    outboundFlightServices: {},
    returnFlightServices: {},
  },

  outboundFlight: {
    paidSeatAssignments: {},
    seatPrices: {},
    seatAssignmentResult: '',
  },

  returnFlight: {
    paidSeatAssignments: {},
    seatPrices: {},
    seatAssignmentResult: '',
  },

  priceDetails: {
    wdcMembershipUpgradeChoice: {},
    passengerExtraCosts: {
      passengerList: [],
      total: { amount: 0, currencyCode: DEFAULT_CURRENCY_CODE },
    },
  },

  isWdcSavingSet: false,

  unsavedAncillaries: getDefaultUnsavedAncillaries(),

  allUnsavedAncillaries: getDefaultUnsavedAncillaries(),

  isAncillaryPostPending: false,

  isRawAncillaryPostPending: false,

  debouncedPostAncillariesPromise: Promise.resolve(),

  // todo find a better "home" for this property
  isPriorityBoardingAlreadyAdded: null,

  unsupportedCarRentalCurrencies: [],
  unsupportedCarTrawlerCurrencies: [],
  selectedCarTrawler: {},
  isForcedCurrencyChange: false,

  bundles: [],

  loungeAccessV2: {
    isLoading: false,
    data: null,
  },

  fastTrackSecurityV2: {
    isLoading: false,
    data: null,
  },

  wizzSafeBanners: [],

  flashPromo: {
    validUntil: null,
    status: FLASH_PROMO_STATUS_DISABLED,
    promotionIds: [],
  },

  previouslySelectedSeats: { outboundSeats: [], returnSeats: [] },
  activatedMemberships: {
    wdc: false,
    privilegePass: false,
  },
});

export const getDefaultUnsavedAncillaries = () => ({
  // NOTE: we don't want to add these as defaults cause anci POST would add them
  //  as-is which may cause some unwanted BE errors
  // isGroupBooking: false,
  // withSummary: false,
  // summaryOnly: false,
  // exchangeCurrencyCode: null,
  // resetAllOutboundSeats: false,
  // resetAllReturnSeats: false,
  passengers: [],
  services: {},
  seatSelection: [],
});

export const hasChangedAncillaries = (obj = {}) => {
  return (
    Boolean(obj.resetAllOutboundSeats) ||
    Boolean(obj.resetAllReturnSeats) ||
    isNotEmpty(obj.passengers) ||
    isNotEmpty(obj.services) ||
    isNotEmpty(obj.seatSelection)
  );
};

export const selectedFlightPriorityBoardingCount = (ancillaries, direction) =>
  ancillaries.passengers.reduce((count, passenger) => {
    if (
      passenger[direction] &&
      !passenger[direction].priorityBoarding.default &&
      passenger[direction].priorityBoarding.selected
    ) {
      count += 1;
    }

    return count;
  }, 0);
