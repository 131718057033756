import {
  COUNTRY_CODE_ITALY,
  WDDC_MEMBERSHIP_STANDARD_ITALY,
  WDDC_MEMBERSHIP_GROUP_ITALY,
  WDC_MEMBERSHIP_UPGRADE,
  WDC_MEMBERSHIP_NAME_MAP,
  WDC_MEMBERSHIP_NAME_MAP_REDESIGNED,
} from '~/constants';

export const hasWdcOnlyFares = (flights) => {
  const fares = flights.reduce((acc, flight) => acc.concat(flight.fares), []); // flights may be an empty array
  return fares.length > 0 ? fares.every((f) => f.wdc) : false;
};

export const isCountryCodeItaly = (countryCode) =>
  countryCode?.toLowerCase() === COUNTRY_CODE_ITALY;

export const isGroupMembership = (membership) =>
  membership.toLowerCase().includes('group');

export const isDomesticWdc = (membership) =>
  [WDDC_MEMBERSHIP_STANDARD_ITALY, WDDC_MEMBERSHIP_GROUP_ITALY].includes(membership);

export const ecommerceVariant = (
  isWdcPremiumEnabled = false,
  isStandardWdcUser,
  membership
) =>
  isWdcPremiumEnabled
    ? WDC_MEMBERSHIP_NAME_MAP_REDESIGNED.get(membership)
    : isStandardWdcUser && isGroupMembership(membership)
    ? WDC_MEMBERSHIP_UPGRADE
    : WDC_MEMBERSHIP_NAME_MAP.get(membership);
