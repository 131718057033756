import axios from 'axios';
import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  STATION_ARRIVAL,
  STATION_DEPARTURE,
} from '~/constants';
import {
  SPECIFIC_TYPE_WDC_MEMBERSHIP,
  SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY,
} from '~/constants/specific-type';
import curry from '~/utils/fp/curry';
import both from '~/utils/fp/both';
import propEq from '~/utils/fp/prop-eq';
import complement from '~/utils/fp/complement';
import whereEq from '~/utils/fp/where-eq';
import isNotUndefined from '~/utils/object/is-not-undefined';
import isNotNil from '~/utils/object/is-not-nil';
import { getDefaultPrice } from '~/utils/price';
import { getShortName } from '~/utils/resource';
import { mapSpecificTypeToInvoiceLabel } from '~/utils/booking/summary';
import { getI18n } from '~/i18n';
import { BOOKING_PRICE_DETAILS } from '~/constants/api-endpoint';
import anyPass from '~/utils/fp/any-pass';

export const getPriceDetails = async (stations) => {
  // don't cache this without a unique url
  const response = await axios.get(BOOKING_PRICE_DETAILS);
  return priceDetailsFrom(stations, response);
};

const priceDetailsFrom = (resourcesStations, response) => {
  const { flights, passengers, services, fees, seats, totals } = response.data;

  // used for naming only, doesn't matter if its outbound or return
  const stations = {
    departure: flights.outbound.departureStation,
    arrival: flights.outbound.arrivalStation,
  };

  const outboundFees = [
    ...flightFeesFrom(resourcesStations, flights, OUTBOUND_FLIGHT_NAME),
    ...ancillaryFeesFrom(resourcesStations, services, OUTBOUND_FLIGHT_NAME),
    ...passengerAncillaryFeesFrom(
      resourcesStations,
      passengers.passengersList,
      OUTBOUND_FLIGHT_NAME
    ),
    ...passengerAncillaryFeesFrom(
      resourcesStations,
      seats.passengersList,
      OUTBOUND_FLIGHT_NAME
    ),
  ];

  const returnFees = [
    ...flightFeesFrom(resourcesStations, flights, RETURN_FLIGHT_NAME),
    ...ancillaryFeesFrom(resourcesStations, services, RETURN_FLIGHT_NAME),
    ...passengerAncillaryFeesFrom(
      resourcesStations,
      passengers.passengersList,
      RETURN_FLIGHT_NAME
    ),
    ...passengerAncillaryFeesFrom(
      resourcesStations,
      seats.passengersList,
      RETURN_FLIGHT_NAME
    ),
  ];

  const globalFees = [
    ...globalFlightFeesFrom(resourcesStations, flights),
    ...ancillaryFeesFrom(resourcesStations, services, 'globals', stations),
    ...passengerAncillaryFeesFrom(
      resourcesStations,
      passengers.passengersList,
      'globals'
    ),
    ...ancillaryFeesFrom(resourcesStations, fees, OUTBOUND_FLIGHT_NAME),
  ];

  return {
    [OUTBOUND_FLIGHT_NAME]: {
      bundle: flights?.outbound?.bundle,
      fromIata: iataFrom(OUTBOUND_FLIGHT_NAME, flights, 'departureStation'),
      toIata: iataFrom(OUTBOUND_FLIGHT_NAME, flights, 'arrivalStation'),
      fees: outboundFees,
      total: totalFrom(outboundFees, totals?.outbound?.currencyCode),
    },

    [RETURN_FLIGHT_NAME]: {
      bundle: flights?.return?.bundle,
      fromIata: iataFrom(RETURN_FLIGHT_NAME, flights, 'departureStation'),
      toIata: iataFrom(RETURN_FLIGHT_NAME, flights, 'arrivalStation'),
      fees: returnFees,
      total: totalFrom(returnFees, totals?.return?.currencyCode),
    },

    globals: {
      label: getI18n().t('price-details-other-fees'),
      fees: globalFees,
      total: totalFrom(globalFees, totals?.fees?.currencyCode),
    },
  };
};

const flightFeesFrom = (resourcesStations, flights, direction) => {
  const flight = flights[direction];
  if (!flight) return [];

  const {
    bundle,
    discountPrice = getDefaultPrice(),
    promotedPrice,
    displayCount,
    fees = [],
    price = getDefaultPrice(),
  } = flight;
  const flightFees = fees
    .filter(both(isCountable, complement(isWdcMembership)))
    .map(simpleFee(resourcesStations, {}));
  const bundleType = bundle.toLowerCase() || 'basic';

  const flightPrice = {
    amount: promotedPrice?.amount ?? price.amount + discountPrice.amount, // discountPrice < 0
    currencyCode: price.currencyCode,
  };

  const i18n = getI18n();
  return [
    {
      label: `${i18n.t('price-details-flight-price')}, ${i18n.t(
        `price-details-fee-fare-${bundle.toLowerCase() || 'fare-basic'}`
      )}`,
      displayCount,
      isIncluded: false,
      price: flightPrice,
      code: bundleType,
    },
    ...flightFees,
  ];
};

const ancillaryFeesFrom = (resourcesStations, ancillaries, direction, stations = {}) =>
  (ancillaries[direction] || [])
    .filter(isCountable)
    .map(simpleFee(resourcesStations, stations));

const passengerAncillaryFeesFrom = (resourcesStations, passengers, direction) =>
  passengers.reduce((acc, passenger) => {
    mergeDuplicationsByLabel(
      acc,
      ancillaryFeesFrom(resourcesStations, passenger, direction)
    );
    return acc;
  }, []);

const globalFlightFeesFrom = (resourcesStations, flights) => {
  const flight = flights[OUTBOUND_FLIGHT_NAME] || {};
  // only wdc for now...
  return (flight.fees || [])
    .filter(isWdcMembership)
    .map(simpleFee(resourcesStations, {}));
};

const iataFrom = (direction, flights, stationType) => {
  const flightDirection = flights[direction];
  return flightDirection ? flightDirection[stationType] : null;
};

const totalFrom = (fees, currencyCode) => ({
  amount: fees.reduce((sum, fee) => sum + fee.price.amount, 0),
  currencyCode,
});

const isCountable = (fee) => fee.displayCount > 0;

const isWdcMembership = anyPass(
  propEq('specificType', SPECIFIC_TYPE_WDC_MEMBERSHIP),
  propEq('specificType', SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY)
);

const simpleFee = curry((resourcesStations, stations, fee) => {
  const {
    code,
    specificType,
    displayCount,
    included: isIncluded,
    promotedPrice,
    price,
    isDepartureStation,
  } = fee;

  return {
    label: getI18n().t(mapSpecificTypeToInvoiceLabel(specificType) || ''),
    code: code || specificType,
    displayCount,
    isIncluded,
    price: isNotNil(promotedPrice) ? promotedPrice : price,
    ...(isNotUndefined(isDepartureStation)
      ? {
          station: getShortName(
            resourcesStations,
            stations[isDepartureStation ? STATION_DEPARTURE : STATION_ARRIVAL]
          ),
        }
      : {}),
  };
});

const mergeDuplicationsByLabel = (fees, newFees) => {
  newFees.forEach((newFee) => {
    const foundFee = fees.find(
      whereEq({ label: newFee.label, isIncluded: newFee.isIncluded })
    );

    if (foundFee) {
      foundFee.displayCount += newFee.displayCount;
      foundFee.price.amount += newFee.price.amount;
    } else {
      fees.push(newFee);
    }
  });
};
