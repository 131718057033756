import Vue from 'vue';
import Vuex from 'vuex';
import log from 'loglevel';
import omit from '~/utils/fp/omit';
import hasIn from '~/utils/fp/has-in';
import { isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import { setSessionItem, restoreSession, removeLocalItem } from '~/utils/storage';
import LegacyVuexOptionAdapter from './legacy-vuex-option-adapter';
import analytics from './modules/analytics';
import ancillaries from './modules/ancillaries';
import booking from './modules/booking';
import bookingExtraInformation from './modules/booking-extra-information';
import checkIn from './modules/check-in';
import coreBooking from './modules/core-booking';
import emergency from './modules/emergency';
import feature from './modules/feature';
import findBookings from './modules/find-bookings';
import flightChange from './modules/flight-change';
import flightDisruption from './modules/flight-disruption';
import flightSelect from './modules/flight-select';
import itinerary from './modules/itinerary';
import locale from './modules/locale';
import nextFlight from './modules/next-flight';
import passengers from './modules/passengers';
import promotion from './modules/promotion';
import resources from './modules/resources';
import search from './modules/search';
import successfulBooking from './modules/successful-booking';
import summary from './modules/summary';
import system from './modules/system';
import upsell from './modules/upsell';
import user from './modules/user';
import volatile from './modules/volatile';
import * as gat from './action-types';
import cms from './modules/cms';
import globalValue from './modules/global-value';
import connectedFlights from './modules/connected-flights';

// do this before this very store's inclusion!
restoreSession();

Vue.use(Vuex);
Vue.use(LegacyVuexOptionAdapter);

// note: these are the generic store modules which are need to be present on
//  every page, page specific modules are registered dynamically later (see
//  `registerNonMainPageRelatedStoreModules`, `importNonMainPageRelatedStoreModule`)
// todo: ancillaries, booking, booking-extra-information, check-in, flight-change
//  and maybe analytics store, flight-disruption, flight-select, itinerary, passengers,
//  promotion, successful-booking, summary, upsell and maybe analytics store modules
//  are not much welcomed here and need to be moved where they actually belong
const modules = {
  analytics,
  ancillaries,
  booking,
  bookingExtraInformation,
  checkIn,
  coreBooking,
  emergency,
  feature,
  findBookings,
  flightChange,
  flightDisruption,
  flightSelect,
  itinerary,
  locale,
  nextFlight,
  passengers,
  promotion,
  resources,
  search,
  successfulBooking,
  summary,
  system,
  upsell,
  user,
  volatile,
  connectedFlights,
};

const initialSSRModules = {
  cms,
  system,
  globalValue,
  locale,
  feature,
  user,
};

const allModules = { ...initialSSRModules, ...modules };

if (isSSRBuild) {
  Object.keys(initialSSRModules).forEach((module) => {
    if (module in modules) delete modules[module];
  });
}

let store;

export const getStore = (state = {}, onlyInitialModules = false) => {
  const isTestEnv = $ENV === 'test';
  const initialized = Boolean(store);
  if (!store || isTestEnv) store = createStoreInstance(onlyInitialModules);

  if (!isSSRBuild || (isSSRBuild && !onlyInitialModules)) {
    Object.entries(allModules).forEach(([key, module]) => {
      if (store.state[key]) return;

      store.registerModule(key, module);
    });
  }

  if (!initialized && !isTestEnv) setupSaveStateToSessionOnChange();

  if (isSSRBuild && isNotEmpty(state)) {
    store.replaceState({
      ...store.state,
      cms: state.cms,
      system: state.system,
      globalValue: state.globalValue,
    });
  }

  return store;
};

const ignoredModuleNames = new Set(['volatile', 'global', 'flightDisruption']);
const persistentModuleNames = new Set(
  Object.keys(allModules).filter((moduleName) => !ignoredModuleNames.has(moduleName))
);
const knownModuleNames = new Set([...persistentModuleNames, ...ignoredModuleNames]);

// note: we need to clone the store options if we want to create multiple store
//  instances from it because Vuex will mutate the passed ins store options :(
const createStoreInstance = (onlyInitialModules) => {
  const instance = new Vuex.Store({
    // note: we need these empty actions because actions will be dispatched by
    //  interceptors and if there are no "listener" vuex errors
    actions: {
      [gat.RESET]: noop,
      [gat.RESTART_BOOKING_RESET]: noop,
      [gat.AFTER_LOGOUT]: noop,
      [gat.REQUEST_START]: noop,
      [gat.REQUEST_END]: noop,
    },
    modules: isSSRBuild && onlyInitialModules ? initialSSRModules : allModules,
  });

  const origRegisterModule = instance.registerModule;
  instance.registerModule = (...args) => {
    origRegisterModule.apply(instance, args);

    const [moduleName, module] = args;
    persistentModuleNames.add(moduleName);
    knownModuleNames.add(moduleName);
    invalidateParsedModuleNameCheckCache();
    addMutationTypeModuleNameMappings(moduleName, module);
  };

  const origUnregisterModule = instance.unregisterModule;
  instance.unregisterModule = (...args) => {
    origUnregisterModule.apply(instance, args);

    const [moduleName] = args;
    persistentModuleNames.delete(moduleName);
    knownModuleNames.delete(moduleName);
    invalidateParsedModuleNameCheckCache();
    removeMutationTypeModuleNameMappings(moduleName);
  };

  return instance;
};

const noop = () => {};

const setupSaveStateToSessionOnChange = () => {
  // this safeguard is not terribly important, we just don't want to litter
  // the console with hundreds of warnings from storage.js
  if (isServer() || !hasIn('sessionStorage', window)) return;

  store.subscribe(onStoreMutation);
};

const onStoreMutation = (mutation) => {
  const moduleNames = [
    ...(mutationTypeModuleNameMap().get(mutation.type)?.values() ?? []),
  ];

  if (isEmpty(moduleNames)) {
    log.warn(
      `[vuex] Unknown mutation type (${mutation.type}). No module defines such mutation.`
    );
    return;
  }

  checkParsedModuleName(mutation.type);

  moduleNames
    .filter((moduleName) => !ignoredModuleNames.has(moduleName))
    .forEach((moduleName) => {
      // note: can't debounce this, not even setTimeout(, 0)
      //  going to successful booking from payment won't have time to write to session :(
      saveToSession(moduleName);
    });
};

const moduleNameRegex = /([a-z-]+)\//i;
const _parsedModuleNameCheckCache = new Set();

const checkParsedModuleName = (mutationType) => {
  if (_parsedModuleNameCheckCache.has(mutationType)) return;

  const parsedModuleName = moduleNameRegex.exec(mutationType)?.[1];
  if (!knownModuleNames.has(parsedModuleName)) {
    log.warn(
      `[vuex] Mutation type (${mutationType}) prefixed with an unknown module name.`
    );
  }

  _parsedModuleNameCheckCache.add(mutationType);
};

const invalidateParsedModuleNameCheckCache = () => _parsedModuleNameCheckCache.clear();

const saveToSession = (moduleName) => {
  // note: we omit the toplevel `volatile` property from the store state to allow
  //  using state that is not synced to session storage
  try {
    setSessionItem(moduleName, omit(['volatile'], store.state[moduleName]));
  } catch {
    log.error(
      'could not save session item, trying to remove cartrawler item and retrying'
    );
    removeLocalItem('ct.smartblock.rentals.ct.vehlocsearch');
    removeLocalItem('ct.smartblock.rentals.ota.vehavailrate');
    setSessionItem(moduleName, omit(['volatile'], store.state[moduleName]));
  }
};

let _mutationTypeModuleNameMap = null;
const mutationTypeModuleNameMap = () => {
  if (_mutationTypeModuleNameMap) return _mutationTypeModuleNameMap;

  return (_mutationTypeModuleNameMap = Object.entries(allModules).reduce(
    (map, [moduleName, module]) => {
      _createUpdatedMutationTypeModuleNameEntries(map, moduleName, module).forEach(
        ([mutationType, moduleNames]) => map.set(mutationType, moduleNames)
      );
      return map;
    },
    new Map()
  ));
};

const addMutationTypeModuleNameMappings = (moduleName, module) => {
  const map = (_mutationTypeModuleNameMap = new Map(mutationTypeModuleNameMap()));
  _createUpdatedMutationTypeModuleNameEntries(map, moduleName, module).forEach(
    ([mutationType, moduleNames]) => map.set(mutationType, moduleNames)
  );
};

const removeMutationTypeModuleNameMappings = (moduleName) => {
  const map = (_mutationTypeModuleNameMap = new Map(mutationTypeModuleNameMap()));
  for (const mutationType of map.keys()) {
    const moduleNames = map.get(mutationType);
    if (!moduleNames.has(moduleName)) continue;

    const updatedModuleNames = new Set(moduleNames);
    updatedModuleNames.delete(moduleName);
    map.set(mutationType, updatedModuleNames);
  }
};

const _createUpdatedMutationTypeModuleNameEntries = (map, moduleName, module) =>
  getMutationTypes(module).reduce((entries, mutationType) => {
    const moduleNames = new Set(map.get(mutationType) ?? []);
    moduleNames.add(moduleName);
    entries.push([mutationType, moduleNames]);
    return entries;
  }, []);

const getMutationTypes = (module) => Object.keys(module.mutations ?? {});

// eslint-disable-next-line camelcase
export const __testsOnly__injectModules = (additionalModules) =>
  Object.assign(allModules, additionalModules);
