export const getDefaultState = () => {
  return {
    user: {
      isNewsletterConsentModalVisible: false,
      // increase with each ajax, decrease with result or reject, so far only used by __vuex actions__
      isLoading: 0,
    },

    search: {
      selectedTab: 'flight',
      // an extra input field in the flight search component but it changes
      // height calculations for here and there (moved here from 'states')
      isRescueFareCodeVisible: false,
    },

    booking: {
      isWdcTargetedModalVisible: false,
      isPaymentFailedModalVisible: false,
      isPaymentExitIntentModalVisible: false,
    },

    itinerary: {
      // modal with contact fields
      isLoading: false,
      isChangeInvoiceVisible: false,
      isSeatSelectionModalVisible: false,
      isSeatSelectionExitIntentModalVisible: false,
      isSeatSelectionHasUnconfirmedChangesFromPrevious: false,
    },

    captcha: {
      isCaptchaHandled: false,
      isCaptchaModalVisible: false,
    },

    // from app.js
    states: {
      isBookingFlowLoaded: false,
      isBookingFlowVisible: false,
      isMapVisible: false,
      isContentNavOpened: false,
      isWizzAirMapVisible: false,
      isSummaryOnMobileVisible: false,
      isYellowRibbonDismissed: false,
      yellowRibbonMessage: '',
      isPhoneNumberUpdateModalVisible: false,
      isCancellationInsuranceInfoModalVisible: false,
      isTravelInsuranceInfoModalVisible: false,
      isTravelAndCancellationInsuranceInfoModalVisible: false,
      isMainPage: false,
      heroBannerInfo: {
        activeSlideId: 0,
        numberOfSlides: 0,
      },
      isFlightAdditionRequested: false,
    },

    itineraryOverview: {
      isAirportTransferModalVisible: false,
      isAirportParkingModalVisible: false,
      isAcordServiceModalVisible: false,
      isAcordServiceComparisonModalVisible: false,
      hasToReopenUpsellModal: false,
      isModifyServicesModalVisible: false,
      isAddFlightExitIntentModalVisible: false,
    },

    navigation: {
      navigationClassList: [],
    },
  };
};
