import { getI18n, pluralize } from '~/i18n';
import { formatCurrency } from '../currency';

/**
 * option format example:
 * {
 *    option: 'EinBusType_bustype_twoway',
 *    vehicleCount: 2,
 *    price: { amount: 6000, currencyCode: 'EUR' }
 * }
 * ||
 * {
 *    id: 'EinBusType_bustype_twoway',
 *    vehicleCount: 2
 * }
 *
 * @param {Object} option
 * @param {Boolean} omitWay    skip translating the oneway twoway part
 * @returns {String}
 */
export function translateAirportTransferOption(option, omitWay) {
  if (!option) {
    return '';
  }
  const value = option.option || option.id;
  const isTaxi = value.includes('taxitype');
  const i18n = getI18n();
  // special offer has no price or anything
  if (value.toLowerCase().includes('specialoffer')) {
    return i18n.t('send-special-offer-in-email');
  }
  // real offer must be assembled
  let translatedText = i18n.t(`${value.replace(/_.*$/, '')}-Title`); // "City Express Budapest"
  if (!omitWay) {
    if (/_oneway/gi.test(value)) {
      translatedText += ` - ${i18n.t('one-way')}`; // " - One Way"
    } else if (/_twoway/gi.test(value)) {
      translatedText += ` - ${i18n.t('return')}`;
    }
  }
  const price = option?.price?.amount; // "Ft600.00 per passenger"
  if (price) {
    const formattedCurrency = formatCurrency(price, option.price.currencyCode);
    translatedText += ` ${i18n.t(isTaxi ? 'price-per-car' : 'price-per-passenger', [
      formattedCurrency,
    ])}`;
  }
  const carCount = option.vehicleCount;
  if (carCount && isTaxi) {
    const pluralizedText = pluralize('with-taxi-car-count', carCount);
    translatedText += ` - ${pluralizedText}`; // " - 1 car"
  }
  return translatedText;
}
