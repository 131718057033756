import curry from '../curry';
import { _path } from '../path';

/**
 * @typedef {(spec: Object<string, any>, testObj: Object<string, any>) => boolean} WhereFn
 */

/**
 * @type WhereFn
 */
export const _where = (spec, testObj) =>
  Object.keys(spec).every((prop) => spec[prop](_path(prop, testObj)));

/**
 * Takes a spec object and a test object; returns true if the test satisfies
 * the spec. Each of the spec's own properties must be a predicate function.
 * Each predicate is applied to the value of the corresponding property of the
 * test object. `where` returns true if all the predicates return true, false
 * otherwise.
 *
 * `where` is well suited to declaratively expressing constraints for other
 * functions such as `filter` and `find`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/where.js
 *
 * @type WhereFn
 * @example
 *
 *      // pred :: Object -> Boolean
 *      let pred = where({
 *        a: equals('foo'),
 *        b: complement(equals('bar')),
 *        x: gt(__, 10),
 *        y: lt(__, 20)
 *      });
 *
 *      pred({a: 'foo', b: 'xxx', x: 11, y: 19}); //=> true
 *      pred({a: 'xxx', b: 'xxx', x: 11, y: 19}); //=> false
 *      pred({a: 'foo', b: 'bar', x: 11, y: 19}); //=> false
 *      pred({a: 'foo', b: 'xxx', x: 10, y: 19}); //=> false
 *      pred({a: 'foo', b: 'xxx', x: 11, y: 20}); //=> false
 */
const where = curry(_where);

export default where;
