export const RESET = 'passengers/reset';
export const SET_PASSENGERS = 'passengers/setPassengers';
export const SET_I_AM_TRAVELLING = 'passengers/setIAmTravelling';
export const SET_FAKE_GROUP_BOOKING_PASSENGER = 'passengers/setFakeGroupBookingPassenger';
export const RESTORE_PASSENGERS = 'passengers/restorePassengers';
export const SET_SAVED_PASSENGER_DETAILS = 'passengers/setSavedPassengerDetails';
export const UPDATE_PASSENGER_REDUCED_MOBILITY = 'passengers/updateReducedMobility';
export const RESET_PASSENGER_REDUCED_MOBILITY = 'passengers/resetReducedMobility';
export const UPDATE_PASSENGER = 'passengers/updatePassenger';
export const UPDATE_SAVED_PASSENGER = 'passengers/updateSavedPassenger';
export const UPDATE_INFANT_ADULT_PASSENGER_NUMBER =
  'passengers/updateInfantAdultPassengerNumber';
export const UPDATE_PASSENGERS_ACCORDION_STATE =
  'passengers/updatePassengersAccordionState';
export const COPY_REDUCED_MOBILITY_TO_ALL_PASSENGERS =
  'passengers/copyReducedMobilityToAllPassengers';
