import log from 'loglevel';
import { LOCALIZATION } from '~/constants';
import { isSSRBuild } from '~/utils/ssr/build-type';
import isNotEmpty from '~/utils/object/is-not-empty';
import partial from '../fp/partial';
import omit from '../fp/omit';
import complement from '../fp/complement';
import {
  getLocalItemRaw,
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from '../storage';
import { parseQueryString } from '../browser';
import getLocale from './get-locale';
import _localizePath from './internal/_localize-path';

export { getLocale };

// cache
let _localizedLabels;

// window.language props to merge into one
// now it's a list of regexps, which will autodetect language-like keys
// but the price is that overwrites (group priority) are harder to control
const mergeWhitelist = [
  /^wizz.*?resources$/,
  /^donottranslateresources$/, // special links for example
];

// for debugging only
const mergeBlacklist = ['culture', 'current', 'labels', 'locale'];

export const LOCALE = getLocale();

export const getDecimalSeparator = () => {
  const localeData = LOCALIZATION.locales[LOCALE];
  return localeData.decimal;
};

export const isLanguageCodeChanged = (languageCode) => {
  return languageCode !== LOCALE;
};

/**
 * @type {(languageCode: String) => boolean}
 */
export const isLanguageCodeNotChanged = complement(isLanguageCodeChanged);

/**
 * merge global "language" values (from sitefinity script link)
 * into one big object. Overwrites MAY happen.
 *
 * @param languagePack         optional, falls back to window.language
 * @param {boolean} noCache    disable caching, useful for testing
 * @returns flat object
 */
export function mergeLocalizedLabels(languagePack, noCache) {
  if (
    ((isSSRBuild && isNotEmpty(_localizedLabels)) || (!isSSRBuild && _localizedLabels)) &&
    !noCache
  ) {
    return _localizedLabels;
  }
  languagePack = languagePack || window.language;
  const allowedKeys = Object.keys(languagePack).filter((key) =>
    mergeWhitelist.some((rex) => rex.test(key))
  );
  const unknownProps = Object.keys(
    omit([...allowedKeys, ...mergeBlacklist], languagePack)
  );
  if (isNotEmpty(unknownProps) && !getLocalItemRaw('hideLabelWarnings')) {
    // props MUST start with "wizz" and end with "resources"
    log.warn('Unknown props in window.language!', unknownProps);
  }
  const localizedLabels = {};
  allowedKeys.forEach((resCode) => {
    const labels = languagePack[resCode];
    Object.keys(labels).forEach((labelName) => {
      localizedLabels[labelName] = labels[labelName];
    });
  });
  _localizedLabels = localizedLabels;
  return localizedLabels;
}

/**
 * Returns the label keys for a specific variation of a label A/B test without the feature name prefix.
 *
 * @param name    Feature name
 * @param value   Feature value (variation)
 * @returns {string}
 */
export function getLabelKeysForVariation({ name, value } = {}) {
  const labels = mergeLocalizedLabels(window.language);

  return (
    [...Object.keys(labels)]
      .filter((key) => key.startsWith(name) && key.endsWith(value))
      // Remove feature name from the label key
      .map((key) => key.replace(`${name}-`, ''))
      // Uppercase variation (last character)
      .map((key) => key.replace(/.$/, (variation) => variation.toUpperCase()))
      .sort((labelA, labelB) => labelA.localeCompare(labelB))
      .join('|')
  );
}

export function isTranslationEnabled() {
  // &showLabelKeys=true query string to disable translation and show only the labels on the whole site
  const params = parseQueryString();
  const showLabelKeys = params.showLabelKeys && params.showLabelKeys === 'true';
  const hideLabelKeys = params.showLabelKeys && params.showLabelKeys === 'false';

  let isEnabled = getSessionItem('disableTranslations')
    ? !getSessionItem('disableTranslations')
    : true;
  if (showLabelKeys) {
    isEnabled = false;
    setSessionItem('disableTranslations', true);
  } else if (hideLabelKeys) {
    isEnabled = true;
    removeSessionItem('disableTranslations');
  }

  return isEnabled;
}

/**
 * get an array of urls for airport transfer content labels
 * (those labels have {0} {1} etc instead of links, so this way
 * silly translators would not mess the urls up)
 *
 * @param {string} prefix           prefix of the url key (for example "BegShuttleType")
 * @param languagePack              optional, falls back to window.language
 * @returns {Array}
 */
export function getDoNotTranslateUrl(prefix, languagePack) {
  const urls = [];
  languagePack = (languagePack || window.language || {}).donottranslateresources || {};
  Object.keys(languagePack).forEach((key) => {
    if (key.indexOf(`${prefix}-Url`) === 0) {
      const pos = Number.parseInt(key.replace(`${prefix}-Url`, ''), 10);
      if (!Number.isNaN(pos)) {
        urls[pos] = languagePack[key];
      }
    }
  });
  return urls;
}

/**
 *
 * @param {String} path
 * @returns {String}
 */
export const localizePath = partial(_localizePath, undefined);
