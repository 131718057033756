/* eslint import/no-cycle: "warn" */
import { to } from 'await-to-js';
import log from 'loglevel';

import {
  STEP_SERVICES,
  STEP_PAYMENT,
  INSURANCE_TYPE_TRAVEL,
  TRACKING_LOCATION_ITINERARY,
  WDC_RETARGETING_POPUP,
  INSURANCE_TYPE_CANCELLATION,
  INSURANCE_TYPE_TRAVEL_ONLY,
  PAYMENT_OPTION_CREDIT_CARD,
  PAYMENT_OPTION_STORED_CREDIT_CARD,
} from '~/constants';
import { sendGaEvent } from '~/utils/analytics';
import { emit } from '~/utils/event-bus';
import { createAction } from '~/utils/store';
import isEmpty from '~/utils/object/is-empty';
import * as BookingService from '~/services/booking';
import * as PaymentService from '~/services/payment';
import * as CheckInService from '~/services/check-in';
import path from '~/utils/fp/path';
import * as PaymentUtils from '~/utils/booking/payment';
import { wdcRenewalPrice } from '../search/getters';
import * as summaryActions from '../summary/actions';
import * as userGetters from '../user/getters';
import { postSelect } from '../flight-select/actions';
import {
  setCancellationInsuranceModalVisibility,
  setTravelInsuranceModalVisibility,
  setTravelAndCancellationInsuranceModalVisibility,
} from '../volatile/actions';
import { getPassengers, postPassengers, updatePassenger } from '../passengers/actions';
import { passengers as currentPassengers } from '../passengers/getters';
import * as analyitcsActions from '../analytics/actions';
import * as coreBookingGetters from '../core-booking/getters';
import {
  payment,
  groupPartialPayment,
  availableBankAccounts,
  defaultPaymentOption,
  defaultStoredCreditCard,
  isAcordServiceInfoModalTrackedFromItinerary,
  isAcordServiceInfoModalTrackedFromPayment,
  isAcordServiceInfoModalTrackedFromServices,
} from './getters';
import * as m from './mutation-types';

/**
 * @type {(store: Store, value: string) => void}
 */
export const setCvv = createAction(m.SET_CVV);

/**
 * @type {(store: Store) => void}
 */
export const setWdcIsAdded = createAction(m.SET_WDC_IS_ADDED);

/**
 * @type {(store: Store) => void}
 */
export const unsetWdcIsAdded = createAction(m.UNSET_WDC_IS_ADDED);

/**
 * @type {(store: Store, value: Object<string, any>) => void}
 */
export const setWdcRequiredMembership = createAction(m.SET_WDC_REQUIRED_MEMBERSHIP);

/**
 * @type {(store: Store, value: []) => void}
 */
export const setWdcAvailableMemberships = createAction(m.SET_WDC_AVAILABLE_MEMBERSHIPS);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setIsWdcUpgradedFromSidebar = createAction(
  m.SET_IS_WDC_UPGRADED_FROM_SIDEBAR
);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setNewsletterSubscription = createAction(m.SET_NEWSLETTER_SUBSCRIPTION);

/**
 * @type {(store: Store, value: string) => void}
 */
export const setNewsletterSubscriptionLocation = createAction(
  m.SET_NEWSLETTER_SUBSCRIPTION_LOCATION
);

/**
 * @type {(store: Store, value: {}) => void}
 */
export const setFareLockFinalizeData = createAction(m.SET_FARE_LOCK_FINALIZE_DATA);

export const setBookingFlowStep = createAction(m.SET_BOOKING_FLOW_STEP);

export const setNewBooking = createAction(m.SET_NEW_BOOKING);

export const resetBooking = createAction(m.RESET_BOOKING);

export const setKayakClickId = createAction(m.SET_KAYAK_CLICK_ID);

export const setWegoClickId = createAction(m.SET_WEGO_CLICK_ID);

export const setAirportTransferModalVisibility = createAction(
  m.SET_AIRPORT_TRANSFER_MODAL_VISIBILITY
);

export const setAirportTransferModalDirection = createAction(
  m.SET_AIRPORT_TRANSFER_MODAL_DIRECTION
);

export const setIsInsurancePolicyAccepted = createAction(
  m.SET_ACORD_SERVICE_INSURANCE_ACCEPTED
);

export const setIsInsuranceNoneSelected = createAction(
  m.SET_ACORD_SERVICE_INSURANCE_NONE_SELECTED
);

export const setIsInsuranceForced = createAction(m.SET_ACORD_SERVICE_INSURANCE_FORCED);

export const setHasSelectedCar = createAction(m.SET_HAS_SELECTED_CAR);

export const unsetHasSelectedCar = createAction(m.UNSET_HAS_SELECTED_CAR);

export const changeBankTransferAccount = createAction(m.CHANGE_BANK_TRANSFER_ACCOUNT);

export const selectTheFirstAvailableBankAccount = ({ commit, state }) => {
  const accounts = availableBankAccounts(state);
  if (isEmpty(accounts)) return;
  commit(m.CHANGE_BANK_TRANSFER_ACCOUNT, accounts[Object.keys(accounts)[0]]);
};

export const setSameForBothFlightsChecked = createAction(
  m.SET_SAME_FOR_BOTH_FLIGHTS_CHECKED
);

export const resetFlightCarriersConditionsAccepted = createAction(
  m.RESET_FLIGHT_CARRIERS_CONDITIONS_ACCEPTED
);

export const setFlightCarrierAccepted = createAction(m.SET_FLIGHT_CARRIER_ACCEPTED);

export const resetCreditCard = createAction(m.HANDLE_PAYMENT_REMOVE_CREDIT_CARD_DATA);

export const setCarrierCodes = createAction(m.SET_CARRIER_CODES);

export const fetchCheckinParams = async ({ commit }) => {
  commit(m.SET_CHECK_IN_PARAMS_ERRORS, []);
  const [error, response] = await to(CheckInService.getCheckInParams());

  if (error) return commit(m.SET_CHECK_IN_PARAMS_ERRORS, error);

  const checkInTime = Math.abs(Math.min(...Object.values(response.data)));
  commit(m.SET_CHECK_IN_PARAMS, checkInTime);
};

// WDC renewal will be removed
export const renewWdc = async (store) => {
  await BookingService.wizzDiscountClubRenewal();
  addPayableWdcRenewal(store);
};

export const lazyRenewWdc = (store) => {
  const { commit } = store;
  commit(m.MARK_WDC_RENEWAL_REQUEST, true);
  addPayableWdcRenewal(store);
};

const addPayableWdcRenewal = (store) => {
  const { state } = store;
  const membership = null;
  const searchWdcRenewalPrice = wdcRenewalPrice(state) || {};
  const userWdcRenewalPrice = userGetters.targetedWdcOffer(state) || {};
  const amount =
    searchWdcRenewalPrice.amount ||
    path('bookingCurrencyRenewalPrice.amount', userWdcRenewalPrice) ||
    0;
  const pointOfSale = WDC_RETARGETING_POPUP;
  analyitcsActions.setWdcMembershipPurchaseLocation(store, pointOfSale);

  const currencyCode =
    searchWdcRenewalPrice.currencyCode ||
    path('bookingCurrencyRenewalPrice.currencyCode', userWdcRenewalPrice) ||
    coreBookingGetters.bookingCurrencyCode(state);

  summaryActions.addPayableWdcRenewal(store, {
    code: { WDC_STANDARD: 'WDCSXT', WDC_GROUP: 'WDCGXT' }[membership],
    membership,
    membershipPrice: {
      amount,
      currencyCode,
    },
  });
};

export const fetchPaymentOptions = async (store) => {
  const { commit, state } = store;
  const response = await PaymentService.getOptions();
  commit(m.HANDLE_PAYMENT_OPTIONS, response.data);

  setSelectedPaymentOptionValue(store, defaultPaymentOption(state));
  setStoredCreditCardValue(store, defaultStoredCreditCard(state));
};

export const getContact = async ({ commit }) => {
  const response = await BookingService.getContact();
  commit(m.GET_CONTACT_DETAILS, response.data);
};

export const setContactDetails = createAction(m.GET_CONTACT_DETAILS);

export const setSelectedPaymentOptionValue = (store, value) => {
  const { commit } = store;

  commit(m.HANDLE_PAYMENT_SELECTED_OPTION, value);
  analyitcsActions.setSelectedPaymentMethodForAnalytics(store, value);
};

export const setStoredCreditCardValue = createAction(
  m.HANDLE_PAYMENT_ADD_STORED_CREDIT_CARD
);

export const fetchCorporateCardFeePercent = async ({ commit }, cardNumber) => {
  const percent = await PaymentService.getCorporateCardFeePercent(cardNumber);
  commit(m.SET_PAYMENT_CORPORATE_CARD_FEE_PERCENT, percent);
};

export const fetchCorporateCardInfo = async ({ state, commit }) => {
  const paymentFromState = payment(state);
  const partialPaymentPayload = PaymentUtils.getGroupBookingPartialPaymentPayload(
    paymentFromState.selected,
    groupPartialPayment
  );

  let promise = Promise.resolve();
  switch (paymentFromState.selected) {
    case PAYMENT_OPTION_CREDIT_CARD:
      promise = PaymentService.getCorporateCardFeeInfo(
        paymentFromState.creditCard.cardNumber,
        coreBookingGetters.paymentCurrencyCode(state),
        partialPaymentPayload?.paymentAmount?.amount
      );
      break;
    case PAYMENT_OPTION_STORED_CREDIT_CARD:
      promise = PaymentService.getStoredCorporateCardFeeInfo(
        paymentFromState.storedCreditCard.id,
        coreBookingGetters.paymentCurrencyCode(state),
        partialPaymentPayload?.paymentAmount?.amount
      );
      break;

    default:
      log.error('Unexpected payment type: ', paymentFromState.selected);
      break;
  }

  const info = await promise;

  commit(m.SET_PAYMENT_CORPORATE_CARD_FEE_INFO, info);
};

export const resetCorporateCardFeeInfo = ({ commit }) => {
  commit(m.SET_PAYMENT_CORPORATE_CARD_FEE_INFO, {
    isCorporateCardFeeAccepted: false,
    corporateCardFeePercent: null,
    originalAmount: null,
    corporateCardFeeAmount: null,
    newTotalAmount: null,
  });
};

export const setIsCorporateCardFeeAccepted = createAction(
  m.SET_PAYMENT_IS_CORPORATE_CARD_FEE_ACCEPTED
);

export const setResidualAmount = createAction(m.HANDLE_PAYMENT_ADD_RESIDUAL_AMOUNT);

export const removeConfidentialCardData = createAction(
  m.HANDLE_PAYMENT_REMOVE_CONFIDENTIAL_CARD_DATA
);

export const disableGroupPartialPayment = createAction(
  m.HANDLE_PAYMENT_DISABLE_PARTIAL_PAYMENT
);

export const setAlreadyPaidStatus = createAction(m.SET_ALREADY_PAID_STATUS);

export const setRestartBookingModalVisibility = createAction(
  m.SET_RESTART_BOOKING_MODAL_VISIBILITY
);

export const setBundleUpsellOriginalCommonBundle = createAction(
  m.SET_BUNDLE_UPSELL_ORIGINAL_COMMON_BUNDLE
);

export const setIsWdcPromotionStepAvailable = createAction(m.SET_IS_WDC_STEP_AVAILABLE);

export const unsetIsWdcPromotionStepAvailable = createAction(
  m.UNSET_IS_WDC_STEP_AVAILABLE
);

export const toggleCancellationInsurance = createAction(m.TOGGLE_CANCELLATION_INSURANCE);
export const setCancellationInsurance = createAction(m.SET_CANCELLATION_INSURANCE);

export const toggleTravelInsurance = createAction(m.TOGGLE_TRAVEL_INSURANCE);
export const setTravelInsurance = createAction(m.SET_TRAVEL_INSURANCE);

export const toggleBaggageInsurance = createAction(m.TOGGLE_BAGGAGE_INSURANCE);
export const setBaggageInsurance = createAction(m.SET_BAGGAGE_INSURANCE);

export const showUrgencyModal = createAction(m.SHOW_URGENCY_MODAL);

export const hideUrgencyModal = ({ commit }) => {
  commit(m.SET_URGENCY_MODAL_ALREADY_SHOWED);
  commit(m.HIDE_URGENCY_MODAL);
};

export const showWdcSavingModal = ({ commit }) => {
  commit(m.SET_WDC_SAVING_MODAL_VISIBILITY, true);
};

export const hideWdcSavingModal = ({ commit }) => {
  commit(m.SET_WDC_SAVING_MODAL_VISIBILITY, false);
};

// todo: error handling if applicable here or on call site
export const maybeContinueWithFareLockToPayment = async (store) => {
  const { state } = store;

  await postSelect(store);

  const _passengers = currentPassengers(state);
  if (isEmpty(_passengers)) {
    await getPassengers(store);
  }
  emit('bookingFlow_passengers_setDefaultProperties');

  if (userGetters.hasAnyWdcMembership(state)) {
    updatePassenger(store, {
      isInfant: false,
      passenger: {
        passengerNumber: 0,
        iamTravelling: true,
      },
    });
  }

  await postPassengers(store);
};

export const showAcordServiceInfoModal = (store, insuranceType, eventLocation) => {
  trackAcordServiceInfoModal(store, eventLocation);

  switch (insuranceType) {
    case INSURANCE_TYPE_TRAVEL:
      return setTravelAndCancellationInsuranceModalVisibility(store, true);
    case INSURANCE_TYPE_CANCELLATION:
      return setCancellationInsuranceModalVisibility(store, true);
    case INSURANCE_TYPE_TRAVEL_ONLY:
      return setTravelInsuranceModalVisibility(store, true);
    default:
      return setCancellationInsuranceModalVisibility(store, true);
  }
};

export const hideAcordServiceInfoModal = (store) => {
  setTravelInsuranceModalVisibility(store, false);
  setCancellationInsuranceModalVisibility(store, false);
  setTravelAndCancellationInsuranceModalVisibility(store, false);
};

export const trackAcordServiceInfoModal = ({ commit, state }, eventLocation = '') => {
  if (
    !isAcordServiceInfoModalTrackedFromServices(state) &&
    eventLocation === STEP_SERVICES
  ) {
    commit(m.SET_ACORD_SERVICE_INFO_MODAL_TRACKED, eventLocation);
    sendGaEvent({ category: 'Insurance', action: 'Click', label: 'Information modal' });
  } else if (
    !isAcordServiceInfoModalTrackedFromPayment(state) &&
    eventLocation === STEP_PAYMENT
  ) {
    commit(m.SET_ACORD_SERVICE_INFO_MODAL_TRACKED, eventLocation);
    sendGaEvent({ category: 'Insurance', action: 'Click', label: 'Information modal' });
  } else if (
    !isAcordServiceInfoModalTrackedFromItinerary(state) &&
    eventLocation === TRACKING_LOCATION_ITINERARY
  ) {
    commit(m.SET_ACORD_SERVICE_INFO_MODAL_TRACKED, eventLocation);
    sendGaEvent({ category: 'Insurance', action: 'Click', label: 'Information modal' });
  }
};

export const setIsBillingValid = createAction(m.SET_BILLING_VALID);

export const updateWdcStepNecessity = createAction(m.SET_WDC_STEP_NECESSARY);

/**
 * @type {(store: Store) => void}
 */
export const showSingleServiceModal = createAction(m.SHOW_SINGLE_SERVICE_MODAL);

/**
 * @type {(store: Store) => void}
 */
export const hideSingleServiceModal = createAction(m.HIDE_SINGLE_SERVICE_MODAL);

/**
 * @type {(store: Store) => void}
 */
export const showWizzFlexModal = ({ commit }) => {
  commit(m.SET_WIZZ_FLEX_MODAL_VISIBILITY, true);
};

/**
 * @type {(store: Store) => void}
 */
export const hideWizzFlexModal = ({ commit }) => {
  commit(m.SET_WIZZ_FLEX_MODAL_VISIBILITY, false);
};

export const setPreviousBookingDetails = createAction(m.SET_PREVIOUS_BOOKING_DETAILS);

export const setIsPaymentExitIntentModalSeen = createAction(
  m.SET_IS_PAYMENT_EXIT_INTENT_MODAL_SEEN
);

/**
 * @type {(store: Store) => void}
 */
export const setIsFlightSearchInSidebarVisible = createAction(
  m.SET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE
);

/**
 * @type {(store: Store) => void}
 */
export const unsetIsFlightSearchInSidebarVisible = createAction(
  m.UNSET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE
);

/**
 * @type {(store: Store) => void}
 */
export const setIsFlightSearchModalVisible = createAction(
  m.SET_IS_FLIGHT_SEARCH_MODAL_VISIBLE
);

/**
 * @type {(store: Store) => void}
 */
export const unsetIsFlightSearchModalVisible = createAction(
  m.UNSET_IS_FLIGHT_SEARCH_MODAL_VISIBLE
);

/**
 * @type {(store: Store) => void}
 */
export const setDestinationBasedNotificationAccepted = createAction(
  m.SET_DESTINATION_BASED_NOTIFICATION_ACCEPTED
);

/**
 * @type {(store: Store) => void}
 */
export const setIndependentPassengers = createAction(m.SET_INDEPENDENT_PASSENGERS);

/**
 * @type {(store: Store) => void}
 */
export const setIsCaptchaRequired = createAction(m.SET_IS_CAPTCHA_REQUIRED);

/**
 * @type {(store: Store) => void}
 */
export const resetCaptchaResponse = createAction(m.RESET_CAPTCHA_RESPONSE);

/**
 * @type {(store: Store) => void}
 */
export const setPaymentCurrency = createAction(m.SET_PAYMENT_CURRENCY);

/**
 * @type {(store: Store) => void}
 */
export const setGroupPartialPaymentValues = createAction(
  m.SET_GROUP_PARTIAL_PAYMENT_VALUES
);

/**
 * @type {(store: Store) => void}
 */
export const setSelectedIdealIssuer = createAction(m.SET_SELECTED_IDEAL_ISSUER);

/**
 * @type {(store: Store) => void}
 */
export const setIsRegisteredForNewsletter = createAction(
  m.SET_IS_REGISTERED_FOR_NEWSLETTER
);

/**
 * @type {(store: Store) => void}
 */
export const setChangeFlightDisclaimerType = createAction(
  m.SET_CHANGE_FLIGHT_DISCLAIMER_TYPE
);

export const fetchPremiumSeatRefund = async ({ commit, state }) => {
  if (!coreBookingGetters.isRebookFlow(state)) return false;

  commit(m.SET_IS_PREMIUM_SEAT_LOADING, true);
  commit(m.SET_PREMIUM_SEAT_REFUND_ERRORS, []);

  const [error, response] = await to(BookingService.getPremiumSeatRefund());

  commit(m.SET_IS_PREMIUM_SEAT_LOADING, false);

  if (error) return commit(m.SET_PREMIUM_SEAT_REFUND_ERRORS, error);

  commit(m.SET_PREMIUM_SEAT_REFUND, response.data);
};

export const postPremiumSeatRefund = async ({ commit }, premiumSeatRefundType) => {
  commit(m.SET_IS_PREMIUM_SEAT_LOADING, true);
  commit(m.SET_PREMIUM_SEAT_REFUND_ERRORS, []);

  const [error] = await to(BookingService.premiumSeatRefund({ premiumSeatRefundType }));

  commit(m.SET_IS_PREMIUM_SEAT_LOADING, false);

  if (error) return commit(m.SET_PREMIUM_SEAT_REFUND_ERRORS, error);
};

export const setPassengerStepPending = createAction(m.SET_PASSENGERS_STEP_PENDING);

export const setSelectedFlightPending = createAction(m.SET_SELECTED_FLIGHT_PENDING);

export const setPaymentStepPending = createAction(m.SET_PAYMENT_STEP_PENDING);

export const setCurrencyChangeWarningDismissed = createAction(
  m.SET_CURRENCY_CHANGE_WARNING_DISMISSED
);

export const setOtaFeeWarningDismissed = ({ commit }) =>
  commit(m.SET_OTA_FEE_WARNING_DISMISSED, true);
export const setCardInGooglePayAvailable = createAction(
  m.SET_CARD_IN_GOOGLE_PAY_AVAILABLE
);

export const setGooglePayLoading = createAction(m.SET_GOOGLE_PAY_LOADING);
export const setGooglePayReady = createAction(m.SET_GOOGLE_PAY_READY);
