import isString from '../is-string';

/**
 * Checks if the argument has a type of String and if it is not empty.
 *
 * @type {(value: any) => boolean}
 */
const isNotEmptyString = (value) => isString(value) && value !== '';

export default isNotEmptyString;
