export const SS_IGNORE_FEATURE_TOGGLES_FROM_STATE = 'ignoreFeatureTogglesFromState';
export const SS_FEATURE_TRACKED_LABELS = 'featureTrackedLabels';
export const SS_LOYAL_CUSTOMER_TRACKED = 'loyalCustomerTracked';
export const SS_BOOKING_COM_POPUNDER_OPENED = 'bookingSearchAlreadyOpened';
export const SS_LANDING_BANNERS = 'landingBanners';
export const SS_CHEAP_FLIGHTS_BANNERS = 'cheapFlightsBanners';
export const SS_FARE_FINDER_PREFER_WDC = 'fareFinderPreferWdc';
export const SS_ECOMMERCE_TRACKINGS = 'ecommerceTrackings';
export const SS_LABEL_TEST_WIZZ_FLEX_SELECTED = 'labelTestWizzFlexSelected';
export const SS_LABEL_TEST_PRIORITY_WITH_CABIN_BAGGAGE_SELECTED =
  'labelTestPriorityBoardingWithCabinBaggageSelected';
export const SS_TRACKED_EVENTS_SESSION_KEY = 'trackedEvents';
export const SS_KOBALOS_PRESENCE = '__kobalos__';
export const SS_SEAT_SELECTION_STEP = 'seat-selection-step';
export const SS_DETECTED_BROWSER_LOCATION = 'detectedBrowserLocation';
export const SS_IS_SEARCH_SUBMIT_IN_PROGRESS = 'isSearchSubmitInProgress';
export const SS_AKAMAI_USER_LOCATION = 'akamaiUserLocation';
export const SS_FLIGHT_SEARCH_SOURCE = 'flight-search-source';
