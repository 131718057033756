import isFunction from '~/utils/object/is-function';
import isObject from '~/utils/object/is-object';
import curry from '../curry';
import { _mapObj } from '../map-obj';

/**
 * @typedef {(transformations: Object<string, any>, obj: Object<string, any>) => Object<string, any>} EvolveFn
 */

/**
 * @type EvolveFn
 */
export const _evolve = (transformations, obj) =>
  _mapObj((value, key) => {
    const transformation = transformations[key];
    let newValue = value;

    if (isFunction(transformation)) {
      newValue = transformation(value);
    } else if (isObject(transformation)) {
      newValue = evolve(transformation, value);
    }

    return newValue;
  }, obj);

/**
 * Creates a new object by recursively evolving a shallow copy of `object`,
 * according to the `transformation` functions. All non-primitive properties
 * are copied by reference.
 *
 * A `transformation` function will not be invoked if its corresponding key
 * does not exist in the evolved object.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/evolve.js
 *
 * @type EvolveFn
 * @example
 *
 *      let tomato = { firstName: '  Tomato ', data: { elapsed: 100, remaining: 1400 }, id: 123 };
 *      let transformations = {
 *        firstName: trim,
 *        lastName: trim, // Will not get invoked.
 *        data: { elapsed: add(1), remaining: add(-1) }
 *      };
 *      evolve(transformations, tomato); //=> { firstName: 'Tomato', data: { elapsed: 101, remaining: 1399 }, id:123 }
 */
const evolve = curry(_evolve);

export default evolve;
