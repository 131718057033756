import equals from '~/utils/fp/equals';
import propEq from '~/utils/fp/prop-eq';
import prop from '~/utils/fp/prop';
import curry from '~/utils/fp/curry';
import uniq from '~/utils/fp/uniq';
import { mapSpecificTypeToId } from '~/utils/booking/summary';
import isNotUndefined from '~/utils/object/is-not-undefined';
import isNotNil from '~/utils/object/is-not-nil';
import { isNotBasicBundle } from '~/utils/booking/booking';
import * as EcommerceService from '~/services/ecommerce';
import {
  OUTBOUND_FLIGHT,
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT,
  RETURN_FLIGHT_NAME,
  STEP_SERVICES,
  BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP,
  SIMPLE_LOCATION_TRACKABLE_PRODUCTS,
  PER_PASSENGER_LOCATION_TRACKABLE_PRODUCTS,
  STEP_SELECT_FLIGHT,
  ANCILLARY_CAR_RENTAL,
  ANCILLARY_CABIN_BAGGAGE,
  WDC_MEMBERSHIP_NAME_MAP,
  FEE_CODES,
  FEE_NAME_INFANT,
} from '~/constants';
import {
  PRODUCT_VARIANT_FAMILY,
  PRODUCT_VARIANT_NON_FAMILY,
  CART_ACTION_ADD,
  CART_ACTION_REMOVE,
} from '~/constants/analytics';
import pathOr from '~/utils/fp/path-or';

export const changedServicesFrom = (
  currentGroupedServices = {},
  newGroupedServices = {}
) => {
  if (equals(currentGroupedServices, newGroupedServices)) return [];

  const diffByPropAndDirection = (property, direction) =>
    objectDiffBySpecificType(
      currentGroupedServices[property],
      newGroupedServices[property],
      0,
      direction
    );

  return [
    ...diffByPropAndDirection('globals'),
    ...diffByPropAndDirection(OUTBOUND_FLIGHT, OUTBOUND_FLIGHT_NAME),
    ...diffByPropAndDirection(RETURN_FLIGHT, RETURN_FLIGHT_NAME),
  ];
};

export const changedAncillariesFrom = (
  currentAncillariesGroupedByPassenger = [],
  newAncillariesGroupedByPassenger = []
) => {
  if (equals(currentAncillariesGroupedByPassenger, newAncillariesGroupedByPassenger)) {
    return [];
  }

  const affectedPassengerIds = uniq(
    [...currentAncillariesGroupedByPassenger, ...newAncillariesGroupedByPassenger].map(
      prop('passengerId')
    )
  );

  const diffByPassengerAndPropAndDirection = (passengerId, flightProp, direction) =>
    objectDiffBySpecificType(
      (currentAncillariesGroupedByPassenger.find(propEq('passengerId', passengerId)) ||
        {})[flightProp],
      (newAncillariesGroupedByPassenger.find(propEq('passengerId', passengerId)) || {})[
        flightProp
      ],
      passengerId,
      direction
    );

  let changedAncillaries = [];

  affectedPassengerIds.forEach((passengerId) => {
    changedAncillaries = [
      ...changedAncillaries,
      ...diffByPassengerAndPropAndDirection(passengerId, 'generic'),
      ...diffByPassengerAndPropAndDirection(
        passengerId,
        OUTBOUND_FLIGHT,
        OUTBOUND_FLIGHT_NAME
      ),
      ...diffByPassengerAndPropAndDirection(
        passengerId,
        RETURN_FLIGHT,
        RETURN_FLIGHT_NAME
      ),
    ];
  });

  return changedAncillaries;
};

export const changedSeatsFrom = (
  currentSeatsGroupedByPassenger = [],
  newSeatsGroupedByPassenger = []
) => {
  if (equals(currentSeatsGroupedByPassenger, newSeatsGroupedByPassenger)) {
    return [];
  }

  const affectedPassengerIds = uniq(
    [...currentSeatsGroupedByPassenger, ...newSeatsGroupedByPassenger].map(prop('id'))
  );

  const diffByPassengerAndPropAndDirection = (passengerId, seatProp, direction) =>
    objectDiffBySeatCode(
      (currentSeatsGroupedByPassenger.find(propEq('id', passengerId)) || {})[seatProp],
      (newSeatsGroupedByPassenger.find(propEq('id', passengerId)) || {})[seatProp],
      passengerId,
      direction
    );

  let changedSeats = [];

  affectedPassengerIds.forEach((passengerId) => {
    changedSeats = [
      ...changedSeats,
      ...diffByPassengerAndPropAndDirection(
        passengerId,
        `${OUTBOUND_FLIGHT_NAME}Seat`,
        OUTBOUND_FLIGHT_NAME
      ),
      ...diffByPassengerAndPropAndDirection(
        passengerId,
        `${RETURN_FLIGHT_NAME}Seat`,
        RETURN_FLIGHT_NAME
      ),
    ];
  });

  return changedSeats;
};

export const getWdcMembershipVariant = ({
  membership,
  isWdc,
  isStandardWdcUser,
  isEcommWdcGroup,
} = {}) => {
  let variant = WDC_MEMBERSHIP_NAME_MAP.get(membership);
  if (isWdc && isStandardWdcUser && isEcommWdcGroup) {
    variant = 'Upgrade';
  }

  return variant;
};

const objectDiffBySpecificType = (
  currentAncillaries = [],
  newAncillaries = [],
  passengerNumber,
  direction
) => {
  const diffObject = [];

  currentAncillaries.forEach((_, index) => {
    const isMissingFromNewAncillaries = !newAncillaries.some(
      propEq('specificType', currentAncillaries[index].specificType)
    );
    if (isMissingFromNewAncillaries) {
      currentAncillaries[index].id = mapSpecificTypeToId(
        currentAncillaries[index].specificType
      );
      diffObject.push({
        ...currentAncillaries[index],
        action: CART_ACTION_REMOVE,
        passengerNumber,
        direction,
      });
    }
  });

  newAncillaries.forEach((_, index) => {
    const isMissingFromCurrentAncillaries = !currentAncillaries.some(
      propEq('specificType', newAncillaries[index].specificType)
    );
    if (isMissingFromCurrentAncillaries) {
      newAncillaries[index].id = mapSpecificTypeToId(newAncillaries[index].specificType);
      diffObject.push({
        ...newAncillaries[index],
        action: CART_ACTION_ADD,
        passengerNumber,
        direction,
      });
    }
  });

  return diffObject;
};

const objectDiffBySeatCode = (
  currentSeat = {},
  newSeat = {},
  passengerNumber,
  direction
) => {
  const diffObject = [];

  if (newSeat.code === currentSeat.code) return diffObject;

  if (isNotUndefined(newSeat.code)) {
    diffObject.push({ ...newSeat, action: CART_ACTION_ADD, passengerNumber, direction });
  }

  if (isNotUndefined(currentSeat.code)) {
    diffObject.push({
      ...currentSeat,
      action: CART_ACTION_REMOVE,
      passengerNumber,
      direction,
    });
  }

  return diffObject;
};

export const mapServiceToEcommerceItem = curry((metadata, changedService) => {
  const {
    direction: flight = OUTBOUND_FLIGHT_NAME,
    id: name,
    feeCode: code,
    displayCount: count,
  } = changedService;

  const ecommerceItem = {
    type: 'service',
    metadata,
    data: {
      name,
      code,
      flight,
      price: extractPriceFromAncillary(changedService),
      count,
      analytics: {
        isDisabled: false,
      },
    },
  };

  if (changedService.id === ANCILLARY_CAR_RENTAL) {
    ecommerceItem.data.analytics.list = STEP_SERVICES;
  } else if (changedService.id === 'airportTransfers' && changedService.variant) {
    ecommerceItem.data.analytics.variant = changedService.variant;
  }

  if (SIMPLE_LOCATION_TRACKABLE_PRODUCTS.includes(changedService.id)) {
    ecommerceItem.data.analytics.location = pathOr(
      '',
      `purchaseLocations.${changedService.id}`,
      metadata
    );
  }

  return {
    action: changedService.action,
    ...ecommerceItem,
  };
});

export const mapAncillaryToEcommerceItem = curry((metadata, changedAncillary) => {
  const {
    direction: flight,
    id: name,
    feeCode: code,
    displayCount: count,
    passengerNumber,
    coupon,
  } = changedAncillary;
  const price = extractPriceFromAncillary(changedAncillary);
  const ecommerceItem = {
    type: 'ancillary',
    metadata,
    data: {
      passengerNumber,
      name,
      code,
      flight,
      price,
      count,
      analytics: {
        isDisabled: false,
        coupon,
      },
    },
  };

  if (name === 'baggage') {
    ecommerceItem.data.analytics.variant = `${
      BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP.get(changedAncillary.specificType).weightInKg
    } kg`;
    ecommerceItem.data.analytics.unitPrice = (price.amount || 0) / count;
    ecommerceItem.data.analytics.quantity = count;
  } else if (name === ANCILLARY_CABIN_BAGGAGE) {
    // tracking is disabled for free stuff
    ecommerceItem.data.analytics.isDisabled = true;
  }

  if (SIMPLE_LOCATION_TRACKABLE_PRODUCTS.includes(name)) {
    const location = pathOr('', `purchaseLocations.${name}`, metadata);
    if (location === STEP_SELECT_FLIGHT) {
      ecommerceItem.data.analytics.quantity = pathOr(
        '',
        'customDimensions.outbound.numberOfPassengers',
        metadata
      );
    }
    ecommerceItem.data.analytics.location = pathOr(
      '',
      `purchaseLocations.${name}`,
      metadata
    );
  } else if (PER_PASSENGER_LOCATION_TRACKABLE_PRODUCTS.includes(name)) {
    ecommerceItem.data.analytics.location = pathOr(
      '',
      `purchaseLocations.${name}.${changedAncillary.passengerNumber}.location`,
      metadata
    );
  }

  return {
    action: changedAncillary.action,
    ...ecommerceItem,
  };
});

export const mapSeatToEcommerceItem = curry((metadata, changedSeat) => {
  const { direction: flight, id: name, feeCode: code, code: seatCode } = changedSeat;

  const ecommerceItem = {
    type: 'ancillary',
    metadata,
    data: {
      passengerNumber: changedSeat.passengerNumber,
      name,
      code,
      flight,
      price: extractPriceFromAncillary(changedSeat),
      count: 1,
      analytics: {
        isDisabled: false,
        variant: seatCode,
        location: pathOr('', 'purchaseLocations.seat', metadata),
      },
    },
  };

  return {
    action: changedSeat.action,
    ...ecommerceItem,
  };
});

const extractPriceFromAncillary = (ancillary) => {
  const priceProperty = isNotNil(ancillary.promotedPrice) ? 'promotedPrice' : 'price';

  return {
    amount: ancillary[priceProperty].amount,
    currencyCode: ancillary[priceProperty].currencyCode,
  };
};

export const trackCartAction = (ecommerceItem) =>
  EcommerceService[ecommerceItem.action](ecommerceItem);

export const mapFareToEcommerceItem = curry(
  (metadata, isEcommerceTrackingDisabled, fare, purchaseLocation, pastPrice) => {
    const {
      direction,
      isWdc,
      flightPriceDetail: { total: price },
      bundle: { code: bundle },
      isFamilyBundle,
      flightSellKey,
      fareSellKey,
      fees,
    } = fare;
    const variant = isFamilyBundle ? PRODUCT_VARIANT_FAMILY : PRODUCT_VARIANT_NON_FAMILY;
    const filteredFees = removeFee(fees, FEE_CODES[FEE_NAME_INFANT]);

    return {
      type: 'fare',
      metadata,
      data: {
        flight: direction,
        fare,
        isWdc,
        price,
        flightSellKey,
        analytics: {
          isDisabled: isEcommerceTrackingDisabled,
          location: purchaseLocation,
          pastPrice,
          ...(isNotBasicBundle(bundle) ? { variant } : {}),
        },
        children: (filteredFees || []).map(
          mapFeeToEcommerceItem(
            metadata,
            direction,
            flightSellKey,
            fareSellKey,
            isEcommerceTrackingDisabled,
            bundle
          )
        ),
      },
    };
  }
);

const mapFeeToEcommerceItem = curry(
  (
    metadata,
    direction,
    flightSellKey,
    fareSellKey,
    isEcommerceTrackingDisabled,
    bundle,
    fee
  ) => {
    const { count, code, price, coupon } = fee;

    return {
      type: 'fee',
      metadata,
      data: {
        flight: direction,
        code,
        price,
        flightSellKey,
        fareSellKey,
        bundle,
        analytics: {
          isDisabled: isEcommerceTrackingDisabled,
          quantity: count,
          coupon,
        },
      },
    };
  }
);

const removeFee = (fees, feeCode) => {
  const feeIndex = fees.findIndex(propEq('code', feeCode));
  return fees.filter((fee, index) => index !== feeIndex);
};
