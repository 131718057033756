import not from '~/utils/fp/not';
import hevolve from '~/utils/object/hevolve';
import hassocPath from '~/utils/object/hassoc-path';
import { mutationAssocPath } from '~/utils/store';
import * as m from './mutation-types';

export default {
  [m.SET_IS_SEAT_SELECTION_HAS_UNCONFIRMED_CHANGES_FROM_PREVIOUS_FLAG]: mutationAssocPath(
    'itinerary.isSeatSelectionHasUnconfirmedChangesFromPrevious'
  ),

  [m.SHOW_SEAT_SELECTION_MODAL]: hassocPath(
    'itinerary.isSeatSelectionModalVisible',
    true
  ),
  [m.HIDE_SEAT_SELECTION_MODAL]: hassocPath(
    'itinerary.isSeatSelectionModalVisible',
    false
  ),
  [m.SET_SEAT_SELECTION_MODAL_VISIBILITY]: mutationAssocPath(
    'itinerary.isSeatSelectionModalVisible'
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_WDC_TARGETED_MODAL]: hassocPath('booking.isWdcTargetedModalVisible', true),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_WDC_TARGETED_MODAL]: hassocPath('booking.isWdcTargetedModalVisible', false),

  /**
   * @type {(state: State, value: boolean) => void}
   */
  [m.SET_WDC_TARGETED_MODAL_VISIBILITY]: mutationAssocPath(
    'booking.isWdcTargetedModalVisible'
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_NEWSLETTER_CONSENT_MODAL]: hassocPath(
    'user.isNewsletterConsentModalVisible',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_NEWSLETTER_CONSENT_MODAL]: hassocPath(
    'user.isNewsletterConsentModalVisible',
    false
  ),

  /**
   * @type {state: State, value: boolean) => void}
   */
  [m.SET_NEWSLETTER_CONSENT_MODAL_VISIBILITY]: mutationAssocPath(
    'user.isNewsletterConsentModalVisible'
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_PHONE_NUMBER_UPDATE_MODAL]: hassocPath(
    'states.isPhoneNumberUpdateModalVisible',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_PHONE_NUMBER_UPDATE_MODAL]: hassocPath(
    'states.isPhoneNumberUpdateModalVisible',
    false
  ),

  /**
   * @type {state: State, value: boolean) => void}
   */
  [m.SET_PHONE_NUMBER_UPDATE_MODAL_VISIBILITY]: mutationAssocPath(
    'states.isPhoneNumberUpdateModalVisible'
  ),

  [m.TOGGLE_USER_MENU]: hevolve({
    states: {
      isUserMenuVisible: not,
    },
  }),

  [m.TOGGLE_IS_SUMMARY_ON_MOBILE_VISIBLE]: (state) => {
    state.states.isSummaryOnMobileVisible = !state.states.isSummaryOnMobileVisible;
  },

  [m.USER_DATA_LOADING]: (state, value) => {
    state.user.isLoading += value ? 1 : -1;
  },

  [m.AIRPORT_TRANSFER_MODAL_VISIBILITY]: (state, value) => {
    state.itineraryOverview.isAirportTransferModalVisible = value;
  },

  [m.AIRPORT_PARKING_MODAL_VISIBILITY]: (state, value) => {
    state.itineraryOverview.isAirportParkingModalVisible = value;
  },

  [m.ACORD_SERVICE_MODAL_VISIBILITY]: (state, value) => {
    state.itineraryOverview.isAcordServiceModalVisible = value;
  },

  [m.MODIFY_SERVICES_MODAL_VISIBILITY]: (state, value) => {
    state.itineraryOverview.isModifyServicesModalVisible = value;
  },

  [m.SET_HAS_TO_REOPEN_UPSELL_MODAL]: (state, value) => {
    state.itineraryOverview.hasToReOpenUpsellModal = value;
  },

  [m.ACORD_SERVICE_COMPARISON_MODAL_VISIBILITY]: (state, value) => {
    state.itineraryOverview.isAcordServiceComparisonModalVisible = value;
  },

  [m.UPDATE_BOOKING_FLOW_VISIBILITY]: (state, value) => {
    state.states.isBookingFlowVisible = value;
  },

  [m.UPDATE_BOOKING_FLOW_LOADED_STATE]: (state, value) => {
    state.states.isBookingFlowLoaded = value;
  },

  [m.SHOW_PAYMENT_FAILED_MODAL]: (state) => {
    state.booking.isPaymentFailedModalVisible = true;
  },

  [m.HIDE_PAYMENT_FAILED_MODAL]: (state) => {
    state.booking.isPaymentFailedModalVisible = false;
  },

  [m.SET_SEAT_SELECTION_EXIT_INTENT_VISIBILITY]: (state, value) => {
    state.itinerary.isSeatSelectionExitIntentModalVisible = value;
  },

  [m.UPDATE_TRAVEL_INSURANCE_INFO_MODAL_VISIBILITY]: (state, value) => {
    state.states.isTravelInsuranceInfoModalVisible = value;
  },

  [m.UPDATE_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY]: (state, value) => {
    state.states.isCancellationInsuranceInfoModalVisible = value;
  },

  [m.UPDATE_TRAVEL_AND_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY]: (state, value) => {
    state.states.isTravelAndCancellationInsuranceInfoModalVisible = value;
  },

  [m.SET_IS_MAIN_PAGE]: (state, value) => {
    state.states.isMainPage = value;
  },

  [m.SET_NAVIGATION_CLASSLIST]: (state, value) => {
    state.navigation.navigationClassList = value;
  },

  [m.SET_CAPTCHA_MODAL_VISIBILITY]: (state, value) => {
    state.captcha.isCaptchaModalVisible = value;
  },

  [m.HIDE_CAPTCHA_MODAL]: (state) => {
    state.captcha.isCaptchaModalVisible = false;
  },

  [m.SHOW_CAPTCHA_MODAL]: (state) => {
    state.captcha.isCaptchaModalVisible = true;
  },

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_PAYMENT_EXIT_INTENT_MODAL]: hassocPath(
    'booking.isPaymentExitIntentModalVisible',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_PAYMENT_EXIT_INTENT_MODAL]: hassocPath(
    'booking.isPaymentExitIntentModalVisible',
    false
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_ADD_FLIGHT_EXIT_INTENT_MODAL]: hassocPath(
    'itineraryOverview.isAddFlightExitIntentModalVisible',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_ADD_FLIGHT_EXIT_INTENT_MODAL]: hassocPath(
    'itineraryOverview.isAddFlightExitIntentModalVisible',
    false
  ),

  [m.SET_HERO_BANNER_INFO]: (state, { propName, value } = {}) => {
    state.states.heroBannerInfo[propName] = value;
  },

  [m.SET_IS_CAPTCHA_HANDLED]: hassocPath('captcha.isCaptchaHandled', true),

  [m.UNSET_IS_CAPTCHA_HANDLED]: hassocPath('captcha.isCaptchaHandled', false),

  [m.SET_IS_RESCUE_FARE_CODE_VISIBLE]: (state, value) => {
    state.search.isRescueFareCodeVisible = value;
  },

  [m.SET_SELECTED_SEARCH_TAB]: (state, value) => {
    state.search.selectedTab = value;
  },

  [m.SET_YELLOW_RIBBON_MESSAGE]: mutationAssocPath('states.yellowRibbonMessage'),

  [m.DISMISS_YELLOW_RIBBON]: hassocPath('states.isYellowRibbonDismissed', true),

  [m.SET_IS_FLIGHT_ADDITION_REQUESTED]: (state, value) => {
    state.states.isFlightAdditionRequested = value;
  },

  [m.SET_CALL_CENTER_INFORMATION]: mutationAssocPath('states.callCenterInformation'),
};
