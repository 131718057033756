import curry from '../curry';

/**
 * @typedef {(prop: string, obj: Object<string, any>) => boolean} HasInFn
 */

/**
 * @type HasInFn
 */
export const _hasIn = (prop, obj) => prop in obj;

/**
 * Returns whether or not an object has a property (own or "inherited") with the
 * specified name
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/hasIn.js
 *
 * @type HasInFn
 * @example
 *
 *      var hasName = hasIn('name');
 *      hasName({name: 'alice'});   //=> true
 *      hasName({name: 'bob'});     //=> true
 *      hasName({});                //=> false
 */
const hasIn = curry(_hasIn);

export default hasIn;
