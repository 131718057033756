import { _has } from '~/utils/fp/has';
import last from '~/utils/fp/last';
import isEmpty from '../is-empty';

/**
 * Mutable assocPath which mutates the object in place (sub objects as well).
 *
 * @type import('../../fp/assoc-path').AssocPathFn
 */
const massocPath = (path, value, obj) => {
  if (isEmpty(path)) return obj;
  const splitPath = path.split('.');
  loop(splitPath, value, obj);
  return obj;
};

const loop = (splitPath, value, obj) => {
  const [prop, ...newSplitPath] = splitPath;
  if (!prop) return obj;

  if (newSplitPath.length > 0 && last(newSplitPath)) {
    const subObj = _has(prop, obj) ? obj[prop] : {};
    value = loop(newSplitPath, value, subObj);
  }

  obj[prop] = value;
  return obj;
};

export default massocPath;
