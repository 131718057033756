import * as m from './mutation-types';

export default {
  [m.SET_ACTIVE_BIO_ARTICLE](state, payload) {
    state.activeBioArticle = payload;
  },

  [m.RESET_BIO_ARTICLE](state) {
    state.activeBioArticle = null;
  },

  [m.SET_INFORMATION_AND_SERVICES_LIST](state, payload) {
    state.informationAndServicesList = payload;
  },

  [m.RESET_INFORMATION_AND_SERVICES_LIST](state) {
    state.informationAndServicesList = [];
  },

  [m.SET_TOP_FLIGHTS_LIST](state, payload) {
    state.topFlightsList = payload;
  },

  [m.RESET_TOP_FLIGHTS_LIST](state) {
    state.topFlightsList = [];
  },

  [m.SET_PAGE_CONTENT](state, payload) {
    state.pageContent = payload;
  },

  [m.SET_HELP_CENTER_SIDE_NAVIGATION_ITEMS](state, payload) {
    state.helpCenterSideNavigationItems = payload;
  },

  [m.RESET_PAGE_CONTENT](state) {
    state.pageContent = null;
  },

  [m.SET_CURRENT_PAGE](state, payload) {
    state.currentPage = payload;
  },

  [m.SET_DESTINATION_CONTENT](state, payload) {
    state.destinationContent = payload;
  },

  [m.RESET_DESTINATION_CONTENT](state) {
    state.destinationContent = null;
  },

  [m.SET_NEWS](state, payload) {
    state.news = payload;
  },

  [m.SET_NEWS_CONTENT](state, payload) {
    state.newsContent = payload;
  },

  [m.RESET_NEWS_CONTENT](state) {
    state.newsContent = null;
  },

  [m.SET_FOOTER_NAVIGATION_ITEMS](state, payload) {
    state.footerNavigationItems = payload;
  },

  [m.RESET_FOOTER_NAVIGATION_ITEMS](state) {
    state.footerNavigationItems = [];
  },

  [m.SET_PAGE_TRANSLATION_LINKS](state, payload) {
    state.pageTranslationLinks = payload;
  },

  [m.RESET_PAGE_TRANSLATION_LINKS](state) {
    state.pageTranslationLinks = null;
  },
};
