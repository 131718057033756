import { sendGaEvent } from '~/utils/analytics';

export const sendPartialPaymentMethodEvent = (action, label) => {
  sendPaymentMethodEvent(`${action} (partial payment)`, label);
};

export const sendPaymentMethodEvent = (action, label = 'not set') => {
  sendGaEvent({ category: 'Payment method', action, label });
};

export const sendInvoicingEvent = (isPrivate, isAddServicesFlow) => {
  let invoicingEventLabel = isPrivate ? 'Private individual' : 'Company pays';

  if (isAddServicesFlow) {
    invoicingEventLabel = 'Not set';
  }

  sendGaEvent({ category: 'Invoicing', action: 'Selected', label: invoicingEventLabel });
};

export const sendMcpEvent = (mcpSelectionSource, isDefaultCurrencySelected, currency) => {
  const category = mcpSelectionSource || 'MCP on payment page';
  const action = isDefaultCurrencySelected
    ? 'Default currency selected'
    : 'Other currency selected';
  sendGaEvent({ category, action, label: currency });
};

export const sendPaymentInitiatedEvent = (action) => {
  sendGaEvent({ category: 'Payment initiated', action });
};
