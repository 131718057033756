import { isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';
import * as globalValueGetters from '~/store/modules/global-value/getters';
import * as globalValueActions from '~/store/modules/global-value/actions';
import isNotEmpty from '../object/is-not-empty';

export const getCultures = (state = {}) => {
  if (isSSRBuild && (isNotEmpty(state) || isServer())) {
    return globalValueGetters.getCultures(state);
  }

  return window.cultures || globalValueGetters.getCultures(state);
};

export const setCultures = (value, store = {}) => {
  if (isSSRBuild && isServer()) {
    globalValueActions.setCultures(store, value);
    return;
  }

  window.cultures = value;
};
