/* eslint import/no-cycle: "warn" */
import {
  CREDIT_CARD_TYPE_CODE_BANCONTACT,
  CURRENCY_CODE_EUR,
  PAYMENT_OPTION_CREDIT_CARD,
  PAYMENT_OPTION_STORED_CREDIT_CARD,
  PAYMENT_OPTION_BANK_TRANSFER,
  PAYMENT_OPTION_I_DEAL,
  PAYMENT_OPTION_PAY_BY_LINK,
  PAYMENT_OPTION_TRUSTLY,
  STEP_SELECT_FLIGHT,
  STEP_PASSENGERS,
  STEP_SEAT_SELECTION,
  STEP_SERVICES,
  STEP_WDC,
  STEP_NOTHING,
  BUNDLE_BASIC,
  BUNDLE_MIDDLE,
  BUNDLE_PLUS,
  CREDIT_CARD_LIST_ORDER,
  BASIC_BOOKING_FLOW_STEPS,
  CARRIER_WIZZ_AD,
  CARRIER_WIZZ_UK,
  CARRIER_WIZZ_HUN,
  COUNTRY_CODE_HU,
  CARRIER_WIZZ_MT,
  BASIC_FARE_LOCK_FLOW_STEPS,
  PAYMENT_OPTION_GOOGLE_PAY,
} from '~/constants';
import has from '~/utils/fp/has';
import allPass from '~/utils/fp/all-pass';
import anyPass from '~/utils/fp/any-pass';
import every from '~/utils/fp/every';
import without from '~/utils/fp/without';
import converge from '~/utils/fp/converge';
import includedIn from '~/utils/fp/included-in';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import path from '~/utils/fp/path';
import some from '~/utils/fp/some';
import values from '~/utils/fp/values';
import and from '~/utils/fp/and';
import compose from '~/utils/fp/compose';
import either from '~/utils/fp/either';
import propEqTrue from '~/utils/fp/prop-eq-true';
import ifElse from '~/utils/fp/if-else';
import always from '~/utils/fp/always';
import takeWhile from '~/utils/fp/take-while';
import equals from '~/utils/fp/equals';
import takeLastWhile from '~/utils/fp/take-last-while';
import both from '~/utils/fp/both';
import complement from '~/utils/fp/complement';
import filter from '~/utils/fp/filter';
import sort from '~/utils/fp/sort';
import isNotEmpty from '~/utils/object/is-not-empty';
import notIncludes from '~/utils/fp/not-includes';
import isNil from '~/utils/object/is-nil';
import gte from '~/utils/fp/gte';
import lte from '~/utils/fp/lte';
import collect from '~/utils/fp/collect';
import toLowerCase from '~/utils/fp/to-lower-case';
import * as PaymentUtils from '~/utils/booking/payment';
import * as BookingUtils from '~/utils/booking/booking';
import __ from '~/utils/fp/__';
import gt from '~/utils/fp/gt';
import * as coreBookingGetters from '../core-booking/getters';
import * as featureGetters from '../feature/getters';
import * as flightSelectGetters from '../flight-select/getters';
// note: don't move this above flight select it will trigger the invisible bomb of
//  cyclic dep. with this order it is still able to work though
import * as ancillariesGetters from '../ancillaries/getters';
import * as passengersGetters from '../passengers/getters';
import * as summaryGetters from '../summary/getters';
import * as upsellGetters from '../upsell/getters';
import * as userGetters from '../user/getters';
import * as volatileGetters from '../volatile/getters';

const bookingProp = (prop) => path(`booking.${prop}`);
const bookingPropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, `booking.${prop}`, state)
);
const bookingVolatileProp = curry((_path, state) => path(_path, state.booking.volatile));

export const canQueryBundleUpsellInfo = (state) => {
  if (
    coreBookingGetters.isAddServicesFlow(state) ||
    coreBookingGetters.isFlightChangeOrRebookFlow(state) ||
    summaryGetters.isFareLockAdded(state) ||
    isGroupBooking(state) ||
    coreBookingGetters.isFareLockFinalizeFlow(state)
  ) {
    return false;
  }

  return !passengersGetters.hasAnyPassengerPrivilegePassActivated(state);
};

/**
 * @type {(state: State) => boolean}
 */
export const isBundleUpsellAvailable = (state) =>
  !featureGetters.isSmartBundleEnabled(state) &&
  canQueryBundleUpsellInfo(state) &&
  upsellGetters.isUpsellOverPriceMinimum(state);

/**
 * @type {(state: State) => String}
 */
export const kayakClickId = bookingProp('kayakClickId');

/**
 * @type {(state: State) => String}
 */
export const wegoClickId = bookingProp('wegoClickId');

/**
 * @type {(state: State) => []}
 */
export const wdcAvailableMemberships = bookingProp('wdc.availableMemberships');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcRequiredMembership = bookingProp('wdc.requiredMembership');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcRequiredMembershipMembership = bookingProp(
  'wdc.requiredMembership.membership'
);

/**
 * @type {(state: State) => boolean}
 */
export const isContactRegisteredForNewsletter = bookingProp(
  'contact.isRegisteredForNewsletter'
);

/**
 * @type {(state: State) => boolean}
 */
export const newsletterSubscription = bookingProp('preferences.newsletterSubscription');

/**
 * @type {(state: State) => boolean}
 */
export const newsletterSubscriptionLocation = bookingProp(
  'newsletterSubscriptionLocation'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const fareLockFinalizeData = bookingProp('fareLockFinalizeData');

/**
 * @type {(state: State) => boolean}
 */
export const hasFareLockFinalizeData = compose(
  some(isNotEmpty),
  values,
  fareLockFinalizeData
);

export const isBasicFareBundleLockedOnAllFlights = (state) => {
  if (!hasFareLockFinalizeData(state)) return false;
  return [
    outboundBundle(state),
    ...(isRoundTrip(state) ? [returnBundle(state)] : []),
  ].every((code) => BookingUtils.isBasicBundle(code || ''));
};

export const fares = bookingProp('fares');

export const currentStepName = bookingProp('step');

export const isWdcStep = (state) => currentStepName(state) === STEP_WDC;

export const shouldGoToPayment = (state) => {
  return [
    STEP_SELECT_FLIGHT,
    STEP_PASSENGERS,
    STEP_SEAT_SELECTION,
    STEP_SERVICES,
  ].includes(currentStepName(state));
};

export const currentStepNameWithoutStepNothing = (state) => {
  if (currentStepName(state) === STEP_NOTHING) return;
  return currentStepName(state);
};

export const isCommonPlusBundle = (state) =>
  [fares(state).outbound, fares(state).return || {}].some((fare) =>
    BookingUtils.isPlusBundle(fare.bundle || '')
  );

export const isCommonMiddleBundle = (state) =>
  [fares(state).outbound, fares(state).return || {}].some((fare) =>
    BookingUtils.isMiddleBundle(fare.bundle || '')
  );

export const isCommonBasicBundle = (state) =>
  [fares(state).outbound, fares(state).return || {}].some((fare) =>
    BookingUtils.isBasicBundle(fare.bundle || '')
  );

export const commonCurrentBundle = (state) => {
  if (isCommonBasicBundle(state)) {
    return BUNDLE_BASIC;
  } else if (isCommonPlusBundle(state)) {
    return BUNDLE_PLUS;
  } else if (isCommonMiddleBundle(state)) {
    return BUNDLE_MIDDLE;
  }

  return BUNDLE_BASIC;
};

export const selectedBundles = (state) => [
  outboundBundle(state),
  ...(isRoundTrip(state) ? [returnBundle(state)] : []),
];

export const outboundBundle = bookingProp('fares.outbound.bundle');

export const returnBundle = bookingProp('fares.return.bundle');

/**
 * @type {(state: State) => boolean}
 */
export const isSameBundleSelectedForBothFlights = converge(equals, [
  outboundBundle,
  returnBundle,
]);

export const isOutboundBundleGoOrPlus = (state) =>
  [BUNDLE_MIDDLE, BUNDLE_PLUS].includes(outboundBundle(state));
export const isReturnBundleGoOrPlus = (state) =>
  [BUNDLE_MIDDLE, BUNDLE_PLUS].includes(returnBundle(state));

export const isOutboundFamilyBundle = bookingProp('fares.outbound.isFamily');

export const isReturnFamilyBundle = bookingProp('fares.return.isFamily');

export const flights = bookingProp('flights');

export const outboundFlightNumber = bookingProp('flights.outbound.flightNumber');

export const returnFlightNumber = bookingProp('flights.return.flightNumber');

export const hoursUntilCheckInAvailable = bookingPropWithFallback(
  24,
  'hoursUntilCheckInAvailable'
);

export const outboundGroupSeatRequest = bookingProp('outboundGroupSeatRequest');

export const returnGroupSeatRequest = bookingProp('isGroupSeatRequest');

export const isGroupSeatRequest = (state) => {
  return outboundGroupSeatRequest(state) || returnGroupSeatRequest(state);
};

export const isDomestic = bookingProp('isDomestic');

export const isWdcPremiumEnabled = bookingProp('isWdcPremiumEnabled');

export const isRoundTrip = (state) =>
  Boolean(state.booking?.flights?.return?.flightSellKey);

export const isWdcBooking = (state) =>
  outboundWdc(state) && (!isRoundTrip(state) || returnWdc(state));

/**
 * @type {(state: State) => boolean}
 */
export const outboundWdc = path('booking.fares.outbound.wdc');

/**
 * @type {(state: State) => boolean}
 */
export const returnWdc = path('booking.fares.return.wdc');

export const isGroupBooking = (state) =>
  BookingUtils.isGroupBooking(numberOfIndependentPassengers(state));

export const numberOfPassengers = (state) =>
  numberOfIndependentPassengers(state) + numberOfInfants(state);

export const numberOfIndependentPassengers = pathOr(
  0,
  'booking.numberOfIndependentPassengers'
);

export const numberOfAdults = bookingProp('adultCount');

export const numberOfChildren = bookingProp('childCount');

export const numberOfInfants = pathOr(0, 'booking.numberOfInfants');

export const hasPaymentOptions = (state) => isNotEmpty(paymentOptions(state));

export const paymentOptions = bookingProp('payment.options');

export const availableCreditCardTypes = bookingPropWithFallback(
  [],
  'payment.options.creditCardPaymentOption.availableCreditCardTypes'
);

export const sortedAvailableCreditCardTypes = compose(
  sort((a, b) => {
    return (
      CREDIT_CARD_LIST_ORDER.indexOf(a.creditCardTypeCode) -
      CREDIT_CARD_LIST_ORDER.indexOf(b.creditCardTypeCode)
    );
  }),
  availableCreditCardTypes
);

export const sortedAndFilteredAvailableCreditCardTypes = compose(
  filter(
    complement(
      either(
        PaymentUtils.isCoBrandedCreditCardType,
        PaymentUtils.isBancontactCreditCardType
      )
    )
  ),
  sortedAvailableCreditCardTypes
);

export const isCoBrandedCreditCardTypeAvailable = compose(
  some(PaymentUtils.isCoBrandedCreditCardType),
  availableCreditCardTypes
);

export const isBankTransferAvailable = (state) => {
  const isAvailable =
    state.booking?.payment?.options?.bankTransferPaymentOption?.isAvailable;
  const unavailableReason =
    state.booking?.payment?.options?.bankTransferPaymentOption?.unavailableReason;
  return isAvailable || (!isAvailable && unavailableReason);
};

export const isVoucherAvailable = bookingPropWithFallback(
  false,
  'payment.options.voucherPaymentOption.isAvailable'
);

export const isCouponAvailable = bookingPropWithFallback(
  false,
  'payment.options.couponOption.isAvailable'
);

export const isWizzAccountAvailable = bookingPropWithFallback(
  false,
  'payment.options.customerAccountPaymentOption.isAvailable'
);

export const isStoredCreditCardAvailable = (state) =>
  and(
    notIncludes(
      coreBookingGetters.paymentCurrencyCode(state),
      bookingPropWithFallback(
        [],
        'payment.options.storedCreditCardPaymentOption.disabledForCurrencies'
      )(state)
    ),
    bookingPropWithFallback(
      false,
      'payment.options.storedCreditCardPaymentOption.isAvailable'
    )(state)
  );

export const isCardInGooglePayAvailable = bookingProp(
  'payment.isCardInGooglePayAvailable'
);
export const isGooglePayReady = bookingProp('payment.isGooglePayReady');
export const isGooglePayLoading = bookingProp('payment.isGooglePayLoading');

export const isCreditCardAvailable = bookingPropWithFallback(
  false,
  'payment.options.creditCardPaymentOption.isAvailable'
);

export const isIdealAvailable = bookingPropWithFallback(
  false,
  'payment.options.iDealPaymentOption.isAvailable'
);

export const idealIssuers = ifElse(
  isIdealAvailable,
  pathOr([], 'booking.payment.options.iDealPaymentOption.issuers'),
  always([])
);

export const selectedIdealIssuerId = bookingPropWithFallback(
  '',
  'payment.selectedIdealIssuer.id'
);

export const isPayByLinkAvailable = bookingPropWithFallback(
  false,
  'payment.options.payByLinkPaymentOption.isAvailable'
);

export const isFareLockPaymentAvailable = bookingPropWithFallback(
  false,
  'payment.options.fareLockPaymentOption.isAvailable'
);

export const isTrustlyAvailable = bookingPropWithFallback(
  false,
  'payment.options.trustlyPaymentOption.isAvailable'
);

export const isGooglePayAvailable = bookingPropWithFallback(
  false,
  'payment.options.googlePayPaymentOption.isAvailable'
);

export const wizzAccountBalance = (state) =>
  state.booking?.payment?.options?.customerAccountPaymentOption?.availableAmount;

export const selectedPaymentOption = (state) => state.booking?.payment?.selected;

export const isCreditCardSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_CREDIT_CARD;

export const isStoredCreditCardSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_STORED_CREDIT_CARD;

export const isBankTransferSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_BANK_TRANSFER;

export const isIdealSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_I_DEAL;

export const isPayByLinkSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_PAY_BY_LINK;

export const isTrustlySelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_TRUSTLY;

export const isGooglePaySelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_GOOGLE_PAY;

export const isCreditCardPaymentSelected = (state) =>
  selectedPaymentOption(state) === PAYMENT_OPTION_CREDIT_CARD;

export const creditCard = (state) => state.booking?.payment?.creditCard;

export const creditCardNumber = (state) => state.booking?.payment?.creditCard?.cardNumber;

export const creditCardTypeCode = (state) =>
  PaymentUtils.determineCreditCardTypeCode(creditCardNumber(state));

export const isBankContactCardType = (state) =>
  creditCardTypeCode(state) === CREDIT_CARD_TYPE_CODE_BANCONTACT;

export const isBancontactPaymentSelected = both(
  isCreditCardPaymentSelected,
  isBankContactCardType
);

export const hasBancontactPaymentError = (state) =>
  isBancontactPaymentSelected(state) &&
  coreBookingGetters.paymentCurrencyCode(state) !== CURRENCY_CODE_EUR;

export const contact = (state) => state.booking?.contact;

const contactCountry = bookingPropWithFallback('', 'contact.country');

export const isHungarianContact = compose(
  equals(COUNTRY_CODE_HU),
  toLowerCase,
  contactCountry
);

export const isPrivateContact = bookingPropWithFallback(true, 'contact.isPrivate');

export const isBillingValid = bookingPropWithFallback(false, 'contact.isBillingValid');

export const payment = (state) => state.booking?.payment;

export const groupPartialPayment = (state) => state.booking?.payment?.groupPartialPayment;

export const bankTransferAccount = bookingProp('payment.bankTransferAccount');

export const bankAccounts = (state) => {
  const bankAccounts =
    state.booking?.payment?.options?.bankTransferPaymentOption?.bankAccounts;
  return isBankTransferAvailable(state) && bankAccounts ? bankAccounts : [];
};

export const availableBankAccounts = (state) => {
  return bankAccounts(state)
    .filter((account) =>
      account.availableCurrencies.includes(coreBookingGetters.paymentCurrencyCode(state))
    )
    .reduce((acc, account) => {
      const swiftCode = account.swiftCode;
      if (!has(swiftCode, acc)) {
        acc[swiftCode] = {
          name: account.name,
          availableCurrencies: account.availableCurrencies,
          countryCode: account.countryCode,
          currencyCode: account.currencyCode,
          swiftCode,
        };
      }

      if (account.isDomestic) {
        acc[swiftCode].domestic = account;
      } else {
        acc[swiftCode].international = account;
      }

      return acc;
    }, {});
};

export const flightCarriers = (state) => state.booking?.carrierCodes;

export const outboundCarrierCode = bookingPropWithFallback(
  '',
  'flights.outbound.carrierCode'
);

export const returnCarrierCode = bookingPropWithFallback(
  '',
  'flights.return.carrierCode'
);

const isAnyFlightOperatedBy = (fn) =>
  compose(some(fn), collect([outboundCarrierCode, returnCarrierCode]));

const isOutboundFlightOperatedBy = (fn) =>
  compose(some(fn), collect([outboundCarrierCode]));

const isAnyFlightOperatedByWizzUk = isAnyFlightOperatedBy(
  BookingUtils.isOperatedByWizzUk
);

const isAnyFlightOperatedByWizzAbuDhabi = isAnyFlightOperatedBy(
  BookingUtils.isOperatedByWizzAbuDhabi
);

const isAnyFlightOperatedByWizzMalta = isAnyFlightOperatedBy(
  BookingUtils.isOperatedByWizzMalta
);

const isFlightOperatedByWizzHungary = isOutboundFlightOperatedBy(
  BookingUtils.isOperatedByWizzHungary
);

export const carrierCode = (state) => {
  if (isAnyFlightOperatedByWizzAbuDhabi(state)) return CARRIER_WIZZ_AD;
  if (isAnyFlightOperatedByWizzUk(state)) return CARRIER_WIZZ_UK;
  if (isAnyFlightOperatedByWizzMalta(state)) return CARRIER_WIZZ_MT;
  return CARRIER_WIZZ_HUN;
};

export const isW6Carrier = (state) => isFlightOperatedByWizzHungary(state);

export const passengersWithDisabledSameCheckbox = bookingProp(
  'passengersWithDisabledSameCheckbox'
);
export const shouldSendWdcRenewalRequest = bookingProp('wdc.shouldSendWdcRenewalRequest');
export const outboundWheelchairLimit = bookingProp(
  'flights.outbound.wheelchairLimitExceeded'
);
export const outboundWheelchairLimitExceeded = bookingProp(
  'flights.outbound.wheelchairLimitExceeded.limitExceeded'
);
export const outboundWheelchairLimitCount = bookingProp(
  'flights.outbound.wheelchairLimitExceeded.unavailableCount'
);

export const returnWheelchairLimit = (state) =>
  state.booking?.flights?.return?.wheelchairLimitExceeded;

export const returnWheelchairLimitExceeded = (state) =>
  state.booking?.flights?.return?.wheelchairLimitExceeded?.limitExceeded;

export const returnWheelchairLimitCount = (state) =>
  state.booking?.flights?.return?.wheelchairLimitExceeded?.unavailableCount;

export const isNewBooking = bookingProp('isNewBooking');

export const isAlreadyPaid = (state) => state.booking?.payment?.isPaid;

export const outboundDepartureStation = (state) =>
  state.booking?.flights?.outbound?.departureStation;

export const returnDepartureStation = (state) =>
  state.booking?.flights?.return?.departureStation;

export const outboundArrivalStation = (state) =>
  state.booking?.flights?.outbound?.arrivalStation;

export const returnArrivalStation = (state) =>
  state.booking?.flights?.return?.arrivalStation;

export const isAirportTransferModalVisible = (state) =>
  state.booking?.isAirportTransferModalVisible;

export const airportTransferModalDirection = (state) =>
  state.booking?.isAirportTransferModalDirection;

export const isCancellationInsuranceSelected = (state) =>
  state.booking?.isCancellationInsuranceSelected;

export const isTravelInsuranceSelected = (state) =>
  state.booking?.isTravelInsuranceSelected;

export const isInsurancePolicyAccepted = (state) =>
  state.booking?.acordService?.isInsurancePolicyAccepted;

export const isInsuranceNoneSelected = (state) =>
  state.booking?.acordService?.isInsuranceNoneSelected;

export const isInsuranceForced = (state) =>
  state.booking?.acordService?.isInsuranceForced;

export const outboundDepartureDateTime = (state) =>
  state.booking?.flights?.outbound?.departureDateTime;

export const outboundArrivalDateTime = (state) =>
  state.booking?.flights?.outbound?.arrivalDateTime;

export const outboundDepartureDate = (state) => state.booking?.flights?.outbound?.date;

export const returnDepartureDate = (state) => state.booking.flights?.return?.date;

export const returnDepartureDateTime = (state) =>
  state.booking?.flights?.return?.departureDateTime;

export const returnArrivalDateTime = (state) =>
  state.booking?.flights?.return?.arrivalDateTime;

export const outboundDepartureIata = (state) =>
  state.booking.flights?.outbound?.departureStation;

export const outboundArrivalIata = (state) =>
  state.booking.flights?.outbound?.arrivalStation;

export const hasSelectedCar = (state) => state.booking.hasSelectedCar;

export const bundleUpsellOriginalCommonBundle = (state) =>
  state.booking?.bundleUpsellOriginalCommonBundle;

export const isRestartBookingModalVisible = (state) =>
  state.booking?.isRestartBookingModalVisible;

export const isWdcAdded = bookingProp('wdc.isAdded');

export const defaultPaymentOption = (state) => {
  if (!hasPaymentOptions(state)) return '';

  if (isIdealAvailable(state)) return PAYMENT_OPTION_I_DEAL;
  if (isPayByLinkAvailable(state)) return PAYMENT_OPTION_PAY_BY_LINK;
  if (isGooglePayAvailable(state) && isCardInGooglePayAvailable(state))
    return PAYMENT_OPTION_GOOGLE_PAY;
  if (isStoredCreditCardAvailable(state)) return PAYMENT_OPTION_STORED_CREDIT_CARD;
  if (isCreditCardAvailable(state)) return PAYMENT_OPTION_CREDIT_CARD;
  if (isGooglePayAvailable(state)) return PAYMENT_OPTION_GOOGLE_PAY;
  if (isTrustlyAvailable(state)) return PAYMENT_OPTION_TRUSTLY;
  if (isBankTransferAvailable(state)) return PAYMENT_OPTION_BANK_TRANSFER;

  return PAYMENT_OPTION_CREDIT_CARD;
};

export const defaultStoredCreditCard = (state) => {
  const cards =
    state.booking?.payment?.options?.storedCreditCardPaymentOption
      ?.availableStoredCreditCards || [];
  return cards.find(propEqTrue('isDefault')) || cards[0] || null;
};

export const flightChangedFrom = bookingProp('flightChangedFrom');

export const outboundChangedFromFlightId = (state) =>
  state.booking?.flightChangedFrom?.outboundFlightFlightSellKey;

export const returnChangedFromFlightId = (state) =>
  state.booking?.flightChangedFrom?.returnFlightFlightSellKey;

export const outboundChangedFromFlightFarePrice = (state) =>
  state.booking?.flightChangedFrom?.outboundFlightFarePrice;

export const returnChangedFromFlightFarePrice = (state) =>
  state.booking?.flightChangedFrom?.returnFlightFarePrice;

export const outboundFlightChangedFromFareId = (state) =>
  state.booking?.flightChangedFrom?.outboundFlightFareSellKey;

export const returnFlightChangedFromFareId = (state) =>
  state.booking?.flightChangedFrom?.returnFlightFareSellKey;

export const originalDepartureDate = (state) =>
  state.booking?.flightChangedFrom?.departureDate;

export const originalReturnDate = (state) => state.booking?.flightChangedFrom?.returnDate;

export const changeFlightDisclaimerType = (state) =>
  state.booking?.changeFlightDisclaimerType;

export const isCurrentStepNotSet = compose(
  either(isNil, equals(STEP_NOTHING)),
  currentStepName
);

export const isSelectFlightOrPassengersStep = compose(
  includedIn([STEP_SELECT_FLIGHT, STEP_PASSENGERS]),
  currentStepName
);

export const isBeforeSeatSelectionStep = either(
  isCurrentStepNotSet,
  isSelectFlightOrPassengersStep
);

export const isUrgencyModalVisible = bookingProp('isUrgencyModalVisible');

export const isUrgencyModalShowed = bookingProp('isUrgencyModalShowed');

export const isWdcStepAvailable = bookingProp('isWdcStepAvailable');

export const isWdcSavingModalVisible = bookingProp('isWdcSavingModalVisible');

export const isCurrencyChangeWarningDismissed = bookingProp(
  'isCurrencyChangeWarningDismissed'
);

export const isOtaFeeWarningDismissed = bookingProp('isOtaFeeWarningDismissed');

export const isAcordServiceInfoModalTrackedFromPayment = (state) =>
  state.booking?.isAcordServiceInfoModalTracked?.payment;

export const isAcordServiceInfoModalTrackedFromServices = (state) =>
  state.booking?.isAcordServiceInfoModalTracked?.services;

export const isAcordServiceInfoModalTrackedFromItinerary = (state) =>
  state.booking?.isAcordServiceInfoModalTracked?.itinerary;

export const stepsCount = (state) => stepSet(state).length;

export const progressBarCurrentStepNumber = (state) => {
  const currentStepNumber = stepSet(state).indexOf(currentStepName(state));
  return currentStepNumber + 1;
};

export const isCaptchaRequired = bookingProp('contact.isCaptchaRequired');

export const isLoggedInAfterSelect = bookingProp('isLoggedInAfterSelect');

export const stepSet = (state) => {
  const excludedSteps = [
    ...(isPassengersStepNecessary(state) ? [] : [STEP_PASSENGERS]),
    ...(isSeatSelectionStepNecessary(state) ? [] : [STEP_SEAT_SELECTION]),
    ...(isServicesStepNecessary(state) ? [] : [STEP_SERVICES]),
    ...(isWdcStepNecessary(state) ? [] : [STEP_WDC]),
  ];
  const steps = coreBookingGetters.isFareLockFinalizeFlow(state)
    ? BASIC_FARE_LOCK_FLOW_STEPS
    : without(excludedSteps, BASIC_BOOKING_FLOW_STEPS);
  return steps;
};

/**
 * @type {(state: State) => boolean}
 */
const isPassengersStepNecessary = allPass(
  anyPass(
    complement(featureGetters.isSplitPaxEnabled),
    complement(summaryGetters.isFareLockAdded)
  ),
  complement(isGroupSeatRequest)
);

/**
 * @type {(state: State) => boolean}
 */
const isSeatSelectionStepNecessary = allPass(
  anyPass(
    complement(isGroupBooking),
    coreBookingGetters.isAddServicesFlow,
    coreBookingGetters.isFlightChangeOrRebookFlow
  ),
  complement(summaryGetters.isFareLockAdded),
  complement(isGroupSeatRequest)
);

/**
 * @type {(state: State) => boolean}
 */
export const arePaymentOptionsVisible = (state) => {
  return (
    summaryGetters.amountDue(state).amount > 0 &&
    arePaymentOptionsLoaded(state) &&
    !isGroupSeatRequest(state)
  );
};

const arePaymentOptionsLoaded = (state) => isNotEmpty(paymentOptions(state));

/**
 * @type {(state: State) => boolean}
 */
const isServicesStepNecessary = complement(
  either(summaryGetters.isFareLockAdded, isGroupSeatRequest)
);

export const isWdcStepNecessary = bookingProp('isWdcStepNecessary');

export const nextStep = (state) => {
  return upcomingSteps(state)[0] ? upcomingSteps(state)[0] : '';
};

export const pastSteps = (state) => {
  const isBefore = (step) =>
    stepSet(state).indexOf(currentStepName(state)) > stepSet(state).indexOf(step);
  return takeWhile(isBefore, stepSet(state));
};

export const upcomingSteps = (state) => {
  const isUpcoming = (step) => currentStepName(state) !== step;
  return takeLastWhile(isUpcoming, stepSet(state));
};

export const currentAndUpcomingSteps = (state) => {
  const isCurrentOrUpcoming = (step) =>
    stepSet(state).indexOf(currentStepName(state)) <= stepSet(state).indexOf(step);
  return takeLastWhile(isCurrentOrUpcoming, stepSet(state));
};

export const isAtSelectFlightStep = (state) =>
  equals(currentStepName(state), STEP_SELECT_FLIGHT);

const isAtBaggagesStep = (state) => equals(currentStepName(state), STEP_PASSENGERS);

const isAtSeatsStep = (state) => equals(currentStepName(state), STEP_SEAT_SELECTION);

const isAtServicesStep = (state) => equals(currentStepName(state), STEP_SERVICES);

export const isWdcUpgradedFromSidebar = bookingProp('wdc.isUpgradedFromSidebar');

export const skipChangeSelectionToWdcFareType = (state) =>
  isWdcPremiumEnabled(state) &&
  isW6Carrier(state) &&
  flightSelectGetters.hasApplicableWdcMembership(state) &&
  userGetters.hasPremiumWdcMembership(state) &&
  gte(numberOfIndependentPassengers(state), 2);

export const isWdcSavingVisible = (state) => {
  if (
    isDomestic(state) ||
    (featureGetters.isLoginAtServicesStepEnabled(state) &&
      !userGetters.isLoggedIn(state) &&
      !isSelectFlightOrPassengersStep(state)) ||
    (isWdcPremiumEnabled(state) &&
      isW6Carrier(state) &&
      (((userGetters.hasStandardWdcMembership(state) ||
        userGetters.hasCoBrandedGroupWdcMembership(state)) &&
        lte(numberOfIndependentPassengers(state), 2)) ||
        (gte(numberOfIndependentPassengers(state), 2) &&
          (userGetters.hasPremiumWdcMembership(state) ||
            userGetters.hasGroupWdcMembership(state)))))
  )
    return false;

  return (
    (isAtBaggagesStep(state) || isAtSeatsStep(state) || isAtServicesStep(state)) &&
    (passengersGetters.iamTravelling(state) || !userGetters.isLoggedIn(state)) &&
    ((ancillariesGetters.isWdcMembershipUpgradeChoiceAvailable(state) &&
      !flightSelectGetters.hasApplicableWdcMembership(state) &&
      !flightSelectGetters.isWdcFareTypeSelected(state)) ||
      (isWdcPremiumEnabled(state) &&
        !isWdcUpgradedFromSidebar(state) &&
        !summaryGetters.hasWdcMembershipFee(state))) &&
    flightSelectGetters.isWdcFareTypeAvailable(state) &&
    !isGroupBooking(state) &&
    !coreBookingGetters.isAddServicesFlow(state) &&
    !coreBookingGetters.isFareLockFinalizeFlow(state) &&
    !coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
    !userGetters.isAgency(state) &&
    !summaryGetters.isFareLockAdded(state) &&
    !isFlightSearchInSidebarVisible(state)
  );
};

export const isWdcSavingAddedVisible = (state) => {
  return and(
    ancillariesGetters.isWdcSavingSet(state),
    summaryGetters.hasWdcMembershipFee(state)
  );
};

export const isWdcSavingAddButtonVisible = (state) => currentStepName(state) !== STEP_WDC;

export const isCancellationRebookFlow = bookingProp('isCancellationRebookFlow');

export const isSingleServiceModalVisible = bookingProp('isSingleServiceModalVisible');

export const isWizzFlexModalVisible = bookingProp('isWizzFlexModalVisible');

export const isWizzFlexVisible = (state) => {
  return (
    ancillariesGetters.isWizzFlexOnOutboundFlightAvailable(state) &&
    currentStepName(state) === STEP_PASSENGERS &&
    !coreBookingGetters.isAddServicesFlow(state) &&
    !isGroupBooking(state) &&
    !coreBookingGetters.isFlightChangeOrRebookFlow(state) &&
    !coreBookingGetters.isFareLockFinalizeFlow(state) &&
    !coreBookingGetters.isCheckInFlow(state)
  );
};

export const shouldWizzFlexExtendClass = (state) =>
  gte(numberOfIndependentPassengers(state), 6);

export const isPaymentExitIntentModalSeen = bookingProp('isPaymentExitIntentModalSeen');

export const isPaymentExitIntentModalVisible = (state) =>
  coreBookingGetters.isNewBookingFlow(state) &&
  !userGetters.isAgency(state) &&
  !isGroupBooking(state) &&
  !summaryGetters.isFareLockAdded(state) &&
  !isUrgencyModalVisible(state) &&
  !isPaymentExitIntentModalSeen(state) &&
  featureGetters.isPaymentExitIntentModalEnabled(state) &&
  volatileGetters.isPaymentExitIntentModalVisible(state) &&
  !isCaptchaRequired(state);

export const isCustomerCenterSupportsCurrentLanguage = bookingProp(
  'payment.options.isCustomerCenterSupportsCurrentLanguage'
);

export const isPaymentExitIntentModalEnabledAndHidden = (state) =>
  coreBookingGetters.isNewBookingFlow(state) &&
  featureGetters.isPaymentExitIntentModalVersionA(state) &&
  !userGetters.isAgency(state) &&
  !isGroupBooking(state) &&
  !summaryGetters.isFareLockAdded(state) &&
  !isUrgencyModalVisible(state) &&
  !isPaymentExitIntentModalSeen(state) &&
  !isCaptchaRequired(state);

export const isFlightSearchInSidebarVisible = bookingProp(
  'isFlightSearchInSidebarVisible'
);

export const isFlightSearchModalVisible = bookingProp('isFlightSearchModalVisible');

export const isDestinationBasedNotificationVisible = bookingProp(
  'payment.options.showWarningInformationLabel'
);

export const isDestinationBasedNotificationAccepted = bookingProp(
  'payment.isDestinationBasedNotificationAccepted'
);

export const cvv = bookingProp('payment.cvv');

export const servicesRequestedLanguage = bookingProp('servicesRequestedLanguage');

export const isPremiumSeatRefundLoading = bookingProp('isPremiumSeatRefundLoading');

export const premiumSeatRefund = bookingProp('premiumSeatRefund');

export const premiumSeatRefundErrors = bookingProp('premiumSeatRefundErrors');

export const hasRefundCashPriceAmount = compose(
  gt(__, 0),
  pathOr(0, 'booking.premiumSeatRefund.refundCashPrice.amount')
);

export const hasRefundWizzAccountPrice = compose(
  gt(__, 0),
  pathOr(0, 'booking.premiumSeatRefund.refundWizzAccountPrice.amount')
);

export const isPremiumSeatRefundNeeded = either(
  hasRefundCashPriceAmount,
  hasRefundWizzAccountPrice
);

/**
 * @type {(state: State) => Boolean}
 */
export const isPassengerStepPending = bookingVolatileProp('isPassengerStepPending');

/**
 * @type {(state: State) => Boolean}
 */
export const isPaymentStepPending = bookingVolatileProp('isPaymentStepPending');

/**
 * @type {(state: State) => Boolean}
 */
export const isSelectedFlightPending = bookingVolatileProp('isSelectedFlightPending');

export const areFlightCarriersAccepted = compose(
  every(propEqTrue('accepted')),
  flightCarriers
);

/**
 * @type {(state: State) => Boolean}
 */
export const isWdcActivationPanelVisibleOnPassengersStep = (state) =>
  (coreBookingGetters.isNewBookingFlow(state) ||
    coreBookingGetters.isConnectedBookingFlow(state) ||
    coreBookingGetters.isFareLockFinalizeFlow(state)) &&
  !flightSelectGetters.isWdcFareTypeSelected(state) &&
  userGetters.isLoggedIn(state) &&
  userGetters.hasAnyWdcMembership(state);

/**
 * @type {(state: State) => Boolean}
 */
export const isWdcActivationPanelVisibleOnFlightSelectStep = (state) =>
  coreBookingGetters.isNewBookingFlow(state) &&
  !flightSelectGetters.isWdcFareTypeSelected(state) &&
  userGetters.isLoggedIn(state) &&
  userGetters.hasAnyWdcMembership(state);

/**
 * @type {(state: State) => Boolean}
 */
export const isStickyBasketWithoutSidebarEnabled = (state) =>
  (coreBookingGetters.isNewBookingFlow(state) ||
    coreBookingGetters.isConnectedBookingFlow(state)) &&
  featureGetters.isStickyBasketWithoutSidebarEnabled(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const currentAmountDueWithoutThirdPartyServicesToShow = (state) =>
  coreBookingGetters.isPaymentCurrencyChangedFromBookingCurrency(state)
    ? summaryGetters.exchangedAmountDueWithoutThirdPartyServices(state)
    : summaryGetters.currentAmountDueWithoutThirdPartyServices(state);

/**
 * @type {(state: State) => Boolean}
 */
export const isCurrencyChangeWarningVisible = (state) =>
  !isCurrencyChangeWarningDismissed(state) &&
  coreBookingGetters.isPaymentCurrencyChangedFromBookingCurrency(state) &&
  summaryGetters.currentAmountDueWithoutThirdPartyServices.amount !== 0;

/**
 * @type {(state: State) => Boolean}
 */
export const isCurrencyChangeWarningOnPaymentPageVisible = (state) =>
  coreBookingGetters.isPaymentCurrencyChangedFromBookingCurrency(state) &&
  summaryGetters.currentAmountDueWithoutThirdPartyServices.amount !== 0;

/**
 * @type {(state: State) => Boolean}
 */
export const isOtaFeeWarningVisible = (state) =>
  (coreBookingGetters.isNewBookingFlow(state) ||
    coreBookingGetters.isFareLockFinalizeFlow(state)) &&
  summaryGetters.hasSystemSurcharge(state) &&
  featureGetters.isOtaFeeWarningEnabled(state);
