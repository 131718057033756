import { _map } from '../map';

/**
 * Accepts a converging function and a list of branching functions and returns
 * a new function. The arity of the new function is the same as the arity of
 * the longest branching function. When invoked, this new function is applied
 * to some arguments, and each branching function is applied to those same
 * arguments. The results of each branching function are passed as arguments
 * to the converging function to produce the return value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/converge.js
 *  . removed the curried nature for the time being see if it is needed at all
 *
 * @type {<T extends any[], U extends any[], V>(after: (...args: U) => V, fns: ((...args: T) => any)[]) => (...args: T) => V}
 * @example
 *
 *      const average = converge(divide, [sum, length])
 *      average([1, 2, 3, 4, 5, 6, 7]) //=> 4
 *
 *      const strangeConcat = converge(concat, [toUpper, toLower])
 *      strangeConcat("Yodel") //=> "YODELyodel"
 *
 */
const converge =
  (after, fns) =>
  (...args) =>
    after(..._map((fn) => fn(...args), fns));

export default converge;
