import to from 'await-to-js';
import log from 'loglevel';
import { apiErrorsOrDefault } from '~/utils/services/errors';
import { createAction } from '~/utils/store';
import { captureAndLogException } from '~/utils/logging';
import { STEP_SUCCESSFUL_PAYMENT } from '~/constants';
import { BOOKING_FLOW_ANALYTICS_STEPS } from '~/constants/analytics';
import * as BookingService from '~/services/booking';
import * as EcommerceService from '~/services/ecommerce';
import * as analyticsGetters from '../analytics/getters';
import * as trackingActions from '../tracking/actions';
import { resetItinerary } from '../itinerary/actions';
import { pnrAndLastName } from '../core-booking/getters';
import * as getters from './getters';
import * as m from './mutation-types';

export const resetAvailableFeedbackStages = createAction(
  m.RESET_AVAILABLE_FEEDBACK_STAGES
);

export const reset = createAction(m.RESET);

export const initialize = async (store) => {
  const { state, commit } = store;

  resetItinerary(store);
  commit(m.RESET);

  const [error, response] = await to(
    BookingService.successfulBooking(pnrAndLastName(state))
  );

  if (error) return commit(m.SET_ERRORS, apiErrorsOrDefault(error));

  commit(m.SET_IS_NEW_BOOKING, false);
  commit(m.INITIALIZE, response.data);
  commit(m.SET_IS_NEW_SUCCESSFUL_BOOKING_INITIALIZED, true);

  if (getters.isGoogleAnalyticsPostRequired(state)) trackPurchases(store);
  trackingActions.fbqTrackPurchase(store);
  trackingActions.pintrkTrackPurchase(store);
  trackingActions.ttqTrackPurchase(store);
};

export const setIsAffiliateModalVisible = createAction(
  m.SET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE
);

export const hideSuccessfulBookingAffiliateModal = createAction(
  m.UNSET_IS_SUCCESSFUL_BOOKING_AFFILIATE_MODAL_VISIBLE
);

export const setIsSuccessfulBookingInitialized = createAction(
  m.SET_IS_NEW_SUCCESSFUL_BOOKING_INITIALIZED
);

export const trackPurchases = async (store) => {
  const { state } = store;

  const [analyticsErrors, analyticsResponse] = await to(BookingService.getAnalytics());
  if (analyticsErrors) {
    captureAndLogException(
      'Unable to get analytics data for successful booking',
      analyticsErrors
    );
    return;
  }

  let analyticalData = analyticsResponse?.data?.googleAnalyticalData;
  const transaction = analyticsResponse?.data?.googleAnalyticalData?.transaction;

  if (!analyticalData || !transaction) {
    log.error('Unable to get analytics data for successful booking');
    return;
  }

  analyticalData = {
    ...analyticalData,
    purchaseLocations: analyticsGetters.purchaseLocations(state),
    hasSavingOnWdc: analyticsGetters.wdcDiscountSaving(state),
    paymentMethod: analyticsGetters.selectedPaymentOptionForAnalytics(state),
  };

  if (!getters.isFareLockFinalize(state)) {
    const checkoutStep = BOOKING_FLOW_ANALYTICS_STEPS.get(STEP_SUCCESSFUL_PAYMENT);
    EcommerceService.measureCheckoutStep(checkoutStep, analyticalData);
  }

  EcommerceService.measureTransaction(analyticalData, 'Itinerary');
  EcommerceService.measureGa4Transaction(analyticalData, 'Itinerary');

  BookingService.analytics();
};
