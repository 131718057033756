/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/internal/_functionName.js
 *
 * @type {(f: Function) => string}
 */
const _functionName = (f) => {
  // String(x => x) evaluates to "x => x", so the pattern may not match.
  const match = String(f).match(/^function (\w*)/);
  return match == null ? '' : match[1];
};

export default _functionName;
