import {
  SPECIFIC_TYPE_BGY,
  SPECIFIC_TYPE_KTW,
  SPECIFIC_TYPE_CRL,
  SPECIFIC_TYPE_OTP,
  SPECIFIC_TYPE_HEAVY_ONE,
  SPECIFIC_TYPE_HEAVY_TWO,
  SPECIFIC_TYPE_HEAVY_THREE,
  SPECIFIC_TYPE_LIGHT_ONE,
  SPECIFIC_TYPE_LIGHT_TWO,
  SPECIFIC_TYPE_LIGHT_THREE,
  SPECIFIC_TYPE_ULTRALIGHT_ONE,
  SPECIFIC_TYPE_ULTRALIGHT_TWO,
  SPECIFIC_TYPE_ULTRALIGHT_THREE,
  SPECIFIC_TYPE_ULTRALIGHT_FOUR,
  SPECIFIC_TYPE_ULTRALIGHT_FIVE,
  SPECIFIC_TYPE_ULTRALIGHT_SIX,
  SPECIFIC_TYPE_EXTRALIGHT_ONE,
  SPECIFIC_TYPE_CARRY_ON_BAG,
  SPECIFIC_TYPE_CABIN_BAG,
  SPECIFIC_TYPE_BUS,
  SPECIFIC_TYPE_TAXI,
  SPECIFIC_TYPE_TRAIN,
  SPECIFIC_TYPE_SHUTTLE_BUS,
  SPECIFIC_TYPE_REGIONAL_TRAIN,
  SPECIFIC_TYPE_FLIGHT_CHANGE,
  SPECIFIC_TYPE_FREE_FLIGHT_CHANGE,
  SPECIFIC_TYPE_TRAVEL_PARTNER,
  SPECIFIC_TYPE_INVOICE_CHANGE,
  SPECIFIC_TYPE_NAME_CHANGE,
  SPECIFIC_TYPE_PRIORITY_BOARDING,
  SPECIFIC_TYPE_TRAVEL_INSURANCE,
  SPECIFIC_TYPE_INFANT,
  SPECIFIC_TYPE_LOUNGE_ACCESS,
  SPECIFIC_TYPE_LOUNGE_ACCESS_V2,
  SPECIFIC_TYPE_SMS_NOTIFICATION,
  SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE,
  SPECIFIC_TYPE_PRIVILEGE_PASS,
  SPECIFIC_TYPE_SPORTS_EQUIPMENT,
  SPECIFIC_TYPE_FARE_LOCK,
  SPECIFIC_TYPE_WIZZ_FLEX,
  SPECIFIC_TYPE_FAST_TRACK_SECURITY,
  SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2,
  SPECIFIC_TYPE_BASIC,
  SPECIFIC_TYPE_SMART,
  SPECIFIC_TYPE_MIDDLE_TWO,
  SPECIFIC_TYPE_MIDDLE,
  SPECIFIC_TYPE_PLUS,
  SPECIFIC_TYPE_SMART_FAMILY,
  SPECIFIC_TYPE_MIDDLE_TWO_FAMILY,
  SPECIFIC_TYPE_MIDDLE_FAMILY,
  SPECIFIC_TYPE_PLUS_FAMILY,
  SPECIFIC_TYPE_AIRPORT_CHECKIN,
  SPECIFIC_TYPE_WDC_MEMBERSHIP,
  SPECIFIC_TYPE_DELAYED_WARRANTY,
  SPECIFIC_TYPE_SEAT_PRICE,
  SPECIFIC_TYPE_ADMINISTRATION,
  SPECIFIC_TYPE_PRICE_DETAILS_FLIGHT_PRICE,
  SPECIFIC_TYPE_GROUP_HANDLING,
  SPECIFIC_TYPE_ONLINE_TRAVEL_AGENCY,
  SPECIFIC_TYPE_CAR_RENTAL,
  SPECIFIC_TYPE_CAR_TRAWLER,
  SPECIFIC_TYPE_SCRAPER,
  SPECIFIC_TYPE_PAPER_INVOICE,
  SPECIFIC_TYPE_SEAT_PROTECTION,
  SPECIFIC_TYPE_CALL_CENTER_TRANSACTION,
  SPECIFIC_TYPE_CALL_CENTER,
  SPECIFIC_TYPE_AIRPORT_CHECKIN_PENALTY,
  SPECIFIC_TYPE_EXCESS_WEIGHT,
  SPECIFIC_TYPE_SYSTEM_SURCHARGE,
  SPECIFIC_TYPE_CANCELLATION,
  SPECIFIC_TYPE_INAD_SURCHARGE,
  SPECIFIC_TYPE_MISSED_DEPARTURE,
  SPECIFIC_TYPE_OTHER_FEES,
  SPECIFIC_TYPE_BAGGAGE_CORRECTION_DISCOUNT,
  SPECIFIC_TYPE_AIRPORT_PARKING,
  SPECIFIC_TYPE_INFANT_NAME_CHANGE,
  SPECIFIC_TYPE_BUNDLE_UPGRADE,
  SPECIFIC_TYPE_BUNDLE_UPGRADE_FAMILY,
  SPECIFIC_TYPE_AUTO_CHECK_IN,
  SPECIFIC_TYPE_HEAVY_FOUR,
  SPECIFIC_TYPE_HEAVY_FIVE,
  SPECIFIC_TYPE_HEAVY_SIX,
  SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY,
  SPECIFIC_TYPE_MEDIUM_ONE,
  SPECIFIC_TYPE_MEDIUM_TWO,
  SPECIFIC_TYPE_MEDIUM_THREE,
  SPECIFIC_TYPE_MEDIUM_FOUR,
  SPECIFIC_TYPE_MEDIUM_FIVE,
  SPECIFIC_TYPE_MEDIUM_SIX,
  SPECIFIC_TYPE_BLUE_RIBBON_BAG_TRACKING,
  SPECIFIC_TYPE_CORPORATE_CARD_FEE,
  SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER,
} from '~/constants/specific-type';

const specificTypeToSummaryLabelMap = new Map([
  [SPECIFIC_TYPE_BGY, 'summary-cart-exclusiveLounge'],
  [SPECIFIC_TYPE_KTW, 'summary-cart-exclusiveLounge'],
  [SPECIFIC_TYPE_CRL, 'summary-cart-exclusiveLounge'],
  [SPECIFIC_TYPE_OTP, 'summary-cart-exclusiveLounge'],
  [SPECIFIC_TYPE_HEAVY_ONE, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_TWO, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_THREE, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_FOUR, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_FIVE, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_SIX, 'summary-cart-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_ONE, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_TWO, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_THREE, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_FOUR, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_FIVE, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_SIX, 'summary-cart-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_ONE, 'summary-cart-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_TWO, 'summary-cart-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_THREE, 'summary-cart-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_ONE, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_TWO, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_THREE, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_FOUR, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_FIVE, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_SIX, 'summary-cart-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_EXTRALIGHT_ONE, 'summary-cart-extralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_CARRY_ON_BAG, 'summary-cart-carry-on-bag-with-size'],
  [SPECIFIC_TYPE_CABIN_BAG, 'summary-cart-hand-baggage-with-size'],
  [SPECIFIC_TYPE_BUS, 'summary-cart-service-name-short-bus'],
  [SPECIFIC_TYPE_TAXI, 'summary-cart-service-name-short-taxi'],
  [SPECIFIC_TYPE_TRAIN, 'summary-cart-service-name-short-train'],
  [SPECIFIC_TYPE_SHUTTLE_BUS, 'summary-cart-service-name-short-bus'],
  [SPECIFIC_TYPE_REGIONAL_TRAIN, 'summary-cart-airport-transfer-regionaltrain'],
  [SPECIFIC_TYPE_FLIGHT_CHANGE, 'summary-cart-flight-change-fee'],
  [SPECIFIC_TYPE_FREE_FLIGHT_CHANGE, 'summary-cart-free-flight-change-fee'],
  [SPECIFIC_TYPE_TRAVEL_PARTNER, 'summary-cart-travelPartner'],
  [SPECIFIC_TYPE_INVOICE_CHANGE, 'price-details-fee-invoice-change'],
  [SPECIFIC_TYPE_NAME_CHANGE, 'summary-cart-nameChange'],
  [SPECIFIC_TYPE_PRIORITY_BOARDING, 'summary-cart-priorityBoarding'],
  [SPECIFIC_TYPE_TRAVEL_INSURANCE, 'summary-cart-insuranceService'],
  [SPECIFIC_TYPE_INFANT, 'summary-cart-infant-fee'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS, 'summary-cart-loungeAccess'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS_V2, 'summary-cart-loungeAccess'],
  [SPECIFIC_TYPE_SMS_NOTIFICATION, 'summary-cart-smsNotification'],
  [SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE, 'summary-cart-seatingTogetherGuarantee'],
  [SPECIFIC_TYPE_PRIVILEGE_PASS, 'summary-cart-privilegePass'],
  [SPECIFIC_TYPE_SPORTS_EQUIPMENT, 'summary-cart-sport-equipment'],
  [SPECIFIC_TYPE_FARE_LOCK, 'summary-cart-fare-lock-feature-name'],
  [SPECIFIC_TYPE_WIZZ_FLEX, 'summary-cart-wizzFlex'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY, 'summary-cart-fastTrackSecurity'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2, 'summary-cart-fastTrackSecurity'],
  [SPECIFIC_TYPE_BASIC, 'summary-cart-fare-basic'],
  [SPECIFIC_TYPE_SMART, 'summary-cart-fare-smart'],
  [SPECIFIC_TYPE_MIDDLE_TWO, 'summary-cart-fare-middletwo'],
  [SPECIFIC_TYPE_MIDDLE, 'summary-cart-fare-middle'],
  [SPECIFIC_TYPE_PLUS, 'summary-cart-fare-plus'],
  [SPECIFIC_TYPE_SMART_FAMILY, 'summary-cart-fare-smart-family'],
  [SPECIFIC_TYPE_MIDDLE_TWO_FAMILY, 'summary-cart-fare-middletwo-family'],
  [SPECIFIC_TYPE_MIDDLE_FAMILY, 'summary-cart-fare-middle-family'],
  [SPECIFIC_TYPE_PLUS_FAMILY, 'summary-cart-fare-plus-family'],
  [SPECIFIC_TYPE_AIRPORT_CHECKIN, 'summary-cart-airport-checkin'],
  [SPECIFIC_TYPE_WDC_MEMBERSHIP, 'summary-cart-wdc-membership'],
  [SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY, 'summary-cart-wddc-membership-it'],
  [SPECIFIC_TYPE_DELAYED_WARRANTY, 'summary-cart-delayWarranty'],
  [SPECIFIC_TYPE_SEAT_PRICE, 'summary-cart-seatReservation'],
  [SPECIFIC_TYPE_ADMINISTRATION, 'summary-cart-administration-fee'],
  [SPECIFIC_TYPE_PRICE_DETAILS_FLIGHT_PRICE, 'summary-cart-flight-ticket'],
  [SPECIFIC_TYPE_GROUP_HANDLING, 'summary-cart-group-handling-fee'],
  [SPECIFIC_TYPE_ONLINE_TRAVEL_AGENCY, 'summary-cart-distribution-fee'],
  [SPECIFIC_TYPE_CAR_RENTAL, 'summary-cart-car-rental'],
  [SPECIFIC_TYPE_CAR_TRAWLER, 'summary-cart-car-rental'],
  [SPECIFIC_TYPE_SCRAPER, 'summary-cart-scraper-fee'],
  [SPECIFIC_TYPE_AIRPORT_PARKING, 'summary-cart-airport-parking'],
  [SPECIFIC_TYPE_INFANT_NAME_CHANGE, 'summary-cart-infant-name-change-fee'],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE, 'summary-cart-bundle-upgrade'],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE_FAMILY, 'summary-cart-bundle-upgrade'],
  [SPECIFIC_TYPE_AUTO_CHECK_IN, 'summary-cart-auto-check-in'],
  [SPECIFIC_TYPE_BLUE_RIBBON_BAG_TRACKING, 'summary-cart-blue-ribbon-bag-tracking'],
  [SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER, 'summary-cart-cafe-and-boutique-voucher'],
]);

const specificTypeToSummaryDiscountLabelMap = new Map([
  [SPECIFIC_TYPE_ADMINISTRATION, 'summary-cart-administration-fee-discount'],
]);

const specificTypeToInvoiceLabelMap = new Map([
  [SPECIFIC_TYPE_BGY, 'price-details-fee-exclusive-lounge-bgy'],
  [SPECIFIC_TYPE_KTW, 'price-details-fee-exclusive-lounge-ktw'],
  [SPECIFIC_TYPE_CRL, 'price-details-fee-exclusive-lounge-crl'],
  [SPECIFIC_TYPE_OTP, 'price-details-fee-exclusive-lounge-otp'],
  [SPECIFIC_TYPE_HEAVY_ONE, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_TWO, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_THREE, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_FOUR, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_FIVE, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_HEAVY_SIX, 'price-details-fee-heavy-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_ONE, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_TWO, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_THREE, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_FOUR, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_FIVE, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_MEDIUM_SIX, 'price-details-fee-medium-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_ONE, 'price-details-fee-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_TWO, 'price-details-fee-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_LIGHT_THREE, 'price-details-fee-light-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_ONE, 'price-details-fee-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_ULTRALIGHT_TWO, 'price-details-fee-ultralight-checked-in-bag-with-size'],
  [
    SPECIFIC_TYPE_ULTRALIGHT_THREE,
    'price-details-fee-ultralight-checked-in-bag-with-size',
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_FOUR,
    'price-details-fee-ultralight-checked-in-bag-with-size',
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_FIVE,
    'price-details-fee-ultralight-checked-in-bag-with-size',
  ],
  [SPECIFIC_TYPE_ULTRALIGHT_SIX, 'price-details-fee-ultralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_EXTRALIGHT_ONE, 'price-details-fee-extralight-checked-in-bag-with-size'],
  [SPECIFIC_TYPE_CARRY_ON_BAG, 'price-details-carry-on-bag'],
  [SPECIFIC_TYPE_CABIN_BAG, 'price-details-fee-airport-transfer-legacy-cabin-bag'],
  [SPECIFIC_TYPE_BUS, 'price-details-fee-airport-transfer-bus'],
  [SPECIFIC_TYPE_TAXI, 'price-details-fee-airport-transfer-taxi'],
  [SPECIFIC_TYPE_TRAIN, 'price-details-fee-airport-transfer-train'],
  [SPECIFIC_TYPE_SHUTTLE_BUS, 'price-details-fee-airport-transfer-shuttlebus'],
  [SPECIFIC_TYPE_REGIONAL_TRAIN, 'price-details-fee-airport-transfer-regionaltrain'],
  [SPECIFIC_TYPE_FLIGHT_CHANGE, 'price-details-fee-flight-change'],
  [SPECIFIC_TYPE_TRAVEL_PARTNER, 'price-details-fee-flexible-travel-partner'],
  [SPECIFIC_TYPE_INVOICE_CHANGE, 'price-details-fee-invoice-change'],
  [SPECIFIC_TYPE_NAME_CHANGE, 'price-details-fee-name-change'],
  [SPECIFIC_TYPE_PRIORITY_BOARDING, 'price-details-fee-priority-boarding'],
  [SPECIFIC_TYPE_TRAVEL_INSURANCE, 'price-details-fee-travel-insurance'],
  [SPECIFIC_TYPE_INFANT, 'price-details-fee-infant'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS, 'price-details-fee-lounge-access'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS_V2, 'price-details-fee-lounge-access'],
  [SPECIFIC_TYPE_SMS_NOTIFICATION, 'price-details-fee-sms-notification'],
  [
    SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE,
    'price-details-fee-seating-together-guarantee',
  ],
  [SPECIFIC_TYPE_PRIVILEGE_PASS, 'price-details-fee-privilege-pass'],
  [SPECIFIC_TYPE_SPORTS_EQUIPMENT, 'price-details-fee-sports-equipment'],
  [SPECIFIC_TYPE_FARE_LOCK, 'price-details-fee-fare-lock'],
  [SPECIFIC_TYPE_WIZZ_FLEX, 'price-details-fee-wizz-flex'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY, 'price-details-fee-fast-track-security'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2, 'price-details-fee-fast-track-security'],
  [SPECIFIC_TYPE_BASIC, 'price-details-fee-fare-basic'],
  [SPECIFIC_TYPE_SMART, 'price-details-fee-fare-smart'],
  [SPECIFIC_TYPE_MIDDLE_TWO, 'price-details-fee-fare-middletwo'],
  [SPECIFIC_TYPE_MIDDLE, 'price-details-fee-fare-middle'],
  [SPECIFIC_TYPE_PLUS, 'price-details-fee-fare-plus'],
  [SPECIFIC_TYPE_SMART_FAMILY, 'price-details-fee-fare-smart-family'],
  [SPECIFIC_TYPE_MIDDLE_TWO_FAMILY, 'price-details-fee-fare-middletwo-family'],
  [SPECIFIC_TYPE_MIDDLE_FAMILY, 'price-details-fee-fare-middle-family'],
  [SPECIFIC_TYPE_PLUS_FAMILY, 'price-details-fee-fare-plus-family'],
  [SPECIFIC_TYPE_AIRPORT_CHECKIN, 'price-details-fee-airport-checkin'],
  [SPECIFIC_TYPE_WDC_MEMBERSHIP, 'price-details-fee-wdc-membership'],
  [SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY, 'price-details-fee-wddc-membership-it'],
  [SPECIFIC_TYPE_DELAYED_WARRANTY, 'price-details-fee-delayed-warranty'],
  [SPECIFIC_TYPE_SEAT_PRICE, 'price-details-fee-seat-price'],
  [SPECIFIC_TYPE_ADMINISTRATION, 'price-details-fee-administration'],
  [SPECIFIC_TYPE_PRICE_DETAILS_FLIGHT_PRICE, 'price-details-flight-price'],
  [SPECIFIC_TYPE_GROUP_HANDLING, 'price-details-fee-group-handling'],
  [SPECIFIC_TYPE_PAPER_INVOICE, 'price-details-fee-paper-invoice'],
  [SPECIFIC_TYPE_SEAT_PROTECTION, 'price-details-fee-seat-protection'],
  [SPECIFIC_TYPE_CALL_CENTER_TRANSACTION, 'price-details-fee-call-center-transaction'],
  [SPECIFIC_TYPE_CALL_CENTER, 'price-details-fee-call-center'],
  [SPECIFIC_TYPE_AIRPORT_CHECKIN_PENALTY, 'price-details-fee-airport-checkin-penalty'],
  [SPECIFIC_TYPE_EXCESS_WEIGHT, 'price-details-fee-excess-weight'],
  [SPECIFIC_TYPE_SYSTEM_SURCHARGE, 'price-details-fee-system-surcharge'],
  [SPECIFIC_TYPE_CANCELLATION, 'price-details-fee-cancellation'],
  [SPECIFIC_TYPE_INAD_SURCHARGE, 'price-details-fee-inad-surcharge'],
  [SPECIFIC_TYPE_MISSED_DEPARTURE, 'price-details-fee-missed-departure'],
  [SPECIFIC_TYPE_OTHER_FEES, 'price-details-other-fee'],
  [
    SPECIFIC_TYPE_BAGGAGE_CORRECTION_DISCOUNT,
    'price-details-baggage-correction-discount',
  ],
  [SPECIFIC_TYPE_ONLINE_TRAVEL_AGENCY, 'price-details-fee-distribution-fee'],
  [SPECIFIC_TYPE_CAR_RENTAL, 'price-details-fee-car-rental'],
  [SPECIFIC_TYPE_CAR_TRAWLER, 'price-details-fee-car-rental'],
  [SPECIFIC_TYPE_SCRAPER, 'price-details-fee-system-surcharge'],
  [SPECIFIC_TYPE_AIRPORT_PARKING, 'price-details-fee-airport-parking'],
  [SPECIFIC_TYPE_INFANT_NAME_CHANGE, 'price-details-fee-infant-name-change'],
  [SPECIFIC_TYPE_AUTO_CHECK_IN, 'price-details-auto-check-in'],
  [SPECIFIC_TYPE_BLUE_RIBBON_BAG_TRACKING, 'price-details-blue-ribbon-bag-tracking'],
  [SPECIFIC_TYPE_CORPORATE_CARD_FEE, 'price-details-corporate-card-fee'],
  [
    SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER,
    'price-details-fee-cafe-and-boutique-voucher',
  ],
]);

const specificTypeToSummaryIconMap = new Map([
  [SPECIFIC_TYPE_BGY, { name: 'icon__exclusive-lounge__simple', color: 'blue' }],
  [SPECIFIC_TYPE_KTW, { name: 'icon__exclusive-lounge__simple', color: 'blue' }],
  [SPECIFIC_TYPE_CRL, { name: 'icon__exclusive-lounge__simple', color: 'blue' }],
  [SPECIFIC_TYPE_OTP, { name: 'icon__exclusive-lounge__simple', color: 'blue' }],
  [SPECIFIC_TYPE_MEDIUM_ONE, { name: 'checked_in_bag_variation3-medium', color: 'blue' }],
  [SPECIFIC_TYPE_MEDIUM_TWO, { name: 'checked_in_bag_variation3-medium', color: 'blue' }],
  [
    SPECIFIC_TYPE_MEDIUM_THREE,
    { name: 'checked_in_bag_variation3-medium', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_MEDIUM_FOUR,
    { name: 'checked_in_bag_variation3-medium', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_MEDIUM_FIVE,
    { name: 'checked_in_bag_variation3-medium', color: 'blue' },
  ],
  [SPECIFIC_TYPE_MEDIUM_SIX, { name: 'checked_in_bag_variation3-medium', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_ONE, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_TWO, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_THREE, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_FOUR, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_FIVE, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_HEAVY_SIX, { name: 'checked_in_bag_variation3-heavy', color: 'blue' }],
  [SPECIFIC_TYPE_LIGHT_ONE, { name: '' }],
  [SPECIFIC_TYPE_LIGHT_TWO, { name: '' }],
  [SPECIFIC_TYPE_LIGHT_THREE, { name: '' }],
  [
    SPECIFIC_TYPE_ULTRALIGHT_ONE,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_TWO,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_THREE,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_FOUR,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_FIVE,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_ULTRALIGHT_SIX,
    { name: 'checked_in_bag_variation3-light', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_EXTRALIGHT_ONE,
    { name: 'checked_in_bag_variation3-extra', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_CARRY_ON_BAG,
    { name: 'icon__bundle-ancillary-carry-on-bag', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_CABIN_BAG,
    { name: 'icon__bundle-ancillary-hand-baggage', color: 'blue' },
  ],
  [SPECIFIC_TYPE_BUS, { name: 'icon__bus__simple', color: 'blue' }],
  [SPECIFIC_TYPE_TAXI, { name: 'icon__bus__simple', color: 'blue' }],
  [SPECIFIC_TYPE_TRAIN, { name: 'icon__bus__simple', color: 'blue' }],
  [SPECIFIC_TYPE_SHUTTLE_BUS, { name: 'icon__bus__simple', color: 'blue' }],
  [SPECIFIC_TYPE_REGIONAL_TRAIN, { name: 'icon__bus__simple', color: 'blue' }],
  [SPECIFIC_TYPE_FLIGHT_CHANGE, { name: '' }],
  [SPECIFIC_TYPE_TRAVEL_PARTNER, { name: '' }],
  [SPECIFIC_TYPE_INVOICE_CHANGE, { name: '' }],
  [SPECIFIC_TYPE_NAME_CHANGE, { name: '' }],
  [
    SPECIFIC_TYPE_PRIORITY_BOARDING,
    { name: 'icon__bundle-ancillary-priority-boarding', color: 'blue' },
  ],
  [SPECIFIC_TYPE_TRAVEL_INSURANCE, { name: 'icon__insurance__simple', color: 'blue' }],
  [SPECIFIC_TYPE_INFANT, { name: '' }],
  [
    SPECIFIC_TYPE_LOUNGE_ACCESS,
    { name: 'icon__exclusive-lounge__simple', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_LOUNGE_ACCESS_V2,
    { name: 'icon__exclusive-lounge__simple', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_SMS_NOTIFICATION,
    { name: 'icon__flight-information__simple', color: 'blue' },
  ],
  [SPECIFIC_TYPE_PRIVILEGE_PASS, { name: 'icon__privilege-pass__simple', color: 'blue' }],
  [SPECIFIC_TYPE_SPORTS_EQUIPMENT, { name: 'icon__bundle__bag--sport', color: 'blue' }],
  [SPECIFIC_TYPE_FARE_LOCK, { name: '' }],
  [SPECIFIC_TYPE_WIZZ_FLEX, { name: 'icon__bundle-ancillary-wizz-flex', color: 'blue' }],
  [
    SPECIFIC_TYPE_FAST_TRACK_SECURITY,
    { name: 'icon__bundle-ancillary-fast-track-security', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2,
    { name: 'icon__bundle-ancillary-fast-track-security', color: 'blue' },
  ],
  [SPECIFIC_TYPE_BASIC, { name: '' }],
  [SPECIFIC_TYPE_SMART, { name: '' }],
  [SPECIFIC_TYPE_MIDDLE_TWO, { name: '' }],
  [SPECIFIC_TYPE_MIDDLE, { name: '' }],
  [SPECIFIC_TYPE_PLUS, { name: '' }],
  [SPECIFIC_TYPE_SMART_FAMILY, { name: '' }],
  [SPECIFIC_TYPE_MIDDLE_TWO_FAMILY, { name: '' }],
  [SPECIFIC_TYPE_MIDDLE_FAMILY, { name: '' }],
  [SPECIFIC_TYPE_PLUS_FAMILY, { name: '' }],
  [
    SPECIFIC_TYPE_AIRPORT_CHECKIN,
    { name: 'icon__bundle-ancillary-airport-check-in', color: 'blue' },
  ],
  [SPECIFIC_TYPE_WDC_MEMBERSHIP, { name: '' }],
  [
    SPECIFIC_TYPE_DELAYED_WARRANTY,
    { name: 'icon__bundle__ontime-arrival', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_SEAT_PRICE,
    { name: 'icon__bundle-ancillary-seat-selection', color: 'blue' },
  ],
  [SPECIFIC_TYPE_ADMINISTRATION, { name: '' }],
  [SPECIFIC_TYPE_PRICE_DETAILS_FLIGHT_PRICE, { name: '' }],
  [SPECIFIC_TYPE_GROUP_HANDLING, { name: '' }],
  [SPECIFIC_TYPE_ONLINE_TRAVEL_AGENCY, { name: '' }],
  [SPECIFIC_TYPE_CAR_RENTAL, { name: 'icon__service--car' }],
  [SPECIFIC_TYPE_CAR_TRAWLER, { name: 'icon__service--car' }],
  [SPECIFIC_TYPE_SCRAPER, { name: '' }],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE, { name: 'icon__wizz-go-bundle' }],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE_FAMILY, { name: 'icon__wizz-go-bundle' }],
  [SPECIFIC_TYPE_AIRPORT_PARKING, { name: 'icon__service--airport-parking' }],
  [
    SPECIFIC_TYPE_AUTO_CHECK_IN,
    { name: 'icon__bundle-ancillary-auto-check-in', color: 'blue' },
  ],
  [
    SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE,
    { name: 'icon__seating-together-v2__simple' },
  ],
  [SPECIFIC_TYPE_BLUE_RIBBON_BAG_TRACKING, { name: 'icon__service--airport-parking' }], // TODO: Change icon!
  [
    SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER,
    { name: 'icon__cafe-and-boutique-voucher', color: 'blue' },
  ],
]);

const specificTypeToIdMap = new Map([
  [SPECIFIC_TYPE_HEAVY_ONE, 'baggage'],
  [SPECIFIC_TYPE_HEAVY_TWO, 'baggage'],
  [SPECIFIC_TYPE_HEAVY_THREE, 'baggage'],
  [SPECIFIC_TYPE_HEAVY_FOUR, 'baggage'],
  [SPECIFIC_TYPE_HEAVY_FIVE, 'baggage'],
  [SPECIFIC_TYPE_HEAVY_SIX, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_ONE, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_TWO, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_THREE, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_FOUR, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_FIVE, 'baggage'],
  [SPECIFIC_TYPE_MEDIUM_SIX, 'baggage'],
  [SPECIFIC_TYPE_LIGHT_ONE, 'baggage'],
  [SPECIFIC_TYPE_LIGHT_TWO, 'baggage'],
  [SPECIFIC_TYPE_LIGHT_THREE, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_ONE, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_TWO, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_THREE, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_FOUR, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_FIVE, 'baggage'],
  [SPECIFIC_TYPE_ULTRALIGHT_SIX, 'baggage'],
  [SPECIFIC_TYPE_EXTRALIGHT_ONE, 'baggage'],
  [SPECIFIC_TYPE_CARRY_ON_BAG, 'carryOnBaggage'],
  [SPECIFIC_TYPE_CABIN_BAG, 'cabinBaggage'],
  [SPECIFIC_TYPE_SPORTS_EQUIPMENT, 'sportsEquipment'],
  [SPECIFIC_TYPE_AIRPORT_CHECKIN, 'airportCheckIn'],
  [SPECIFIC_TYPE_DELAYED_WARRANTY, 'delayWarranty'],
  [SPECIFIC_TYPE_BGY, 'exclusiveLounge'],
  [SPECIFIC_TYPE_KTW, 'exclusiveLounge'],
  [SPECIFIC_TYPE_CRL, 'exclusiveLounge'],
  [SPECIFIC_TYPE_OTP, 'exclusiveLounge'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY, 'fastTrackSecurity'],
  [SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2, 'fastTrackSecurity'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS, 'loungeAccess'],
  [SPECIFIC_TYPE_LOUNGE_ACCESS_V2, 'loungeAccess'],
  [SPECIFIC_TYPE_PRIORITY_BOARDING, 'priorityBoarding'],
  [SPECIFIC_TYPE_TRAVEL_INSURANCE, 'acordService'],
  [SPECIFIC_TYPE_BUS, 'airportTransfers'],
  [SPECIFIC_TYPE_TAXI, 'airportTransfers'],
  [SPECIFIC_TYPE_TRAIN, 'airportTransfers'],
  [SPECIFIC_TYPE_SHUTTLE_BUS, 'airportTransfers'],
  [SPECIFIC_TYPE_REGIONAL_TRAIN, 'airportTransfers'],
  [SPECIFIC_TYPE_CAR_RENTAL, 'carRental'],
  [SPECIFIC_TYPE_CAR_TRAWLER, 'carTrawler'],
  [SPECIFIC_TYPE_SMS_NOTIFICATION, 'smsNotification'],
  [SPECIFIC_TYPE_WIZZ_FLEX, 'wizzFlex'],
  [SPECIFIC_TYPE_AIRPORT_PARKING, 'airportParking'],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE, 'middle-upsell'],
  [SPECIFIC_TYPE_BUNDLE_UPGRADE_FAMILY, 'middle-family-upsell'],
  [SPECIFIC_TYPE_AUTO_CHECK_IN, 'autoCheckIn'],
  [SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE, 'seatingTogetherGuarantee'],
  [SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER, 'cafeVoucher'],
]);

const paymentSpecificTypeToSummaryLabelMap = new Map([
  ['CreditCard', 'summary-cart-credit-card-payment'],
  ['CustomerAccount', 'summary-cart-wizz-account-value'],
  ['Voucher', 'summary-cart-voucher-discount'],
  ['BankTransfer', 'summary-cart-bank-transfer'], // NOTE: this label doesn't exist
  ['iDEAL', 'summary-cart-ideal'], // NOTE: this label doesn't exist
]);

export const mapPaymentSpecificTypeToSummaryLabel = (specificType) =>
  paymentSpecificTypeToSummaryLabelMap.get(specificType);

export const mapSpecificTypeToSummaryLabel = (specificType) =>
  specificTypeToSummaryLabelMap.get(specificType);

export const mapSpecificTypeToSummaryDiscountLabel = (specificType) =>
  specificTypeToSummaryDiscountLabelMap.get(specificType);

export const mapSpecificTypeToInvoiceLabel = (specificType) =>
  specificTypeToInvoiceLabelMap.get(specificType);

export const mapSpecificTypeToSummaryIcon = (specificType) =>
  specificTypeToSummaryIconMap.get(specificType);

export const mapSpecificTypeToId = (specificType) =>
  specificTypeToIdMap.get(specificType);
