import {
  BOTH_FLIGHT_NAME,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  OUTBOUND_FLIGHT_NAME,
  PACKAGE_TRAVEL_UNLICENSED_CARRIERS,
  RETURN_FLIGHT_NAME,
  STATUS_CONFIRMED,
  STATUS_FARE_LOCK_FINALIZE,
  CAR_RENTAL_PAYMENT_STATUS_SUCCESS,
} from '~/constants';
import { isAdult, isChild } from '~/utils/booking/passenger';
import * as userGetters from '~/store/modules/user/getters';
import allPass from '~/utils/fp/all-pass';
import compose from '~/utils/fp/compose';
import applySpec from '~/utils/fp/apply-spec';
import curry from '~/utils/fp/curry';
import either from '~/utils/fp/either';
import equals from '~/utils/fp/equals';
import map from '~/utils/fp/map';
import propEq from '~/utils/fp/prop-eq';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import {
  successfulBookingProp,
  passengerAncillaries,
  successfulBookingPropOr,
} from './internal';

export const getStatus = (state) => state.successfulBooking?.booking?.status || '';

export const isStatus = curry((status, state) => getStatus(state) === status);

export const pnr = successfulBookingProp('booking.pnr');

export const lastName = successfulBookingProp('booking.lastName');

export const pnrAndLastName = applySpec({
  pnr,
  lastName,
});

export const booking = successfulBookingProp('booking');

export const isSuccessfulBookingInitialized = successfulBookingProp(
  'isSuccessfulBookingInitialized'
);

export const status = successfulBookingProp('booking.status');

export const isConfirmed = compose(equals(STATUS_CONFIRMED), status);

export const isOutboundCheckInAvailable = successfulBookingProp(
  'operations.outboundCheckIn'
);

export const isReturnCheckInAvailable = successfulBookingProp('operations.returnCheckIn');

export const isAutoCheckInAvailable = successfulBookingProp(
  'operations.isAutoCheckInAvailable'
);

export const isCarbonOffsetRibbonVisible = successfulBookingProp(
  'operations.displayCarbonOffsetRibbon'
);

export const outboundFlight = successfulBookingProp('booking.flights.outbound');

export const hasOutboundFlight = compose(isNotNil, outboundFlight);

export const returnFlight = successfulBookingProp('booking.flights.return');

export const hasReturnFlight = compose(isNotNil, returnFlight);

export const isTravelDocumentNeeded = successfulBookingProp('booking.needToAddTravelDoc');

export const isMobilePhoneUpdateNeeded = successfulBookingProp(
  'booking.needToAddMobilePhone'
);

export const isNonSchengenNotificationVisible = successfulBookingProp(
  'booking.displayNonSchengenNotification'
);

export const earliestCheckinWithoutOldSeatVersion = successfulBookingProp(
  'booking.bookingHasEarliestCheckinWithoutSeatOldVersionAllowedComment'
);

export const trackingNumber = successfulBookingProp('booking.trackingNumber');

export const totalPaidAmount = successfulBookingProp('booking.totalPaidAmount');

export const canListInvoices = successfulBookingProp('operations.canListInvoices');

export const canShowPriceDetails = successfulBookingProp(
  'operations.canShowPriceDetails'
);

export const outboundDepartureDate = successfulBookingProp(
  'booking.flights.outbound.departureDate'
);

export const outboundArrivalDate = successfulBookingProp(
  'booking.flights.outbound.arrivalDate'
);

export const returnDepartureDate = successfulBookingProp(
  'booking.flights.return.departureDate'
);

export const returnArrivalDate = successfulBookingProp(
  'booking.flights.return.arrivalDate'
);

export const outboundDepartureStationIata = successfulBookingProp(
  'booking.flights.outbound.departureStation'
);

export const outboundArrivalStationIata = successfulBookingProp(
  'booking.flights.outbound.arrivalStation'
);

export const isOutboundFlightFlown = successfulBookingProp(
  'booking.flights.outbound.isFlown'
);

export const isReturnFlightFlown = successfulBookingProp(
  'booking.flights.return.isFlown'
);

export const isBookingAvailable = either(hasOutboundFlight, hasReturnFlight);

export const isNewVersionSupported = successfulBookingProp(
  'booking.isNewVersionSupported'
);

export const isOwnerOfBooking = successfulBookingProp('booking.isOwner');

export const totalAmountDue = successfulBookingProp('booking.totalAmountDue');

export const holdUntil = successfulBookingProp('booking.holdUntil');

export const fareLockHoldTimeInHours = successfulBookingProp(
  'booking.fareLockHoldTimeInHours'
);

export const isGoogleAnalyticsPostRequired = successfulBookingPropOr(
  false,
  'booking.isGoogleAnalyticsPostRequired'
);

export const isFareLockFinalize = isStatus(STATUS_FARE_LOCK_FINALIZE);

export const reservedCar = successfulBookingProp('booking.reservedCar');

export const carTrawlerReservedCar = successfulBookingProp(
  'booking.carTrawlerReservedCar'
);

export const isCarReservationSuccessful = (state) =>
  reservedCar(state)?.paymentStatus === CAR_RENTAL_PAYMENT_STATUS_SUCCESS;

export const hasRentalcarsCarRental = (state) => isNotNil(reservedCar(state));

export const isCarTrawlerReservationSuccessful = (state) =>
  carTrawlerReservedCar(state)?.paymentStatus === CAR_RENTAL_PAYMENT_STATUS_SUCCESS;

export const hasCarTrawlerCarRental = (state) => isNotNil(carTrawlerReservedCar(state));

export const carRentalCreditBackPercentage = successfulBookingPropOr(
  null,
  'booking.carRentalCreditBackPercentage'
);

export const payments = successfulBookingPropOr([], 'booking.payments');

export const errors = successfulBookingProp('errors');

export const isNewBooking = successfulBookingProp('isNewBooking');

export const hasErrors = compose(isNotEmpty, errors);

export const isRoundTrip = compose(isNotNil, returnFlight);

export const flights = (state) => [
  {
    ...outboundFlight(state),
    direction: DIRECTION_OUTBOUND,
  },
  ...(isRoundTrip(state)
    ? [
        {
          ...returnFlight(state),
          direction: DIRECTION_RETURN,
        },
      ]
    : []),
];

export const passengers = compose(
  map((passenger) => ({
    ...passenger,
    hasInfant: Boolean(passenger.infant),
    outboundFlightAncillaries: passengerAncillaries(DIRECTION_OUTBOUND, passenger),
    returnFlightAncillaries: passengerAncillaries(DIRECTION_RETURN, passenger),
  })),
  successfulBookingProp('booking.passengers')
);

export const numberOfAdults = (state) => passengers(state).filter(isAdult).length;

export const numberOfChildren = (state) => passengers(state).filter(isChild).length;

export const numberOfInfants = (state) =>
  passengers(state)
    .filter(isAdult)
    .filter((passenger) => isNotNil(passenger.infant)).length;

export const outboundPurchasedServices = (state) =>
  successfulBookingProp('booking.availableAncillaryServices', state).filter(
    either(
      propEq('selectedForFlightType', OUTBOUND_FLIGHT_NAME),
      propEq('selectedForFlightType', BOTH_FLIGHT_NAME)
    )
  );

export const returnPurchasedServices = (state) =>
  successfulBookingProp('booking.availableAncillaryServices', state).filter(
    either(
      propEq('selectedForFlightType', RETURN_FLIGHT_NAME),
      propEq('selectedForFlightType', BOTH_FLIGHT_NAME)
    )
  );

export const isCustomerRated = successfulBookingProp('booking.availableFeedbackStages');

export const areAffiliatesVisible = (state) => {
  const outboundCarrier = outboundFlight(state)?.carrier?.toLowerCase();
  if (!outboundCarrier) return false;

  if (!PACKAGE_TRAVEL_UNLICENSED_CARRIERS.includes(outboundCarrier)) return true;
  return !(userGetters.isBrowsingFromEU(state) || userGetters.isEUCitizen(state));
};

export const isSuccessfulBookingAffiliateModalVisible = allPass(
  areAffiliatesVisible,
  successfulBookingProp('isSuccessfulBookingAffiliateModalVisible')
);
