import add from '../add';

/**
 * Increments its argument.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/inc.js
 *
 * @type {(a: number) => number}
 * @example
 *
 *      inc(42); //=> 43
 */
const inc = add(1);

export default inc;
