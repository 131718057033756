import tail from '../tail';
import head from '../head';
import equals from '../equals';

/**
 * Checks if all input arguments are equal.
 * If called with less than 2 arguments will return true.
 * Values will be compared with `equals`
 *
 * @type {<T extends any[]>(...args: T) => boolean}
 */
const equalsAll = (...values) => {
  if (values.length < 2) return true;
  return tail(values).every(equals(head(values)));
};

export default equalsAll;
