import add from '../add';

/**
 * Decrements its argument.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/dec.js
 *
 * @type {(a: number) => number}
 * @example
 *
 *      dec(42); //=> 41
 */
const dec = add(-1);

export default dec;
