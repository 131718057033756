import * as m from './mutation-types';

export default {
  [m.SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },

  [m.SET_CURRENCIES_ON_ACTIVE_STATIONS](state, currenciesOnActiveStations) {
    state.currenciesOnActiveStations = currenciesOnActiveStations;
  },

  [m.SET_AVAILABLE_LOCALES](state, availableLocales) {
    state.availableLocales = availableLocales;
  },

  [m.SET_LANGUAGE_CODE](state, value) {
    state.languageCode = value;
  },

  [m.SET_IS_GET_ANCILLARIES_FOR_LOCALE_CHANGE](state, value) {
    state.shouldRefreshAncillaries = value;
  },
};
