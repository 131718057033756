import {
  ANCILLARY_SMS_NOTIFICATION,
  ANCILLARY_AIRPORT_CHECK_IN,
  ANCILLARY_ACORD_SERVICE,
  ANCILLARY_FAST_TRACK_SECURITY,
  ANCILLARY_FAST_TRACK_SECURITY_V2,
  ANCILLARY_LOUNGE_ACCESS,
  ANCILLARY_LOUNGE_ACCESS_V2,
  ANCILLARY_DELAY_WARRANTY,
  ANCILLARY_PRIORITY_BOARDING,
  ANCILLARY_AIRPORT_TRANSFERS,
  ANCILLARY_WIZZ_FLEX,
  ANCILLARY_EXCLUSIVE_LOUNGE,
  ANCILLARY_PRIVILEGE_PASS,
  ANCILLARY_AIRPORT_PARKING,
  ANCILLARY_BOOKING_COM,
  ANCILLARY_CAR_RENTAL,
  ANCILLARY_CAR_TRAWLER,
  ANCILLARY_CARRY_ON_BAG,
  ANCILLARY_AUTO_CHECK_IN,
  ANCILLARY_SEATING_TOGETHER_GUARANTEE,
  ANCILLARY_CAR_SIXT,
  ANCILLARY_BAGS,
  ANCILLARY_SEAT_RESERVATION,
  ANCILLARY_SEAT,
  ANCILLARY_E_BAG1,
  ANCILLARY_HAND_BAGGAGE,
  ANCILLARY_SPORTS_EQUIPMENT,
  ANCILLARY_HEAVYTHREE,
  ANCILLARY_HEAVYTWO,
  ANCILLARY_HEAVYONE,
  ANCILLARY_BAG6,
  ANCILLARY_BAG5,
  ANCILLARY_BAG4,
  ANCILLARY_BAG3,
  ANCILLARY_BAG2,
  ANCILLARY_BAG1,
  ANCILLARY_ULTRALIGHTTWO,
  ANCILLARY_ULTRALIGHTONE,
  ANCILLARY_U_BAG6,
  ANCILLARY_U_BAG5,
  ANCILLARY_U_BAG4,
  ANCILLARY_U_BAG3,
  ANCILLARY_U_BAG2,
  ANCILLARY_U_BAG1,
  ANCILLARY_CHECKED_IN_BAGGAGE,
  ANCILLARY_AIRPORT_TRANSFER,
  ANCILLARY_DELAYED_WARRANTY,
  ANCILLARY_TRANSFER,
  ANCILLARY_ACORD_INSURANCE,
  ANCILLARY_BAG,
  ANCILLARY_U_BAG,
  ANCILLARY_ULTRALIGHT,
  ANCILLARY_HEAVY,
  ANCILLARY_AIRPORT_CHECKIN,
  ANCILLARY_TRANSFER_BUS,
  ANCILLARY_TRANSFER_TAXI,
  ANCILLARY_TRANSFER_TRAIN,
  ANCILLARY_TRANSFER_SHUTTLEBUS,
  ANCILLARY_TRANSFER_REGIONALTRAIN,
  ANCILLARY_TRANSFER_TRAVEL_INSURANCE,
  ANCILLARY_HEAVYFOUR,
  ANCILLARY_HEAVYFIVE,
  ANCILLARY_HEAVYSIX,
  ANCILLARY_LIGHTONE,
  ANCILLARY_LIGHTTWO,
  ANCILLARY_LIGHTTHREE,
  ANCILLARY_EXTRALIGHTONE,
  ANCILLARY_ULTRALIGHTTHREE,
  ANCILLARY_ULTRALIGHTFOUR,
  ANCILLARY_ULTRALIGHTFIVE,
  ANCILLARY_ULTRALIGHTSIX,
  ANCILLARY_AIRPORTPARKING,
  ANCILLARY_MEDIUMONE,
  ANCILLARY_MEDIUMTWO,
  ANCILLARY_MEDIUMTHREE,
  ANCILLARY_MEDIUMFOUR,
  ANCILLARY_MEDIUMFIVE,
  ANCILLARY_MEDIUMSIX,
  ANCILLARY_M_BAG1,
  ANCILLARY_M_BAG2,
  ANCILLARY_M_BAG3,
  ANCILLARY_M_BAG4,
  ANCILLARY_M_BAG5,
  ANCILLARY_M_BAG6,
  ANCILLARY_E_SIM_DATA_ROAMING,
  ANCILLARY_BLUE_RIBBON_BAG_TRACKING,
  ANCILLARY_BLUE_RIBBON_BAG,
  ANCILLARY_PRM_HEARING_IMPAIRED,
  ANCILLARY_PRM_MOBILITY_IMPAIRED,
  ANCILLARY_PRM_VISUALLY_IMPAIRED,
  ANCILLARY_CAFE_AND_BOUTIQUE_VOUCHER,
} from '~/constants';

export const ANCILLARIES_TO_DISPLAY = [
  {
    name: ANCILLARY_CARRY_ON_BAG,
    order: 0,
    labelKey: 'summary-cart-carry-on-bag-with-size',
  },
  {
    name: ANCILLARY_HAND_BAGGAGE,
    order: 0,
    labelKey: 'hand-baggage-with-size',
  },
  {
    name: ANCILLARY_HEAVYONE,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_HEAVYTWO,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_HEAVYTHREE,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_HEAVYFOUR,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_HEAVYFIVE,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_HEAVYSIX,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTONE,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTTWO,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTTHREE,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTFOUR,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTFIVE,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_ULTRALIGHTSIX,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_E_BAG1,
    order: 1,
    labelKey: 'extralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG1,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG2,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG3,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG4,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG5,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_U_BAG6,
    order: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMONE,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMTWO,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMTHREE,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMFOUR,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMFIVE,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_MEDIUMSIX,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG1,
    order: 1,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG2,
    order: 2,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG3,
    order: 3,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG4,
    order: 4,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG5,
    order: 5,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_M_BAG6,
    order: 6,
    labelKey: 'medium-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_LIGHTONE,
    order: 1,
    labelKey: 'light-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_LIGHTTWO,
    order: 1,
    labelKey: 'light-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_LIGHTTHREE,
    order: 1,
    labelKey: 'light-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG1,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG2,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG3,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG4,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG5,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_BAG6,
    order: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
  },
  {
    name: ANCILLARY_SPORTS_EQUIPMENT,
    order: 2,
    labelKey: 'sport-equipment',
  },
  {
    name: ANCILLARY_PRIORITY_BOARDING,
    order: 3,
    labelKey: 'wizz-priority-ancillary',
  },
  {
    name: ANCILLARY_SEAT,
    order: 4,
  },
  {
    name: ANCILLARY_CAR_RENTAL,
    order: 5,
    labelKey: 'car-rental',
  },
  {
    name: ANCILLARY_CAR_TRAWLER,
    order: 5,
    labelKey: 'car-rental',
  },
  {
    name: ANCILLARY_WIZZ_FLEX,
    order: 5,
    labelKey: 'wizz-flex',
  },
  {
    name: ANCILLARY_CAR_SIXT,
    order: 5,
    labelKey: 'car-rental',
  },
  {
    name: ANCILLARY_BLUE_RIBBON_BAG_TRACKING,
    order: 2,
    labelKey: 'blue-ribbon-bag-tracking',
  },
  {
    name: ANCILLARY_CAFE_AND_BOUTIQUE_VOUCHER,
    order: 5,
    labelKey: 'cafe-and-boutique-voucher',
  },
];

export const ANCILLARIES_TO_DISPLAY_DIVIDE_FLIGHT_CHANGE = [
  {
    name: ANCILLARY_AUTO_CHECK_IN,
    order: 4,
    labelKey: 'autoCheckIn',
  },
  {
    name: ANCILLARY_DELAYED_WARRANTY,
    order: 5,
    labelKey: 'delayedWarranty',
  },
  {
    name: ANCILLARY_AIRPORTPARKING,
    order: 5,
    labelKey: 'airport-parking',
  },
  {
    name: ANCILLARY_EXTRALIGHTONE,
    order: 5,
    labelKey: 'extra-light-one',
  },
  {
    name: ANCILLARY_CAFE_AND_BOUTIQUE_VOUCHER,
    order: 5,
    labelKey: 'cafe-and-boutique-voucher',
  },
];

export const MATCH_ICON_TO_ANCILLARY_NAME = new Map();
MATCH_ICON_TO_ANCILLARY_NAME.set(ANCILLARY_CARRY_ON_BAG, {
  simple: {
    name: 'icon__bundle-ancillary-carry-on-bag',
    color: 'blue',
  },
})
  .set(ANCILLARY_SMS_NOTIFICATION, {
    simple: {
      name: 'icon__flight-information__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__service--flightinfo',
    },
  })
  .set(ANCILLARY_ACORD_INSURANCE, {
    simple: {
      name: 'icon__insurance__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__insurance',
    },
  })
  .set(ANCILLARY_ACORD_SERVICE, {
    simple: {
      name: 'icon__insurance__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__insurance',
    },
  })
  .set(ANCILLARY_TRANSFER, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_TRANSFER_BUS, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_TRANSFER_TAXI, {
    simple: {
      name: 'icon__taxi__simple',
    },
    halftone: {
      name: 'icon__taxi',
    },
  })
  .set(ANCILLARY_TRANSFER_TRAIN, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_TRANSFER_SHUTTLEBUS, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_TRANSFER_REGIONALTRAIN, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_TRANSFER_TRAVEL_INSURANCE, {
    simple: {
      name: 'icon__insurance__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_PRIORITY_BOARDING, {
    simple: {
      name: 'icon__bundle-ancillary-priority-boarding',
      color: 'blue',
    },
    halftone: {
      name: 'icon__wizz-priority-bags',
    },
  })
  .set(ANCILLARY_FAST_TRACK_SECURITY, {
    simple: {
      name: 'icon__bundle-ancillary-fast-track-security',
      color: 'blue',
    },
    halftone: {
      name: 'icon__fast-track',
    },
  })
  .set(ANCILLARY_FAST_TRACK_SECURITY_V2, {
    simple: {
      name: 'icon__bundle-ancillary-fast-track-security',
      color: 'blue',
    },
    halftone: {
      name: 'icon__fast-track',
    },
  })
  .set(ANCILLARY_LOUNGE_ACCESS, {
    simple: {
      name: 'icon__exclusive-lounge__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__couch',
    },
  })
  .set(ANCILLARY_LOUNGE_ACCESS_V2, {
    simple: {
      name: 'icon__exclusive-lounge__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__couch',
    },
  })
  .set(ANCILLARY_EXCLUSIVE_LOUNGE, {
    simple: {
      name: 'icon__exclusive-lounge__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__couch',
    },
  })
  .set(ANCILLARY_SEATING_TOGETHER_GUARANTEE, {
    simple: {
      name: 'icon__service--sitting-together',
    },
    halftone: {
      name: 'icon__seating-together-v2',
    },
  })
  .set(ANCILLARY_DELAYED_WARRANTY, {
    simple: {
      name: 'icon__bundle__ontime-arrival',
      color: 'blue',
    },
    halftone: {
      name: 'icon__clock',
    },
  })
  .set(ANCILLARY_DELAY_WARRANTY, {
    simple: {
      name: 'icon__bundle__ontime-arrival',
      color: 'blue',
    },
    halftone: {
      name: 'icon__clock',
    },
  })
  .set(ANCILLARY_AIRPORT_TRANSFER, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_AIRPORT_PARKING, {
    simple: {
      name: 'icon__service--airport-parking',
    },
    halftone: {
      name: 'icon__service--airport-parking',
    },
  })
  .set(ANCILLARY_AIRPORTPARKING, {
    simple: {
      name: 'icon__service--airport-parking',
    },
    halftone: {
      name: 'icon__service--airport-parking',
    },
  })
  .set(ANCILLARY_AIRPORT_TRANSFERS, {
    simple: {
      name: 'icon__bus__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__bus',
    },
  })
  .set(ANCILLARY_WIZZ_FLEX, {
    simple: {
      name: 'icon__bundle-ancillary-wizz-flex',
      color: 'blue',
    },
    halftone: {
      name: 'icon__service--wizz-flex',
    },
  })
  .set(ANCILLARY_AIRPORT_CHECK_IN, {
    simple: {
      name: 'icon__bundle-ancillary-airport-check-in',
      color: 'blue',
    },
    halftone: {
      name: 'icon__airport-checkin-service',
    },
  })
  .set(ANCILLARY_AIRPORT_CHECKIN, {
    simple: {
      name: 'icon__bundle-ancillary-airport-check-in',
      color: 'blue',
    },
    halftone: {
      name: 'icon__airport-checkin-service',
    },
  })
  .set(ANCILLARY_AUTO_CHECK_IN, {
    simple: {
      name: 'icon__bundle-ancillary-auto-check-in',
      color: 'blue',
    },
    halftone: {
      name: 'icon__auto-check-in',
    },
  })
  .set(ANCILLARY_PRIVILEGE_PASS, {
    simple: {
      name: 'icon__privilege-pass__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__card--user',
    },
  })
  .set(ANCILLARY_E_SIM_DATA_ROAMING, {
    halftone: {
      name: 'icon__e-sim-data-roaming',
    },
  })
  .set(ANCILLARY_CAR_RENTAL, {
    halftone: {
      name: 'icon__service--car',
    },
  })
  .set(ANCILLARY_CAR_TRAWLER, {
    halftone: {
      name: 'icon__service--car',
    },
  })
  .set(ANCILLARY_BOOKING_COM, {
    halftone: {
      name: 'icon__service--booking-com',
    },
  })
  .set(ANCILLARY_CHECKED_IN_BAGGAGE, {
    simple: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG1, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG2, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG3, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG4, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG5, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_U_BAG6, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTONE, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTTWO, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTTHREE, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTFOUR, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTFIVE, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_ULTRALIGHTSIX, {
    simple: {
      name: 'checked_in_bag_variation3-light',
      color: 'blue',
    },
  })
  .set(ANCILLARY_LIGHTONE, {
    simple: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_LIGHTTWO, {
    simple: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_LIGHTTHREE, {
    simple: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_EXTRALIGHTONE, {
    simple: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMONE, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMTWO, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMTHREE, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMFOUR, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMFIVE, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_MEDIUMSIX, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG1, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG2, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG3, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG4, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG5, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_M_BAG6, {
    simple: {
      name: 'checked_in_bag_variation3-medium',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG1, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG2, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG3, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG4, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG5, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BAG6, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYONE, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYTWO, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYTHREE, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYFOUR, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYFIVE, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HEAVYSIX, {
    simple: {
      name: 'checked_in_bag_variation3-heavy',
      color: 'blue',
    },
  })
  .set(ANCILLARY_SPORTS_EQUIPMENT, {
    simple: {
      name: 'icon__bundle__bag--sport',
      color: 'blue',
    },
  })
  .set(ANCILLARY_HAND_BAGGAGE, {
    // FE only ancillary
    simple: {
      name: 'icon__bundle-ancillary-hand-baggage',
      color: 'blue',
    },
  })
  .set(ANCILLARY_E_BAG1, {
    // FE only ancillary
    simple: {
      name: 'checked_in_bag_variation3-extra',
      color: 'blue',
    },
  })
  .set(ANCILLARY_SEAT, {
    // FE only ancillary
    simple: {
      name: 'icon__bundle-ancillary-seat-selection',
      color: 'blue',
    },
    halftone: {
      name: 'icon__seat',
    },
  })
  .set(ANCILLARY_SEAT_RESERVATION, {
    // FE only ancillary
    simple: {
      name: 'icon__bundle-ancillary-seat-selection',
      color: 'blue',
    },
    halftone: {
      name: 'icon__seat',
    },
  })
  .set(ANCILLARY_BAGS, {
    // FE only ancillary
    halftone: {
      name: 'icon__bundle-ancillary-ultralight-checked-in-baggage--large',
    },
  })
  .set(ANCILLARY_CAR_RENTAL, {
    simple: {
      name: 'icon__car-rental__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__car-rental',
    },
  })
  .set(ANCILLARY_CAR_TRAWLER, {
    simple: {
      name: 'icon__car-rental__simple',
      color: 'blue',
    },
    halftone: {
      name: 'icon__car-rental',
    },
  })
  .set(ANCILLARY_CAR_SIXT, {
    simple: {
      name: 'icon__car-rental__simple',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BLUE_RIBBON_BAG_TRACKING, {
    simple: {
      name: 'icon__blue-ribbon-bag-tracking',
      color: 'blue',
    },
  })
  .set(ANCILLARY_BLUE_RIBBON_BAG, {
    simple: {
      name: 'icon__blue-ribbon-bag-tracking',
      color: 'blue',
    },
  })
  .set(ANCILLARY_PRM_HEARING_IMPAIRED, {
    simple: {
      name: 'wheelchair',
      color: 'blue',
    },
  })
  .set(ANCILLARY_PRM_MOBILITY_IMPAIRED, {
    simple: {
      name: 'wheelchair',
      color: 'blue',
    },
  })
  .set(ANCILLARY_PRM_VISUALLY_IMPAIRED, {
    simple: {
      name: 'wheelchair',
      color: 'blue',
    },
  })
  .set(ANCILLARY_CAFE_AND_BOUTIQUE_VOUCHER, {
    simple: {
      name: 'icon__cafe-and-boutique-voucher',
      color: 'blue',
    },
  });

export const REDUCED_MOBILITY_OPTIONS = [
  ANCILLARY_PRM_HEARING_IMPAIRED,
  ANCILLARY_PRM_MOBILITY_IMPAIRED,
  ANCILLARY_PRM_VISUALLY_IMPAIRED,
  'assistanceNeededSSRCode',
  'assistanceDogSSRCode',
  'wheelchairManualSSRCode',
  'wheelchairBatteryPoweredSSRCode',
  'wheelchairCanAscendStairsSSRCode',
  'wheelchairCannotAscendStairsSSRCode',
  'wheelchairCanWalkSSRCode',
  'wheelchairCompletelyImmobileSSRCode',
];

export const NO_CHECK_IN_REASON_SEAT_NEEDED = 'onlineCheckinSeatNeeded';
export const NO_CHECK_IN_REASON_TOO_EARLY = 'onlineCheckinNotAvailableTooEarly';
export const NO_CHECK_IN_REASON_TOO_LATE = 'onlineCheckinNotAvailableTooLate';
export const NO_CHECK_IN_REASON_OVER_OR_UNDER_PAYMENT = 'overOrUnderPayment';
export const NO_CHECK_IN_REASON_NOT_AVAILABLE = 'onlineCheckinNotAvailable';
export const NO_CHECK_IN_REASON_BLOCKED_BY_OUTBOUND =
  'onlineCheckinBlockedByOutboundFlightCheckinState';
export const NO_CHECK_IN_REASON_CHECK_IN_DENIED = 'checkInDenied';
export const NO_CHECK_IN_REASON_WAY_TOO_EARLY = 'onlineCheckinNotAvailableWayTooEarly'; // FE only reason
export const NO_CHECK_IN_REASON_TOO_EARLY_WITH_SEATING_TOGETHER =
  'onlineCheckinNotAvailableTooEarlyWithSTTG'; // FE only reason
export const NO_CHECK_IN_REASON_BOOKING_IS_IN_QUEUE =
  'onlineCheckinNotAvailableBookingIsInQueue';

export const NO_CHECK_IN_REASONS = [
  NO_CHECK_IN_REASON_TOO_EARLY,
  NO_CHECK_IN_REASON_TOO_LATE,
  NO_CHECK_IN_REASON_OVER_OR_UNDER_PAYMENT,
  NO_CHECK_IN_REASON_NOT_AVAILABLE,
  NO_CHECK_IN_REASON_BLOCKED_BY_OUTBOUND,
  NO_CHECK_IN_REASON_WAY_TOO_EARLY,
  NO_CHECK_IN_REASON_TOO_EARLY_WITH_SEATING_TOGETHER,
];

export const PHASE_FLIGHT_FLOWN = 'phaseFlightFlown';
export const PHASE_CHECK_IN_NOT_AVAILABLE_BEFORE_DEADLINE =
  'checkInNotAvailableBeforeDeadline';
export const PHASE_CHECK_IN_NOT_AVAILABLE_AFTER_DEADLINE =
  'checkInNotAvailableAfterDeadline';
export const PHASE_CHECK_IN_AVAILABLE = 'checkInAvailable';
export const PHASE_ONLINE_CHECK_IN_NOT_AVAILABLE = 'checkInNotAvailable';
export const PHASE_CHECK_IN_NOT_AVAILABLE_FLIGHT_DISRUPTION =
  'checkInNotAvailableFlightDisruption';
export const PHASE_DOWNLOAD_BOARDING_CARDS_NOT_AVAILABLE_AFTER_DEADLINE =
  'downloadBoardingCardsNotAvailableAfterDeadline';
export const PHASE_CHECK_IN_NOT_AVAILABLE_AND_DOWNLOAD_BOARDING_CARDS_AVAILABLE =
  'checkInNotAvailableAndDownloadBoardingCardsAvailable';
export const PHASE_CHECK_IN_AND_DOWNLOAD_BOARDING_CARDS_AVAILABLE =
  'checkInAndDownloadBoardingCardsAvailable';
export const PHASE_DOWNLOAD_ALL_BOARDING_CARDS_AVAILABLE =
  'downloadAllBoardingCardsAvailable';
export const PHASE_REPRINT_BOARDING_CARDS = 'reprintBoardingCards';

export const INITIAL_FLOW_SEAT = 'seat';
export const INITIAL_FLOW_CHECK_IN = 'check-in';

export const CHECK_IN_DEADLINE_HOURS_PRIOR_FLIGHT = 3;
