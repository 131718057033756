export const SET_IS_TIMEOUTS_INITIALIZED = 'system/setIsTimeoutsInitialized';
export const SET_TIMEOUTS = 'system/setTimeouts';
export const RESET_TIMEOUTS = 'system/resetTimeouts';
export const SET_IS_BOT_BEHAVIOR_DETECTED = 'system/setIsBotBehaviorDetected';
export const UPDATE_WEBSITE_DOWN_INFO = 'system/updateWebsiteDownInfo';
export const ENABLE_TIMEOUT_MODAL = 'system/enableTimeoutModal';
export const DISABLE_TIMEOUT_MODAL = 'system/disableTimeoutModal';
export const SHOW_TIMEOUT_MODAL = 'system/showTimeoutModal';
export const HIDE_TIMEOUT_MODAL = 'system/hideTimeoutModal';
export const SHOW_CSRF_ERROR_MODAL = 'system/showCsrfErrorModal';
export const HIDE_CSRF_ERROR_MODAL = 'system/hideCsrfErrorModal';
export const SET_TIMEOUT_MODAL_VISIBILITY = 'system/setTimeoutModalVisibility';
export const SHOW_REGISTRATION_MODAL = 'system/showRegistrationModal';
export const HIDE_REGISTRATION_MODAL = 'system/hideRegistrationModal';
export const SHOW_REGISTRATION_PENDING_MODAL = 'system/showRegistrationPendingModal';
export const HIDE_REGISTRATION_PENDING_MODAL = 'system/hideRegistrationPendingModal';
export const SHOW_RESEND_VERIFICATION_EMAIL_MODAL =
  'system/showResendVerificationEmailModal';
export const HIDE_RESEND_VERIFICATION_EMAIL_MODAL =
  'system/hideResendVerificationEmailModal';
export const SET_EMAIL_VERIFICATION_ERRORS = 'system/emailVerificationErrors';
export const SET_REGISTRATION_EMAIL = 'system/setRegistrationEmail';
export const SHOW_LOGIN_MODAL = 'system/showLoginModal';
export const HIDE_LOGIN_MODAL = 'system/hideLoginModal';
export const SET_LOGIN_REDIRECT_URL = 'system/setLoginRedirectUrl';
export const RESET_LOGIN_REDIRECT_URL = 'system/resetLoginRedirectUrl';
export const SHOW_FORGOT_PASSWORD_MODAL = 'system/showForgotPasswordModal';
export const HIDE_FORGOT_PASSWORD_MODAL = 'system/hideForgotPasswordModal';
export const SHOW_RESET_PASSWORD_MODAL = 'system/showResetPasswordModal';
export const HIDE_RESET_PASSWORD_MODAL = 'system/hideResetPasswordModal';
export const SHOW_CHANGE_PASSWORD_MODAL = 'system/showChangePasswordModal';
export const HIDE_CHANGE_PASSWORD_MODAL = 'system/hideChangePasswordModal';
export const SHOW_FIND_BOOKING_MODAL = 'system/showFindBookingModal';
export const HIDE_FIND_BOOKING_MODAL = 'system/hideFindBookingModal';
export const SHOW_MOBILE_NAVIGATION = 'system/showMobileNavigation';
export const HIDE_MOBILE_NAVIGATION = 'system/hideMobileNavigation';
export const SET_MOBILE_NAVIGATION_VISIBILITY = 'system/setMobileNavigationVisibility';
export const SHOW_BOT_DETECTION_MODAL = 'system/showBotDetectionModal';
export const HIDE_BOT_DETECTION_MODAL = 'system/hideBotDetectionModal';
export const SHOW_NEW_PRIVACY_POLICY_MODAL = 'system/showNewPrivacyPolicyModal';
export const HIDE_NEW_PRIVACY_POLICY_MODAL = 'system/hideNewPrivacyPolicyModal';
export const SHOW_FLOW_RESET_MODAL = 'system/showFlowResetModal';
export const HIDE_FLOW_RESET_MODAL = 'system/hideFlowResetModal';
export const SHOW_LOGIN_LOGOUT_FEEDBACK_MODAL = 'system/showLoginLogoutFeedbackModal';
export const HIDE_LOGIN_LOGOUT_FEEDBACK_MODAL = 'system/hideLoginLogoutFeedbackModal';
export const TOGGLE_LANGUAGE_SWITCH = 'system/toggleLanguageSwitch';
export const SET_LANGUAGE_SWITCH_VISIBILITY = 'system/setLanguageSwitchVisibility';
export const SHOW_LOCALES_INSURANCE_MODAL = 'system/showLocalesInsuranceModal';
export const HIDE_LOCALES_INSURANCE_MODAL = 'system/hideLocalesInsuranceModal';
export const SHOW_INFORMATION_SERVICES = 'system/showInformationServices';
export const HIDE_INFORMATION_SERVICES = 'system/hideInformationServices';
export const TOGGLE_INFORMATION_SERVICES = 'system/toggleInformationServices';
export const IS_INFORMATION_SERVICES_MENU_ITEM_VISIBLE =
  'system/isInformationServicesMenuItemVisible';
export const SHOW_PLAN = 'system/showPlan';
export const HIDE_PLAN = 'system/hidePlan';
export const TOGGLE_PLAN = 'system/togglePlan';
export const SHOW_TOP_FLIGHTS = 'system/showTopFlights';
export const HIDE_TOP_FLIGHTS = 'system/hideTopFlights';
export const TOGGLE_TOP_FLIGHTS = 'system/toggleTopFlights';
export const SET_STICKY_HEADER_VISIBILITY = 'system/setStickyHeaderVisibility';
export const UNSET_STICKY_HEADER_VISIBILITY = 'system/unsetStickyHeaderVisibility';
export const SET_NAVIGATION_LOADING_STATE = 'system/setNavigationLoadingState';
export const UNSET_NAVIGATION_LOADING_STATE = 'system/unsetNavigationLoadingState';
export const SET_IS_INPUT_FOCUSED = 'system/setIsInputFocused';
export const SET_ACTIVE_ELEMENT_ID = 'system/setActiveElementId';
export const SET_IS_ROUTE_LOADING_STATE = 'system/setIsRouteLoadingState';
export const ADD_TO_ACTIVE_ENDPOINTS = 'system/addToActiveEndpoints';
export const REMOVE_FROM_ACTIVE_ENDPOINTS = 'system/removeFromActiveEndpoints';
export const SHOW_INVALID_DATE_OF_BIRTH_MODAL = 'system/showInvalidDateOfBirthModal';
export const HIDE_INVALID_DATE_OF_BIRTH_MODAL = 'system/hideInvalidDateOfBirthModal';
