import getSeats from '~/utils/booking/seat-selection/get-seats';
import areAdjacentSeats from '../are-adjacent-seats';

const areAdjacentSeatsByCode = (seatCodeA, seatCodeB, rawSeatMap) => {
  const seats = getSeats(rawSeatMap).filter((s) =>
    [seatCodeA, seatCodeB].includes(s.code)
  );
  return areAdjacentSeats(...seats);
};

export default areAdjacentSeatsByCode;
