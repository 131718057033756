import { CURRENCY_CODE_EUR } from '~/constants';
import * as m from './mutation-types';

export default {
  [m.SET_CURRENCY_CODES](
    state,
    { bookingCurrencyCode, departureCurrencyCode, arrivalCurrencyCode }
  ) {
    state.currencies.booking = bookingCurrencyCode;
    state.currencies.departure = departureCurrencyCode;
    state.currencies.arrival = arrivalCurrencyCode;
  },

  [m.SET_PAYMENT_CURRENCY](state, currencyCode) {
    state.payment.previousCurrency = state.payment.currency;
    state.payment.currency = currencyCode || CURRENCY_CODE_EUR;
  },

  [m.SET_EXTENDED_ANCILLARY](state) {
    state.hasExtendedAncillary = true;
  },

  [m.RESET_EXTENDED_ANCILLARY](state) {
    state.hasExtendedAncillary = false;
  },

  [m.SET_FLOW_TYPE](state, type) {
    state.flowType = type;
  },

  [m.SET_IS_CANCELLATION_REBOOK_FLOW](state) {
    state.isCancellationRebookFlow = true;
  },

  [m.SET_IS_CHECK_IN_FLOW](state, value) {
    state.isCheckInFlow = value;
  },

  [m.SET_PNR_AND_LAST_NAME](state, { pnr, lastName }) {
    state.pnr = pnr;
    state.lastName = lastName;
  },

  [m.RESET](state) {
    state.pnr = '';
    state.lastName = '';
  },
};
