import {
  ADULT,
  CHILD,
  INFANT,
  GENDER_FEMALE,
  GENDER_MALE,
  MIN_DATE_OF_BIRTH_YEARS, // vs wtf MIN_AGE_FOR_CHILD
  MIN_DATE_OF_BIRTH_YEARS_FOR_CHILD,
  MIN_DATE_OF_BIRTH_YEARS_FOR_INFANT,
  MAX_DATE_OF_BIRTH_YEARS,
  MAX_DATE_OF_BIRTH_YEARS_FOR_CHILD,
  MAX_DATE_OF_BIRTH_YEARS_FOR_INFANT,
  MIN_AGE_FOR_CHILD,
  MAX_AGE_FOR_CHILD,
  MAX_AGE_FOR_INFANT,
  MAX_AGE_FOR_ADULT,
  INFANT_AGE_LIMIT_IN_DAYS,
  GENDER_UNKNOWN,
  GENDER_ICON_MAN,
  GENDER_ICON_WOMAN,
  GENDER_ICON_UNKNOWN,
  GENDER_ICON_CHILD,
  GENDER_ICON_INFANT,
} from '~/constants';
import complement from '~/utils/fp/complement';
import allPass from '~/utils/fp/all-pass';
import compose from '~/utils/fp/compose';
import isEmpty from '~/utils/object/is-empty';
import { addDays, toDefaultFormat, differenceInDays, subYears } from '~/utils/date';
import { getCountryName } from '~/utils/resource';
import { getI18n } from '~/i18n';

const maxAges = {
  adult: MAX_AGE_FOR_ADULT,
  child: MAX_AGE_FOR_CHILD,
  infant: MAX_AGE_FOR_INFANT,
};

const minAges = {
  adult: MAX_AGE_FOR_CHILD,
  child: MIN_AGE_FOR_CHILD,
  infant: 0,
};

// passenger type may hold visibility (accessibility) information from now on
// (so far only during the group name change on itinerary, so I'm not gonna change everywhere)
export const getPassengerType = (passenger) => {
  let { passengerType = ADULT, type } = passenger || {};

  if (passengerType && typeof passengerType === 'object' && passengerType.value) {
    passengerType = passengerType.value;
  } else if (type) {
    passengerType = type;
  }
  return passengerType;
};

export const passengerTypeIs = (p, t) => getPassengerType(p) === t;

export const isFemale = (passenger) =>
  Boolean(
    passenger &&
      (passenger.gender === GENDER_FEMALE || passenger?.gender?.value === GENDER_FEMALE)
  );

export const isMale = (passenger) =>
  Boolean(
    passenger &&
      (passenger.gender === GENDER_MALE || passenger?.gender?.value === GENDER_MALE)
  );

export const isAdult = (passenger) => passengerTypeIs(passenger, ADULT);

export const isChild = (passenger) => passengerTypeIs(passenger, CHILD);

export const isInfant = (passenger) => passengerTypeIs(passenger, INFANT);

export const isNotInfant = complement(isInfant);

export const isTravelPartner = (passenger) =>
  Boolean(passenger && passenger.isTravelPartner);

export const isAnonymPassenger = (passenger) => Boolean(passenger && passenger.isAnonym);

// passengers will be mutated!
export function sortPassengers(passengers) {
  return [...passengers].sort(passengerComparator);
}

const passengerComparator = (passengerOne, passengerTwo) => {
  const numericalOrder =
    passengerOne.passengerNumber > passengerTwo.passengerNumber ? 1 : -1;
  if (isAdult(passengerOne)) {
    return isAdult(passengerTwo) ? numericalOrder : -1;
  }
  if (isChild(passengerOne)) {
    return isAdult(passengerTwo) ? 1 : isInfant(passengerTwo) ? -1 : numericalOrder;
  }
  if (isInfant(passengerOne)) {
    return isInfant(passengerTwo) ? numericalOrder : 1;
  }
  return -1;
};

export function getName(passenger, getByValue = false) {
  if (!(passenger && passenger.firstName && passenger.lastName)) {
    return '';
  }
  if (getByValue) {
    return `${passenger.firstName.value} ${passenger.lastName.value}`;
  }
  return `${passenger.firstName} ${passenger.lastName}`;
}

export function getGender(passenger) {
  if (!passenger) return GENDER_UNKNOWN;
  if (isMale(passenger)) return GENDER_MALE;
  if (isFemale(passenger)) return GENDER_FEMALE;
  return GENDER_UNKNOWN;
}

export function isReducedMobility(passenger) {
  passenger = passenger || { prm: false, reducedMobility: {} };
  passenger.reducedMobility = passenger.reducedMobility || {};

  // where is this `prm` thing set ?
  const prm = passenger.prm;
  const selectedRedmob = Object.values(passenger.reducedMobility).includes(true);

  return prm || selectedRedmob;
}

/**
 * @param {Object} bookingPassenger
 * @returns {Boolean}
 */
export const isNotReducedMobility = complement(isReducedMobility);

// who may serve as an assistant for a redmob person?
export function isReducedMobilityAssistant(passengers, passenger) {
  const reducedMobilityPassengers = passengers.filter(isReducedMobility);
  const regularPassengers = passengers.filter(complement(isReducedMobility));

  // no redmob at all, gtfo
  if (isEmpty(reducedMobilityPassengers)) {
    return false;
  }

  // all the able passengers must be assistants (2 regular, 3 redmob for example)
  if (regularPassengers.length <= reducedMobilityPassengers.length) {
    return true;
  }

  // 5 regular, 2 redmob => first two regular passenger will be marked as assistants
  const assistants = regularPassengers.slice(0, reducedMobilityPassengers.length);
  return assistants.includes(passenger);
}

/**
 * @param {Object} bookingPassenger
 * @returns {Boolean}
 */
export const isRegularAdult = allPass(isAdult, isNotInfant, isNotReducedMobility);

export function hasOnlyReducedMobilityPassengers(passengers) {
  return (
    passengers
      .filter(isNotInfant)
      .filter(
        (passenger) =>
          isReducedMobility(passenger) ||
          isReducedMobilityAssistant(passengers, passenger)
      ).length === passengers.length && passengers.length > 0
  );
}

export function getPlaceHolderName(passenger, index) {
  if (!passenger.firstName || !passenger.lastName) {
    return `${getI18n().t(getPassengerType(passenger))} ${index}`;
  }

  return `${passenger.firstName} ${passenger.lastName}`;
}

export const getPassengerKind = (passenger) => {
  const { gender } = passenger;
  const male = isMale(passenger);
  const female = isFemale(passenger);
  const adult = isAdult(passenger);
  const child = isChild(passenger);

  if (adult && female) return 'woman';
  if (adult && male) return 'man';
  if (adult && !gender) return 'unknown';
  if (child) return 'child';

  return 'infant';
};

export function getPassengerNumberWithType(
  passenger,
  explicitNumber,
  omitNumberForInfant
) {
  const passengerType = getPassengerType(passenger);

  if (!passengerType) {
    return '';
  }

  const id =
    typeof explicitNumber === 'number' ? explicitNumber : passenger.passengerNumber;
  const i18n = getI18n();

  if (isTravelPartner(passenger)) {
    return `${i18n.t('travel-partner-name')} (${i18n.t(id + 1)})`;
  }

  if (isInfant(passenger)) {
    return `${i18n.t('passenger')} (${i18n.t(passengerType)})`;
  }

  return `${id + 1}. ${i18n.t('passenger')} (${i18n.t(passengerType)})`;
}

export function getPassengerNumberWithNameAndType(passenger, explicitNumber) {
  const passengerType = getPassengerType(passenger);

  if (!passengerType) {
    return '';
  }

  const id =
    typeof explicitNumber === 'number' ? explicitNumber : passenger.passengerNumber;
  const i18n = getI18n();

  if (isTravelPartner(passenger)) {
    return `${i18n.t('travel-partner-name')} (${i18n.t(id + 1)})`;
  }

  if (isInfant(passenger)) {
    if (passenger.firstName && passenger.lastName) {
      return `${passenger.firstName} ${passenger.lastName} (${i18n.t(passengerType)})`;
    } else {
      return `${i18n.t('passenger')} (${i18n.t(passengerType)})`;
    }
  }

  if (passenger.firstName && passenger.lastName) {
    return `${id + 1}. ${passenger.firstName} ${passenger.lastName} (${i18n.t(
      passengerType
    )})`;
  } else {
    return `${id + 1}. ${i18n.t('passenger')} (${i18n.t(passengerType)})`;
  }
}

export const getAddressLine1 = (passenger) => passenger.address;

export const getAddressLine2 = (countries, passenger) =>
  `${passenger.zip} ${passenger.city}, ${getCountryName(countries, passenger.country)}`;

export const isChildGenderRequired = () => true; // WIP

export function getMinDateOfBirth(passenger) {
  if (isAdult(passenger)) {
    return MIN_DATE_OF_BIRTH_YEARS;
  } else if (isChild(passenger)) {
    return MIN_DATE_OF_BIRTH_YEARS_FOR_CHILD;
  }
  return MIN_DATE_OF_BIRTH_YEARS_FOR_INFANT;
}

export function getMaxDateOfBirth(passenger) {
  if (isAdult(passenger)) {
    return MAX_DATE_OF_BIRTH_YEARS;
  } else if (isChild(passenger)) {
    return MAX_DATE_OF_BIRTH_YEARS_FOR_CHILD;
  }
  return MAX_DATE_OF_BIRTH_YEARS_FOR_INFANT;
}

export function copyPrivilegePassParams(passengers, itineraryPassengers) {
  passengers.filter(isNotInfant).forEach((passenger) => {
    const privilegePassPassenger = itineraryPassengers.find(
      (iPassenger) => passenger.passengerNumber === iPassenger.passengerNumber
    );
    if (privilegePassPassenger) {
      passenger.privilegePassActivated = privilegePassPassenger.hasAppliedPrivilegePass;
      passenger.hasAppliedPrivilegePass = privilegePassPassenger.hasAppliedPrivilegePass;
    }
  });
}

// moved it here from passenger component. We already have a `getMinDateOfBirth`
// which returns a year only, this one returns a date with the flight dep-date taken into account
export function getMinDateOfBirthByFlight(
  passengerType = 'child',
  outboundDepartureDate,
  returnDepartureDate
) {
  let date = returnDepartureDate || outboundDepartureDate;
  date = addDays(1, date);
  return compose(toDefaultFormat, subYears(maxAges[passengerType]))(date);
}

export function getMaxDateOfBirthByFlight(
  passengerType = 'child',
  outboundDepartureDate
) {
  return compose(
    toDefaultFormat,
    subYears(minAges[passengerType])
  )(outboundDepartureDate);
}

export const isInfantAgeReachedLimit = (dateOfBirth, flightDate) => {
  const infantDateBirth = dateOfBirth ? toDefaultFormat(dateOfBirth) : null;

  if (!infantDateBirth) {
    return false;
  }

  return differenceInDays(flightDate, infantDateBirth) < INFANT_AGE_LIMIT_IN_DAYS;
};

/**
 * Pax number to letter for flexible travel partner SurNameA, SurNameB etc, etc
 * ASCII('0') = 48
 * ASCII('A') = 65
 *
 * @param {Number} passengerNumber passenger number
 * @param {Number} offset offset on ASCII table, default: the distance between '0' and 'A'
 * @returns {String} alphabet letter
 */
export const passengerNumberToAlphabet = (passengerNumber, offset = 17) =>
  String.fromCharCode(48 + (passengerNumber || 0) + offset);

export const reducedMobilitySchema = () => ({
  assistanceDog: null,
  assistanceNeeded: null,
  blind: null,
  deaf: null,
  wheelchairBatteryPowered: null,
  wheelchairCanAscendStairs: null,
  wheelchairCanWalk: null,
  wheelchairImmobile: null,
  wheelchairManualPowered: null,
  wheelchairTravelIfOnboardWheelchairNotAvailable: null,
  wheelchair: null,
  hasOwnWheelchair: null,
});

export const getGenderIconType = (passenger) => {
  if (isAdult(passenger) && isMale(passenger)) return GENDER_ICON_MAN;
  if (isAdult(passenger) && isFemale(passenger)) return GENDER_ICON_WOMAN;
  if (isChild(passenger)) return GENDER_ICON_CHILD;
  if (isInfant(passenger)) return GENDER_ICON_INFANT;
  return GENDER_ICON_UNKNOWN;
};

export const areBaggageOptionsEqual = (a, b) => {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  return a.every((aOption) =>
    b.find((bOption) => {
      if (aOption.option !== bOption.option) return false;
      if (aOption.price.amount === bOption.price.amount) return true;
      return aOption.price.amount !== 0 && bOption.price.amount !== 0;
    })
  );
};
