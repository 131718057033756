import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  otherFlightNameMap,
  CURRENCY_CODE_EUR,
} from '~/constants';
import compose from '~/utils/fp/compose';
import max from '~/utils/fp/max';
import pathOr from '~/utils/fp/path-or';
import inc from '~/utils/fp/inc';
import dec from '~/utils/fp/dec';
import halter from '~/utils/object/halter';
import hevolve from '~/utils/object/hevolve';
import {
  mapSpecificTypeToSummaryLabel,
  mapSpecificTypeToSummaryDiscountLabel,
} from '~/utils/booking/summary';
import hassocPath from '~/utils/object/hassoc-path';
import add from '~/utils/fp/add';
import isNotEmpty from '~/utils/object/is-not-empty';
import { getDefaultPrice, getPrice } from '~/utils/price';
import isNotUndefined from '~/utils/object/is-not-undefined';
import { mutationAssocPath } from '~/utils/store';
import * as m from './mutation-types';
import {
  resetState,
  flightsFrom,
  passengersFrom,
  seatsFrom,
  groupedServicesFrom,
  paymentsFrom,
} from './internal';

const updateFlightsWdcMembership = (state, payload = {}) => {
  const { bookingCurrencyCode, payableItem } = payload;
  const wdcMembershipFee =
    (payableItem.promotedMembershipPrice
      ? payableItem.promotedMembershipPrice
      : payableItem.membershipPrice) || getDefaultPrice(bookingCurrencyCode);
  const { flights } = state;
  const prevWdcMembershipFeeAmount = pathOr(0, 'wdcMembershipFee.amount', flights);
  const prevFlightsTotalAmount = pathOr(0, 'total.amount', flights);
  const isFareLockAdded = isNotEmpty(flights.fareLock.price);
  const prevTotalAmount = pathOr(0, 'total.amount', state);

  const newFlightsTotalAmount = isFareLockAdded
    ? prevFlightsTotalAmount
    : Math.max(0, prevFlightsTotalAmount - prevWdcMembershipFeeAmount) +
      wdcMembershipFee.amount;

  const newTotalAmount = isFareLockAdded
    ? prevTotalAmount
    : Math.max(0, prevTotalAmount - prevFlightsTotalAmount) + newFlightsTotalAmount;

  clearFlightsWdcMembership(state, bookingCurrencyCode);

  halter(
    {
      total: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDue: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDueWithoutThirdPartyServices: {
        amount: newTotalAmount,
        currencyCode: bookingCurrencyCode,
      },
      exchangedAmountDue: null,
      exchangedAmountDueWithoutThirdPartyServices: null,

      flights: {
        total: { amount: newFlightsTotalAmount, currencyCode: bookingCurrencyCode },
        payableWdcMembership: payableItem,
        wdcMembershipFee,
      },
    },
    state
  );
};

const clearFlightsWdcMembership = (state, bookingCurrencyCode) => {
  const { flights } = state;
  const prevWdcMembershipFeeAmount = pathOr(0, 'wdcMembershipFee.amount', flights);
  const prevFlightsTotalAmount = pathOr(0, 'total.amount', flights);
  const isFareLockAdded = isNotEmpty(flights.fareLock.price);
  const prevTotalAmount = pathOr(0, 'total.amount', state);

  const newFlightsTotalAmount = isFareLockAdded
    ? prevFlightsTotalAmount
    : Math.max(0, prevFlightsTotalAmount - prevWdcMembershipFeeAmount);

  const newTotalAmount = isFareLockAdded
    ? prevTotalAmount
    : Math.max(0, prevTotalAmount - prevFlightsTotalAmount) + newFlightsTotalAmount;

  hassocPath('flights.payableWdcMembership', {}, state);
  delete state.flights.wdcMembershipFee;

  halter(
    {
      total: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDue: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDueWithoutThirdPartyServices: {
        amount: newTotalAmount,
        currencyCode: bookingCurrencyCode,
      },
      exchangedAmountDue: null,
      exchangedAmountDueWithoutThirdPartyServices: null,

      flights: {
        total: { amount: newFlightsTotalAmount, currencyCode: bookingCurrencyCode },
      },
    },
    state
  );
};

const updateFlightsWdcRenewal = (state, payload = {}) => {
  const { bookingCurrencyCode, payableItem } = payload;
  const wdcRenewalFee =
    payableItem.membershipPrice || getDefaultPrice(bookingCurrencyCode);
  const { flights } = state;
  const prevWdcRenewalFeeAmount = pathOr(0, 'wdcRenewalFee.amount', flights);
  const prevFlightsTotalAmount = pathOr(0, 'total.amount', flights);
  const isFareLockAdded = isNotEmpty(flights.fareLock.price);
  const prevTotalAmount = pathOr(0, 'total.amount', state);

  const newFlightsTotalAmount = isFareLockAdded
    ? prevFlightsTotalAmount
    : Math.max(0, prevFlightsTotalAmount - prevWdcRenewalFeeAmount) +
      wdcRenewalFee.amount;

  const newTotalAmount = isFareLockAdded
    ? prevTotalAmount
    : Math.max(0, prevTotalAmount - prevFlightsTotalAmount) + newFlightsTotalAmount;

  clearFlightsWdcRenewal(state, bookingCurrencyCode);

  halter(
    {
      total: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDue: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDueWithoutThirdPartyServices: {
        amount: newTotalAmount,
        currencyCode: bookingCurrencyCode,
      },
      exchangedAmountDue: null,
      exchangedAmountDueWithoutThirdPartyServices: null,

      flights: {
        total: { amount: newFlightsTotalAmount, currencyCode: bookingCurrencyCode },
        wdcRenewalFee,
      },
    },
    state
  );
};

const clearFlightsWdcRenewal = (state, bookingCurrencyCode) => {
  const { flights } = state;
  const prevWdcRenewalFeeAmount = pathOr(0, 'wdcRenewalFee.amount', flights);
  const prevFlightsTotalAmount = pathOr(0, 'total.amount', flights);
  const isFareLockAdded = isNotEmpty(flights.fareLock.price);
  const prevTotalAmount = pathOr(0, 'total.amount', state);

  const newFlightsTotalAmount = isFareLockAdded
    ? prevFlightsTotalAmount
    : Math.max(0, prevFlightsTotalAmount - prevWdcRenewalFeeAmount);

  const newTotalAmount = isFareLockAdded
    ? prevTotalAmount
    : Math.max(0, prevTotalAmount - prevFlightsTotalAmount) + newFlightsTotalAmount;

  delete state.flights.wdcRenewalFee;

  halter(
    {
      total: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDue: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
      amountDueWithoutThirdPartyServices: {
        amount: newTotalAmount,
        currencyCode: bookingCurrencyCode,
      },
      exchangedAmountDue: null,
      exchangedAmountDueWithoutThirdPartyServices: null,

      flights: {
        total: { amount: newFlightsTotalAmount, currencyCode: bookingCurrencyCode },
      },
    },
    state
  );
};

export default {
  [m.SET_MCP_SELECTION_SOURCE]: mutationAssocPath('mcpSelectionSource'),

  [m.SET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT_FLAG]: (state, value) => {
    hassocPath('isWizzFlexSelectedAtFlightSelect', value, state);
  },

  [m.UNSET_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT]: hassocPath(
    'isWizzFlexSelectedAtFlightSelect',
    false
  ),

  [m.BACKUP_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT](state) {
    state.isWizzFlexSelectedAtFlightSelectBackup = state.isWizzFlexSelectedAtFlightSelect;
  },

  [m.RESTORE_IS_WIZZ_FLEX_SELECTED_AT_FLIGHT_SELECT](state) {
    state.isWizzFlexSelectedAtFlightSelect = state.isWizzFlexSelectedAtFlightSelectBackup;
  },

  [m.RESET_TOTAL_AMOUNT]: hassocPath('total.amount', 0),

  [m.RESET]: resetState,

  [m.RESET_SUMMARY]: resetState,
  [m.UPDATE_FLIGHTS_WDC_MEMBERSHIP]: updateFlightsWdcMembership,
  [m.CLEAR_FLIGHTS_WDC_MEMBERSHIP]: clearFlightsWdcMembership,
  [m.UPDATE_FLIGHTS_WDC_RENEWAL]: updateFlightsWdcRenewal,
  [m.CLEAR_FLIGHTS_WDC_RENEWAL]: clearFlightsWdcRenewal,

  [m.UPDATE_FLIGHTS_FLIGHT](state, payload = {}) {
    const { flights, total } = state;
    const prevFlightsTotalAmount = flights.total ? flights.total.amount : 0;
    const fareLockPrice = state.flights.fareLock.price;
    const isFareLockAdded = isNotEmpty(fareLockPrice);
    const totalAmount = total ? total.amount : 0;
    const {
      type,
      flight,
      fare,
      isGroupSeatRequest,
      bookingCurrencyCode,
      wdcMembershipFee,
    } = payload;
    const {
      flightNumber,
      carrierCode,
      departureStation: departureStationIata,
      arrivalStation: arrivalStationIata,
      departureDateTime: departureDate,
      arrivalDateTime: arrivalDate,
      opSuffix,
    } = flight;
    const { bundle, isFamilyBundle, priceDetail } = fare;

    const bookingDepartureStation =
      (type === OUTBOUND_FLIGHT_NAME ? departureStationIata : arrivalStationIata) || '';
    const bookingArrivalStation =
      (type === OUTBOUND_FLIGHT_NAME ? arrivalStationIata : departureStationIata) || '';

    let {
      price: ticketPrice,
      displayCount: ticketDisplayCount,
      discountType,
      discountRate,
      discountPrice,
      total: newFlightTotal,
      fees,
      promotion,
    } = priceDetail || {};
    fees = (fees || []).map((fee, index) => {
      const { price, discountPrice, displayCount, specificType } = fee || {};
      const label = mapSpecificTypeToSummaryLabel(specificType);
      const discountLabel = mapSpecificTypeToSummaryDiscountLabel(specificType);
      return {
        id: index,
        price: price || getDefaultPrice(bookingCurrencyCode),
        ...(discountPrice && { discountPrice }),
        displayCount: displayCount || 0,
        label,
        ...(discountLabel && { discountLabel }),
        specificType,
      };
    });

    newFlightTotal = newFlightTotal || getDefaultPrice(bookingCurrencyCode);

    const otherType = otherFlightNameMap.get(type);
    const otherFlight = flights[`${otherType}Flight`];
    const otherFlightTotalAmount = pathOr(0, 'total.amount', otherFlight);
    const wdcMembershipFeeAmount = isNotUndefined(wdcMembershipFee)
      ? wdcMembershipFee.amount
      : 0;
    const newFlightsTotalAmount = isFareLockAdded
      ? fareLockPrice.amount
      : newFlightTotal.amount + otherFlightTotalAmount + wdcMembershipFeeAmount;

    const newTotalAmount = isFareLockAdded
      ? fareLockPrice.amount
      : Math.max(0, totalAmount - prevFlightsTotalAmount) + newFlightsTotalAmount;

    const lockedPrice = isFareLockAdded
      ? {
          amount: newFlightTotal.amount + otherFlightTotalAmount,
          currencyCode: bookingCurrencyCode,
        }
      : getDefaultPrice(bookingCurrencyCode);

    const promotedPrice = promotion?.promotedPrice ?? null;
    const promotionValue = promotion?.discountValue ?? null;

    halter(
      {
        departureStation: bookingDepartureStation,
        arrivalStation: bookingArrivalStation,
        total: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
        amountDue: { amount: newTotalAmount, currencyCode: bookingCurrencyCode },
        amountDueWithoutThirdPartyServices: {
          amount: newTotalAmount,
          currencyCode: bookingCurrencyCode,
        },
        exchangedAmountDue: null,
        exchangedAmountDueWithoutThirdPartyServices: null,

        flights: {
          total: { amount: newFlightsTotalAmount, currencyCode: bookingCurrencyCode },
          [`${type}Flight`]: {
            flightNumber: flightNumber || '',
            carrierCode: carrierCode || '',
            opSuffix: opSuffix || '',
            departureStationIata: departureStationIata || '',
            departureDate: departureDate || '',
            arrivalStationIata: arrivalStationIata || '',
            arrivalDate: arrivalDate || '',
            bundle: bundle || '',
            isFamilyBundle: isFamilyBundle || false,
            isGroupSeatRequest: isGroupSeatRequest || false,
            ticketPrice: ticketPrice || getDefaultPrice(bookingCurrencyCode),
            ticketDisplayCount: ticketDisplayCount || 0,
            discountType: discountType || '',
            discountRate: discountRate || 0,
            discountPrice: discountPrice || getDefaultPrice(bookingCurrencyCode),
            total: newFlightTotal,
            fees,
            promotedPrice,
            promotionValue,
          },

          fareLock: {
            ...(isFareLockAdded ? { lockedPrice } : {}),
          },
        },
      },
      state
    );
  },

  [m.CLEAR_FLIGHTS_FLIGHT](state, { flightName, bookingCurrencyCode }) {
    if (![OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME].includes(flightName)) return;

    const flightTotal =
      state.flights[`${flightName}Flight`].total || getDefaultPrice(bookingCurrencyCode);
    hassocPath(`flights.${flightName}Flight`, {}, state);

    let {
      total,
      amountDue,
      amountDueWithoutThirdPartyServices,
      flights,
      flights: {
        total: flightsTotal,
        fareLock: { price: fareLockPrice },
      },
    } = state;

    flightsTotal = flightsTotal || getDefaultPrice(bookingCurrencyCode);
    const isFareLockAdded = isNotEmpty(fareLockPrice);

    total = total || getDefaultPrice(bookingCurrencyCode);
    amountDue = amountDue || getDefaultPrice(bookingCurrencyCode);
    amountDueWithoutThirdPartyServices =
      amountDueWithoutThirdPartyServices || getDefaultPrice(bookingCurrencyCode);

    const outboundFlightTotal =
      flights.outboundFlight.total || getDefaultPrice(bookingCurrencyCode);
    const returnFlightTotal =
      flights.returnFlight.total || getDefaultPrice(bookingCurrencyCode);
    const newFlightsTotalAmount = isFareLockAdded
      ? flightsTotal.amount
      : outboundFlightTotal.amount + returnFlightTotal.amount;
    const newFlightsTotal = {
      amount: newFlightsTotalAmount,
      currencyCode: bookingCurrencyCode,
    };

    const newTotalAmount = isFareLockAdded
      ? total.amount
      : Math.max(0, total.amount - flightTotal.amount);
    const newTotal = { amount: newTotalAmount, currencyCode: bookingCurrencyCode };
    const newAmountDueAmount = isFareLockAdded
      ? amountDue.amount
      : Math.max(0, amountDue.amount - flightTotal.amount);
    const newAmountDue = {
      amount: newAmountDueAmount,
      currencyCode: bookingCurrencyCode,
    };
    const newAmountDueWithoutThirdPartyServicesAmount = isFareLockAdded
      ? amountDueWithoutThirdPartyServices.amount
      : Math.max(0, amountDueWithoutThirdPartyServices.amount - flightTotal.amount);
    const newAmountDueWithoutThirdPartyServices = {
      amount: newAmountDueWithoutThirdPartyServicesAmount,
      currencyCode: bookingCurrencyCode,
    };

    const lockedPrice = isFareLockAdded
      ? {
          amount: outboundFlightTotal.amount + returnFlightTotal.amount,
          currencyCode: bookingCurrencyCode,
        }
      : getDefaultPrice(bookingCurrencyCode);

    halter(
      {
        total: newTotal,
        amountDue: newAmountDue,
        amountDueWithoutThirdPartyServices: newAmountDueWithoutThirdPartyServices,
        exchangedAmountDue: null,
        exchangedAmountDueWithoutThirdPartyServices: null,
        flights: {
          total: newFlightsTotal,
          fareLock: {
            ...(isFareLockAdded ? { lockedPrice } : {}),
          },
        },
      },
      state
    );
  },

  [m.UPDATE_FLIGHTS_FARE_LOCK](state, payload = {}) {
    const { price, bookingCurrencyCode = CURRENCY_CODE_EUR } = payload;
    const {
      flights: { total: flightsTotal },
    } = state;
    halter(
      {
        total: price,
        amountDue: price,
        amountDueWithoutThirdPartyServices: price,
        exchangedAmountDue: null,
        exchangedAmountDueWithoutThirdPartyServices: null,

        flights: {
          total: price,
          fareLock: {
            price: price || getDefaultPrice(bookingCurrencyCode),
            lockedPrice: flightsTotal || getDefaultPrice(bookingCurrencyCode),
          },
        },
      },
      state
    );
  },

  [m.CLEAR_FLIGHTS_FARE_LOCK](state, bookingCurrencyCode = CURRENCY_CODE_EUR) {
    hassocPath('flights.fareLock', {}, state);

    const { flights } = state;
    const outboundFlightTotal =
      flights.outboundFlight.total || getDefaultPrice(bookingCurrencyCode);
    const returnFlightTotal =
      flights.returnFlight.total || getDefaultPrice(bookingCurrencyCode);
    const flightsTotal = {
      amount: outboundFlightTotal.amount + returnFlightTotal.amount,
      currencyCode: outboundFlightTotal.currencyCode,
    };

    halter(
      {
        total: flightsTotal,
        amountDue: flightsTotal,
        amountDueWithoutThirdPartyServices: flightsTotal,
        exchangedAmountDue: null,
        exchangedAmountDueWithoutThirdPartyServices: null,
        flights: { total: flightsTotal },
      },
      state
    );
  },

  [m.UPDATE](state, payload = {}) {
    const {
      resourcesStations,
      disallowAutoUpdateOfFlightsAndTotal = false,
      totals,
      flights,
      passengers,
      seats,
      services,
      payment,
      bookingCurrencyCode,
      paymentCurrencyCode,
      departureStation,
      arrivalStation,
    } = payload;

    let {
      total,
      amountDue,
      amountDueCharge: amountDueWithoutThirdPartyServices,
      exchangedAmountDue,
      exchangedAmountDueCharge: exchangedAmountDueWithoutThirdPartyServices,
    } = totals || {};

    const stations = {
      departure: departureStation,
      arrival: arrivalStation,
    };

    const newFlights = disallowAutoUpdateOfFlightsAndTotal
      ? null
      : flightsFrom(bookingCurrencyCode, flights);
    const newPassengers = passengersFrom(bookingCurrencyCode, passengers);
    const newSeats = seatsFrom(bookingCurrencyCode, seats);
    const newServices = groupedServicesFrom(
      resourcesStations,
      bookingCurrencyCode,
      services,
      stations
    );
    const newPayments = paymentsFrom(bookingCurrencyCode, payment);

    // note: `disallowAutoUpdateOfFlightsAndTotal` flag is used in flight select step
    //  only where we mainly manually manage the cart + service selection like Wizz Flex
    //  is done by ancillaries POST which may rewrite our manually crafted cart so
    //  the flag is born to resolve this
    if (disallowAutoUpdateOfFlightsAndTotal) {
      const currencyCode = total ? total.currencyCode : bookingCurrencyCode;
      const newTotalAmount = [
        state.flights,
        newPassengers,
        newSeats,
        newServices,
        newPayments,
      ]
        .map(pathOr(0, 'total.amount'))
        .reduce(add, 0);

      total = getPrice(newTotalAmount, currencyCode);
      amountDue = getPrice(newTotalAmount, currencyCode);
      amountDueWithoutThirdPartyServices = getPrice(newTotalAmount, currencyCode);
      exchangedAmountDue = null;
      exchangedAmountDueWithoutThirdPartyServices = null;
    }

    Object.assign(state, {
      total: total || getDefaultPrice(bookingCurrencyCode),
      amountDue: amountDue || getDefaultPrice(bookingCurrencyCode),
      amountDueWithoutThirdPartyServices:
        amountDueWithoutThirdPartyServices || getDefaultPrice(bookingCurrencyCode),
      exchangedAmountDue: exchangedAmountDue || getDefaultPrice(paymentCurrencyCode),
      exchangedAmountDueWithoutThirdPartyServices:
        exchangedAmountDueWithoutThirdPartyServices ||
        getDefaultPrice(paymentCurrencyCode),
      ...(newFlights ? { flights: newFlights } : {}),
      passengers: newPassengers,
      seats: newSeats,
      services: newServices,
      payments: newPayments,
      departureStation,
      arrivalStation,
    });
  },

  [m.INCREMENT_LOADING_COUNTER]: hevolve({ loadingCounter: inc }),

  [m.DECREMENT_LOADING_COUNTER]: hevolve({
    loadingCounter: compose(max(0), dec),
  }),

  [m.SET_EXCHANGED_AMOUNT_DUE](state, value) {
    state.exchangedAmountDue = value;
  },

  [m.SET_EXCHANGED_AMOUNT_DUE_WITHOUT_THIRD_PARTY_SERVICES](state, value) {
    state.exchangedAmountDueWithoutThirdPartyServices = value;
  },

  [m.UPDATE_PAST_PURCHASES](state, pastPurchases) {
    state.pastPurchases = halter(pastPurchases, state.pastPurchases);
  },

  [m.SET_PAYABLE_FLIGHT_AND_FARE](state, payload = {}) {
    const { flights } = state;
    const { type, ecommerceItem } = payload;
    flights[`${type}PayableItem`] = ecommerceItem;
  },

  [m.SET_INSURANCE_ADD_TO_CART](state, payload) {
    state.hasPurchasedInsuranceAlready = payload;
  },
};
