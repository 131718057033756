import { isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';
import * as globalValueGetters from '~/store/modules/global-value/getters';
import * as globalValueActions from '~/store/modules/global-value/actions';
import * as localeActions from '~/store/modules/locale/actions';
import isNotEmpty from '../object/is-not-empty';

export const getLanguage = (state = {}) => {
  if (isSSRBuild && (isNotEmpty(state) || isServer())) {
    return globalValueGetters.getLanguage(state);
  }

  return window.language || globalValueGetters.getLanguage(state);
};

export const setLanguage = (value, store = {}) => {
  if (isSSRBuild && isServer()) {
    globalValueActions.setLanguage(store, value);
    localeActions.setLanguageCode(store, value.locale);
    return;
  }

  window.language = value;
};
