import curry from '~/utils/fp/curry';

/**
 * @typedef {(object: any{}) => any{}} InvertObjFn
 */
export const _invertObj = function invertObj(obj) {
  const props = Object.keys(obj);
  const len = props.length;
  let idx = 0;
  const out = {};

  while (idx < len) {
    const key = props[idx];
    out[obj[key]] = key;
    idx += 1;
  }
  return out;
};

/**
 * Flips the key and the values of the object
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/invertObj.js
 *
 * @type InvertObjFn
 * @example
 *
 *      inbertobj({'bob': 'burger'}) // => {burger: 'bob'}
 */
export default curry(_invertObj);
