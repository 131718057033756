import curry from '../curry';

/**
 * @typedef {<T>(fn: (a: T) => boolean, T[]) => T | undefined} FindFn
 */

/**
 * @type FindFn
 */
export const _find = (fn, arr) => arr.find((el) => fn(el));

/**
 * Returns the first element of the list which matches the predicate, or
 * `undefined` if no element matches.
 *
 * note: predicate function will be called as fn(element)
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/find.js
 *
 * @type FindFn
 * @example
 *
 *      const xs = [{a: 1}, {a: 2}, {a: 3}];
 *      find(propEq('a', 2))(xs); //=> {a: 2}
 *      find(propEq('a', 4))(xs); //=> undefined
 */
const find = curry(_find);

export default find;
