import curry from '../curry';

/**
 * @typedef {{<T>(el: T, arr: T[]) => T[]}} AppendFn
 */

/**
 * @type AppendFn
 */
export const _append = (el, arr) => [...arr, el];

/**
 * Returns a new array containing the contents of the given array, followed by
 * the given element.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/append.js
 *
 * @type AppendFn
 * @example
 *
 *      append('tests', ['write', 'more']); //=> ['write', 'more', 'tests']
 *      append('tests', []); //=> ['tests']
 *      append(['tests'], ['write', 'more']); //=> ['write', 'more', ['tests']]
 */
const append = curry(_append);

export default append;
