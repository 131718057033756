export const getDefaultState = () => ({
  isInitializationFailed: false,
  outboundFlights: {
    departureStation: '',
    arrivalStation: '',
    isRegularSoldOut: false,
    isWDCSoldOut: false,
    firstFlight: {
      departureStation: '',
      arrivalStation: '',
      regularPrice: null,
      wdcPrice: null,
      wdc: {},
      arrivalDateTime: '',
      arrivalTimeUtcOffset: '',
      departureDateTime: '',
      departureTimeUtcOffset: '',
      duration: '',
      flightNumber: '',
      carrierCode: '',
      operatingCarrierCode: '',
    },
    secondFlight: {
      departureStation: '',
      arrivalStation: '',
      regularPrice: null,
      wdcPrice: null,
      wdc: {},
      arrivalDateTime: '',
      arrivalTimeUtcOffset: '',
      departureDateTime: '',
      departureTimeUtcOffset: '',
      duration: '',
      flightNumber: '',
      carrierCode: '',
      operatingCarrierCode: '',
    },
    totalFlight: {},
  },
  returnFlights: {
    arrivalStation: '',
    departureStation: '',
    firstFlight: {
      departureStation: '',
      arrivalStation: '',
      regularPrice: null,
      wdcPrice: null,
      wdc: {},
      isRegularSoldOut: false,
      isWDCSoldOut: false,
      arrivalDateTime: '',
      arrivalTimeUtcOffset: '',
      carrierCode: '',
      departureDateTime: '',
      departureTimeUtcOffset: '',
      duration: '',
      flightNumber: '',
      operatingCarrierCode: '',
    },
    secondFlight: {
      departureStation: '',
      arrivalStation: '',
      regularPrice: null,
      wdcPrice: null,
      wdc: {},
      isRegularSoldOut: false,
      isWDCSoldOut: false,
      arrivalDateTime: '',
      arrivalTimeUtcOffset: '',
      carrierCode: '',
      departureDateTime: '',
      departureTimeUtcOffset: '',
      duration: '',
      flightNumber: '',
      operatingCarrierCode: '',
    },
    totalFlight: {},
  },

  outboundFlightTransit: {},
  returnFlightTransit: {},
});

export const resetState = (state) => Object.assign(state, getDefaultState());
