/**
 * @type {<T extends any[], U>(fns: ((...args: T) => U)[]) => (...args: T) => U[]}
 */
// note: `converge` can be an alternative
const collect =
  (fns) =>
  (...args) =>
    fns.map((fn) => fn(...args));

export default collect;
