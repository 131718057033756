import flatten from '~/utils/fp/flatten';
// we have to collect "measure product details" calls and send them out
// in a burst if possible, so let's create a primitive queue
const MAX_TIMEOUT = 1000;
const queue = {};
let timer = null;

// item may be an array too with a batch property glued
// (airport transfers already collects stuff into an array),
// otherwise it's just a payload object
export default function (item, callback) {
  const { batch } = item;
  delete item.batch;
  if (!queue[batch]) {
    queue[batch] = [];
  }
  if (Array.isArray(item)) {
    queue[batch] = queue[batch].concat(item);
  } else {
    queue[batch].push(item);
  }
  if (timer !== null) {
    clearTimeout(timer);
    timer = null;
  }
  timer = setTimeout(() => {
    const mergedQueue = flatten(Object.values(queue));
    callback(mergedQueue);
    delete queue[batch];
  }, MAX_TIMEOUT);
}
