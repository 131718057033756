import { FLOW_TYPE_NEW_BOOKING } from '~/constants';

export const getDefaultState = () => ({
  currencies: {
    booking: null,
    departure: null,
    arrival: null,
  },
  flowType: FLOW_TYPE_NEW_BOOKING, // TODO clean up flow handling

  // notes: flipped when a passenger start rebooking
  // because of cancellation or schedule change
  isCancellationRebookFlow: false,

  /**
   * isCheckInFlow
   * @type Boolean
   * We needed this because we have to track separate
   * in the Enhanced ECommerce the Add Services and CheckIn flow,
   */
  isCheckInFlow: false,
  hasExtendedAncillary: false,

  payment: {
    currency: '',
  },

  pnr: '',
  lastName: '',
});
