import to from 'await-to-js';
import { FLOW_TYPE_CHANGE_FLIGHT, FLOW_TYPE_REBOOK } from '~/constants';
import { SS_SEAT_SELECTION_STEP } from '~/constants/session-storage';
import * as BookingService from '~/services/booking';
import prop from '~/utils/fp/prop';
import propEq from '~/utils/fp/prop-eq';
import { apiErrorsOrDefault } from '~/utils/services/errors';
import { removeSessionItem } from '~/utils/storage';
import { createAction } from '~/utils/store';
import { goToFlightChange } from '~/utils/navigation';
import { captureAndLogException, captureException } from '~/utils/logging';
import * as flightSelectActions from '../flight-select/actions';
import * as searchActions from '../search/actions';
import * as ancillaryActions from '../ancillaries/actions';
import * as searchGetters from '../search/getters';
import * as itineraryGetters from '../itinerary/getters';
import * as summaryActions from '../summary/actions';
import * as summaryGetters from '../summary/getters';
import * as bookingActions from '../booking/actions';
import * as coreBookingActions from '../core-booking/actions';
import * as getters from './getters';
import * as m from './mutation-types';

export const setCurrentStep = ({ commit, state }, stepName) => {
  const step = getters.availableSteps(state).find(propEq('name', stepName));
  if (step) commit(m.SET_CURRENT_STEP, step);
};

export const setBooking = (store, booking) => {
  coreBookingActions.setFlowType(
    store,
    booking.rebook ? FLOW_TYPE_REBOOK : FLOW_TYPE_CHANGE_FLIGHT
  );
  store.commit(m.SET_BOOKING, {
    booking,
    canDivide: itineraryGetters.canDoFlightChangeDivide(store.state),
  });
};

export const resetSelectedPassengers = createAction(m.RESET_SELECTED_PASSENGERS);

export const addSelectedPassenger = createAction(m.ADD_SELECTED_PASSENGER);

export const removeSelectedPassenger = createAction(m.REMOVE_SELECTED_PASSENGER);

export const resetCurrentStep = createAction(m.RESET_CURRENT_STEP);

export const resetErrors = (store) => {
  store.commit(m.RESET_ERRORS);
  flightSelectActions.resetErrors(store);
};

export const fetchServices = async (store) => {
  resetErrors(store);
  const { commit } = store;
  const [error, response] = await to(BookingService.getDivideFlightChangeServices());

  if (error) return commit(m.SET_INITIALIZATION_ERRORS, apiErrorsOrDefault(error));
  commit(m.SET_SERVICES, response.data);
};

export const fetchInformation = async (store) => {
  const { state, commit } = store;

  resetErrors(store);
  removeSessionItem(SS_SEAT_SELECTION_STEP);

  const [error, response] = await to(BookingService.getFlightChangeInformation());
  if (error) return commit(m.SET_INITIALIZATION_ERRORS, apiErrorsOrDefault(error));

  resetSelectedPassengers(store);
  ancillaryActions.reset(store);

  const {
    arrivalStation,
    departureStation,
    departureDateTime,
    arrivalStationCurrencyCode,
    departureStationCurrencyCode,
    farePrice: outboundFlightFarePrice,
    fareSellKey: outboundFlightFareSellKey,
    flightSellKey: outboundFlightFlightSellKey,
  } = response.data?.outboundFlight?.value ?? {};
  const {
    arrivalStation: returnArrivalStation = null,
    departureDateTime: returnDepartureDateTime = '',
    farePrice: returnFlightFarePrice = null,
    fareSellKey: returnFlightFareSellKey = null,
    flightSellKey: returnFlightFlightSellKey = null,
  } = response.data?.returnFlight?.value ?? {};
  const {
    adultCount: adult,
    infantCount: infant,
    childCount: child,
  } = response.data.passengers;
  const { accessibility: outboundAccessibility } = response.data?.outboundFlight ?? {};
  const { accessibility: returnAccessibility } = response.data?.returnFlight ?? {};

  // notes: since the flight-select fed from the search store, we must sync it with our stuff
  searchActions.setArrivalStation(store, {
    iata: arrivalStation,
    accessibility: returnAccessibility,
  });
  searchActions.setDepartureStation(store, {
    iata: departureStation,
    accessibility: outboundAccessibility,
  });
  searchActions.setDepartureDate(store, departureDateTime);
  searchActions.setOriginalDepartureDate(store, departureDateTime);
  searchActions.setReturnDate(store, returnDepartureDateTime);
  searchActions.setPassengers(store, { infant, adult, child });

  bookingActions.setPreviousBookingDetails(store, {
    isRoundTrip: Boolean(returnArrivalStation),
    outboundFlightFarePrice,
    returnFlightFarePrice,
    outboundFlightFareSellKey,
    returnFlightFareSellKey,
    outboundFlightFlightSellKey,
    returnFlightFlightSellKey,
    departureStation: searchGetters.departureStationIata(state),
    arrivalStation: searchGetters.arrivalStationIata(state),
    departureDate: searchGetters.formattedDepartureDate(state),
    returnDate: searchGetters.formattedReturnDate(state),
    outboundBundle: summaryGetters.pastPurchases(state).outbound.bundle,
    returnBundle: summaryGetters.pastPurchases(state).return.bundle,
  });

  coreBookingActions.setCurrencyCodes(store, {
    bookingCurrencyCode: departureStationCurrencyCode,
    departureCurrencyCode: departureStationCurrencyCode,
    arrivalCurrencyCode: arrivalStationCurrencyCode,
  });

  setBooking(store, {
    ...(response.data || {}),
    isGroupBooking: itineraryGetters.isGroupBooking(store.state),
  });
};

export const startDivideFlightChangeFlow = async (store) => {
  store.commit(m.SET_PASSENGER_ERRORS, []);
  const passengers = getters.selectedPassengers(store.state).map(prop('passengerNumber'));
  const [error] = await to(BookingService.startDivideFlightChange({ passengers }));

  if (error) return store.commit(m.SET_PASSENGER_ERRORS, apiErrorsOrDefault(error));
};

export const startFlightChangeFlow = async (store) => {
  const [error] = await to(_startFlightChangeFlow(store));
  if (error) {
    captureAndLogException('Could not start flight change.', error);
  }
};

const _startFlightChangeFlow = async (store) => {
  await summaryActions.reset(store);
  await summaryActions.fetchPastPurchases(store);
  const response = await BookingService.startFlightChange();

  setBooking(store, {
    ...(response.data || {}),
    isGroupBooking: itineraryGetters.isGroupBooking(store.state),
  });

  const {
    adultCount: adult,
    infantCount: infant,
    childCount: child,
  } = response.data.passengers;
  searchActions.setPassengers(store, { infant, adult, child });
};

export const continueFlightChangeFlow = (store, isReplaceState = true) => {
  goToFlightChange(isReplaceState);
};

export const initializePaymentStep = async (store) => {
  const { commit } = store;

  const [summaryError] = await to(ancillaryActions.getSummaryOnly(store));
  const [contactError] = await to(bookingActions.getContact(store));

  if (summaryError) {
    captureException(summaryError);
    commit(m.SET_SUMMARY_ERRORS, apiErrorsOrDefault(summaryError));
  }
  if (contactError) {
    captureException(contactError);
    commit(m.SET_CONTACT_ERRORS, apiErrorsOrDefault(contactError));
  }
};
