import { _slice } from '../slice';

/**
 * Returns all but the first element of the given array.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/tail.js
 *
 * @type {<T>(arr: T[]) => T[]}
 * @example
 *
 *      tail([1, 2, 3]);  //=> [2, 3]
 *      tail([1, 2]);     //=> [2]
 *      tail([1]);        //=> []
 *      tail([]);         //=> []
 */
const tail = (arr) => _slice(1, Number.POSITIVE_INFINITY, arr);

export default tail;
