import hassocPath from '~/utils/object/hassoc-path';
import { mutationAssocPath } from '~/utils/store';
import * as m from './mutation-types';
import { overrideFeatureValues } from './internal';

/**
 * @type {(state: State, value: Object<string, any>[]) => void}
 */
const setFeatures = (state, value) => {
  // note: unfortunately we can't use a getter because overriding relies on
  //  local storage which is not reactive in itself and the getter won't be
  //  recalculated on local storage change
  state.originalFeatures = value;
  state.features = overrideFeatureValues(state.originalFeatures);
};

const incrementInitConfigCatCounter = (state) => {
  state.initConfigCatCounter++;
};

const decrementInitConfigCatCounter = (state) => {
  state.initConfigCatCounter--;
};

export default {
  [m.SET_TEST_ID]: mutationAssocPath('testId'),
  [m.RESET_TEST_ID]: hassocPath('testId', null),
  [m.SET_FEATURES]: setFeatures,
  [m.SET_FETCH_FEATURE_STATE]: mutationAssocPath('fetchFeaturesState'),
  [m.SET_CONFIG_CAT_SNAPSHOT]: mutationAssocPath('configCatSnapshot'),
  [m.INCREMENT_INIT_CONFIG_CAT_COUNTER]: incrementInitConfigCatCounter,
  [m.DECREMENT_INIT_CONFIG_CAT_COUNTER]: decrementInitConfigCatCounter,
};
