import { mutationAssocPath } from '~/utils/store';
import * as m from './mutation-types';

export default {
  [m.SET_EXTRA_INFORMATION]: mutationAssocPath('extraInformation'),

  [m.RESET_EXTRA_INFORMATION](state) {
    state.extraInformation = [];
  },
};
