import {
  CHECK_IN_STEP_CHANGES_TO_ACCEPT_NAME,
  CHECK_IN_STEP_PAYMENT_NAME,
} from '~/router/check-in/constants';
import { flightPropByDirection, seatPropByDirection } from '~/utils/booking/flight';
import allPass from '~/utils/fp/all-pass';
import always from '~/utils/fp/always';
import complement from '~/utils/fp/complement';
import compose from '~/utils/fp/compose';
import filter from '~/utils/fp/filter';
import gt from '~/utils/fp/gt';
import ifElse from '~/utils/fp/if-else';
import lt from '~/utils/fp/lt';
import map from '~/utils/fp/map';
import omit from '~/utils/fp/omit';
import pick from '~/utils/fp/pick';
import propEq from '~/utils/fp/prop-eq';
import propEqFalse from '~/utils/fp/prop-eq-false';
import propIsNotEmpty from '~/utils/fp/prop-is-not-empty';
import reverse from '~/utils/fp/reverse';
import isNil from '~/utils/object/is-nil';
import isNotEmpty from '~/utils/object/is-not-empty';

export const SERVICES_DIRECTION_GLOBAL = 'globals';

export const getDefaultState = () => ({
  recentlyPurchasedItems: {
    /**
     *  @type {Array}
     */
    ancillaries: [],

    /**
     *  @type {Array}
     */
    seats: [],

    /**
     *  @type {Object}
     */
    services: {},
  },
  isEmptyPaymentOccurred: false,
  availableOperations: {
    canListInvoices: false,
    canShowPriceDetails: false,
    canChangeReturnSeat: true,
    canChangeOutboundSeat: true,
  },
  hoursUntilCheckInAvailable: {},
  isCheckInAvailable: null,
  recentlyPaidAmount: null,
  isAutoCheckIn: null,
  currentStep: null,
  stepsData: {
    warning: {
      labels: [],
    },
    passengers: {
      isSeatSelectionBlockedBySeatingTogether: false,
      availablePassengers: [],
      selectedPassengers: [],
      isStepValid: false,
      errors: [],
    },
    changesToAccept: {
      errors: [],
      changes: [],
    },
    payment: {
      isAlreadyPayed: false,
      isPaymentFailed: false,
    },
    travelDocuments: {
      errors: [],
      passengers: [],
      biometricNationalities: [],
      visaOrResidencePermitRequiredNationalities: [],
      travelDocumentRules: [],
      nationalId: [],
      passport: [],
      validationMessages: [],
    },
    contactData: {
      passengers: [],
      errors: [],
      passengerErrors: [],
    },
    prohibitedItems: {
      errors: [],
    },
    allDone: {
      passengers: [],
      errors: [],
      bookingComCreditBackPercentage: 0,
      carRentalCreditBackPercentage: 0,
      isCheckInForReturnButtonAvailable: false,
      isOnlineCheckInDisabledForReturnLeg: false,
      checkinStartDiffReturnFlight: null,
      availableFeedbackStages: [],
    },
  },
  direction: null,
  lastName: null,
  firstName: null,
  errors: [],
  flight: {},
  steps: [],
  pnr: null,
});

export const isCheckInPaymentStep = (name) => name === CHECK_IN_STEP_PAYMENT_NAME;

export const isChangesToAcceptStep = (name) =>
  name === CHECK_IN_STEP_CHANGES_TO_ACCEPT_NAME;

export const isNotCheckInPaymentStep = complement(isCheckInPaymentStep);

export const findStepBeforeOrderNumber = (steps, orderNumber) =>
  reverse(steps).find((step) => lt(step.order, orderNumber)) || null;

export const findStepAfterOrderNumber = (steps, orderNumber) =>
  steps.find((step) => gt(step.order, orderNumber)) || null;

export const getValidationPayload = compose(
  map(
    pick([
      'passengerNumber',
      'mobilePhoneNumber',
      'email',
      'contactDataNotProvided',
      'saveMobilePhoneToProfile',
    ])
  ),
  filter(
    allPass(
      ifElse(propEqFalse('isBookingOwner'), propIsNotEmpty('email'), always(true)),
      propIsNotEmpty('mobilePhoneNumber')
    )
  )
);

export const getContactDataPostPayload = (passengers) =>
  passengers.map(
    pick([
      'passengerNumber',
      'mobilePhoneNumber',
      'contactDataNotProvided',
      'email',
      'saveMobilePhoneToProfile',
      'dateOfBirth',
    ])
  );

export const getTravelDocumentsPostData = (passengers, visaOrResidenceNationalities) =>
  passengers.map((passenger) => {
    const passengerInt = { ...passenger };

    if (passenger.visaOrResidenceSelect === 'residence') {
      passengerInt.travelDocuments = omit(
        ['visaIssuingCountry', 'visaNumber', 'visaExpirationDate'],
        passengerInt.travelDocuments
      );
    }

    if (passenger.visaOrResidenceSelect === 'visa') {
      passengerInt.travelDocuments = omit(
        [
          'residencePermitDoesNotExpire',
          'residencePermitExpirationDate',
          'residencePermitIssuingCountry',
          'residencePermitNumber',
        ],
        passengerInt.travelDocuments
      );
    }

    if (passenger.travelDocuments.docDoesNotExpire) {
      passengerInt.travelDocuments.docExpirationDate = null;
    }
    return passengerInt;
  });

export const PASSENGER_MATCHER_REGEX = /-(i?\d)$/;
export const INFANT_MATCHER_REGEX = /-i(\d)$/;

export const createTravelDocumentErrors = (acc, error) => {
  const replacerRegex = /-(i|\d|i\d)$/;

  const [, passengerNumber] = error.match(PASSENGER_MATCHER_REGEX) || [];
  const [, adultPassengerNumber] = error.match(INFANT_MATCHER_REGEX) || [];
  const existingPassenger = acc.find(propEq('passengerNumber', passengerNumber));

  if (!passengerNumber) return acc;

  if (!existingPassenger) {
    acc.push({
      errors: [error.replace(replacerRegex, '')],
      adultPassengerNumber: adultPassengerNumber || null,
      passengerNumber: passengerNumber || null,
    });
  } else existingPassenger.errors.push(error.replace(replacerRegex, ''));

  return acc;
};

export const getRecentlyPurchasedPassengerAncillaries = (
  ancillaries,
  passengerNumber,
  direction
) => {
  const targetPassenger = ancillaries.find(propEq('passengerId', passengerNumber));
  if (isNil(targetPassenger)) return [];
  return (targetPassenger[flightPropByDirection(direction)] || []).map((ancillary) => ({
    ...pick(['displayCount', 'icon', 'label'], ancillary),
  }));
};

export const getRecentlyPurchasedSeats = (seats, passengerNumber, direction) => {
  const targetPassenger = seats.find(propEq('id', passengerNumber));
  const seat = targetPassenger?.[seatPropByDirection(direction)];
  return isNotEmpty(seat)
    ? [{ ...pick(['icon'], seat), label: seat.code, displayCount: 0 }]
    : [];
};

export const getRecentlyPurchasedServices = (
  services,
  direction,
  numberOfIndependentPassengers
) =>
  (services?.[direction] || []).map((service) => {
    const { displayCount, icon, label } = service;

    return {
      displayCount: determineDisplayCount(
        direction,
        displayCount,
        numberOfIndependentPassengers
      ),
      icon,
      label,
    };
  });

const determineDisplayCount = (direction, displayCount, numberOfPassengers) => {
  if (direction === SERVICES_DIRECTION_GLOBAL) return null;
  return displayCount / numberOfPassengers;
};
