import curry from '../curry';
import includedIn from '../included-in';
import { _reject } from '../reject';

/**
 * @typedef {<T>(arr1: T[], arr2: T[]) => T[]} WithoutFn
 */

/**
 * @type WithoutFn
 */
export const _without = (arr1, arr2) => _reject(includedIn(arr1), arr2);

/**
 * Returns a new list without values in the first argument.
 * [`equals`](#equals) is used to determine equality.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/without.js
 *
 * @type WithoutFn
 * @example
 *
 *      without([1, 2], [1, 2, 1, 3, 4]); //=> [3, 4]
 */
const without = curry(_without);

export default without;
