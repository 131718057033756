import alter from '~/utils/fp/alter';
import { formatCurrency } from '~/utils/currency';
import { getDefaultState } from './internal';
import * as m from './mutation-types';

const promoFormatting = (currency, type, value) => {
  switch (type) {
    case 'percentage':
      return `${value}%`;
    case 'fixedAmount':
      return formatCurrency(value, currency);
    case 'fixedPrice':
      return formatCurrency(value, currency);
    default:
      return '';
  }
};

const convertPromotionData = (data) => {
  if (!data) return {};
  return data
    .flatMap((item) => {
      const { integrationId, promoCurrency, promoType, promoValue } = item;
      return item.placements.map(
        alter({ integrationId, promoCurrency, promoType, promoValue })
      );
    })
    .reduce((acc, value) => {
      if (
        value.placementName.includes('eWizz') ||
        value.placementName.includes('ewizz')
      ) {
        acc[value.placementName] = acc[value.placementName] || [];
        acc[value.placementName].push({
          integrationId: value.integrationId,
          disclaimerLabelKey: value.disclaimerLabelKey,
          labelKey: value.labelKey,
          promoType: value.promoType,
          formatting: promoFormatting(
            value.promoCurrency,
            value.promoType,
            value.promoValue
          ),
        });
      }
      return acc;
    }, {});
};

const reset = (state) => Object.assign(state, getDefaultState());

export default {
  [m.RESET]: reset,

  [m.SET_ANCILLARY_PROMO_DETAILS](state, data) {
    state.outboundPromotions = convertPromotionData(data.outboundPromotions);
    state.returnPromotions = convertPromotionData(data.returnPromotions);
  },

  [m.ADD_PROMOTION_TO_TRACK](state, data) {
    if (state.promotionsToTrack.includes(data)) return;
    state.promotionsToTrack.push(data);
  },
};
