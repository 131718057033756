export const getDefaultState = () => {
  return {
    stations: [],
    countries: [],
    serviceFees: [],
    disruptionCreditInformation: {
      errors: null,
      creditBackAgencyBonusPercent: 0,
      creditBackBonusPercent: 0,
    },
  };
};
