import EventEmitter from 'events';
import axios from 'axios';
import to from 'await-to-js';
import partial from './fp/partial';
import identity from './fp/identity';
import deepClone from './object/deep-clone';
import isNotFunction from './object/is-not-function';

let cache = {};
let pending = {};
let emitters = {};

const MAX_TIME = 25000; // max xhr wait

/**
 * http mem cache wrapper for vue resource, errors are not cached
 *
 * @param method        Vue.http[method], eg. 'post'
 * @param api           api url, eg. 'booking/summary'
 * @param data          parameters
 * @param modifier      on success data processor (warning: vs header)
 * @returns {Promise}
 */
const httpCache = (method, api, data, modifier) => {
  if (isNotFunction(modifier)) {
    modifier = identity;
  }

  const dataId = `${api}-${method}-${JSON.stringify(data) || 'undefined'}`;

  // todo: refactor one day so eslint comment can be removed
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    // if we have it in the cache, return it and bail out \o/
    if (cache[dataId]) return resolve(retrieve(dataId));

    pending[dataId] = (pending[dataId] || 0) + 1;
    emitters[dataId] = emitters[dataId] || new EventEmitter();

    // subscribe to the done events of this xhr call
    const failTimeout = setTimeout(() => reject(new Error('timed out')), MAX_TIME);

    emitters[dataId].once('done', (error, data) => {
      clearTimeout(failTimeout);
      pending[dataId] -= 1;

      if (error) {
        reject(error);
      } else {
        resolve({ data });
      }
    });

    if (pending[dataId] !== 1) return;

    const isPostMethod = method.toLowerCase() === 'post';
    const requestPayload = isPostMethod ? data : { params: data };

    // first and only (real) call, will fire a done event on the call's channel
    const [error, response] = await to(axios[method](api, requestPayload));
    if (error) {
      emitters[dataId].emit('done', error);
      return;
    }

    cache[dataId] = modifier(response.data);
    emitters[dataId].emit('done', null, getDataClone(dataId));
  });
};

const retrieve = (dataId) => ({ data: getDataClone(dataId) });

// we tend to modify the responses here and there, but with a shared resultset
//  that would be catastrophic
const getDataClone = (dataId) => deepClone(cache[dataId]);

export const http = {
  __reset: () => {
    cache = {};
    pending = {};
    emitters = {};
  }, // for test only
  post: partial(httpCache, 'post'),
  get: partial(httpCache, 'get'),
};
