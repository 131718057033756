import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => boolean} LtFn
 */

/**
 * @type LtFn
 */
export const _lt = (a, b) => a < b;

/**
 * Returns `true` if the first argument is less than the second; `false`
 * otherwise.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/lt.js
 *
 * @type LtFn
 * @example
 *
 *      lt(2, 1); //=> false
 *      lt(2, 2); //=> false
 *      lt(2, 3); //=> true
 */
const lt = curry(_lt);

export default lt;
