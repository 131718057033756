import log from 'loglevel';
import isNotUndefined from '../object/is-not-undefined';
import isObject from '../object/is-object';

/**
 * based on: https://github.com/kazupon/vue-i18n/blob/v8.22.1/src/format.js
 */
export class LabelFormatter {
  constructor() {
    this._caches = Object.create(null);
  }

  interpolate(message, values) {
    // we use double curlies in most of the templates, rewriting everything
    // to triple (no html escaping) is an overkill, so let's convert simple
    // html labels to plain text labels here if possible
    if (/&\w{2,4};/.test(message) && !message.includes('<')) {
      message = message.replaceAll('&amp;', '&').replaceAll('&nbsp;', ' ');
    }

    // adding empty labels is not possible, so we convert '-' to empty \o/
    if (message === '-') return [''];

    if (!values) return [message];

    let tokens = this._caches[message];
    if (!tokens) {
      tokens = parse(message);
      this._caches[message] = tokens;
    }

    return compile(tokens, values);
  }
}

const RE_TOKEN_LIST_VALUE = /^\d+/;
const RE_TOKEN_NAMED_VALUE = /^\w+/;

const parse = (format) => {
  const tokens = [];
  let position = 0;

  let text = '';
  while (position < format.length) {
    let char = format[position++];
    if (char === '{') {
      if (text) {
        tokens.push({ type: 'text', value: text });
      }

      text = '';
      let sub = '';
      char = format[position++];
      while (isNotUndefined(char) && char !== '}') {
        sub += char;
        char = format[position++];
      }
      const isClosed = char === '}';

      const type = RE_TOKEN_LIST_VALUE.test(sub)
        ? 'list'
        : isClosed && RE_TOKEN_NAMED_VALUE.test(sub)
        ? 'named'
        : 'unknown';
      tokens.push({ value: sub, type });
    } else if (char === '%') {
      // when found rails i18n syntax, skip text capture
      if (format[position] !== '{') {
        text += char;
      }
    } else {
      text += char;
    }
  }

  if (text) tokens.push({ type: 'text', value: text });
  return tokens;
};

const compile = (tokens, values) => {
  const compiled = [];
  let index = 0;

  const mode = Array.isArray(values) ? 'list' : isObject(values) ? 'named' : 'unknown';
  if (mode === 'unknown') return compiled;

  while (index < tokens.length) {
    const token = tokens[index];
    switch (token.type) {
      case 'text':
        compiled.push(token.value);
        break;
      case 'list':
        compiled.push(values[Number.parseInt(token.value, 10)]);
        break;
      case 'named':
        if (mode === 'named') {
          compiled.push(values[token.value]);
        } else if ($ENV !== 'production') {
          log.warn(
            `Type of token '${token.type}' and format of value '${mode}' don't match!`
          );
        }
        break;
      case 'unknown':
        if ($ENV !== 'production') {
          log.warn(`Detect 'unknown' type of token!`);
        }
        break;
      // no default
    }
    index++;
  }

  return compiled;
};
