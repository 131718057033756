import {
  STEP_NOTHING,
  STEP_SELECT_FLIGHT,
  STEP_PASSENGERS,
  STEP_SERVICES,
  STEP_PAYMENT,
  FLOODLIGHT_VARIABLES_KEY_MAP,
} from '~/constants';
import { ANALYTICS_CHECKOUT_STEP_ITINERARY } from '~/constants/analytics';

import { gtag } from '../analytics/gtag';
import { getFloodlightId } from '../analytics/get-floodlight-id';

const CAT_TO_STEP_MAP = new Map();
CAT_TO_STEP_MAP.set(STEP_NOTHING, 'searc0');
CAT_TO_STEP_MAP.set(STEP_SELECT_FLIGHT, 'selec0');
CAT_TO_STEP_MAP.set(STEP_PASSENGERS, 'passe0');
CAT_TO_STEP_MAP.set(STEP_SERVICES, 'servi0');
CAT_TO_STEP_MAP.set(STEP_PAYMENT, 'payme0');
CAT_TO_STEP_MAP.set(ANALYTICS_CHECKOUT_STEP_ITINERARY, 'itine0');

export const trackFloodlightEvent = (options = {}) => {
  const {
    params = {},
    sendTo,
    eventType = 'conversion',
    isPageRequest = false,
  } = options;
  if (!getFloodlightId() || !sendTo) return;
  const _params = isPageRequest
    ? getPageEventTrackingParams(params, sendTo)
    : getEventTrackingParams(params, sendTo);
  gtag('event', eventType, _params);
};

const getPageEventTrackingParams = (params, sendTo) => {
  const _params = getDefaultFloodlightTrackingParams(sendTo);
  Object.entries(FLOODLIGHT_VARIABLES_KEY_MAP).forEach(
    ([customKey, floodlightVariableKey]) => {
      // if the key is the page url we need to keep that
      const floodlightValue = customKey === 'pageUrl' ? params[customKey] || '' : '';
      _params[floodlightVariableKey] = floodlightValue;
    }
  );
  return _params;
};

const getEventTrackingParams = (params, sendTo) => {
  const _params = getDefaultFloodlightTrackingParams(sendTo);
  Object.entries(params).forEach(([customKey, trackingValue]) => {
    _params[FLOODLIGHT_VARIABLES_KEY_MAP[customKey] || customKey] = trackingValue;
  });
  return _params;
};

const getDefaultFloodlightTrackingParams = (sendTo) => {
  return {
    allow_custom_scripts: true,
    send_to: `${getFloodlightId()}/${sendTo}`,
  };
};

export const trackFloodlightActivity = (nextStep) => {
  const cat = CAT_TO_STEP_MAP.get(nextStep);
  if (!cat || document.querySelector(`#DCLK_FLDiv${nextStep}`) !== null) {
    return false;
  }

  const axel = String(Math.random());
  const a = axel * 10000000000000;

  const floodlightEl = document.createElement('div');
  floodlightEl.setAttribute('id', `DCLK_FLDiv${nextStep}`);
  floodlightEl.style.position = 'absolute';
  floodlightEl.style.top = '0';
  floodlightEl.style.left = '0';
  floodlightEl.style.width = '1px';
  floodlightEl.style.height = '1px';
  floodlightEl.style.display = 'none';
  document.body.append(floodlightEl);

  const src = `https://5308433.fls.doubleclick.net/activityi
    ;src=5308433
    ;type=count0
    ;cat=${cat}
    ;dc_lat=
    ;dc_rdid=
    ;tag_for_child_directed_treatment=
    ;ord=${a}
    ?`.replace(/\s/g, '');

  floodlightEl.innerHTML = `<iframe
    id="DCLK_FLIframe${nextStep}"
    src="${src}"
    width="1"
    height="1"
    frameborder="0"
    style="display:none"></iframe>`;
};
