import isNotEmpty from '~/utils/object/is-not-empty';
import __ from './fp/__';
import curry from './fp/curry';
import length from './fp/length';
import gt from './fp/gt';
import compact from './fp/compact';
import whereEq from './fp/where-eq';
import pluck from './fp/pluck';
import filter from './fp/filter';
import compose from './fp/compose';
import sortBy from './fp/sort-by';
import map from './fp/map';
import prop from './fp/prop';
import toLowerCase from './fp/to-lower-case';
import isNotUndefined from './object/is-not-undefined';
import propEq from './fp/prop-eq';
import propEqFalse from './fp/prop-eq-false';
import ifElse from './fp/if-else';
import isEmpty from './object/is-empty';
import always from './fp/always';
import assoc from './fp/assoc';

// `getCountryName(countries, 'CG')` --> 'Congo'
export const getCountryName = (countries, countryCode) =>
  findCountryByCountryCode(countries, countryCode)?.name ?? countryCode;

// `findCountryByCountryCode(countries, 'HU')` --> {}
export const findCountryByCountryCode = (countries, countryCode) =>
  countries.find(
    (country) => country.code.toLowerCase() === String(countryCode).toLowerCase()
  ) ?? null;

// `hasCountryByCountryCode(countries, 'HU')` --> Boolean
export const hasCountryByCountryCode = (countries, countryCode) =>
  isNotEmpty(countryCode) && Boolean(findCountryByCountryCode(countries, countryCode));

// `getIATA('Brussels Charleroi')` --> 'CRL'
export function getIATA(stations, shortName, strict = false) {
  return (
    (stations.find((station) => station.shortName === shortName) || {}).iata ||
    (strict ? undefined : shortName)
  );
}

// `getShortName(stations, 'CRL')` --> 'Brussels Charleroi'
export function getShortName(stations, iata, strict) {
  const defaultValue = strict ? null : iata;
  return findStationByIata(iata, stations)?.shortName ?? defaultValue;
}

// in case of multi airport search. eg: LON (LONDON ALL)
export const isFakeStation = (stations, iata) => {
  return findStationByIata(iata, stations)?.isFakeStation ?? false;
};

export const isSchengenCountry = (countries, countryCode) => {
  const country = countries.find(propEq('code', countryCode));
  return country?.isSchengen ?? false;
};

export const findStationByNamePrefix = (prefix, stations) => {
  let result = null;

  if (prefix && prefix.length > 0) {
    result = stations.find((station) =>
      station.shortName.toLowerCase().startsWith(prefix.toLowerCase())
    );
  }

  return isNotUndefined(result) ? result : null;
};

/**
 * @type {(iata: string, stations: {}[]) => {}}
 */
export const findStationByIata = curry((iata, stations) =>
  stations.find((station) => station.iata.toLowerCase() === String(iata).toLowerCase())
);

// `hasStationByIata('BUD', stations)` --> Boolean
export const hasStationByIata = (iata, stations) =>
  Boolean(findStationByIata(iata, stations));

export const findStationByNameOrReturnFirst = (name, stations) => {
  const station = findStationByName(name, stations);
  return station || stations[0] || null;
};

export const findStationByName = (name, stations) => {
  let result = null;

  if (name && name.length > 0) {
    result = stations.find(
      (station) => station.shortName.toLowerCase() === name.toLowerCase()
    );
  }

  return isNotUndefined(result) ? result : null;
};

export const findStationsWithConnectionToCountry = (countryCode, stations) =>
  filter(
    compose(
      gt(__, 0),
      length,
      filter(
        (station) =>
          station.countryCode.toLowerCase() === String(countryCode).toLowerCase()
      ),
      getStationConnections(__, stations)
    )
  )(stations);

/**
 * @type {(stations: Object<string, any>[], iata: string) => string}
 */
export const getCountryCodeByIata = (stations, iata) => {
  return findStationByIata(iata, stations)?.countryCode ?? null;
};

/**
 * @type {(stations: Object<string, any>[], countryCode: string) => Object<string, any>[]}
 */
export const getCountryStations = curry((stations, countryCode) =>
  filter(
    (station) => station.countryCode.toLowerCase() === String(countryCode).toLowerCase()
  )(stations)
);

/**
 * @type {(stations: Object<string, any>[], countryCode: string) => Object<string, any>[]}
 */
export const getCountryStationsOrderedByIata = (stations, countryCode) =>
  compose(
    sortBy(compose(toLowerCase, prop('iata'))),
    getCountryStations(stations)
  )(countryCode);

export const findStationConnectionsByDomesticAndIata = (domestic, iata, stations) => {
  const connectionIatas = getConnectionsByIata(iata, stations)
    .filter((conn) => conn.isDomestic === domestic)
    .map((conn) => conn.iata);
  return stations.filter((station) => connectionIatas.includes(station.iata));
};

/**
 * @type {(station: Station) => Connection[]}
 */
const getDirectConnections = compose(
  filter(propEqFalse('isConnected')),
  prop('connections')
);

/**
 * @type {(station: Station) => Connection[]}
 */
const getAllConnections = prop('connections');

/**
 * @type {(iata: string, stations: Station[]) => Connection[]}
 */
export const getDirectConnectionsByIata = compose(
  ifElse(isEmpty, always([]), getDirectConnections),
  findStationByIata
);

/**
 * @type {(iata: string, stations: Station[]) => Connection[]}
 */
export const getAllConnectionsByIata = compose(
  ifElse(isEmpty, always([]), getAllConnections),
  findStationByIata
);

const findStationConnectionsByIata = (getConnectionsByIata) => (iata, stations) => {
  const connections = getConnectionsByIata(iata, stations);
  const connectionIatas = pluck('iata', connections);
  return stations
    .filter((station) => connectionIatas.includes(station.iata))
    .map((station) =>
      assoc(
        'isConnected',
        connections.some(
          (connection) => connection.iata === station.iata && connection.isConnected
        ),
        station
      )
    );
};

/**
 * @type {(iata: string, stations: Station[]) => Station[]}
 */
export const findDirectStationConnectionsByIata = findStationConnectionsByIata(
  getDirectConnectionsByIata
);

/**
 * @type {(iata: string, stations: Station[]) => Station[]}
 */
export const findAllStationConnectionsByIata = findStationConnectionsByIata(
  getAllConnectionsByIata
);

export const hasConnectionsWithDomestic = curry(
  (domestic, station) => getConnectionsByDomestic(domestic, station).length > 0
);

export const hasAnySpecificConnections = curry((iatas, station) => {
  if (!iatas || iatas.length === 0) {
    return true;
  }

  return station.connections.some((conn) => iatas.includes(conn.iata));
});

export const getFlightTitle = (stations, departureStationIata, arrivalStationIata) => {
  const departureStation = getShortName(stations, departureStationIata);
  const arrivalStation = getShortName(stations, arrivalStationIata);
  return departureStation && arrivalStation
    ? `${departureStation} – ${arrivalStation}`
    : '';
};

/**
 * @type {(fromIata: string, toIata: string, stations: {}[]) => {}}
 */
export const getConnection = (fromIata, toIata, stations) => {
  const fromStation = findStationByIata(fromIata, stations);
  const connection = fromStation
    ? fromStation.connections.find(whereEq({ iata: toIata }))
    : null;
  return connection || null;
};

export const hasConnection = (fromIata, toIata, stations) => {
  return Boolean(getConnection(fromIata.toUpperCase(), toIata.toUpperCase(), stations));
};

export const getConnectionsByIata = (iata, stations, emitConnectedFlights = true) => {
  const station = findStationByIata(iata, stations);
  if (!station) return [];

  return emitConnectedFlights
    ? station.connections.filter((connection) => !connection.isConnected)
    : station.connections;
};

export const getConnectionsByDomestic = (domestic, station) =>
  station.connections.filter((conn) => conn.isDomestic === domestic);

export const getStationConnections = curry((fromStation, stations) =>
  compose(
    compact,
    map(findStationByIata(__, stations)),
    pluck('iata'),
    prop('connections')
  )(fromStation)
);
