import { isSSRBuild } from '~/utils/ssr/build-type';

export const CHECK_IN_URL = `${isSSRBuild ? '' : '/'}check-in`;
export const CHECK_IN_PATH = `${CHECK_IN_URL}/:pnr([a-zA-Z0-9]{6})/:lastName/:direction(outbound|return)/:step?`;
export const CHECK_IN_NAME = 'check-in-flow';
export const CHECK_IN_TYPE_AUTO = 'auto';
export const CHECK_IN_STEP_FIRST_NAME_CONFIRM_PATH = 'confirm-first-name';
export const CHECK_IN_STEP_FIRST_NAME_CONFIRM_NAME = 'check-in-confirm-first-name';
export const CHECK_IN_STEP_ERROR_PATH = 'error';
export const CHECK_IN_STEP_ERROR_NAME = 'check-in-error';
export const CHECK_IN_STEP_WARNING_PATH = 'warning';
export const CHECK_IN_STEP_WARNING_NAME = 'check-in-warning';
export const CHECK_IN_STEP_PASSENGERS_PATH = 'passengers';
export const CHECK_IN_STEP_PASSENGERS_NAME = 'check-in-passengers';

export const CHECK_IN_STEP_CHANGES_TO_ACCEPT_PATH = 'changes-to-accept';
export const CHECK_IN_STEP_CHANGES_TO_ACCEPT_NAME = 'check-in-changes-to-accept';

export const CHECK_IN_STEP_SEAT_SELECTION_PATH = 'seat-selection';
export const CHECK_IN_STEP_SEAT_SELECTION_NAME = 'check-in-seat-selection';
export const CHECK_IN_STEP_SERVICES_PATH = 'services';
export const CHECK_IN_STEP_SERVICES_NAME = 'check-in-services';
export const CHECK_IN_STEP_PAYMENT_PATH = 'payment';
export const CHECK_IN_STEP_PAYMENT_NAME = 'check-in-payment';
export const CHECK_IN_STEP_BAGGAGE_PATH = 'baggage';
export const CHECK_IN_STEP_BAGGAGE_NAME = 'check-in-baggage';
export const CHECK_IN_STEP_PAYMENT_SUCCESS_PATH = 'success';
export const CHECK_IN_STEP_PAYMENT_SUCCESS_NAME = 'check-in-payment-success';
export const CHECK_IN_STEP_PAYMENT_FAILED_PATH = 'failed';
export const CHECK_IN_STEP_PAYMENT_FAILED_NAME = 'check-in-payment-failed';
export const CHECK_IN_STEP_TRAVEL_DOCUMENTS_PATH = 'travel-documents';
export const CHECK_IN_STEP_TRAVEL_DOCUMENTS_NAME = 'check-in-travel-documents';
export const CHECK_IN_STEP_QR_SCANNING_PATH = 'qr-scanning';
export const CHECK_IN_STEP_QR_SCANNING_NAME = 'check-in-qr-scanning';
export const CHECK_IN_STEP_CONTACT_DATA_PATH = 'contact-data';
export const CHECK_IN_STEP_CONTACT_DATA_NAME = 'check-in-contact-data';
export const CHECK_IN_STEP_CONFIRM_DATA_PATH = 'confirm-data';
export const CHECK_IN_STEP_CONFIRM_DATA_NAME = 'check-in-confirm-data';
export const CHECK_IN_STEP_PROHIBITED_ITEMS_PATH = 'prohibited-items';
export const CHECK_IN_STEP_PROHIBITED_ITEMS_NAME = 'check-in-prohibited-items';
export const CHECK_IN_STEP_ALL_DONE_PATH = 'all-done';
export const CHECK_IN_STEP_ALL_DONE_NAME = 'check-in-all-done';

export const CHECK_IN_ROUTES = new Map([
  [CHECK_IN_STEP_FIRST_NAME_CONFIRM_PATH, CHECK_IN_STEP_FIRST_NAME_CONFIRM_NAME],
  [CHECK_IN_STEP_ERROR_PATH, CHECK_IN_STEP_ERROR_NAME],
  [CHECK_IN_STEP_WARNING_PATH, CHECK_IN_STEP_WARNING_NAME],
  [CHECK_IN_STEP_PASSENGERS_PATH, CHECK_IN_STEP_PASSENGERS_NAME],
  [CHECK_IN_STEP_CHANGES_TO_ACCEPT_PATH, CHECK_IN_STEP_CHANGES_TO_ACCEPT_NAME],
  [CHECK_IN_STEP_SEAT_SELECTION_PATH, CHECK_IN_STEP_SEAT_SELECTION_NAME],
  [CHECK_IN_STEP_BAGGAGE_PATH, CHECK_IN_STEP_BAGGAGE_NAME],
  [CHECK_IN_STEP_SERVICES_PATH, CHECK_IN_STEP_SERVICES_NAME],
  [CHECK_IN_STEP_PAYMENT_PATH, CHECK_IN_STEP_PAYMENT_NAME],
  [CHECK_IN_STEP_TRAVEL_DOCUMENTS_PATH, CHECK_IN_STEP_TRAVEL_DOCUMENTS_NAME],
  [CHECK_IN_STEP_QR_SCANNING_PATH, CHECK_IN_STEP_QR_SCANNING_NAME],
  [CHECK_IN_STEP_CONTACT_DATA_PATH, CHECK_IN_STEP_CONTACT_DATA_NAME],
  [CHECK_IN_STEP_CONFIRM_DATA_PATH, CHECK_IN_STEP_CONFIRM_DATA_NAME],
  [CHECK_IN_STEP_PROHIBITED_ITEMS_PATH, CHECK_IN_STEP_PROHIBITED_ITEMS_NAME],
  [CHECK_IN_STEP_ALL_DONE_PATH, CHECK_IN_STEP_ALL_DONE_NAME],
]);
