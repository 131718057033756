import mutations from './mutations';
import { getDefaultState } from './internal';

// volatile data is not synchronized into session storage,
// this is a superglobal in memory storage, use it sparingly,
// think of it as event channels that you can subscribe to
const state = () => getDefaultState();

const store = {
  state,
  mutations,
};

export default store;
