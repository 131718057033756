import curry from '../curry';

/**
 * @typedef {(left: Object<string, any>, right: Object<string, any>) => Object<string, any>} MergeLeftFn
 */

/**
 * @type MergeLeftFn
 */
export const _mergeLeft = (left, right) => Object.assign({}, right, left);

/**
 * Create a new object with the own properties of the first object merged with
 * the own properties of the second object. If a key exists in both objects,
 * the value from the first object will be used.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/mergeLeft.js
 *
 * @type MergeLeftFn
 * @example
 *
 *      mergeLeft({ 'age': 40 }, { 'name': 'fred', 'age': 10 });
 *      //=> { 'name': 'fred', 'age': 40 }
 *
 *      const resetToDefault = mergeLeft({x: 0});
 *      resetToDefault({x: 5, y: 2}); //=> {x: 0, y: 2}
 */
const mergeLeft = curry(_mergeLeft);

export default mergeLeft;
