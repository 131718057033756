import reduce from '../reduce';

/**
 * Converts an array of keys into an object where keys and values will be the same
 * as found in the array.
 *
 * @type {(keys: string[]) => Object<string, string>}
 * @example
 *
 *      toSameKeyValueObj(['a', 'b']); //=> { a: 'a', b: 'b' }
 *      toSameKeyValueObj([1, 2]); //=> { 1: 1, 2: 2 }
 */
const toSameKeyValueObj = reduce(
  (acc, key) => {
    acc[key] = key;
    return acc;
  },
  () => ({})
);

export default toSameKeyValueObj;
