export const LOGIN = 'user/login';
export const LOGOUT = 'user/logout';
export const UPDATE_LAST_SEEN_ACTIVE = 'user/updateLastSeenActive';
export const SET_USER_MOBILE_PHONE = 'user/setUserMobilePhone';
export const SET_USER_DATE_OF_BIRTH = 'user/setUserDateOfBirth';
export const CREATE_CAPTCHA_REQUIRED_DEFERRED = 'user/createCaptchaRequiredDeferred';
export const RESET_CAPTCHA_REQUIRED_DEFERRED = 'user/resetCaptchaRequiredDeferred';
export const RESOLVE_AND_RESET_CAPTCHA_REQUIRED_DEFERRED =
  'user/resolveAndResetCaptchaRequiredDeferred';
export const SHOW_CAPTCHA_MODAL = 'user/showCaptchaModal';
export const HIDE_CAPTCHA_MODAL = 'user/hideCaptchaModal';
export const SHOW_YOU_ARE_DONE_THANK_YOU_MODAL = 'user/showYouAreDoneThankYouModal';
export const HIDE_YOU_ARE_DONE_THANK_YOU_MODAL = 'user/hideYouAreDoneThankYouModal';
export const SET_IS_PRIVACY_POLICY_ACCEPTED = 'user/setIsPrivacyPolicyAccepted';
export const UNSET_IS_PRIVACY_POLICY_ACCEPTED = 'user/unsetIsPrivacyPolicyAccepted';
export const SET_SHOULD_SHOW_NEWSLETTER_CONSENT_MODAL =
  'user/setShouldShowNewsletterConsentModal';
export const UNSET_SHOULD_SHOW_NEWSLETTER_CONSENT_MODAL =
  'user/unsetShouldShowNewsletterConsentModal';
export const SET_USER_HAS_USER_EMAIL_SUBSCRIPTION_FLAG =
  'user/setUserHasUserEmailSubscriptionFlag';
export const UPDATE_PROFILE = 'user/updateProfile';
export const UPDATE_WDC = 'user/updateWdc';
export const UPDATE_MY_CURRENT_BOOKINGS = 'user/updateMyCurrentBookings';
export const UPDATE_MY_PAST_BOOKINGS = 'user/updateMyPastBookings';
export const UPDATE_MY_CANCELLED_BOOKINGS = 'user/updateMyCancelledBookings';
export const SET_REFUNDABLE_BOOKINGS = 'user/setRefundableBookings';
export const SET_AUTOMATICALLY_REFUNDABLE_BOOKINGS =
  'user/setAutomaticallyRefundableBookings';
export const UPDATE_CANCELLED_BOOKINGS_COUNT_IN_TOTAL =
  'user/updateCancelledBookingsCountInTotal';
export const UPDATE_CURRENT_BOOKINGS_COUNT_IN_TOTAL =
  'user/updateCurrentBookingsCountInTotal';
export const UPDATE_PAST_BOOKINGS_COUNT_IN_TOTAL = 'user/updatePastBookingsCountInTotal';
export const SET_USER_ORIGINAL_HAS_USER_EMAIL_SUBSCRIPTION_FLAG =
  'user/setUserOriginalHasUserEmailSubscriptionFlag';
export const SHOW_INVALID_BOOKING_STATE_MODAL = 'user/showInvalidBookingStateModal';
export const HIDE_INVALID_BOOKING_STATE_MODAL = 'user/hideInvalidBookingStateModal';
export const SET_INVALID_BOOKING_STATE_MODAL_VISIBILITY =
  'user/setInvalidBookingStateModalVisibility';
export const INCREMENT_LOADING_COUNTER = 'user/incrementLoadingCounter';
export const DECREMENT_LOADING_COUNTER = 'user/decrementLoadingCounter';
export const ADD_REGISTRATION_ERRORS = 'user/addRegistrationErrors';
export const REMOVE_REGISTRATION_ERRORS = 'user/removeRegistrationErrors';
export const ADD_LOGIN_ERRORS = 'user/addLoginErrors';
export const REMOVE_LOGIN_ERRORS = 'user/removeLoginErrors';
export const ADD_PROFILE_ERRORS = 'user/addProfileErrors';
export const REMOVE_PROFILE_ERRORS = 'user/removeProfileErrors';
export const UNSET_SHOULD_DISPLAY_MOBILE_PHONE_WARNING_MODAL =
  'user/unsetShouldDisplayMobilePhoneWarningModal';
export const ADD_CUSTOMER_FEEDBACK_ERRORS = 'user/addCustomerFeedbackErrors';
export const FETCH_CUSTOMER_FEEDBACK = 'user/fetchCustomerFeedback';
export const SET_CUSTOMER_FEEDBACK = 'user/setCustomerFeedback';
export const REMOVE_CUSTOMER_FEEDBACK_ERRORS = 'user/removeCustomerFeedbackErrors';
export const SET_IS_FAKE_GEO_LOCATION_ADDED = 'user/setIsFakeGeoLocationAdded';
export const SET_GEO_LOCATION = 'user/setGeoLocation';
export const SET_DETECTED_BROWSING_LOCATION = 'user/setDetectedBrowsingLocation';
export const SET_ACCOUNT_HISTORY = 'user/setAccountHistory';
export const SET_SELECTED_WDC_MEMBERSHIP = 'user/setSelectedWdcMembership';
export const SET_IS_YOUR_CLAIMS_POPUP_CLICKED = 'user/setIsYourClaimsPopupClicked';
export const SET_FREE_WDC_SUCCESSFUL_SUBSCRIPTION =
  'user/setFreeWDCSuccessfulSubscription';
export const UPDATE_PREVIOUS_FIRST_NAME = 'user/updatePreviousFirstName';
export const RESET_PREVIOUS_FIRST_NAME = 'user/resetPreviousFirstName';

export const SET_EMAIL_VERIFICATION_STATUS = 'user/setEmailVerificationStatus';
export const SHOW_CONTACT_DATA_VERIFICATION_MESSAGE =
  'user/showContactDataVerificationMessage';
export const HIDE_CONTACT_DATA_VERIFICATION_MESSAGE =
  'user/hideContactDataVerificationMessage';
