import { _flat } from '../flat';

/**
 * Shorthand for `chain(identity)`, which removes one level of nesting from
 * any [Chain](https://github.com/fantasyland/fantasy-land#chain).
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/unnest.js
 *
 * @type {<T>(arr: T[]) => T[]}
 * @example
 *
 *      unnest([1, [2], [[3]]]); //=> [1, 2, [3]]
 *      unnest([[1, 2], [3, 4], [5, 6]]); //=> [1, 2, 3, 4, 5, 6]
 */
const unnest = (arr) => _flat(1, arr);

export default unnest;
