import { isServer } from '~/utils/ssr';
import { cultures } from '~/constants/cultures';
import { language } from '~/constants/language';
import { page } from '~/constants/page';
import { FALLBACK_STATIC_SSR_PATH } from '~/constants';

export const getDefaultState = () => ({
  staticAssetUrl: `${
    // eslint-disable-next-line no-undef
    (isServer() ? process.env.STATIC_SSR_PATH : $STATIC_SSR_PATH) ??
    FALLBACK_STATIC_SSR_PATH
  }`,
  isRtl: false,
  urls: [],
  files: [],
  globalWizz: {},
  assetManifest: {},
  storedResourceNames: {},
  resourceScripts: '',
  language,
  cultures,
  page,
  bannerUrls: {},
  homePageBannersData: {},
});
