import curry from '../curry';
import propIsEmpty from '../prop-is-empty';

/**
 * @typedef {<T, K extends keyof T>(p: K, obj: T) => boolean} PropIsNotEmptyFn
 */

/**
 * @type PropIsNotEmptyFn
 */
export const _propIsNotEmpty = (p, obj) => !propIsEmpty(p, obj);

/**
 * Returns `true` if the specified object property is not empty, `false` otherwise.
 *
 * @type PropIsNotEmptyFn
 * @example
 *
 *      // pred :: Object -> Boolean
 *      var pred = propIsNotEmpty('a');
 *
 *      pred({a: [1, 2]});              //=> true
 *      pred({a: { b: 1 }});            //=> true
 *      pred({a: 'b'});              //=> true
 *      pred({a: {}});              //=> true
 */
const propIsNotEmpty = curry(_propIsNotEmpty);

export default propIsNotEmpty;
