import { MIN_GROUP_PASSENGERS_COUNT } from '~/constants';
import {
  createTravelDocumentErrors,
  getRecentlyPurchasedPassengerAncillaries,
  getRecentlyPurchasedSeats,
  getRecentlyPurchasedServices,
  findStepAfterOrderNumber,
  findStepBeforeOrderNumber,
  isCheckInPaymentStep,
  isChangesToAcceptStep,
  isNotCheckInPaymentStep,
  SERVICES_DIRECTION_GLOBAL,
  PASSENGER_MATCHER_REGEX,
  INFANT_MATCHER_REGEX,
} from '~/store/modules/check-in/internal';
import { flights } from '~/store/modules/itinerary/getters';
import { hasItemsInCart, hasAmountDue } from '~/store/modules/summary/getters';
import { isAdult, isNotInfant } from '~/utils/booking/passenger';
import allPass from '~/utils/fp/all-pass';
import both from '~/utils/fp/both';
import complement from '~/utils/fp/complement';
import curry from '~/utils/fp/curry';
import either from '~/utils/fp/either';
import equals from '~/utils/fp/equals';
import filter from '~/utils/fp/filter';
import findIndex from '~/utils/fp/find-index';
import find from '~/utils/fp/find';
import head from '~/utils/fp/head';
import lt from '~/utils/fp/lt';
import map from '~/utils/fp/map';
import path from '~/utils/fp/path';
import length from '~/utils/fp/length';
import compose from '~/utils/fp/compose';
import gt from '~/utils/fp/gt';
import gte from '~/utils/fp/gte';
import __ from '~/utils/fp/__';
import prop from '~/utils/fp/prop';
import propEq from '~/utils/fp/prop-eq';
import propEqFalse from '~/utils/fp/prop-eq-false';
import propEqTrue from '~/utils/fp/prop-eq-true';
import reduce from '~/utils/fp/reduce';
import some from '~/utils/fp/some';
import isNil from '~/utils/object/is-nil';
import isNotEmpty from '~/utils/object/is-not-empty';
import propOr from '~/utils/fp/prop-or';
import orElse from '~/utils/fp/or-else';
import isEmpty from '~/utils/object/is-empty';
import { currentDateAndTime, isAfter } from '~/utils/date';
import _isFlownFlight from '~/utils/booking/booking/is-flown-flight';
import {
  CHECK_IN_STEP_ALL_DONE,
  CHECK_IN_STEP_PASSENGERS,
  CHECK_IN_STEP_PAYMENT,
  CHECK_IN_STEP_TRAVEL_DOCUMENTS,
  CHECK_IN_STEP_WARNING,
} from './constants';

const checkInProp = curry((prop, state) => path(prop, state.checkIn));

export const errors = checkInProp('errors');

export const steps = checkInProp('steps');

export const firstName = checkInProp('firstName');

const isNotAtAllDoneOrPaymentStep = (state) =>
  !isAtAllDoneStep(state) && !isAtPaymentStep(state);

export const availableSteps = (state) =>
  compose(
    map((step) => ({
      ...step,
      // note: can edit when not on `all-done` or `payment` step
      //  or when no `payment` step present and it's not the `all-done` step
      isEditable:
        step.name !== CHECK_IN_STEP_WARNING &&
        isNotAtAllDoneOrPaymentStep(state) &&
        (isPaymentStepAvailable(state) ? isStepBeforePayment(state) : true),
    })),
    _availableSteps
  )(state);

const _availableSteps = (state) =>
  compose(
    filter((step) => {
      if (isCheckInPaymentStep(step.name))
        return (hasItemsInCart(state) && hasAmountDue(state)) || isAlreadyPaid(state);

      if (isChangesToAcceptStep(step.name)) return isChangesToAcceptStepVisible(state);

      return isNotCheckInPaymentStep(step.name) || isPaymentFailed(state);
    }),
    steps
  )(state);

export const firstStep = (state) => head(availableSteps(state));

export const hasAvailableSteps = compose(isNotEmpty, availableSteps);

/**
 * @type {(state: State) => string | null}
 */
export const currentStep = checkInProp('currentStep');

/**
 * @type {(state: State) => string | undefined}
 */
const currentStepName = checkInProp('currentStep.name');

const isAtPaymentStep = compose(equals(CHECK_IN_STEP_PAYMENT), currentStepName);

const isPaymentStepAvailable = compose(
  some(propEq('name', CHECK_IN_STEP_PAYMENT)),
  _availableSteps
);

const isAtAllDoneStep = compose(equals(CHECK_IN_STEP_ALL_DONE), currentStepName);

export const paymentStep = compose(find(propEq('name', CHECK_IN_STEP_PAYMENT)), steps);

export const passengersStep = compose(
  find(propEq('name', CHECK_IN_STEP_PASSENGERS)),
  steps
);

/**
 * note: might return -1 as well because `currentStep` starts with a `null` value
 *
 * @type {(state: State) => number}
 */
export const currentStepIndex = (state) =>
  compose(findIndex(propEq('name', currentStepName(state))), availableSteps)(state);

export const bookedFlights = (state) => flights(state);

export const pnr = checkInProp('pnr');

export const lastName = checkInProp('lastName');

export const direction = checkInProp('direction');

export const flight = checkInProp('flight');

export const carrierCode = checkInProp('flight.carrier');

export const isDomestic = checkInProp('flight.isDomestic');

export const isRoundTrip = checkInProp('flight.isRoundTrip');

export const isAutoCheckIn = checkInProp('isAutoCheckIn');

export const hoursUntilCheckInAvailable = checkInProp('hoursUntilCheckInAvailable');

/**
 * @type {(state: State) => Record<string, any> | null}
 */
export const previousStep = (state) => {
  const _currentStep = currentStep(state);
  if (isEmpty(_currentStep)) return null;
  return findStepBeforeOrderNumber(availableSteps(state), _currentStep.order);
};

/**
 * @type {(state: State) => Record<string, any> | null}
 */
export const nextStep = (state) => {
  const _currentStep = currentStep(state);
  if (isEmpty(_currentStep)) return null;
  return findStepAfterOrderNumber(availableSteps(state), _currentStep.order);
};

/**
 * @type {(state: State) => string}
 */
export const previousStepName = compose(propOr('', 'name'), orElse({}), previousStep);

/**
 * @type {(state: State) => string}
 */
export const nextStepName = compose(propOr('', 'name'), orElse({}), nextStep);

// note: if we dont' have `currentStep` or `paymentStep` it returns false which
//  in itself not much usable information, client side should take additional
//  measures
export const isStepBeforePayment = (state) =>
  compose(
    lt(__, paymentStep(state)?.order),
    prop('order'),
    orElse({}),
    currentStep
  )(state);

export const stepAfterPayment = (state) =>
  findStepAfterOrderNumber(_availableSteps(state), paymentStep(state)?.order);

export const warningLabels = checkInProp('stepsData.warning.labels');

export const availablePassengers = checkInProp(
  'stepsData.passengers.availablePassengers'
);

export const isNationalityNeeded = checkInProp('stepsData.passengers.needNationality');

export const selectedPassengers = (state) =>
  compose(
    filter(
      (passengerNumber) =>
        !(
          availablePassengers(state).find(propEq('passengerNumber', passengerNumber)) ||
          {}
        ).checkInDenied
    ),
    checkInProp('stepsData.passengers.selectedPassengers')
  )(state);

export const selectedPassengersNationality = checkInProp(
  'stepsData.passengers.nationalities'
);

// todo selectedPassengers -> selectedPassengerNumbers
export const selectedPassengersDetails = (state) =>
  compose(
    filter((passenger) => selectedPassengers(state).includes(passenger.passengerNumber)),
    availablePassengers
  )(state);

export const isTravelDocumentRequired = compose(
  some(propEqTrue('isTravelDocumentRequired')),
  selectedPassengersDetails
);

const isTravelDocumentNotRequired = complement(isTravelDocumentRequired);

const isFirstPassengerWithinSelectedPassengers = compose(
  some(propEq('passengerNumber', 0)),
  selectedPassengersDetails
);

export const shouldValidateCheckInPassengers = compose(
  some(propEqTrue('needDateOfBirth')),
  selectedPassengersDetails
);

export const isContactDataRequired = both(
  either(isFirstPassengerWithinSelectedPassengers, shouldValidateCheckInPassengers),
  isTravelDocumentNotRequired
);

export const isPassengersStepValid = checkInProp('stepsData.passengers.isStepValid');

export const isSeatSelectionBlockedBySeatingTogether = checkInProp(
  'stepsData.passengers.isSeatSelectionBlockedBySeatingTogether'
);

export const hasAnySelectedPassenger = compose(gt(__, 0), length, selectedPassengers);

export const passengerStepErrors = checkInProp('stepsData.passengers.errors');

export const hasAnyPassengerStepError = compose(isNotEmpty, passengerStepErrors);

export const isEmptyPayment = checkInProp('isEmptyPaymentOccurred');

export const isPaymentFailed = checkInProp('stepsData.payment.isPaymentFailed');

export const contactDataErrors = checkInProp('stepsData.contactData.errors');

export const hasContactDataError = compose(isNotEmpty, contactDataErrors);

export const contactDataPassengerErrors = checkInProp(
  'stepsData.contactData.passengerErrors'
);

export const hasContactDataPassengerErrors = compose(
  isNotEmpty,
  contactDataPassengerErrors
);

export const contactDataPassengers = (state) =>
  compose(
    filter(
      allPass(
        either(propEqTrue('needContactData'), propEqTrue('needDateOfBirth')),
        isAdult,
        isAmongSelectedPassengers(state)
      )
    ),
    checkInProp('stepsData.contactData.passengers')
  )(state);

const isAmongSelectedPassengers = curry((state, passenger) =>
  selectedPassengers(state).includes(passenger.passengerNumber)
);

export const checkInPostErrors = checkInProp('stepsData.prohibitedItems.errors');

export const isAlreadyPaid = compose(
  both(complement(isEmptyPayment), checkInProp('stepsData.payment.isAlreadyPayed'))
);

export const travelDocumentsPassengers = checkInProp(
  'stepsData.travelDocuments.passengers'
);

export const travelDocumentValidationMessages = checkInProp(
  'stepsData.travelDocuments.validationMessages'
);

export const biometricNationalities = checkInProp(
  'stepsData.travelDocuments.biometricNationalities'
);

export const visaOrResidencePermitRequiredNationalities = checkInProp(
  'stepsData.travelDocuments.visaOrResidencePermitRequiredNationalities'
);

export const travelDocumentRules = checkInProp(
  'stepsData.travelDocuments.travelDocumentRules'
);

export const travelDocumentErrors = compose(
  map((passenger) => ({
    ...passenger,
    adultPassengerNumber: isNil(passenger.adultPassengerNumber)
      ? null
      : Number(passenger.adultPassengerNumber),
    passengerNumber: passenger.passengerNumber.includes('i')
      ? null
      : Number(passenger.passengerNumber),
  })),
  reduce(createTravelDocumentErrors, () => []),
  checkInProp('stepsData.travelDocuments.errors')
);

export const genericTravelDocumentErrors = compose(
  filter((error) => {
    const [, passengerNumber] = error.match(PASSENGER_MATCHER_REGEX) || [];
    const [, adultPassengerNumber] = error.match(INFANT_MATCHER_REGEX) || [];
    return !passengerNumber && !adultPassengerNumber;
  }),
  checkInProp('stepsData.travelDocuments.errors')
);

const recentlyPurchasedPassengerAncillaries = (state) =>
  compose(
    reduce(
      (acc, { firstName, lastName, passengerNumber }) => {
        const { seats, ancillaries, services } = checkInProp(
          'recentlyPurchasedItems',
          state
        );
        const _direction = direction(state);
        const recentlyPurchasedAncillaries = [
          ...getRecentlyPurchasedPassengerAncillaries(
            ancillaries,
            passengerNumber,
            _direction
          ),
          ...getRecentlyPurchasedSeats(seats, passengerNumber, _direction),
          ...getRecentlyPurchasedServices(
            services,
            `${_direction}Flight`,
            numberOfIndependentPassengers(state)
          ),
        ];

        if (isNotEmpty(recentlyPurchasedAncillaries)) {
          acc.push({
            name: `${firstName} ${lastName}`,
            recentlyPurchasedAncillaries,
          });
        }

        return acc;
      },
      () => []
    ),
    selectedPassengersDetails
  )(state);

export const recentlyPurchasedItems = (state) => ({
  services: getRecentlyPurchasedServices(
    checkInProp('recentlyPurchasedItems', state).services,
    SERVICES_DIRECTION_GLOBAL,
    numberOfIndependentPassengers(state)
  ),
  passengers: recentlyPurchasedPassengerAncillaries(state),
});

export const hasRecentlyPurchasedItems = compose(
  some(isNotEmpty),
  Object.values,
  recentlyPurchasedItems
);

export const recentlyPaidAmount = checkInProp('recentlyPaidAmount');

export const contactDataNumberOfIndependentPassengers = compose(
  length,
  filter(isNotInfant),
  contactDataPassengers
);

export const isTravelDocumentsStepAvailable = compose(
  some(propEq('name', CHECK_IN_STEP_TRAVEL_DOCUMENTS)),
  steps
);

const numberOfIndependentPassengers = compose(
  length,
  filter(isNotInfant),
  availablePassengers
);

/**
 * @type {(state: State) => boolean}
 */
export const isGroupBooking = compose(
  gte(__, MIN_GROUP_PASSENGERS_COUNT),
  numberOfIndependentPassengers
);

export const canListInvoices = checkInProp('availableOperations.canListInvoices');

export const canShowPriceDetails = checkInProp('availableOperations.canShowPriceDetails');

export const isFlightFlown = compose(_isFlownFlight, flight);

export const totalPassengerCount = compose(length, availablePassengers);

const canChangeOutboundSeat = checkInProp('availableOperations.canChangeOutboundSeat');

const canChangeReturnSeat = checkInProp('availableOperations.canChangeReturnSeat');

export const canChangeSeats = either(canChangeOutboundSeat, canChangeReturnSeat);

export const allDonePassengers = checkInProp('stepsData.allDone.passengers');

export const availableFeedbackStages = checkInProp(
  'stepsData.allDone.availableFeedbackStages'
);

export const bookingComCreditBackPercentage = checkInProp(
  'stepsData.allDone.bookingComCreditBackPercentage'
);

export const carRentalCreditBackPercentage = checkInProp(
  'stepsData.allDone.carRentalCreditBackPercentage'
);

export const isCheckInForReturnButtonAvailable = checkInProp(
  'stepsData.allDone.isCheckInForReturnButtonAvailable'
);

export const isOnlineCheckInDisabledForReturnLeg = checkInProp(
  'stepsData.allDone.isOnlineCheckInDisabledForReturnLeg'
);

/**
 * @type {(state: State) => Record<string, any> | null}
 */
export const checkInStartDiffForReturnFlight = checkInProp(
  'stepsData.allDone.checkinStartDiffReturnFlight'
);

export const isManuallyAdded = checkInProp('stepsData.allDone.isManuallyAdded');

export const isOwner = checkInProp('stepsData.allDone.isOwner');

export const allDonePassengerCount = compose(
  length,
  filter(allPass(isNotInfant, propEqFalse('checkInDenied'))),
  allDonePassengers
);

export const travelDocsDocTypeNationalId = checkInProp(
  'stepsData.travelDocuments.nationalId'
);

export const travelDocsDocTypePassport = checkInProp(
  'stepsData.travelDocuments.passport'
);

export const changesToAccept = checkInProp('stepsData.changesToAccept.changes');

export const isChangesToAcceptStepValid = checkInProp(
  'stepsData.changesToAccept.isStepValid'
);

export const passengersWithChangesToAccept = (state) =>
  compose(
    filter((passenger) => passenger.needChangesToAcceptPage),
    selectedPassengersDetails
  )(state);

export const isChangesToAcceptStepVisible = compose(
  some(propEqTrue('needChangesToAcceptPage')),
  selectedPassengersDetails
);

export const availableAllDonePassengers = compose(
  length,
  filter(isNotInfant),
  allDonePassengers
);
