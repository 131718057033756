import equals from '~/utils/fp/equals';

export const moduleName = 'system';
export const WEBSITE_DOWN_REASON_MAINTENANCE = 'MAINTENANCE';
export const LOGIN_MODAL_ACTION_CANCEL = 'cancel';
export const DEFAULT_SESSION_TIMEOUT_IN_MINUTES = 20;
export const DEFAULT_INACTIVITY_TIMEOUT_IN_SECONDS = 30;
export const DEFAULT_REDIRECT_TIMEOUT_IN_MILISECONDS = 2000;

/**
 * @type {(value: string) => boolean}
 */
export const isLoginModalCancelAction = equals(LOGIN_MODAL_ACTION_CANCEL);
