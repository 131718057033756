import curry from '~/utils/fp/curry';
import path from '~/utils/fp/path';
import pick from '~/utils/fp/pick';
import { DIRECTION_OUTBOUND, DIRECTION_RETURN } from '~/constants';
import { DISRUPTION_TYPE_NONE } from '~/constants/flight-disruption';

export const flightDisruptionProp = curry((_path, state) =>
  path(_path, state.flightDisruption)
);

export const rebookToSeatFlight = (rebook) => {
  const outboundFlight = rebook?.outboundFlight?.value;
  const returnFlight = rebook?.returnFlight?.value;
  return {
    outbound: outboundFlight ? convertFlight(outboundFlight) : null,
    return: returnFlight ? convertFlight(returnFlight) : null,
  };
};

export const getDefaultState = () => ({
  disruptionType: DISRUPTION_TYPE_NONE,
  outboundFlight: null,
  returnFlight: null,
  canRebook: false,
  canAcceptNewFlight: false,
  canRefund: false,
  loadingCounter: 0,
  shouldAcceptCarrierChange: false,
  acceptanceCarrierCode: '',
  shouldReselectSeats: false,
  flightStatusChangedState: null,
  refundOptions: {},
  selectedRefundOptions: {
    outbound: false,
    return: false,
  },
  rebookToSeatFlight: null,
  errors: [],
  seatSelectionNeededPassengerList: {
    outboundSeatSelectionNeededPassengerList: [],
    returnSeatSelectionNeededPassengerList: [],
  },
  changedPassengers: {
    [DIRECTION_OUTBOUND]: [],
    [DIRECTION_RETURN]: [],
  },
  creditBackBonusPercent: null,
  thirdPartiesNeedChange: [],
});

const convertFlight = (flight) => {
  const result = pick(
    [
      'departureStation',
      'arrivalStation',
      'flightSellKey',
      'departureDateTime',
      'arrivalDateTime',
    ],
    flight
  );

  const [carrier, flightNumber] = (flight?.flightSellKey || '').split('~');

  result.departureDate = flight.departureDateTime;
  result.arrivalDate = flight.arrivalDateTime;
  result.flightNumber = carrier;
  result.carrier = flightNumber;

  return result;
};
