import { to } from 'await-to-js';
import { captureAndLogException } from '~/utils/logging';
import * as BookingService from '~/services/booking';
import * as m from './mutation-types';

export const fetchExtraInformation = async ({ state, commit }) => {
  commit(m.RESET_EXTRA_INFORMATION);
  const [error, response] = await to(BookingService.getExtraInformation());
  if (error) return captureAndLogException('Could not fetch information banners', error);
  commit(m.SET_EXTRA_INFORMATION, response.data?.informationBanners ?? []);
};
