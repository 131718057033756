import { getI18n } from '~/i18n';
import isNotNil from '~/utils/object/is-not-nil';
import { capitalize, toSentenceCase } from '~/utils/string';

/**
 * Returns a translated string that may be numbered
 * e.g.: error-too-tall-1 => Passenger 1: Too tall passenger can only travel while sitting
 *
 * @param labelKey       Label key
 * @param params         label params array
 * @param listPrefixAdd  BE sends 0 based indexes, FE displays 1 based indexes
 * @param listPrefix     numbered error messages may need an intelligent prefix
 * @param skipTranslation
 *                       (this may be useful for group booking or csv parsing errors):
 *                       - with `PrefixCountPassenger`:
 *                         `PassengerFirstNameEmpty-{0}` ---> `Passenger 1: missing first name`
 *                       -  with `PrefixCountGeneric`
 *                         `PassengerGenderNull-{0}` ---> `Item 1: invalid gender type`
 *                       - infants have no passengerNumber, so no postfix will be attached for infant errors
 * @returns {string}     If everything goes well, method returns the translated string.
 */
export const parseNumberedLabel = function ({
  labelKey,
  params = [],
  listPrefix = null,
  listPrefixAdd = 1,
  skipTranslation = false,
}) {
  const i18n = getI18n();
  const matcher = labelKey.match(/^(.*)[\s-]([\d{|}]+)$/) || [];
  const isLabelKeyHasUnparsedBackendFormatString = matcher[2]?.startsWith('{');
  const hasCounter =
    matcher.length > 2 &&
    (skipTranslation || i18n.te(matcher[1])) &&
    !isLabelKeyHasUnparsedBackendFormatString;
  const number = hasCounter
    ? (Number.parseInt(matcher[2], 10) || 0) + listPrefixAdd
    : null;
  const labelPrefix =
    isNotNil(listPrefix) && !skipTranslation ? i18n.t(listPrefix, [number]) : null;
  const _labelKey =
    hasCounter || isLabelKeyHasUnparsedBackendFormatString ? matcher[1] : labelKey;
  let translation = toSentenceCase(_labelKey);

  if (hasCounter) {
    translation = `${labelPrefix ? `${labelPrefix} ` : ''}${capitalize(
      i18n.t(_labelKey)
    )}`;
  }
  if (i18n.te(_labelKey)) {
    translation = i18n.t(_labelKey, params);
  }

  return {
    originalLabelKey: _labelKey,
    labelKey: _labelKey,
    translation: skipTranslation ? null : translation,
    type: listPrefix,
    number,
  };
};
