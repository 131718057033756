import {
  ANCILLARY_SEAT,
  DIRECTION_OUTBOUND,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
} from '~/constants';
import { getAncillariesFromServices } from '~/utils/ancillary';
import curry from '~/utils/fp/curry';
import path from '~/utils/fp/path';
import pathOr from '~/utils/fp/path-or';
import propNotEq from '~/utils/fp/prop-not-eq';

export const moduleName = 'successfulBooking';
export const successfulBookingProp = curry((prop, state) =>
  path(prop, state[moduleName])
);
export const successfulBookingPropOr = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, prop, state[moduleName])
);

export const getDefaultState = () => ({
  isNewBooking: true,
  isSuccessfulBookingAffiliateModalVisible: false,
  isSuccessfulBookingInitialized: false,

  operations: {
    canShowPriceDetails: false,
    canListInvoices: false,
    outboundCheckIn: false,
    returnCheckIn: false,
    displayCarbonOffsetRibbon: false,
  },

  booking: {
    flights: {},
    passengers: [],
    totalPaidAmount: null,
    availableFeedbackStages: [],
  },

  errors: [],
});

const getFlightPropByDirection = (direction) =>
  direction === DIRECTION_OUTBOUND ? OUTBOUND_FLIGHT : RETURN_FLIGHT;

export const passengerAncillaries = (direction, passenger) => {
  const passengerServices = passenger[getFlightPropByDirection(direction)]?.services;
  if (!passengerServices) return [];
  return getAncillariesFromServices(passengerServices).filter(
    propNotEq('name', ANCILLARY_SEAT)
  );
};
