/* eslint import/no-cycle: "warn" */
import to from 'await-to-js';
import {
  DIRECTION_OUTBOUND,
  FARE_CHART_DAY_INTERVAL,
  FORWARD,
  INFANT_LIMIT,
  OXY_LIMIT,
  SOLD_OUT,
  NO_FARES,
  FLIGHT_IN_PAST,
  DISCOUNT_TYPE_NONE,
  FARE_DISCOUNT_TYPE_PERCENTAGE,
  BUNDLE_MIDDLE,
  DIRECTION_RETURN,
  MIN_INTERVAL_BEFORE_RETURN_FLIGHT,
  WDC_MAX_PASSENGER_COUNT_GROUP,
  BUNDLE_PLUS,
  BUNDLE_BASIC,
  BUNDLE_ANCILLARY_SEAT_SELECTION,
} from '~/constants';
import propEqTrue from '~/utils/fp/prop-eq-true';
import propEq from '~/utils/fp/prop-eq';
import pathOr from '~/utils/fp/path-or';
import isNil from '~/utils/object/is-nil';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import { mergeFlightDates, markBadDatesInFareChart } from '~/utils/booking/flight';
import {
  isBasicBundle,
  isMiddleBundle,
  isMiddleTwoBundle,
  isSmartBundle,
  isPlusBundle,
  isOutboundDirection,
} from '~/utils/booking/booking';
import { isAncillaryPromoApplied } from '~/utils/booking/select';
import { capitalize } from '~/utils/string';
import { captureAndLogException } from '~/utils/logging';
import * as AssetService from '~/services/asset';
import * as BookingService from '~/services/booking';
import * as SearchService from '~/services/search';
import * as AncillaryService from '~/services/booking/ancillary';

import * as ancillariesActions from '~/store/modules/ancillaries/actions';
import * as ancillariesGetters from '~/store/modules/ancillaries/getters';
import * as analyticsGetters from '~/store/modules/analytics/getters';
import * as bookingActions from '~/store/modules/booking/actions';
import * as bookingGetters from '~/store/modules/booking/getters';
import * as coreBookingGetters from '~/store/modules/core-booking/getters';
import * as promotionActions from '~/store/modules/promotion/actions';
import * as passengersActions from '~/store/modules/passengers/actions';
import * as passengersGetters from '~/store/modules/passengers/getters';
// todo we don't want to use the search store here at all
import * as searchActions from '~/store/modules/search/actions';
import * as resourcesGetters from '~/store/modules/resources/getters';
import * as resourceActions from '~/store/modules/resources/actions';
import * as summaryGetters from '~/store/modules/summary/getters';
import * as summaryActions from '~/store/modules/summary/actions';
import * as userGetters from '~/store/modules/user/getters';
import * as featureGetters from '~/store/modules/feature/getters';

import curry from '~/utils/fp/curry';
import __ from '~/utils/fp/__';
import {
  addDays,
  currentDateAndTime,
  toDefaultFormat,
  differenceInMinutes,
  isAfter,
  isBefore,
  getUtcOffset,
} from '~/utils/date';
import isNotNil from '~/utils/object/is-not-nil';
import * as getters from '../getters';
import * as m from '../mutation-types';
import * as sync from '../sync';
import { LoadingCounter } from '../constants';
import { ANCILLARY_REGULAR_SEAT_LABEL_KEY } from '../../ancillaries/internal';
import {
  removeAndPostWizzFlex,
  initWizzFlex,
  _postSelect,
  getPayableFlight,
  getPayableFare,
  addAndPostWizzFlex,
} from './internal-c';

export const fetchImportantInformation = async (store, direction) => {
  const { state, commit } = store;
  const isOutbound = isOutboundDirection(direction);
  const stations = resourcesGetters.stationsWithFakes(state);

  const fromIATA = isOutbound
    ? getters.departureStationIata(state)
    : getters.arrivalStationIata(state);
  const toIATA = isOutbound
    ? getters.arrivalStationIata(state)
    : getters.departureStationIata(state);
  const departureStation = stations.find((station) => station.iata === fromIATA);
  const arrivalStation = stations.find((station) => station.iata === toIATA);

  const data = {
    fromIATA,
    toIATA,
    departureCountry: departureStation ? departureStation.countryCode : null,
    arrivalCountry: arrivalStation ? arrivalStation.countryCode : null,
    departureDate: isOutbound
      ? getters.selectedOutboundFlightDate(state)
      : getters.selectedReturnFlightDate(state),
    returnDate: isOutbound ? getters.selectedReturnFlightDate(state) : null,
  };

  const response = await AssetService.importantInfo(data);
  const labels = pathOr([], 'data.labels', response);

  commit(m.SET_IMPORTANT_INFORMATION, {
    labels,
    direction,
  });
};

export const getFlightSelectSearchData = (
  state,
  searchDates,
  metaSearchSource = null
) => {
  const departureIata = getters.departureStationIata(state);
  const arrivalIata = getters.arrivalStationIata(state);
  const isDomestic = getters.isDomestic(state);

  // isWdcFareTypeAvailable getter should not be used here
  // as it checks for response data which we don't have yet
  const isWdcFareTypeAvailable =
    getters.numberOfIndependentPassengers(state) <= WDC_MAX_PASSENGER_COUNT_GROUP;

  const searchData = {
    flightList: [
      {
        departureStation: departureIata,
        arrivalStation: arrivalIata,
        departureDate: searchDates.outbound,
      },
    ],
    adultCount: getters.numberOfAdultPassengers(state),
    childCount: getters.numberOfChildPassengers(state),
    infantCount: getters.numberOfInfantPassengers(state),
    wdc:
      arrivalIata &&
      !isDomestic &&
      isWdcFareTypeAvailable &&
      !userGetters.isAgency(state),
    isFlightChange: coreBookingGetters.isFlightChangeOrRebookFlow(state),
    ...(metaSearchSource && { metaSearchSource }),
  };

  const _rescueFareCode = getters.rescueFareCode(state);
  if (_rescueFareCode) searchData.rescueFareCode = _rescueFareCode;

  if (getters.isRoundTrip(state)) {
    searchData.flightList.push({
      departureStation: arrivalIata,
      arrivalStation: departureIata,
      departureDate: searchDates.return,
    });
  }

  return searchData;
};

export const fetchPossibleDatesForFlights = (store, dates) => {
  const { state } = store;
  const outboundPromise = fetchPossibleDatesForFlight(
    store,
    dates[DIRECTION_OUTBOUND],
    DIRECTION_OUTBOUND
  );
  let returnPromise = Promise.resolve();
  if (getters.isRoundTrip(state)) {
    returnPromise = fetchPossibleDatesForFlight(
      store,
      dates[DIRECTION_RETURN],
      DIRECTION_RETURN
    );
  }
  return Promise.all([outboundPromise, returnPromise]);
};

const fetchPossibleDatesForFlight = (store, date, direction) => {
  const { state } = store;
  const isOutbound = isOutboundDirection(direction);
  return searchActions.getPossibleDatesForFlight(store, {
    departureStation: isOutbound
      ? getters.departureStationIata(state)
      : getters.arrivalStationIata(state),
    arrivalStation: isOutbound
      ? getters.arrivalStationIata(state)
      : getters.departureStationIata(state),
    selectedDate: date,
    direction,
  });
};

export const openedFlight = (state, direction = DIRECTION_OUTBOUND) => {
  return isOutboundDirection(direction)
    ? getters.openedOutboundFlight(state)
    : getters.openedReturnFlight(state);
};

/**
 * @type {(store: Store) => void}
 */
export const initFareTypeSelection = (store) => {
  const { state } = store;
  _setIsWdcFareTypeSelectedFlag(
    store,
    getters.canInitializeFareTypeWithWdcFareType(state)
  );
};

/**
 * @type {(store: Store) => void}
 */
export const initFareSelection = (store) => {
  const { state } = store;
  if (
    getters.canInitializeFareTypeWithWdcFareType(state) &&
    getters.hasSelectedOutboundFare(state)
  ) {
    [
      DIRECTION_OUTBOUND,
      ...(getters.isRoundTrip(state) && getters.hasSelectedReturnFare(state)
        ? [DIRECTION_RETURN]
        : []),
    ].forEach((direction) => {
      _selectFare(store, {
        direction,
        skipWizzFlexInit: true,
        fareId: getters[`selected${capitalize(direction)}FareId`](state),
        flightId: getters[`selected${capitalize(direction)}FlightId`](state),
      });
    });
  }
};

export const fetchWdcMemberships = async (store) => {
  const { commit } = store;
  const response = await SearchService.getWdcMemberships();
  commit(m.UPDATE_WDC_DATA, response.data);
  sync.syncOnFetchWdcMemberships(store, response.data);
};

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise}
 */
export const _selectFare = (store, payload = {}) => {
  const { state, commit } = store;
  const { skipWizzFlexInit = false, direction, fareId, flightId = '' } = payload;

  commit(m.UNSET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION);
  commit(m.SELECT_FARE, { direction, fareId, flightId });

  if (
    isOutboundDirection(direction) &&
    isThereNotEnoughTimeBeforeSelectedReturnFlightDeparture(state)
  ) {
    resetReturnSelection(store);
  }

  const capitalDirection = capitalize(direction);
  const selectedFlight = getters[`selected${capitalDirection}Flight`](state);
  const selectedFare = getters[`selected${capitalDirection}Fare`](state);
  const selectedPriceDetails = getters.isWdcFareTypeSelected(state)
    ? selectedFare.wdcPriceDetails
    : selectedFare.regularPriceDetails;

  const purchaseLocation =
    direction === DIRECTION_OUTBOUND
      ? analyticsGetters.outboundFarePurchaseLocation(state)
      : analyticsGetters.returnFarePurchaseLocation(state);

  summaryActions.addPayableFlightAndFare(store, {
    type: direction,
    flight: getPayableFlight(selectedFlight),
    fare: getPayableFare(
      selectedFare,
      selectedPriceDetails,
      getters.isWdcFareTypeSelected(state)
    ),
    oldFare: null,
    fees: selectedPriceDetails.fees,
    metadata: analyticsGetters.metadata(state),
    purchaseLocation,
    isGroupSeatRequest: selectedFlight.isGroupSeatRequest,
    isEcommerceTrackingDisabled: false, // didn't see a case when it's true in flight-select that's why it's constant
  });

  if (summaryGetters.isFareLockAdded(state) && !featureGetters.isSplitPaxEnabled(state)) {
    removeFareLock(store);
  }

  sync.syncFlightAndFare(store, {
    direction,
    fareId,
    selectedFlightId: selectedFlight.id,
    selectedFareId: selectedPriceDetails.fareSellKey,
    isFlightChangeOrRebookFlow: coreBookingGetters.isFlightChangeOrRebookFlow(state),
  });

  let promise = Promise.resolve();
  if (!skipWizzFlexInit && getters.isWizzFlexPossiblyAvailable(state)) {
    promise = initWizzFlex(store);
  }
  return promise;
};

/**
 * @type {(store: Store) => Promise}
 */
export const _toggleFareLock = (store) => {
  const { state } = store;

  let promise = Promise.resolve();
  if (summaryGetters.isFareLockAdded(state)) {
    removeFareLock(store);
  } else {
    promise = addFareLock(store);
  }

  return promise;
};

export const coreSubmit = async (store) => {
  const { state } = store;
  const isFlightChangeOrRebookFlow = coreBookingGetters.isFlightChangeOrRebookFlow(state);

  if (getters.shouldCallPostSelectAtSubmit(state)) {
    const isWizzFlexAdded =
      ancillariesGetters.isWizzFlexSelected(state) &&
      summaryGetters.isWizzFlexSelectedAtFlightSelect(state);
    await _postSelect(state);
    if (!isFlightChangeOrRebookFlow && isWizzFlexAdded)
      await addAndPostWizzFlex(store, true);
  }

  // todo the following stuff should not be here it is related to flow handling
  //  what to do next etc.

  // todo migrate `shouldSendWdcRenewalRequest` to flight select (and/or later to wdc store)
  if (bookingGetters.shouldSendWdcRenewalRequest(state)) {
    // todo we could move this to a separate wdc store module when the time comes
    await bookingActions.renewWdc(store);
    await ancillariesActions.getAncillaries(store, { withSummary: true });
  }

  if (getters.isGroupSeatRequested(state)) return;

  if (getters.isGroupBooking(state) && !isFlightChangeOrRebookFlow)
    return initPassengersForGroupBooking(store);

  if (getters.isWdcMembershipAddedToCart(state)) {
    const selectedMembership = getters
      .wdcMemberships(state)
      .find((item) => item.membership.includes(getters.selectedWdcMembership(state)));

    await BookingService.wizzDiscountClub({
      requestedWdcMembership: selectedMembership.membership,
    });
  }

  await fetchAdditionalFees(store);

  return initPassengersForNormalBooking(store, {
    userProfile: userGetters.profile(state),
    isAgencyUser: userGetters.isAgency(state),
    isLoggedIn: userGetters.isLoggedIn(state),
    isFlightChangeOrRebookFlow,
    savedPassengerDetails: passengersGetters.savedPassengerDetails(state),
  });
};

export const _fetchMoreFlightDates = async (store, direction, scrollDirection) => {
  const { commit, state } = store;
  if (getters.hasRescueFareCode(state)) return;

  const isForward = scrollDirection === FORWARD;
  // todo here we should/could handle (probably) a flight change case where
  //  outbound flight is flown, which means the return becomes the outbound in
  //  response?
  const data = getFlightDateData(store);
  const dates = getters.datePickedDates(state)[direction] || [];
  const fromDate = dates[isForward ? dates.length - 1 : 0].date;
  const fromDateShort = fromDate.replace(/T.*$/, '');
  const flight = data.flightList[isOutboundDirection(direction) ? 0 : 1];
  const multiplier = isForward ? 1 : -1;
  data.wdc = getters.isWdcFareTypeSelected(state);

  // going backwards, but we have already reached today on the display
  if (!isForward && fromDateShort === toDefaultFormat(currentDateAndTime())) return;

  // calculate offset (edge+-N)
  let newDate = addDays(FARE_CHART_DAY_INTERVAL * multiplier, fromDateShort, 'days');
  if (isAfter(currentDateAndTime(), newDate)) {
    newDate = currentDateAndTime();
  }

  const newDateRaw = toDefaultFormat(newDate);
  if (flight.date === newDateRaw) return;

  flight.date = newDateRaw;

  commit(m.INCREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);
  const response = await AssetService.fareChart(data);
  commit(m.DECREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);

  if (isEmpty(response.data)) {
    commit(m.RESET_FLIGHT_DATES, direction);
    return;
  }

  const merged = mergeFlightDates(
    {
      outboundFlights: getters.outboundFlightDates(state),
      returnFlights: getters.returnFlightDates(state),
    },
    response.data,
    direction,
    isForward
  );

  if (getters.isRoundTrip(state)) {
    markBadDatesInFareChart(getters.selectedOutboundFlightDate(state), response.data);
  }

  commit(m.SET_FLIGHT_DATES, { direction, value: merged[`${direction}Flights`] });
};

/**
 * @type {(store: Store) => void}
 */
export const _setIsWdcFareTypeSelected = (store) => {
  _setIsWdcFareTypeSelectedFlag(store, true);
};

// Handle cases where one leg has only wdc fares, the other has both.
export const handleMixedFareTypes = (outboundFlights, returnFlights) => {
  if (hasOnlyWdcFares(outboundFlights) || hasOnlyWdcFares(returnFlights)) {
    return filterOutRegularFares(outboundFlights, returnFlights);
  }
  return [outboundFlights, returnFlights];
};

export const hasOnlyWdcFares = (flights) => {
  if (isEmpty(flights)) return false;
  return flights.every((flight) => {
    if (isEmpty(flight.fares)) return false;
    return flight.fares.every((fare) => isNil(fare.regularPriceDetails));
  });
};

const filterOutRegularFares = (outboundFlights, returnFlights) => {
  const filteredOutboundFlights = outboundFlights.map((flight) => {
    return removeRegularPriceDetails(flight);
  });
  const filteredReturnFlights = returnFlights.map((flight) => {
    return removeRegularPriceDetails(flight);
  });

  return [filteredOutboundFlights, filteredReturnFlights];
};

const removeRegularPriceDetails = (flight) => {
  flight.fares.forEach((fare) => {
    fare.regularPriceDetails = null;
    return fare;
  });
  return flight;
};

export const convertFlight =
  (
    state,
    { isFlightChangeOrRebookFlow, direction, bundles, isGroupBooking, transit = null }
  ) =>
  (flight) => {
    const {
      flightSellKey = '',
      flightNumber = '',
      carrierCode = '',
      opSuffix = '',
      operatingCarrierCode = '',
      departureDateTime = '',
      arrivalDateTime = '',
      departureStation = '',
      arrivalStation = '',
      duration,
      fares = [],
      isRequest,
    } = flight;

    return {
      id: flightSellKey,
      flightNumber,
      carrierCode,
      opSuffix,
      operatingCarrierCode,
      // todo we should attach this info in the getter I think, it is not a base state
      direction,
      departureDateTime,
      arrivalDateTime,
      departureTimeUtcOffset: getUtcOffset(flight.departureTimeUtcOffset),
      arrivalTimeUtcOffset: getUtcOffset(flight.arrivalTimeUtcOffset),

      departureStationIata: departureStation,
      arrivalStationIata: arrivalStation,

      duration,
      blockingIssues: getBlockingIssues(flight, isFlightChangeOrRebookFlow),
      fares: convertFares(state, {
        fares,
        bundles,
        flightNumber,
        isFlightChangeOrRebookFlow,
        isGroupBooking,
      }),
      isRequest,
      ...(transit && { transit }),
    };
  };

/**
 * @type {(store: Store) => void}
 */
export const removeFareLock = (store) => {
  const { state } = store;
  if (!summaryGetters.isFareLockAdded(state)) return;

  const fareLockItem = getPayableFareLock(state);
  summaryActions.removePayableFareLock(store, fareLockItem);
};

export const getFlightDateData = (store, baseDates = {}) => {
  const { state } = store;
  const departureIata = getters.departureStationIata(state);
  const arrivalIata = getters.arrivalStationIata(state);

  return {
    isRescueFare: getters.hasRescueFareCode(state),
    adultCount: getters.numberOfAdultPassengers(state),
    childCount: getters.numberOfChildPassengers(state),
    dayInterval: FARE_CHART_DAY_INTERVAL,
    wdc: getters.isWdcFareTypeSelected(state),
    isFlightChange: coreBookingGetters.isFlightChangeOrRebookFlow(state),

    flightList: [
      {
        departureStation: departureIata,
        arrivalStation: arrivalIata,
        date: isEmpty(baseDates)
          ? getters.selectedOutboundFlightDate(state)
          : baseDates.outbound,
      },
      ...(getters.isRoundTrip(state)
        ? [
            {
              departureStation: arrivalIata,
              arrivalStation: departureIata,
              date: isEmpty(baseDates)
                ? getters.selectedReturnFlightDate(state)
                : baseDates.return,
            },
          ]
        : []),
    ],
  };
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const _setIsWdcFareTypeSelectedFlag = (store, value) => {
  const { commit } = store;
  commit(m.SET_IS_WDC_FARE_TYPE_SELECTED_FLAG, value);
  sync.syncFareTypeSelection(store, value);
};

export const isThereEnoughTimeBeforeDeparture = (arrivalTime, departureTime) => {
  return (
    differenceInMinutes(departureTime, arrivalTime, 'minutes') >=
    MIN_INTERVAL_BEFORE_RETURN_FLIGHT
  );
};

/**
 * @type {(store: Store) => Promise}
 */
const addFareLock = (store) => {
  const { state } = store;
  if (summaryGetters.isFareLockAdded(state)) {
    removeFareLock(store);
  }

  const fareLockItem = getPayableFareLock(state);
  summaryActions.addPayableFareLock(store, fareLockItem);

  let promise = Promise.resolve();
  if (ancillariesGetters.isWizzFlexSelected(state)) {
    promise = removeAndPostWizzFlex(store);
  }

  return promise;
};

const initPassengersForNormalBooking = async (store, params = {}) => {
  const { state } = store;
  const {
    userProfile,
    isAgencyUser,
    isLoggedIn,
    isFlightChangeOrRebookFlow,
    savedPassengerDetails,
  } = params;

  const isSplitPaxEnabled =
    featureGetters.isSplitPaxEnabled(state) && coreBookingGetters.isNewBookingFlow(state);

  if (!isSplitPaxEnabled) {
    await passengersActions.getPassengers(store);
  }

  const canInitFlashPromo = getters.canInitializeFlashPromos(state);

  if (canInitFlashPromo) await ancillariesActions.initializeFlashPromos(store);
  await ancillariesActions.getAncillaries(store, { withSummary: true });

  await promotionActions.getPromotion(store);
  const [serviceFeesError] = await to(
    resourceActions.fetchServiceFees(store, {
      currencyCodes: [coreBookingGetters.bookingCurrencyCode(state)],
    })
  );
  if (serviceFeesError) {
    captureAndLogException('Cannot get serviceFees', serviceFeesError);
  }
  // note: this is special and we don't want to await for it at all, it is called
  //  here because it is slow as hell and may be it will have enough time to finish
  //  when the user reaches services step
  if (!summaryGetters.isFareLockAdded(state)) {
    ancillariesActions.getAirportParking(store);
    AncillaryService.initializeInsurance();
  }

  if (isFlightChangeOrRebookFlow || isAgencyUser) return;

  const hasSavedPassengers = isNotEmpty(savedPassengerDetails);

  if (hasSavedPassengers && isSplitPaxEnabled) {
    passengersActions.restorePassengers(store);
    await passengersActions.postPassengers(store);
    return;
  } else if (hasSavedPassengers) {
    passengersActions.restorePassengers(store);
  }

  if (isLoggedIn && !hasSavedPassengers && !isSplitPaxEnabled) {
    passengersActions.updatePassenger(store, {
      isInfant: false,
      passenger: {
        passengerNumber: 0,
        firstName: userProfile.firstName.value,
        lastName: userProfile.lastName.value,
        gender: userProfile.gender.value,
        iamTravelling: true,
      },
    });
  }
};

const initPassengersForGroupBooking = async (store) => {
  const { state } = store;
  passengersActions.setFakeGroupBookingPassenger(store);
  await ancillariesActions.getAncillaries(store, { withSummary: true });
  await promotionActions.getPromotion(store);
  const [serviceFeesError] = await to(
    resourceActions.fetchServiceFees(store, {
      currencyCodes: [coreBookingGetters.bookingCurrencyCode(state)],
    })
  );
  if (serviceFeesError) {
    captureAndLogException('Cannot get serviceFees', serviceFeesError);
  }
};

export const fetchAdditionalFees = async (store) => {
  const response = await SearchService.getAdditionalFees();
  sync.syncAdditionalFees(store, response.data);
};

const getBlockingIssues = (flight, isFlightChangeOrRebookFlow) => {
  const { infantLimitExceeded, oxyLimitExceeded, fares, isRequest } = flight;

  return {
    [INFANT_LIMIT]: infantLimitExceeded.limitExceeded && infantLimitExceeded.isBlocking,
    [OXY_LIMIT]:
      isFlightChangeOrRebookFlow &&
      oxyLimitExceeded.limitExceeded &&
      oxyLimitExceeded.isBlocking,
    [SOLD_OUT]: isNotEmpty(fares) ? fares.every(propEqTrue('soldOut')) : false,
    [NO_FARES]: isEmpty(fares) && !isRequest,
    [FLIGHT_IN_PAST]: isBefore(flight.departureDateTime, currentDateAndTime()),
  };
};

const convertFares = (
  state,
  { fares, bundles, flightNumber, isFlightChangeOrRebookFlow, isGroupBooking }
) => {
  const basicFares = fares.filter((fare) => isBasicBundle(fare.bundle));
  const middleFares = fares.filter((fare) => isMiddleBundle(fare.bundle));
  const middleTwoFares = fares.filter((fare) => isMiddleTwoBundle(fare.bundle));
  const smartFares = fares.filter((fare) => isSmartBundle(fare.bundle));
  const plusFares = fares.filter((fare) => isPlusBundle(fare.bundle));

  return [basicFares, smartFares, middleFares, middleTwoFares, plusFares]
    .filter(isNotEmpty)
    .map((fares) => {
      const regularFare = fares.find((fare) => isNil(fare.wdc));
      const wdcFare = fares.find((fare) => isNotNil(fare.wdc));
      const bundle = bundles.find(
        propEq('code', regularFare ? regularFare.bundle : wdcFare.bundle)
      );

      return convertFare(state, {
        flightNumber,
        bundle,
        regularFare,
        wdcFare,
        isFlightChangeOrRebookFlow,
        isGroupBooking,
      });
    });
};

const convertFare = (
  state,
  {
    flightNumber,
    bundle,
    regularFare,
    wdcFare,
    isFlightChangeOrRebookFlow,
    isGroupBooking,
  }
) => {
  const { soldOut, isFamily } = regularFare || wdcFare;
  const regularPriceDetails = regularFare
    ? convertPriceDetails(regularFare, isFlightChangeOrRebookFlow)
    : null;
  const wdcPriceDetails = wdcFare
    ? convertPriceDetails(wdcFare, isFlightChangeOrRebookFlow)
    : null;

  return {
    id: `${flightNumber}-${bundle.code}`,
    isPromoWdcEnabled: false,
    wdcMessageAvailable: false,
    selected: false,
    soldOut,
    isFamily,

    regularPriceDetails,
    wdcPriceDetails,
    bundle: convertBundle(state, { isFamily, bundle, isGroupBooking }),
  };
};

const convertPriceDetails = (fare, isFlightChangeOrRebookFlow) => {
  const {
    fareSellKey,
    fareDiscountType,
    flightPriceDetail,
    administrationFeePrice,
    basePrice,
    discountedFarePrice,
    flightChangeFeePrice,
    fareLockPrice,
    pastPrice,
    promotion,
    wdc,
  } = fare;

  const isDiscounted = isDiscountedFare(fare);

  return {
    fareSellKey: fareSellKey || '',
    discountType: fareDiscountType || DISCOUNT_TYPE_NONE,
    discountRate: flightPriceDetail?.discountRate || 0,
    displayCount: flightPriceDetail?.displayCount || 0,
    administrationFeePrice,
    ticketPrice: flightPriceDetail?.price,
    originalPrice: basePrice,
    promotionPrice: isDiscounted ? getPromotionPrice(fare) : null,
    farePrice: isFlightChangeOrRebookFlow ? discountedFarePrice : null,
    discountPrice: flightPriceDetail?.discountPrice,
    totalPrice: flightPriceDetail?.total,
    flightChangeFeePrice,
    fareLockPrice,
    pastPrice,
    discountDetails: isDiscounted ? getDiscountDetails(fare) : null,
    fees: flightPriceDetail?.fees,
    promotion,
    ...(isNotNil(wdc) && { wdc }),
  };
};

const getPromotionPrice = (fare) =>
  isAncillaryPromoApplied(fare) ? fare.promotion.promotedPrice : fare.discountedPrice;

const convertBundle = (state, { isFamily, bundle, isGroupBooking }) => {
  const code = bundle.code.toLowerCase();
  let ancillaries = [];
  if (code === BUNDLE_MIDDLE) {
    ancillaries = bundle.ancillaryServices.map((ancillary) => {
      return ancillary === BUNDLE_ANCILLARY_SEAT_SELECTION
        ? ANCILLARY_REGULAR_SEAT_LABEL_KEY
        : ancillary;
    });
  } else {
    ancillaries = bundle.ancillaryServices;
  }

  if (featureGetters.isSmartBundleEnabled(state)) {
    if (code === BUNDLE_BASIC) {
      bundle.groupedAncillaryServices = bundle.groupedAncillaryServices.map(
        ({ name, key }) => {
          return {
            name: !name.includes('-smartab') ? `${name}-smartab` : name,
            key: !key.includes('-smartab') ? `${key}-smartab` : key,
          };
        }
      );
    } else if (code === BUNDLE_PLUS) {
      bundle.groupedAncillaryServices = bundle.groupedAncillaryServices.map(
        ({ name, key }) => {
          return name === 'usp2-group-1-plus-seat'
            ? {
                name: !name.includes('-smartab') ? `${name}-smartab` : name,
                key,
              }
            : { name, key };
        }
      );
    }

    ancillaries = bundle.ancillaryServices.map((ancillary) => `${ancillary}-smartab`);
  }

  const title = getTitle(code, isFamily, isGroupBooking);

  if (hasExtendedAncillaries(bundle)) {
    ancillaries = [...ancillaries, ...bundle.otherLabelKeys];
  }

  return { ...bundle, ancillaries, code, title };
};

const getTitle = (bundleCode, isFamily, isGroupBooking) => {
  return bundleCode === BUNDLE_PLUS
    ? {
        main: `flight-select-fare-selector-bundle-${bundleCode}${
          isFamily ? '-family' : isGroupBooking ? '-group' : ''
        }`,
        secondary: `flight-select-fare-selector-bundle-${bundleCode}${
          isGroupBooking ? '-group' : ''
        }-subtitle`,
      }
    : {
        main: `flight-select-fare-selector-bundle-${bundleCode}${
          isFamily ? '-family' : ''
        }`,
        secondary: `flight-select-fare-selector-bundle-${bundleCode}-subtitle`,
      };
};

const hasExtendedAncillaries = (bundle) => isNotEmpty(bundle.otherLabelKeys);

const isDiscountedFare = (fare) => {
  return (
    fare.fareDiscountType !== DISCOUNT_TYPE_NONE ||
    fare.isFamily ||
    isAncillaryPromoApplied(fare)
  );
};

const getOriginalFlightPrice = (fare) => {
  if (isAncillaryPromoApplied(fare)) return fare.discountedPrice;
  if (fare.isFamily) return fare.discountedPriceWithoutFamilyDiscount;
  if (!fare.basePrice) return null;
  return {
    amount: fare.basePrice.amount - fare.administrationFeePrice.amount,
    currencyCode: fare.basePrice.currencyCode,
  };
};

const getNewFlightPrice = (fare) => {
  if (isAncillaryPromoApplied(fare)) return fare.promotion.promotedPrice;
  if (fare.isFamily) return fare.discountedPrice;
  if (!fare.basePrice) return null;
  return {
    amount: fare.discountedPrice.amount - fare.administrationFeePrice.amount,
    currencyCode: fare.discountedPrice.currencyCode,
  };
};

const getSaving = (fare) => {
  if (isAncillaryPromoApplied(fare)) {
    return {
      amount: fare.promotion.discountValue,
      currencyCode: fare.discountedPrice.currencyCode,
    };
  }
  if (fare.isFamily) {
    return {
      amount:
        fare.discountedPriceWithoutFamilyDiscount.amount - fare.discountedPrice.amount,
      currencyCode: fare.discountedPrice.currencyCode,
    };
  }
  if (!fare.basePrice) return null;
  return {
    amount: fare.basePrice.amount - fare.discountedPrice.amount,
    currencyCode: fare.discountedPrice.currencyCode,
  };
};

const getDiscountType = (fare) =>
  isAncillaryPromoApplied(fare)
    ? fare.promotion.promotion.promoType
    : fare.fareDiscountType;

const getDiscountRate = (fare) =>
  isAncillaryPromoApplied(fare)
    ? fare.promotion.promotion.promoValue
    : fare.flightPriceDetail?.discountRate;

const isPercentageDiscountOnAncillaryPromo = (fare) =>
  isAncillaryPromoApplied(fare) &&
  fare.promotion?.promotion.promoType === FARE_DISCOUNT_TYPE_PERCENTAGE;

const getDiscountDetails = (fare) => {
  const isPercentageDiscount =
    fare.fareDiscountType === FARE_DISCOUNT_TYPE_PERCENTAGE ||
    isPercentageDiscountOnAncillaryPromo(fare);

  return {
    administrationFeePrice: fare.administrationFeePrice,
    originalFlightPrice: getOriginalFlightPrice(fare),
    newFlightPrice: getNewFlightPrice(fare),
    discountType: getDiscountType(fare),
    discountRate: getDiscountRate(fare),
    saving: getSaving(fare),
    isPercentageDiscount,
  };
};

/**
 * @type {(state: State) => object}
 */
const getPayableFareLock = (state) => {
  const { amount, currencyCode } = getters.fareLockPrice(state) || {};
  const feeCode = getters.fareLockFeeCode(state);

  return {
    feeCode,

    price: {
      amount,
      currencyCode,
    },

    metadata: analyticsGetters.metadata(state),
  };
};

const isThereNotEnoughTimeBeforeSelectedReturnFlightDeparture = (state) => {
  return (
    getters.isRoundTrip(state) &&
    getters.selectedReturnFlight(state) &&
    !isThereEnoughTimeBeforeDeparture(
      getters.selectedOutboundFlight(state).arrivalDateTime,
      getters.selectedReturnFlight(state).departureDateTime
    )
  );
};

/**
 * @type {(store: Store, direction: Direction) => void}
 */
const resetSelectionByDirection = curry((store, direction) => {
  const { commit } = store;
  commit(m.REMOVE_FARE, direction); // remove selected state of flight and fare

  summaryActions.clearPayableFlightFare(store, {
    // remove fare from summary
    type: direction,
  });

  sync.resetFlightAndFare(store, direction);
});

/**
 * @type {(store: Store) => void}
 */
export const resetOutboundSelection = resetSelectionByDirection(__, DIRECTION_OUTBOUND);

/**
 * @type {(store: Store) => void}
 */
export const resetReturnSelection = resetSelectionByDirection(__, DIRECTION_RETURN);

/**
 * @type {(store: Store) => void}
 */
export const resetAllSelection = (store) => {
  [DIRECTION_OUTBOUND, DIRECTION_RETURN].forEach(resetSelectionByDirection(store));
};
