/**
 * Returns a new list or string with the elements or characters in reverse
 * order.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/reverse.js
 *
 * @type {<T>(arr: T[]) => T[]}
 * @example
 *
 *      reverse([1, 2, 3]);  //=> [3, 2, 1]
 *      reverse([1, 2]);     //=> [2, 1]
 *      reverse([1]);        //=> [1]
 *      reverse([]);         //=> []
 *
 *      reverse('abc');      //=> 'cba'
 *      reverse('ab');       //=> 'ba'
 *      reverse('a');        //=> 'a'
 *      reverse('');         //=> ''
 */
const reverse = (arr) => [...arr].reverse();

export default reverse;
