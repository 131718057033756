import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  CHECK_IN_STATUS_CHECKED_IN,
  ITINERARY_SECTION_BOOKING_DETAILS,
} from '~/constants';
import compose from '~/utils/fp/compose';
import filter from '~/utils/fp/filter';
import pick from '~/utils/fp/pick';
import pluck from '~/utils/fp/pluck';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import both from '~/utils/fp/both';
import propEq from '~/utils/fp/prop-eq';
import allPass from '~/utils/fp/all-pass';
import {
  isAdult,
  isChild,
  isNotInfant,
  isRegularAdult,
  isReducedMobility,
} from '~/utils/booking/passenger';
import propEqFalse from '~/utils/fp/prop-eq-false';
import propEqTrue from '~/utils/fp/prop-eq-true';
import omit from '~/utils/fp/omit';
import isNil from '~/utils/object/is-nil';
import isNotNil from '~/utils/object/is-not-nil';
import _isFlownFlight from '~/utils/booking/booking/is-flown-flight';
import { getDefaultPrice } from '~/utils/price';
import { formatDate, toDefaultFormat } from '~/utils/date';

export const DIVIDE_KIND_CANCELLATION = 'cancellation';
export const DIVIDE_KIND_FLIGHT_CHANGE = 'flight-change';

export const getDefaultState = () => ({
  isAddServiceVisitedOnce: false,
  isAddBagsFlow: false,
  isLoginRequiredToContinueToPayment: false,
  booking: null, // |---> this way to the rabbit hole
  currentType: null,
  currentFlight: null,
  // contains data from itinerary's passenger list
  // which is somewhat different from the one fetched from the checkin passengers endpoint
  selectedPassenger: null,
  // the one currently being checked in
  selectedCheckInPassenger: null,
  checkInPassengers: {
    // list of outbound passengers
    outbound: [],
    // list of returning passengers
    return: [],
  },
  currentCurrency: null,

  isSeatSelectionNeeded: false,
  invoices: [],
  // 'invoices' vs 'paymentDetails' in the footer accordion
  // (do not reset it in RESET_ITIN pls, it's not that interesting)
  preferredInvoiceTab: 'invoices',
  loadingCounter: 0,
  errors: [],
  hasServerError: null,
  availableFeedbackStages: [],
  activeModal: null,
  activeSection: ITINERARY_SECTION_BOOKING_DETAILS,
  isSeatSelectionModalVisible: false,
  recentlyPaidAmount: null,
  isUpgradeBundleModalVisible: false,
  isSuccessfulBookingLoaded: false,
  upsoldBookings: [],
  advertisementBanner: null,

  // Used to store the check in flow's stuff
  checkInData: {
    passengers: [],
    recentlyPurchasedItems: {
      ancillaries: [],
      services: [],
      seats: [],
    },
    documentRulesByCountries: {},
    idOfStartedFlow: null,
    isEmptyPaymentOccurred: false,
    bannedPassengerWarningSeenBy: 0,
    bannedPassengers: [],
  },

  priceDetails: {
    [OUTBOUND_FLIGHT_NAME]: {
      fromIata: '',
      toIata: '',
      fees: [],
      total: getDefaultPrice(),
    },
    [RETURN_FLIGHT_NAME]: {
      fromIata: '',
      toIata: '',
      fees: [],
      total: getDefaultPrice(),
    },
    globals: {
      fees: [],
      total: getDefaultPrice(),
    },
  },
  priceDetailsErrors: [],

  isBookingDotComModalVisible: false,

  contact: {
    errors: [],
    loadingCounter: 0,
    isUpdated: false,
  },

  isFlightChangeWarningModalShown: false,
  divideKind: '',
  isAutoCheckInSelected: false,
  displayCarbonOffsetRibbon: false,
  autoCallMethodName: '',
  selectedTabDirection: OUTBOUND_FLIGHT_NAME,
  isAddFlightExitIntentModalSeen: false,
  outboundFreeCheckinStartDate: null,
  returnFreeCheckinStartDate: null,
  isComingFromSeatModificationCI: false,
});

export const isGlobalOperationAvailable = curry((operation, state) =>
  isOperationAvailable(state, operation)
);

export const isOutboundOperationAvailable = curry((operation, state) =>
  isOperationAvailable(state, operation, OUTBOUND_FLIGHT_NAME)
);

export const isReturnOperationAvailable = curry((operation, state) =>
  isOperationAvailable(state, operation, RETURN_FLIGHT_NAME)
);

const isOperationAvailable = (state, operation, direction) =>
  (
    state.itinerary?.booking?.availableOperations?.[direction ?? 'globals'] ?? []
  ).includes(operation);

export const getStatus = (state) => state.itinerary?.booking?.itineraryStatus || '';

export const isStatus = curry((status, state) => getStatus(state) === status);

export const getFlight = (state, direction) =>
  state.itinerary?.booking?.[`${direction}Flight`] || null;

export const isFlightFlown = curry((direction, state) => {
  const flight = getFlight(state, direction);
  return _isFlownFlight(flight);
});

// if a passenger is in the checkin return/outbound array
// and it has no infant then do not show it
export const showInfant = (state, direction, passenger) => {
  const found = state.itinerary.checkInPassengers[direction].find(
    (cinPass) => cinPass.passengerNumber === passenger.passengerNumber
  );
  // infant is null if we nulled it after checkin, acc is hidden if the server sends it in getCheckin
  return !(found && (found.infant === null || found.infant.accessibility === 'hidden'));
};

export const DEFAULT_DATE_TIME_FORMAT = 'd/M/yyyy HH:mm';

export const initialSeatAssignmentsForFlight = curry((direction, state) =>
  (state.itinerary?.booking?.passengers || [])
    .filter(isNotInfant)
    .reduce((acc, passenger) => {
      const seatCode = (passenger[direction] || {}).seatUnitDesignator;
      if (seatCode) {
        acc[seatCode] = passenger.passengerNumber;
      }
      return acc;
    }, {})
);

export const paidSeatPricesForFlight = curry((direction, state) =>
  (state.itinerary?.booking?.passengers || [])
    .filter(isNotInfant)
    .reduce((acc, passenger) => {
      const seatPrice = pathOr(0, 'services.seatPrice.amount', passenger[direction]);
      acc[passenger.passengerNumber] = seatPrice;
      return acc;
    }, {})
);

export const passengerCheckInStatusesForFlight = curry((direction, state) =>
  (state.itinerary?.checkInPassengers?.[direction] || []).reduce(
    (acc, checkInPassenger) => {
      const { passengerNumber, flightCheckinStatus } = checkInPassenger;
      acc[passengerNumber] = flightCheckinStatus === CHECK_IN_STATUS_CHECKED_IN;
      return acc;
    },
    {}
  )
);

export const convertPassengersPassengersToRawPassengers = (
  passengers,
  passengersPassengers
) => {
  let redmobCount = passengersPassengers.filter(isReducedMobility).length;
  return passengersPassengers.filter(isNotInfant).map((bookingPassenger, index) => {
    const passenger = passengers[index];
    const {
      passengerNumber: id,
      firstName,
      lastName,
      gender,
      passengerType: type,
      privilegePassActivated,
      hasAppliedPrivilegePass,
    } = bookingPassenger;
    const { infant } = passenger;
    const regularAdult = isRegularAdult(bookingPassenger);
    const reducedMobility = isReducedMobility(bookingPassenger);
    const hasInfant = isNotNil(infant);
    const reducedMobilityAssistant = redmobCount > 0 && regularAdult;
    redmobCount -= reducedMobilityAssistant ? 1 : 0;
    return {
      id,
      firstName,
      lastName,
      gender,
      type,

      hasInfant,
      reducedMobility,
      reducedMobilityAssistant,
      privilegePassActivated,
      hasAppliedPrivilegePass,
    };
  });
};

export const currentFlow = (state) => state.itinerary?.currentFlow;

export const isServiceSelected = (state, serviceName, payableServices) =>
  (state.itinerary?.booking?.services?.outbound || []).some(
    both(propEq('name', serviceName), propEq('selected', true))
  ) || payableServices.some(propEq('name', serviceName));

export const isServiceSelectedOnFlight = (
  state,
  serviceName,
  direction,
  payableServices
) =>
  (state.itinerary?.booking?.services?.[direction] || []).some(
    allPass(propEq('name', serviceName), propEq('selected', true))
  ) ||
  payableServices.some(both(propEq('name', serviceName), propEq('flight', direction)));

export const selectedOptionForService = (state, serviceName, payableServices) =>
  (state.itinerary?.booking?.services?.outbound || []).find(propEq('name', serviceName))
    .selected || payableServices.find(propEq('name', serviceName)).selected;

export const selectedOptionForServiceOnFlight = (
  state,
  serviceName,
  direction,
  payableServices
) =>
  (state.itinerary?.booking?.services?.[direction] || []).find(
    propEq('name', serviceName)
  ).selected ||
  payableServices.find(both(propEq('name', serviceName), propEq('flight', direction)))
    .selected;

export const passengersCountByType = (state) =>
  (state.itinerary?.booking?.passengers || []).reduce(
    (acc, passenger) => {
      acc.adults += isAdult(passenger) ? 1 : 0;
      acc.children += isChild(passenger) ? 1 : 0;
      acc.infants += passenger.infant ? 1 : 0;
      return acc;
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    }
  );

export const flightDatesByDirection = (state, dateFormat) =>
  [OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME].reduce(
    (acc, direction) => {
      const flight = state.itinerary?.booking?.[`${direction}Flight`];
      if (!flight) return acc;

      acc[direction].departureDate = formatDate(dateFormat, flight.departureDate);
      acc[direction].arrivalDate = formatDate(dateFormat, flight.arrivalDate);
      return acc;
    },
    {
      [OUTBOUND_FLIGHT_NAME]: {
        departureDate: '',
        arrivalDate: '',
      },
      [RETURN_FLIGHT_NAME]: {
        departureDate: '',
        arrivalDate: '',
      },
    }
  );

export const getCheckInPassengerDetails = (passenger) => {
  const flightCheckIn = passenger.flightCheckin;
  const ifRequired = (name) => {
    const obj = flightCheckIn[name];
    return obj && obj.accessibility === 'required' ? obj.value : 'hidden';
  };

  const optional = (name) => {
    const obj = flightCheckIn[name];
    return obj && obj.accessibility === 'required' ? obj.value : '';
  };

  const bioPassNationalities =
    passenger.biometricPassportCanReplaceVisaOrResidencePermitNationalities;
  const passengerNationality = passenger.nationality.value
    ? passenger.nationality.value
    : null;

  return {
    firstName: passenger.firstName.value,
    lastName: passenger.lastName.value,
    passengerType: passenger.passengerType,
    gender: passenger.gender,
    dateOfBirth: passenger.dateOfBirth.value
      ? toDefaultFormat(passenger.dateOfBirth.value)
      : null,
    nationality: passengerNationality,
    seatUnitDesignator: passenger.seatUnitDesignator,
    htmlBoardingCardEnabled: passenger.htmlBoardingCardEnabled,
    pdfBoardingCardEnabled: passenger.pdfBoardingCardEnabled,
    passbookEnabled: passenger.passbookEnabled,
    needDateOfBirth: passenger.needDateOfBirth,
    checkInDenied: passenger.checkInDenied,
    mobilePhoneNumber: passenger.mobilePhoneNumber,
    isSaveToProfileVisible: passenger.isSaveToProfileVisible,
    saveMobilePhoneToProfile: passenger.saveMobilePhoneToProfile,
    isDifferentNationalityAccepted: null,
    // travel doc
    saveTravelDoc: ifRequired('saveTravelDoc'),
    docType:
      ifRequired('docNumber') !== null && ifRequired('docType') !== null
        ? ifRequired('docType')
        : null,
    docNumber: ifRequired('docNumber'),
    docIssuedCountry: ifRequired('docIssuedCountry'),
    docIssuedDate: ifRequired('docIssuedDate'),
    docExpirationDate: ifRequired('docExpirationDate'),
    docDoesNotExpire: ifRequired('docDoesNotExpire'),
    // visa and residence permit section
    residencePermitNumber: optional('residencePermitNumber'),
    residencePermitIssuingCountry: optional('residencePermitIssuingCountry'),
    residencePermitExpirationDate: optional('residencePermitExpirationDate'),
    residencePermitDoesNotExpire: optional('residencePermitDoesNotExpire'),
    visaNumber: optional('visaNumber'),
    visaIssuingCountry: optional('visaIssuingCountry'),
    biometricNationalities: bioPassNationalities,
    visaOrResidencePermitRequiredNationalities:
      passenger.visaOrResidencePermitRequiredNationalities,
    isTravelDocumentRequired: passenger.isTravelDocumentRequired,
    isPassportBiometric: bioPassNationalities.includes(passengerNationality)
      ? false
      : 'hidden',
  };
};

export const getCheckInData = (passengers) => ({
  checkinPassengers: [...passengers].map((passenger) => {
    const shouldDeleteResidenceKeys = isNil(passenger.residencePermitNumber);
    const shouldDeleteVisaKeys = isNil(passenger.visaNumber);
    let pax = passenger;

    if (shouldDeleteResidenceKeys) {
      pax = omit(
        [
          'residencePermitDoesNotExpire',
          'residencePermitExpirationDate',
          'residencePermitIssuingCountry',
          'residencePermitNumber',
        ],
        pax
      );
    }

    if (shouldDeleteVisaKeys) {
      pax = omit(['visaIssuingCountry', 'visaNumber'], pax);
    }

    Object.entries(pax)
      .filter(([key, value]) => value === 'hidden')
      .forEach(([key]) => delete pax[key]);

    return pax;
  }),
});

export const formatBannedPassengers = compose(
  pluck('passengerNumber'),
  filter(propEqTrue('checkInDenied'))
);

export const formatValidationPassengers = (passengers) =>
  passengers
    .filter(isNotInfant)
    .filter(propEqFalse('checkInDenied'))
    .map((passenger) => ({
      ...pick(['passengerNumber', 'lastName', 'firstName', 'dateOfBirth'], passenger),
    }));

export const resetState = (state) => Object.assign(state, getDefaultState());
