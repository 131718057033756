/**
 * A function that does nothing but return the parameter supplied to it. Good
 * as a default or placeholder function.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/identity.js
 *
 * @type {<T>(a: T) => T}
 * @example
 *
 *      identity(1); //=> 1
 *
 *      let obj = {};
 *      identity(obj) === obj; //=> true
 */
const identity = (x) => x;

export default identity;
