import log from 'loglevel';
import nanoid from 'nanoid';
import * as configcat from 'configcat-js';
import { getCookie, setCookie } from '~/utils/storage';
import { CustomConfigCatCache } from './custom-cache';

let configCatClient = null;

export const getConfigCatClient = () => {
  if (!configCatClient) log.warn(`Please don't forget to init configCat before usage.`);
  return configCatClient;
};

export const init = () => {
  try {
    // Manual Polling mode is used to avoid unnecessary requests, we only want to poll once per session
    // which is done in the featureActions.fetchAndTrackConfigCatFeatures action
    configCatClient = configcat.getClient(
      window.wizz.configCatSdkKey,
      configcat.PollingMode.ManualPoll,
      {
        defaultUser: getConfigCatDefaultUser(),
        cache: new CustomConfigCatCache(),
      }
    );
  } catch (error) {
    log.error('ConfigCat client initialization failed', error);
  }
};

const getConfigCatDefaultUser = () => {
  let userIdentifier = getCookie('config-cat-id');

  if (!userIdentifier) {
    userIdentifier = nanoid();
    setCookie('config-cat-id', userIdentifier, {
      maxAge: 31536000, // 1 year
      path: '/',
    });
  }

  return new configcat.User(userIdentifier);
};
