import toSameKeyValueObj from '~/utils/fp/to-same-key-value-obj';

export const moduleName = 'flightSelect';

export const Operation = Object.freeze(
  toSameKeyValueObj([
    'submit',
    'showReturnFlights',
    'initialize',
    'onLogin',
    'toggleFareType',
    'showNextAvailableFlight',
    'fetchMoreFlightDates',
    'changeFlight',
    'toggleFareLock',
    'selectFare',
    'toggleWizzFlex',
    'setWizzFlex',
    'selectOutboundFlightDate',
    'selectReturnFlightDate',
    'selectNextOutboundFlightDate',
    'selectPreviousOutboundFlightDate',
    'selectNextReturnFlightDate',
    'selectPreviousReturnFlightDate',
    'initPassengers',
  ])
);

export const ErrorType = Object.freeze({
  ...toSameKeyValueObj(['getFlights']),
  ...Operation,
});

export const LoadingCounter = Object.freeze({
  getFlights: 'flights',
  ...toSameKeyValueObj(['datePicker']),
  ...Operation,
});
