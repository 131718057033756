import {
  format as _format,
  isWithinInterval,
  add as _add,
  sub as _sub,
  isValid,
  isEqual as _isEqual,
  isSameDay as _isSameDay,
  isSameMonth as _isSameMonth,
  isSameYear as _isSameYear,
  getYear as _getYear,
  getMonth as _getMonth,
  getDay as _getDay,
  getHours as _getHours,
  getMinutes as _getMinutes,
  getISODay as _getISODay,
  getDate as _getDate,
  setYear as _setYear,
  setMonth as _setMonth,
  setDay as _setDay,
  setHours as _setHours,
  setMinutes as _setMinutes,
  setSeconds as _setSeconds,
  startOfHour as _startOfHour,
  startOfDay as _startOfDay,
  startOfMonth as _startOfMonth,
  startOfYear as _startOfYear,
  endOfDay as _endOfDay,
  endOfMonth as _endOfMonth,
  endOfYear as _endOfYear,
  differenceInSeconds as _differenceInSeconds,
  differenceInMinutes as _differenceInMinutes,
  differenceInHours as _differenceInHours,
  differenceInDays as _differenceInDays,
  differenceInMonths as _differenceInMonths,
  differenceInYears as _differenceInYears,
  isBefore as _isBefore,
  isAfter as _isAfter,
  intervalToDuration as _intervalToDuration,
  formatDuration as _formatDuration,
  parseISO as _parseISO,
  toDate as _toDate,
} from 'date-fns';
import {
  enGB,
  hu,
  bg,
  bs,
  cs,
  de,
  es,
  ca,
  el,
  fr,
  ka,
  he,
  it,
  lv,
  lt,
  mk,
  nl,
  nb,
  pl,
  ro,
  pt,
  ru,
  sk,
  sq,
  sv,
  sr,
  uk,
  ar,
} from 'date-fns/locale';
import { LABEL_DURATION_H, LABEL_DURATION_M } from '~/constants';
import { getI18n } from '~/i18n';
import curry from '~/utils/fp/curry';
import complement from '~/utils/fp/complement';
import isDate from '~/utils/object/is-date';
import isString from '~/utils/object/is-string';
import isNotString from '~/utils/object/is-not-string';
import isNumber from '~/utils/object/is-number';
import { DEFAULT_DATE_FORMAT, DEFAULT_LONG_DATE_FORMAT } from '~/constants/date';
import isNil from '~/utils/object/is-nil';
import abs from '~/utils/fp/abs';
import { getLocale } from '~/utils/localization';
import { getLocalization } from './locales';

const SHORT_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const SHORT_DATE_WITH_DOT_FORMAT_REGEX = /^\d{4}.\d{2}.\d{2}$/;

const LONG_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
const LONG_DATE_WITH_DOT_FORMAT_REGEX = /^\d{4}.\d{2}.\d{2}T\d{2}:\d{2}:\d{2}$/;

const localeMap = {
  'en-gb': enGB,
  'hu-hu': hu,
  'bg-bg': bg,
  'bs-latn-ba': bs,
  'cs-cz': cs,
  'de-de': de,
  'es-es': es,
  'ca-es': ca,
  'el-gr': el,
  'fr-fr': fr,
  'ka-ge': ka,
  'he-il': he,
  'it-it': it,
  'lv-lv': lv,
  'lt-lt': lt,
  'mk-mk': mk,
  'nl-nl': nl,
  'nb-no': nb,
  'pl-pl': pl,
  'ro-ro': ro,
  'pt-pt': pt,
  'ru-ru': ru,
  'sk-sk': sk,
  'sq-al': sq,
  'sv-se': sv,
  'sr-cyrl-cs': sr,
  'uk-ua': uk,
  'ar-ae': ar,
};

const format = (format, date) => {
  return _format(toDate(date), format, { locale: getLocalization(getLocale()) });
};

const getDateFnsLocale = (locale) => {
  return localeMap[locale] ?? enGB;
};

export const getFormattedDate = (dateString, locale) => {
  return _format(_parseISO(dateString), 'MMM d, yyyy, HH:mm', {
    locale: getDateFnsLocale(locale),
  });
};

export const toDefaultFormat = (date) => format(DEFAULT_DATE_FORMAT, date);

export const currentDateAndTime = () => {
  return format(DEFAULT_LONG_DATE_FORMAT, new Date());
};

export const formatDate = (format, date) => {
  if (isInvalidDate(date)) return '';
  const locale = getLocalization(getLocale());
  return _format(toDate(date), format, { locale });
};

export const isBetween = (firstDate, secondDate, date) =>
  isWithinInterval(toDate(date), {
    start: toDate(firstDate),
    end: toDate(secondDate),
  });

const isSameWith = (granularity) => (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  const granularities = {
    equal: _isEqual,
    day: _isSameDay,
    month: _isSameMonth,
    year: _isSameYear,
  };
  return granularities[granularity](toDate(firstDate), toDate(secondDate));
};

export const isExactlyTheSame = isSameWith('equal');

export const isSameDay = isSameWith('day');

export const isSameMonth = isSameWith('month');

export const isSameYear = isSameWith('year');

export const isSameOrAfter = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return isExactlyTheSame(firstDate, secondDate) || isAfter(firstDate, secondDate);
};

const isAfterDate = (granularity) => (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  if (granularity === 'day') {
    firstDate = startOfDay(firstDate);
    secondDate = endOfDay(secondDate);
  }
  if (granularity === 'month') {
    firstDate = startOfMonth(firstDate);
    secondDate = endOfMonth(secondDate);
  }
  return _isAfter(toDate(firstDate), toDate(secondDate));
};

export const isAfter = isAfterDate('');

export const isDayAfter = isAfterDate('day');

export const isMonthAfter = isAfterDate('month');

const isBeforeDate = (granularity) => (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  if (granularity === 'day') {
    firstDate = endOfDay(firstDate);
    secondDate = startOfDay(secondDate);
  }
  return _isBefore(toDate(firstDate), toDate(secondDate));
};

export const isBefore = isBeforeDate('');

export const isDayBefore = isBeforeDate('day');

export const differenceInYears = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInYears(toDate(firstDate), toDate(secondDate));
};

export const differenceInMonths = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInMonths(toDate(firstDate), toDate(secondDate));
};

export const differenceInDays = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInDays(toDate(firstDate), toDate(secondDate));
};

export const differenceInHours = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInHours(toDate(firstDate), toDate(secondDate));
};

export const differenceInMinutes = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInMinutes(toDate(firstDate), toDate(secondDate));
};

export const differenceInSeconds = (firstDate, secondDate) => {
  if (isInvalidDate(firstDate) || isInvalidDate(secondDate)) return '';
  return _differenceInSeconds(toDate(firstDate), toDate(secondDate));
};

const startOf = (unit) => (date) => {
  const getPartOfTimeMap = {
    year: _startOfYear,
    month: _startOfMonth,
    day: _startOfDay,
    hour: _startOfHour,
  };
  return format(DEFAULT_LONG_DATE_FORMAT, getPartOfTimeMap[unit](toDate(date)));
};

export const startOfHour = startOf('hour');

export const startOfDay = startOf('day');

export const startOfMonth = startOf('month');

export const startOfCurrentYear = () => startOf('year')(currentDateAndTime());

const endOf = (unit) => (date) => {
  const getPartOfTimeMap = {
    year: _endOfYear,
    month: _endOfMonth,
    day: _endOfDay,
  };
  return format(DEFAULT_LONG_DATE_FORMAT, getPartOfTimeMap[unit](toDate(date)));
};

export const endOfDay = endOf('day');

export const endOfMonth = endOf('month');

export const endOfYear = endOf('year');

export const endOfCurrentYear = () => endOf('year')(currentDateAndTime());

const add = (granularity) =>
  curry((value, date) => {
    const options = {};
    options[granularity] = value;
    date = _add(toDate(date), options);
    return format(DEFAULT_LONG_DATE_FORMAT, date);
  });

export const addYears = add('years');

export const addMonths = add('months');

export const addDays = add('days');

export const addHours = add('hours');

export const addMinutes = add('minutes');

export const addSeconds = add('seconds');

const subtract = (granularity) =>
  curry((value, date) => {
    const options = {};
    options[granularity] = value;
    date = _sub(toDate(date), options);
    return format(DEFAULT_LONG_DATE_FORMAT, date);
  });

export const subYears = subtract('years');

export const subMonths = subtract('months');

export const subDays = subtract('days');

export const subHours = subtract('hours');

const get = (method) => (date) => {
  const getPartOfTimeMap = {
    year: _getYear,
    month: _getMonth,
    day: _getDay,
    hour: _getHours,
    minute: _getMinutes,
    isoWeekday: _getISODay,
    date: _getDate,
  };
  return getPartOfTimeMap[method](toDate(date));
};

export const getYear = get('year');

export const getMonth = get('month');

export const getDay = get('day');

export const getHour = get('hour');

export const getMinute = get('minute');

export const getIsoWeekDay = get('isoWeekday');

export const getDate = get('date');

export const getDuration = (date) => {
  const times = {
    milliseconds: 0,
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
    years: 0,
  };
  if (!date) return times;
  const isNegative = date.startsWith('-');
  const getValue = (item) => {
    if (isNil(item)) return 0;
    return Number(`${isNegative ? '-' : ''}${item}`);
  };
  if (isNegative) {
    date = date.slice(1);
  }
  const segments = date.split(':');
  const setSecondsAndMilliseconds = () => {
    let segment = segments.pop();
    segment = segment.split('.');
    times.milliseconds = getValue(includesDot(date) ? segment.pop() : 0);
    times.seconds = getValue(segment.pop());
  };
  setSecondsAndMilliseconds();
  times.minutes = getValue(segments.pop());
  times.hours = getValue(segments.pop());
  times.days = getValue(segments.pop());
  times.months = getValue(segments.pop());
  times.years = getValue(segments.pop());
  return times;
};

const includesDot = (str) => str.includes('.');

const set = (method) => (value, date) => {
  const setPartOfTimeMap = {
    year: _setYear,
    month: _setMonth,
    day: _setDay,
    hour: _setHours,
    minute: _setMinutes,
    second: _setSeconds,
  };
  return format(DEFAULT_LONG_DATE_FORMAT, setPartOfTimeMap[method](toDate(date), value));
};

export const setYear = set('year');

export const setMonth = set('month');

export const setHour = set('hour');

export const setMinute = set('minute');

export const setSecond = set('second');

export const getUtcOffset = (time) => {
  if (!time) return '';
  const duration = getDuration(time);
  const hours = Number(duration.hours);
  const minutes = Number(duration.minutes);
  const isHoursZero = abs(hours) === 0;
  const isMinutesZero = abs(minutes) === 0;

  if (isHoursZero && isMinutesZero) return '';

  const prefix = hours < 0 || minutes < 0 ? '-' : '+';
  return `${prefix}${abs(hours)}${isMinutesZero ? '' : `:${abs(minutes)}`}`;
};

export const formatDuration = (duration) => {
  const d = getDuration(duration);
  const i18n = getI18n();
  return `${d.hours}${i18n.t(LABEL_DURATION_H)} ${d.minutes}${i18n.t(LABEL_DURATION_M)}`;
};

export const formatDiffDuration = (date1, date2) => {
  const d = _intervalToDuration({
    start: toDate(date1),
    end: toDate(date2),
  });
  const i18n = getI18n();
  return `${d.hours}${i18n.t(LABEL_DURATION_H)} ${d.minutes}${i18n.t(LABEL_DURATION_M)}`;
};

export const formatDurationHoursMinutes = (hours, minutes) => {
  return _formatDuration({ hours, minutes }, { locale: getLocalization(getLocale()) });
};

/**
 * @type {(start: Date, end: Date) => Duration}
 */
export const intervalToDuration = (start, end) =>
  _intervalToDuration({
    start: toDate(start),
    end: toDate(end),
  });

/**
 * @type {(date: Date | string | number) => Date}
 */
export const toDate = (date) => {
  const realDate = toDateNoValidation(date);
  if (isInvalidDate(realDate))
    throw new Error(`Date is missing or value is dirty: ${date}`);
  return realDate;
};

/**
 * @type {(date: Date | string | number) => boolean}
 */
export const isValidDate = (date) => {
  if (!date) return false;
  return isValid(toDateNoValidation(date));
};

export const isInvalidDate = complement(isValidDate);

/**
 * @type {(date: Date | string | number) => Date}
 */
const toDateNoValidation = (date) => {
  if (isDate(date) || isNumber(date)) return _toDate(date);
  if (isString(date)) return _parseISO(normalize(date));
  // here we want an invalid date to be returned
  return new Date('NOK');
};

/**
 * note: date only date strings will be parsed as UTC, otherwise if there is
 * timezone info parsing will use that if not it will be parsed as local time
 *
 * @type {(date: string) => string}
 */
const normalize = (dateStr) => {
  if (isNotString(dateStr)) return dateStr;
  // todo: might not be needed anymore?
  // note: sometimes an error happens if pikaday is using YYYY.MM.DD instead of YYYY-MM-DD
  dateStr = SHORT_DATE_WITH_DOT_FORMAT_REGEX.test(dateStr)
    ? dateStr.replaceAll('.', '-')
    : dateStr;

  return hasNoTimePart(dateStr) ? `${dateStr}Z` : dateStr;
};

/**
 * https://en.wikipedia.org/wiki/ISO_8601
 *
 * @type {(dateStr: string) => boolean}
 */
const hasTimePart = (dateStr) => {
  return /T\d{2}/.test(dateStr);
};

const hasNoTimePart = complement(hasTimePart);

export const convertShortDateToLocal = (shortDateStr) => {
  if (!isShortDate(shortDateStr)) return shortDateStr;

  shortDateStr = SHORT_DATE_WITH_DOT_FORMAT_REGEX.test(shortDateStr)
    ? shortDateStr.replaceAll('.', '-')
    : shortDateStr;

  return `${shortDateStr}T00:00:00`;
};

export const isShortDate = (dateStr) => {
  if (SHORT_DATE_WITH_DOT_FORMAT_REGEX.test(dateStr)) {
    dateStr = dateStr.replaceAll('.', '-');
  }
  return SHORT_DATE_FORMAT_REGEX.test(dateStr);
};

export const isLongDate = (dateStr) => {
  if (LONG_DATE_WITH_DOT_FORMAT_REGEX.test(dateStr)) {
    dateStr = dateStr.replaceAll('.', '-');
  }
  return LONG_DATE_FORMAT_REGEX.test(dateStr);
};

export const isWithin48hours = (dateStr) => {
  const twoDaysBefore = subDays(2, dateStr);
  return isBefore(twoDaysBefore, currentDateAndTime());
};

export const isOlderThanXDays = (dateStr, numberOfDays) => {
  const xDaysBefore = subDays(numberOfDays, currentDateAndTime());
  return isBefore(dateStr, xDaysBefore);
};
