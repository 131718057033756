import * as m from './mutation-types';

export default {
  [m.SET_STATIC_ASSET_URL](state, payload) {
    state.staticAssetUrl = payload;
  },

  [m.SET_LANGUAGE](state, payload) {
    state.language = payload;
  },

  [m.SET_GLOBAL_URLS](state, payload) {
    state.urls = payload;
  },

  [m.RESET_GLOBAL_URLS](state) {
    state.urls = [];
  },

  [m.SET_GLOBAL_FILES](state, payload) {
    state.files = payload;
  },

  [m.RESET_GLOBAL_FILES](state) {
    state.files = [];
  },

  [m.SET_GLOBAL_WIZZ](state, payload) {
    state.globalWizz = payload;
  },

  [m.SET_ASSET_MANIFEST](state, payload) {
    state.assetManifest = payload;
  },

  [m.SET_CULTURES](state, payload) {
    state.cultures = payload;
  },

  [m.SET_PAGE](state, payload) {
    state.page = payload;
  },

  [m.SET_IS_RTL](state, payload) {
    state.isRtl = payload;
  },

  [m.SET_STORED_RESOURCE_NAMES](state, payload) {
    state.storedResourceNames = payload;
  },

  [m.RESET_STORED_RESOURCE_NAMES](state) {
    state.storedResourceNames = {};
  },

  [m.SET_RESOURCE_SCRIPTS](state, payload) {
    state.resourceScripts = payload;
  },

  [m.RESET_RESOURCE_SCRIPTS](state) {
    state.resourceScripts = '';
  },

  [m.SET_BANNER_URLS](state, payload) {
    state.bannerUrls = payload;
  },

  [m.SET_HOME_PAGE_BANNERS_DATA](state, payload) {
    state.homePageBannersData = payload;
  },
};
