import { DEFAULT_LANGUAGE_CODE } from '~/store/modules/emergency/constants';

export const getDefaultState = () => ({
  activeBioArticle: null,
  informationAndServicesList: [],
  topFlightsList: [],
  pageContent: null,
  currentPage: {
    url: '',
    // eslint-disable-next-line no-undef
    cultureCode: global?.CULTURE_CODE ?? DEFAULT_LANGUAGE_CODE,
  },
  destinationContent: null,
  news: {
    items: [],
    cultureCode: null,
  },
  newsContent: null,
  pageTranslationLinks: null,
  footerNavigationItems: [],
  helpCenterSideNavigationItems: [],
});
