// Pinterest Send Event parameters
export const PINTEREST_EVENT_CATEGORY_PAGE_VISIT = 'PageVisit';
export const PINTEREST_EVENT_CATEGORY_ADD_TO_CART = 'AddToCart';
export const PINTEREST_EVENT_CATEGORY_PURCHASE = 'Checkout';
export const PINTEREST_EVENT_CATEGORY_SEARCH = 'Search';

// TikTok Send Event parameters
export const TIKTOK_EVENT_CATEGORY_SEARCH = 'Search';
export const TIKTOK_EVENT_CATEGORY_VIEW_CONTENT = 'ViewContent';
export const TIKTOK_EVENT_CATEGORY_ADD_TO_CART = 'AddToCart';
export const TIKTOK_EVENT_CATEGORY_PURCHASE = 'Purchase';
