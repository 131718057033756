export const RESET_ERRORS = 'checkIn/resetErrors';
export const SET_ERRORS = 'checkIn/setErrors';
export const SET_IS_AUTO_CHECK_IN = 'checkIn/setIsAutoCheckIn';
export const RESET_CHECK_IN_STORE = 'checkIn/resetCheckInStore';
export const SET_STEPS = 'checkIn/setSteps';
export const SET_FLIGHT = 'checkIn/setFlight';
export const SET_CURRENT_STEP = 'checkIn/setCurrentStep';
export const SET_BOOKING_DETAILS = 'checkIn/setBookingDetails';
export const SET_ALREADY_PAYED_STATUS = 'checkIn/setAlreadyPaidStatus';
export const SET_WARNING_STEP = 'checkIn/setWarningStep';
export const SET_PASSENGERS_STEP = 'checkIn/setPassengersStep';
export const SET_PASSENGERS = 'checkIn/setPassengers';
export const SET_CHANGES_TO_ACCEPT_STEP = 'checkIn/setChangesToAcceptStep';
export const SET_CHANGES_TO_ACCEPT_STEP_VALIDITY =
  'checkIn/setChangesToAcceptStepValidity';
export const SET_SELECTED_PASSENGERS = 'checkIn/setSelectedPassengers';
export const SET_SELECTED_PASSENGERS_NATIONALITY =
  'checkIn/setSelectedPassengersNationality';
export const SET_PASSENGER_STEP_VALIDITY = 'checkIn/setPassengerStepValidity';
export const SET_PASSENGER_STEP_ERRORS = 'checkIn/setPassengerStepErrors';
export const SET_CHANGES_TO_ACCEPT_STEP_ERRORS = 'checkIn/setChangesToAcceptStepErrors';
export const SET_RECENTLY_PURCHASED_ITEMS = 'checkIn/setRecentlyPurchasedItems';
export const SET_RECENTLY_PAID_AMOUNT = 'checkIn/setRecentlyPaidAmount';
export const SET_EMPTY_PAYMENT_OCCURRED_STATUS = 'checkIn/setEmptyPaymentOccurredStatus';
export const REMOVE_CHECK_IN_PASSENGERS = 'checkIn/removeCheckInPassenger';
export const DISABLE_EDITABLE_STEPS_BEFORE_PAYMENT_STEP =
  'checkIn/disableEditableStepsBeforePaymentStep';
export const DISABLE_EDITABLE_STEPS = 'checkIn/disableEditableSteps';
export const SET_CONTACT_DATA_STEP_ERRORS = 'checkIn/setContactDataStepErrors';
export const SET_CONTACT_DATA_STEP = 'checkIn/setContactDataStep';
export const UPDATE_PASSENGER_CONTACT_DATA = 'checkIn/updatePassengerContactData';
export const SET_CHECK_IN_POST_ERRORS = 'checkIn/setCheckInPostErrors';
export const SET_TRAVEL_DOCUMENTS_STEP = 'checkIn/setTravelDocumentsStep';
export const SET_TRAVEL_DOCUMENTS_STEP_ERRORS = 'checkIn/setTravelDocumentsStepErrors';

export const SET_TRAVEL_DOCUMENTS_VALIDATION_MESSAGES =
  'checkIn/setTravelDocumentValidationMessages';

export const REMOVE_PASSENGER_TRAVEL_DOCUMENT_STEP_ERRORS =
  'checkIn/removePassengerTravelDocumentStepErrors';
export const RESET_CONTACT_DATA_STEP_PASSENGER_ERRORS =
  'checkIn/resetContactDataStepPassengerErrors';
export const SET_CONTACT_DATA_STEP_PASSENGER_ERRORS =
  'checkIn/setContactDataStepPassengerErrors';
export const RESET_CONTACT_DATA_STEP_ERRORS = 'checkIn/resetContactDataStepErrors';
export const SET_PAYMENT_FAILED_STATUS = 'checkIn/setPaymentFailedStatus';
export const UPDATE_PASSENGER_TRAVEL_DOCUMENT_PROP =
  'checkIn/updatePassengerTravelDocumentProp';
export const SET_AVAILABLE_OPERATIONS = 'checkIn/setAvailableOperations';
export const SET_ALL_DONE_STEP = 'checkIn/setAllDoneStep';
export const SET_ALL_DONE_STEP_ERRORS = 'checkIn/setAllDoneStepErrors';
export const RESET_AVAILABLE_FEEDBACK_STAGES = 'checkIn/resetAvailableFeedbackStages';
export const SET_CHECK_IN_PARAMS = 'checkIn/setCheckInParams';
export const SET_CHECK_IN_PARAMS_ERRORS = 'checkIn/setCheckInParamsErrors';
