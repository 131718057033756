import Vue from 'vue';
import mitt from 'mitt';
import isEmpty from './object/is-empty';

// list events here, later on we may move these to a standalone file
// use underscores with camelcase
const events = [
  'test',
  // ie has troubles with the hashchange event
  'browser_fakeHashChangeEvent',
  // another tab has been opened
  'browser_otherTabOpen',
  'flightSearch_stationSelected',
  'flightSearch_stationFieldFocused', // payload: state
  'flightSearch_passengersFieldFocused', // payload: state
  'flightSearch_findStation',
  /**
   * Itinerary and add services things
   */
  'showAddServicesFooter',
  // checkin upsell
  'addServicesModal_show',
  'itinerary_trackServiceDetails',
  'itinerary_showEditGroupPassengersModal',
  'itinerary_initAddServices',
  'itinerary_checkout',
  'itinerary_changeSeats',
  'itinerary_softReload',
  'itinerary_reload',
  'itinerary_changeFlight',
  'itinerary_addBags',
  'itinerary_rebook_showSeatMap',
  'itinerary_showUploadInstructionModal',
  'itinerary_checkIn_autoSeatAssign',
  'itinerary_itinerarySuccessfullyInitialized',
  'itinerary_startCheckInFlow',
  'itinerary_addAutoCheckinService',
  /**
   * global login, logout events
   * interactive logout means the user asked for a logout (so it's not a session timeout)
   */
  'user_beforeInteractiveLogout', // right before the store's are flushed!
  'user_afterLogin', // login without get profile would be useless
  'user_afterLoginWelcomeMessage', // after the welcome dialog went away or closed
  'user_afterNewsletterConsentModal', // after the newsletter consent dialog was closed
  'user_loginAfterResetPassword', // login after password reset
  /**
   * Summary
   * (I got tired of the pingpong)
   */
  'summary_highlight',
  'summary_paymentCurrencyChanged',
  /**
   * Fare finder events
   */
  'fareFinder_offsetMonth', // month pager clicked
  'fareFinder_selectFlight', // item selected in the calendar
  'fareFinder_invalidIata', // invalid iata code from queryparams
  'fareFinder_removeFlight', // close flight card in sidebar
  'fareFinder_startSearchTopmost', // search started in the topmost component
  'fareFinder_setTimeTable', // timetable button clicked in the calendar
  'fareFinder_startBooking', // sidebar start booking button pressed
  'fareFinder_mobileUiStateChange', // set the mobile ui state number/level
  'fareFinder_isCommonLoadingChange',
  /**
   * Fonts are ready to access (web font loader)
   */
  'webFontLoader_active',
  /**
   * Flight map
   */
  'flightMap_enableAutoProgression', // enables jumping from one input to another in the Flight search component
  'flightMap_disableAutoProgression', // disables jumping from one input to another in the Flight search component
  /**
   * Booking flow
   */
  'bookingFlow_redirectToItinerary',
  'bookingFlow_triggerInsuranceValidation',
  'bookingFlow_payment_changeIsCreditCardValid',
  'bookingFlow_payment_changeIsStoredCreditCardValid',
  'bookingFlow_setCreditCardFirstTimeEdit',
  'bookingFlow_passengers_showAddTheSameToAllPassengersModal',
  'bookingFlow_passengers_showPrivilegePassModal',
  'bookingFlow_passengers_startPassengerLoading',
  'bookingFlow_passengers_stopPassengerLoading',
  'bookingFlow_passengers_removePrivilegePass',
  'bookingFlow_passengers_setDefaultProperties',
  'bookingFlow_passengers_triggerSubmit',
  'bookingFlow_services_insuranceValidation',
  'bookingFlow_services_serviceValidation',
  /**
   * Reduced mobility modal
   */
  'passengers_showReducedMobilityModal',
  'passengers_hideReducedMobilityModal',
  /**
   * Flight Change
   */
  'flightChange_restart',
  /**
   * Gift Voucher Selector
   */
  'giftVoucherSelector_triggerValidation',
  'giftVoucherSelector_voucherCurrencyChange',
  'giftVoucherSelector_paymentCurrencyChange',
  'giftVoucherSelector_toggleSummaryOnMobileVisibility',
  'giftVoucherSelector_payment_changeIsStoredCreditCardValid',
  'giftVoucherSelector_payment_changeIsCreditCardValid',
  'giftVoucherSelector_payment_tryCardPaymentAgain',
  'giftVoucherSelector_payment_triggerCreditCardValidation',
  'giftVoucherSelector_payment_triggerBillingValidation',
  'giftVoucherSelector_payment_triggerPaymentScroll',
  /**
   *  Gift Voucher Itinerary
   */
  'giftVoucherItinerary_showContactInformationModal',
  'giftVoucherItinerary_showEmailModal',
  'giftVoucherItinerary_showPaymentDetailsModal',
  /**
   * Prevent Click Directive
   */
  'preventClick_preventOverlayClick',
  /**
   * Seat Selection Base
   */
  'seatSelectionBase_showInfantChildInLastRowModal',
  'seatSelectionBase_showOneInfantPerRowSideModal',
  'seatSelectionBase_showReducedMobilitySeatingModal',
  'seatSelectionBase_showSeatTooltip',
  'seatSelectionBase_showOfferSameSeatsOnReturnFlightModal',
  'seatSelectionBase_postAncillaries',
  /**
   * Seat Selection Step
   */
  'seatSelectionStep_showDidNotSelectAnySeatsInfoModal',
  'seatSelectionStep_showDidNotSelectAllSeatsInfoModal',
  'seatSelectionStep_showExtraLegroomSeatsUnavailableModal',
  /**
   * Seat Selection Modal
   */
  'seatSelectionModal_show',
  'seatSelectionModal_close',
  'seatSelectionModal_hidden',
  'seatSelectionModal_showExitIntentModal',
  /**
   * Trip Planner
   */
  'tripPlanner_hideAllOptions',
  'tripPlanner_optionsHidden',
  /**
   * Overload modal
   */
  'showOverloadModal',
  'hideOverloadModal',
  /**
   * Carousel
   */
  'carousel_updateHover',
  /**
   * Inline Carousel
   */
  'inlineCarousel_updateHover',
  'activateCarouselSlide',
  /**
   * Perfect Scrollbar
   *
   * Used by active-element-scrolltop and perfect-scrollbar directives
   */
  'perfectScroll_activeItem',
  /**
   * Dropdown events
   */
  'dropdown_insuranceOneWayOption',
  'dropdown_insuranceOneWay',
  /**
   * Check in flow
   */
  'checkInFlow_showPassengersWithoutSeatsOutside48HoursModal',
  'checkInFlow_showPassengersWithoutSeatsWithin48HoursModal',
  'checkInFlow_showCheckInNotAvailableModal',
  /**
   * Chatbot
   * chatbot opened
   */
  'chatbot_opened',
  /**
   * Claims
   * open chatbot
   */
  'open_chatbot',
];

// not the best solution, but better than nothing
export const EVENTS = events.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

const eventBus = mitt();

/**
 * Trigger an event. 1 additional argument will be passed into the listener’s
 * callback function.
 *
 * @param {String} name
 * @param {*} [payload]
 */
export const emit = (name, payload) => {
  safeguard(name);
  eventBus.emit(name, payload);
};

/**
 * Trigger an event on next tick. 1 additional argument will be passed into the
 * listener’s callback function.
 *
 * @param {String} name
 * @param {*} [payload]
 */
export const emitOnNextTick = (name, payload) => {
  safeguard(name);
  Vue.nextTick(() => {
    eventBus.emit(name, payload);
  });
};

/**
 * Listen for a custom event. The callback will receive all the additional arguments
 * passed into these event-triggering methods.
 *
 * @param {String} name
 * @param {Function} callback
 */
export const on = (name, callback) => {
  safeguard(name);
  eventBus.on(name, callback);
};

/**
 * Remove event listener(s).
 *
 * If no arguments are provided, remove all event listeners;
 * If only the event is provided, remove all listeners for that event;
 * If both event and callback are given, remove the listener for that specific callback only.
 *
 * @param {String} name
 * @param {Function} callback
 */
export const off = (...args) => {
  if (isEmpty(args)) {
    return eventBus.all.clear();
  }
  eventBus.off(...args);
};

function safeguard(name) {
  if (!events.includes(name)) {
    throw new Error(
      `Unknown event name "${name}", please add your event to the list with a short comment.`
    );
  }
}
