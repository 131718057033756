import { _has } from '~/utils/fp/has';
import { _alter } from '~/utils/fp/alter';
import isObject from '../is-object';

/**
 * Hybrid alter which does not create a new top level object but it will
 * create new sub objects along the path.
 *
 * @type import('../../fp/deep-merge-left').DeepMergeLeftFn
 */
const halter = (alts, obj) => {
  Object.entries(alts).forEach(([altKey, altValue]) => {
    let newValue = altValue;

    if (_has(altKey, obj) && isObject(altValue)) {
      const subObj = obj[altKey] || {};
      newValue = _alter(altValue, subObj);
    }

    obj[altKey] = newValue;
  });

  return obj;
};

export default halter;
