import curry from '../curry';
import { _find } from '../find';
import whereEq from '../where-eq';

/**
 * @typedef {<T>(id: any, T[]) => T} FindByIdFn
 */

/**
 * @type FindByIdFn
 */
export const _findById = (id, arr) => _find(whereEq({ id }), arr);

/**
 * @type FindByIdFn
 */
const findById = curry(_findById);

export default findById;
