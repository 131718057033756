import { createAction } from '~/utils/store';
import * as m from './mutation-types';

export const reset = createAction(m.RESET);

export const setCurrencyCodes = createAction(m.SET_CURRENCY_CODES);

export const setPaymentCurrency = createAction(m.SET_PAYMENT_CURRENCY);

export const setFlowType = createAction(m.SET_FLOW_TYPE);

export const setIsCancellationRebookFlow = createAction(
  m.SET_IS_CANCELLATION_REBOOK_FLOW
);

export const setIsCheckInFlow = createAction(m.SET_IS_CHECK_IN_FLOW);

export const setPnrAndLastName = createAction(m.SET_PNR_AND_LAST_NAME);
