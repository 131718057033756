import curry from '../curry';

/**
 * @typedef {<T>(fn: (value: T) => boolean, arr: T[]) => a} FindIndexEq
 */

/**
 * @type FindIndexEq
 */
export const _findIndex = (fn, arr) => arr.findIndex(fn);

/**
 * Returns the index of the first element of the list which matches the
 * predicate, or `-1` if no element matches.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/findIndex.js
 *
 * @type FindIndexEq
 * @example
 *
 *      let xs = [{a: 1}, {a: 2}, {a: 3}];
 *      findIndex(propEq('a', 2))(xs); //=> 1
 *      findIndex(propEq('a', 4))(xs); //=> -1
 */
const findIndex = curry(_findIndex);

export default findIndex;
