import { LANGUAGE_CODE_EN_GB } from '~/constants';
import { SS_ECOMMERCE_TRACKINGS } from '~/constants/session-storage';
import deepMerge from '~/utils/fp/deep-merge';
import compose from '~/utils/fp/compose';
import inc from '~/utils/fp/inc';
import dec from '~/utils/fp/dec';
import max from '~/utils/fp/max';
import concat from '~/utils/fp/concat';
import always from '~/utils/fp/always';
import hassocPath from '~/utils/object/hassoc-path';
import massocPath from '~/utils/object/massoc-path';
import hevolve from '~/utils/object/hevolve';
import isNotEmptyArray from '~/utils/object/is-not-empty-array';
import { clearHashFromUrl, setHref } from '~/utils/browser';
import { createDeferred } from '~/utils/promise';
import { removeSessionItem } from '~/utils/storage';
import { mutationAssocPath } from '~/utils/store';
import { isSSRBuild } from '~/utils/ssr/build-type';
import { moduleName, getProfileModel, getWizzAccountModel } from './internal';
import * as m from './mutation-types';

/**
 * @type {(state: State, payload: Object<string, any>) => void}
 */
const login = (state, payload = {}) =>
  Object.assign(state, {
    isLoggedIn: true,
    loadingCounter: 0,
    isLatestGCCAccepted: payload.isLatestGCCAccepted ?? true,
    isLatestWizzAccountPolicyAccepted: payload.isLatestWizzAccountPolicyAccepted ?? true,
    shouldDisplayMobilePhoneWarningModal:
      payload.shouldDisplayMobilePhoneWarningModal ?? false,
    wdc: payload.wdc ?? false,
    targetedWdcOffer: payload.targetedWdcOffer ?? null,
    accountHistory: getWizzAccountModel(),
  });

const logout = (state) => {
  Object.assign(state, {
    isLoggedIn: false,
    isLatestGCCAccepted: true,
    isLatestWizzAccountPolicyAccepted: true,
    targetedWdcOffer: null,
    profile: getProfileModel(),
    originalProfileState: getProfileModel(),
    wdc: false,
    lastSeenActive: null,
    shouldDisplayMobilePhoneWarningModal: false,
    accountHistory: getWizzAccountModel(),
  });

  removeSessionItem(moduleName);
  removeSessionItem(SS_ECOMMERCE_TRACKINGS);

  // redirect to page root, but try not to loose the lang code
  clearHashFromUrl();

  const languageCode = window.page.culture;
  const mainUrl = new URL(window.location.href);
  mainUrl.pathname = languageCode === LANGUAGE_CODE_EN_GB ? '/' : `/${languageCode}/`;
  if (!isSSRBuild) {
    mainUrl.hash = '/';
  }
  setHref(mainUrl.href);
};

/**
 * @type {(state: State) => void}
 */
const createCaptchaRequiredDeferred = (state) => {
  hassocPath('volatile.captchaRequiredDeferred', createDeferred(), state);
};

/**
 * @type {(state: State) => void}
 */
const resolveAndResetCaptchaRequiredDeferred = (state) => {
  state.volatile.captchaRequiredDeferred?.resolve();
  hassocPath('volatile.captchaRequiredDeferred', null, state);
};

/**
 * @type {(state: State) => void}
 */
const showCaptchaModal = hassocPath('volatile.isCaptchaModalVisible', true);

/**
 * @type {(state: State) => void}
 */
const hideCaptchaModal = hassocPath('volatile.isCaptchaModalVisible', false);

/**
 * @type {(state: State) => void}
 */
const showYouAreDoneThankYouModal = hassocPath(
  'volatile.isYouAreDoneThankYouModalVisible',
  true
);

/**
 * @type {(state: State) => void}
 */
const hideYouAreDoneThankYouModal = hassocPath(
  'volatile.isYouAreDoneThankYouModalVisible',
  false
);

export default {
  [m.SET_USER_MOBILE_PHONE]: mutationAssocPath('profile.mobilePhone'),
  [m.SET_USER_DATE_OF_BIRTH]: mutationAssocPath('profile.dateOfBirth'),
  [m.SET_USER_HAS_USER_EMAIL_SUBSCRIPTION_FLAG]: mutationAssocPath(
    'profile.hasUserEmailSubscription'
  ),
  [m.SET_USER_ORIGINAL_HAS_USER_EMAIL_SUBSCRIPTION_FLAG]: mutationAssocPath(
    'originalProfileState.hasUserEmailSubscription'
  ),
  [m.UPDATE_WDC]: mutationAssocPath('wdc'),
  [m.SET_CUSTOMER_FEEDBACK]: mutationAssocPath('feedback.rating'),
  [m.FETCH_CUSTOMER_FEEDBACK]: mutationAssocPath('feedback.labels'),
  [m.SET_IS_FAKE_GEO_LOCATION_ADDED]: mutationAssocPath('isFakeGeoLocationAdded'),
  [m.SET_GEO_LOCATION]: mutationAssocPath('geoLocation'),
  [m.CREATE_CAPTCHA_REQUIRED_DEFERRED]: createCaptchaRequiredDeferred,
  [m.RESOLVE_AND_RESET_CAPTCHA_REQUIRED_DEFERRED]: resolveAndResetCaptchaRequiredDeferred,
  [m.SHOW_CAPTCHA_MODAL]: showCaptchaModal,
  [m.HIDE_CAPTCHA_MODAL]: hideCaptchaModal,
  [m.SHOW_YOU_ARE_DONE_THANK_YOU_MODAL]: showYouAreDoneThankYouModal,
  [m.HIDE_YOU_ARE_DONE_THANK_YOU_MODAL]: hideYouAreDoneThankYouModal,

  /**
   * @type {(state: State) => void}
   */
  [m.SET_IS_PRIVACY_POLICY_ACCEPTED]: hassocPath('profile.ppAccepted', true),

  /**
   * @type {(state: State) => void}
   */
  [m.UNSET_IS_PRIVACY_POLICY_ACCEPTED]: hassocPath('profile.ppAccepted', false),

  /**
   * @type {(state: State) => void}
   */
  [m.SET_SHOULD_SHOW_NEWSLETTER_CONSENT_MODAL]: hassocPath(
    'profile.shouldShowNewsletterConsentModal',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.UNSET_SHOULD_SHOW_NEWSLETTER_CONSENT_MODAL]: hassocPath(
    'profile.shouldShowNewsletterConsentModal',
    false
  ),

  [m.LOGIN]: login,
  [m.LOGOUT]: logout,

  [m.UPDATE_PROFILE](state, profile) {
    state.profile = deepMerge(state.profile, profile);
    state.originalProfileState = deepMerge(state.originalProfileState, profile);
  },

  [m.UPDATE_LAST_SEEN_ACTIVE](state) {
    state.lastSeenActive = Date.now();
  },

  [m.UPDATE_MY_CURRENT_BOOKINGS]: (state, bookings) =>
    hassocPath('profile.currentBookings', bookings, state),

  [m.UPDATE_MY_PAST_BOOKINGS]: (state, bookings) =>
    hassocPath('profile.pastBookings', bookings, state),

  [m.UPDATE_MY_CANCELLED_BOOKINGS]: (state, bookings) =>
    hassocPath('profile.cancelledBookings', bookings, state),

  [m.SET_REFUNDABLE_BOOKINGS]: (state, bookings) =>
    hassocPath('profile.refundableBookings', bookings, state),

  [m.SET_AUTOMATICALLY_REFUNDABLE_BOOKINGS]: (state, bookings) =>
    hassocPath('profile.automaticallyRefundableBookings', bookings, state),

  [m.UPDATE_CANCELLED_BOOKINGS_COUNT_IN_TOTAL]: (state, count) =>
    hassocPath('profile.cancelledBookingsCountInTotal', count, state),

  [m.UPDATE_CURRENT_BOOKINGS_COUNT_IN_TOTAL]: (state, count) =>
    hassocPath('profile.currentBookingsCountInTotal', count, state),

  [m.UPDATE_PAST_BOOKINGS_COUNT_IN_TOTAL]: (state, count) =>
    hassocPath('profile.pastBookingsCountInTotal', count, state),

  /**
   * @type {(state: State) => void}
   */
  [m.SHOW_INVALID_BOOKING_STATE_MODAL]: hassocPath(
    'isInvalidBookingStateModalVisible',
    true
  ),

  /**
   * @type {(state: State) => void}
   */
  [m.HIDE_INVALID_BOOKING_STATE_MODAL]: hassocPath(
    'isInvalidBookingStateModalVisible',
    false
  ),

  /**
   * @type {(state: State, value: boolean) => void}
   */
  [m.SET_INVALID_BOOKING_STATE_MODAL_VISIBILITY]: mutationAssocPath(
    'isInvalidBookingStateModalVisible'
  ),

  [m.INCREMENT_LOADING_COUNTER]: hevolve({ loadingCounter: inc }),

  [m.DECREMENT_LOADING_COUNTER]: hevolve({
    loadingCounter: compose(max(0), dec),
  }),

  [m.ADD_REGISTRATION_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    hevolve({ registration: { errors: concat(errors) } }, state);
  },

  [m.REMOVE_REGISTRATION_ERRORS](state) {
    state.registration.errors = [];
  },

  [m.ADD_LOGIN_ERRORS](state, { errors, isCaptchaRequired = false } = {}) {
    if (!isNotEmptyArray(errors)) return;
    hevolve(
      { login: { errors: concat(errors), isCaptchaRequired: always(isCaptchaRequired) } },
      state
    );
  },

  [m.REMOVE_LOGIN_ERRORS](state) {
    state.login.errors = [];
    state.login.isCaptchaRequired = false;
  },

  [m.ADD_PROFILE_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    hevolve({ profile: { errors: concat(errors) } }, state);
  },

  [m.REMOVE_PROFILE_ERRORS](state) {
    state.profile.errors = [];
  },

  [m.UNSET_SHOULD_DISPLAY_MOBILE_PHONE_WARNING_MODAL]: hassocPath(
    'shouldDisplayMobilePhoneWarningModal',
    false
  ),

  [m.ADD_CUSTOMER_FEEDBACK_ERRORS](state, errors) {
    if (!isNotEmptyArray(errors)) return;
    hevolve({ feedback: { errors: concat(errors) } }, state);
  },

  [m.REMOVE_CUSTOMER_FEEDBACK_ERRORS](state) {
    state.feedback.errors = [];
  },

  [m.SET_DETECTED_BROWSING_LOCATION](state, value) {
    state.detectedBrowserLocation = value || '';
  },

  [m.SET_ACCOUNT_HISTORY](state, value) {
    state.accountHistory.deposits = value.deposits || [];
    state.accountHistory.withDrawal = value.withDrawal || [];
    state.accountHistory.remaining = value.remaining;
  },

  [m.SET_SELECTED_WDC_MEMBERSHIP](state, payload) {
    state.profile.selectedWdcMembership = payload;
  },

  [m.SET_IS_YOUR_CLAIMS_POPUP_CLICKED](state, value) {
    state.isYourClaimsPopupClicked = value;
  },

  [m.SET_FREE_WDC_SUCCESSFUL_SUBSCRIPTION](state, payload) {
    state.profile.isSuccessfulSubscriptionVisible = payload;
  },

  [m.UPDATE_PREVIOUS_FIRST_NAME](state) {
    massocPath('previousFirstName', state.profile.firstName.value, state);
  },

  [m.RESET_PREVIOUS_FIRST_NAME](state) {
    state.previousFirstName = '';
  },

  [m.SET_EMAIL_VERIFICATION_STATUS](state, value) {
    state.isEmailVerified = value;
  },

  [m.SHOW_CONTACT_DATA_VERIFICATION_MESSAGE](state) {
    state.contactDataVerificationMessageVisible = true;
  },

  [m.HIDE_CONTACT_DATA_VERIFICATION_MESSAGE](state) {
    state.contactDataVerificationMessageVisible = false;
  },
};
