import path, { _path } from '../path';

/**
 * @typedef {<T, K extends keyof T>(p: K, obj: T) => T[K]} PropFn
 */

/**
 * @type PropFn
 */
export const _prop = _path;

/**
 * Returns a function that when supplied an object returns the indicated
 * property of that object, if it exists.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/prop.js
 *
 * @type PropFn
 * @example
 *
 *      prop('x', {x: 100}); //=> 100
 *      prop('x', {}); //=> undefined
 */
const prop = path;

export default prop;
