import whereEq from '~/utils/fp/where-eq';
import getSeats from '~/utils/booking/seat-selection/get-seats';
import findAssignedSeatCode from '../find-assigned-seat-code';

const findAssignedSeat = (passengerId, seatAssignments, rawSeatMap) => {
  const seatCode = findAssignedSeatCode(passengerId, seatAssignments);
  return getSeats(rawSeatMap).find(whereEq({ code: seatCode }));
};

export default findAssignedSeat;
