export const FLIGHT_CANCELLATION_PATH = 'cancellation';
export const FLIGHT_CANCELLATION_NAME = 'cancellation';

export const FLIGHT_CANCELLATION_STEP_NAME_INFORMATION =
  'flight-cancellation-information';
export const FLIGHT_CANCELLATION_STEP_NANE_PAYMENT_FAILED =
  'flight-cancellation-payment-failed';
export const FLIGHT_CANCELLATION_STEP_NAME_PAYMENT = 'flight-cancellation-payment';
export const FLIGHT_CANCELLATION_STEP_NAME_SELECT = 'select';

export const FLIGHT_CANCELLATION_STEP_PATH_INFORMATION = 'information';
export const FLIGHT_CANCELLATION_STEP_PATH_PAYMENT = 'payment';
export const FLIGHT_CANCELLATION_STEP_PATH_PAYMENT_FAILED = 'failed';
export const FLIGHT_CANCELLATION_STEP_PATH_SELECT = 'select';

export const FLIGHT_CANCELLATION_ROUTES = new Map([
  [FLIGHT_CANCELLATION_STEP_PATH_INFORMATION, FLIGHT_CANCELLATION_STEP_NAME_INFORMATION],
  [FLIGHT_CANCELLATION_STEP_PATH_SELECT, FLIGHT_CANCELLATION_STEP_NAME_SELECT],
  [FLIGHT_CANCELLATION_STEP_PATH_PAYMENT, FLIGHT_CANCELLATION_STEP_NAME_PAYMENT],
]);
