/*
 * outbound link tracking reimplemented based on autotrack plugin:
 * https://github.com/googleanalytics/autotrack
 */

import { isServer } from '~/utils/ssr';
import { sendGaEvent } from './send-ga-event';

export const initializeOutboundLinkTracker = (opts = {}) => {
  if (isServer() || !window.addEventListener) return;

  const defaultOpts = {
    events: ['click'],
    linkSelector: 'a',
  };

  const options = { ...defaultOpts, ...opts };

  const delegates = {};
  const delegate = require('../browser/delegate').default;
  options.events.forEach((event) => {
    delegates[event] = delegate(
      document,
      event,
      options.linkSelector,
      handleLinkInteractions,
      {
        composed: true,
        useCapture: true,
      }
    );
  });
};

/**
 * Handles all interactions on link elements. A link is considered an outbound
 * link if its hostname property does not match location.hostname. When the
 * beacon transport method is not available, the links target is set to
 * "_blank" to ensure the hit can be sent.
 * @param {Event} event The DOM click event.
 * @param {Element} link The delegated event target.
 */
const handleLinkInteractions = (event, link) => {
  const parseUrl = require('../browser/parse-url').default;

  if (shouldTrackOutboundLink(link, parseUrl)) {
    const href = link.getAttribute('href') || link.getAttribute('xlink:href');
    const url = parseUrl(href);

    sendGaEvent({
      category: 'Outbound Link',
      action: event.type,
      label: url.href,
    });
  }
};

/**
 * Determines whether or not gtag should send a hit when a link is
 * clicked.
 * @param {Element} link The link that was clicked on.
 * @param {Function} parseUrlFn A cross-browser utility method for url parsing
 * @return {boolean} Whether or not the link should be tracked.
 */
export const shouldTrackOutboundLink = (link, parseUrlFn) => {
  const isLinkObject = typeof link === 'object' && link.getAttribute;
  const href = isLinkObject
    ? link.getAttribute('href') || link.getAttribute('xlink:href')
    : link;
  const url = parseUrlFn(href);

  // boarding card could be accessed via a be call only,
  // this caused problems with the tracking
  if (/^be\./.test(url.hostname)) {
    return false;
  }

  // the original logic from
  // https://github.com/googleanalytics/autotrack/blob/64d0a5b147d729a2582afa12687445cb0ea2692a/lib/plugins/outbound-link-tracker.js#L136
  return url.hostname !== location.hostname && url.protocol.slice(0, 4) === 'http';
};
