import isNotNil from '~/utils/object/is-not-nil';
import { isServer } from '~/utils/ssr';

interface EcommerceItem {
  /* eslint-disable camelcase */
  item_id: string;
  item_name: string;
  item_category: string;
  quantity: number;
  item_variant?: string;
  item_brand?: string;
  price?: number;
  coupon?: string;
  affiliation?: string;
  purchaseLocation?: string;
  hasSavingOnWdc?: string;
  direction?: string;
  productPrice?: number;
}

interface EcommerceTransaction {
  transaction_id: string;
  /* eslint-enable camelcase */
  affiliation: string;
  value: string;
  shipping: string;
  tax: string;
  currency: string;
}

type EcommerceEventType = 'add_to_cart' | 'remove_from_cart' | 'view_item' | 'purchase';

interface GTMProps {
  event?: string;
  ecommerce?: {
    items: EcommerceItem[];
    [key: string]: unknown;
  } | null;
  [key: string]: unknown;
}

interface WindowWithGTM extends Window {
  dataLayer?: {
    push: (args: GTMProps) => void;
  };
}

interface CoreWebVitalsMeasurement {
  name: string;
  rating: 'good' | 'needsImprovement' | 'poor';
  id: string;
  value: number;
  delta: number;
  attribution?: {
    largestShiftTarget: string;
    interactionTarget: string;
    element: string;
  };
}

export const gtm = (args: GTMProps) => {
  if (isServer() || !isGTMAvailable()) return;
  (window as WindowWithGTM)?.dataLayer?.push(args);
};

export const sendGTMEcommerceEvent = ({
  items,
  eventType,
  transaction = null,
  paymentMethod = null,
}: {
  items: EcommerceItem[];
  eventType: EcommerceEventType;
  transaction?: EcommerceTransaction | null;
  paymentMethod?: string | null;
}) => {
  gtm({ ecommerce: null });
  gtm({
    event: eventType,
    ecommerce: {
      items,
      ...transaction,
    },
    ...(paymentMethod && { paymentMethod }),
  });
};

const isGTMAvailable = () => isNotNil((window as WindowWithGTM).dataLayer);

export const processCoreWebVitals = (data: CoreWebVitalsMeasurement) => {
  if (!isGTMAvailable()) return;

  let targetElement: string | null | undefined = null;

  switch (data.name) {
    case 'CLS':
      targetElement =
        data.rating === 'good' ? null : data.attribution?.largestShiftTarget;
      break;
    case 'INP':
      targetElement = data.rating === 'good' ? null : data.attribution?.interactionTarget;
      break;
    case 'LCP':
      targetElement = data.rating === 'good' ? null : data.attribution?.element;
      break;
    default:
      targetElement = null;
      break;
  }

  const webVitalsMeasurement = {
    name: data.name,
    id: data.id,
    value: data.value,
    rating: data.rating,
    targetElement,
  };

  gtm({
    event: 'web-vitals',
    webVitalsMeasurement,
  });
};
