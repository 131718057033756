import { isServer } from '~/utils/ssr';
import { isSSRBuild } from '~/utils/ssr/build-type';
import * as globalValueGetters from '~/store/modules/global-value/getters';
import * as globalValueActions from '~/store/modules/global-value/actions';
import isNotEmpty from '../object/is-not-empty';

export const getUrls = (state = {}) => {
  if (isSSRBuild && (isNotEmpty(state) || isServer()))
    return globalValueGetters.getUrls(state);

  return window.urls || globalValueGetters.getUrls(state);
};

export const setUrls = (value, store = {}) => {
  if (isSSRBuild && isServer()) {
    globalValueActions.setGlobalUrls(store, value);
    return;
  }

  window.urls = value;
};
