import curry from '../curry';

/**
 * @typedef {(a: number, b: number) => number} MaxFn
 */

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/max.js
 *
 * @type MaxFn
 */
export const _max = (a, b) => Math.max(a, b);

/**
 * @type MaxFn
 */
const max = curry(_max);

export default max;
