import curry from '../curry';
import { _equals } from '../equals';
import { _path } from '../path';

/**
 * @typedef {(p: string, value: any, obj: Object<string, any>) => boolean} PathEqFn
 */

/**
 * @type PathEqFn
 */
export const _pathEq = (p, value, obj) => _equals(_path(p, obj), value);

/**
 * Determines whether a nested path on an object has a specific value, in
 * `equals` terms. Most likely used to filter a list.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pathEq.js
 *
 * @type PathEqFn
 * @example
 *
 *      let user1 = { address: { zipCode: 90210 } };
 *      let user2 = { address: { zipCode: 55555 } };
 *      let user3 = { name: 'Bob' };
 *      let users = [ user1, user2, user3 ];
 *      let isFamous = pathEq('address.zipCode', 90210);
 *      filter(isFamous, users); //=> [ user1 ]
 */
const pathEq = curry(_pathEq);

export default pathEq;
