import { getDefaultPrice } from '~/utils/price';

export const getDefaultState = () => ({
  isPostBookingUpgradeStarted: false,
  upgradeLocation: null,
  upgradePrice: null,
  upgradeBundle: '',
  upsellInfo: {
    isOverPriceMinimum: false,
    ancillaries: [],
    price: getDefaultPrice(),
    saving: getDefaultPrice(),
    upsellToBundle: '',
  },
  postBookingUpsellInfo: {
    isFirstFetch: true,
    shouldFetchUpsell: true,
    isUpsellAvailable: false,
    price: getDefaultPrice(),
  },
});
