import curry from '../curry';
import { _slice } from '../slice';

/**
 * @typedef {} TakeWhileFn
 */

/**
 * @type TakeWhileFn
 */
export const _takeWhile = (fn, arr) => {
  let index = 0;
  const length = arr.length;

  while (index < length && fn(arr[index])) {
    index += 1;
  }

  return _slice(0, index, arr);
};

/**
 * Returns a new list containing the first `n` elements of a given list,
 * passing each value to the supplied predicate function, and terminating when
 * the predicate function returns `false`. Excludes the element that caused the
 * predicate function to fail. The predicate function is passed one argument:
 * *(value)*.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/takeWhile.js
 *
 * @type TakeWhileFn
 * @example
 *
 *      let isNotFour = x => x !== 4;
 *
 *      takeWhile(isNotFour, [1, 2, 3, 4, 3, 2, 1]); //=> [1, 2, 3]
 */
const takeWhile = curry(_takeWhile);

export default takeWhile;
