export const SET_CURRENT_STEP = 'flightChange/setCurrentStep';
export const SET_BOOKING = 'flightChange/setBooking';
export const ADD_SELECTED_PASSENGER = 'flightChange/addSelectedPassenger';
export const REMOVE_SELECTED_PASSENGER = 'flightChange/removeSelectedPassenger';
export const RESET_SELECTED_PASSENGERS = 'flightChange/resetSelectedPassengers';
export const SET_INITIALIZATION_ERRORS = 'flightChange/setInitializationErrors';
export const SET_PASSENGER_ERRORS = 'flightChange/setPassengerErrors';
export const SET_SEAT_SELECTION_ERRORS = 'flightChange/setSeatSelectionErrors';
export const SET_SUMMARY_ERRORS = 'flightChange/setSummaryErrors';
export const SET_CONTACT_ERRORS = 'flightChange/setContactErrors';
export const SET_SERVICES = 'flightChange/setServices';
export const RESET_ERRORS = 'flightChange/resetErrors';
export const RESET_CURRENT_STEP = 'flightChange/resetSteps';
