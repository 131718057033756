import uniqBy from '../uniq-by';
import identity from '../identity';

/**
 * Returns a new list containing only one copy of each element in the original
 * list. `equals` is used to determine equality.
 *
 * https://github.com/ramda/ramda/blob/v0.27.0/source/uniq.js
 *
 * @type {<T>(arr: T[]) => T[]}
 * @example
 *
 * @example
 *
 *      uniq([1, 1, 2, 1]); //=> [1, 2]
 *      uniq([1, '1']);     //=> [1, '1']
 *      uniq([[42], [42]]); //=> [[42]]
 */

const uniq = uniqBy(identity);

export default uniq;
