export const SPECIFIC_TYPE_BGY = 'bgy';
export const SPECIFIC_TYPE_KTW = 'ktw';
export const SPECIFIC_TYPE_CRL = 'crl';
export const SPECIFIC_TYPE_OTP = 'otp';
export const SPECIFIC_TYPE_HEAVY_ONE = 'heavyone';
export const SPECIFIC_TYPE_HEAVY_TWO = 'heavytwo';
export const SPECIFIC_TYPE_HEAVY_THREE = 'heavythree';
export const SPECIFIC_TYPE_HEAVY_FOUR = 'heavyfour';
export const SPECIFIC_TYPE_HEAVY_FIVE = 'heavyfive';
export const SPECIFIC_TYPE_HEAVY_SIX = 'heavysix';
export const SPECIFIC_TYPE_MEDIUM_ONE = 'mediumone';
export const SPECIFIC_TYPE_MEDIUM_TWO = 'mediumtwo';
export const SPECIFIC_TYPE_MEDIUM_THREE = 'mediumthree';
export const SPECIFIC_TYPE_MEDIUM_FOUR = 'mediumfour';
export const SPECIFIC_TYPE_MEDIUM_FIVE = 'mediumfive';
export const SPECIFIC_TYPE_MEDIUM_SIX = 'mediumsix';
export const SPECIFIC_TYPE_LIGHT_ONE = 'lightone';
export const SPECIFIC_TYPE_LIGHT_TWO = 'lighttwo';
export const SPECIFIC_TYPE_LIGHT_THREE = 'lightthree';
export const SPECIFIC_TYPE_ULTRALIGHT_ONE = 'ultralightone';
export const SPECIFIC_TYPE_ULTRALIGHT_TWO = 'ultralighttwo';
export const SPECIFIC_TYPE_ULTRALIGHT_THREE = 'ultralightthree';
export const SPECIFIC_TYPE_ULTRALIGHT_FOUR = 'ultralightfour';
export const SPECIFIC_TYPE_ULTRALIGHT_FIVE = 'ultralightfive';
export const SPECIFIC_TYPE_ULTRALIGHT_SIX = 'ultralightsix';
export const SPECIFIC_TYPE_EXTRALIGHT_ONE = 'extralightone';
export const SPECIFIC_TYPE_CARRY_ON_BAG = 'carry-on-bag';
export const SPECIFIC_TYPE_CABIN_BAG = 'cabin-bag';
export const SPECIFIC_TYPE_BUS = 'bus';
export const SPECIFIC_TYPE_TAXI = 'taxi';
export const SPECIFIC_TYPE_TRAIN = 'train';
export const SPECIFIC_TYPE_SHUTTLE_BUS = 'shuttlebus';
export const SPECIFIC_TYPE_REGIONAL_TRAIN = 'regionaltrain';
export const SPECIFIC_TYPE_FLIGHT_CHANGE = 'flight-change';
export const SPECIFIC_TYPE_FREE_FLIGHT_CHANGE = 'free-flight-change';
export const SPECIFIC_TYPE_TRAVEL_PARTNER = 'travel-partner';
export const SPECIFIC_TYPE_INVOICE_CHANGE = 'invoice-change';
export const SPECIFIC_TYPE_NAME_CHANGE = 'name-change';
export const SPECIFIC_TYPE_PRIORITY_BOARDING = 'priority-boarding';
export const SPECIFIC_TYPE_TRAVEL_INSURANCE = 'travel-insurance';
export const SPECIFIC_TYPE_INFANT = 'infant';
export const SPECIFIC_TYPE_LOUNGE_ACCESS = 'lounge-access';
export const SPECIFIC_TYPE_LOUNGE_ACCESS_V2 = 'lounge-access-v2';
export const SPECIFIC_TYPE_SMS_NOTIFICATION = 'sms-notification';
export const SPECIFIC_TYPE_PRIVILEGE_PASS = 'privilege-pass';
export const SPECIFIC_TYPE_SPORTS_EQUIPMENT = 'sports-equipment';
export const SPECIFIC_TYPE_FARE_LOCK = 'fare-lock';
export const SPECIFIC_TYPE_WIZZ_FLEX = 'wizz-flex';
export const SPECIFIC_TYPE_FAST_TRACK_SECURITY = 'fast-track-security';
export const SPECIFIC_TYPE_FAST_TRACK_SECURITY_V2 = 'fast-track-security-v2';
export const SPECIFIC_TYPE_BASIC = 'basic';
export const SPECIFIC_TYPE_SMART = 'smart';
export const SPECIFIC_TYPE_MIDDLE_TWO = 'middletwo';
export const SPECIFIC_TYPE_MIDDLE = 'middle';
export const SPECIFIC_TYPE_PLUS = 'plus';
export const SPECIFIC_TYPE_SMART_FAMILY = 'smart-family';
export const SPECIFIC_TYPE_MIDDLE_TWO_FAMILY = 'middletwo-family';
export const SPECIFIC_TYPE_MIDDLE_FAMILY = 'middle-family';
export const SPECIFIC_TYPE_PLUS_FAMILY = 'plus-family';
export const SPECIFIC_TYPE_AIRPORT_CHECKIN = 'airport-checkin';
export const SPECIFIC_TYPE_SEATING_TOGETHER_GUARANTEE = 'seating-together-guarantee';
export const SPECIFIC_TYPE_WDC_MEMBERSHIP = 'wdc-membership';
export const SPECIFIC_TYPE_WDDC_MEMBERSHIP_ITALY = 'wddc-membership-it';
export const SPECIFIC_TYPE_DELAYED_WARRANTY = 'delayed-warranty';
export const SPECIFIC_TYPE_SEAT_PRICE = 'seat-price';
export const SPECIFIC_TYPE_ADMINISTRATION = 'administration';
export const SPECIFIC_TYPE_PRICE_DETAILS_FLIGHT_PRICE = 'price-details-flight-price';
export const SPECIFIC_TYPE_GROUP_HANDLING = 'group-handling';
export const SPECIFIC_TYPE_ONLINE_TRAVEL_AGENCY = 'online-travel-agency';
export const SPECIFIC_TYPE_CAR_RENTAL = 'car-rental';
export const SPECIFIC_TYPE_CAR_TRAWLER = 'car-trawler';
export const SPECIFIC_TYPE_SCRAPER = 'scraper';
export const SPECIFIC_TYPE_PAPER_INVOICE = 'paper-invoice';
export const SPECIFIC_TYPE_SEAT_PROTECTION = 'seat-protection';
export const SPECIFIC_TYPE_CALL_CENTER_TRANSACTION = 'call-center-transaction';
export const SPECIFIC_TYPE_CALL_CENTER = 'call-center';
export const SPECIFIC_TYPE_AIRPORT_CHECKIN_PENALTY = 'airport-checkin-penalty';
export const SPECIFIC_TYPE_EXCESS_WEIGHT = 'excess-weight';
export const SPECIFIC_TYPE_SYSTEM_SURCHARGE = 'system-surcharge';
export const SPECIFIC_TYPE_CANCELLATION = 'cancellation';
export const SPECIFIC_TYPE_INAD_SURCHARGE = 'inad-surcharge';
export const SPECIFIC_TYPE_MISSED_DEPARTURE = 'missed-departure';
export const SPECIFIC_TYPE_OTHER_FEES = 'other-fees';
export const SPECIFIC_TYPE_BAGGAGE_CORRECTION_DISCOUNT = 'baggage-correction-discount';
export const SPECIFIC_TYPE_WDC_RENEWAL = 'wdc-renewal';
export const SPECIFIC_TYPE_AIRPORT_PARKING = 'airportparking';
export const SPECIFIC_TYPE_INFANT_NAME_CHANGE = 'infant-name-change';
export const SPECIFIC_TYPE_BUNDLE_UPGRADE = 'middle-upsell';
export const SPECIFIC_TYPE_BUNDLE_UPGRADE_FAMILY = 'middle-family-upsell';
export const SPECIFIC_TYPE_AUTO_CHECK_IN = 'auto-check-in';
export const SPECIFIC_TYPE_BLUE_RIBBON_BAG_TRACKING = 'blue-ribbon-bag-tracking';
export const SPECIFIC_TYPE_CORPORATE_CARD_FEE = 'corporate-card-fee';
export const SPECIFIC_TYPE_CAFE_AND_BOUTIQUE_VOUCHER = 'cafeVoucher';
