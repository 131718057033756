import curry from '../curry';

/**
 * @typedef {(fn: (value: any, key: string, obj: Object<string, any>) => any, obj: Object<string, any>) => Object<string, any>} MapObjFn
 */

/**
 * @type MapObjFn
 */
export const _mapObj = (fn, obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = fn(value, key, obj);
    return acc;
  }, {});

/**
 * note: you should take care of cloning, etc. of object, array properties in your `fn`
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/mapObjIndexed.js
 *
 * @type MapObjFn
 * @example
 *
 *      const xyz = { x: 1, y: 2, z: 3 };
 *      const prependKeyAndDouble = (num, key, obj) => key + (num * 2);
 *
 *      mapObj(prependKeyAndDouble, xyz); //=> { x: 'x2', y: 'y4', z: 'z6' }
 */
const mapObj = curry(_mapObj);

export default mapObj;
