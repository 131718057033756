import FileSaver from 'file-saver';


export const openInNewTab = (data, contentType) => {
  const blob = new Blob([data], { type: contentType });
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, '_blank');
}

export const saveAsFile = (data, contentType, filename) => {
  const blob = new Blob([data], { type: contentType });
  return FileSaver.saveAs(blob, filename);
};
