import { languageCode } from '~/constants/language';

// eslint-disable-next-line no-undef
const baseUrl = global?.BASE_URL ?? '';

export const page = {
  culture: languageCode,
  translations: {
    // current page all translation version
    'en-gb': `${baseUrl}/en-gb`,
    'hu-hu': `${baseUrl}/hu-hu`,
    'cs-cz': `${baseUrl}/cs-cz`,
    'bg-bg': `${baseUrl}/bg-bg`,
    'de-de': `${baseUrl}/de-de`,
    'es-es': `${baseUrl}/es-es`,
    'ca-es': `${baseUrl}/ca-es`,
    'el-gr': `${baseUrl}/el-gr`,
    'fr-fr': `${baseUrl}/fr-fr`,
    'it-it': `${baseUrl}/it-it`,
    'lv-lv': `${baseUrl}/lv-lv`,
    'lt-lt': `${baseUrl}/lt-lt`,
    'mk-mk': `${baseUrl}/mk-mk`,
    'nl-nl': `${baseUrl}/nl-nl`,
    'pl-pl': `${baseUrl}/pl-pl`,
    'pt-pt': `${baseUrl}/pt-pt`,
    'ro-ro': `${baseUrl}/ro-ro`,
    'ru-ru': `${baseUrl}/ru-ru`,
    'sv-se': `${baseUrl}/sv-se`,
    'sr-cyrl-cs': `${baseUrl}/sr-cyrl-cs`,
    'uk-ua': `${baseUrl}/uk-ua`,
    'he-il': `${baseUrl}/he-il`,
    'bs-latn-ba': `${baseUrl}/bs-latn-ba`,
    'sk-sk': `${baseUrl}/sk-sk`,
    'nb-no': `${baseUrl}/nb-no`,
    'sq-al': `${baseUrl}/sq-al`,
    'ka-ge': `${baseUrl}/ka-ge`,
    'ar-ae': `${baseUrl}/ar-ae`,
  },
};
