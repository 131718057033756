import curry from '../curry';

/**
 * @typedef {<T>(fn: (value: T) => boolean, arr: T[]) => T[]} FilterFn
 */

/**
 * @type FilterFn
 */
export const _filter = (fn, arr) => arr.filter((el) => fn(el));

/**
 * Note: predicate function will be called as fn(element)
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/filter.js
 *
 * @type FilterFn
 */
const filter = curry(_filter);

export default filter;
