import hevolve from '~/utils/object/hevolve';
import { pintrkTrack } from '~/utils/analytics/pinterest-tag';
import { ttqTrack } from '~/utils/analytics/tik-tok-pixel';
import * as coreBookingGetters from '~/store/modules/core-booking/getters';
import * as bookingGetters from '~/store/modules/booking/getters';
import * as searchGetters from '~/store/modules/search/getters';
import { query as bookingQuery } from '../booking/query';
import { query as coreBookingQuery } from '../core-booking/query';
import { query as searchQuery } from '../search/query';
import { query as successfulBookingQuery } from '../successful-booking/query';
import {
  PINTEREST_EVENT_CATEGORY_PAGE_VISIT,
  PINTEREST_EVENT_CATEGORY_SEARCH,
  PINTEREST_EVENT_CATEGORY_ADD_TO_CART,
  PINTEREST_EVENT_CATEGORY_PURCHASE,
  TIKTOK_EVENT_CATEGORY_SEARCH,
  TIKTOK_EVENT_CATEGORY_VIEW_CONTENT,
  TIKTOK_EVENT_CATEGORY_ADD_TO_CART,
  TIKTOK_EVENT_CATEGORY_PURCHASE,
} from './constants';
import { fbqTrack, withZone, clarify } from './internal';

// On the flight search results page
export const fbqTrackSearch = ({ state }) => {
  const { currencyCode } = coreBookingQuery(['bookingCurrencyCode:currencyCode'], state);

  const params = searchQuery(
    [
      'cheapestOutboundFarePrice',
      'cheapestReturnFarePrice',
      'departureStationIata',
      'arrivalStationIata',
      'numberOfAdultPassengers',
      'numberOfChildPassengers',
      'numberOfInfantPassengers',
      'outboundDepartureDate',
      'returnDepartureDate',
    ],
    state
  );

  const price = {
    amount: params.cheapestOutboundFarePrice + params.cheapestReturnFarePrice,
    currencyCode,
  };

  hevolve(
    {
      returnDepartureDate: clarify,
    },
    params
  );

  fbqTrack('Search', { ...params, price });
};

// When the user has selected the flight(s)
export const fbqTrackViewContent = ({ state }) =>
  fbqTrack('ViewContent', fbqCommonParams(state));

// When the user enters the payment screen
export const fbqTrackInitiateCheckout = ({ state }) =>
  fbqTrack('InitiateCheckout', fbqCommonParams(state));

const fbqCommonParams = (state) => {
  const params = bookingQuery(
    [
      'outboundDepartureDateTime:outboundDepartureDate',
      'outboundArrivalDateTime:outboundArrivalDate',
      'returnDepartureDateTime:returnDepartureDate',
      'returnArrivalDateTime:returnArrivalDate',
      'outboundDepartureStation:departureStationIata',
      'outboundArrivalStation:arrivalStationIata',
      'numberOfAdults',
      'numberOfChildren',
      'numberOfInfants',
    ],
    state
  );

  hevolve(
    {
      outboundDepartureDate: withZone,
      outboundArrivalDate: withZone,
      returnDepartureDate: withZone,
      returnArrivalDate: withZone,
    },
    params
  );

  return params;
};

// On the booking confirmed page
export const fbqTrackPurchase = ({ state }) => {
  const params = successfulBookingQuery(
    [
      'outboundDepartureDate',
      'outboundArrivalDate',
      'returnDepartureDate',
      'returnArrivalDate',
      'outboundDepartureStationIata:departureStationIata',
      'outboundArrivalStationIata:arrivalStationIata',
      'numberOfAdults',
      'numberOfChildren',
      'numberOfInfants',
      'totalPaidAmount:value',
    ],
    state
  );

  hevolve(
    {
      outboundDepartureDate: withZone,
      outboundArrivalDate: withZone,
      returnDepartureDate: withZone,
      returnArrivalDate: withZone,
    },
    params
  );

  fbqTrack('Purchase', params);
};

export const pintrkTrackSearch = ({ state }) => {
  const departureStationIata = searchGetters.departureStationIata(state);
  const arrivalStationIata = searchGetters.arrivalStationIata(state);

  const payload = {
    search_query: `${departureStationIata}-${arrivalStationIata}`,
  };

  pintrkTrack(PINTEREST_EVENT_CATEGORY_SEARCH, payload);
};

export const pintrkTrackPageVisit = ({ state }) => {
  const currentStepName = bookingGetters.currentStepNameWithoutStepNothing(state);
  if (!currentStepName) return;

  pintrkTrack(PINTEREST_EVENT_CATEGORY_PAGE_VISIT, {
    line_items: [{ product_category: 'page', product_id: currentStepName }],
  });
};

export const pintrkTrackAddToCart = ({ state }) => {
  const currencyCode = coreBookingGetters.paymentCurrencyCode(state);

  const params = bookingQuery(
    [
      'fares',
      'outboundBundle',
      'returnBundle',
      'outboundDepartureStation:departureStationIata',
      'outboundArrivalStation:arrivalStationIata',
      'numberOfIndependentPassengers',
      'isRoundTrip',
    ],
    state
  );

  const payload = {
    value: params.isRoundTrip
      ? (params.fares.outbound.basePrice?.amount ?? 0) +
        params.fares.return.basePrice.amount
      : params.fares.outbound.basePrice.amount,
    order_quantity: params.isRoundTrip
      ? params.numberOfIndependentPassengers * 4
      : params.numberOfIndependentPassengers * 2,
    order_id: params.isRoundTrip
      ? `${params.fares.outbound.fareSellKey}-${params.fares.return.fareSellKey}`
      : params.fares.outbound.fareSellKey,
    currency: currencyCode,
    line_items: [
      {
        product_id: `${params.departureStationIata}-${params.arrivalStationIata}-${params.outboundBundle}`,
        product_category: 'fare',
        product_price: params.fares.outbound.basePrice?.amount ?? 0,
        product_quantity: params.numberOfIndependentPassengers,
      },

      params.isRoundTrip && {
        product_id: `${params.arrivalStationIata}-${params.departureStationIata}-${params.returnBundle}`,
        product_category: 'fare',
        product_price: params.fares.return.basePrice.amount,
        product_quantity: params.numberOfIndependentPassengers,
      },
    ],
  };

  pintrkTrack(PINTEREST_EVENT_CATEGORY_ADD_TO_CART, payload);
};

export const pintrkTrackPurchase = ({ state }) => {
  const currencyCode = coreBookingGetters.paymentCurrencyCode(state);

  const params = successfulBookingQuery(
    [
      'outboundDepartureStationIata:departureStationIata',
      'outboundArrivalStationIata:arrivalStationIata',
      'totalPaidAmount',
      'trackingNumber',
      'passengers',
    ],
    state
  );

  const payload = {
    value: params.totalPaidAmount.amount,
    order_quantity: params.passengers.length,
    order_id: params.trackingNumber,
    currency: currencyCode,
    line_items: [
      {
        product_id: `${params.departureStationIata}-${params.arrivalStationIata}`,
        product_category: 'booking',
      },
    ],
  };

  pintrkTrack(PINTEREST_EVENT_CATEGORY_PURCHASE, payload);
};

export const ttqTrackSearch = ({ state }) => {
  const departureStationIata = searchGetters.departureStationIata(state);
  const arrivalStationIata = searchGetters.arrivalStationIata(state);

  const payload = {
    query: `${departureStationIata}-${arrivalStationIata}`,
    content_id: `${departureStationIata}-${arrivalStationIata}`,
  };

  ttqTrack(TIKTOK_EVENT_CATEGORY_SEARCH, payload);
};

export const ttqTrackViewContent = ({ state }) => {
  const progressBarCurrentStepNumber = bookingGetters.progressBarCurrentStepNumber(state);
  const currentStepName = bookingGetters.currentStepNameWithoutStepNothing(state);

  if (!currentStepName) return;

  const payload = {
    content_name: currentStepName,
    content_id: progressBarCurrentStepNumber,
  };

  ttqTrack(TIKTOK_EVENT_CATEGORY_VIEW_CONTENT, payload);
};

export const ttqTrackAddToCart = ({ state }) => {
  const currencyCode = coreBookingGetters.paymentCurrencyCode(state);
  const params = bookingQuery(
    [
      'fares',
      'outboundBundle',
      'returnBundle',
      'outboundDepartureStation:departureStationIata',
      'outboundArrivalStation:arrivalStationIata',
      'numberOfIndependentPassengers',
      'isRoundTrip',
    ],
    state
  );

  const payload = {
    value: params.isRoundTrip
      ? (params.fares.outbound.basePrice?.amount ?? 0) +
        params.fares.return.basePrice.amount
      : params.fares.outbound.basePrice.amount,
    quantity: params.isRoundTrip
      ? params.numberOfIndependentPassengers * 4
      : params.numberOfIndependentPassengers * 2,
    currency: currencyCode,
    contents: [
      {
        content_id: `${params.departureStationIata}-${params.arrivalStationIata}-${params.outboundBundle}`,
        content_name: params.outboundBundle,
        content_category: 'fare',
        value: params.fares.outbound.basePrice?.amount ?? 0,
        quantity: params.numberOfIndependentPassengers,
      },
      (params.isRoundTrip && {
        content_id: `${params.arrivalStationIata}-${params.departureStationIata}-${params.returnBundle}`,
        content_name: params.returnBundle,
        content_category: 'fare',
        value: params.fares.return.basePrice.amount,
        quantity: params.numberOfIndependentPassengers,
      }) ||
        {},
    ],
  };

  ttqTrack(TIKTOK_EVENT_CATEGORY_ADD_TO_CART, payload);
};

export const ttqTrackPurchase = ({ state }) => {
  const currencyCode = coreBookingGetters.paymentCurrencyCode(state);

  const params = successfulBookingQuery(
    [
      'outboundDepartureStationIata:departureStationIata',
      'outboundArrivalStationIata:arrivalStationIata',
      'totalPaidAmount',
      'trackingNumber',
      'passengers',
    ],
    state
  );

  const payload = {
    value: params.totalPaidAmount.amount,
    quantity: params.passengers.length,
    content_id: params.trackingNumber,
    currency: currencyCode,
    contents: [
      {
        content_id: `${params.departureStationIata}-${params.arrivalStationIata}`,
        category_type: 'booking',
      },
    ],
  };

  ttqTrack(TIKTOK_EVENT_CATEGORY_PURCHASE, payload);
};
