export const DAY_DATE_FORMAT = 'do';
export const MONTH_ONLY_DATE_FORMAT = 'M';
export const YEAR_ONLY_DATE_FORMAT = 'yyyy';
export const MONTH_AND_YEAR_DATE_FORMAT = 'M-yyyy';
export const HOUR_ONLY_DATE_FORMAT = 'H';
export const MINUTES_ONLY_DATE_FORMAT = 'm';
export const NUMERAL_DATE_FORMAT = 'P';
export const WRITTEN_SHORT_MONTH_NAME_DATE_FORMAT = 'PP';
export const DATE_AND_TIME_DATE_FORMAT = 'dd/MM/yyyy HH:mm';
export const SHORT_MONTH_AM_PM_DATE_FORMAT = 'PPP p a';
export const DEFAULT_LONG_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss`;
export const FULLY_WRITTEN_DATE_FORMAT = 'PPPP';
export const WHOLE_DATE_SHORT_MONTH_DATE_FORMAT = 'eee, PP';
export const YEAR_MONTH_DATE_FORMAT = 'yyyy-MM';
export const MONTH_FULL_NAME_DATE_FORMAT = 'MMMM';
export const MONTH_SHORT_NAME_DATE_FORMAT = 'MMM';
export const SHORT_DATE_WITH_DAY_NAME_DATE_FORMAT = 'EEE dd, MMM';
export const YEAR_LAST_TWO_DIGITS_DATE_FORMAT = 'yy';
export const YEAR_AND_MONTH_NAME_DATE_FORMAT = 'MMMM yyyy';
export const SHORT_MONTH_AND_DAY_DATE_FORMAT = 'dd MMM';
export const DAY_WITH_AM_PM_DATE_FORMAT = 'EEE p a';
export const ANALYTICS_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_DATE_FORMAT = 'HH:mm';
export const FULL_TEXTUAL_DATE_FORMAT = 'PPP';
export const DAY_NAME_DATE_FORMAT = 'EEE';
export const FULL_DAY_NAME_DATE_FORMAT = 'EEEE';
export const DAY_NUMBER_DATE_FORMAT = 'd';
export const FACEBOOK_DATE_FORMAT = 'yyyyMMdd';
export const CREDIT_CARD_DATE_FORMAT = 'MM/yy';
export const FLIGHT_DATE_FORMAT = 'dd MMM, HH:mm';
export const FLIGHT_TIME_WITH_AM_PM_FORMAT = 'HH:mm a';
