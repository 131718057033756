import { removeSessionItem } from '~/utils/storage';
import { getDefaultState } from './internal';
import * as m from './mutation-types';

export default {
  [m.RESET](state) {
    Object.assign(state, getDefaultState());
    removeSessionItem('nextFlight');
  },

  [m.NEXT_FLIGHT_UPDATE](state, nextFlight) {
    Object.assign(state, getDefaultState(), { nextFlight });
  },
};
