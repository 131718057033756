import isUndefined from '~/utils/object/is-undefined';
import deepClone from '~/utils/object/deep-clone';
import curry from '~/utils/fp/curry';

// https://developers.facebook.com/docs/marketing-api/dynamic-ads-for-flights/events/v3.1
export const fbqTrack = (event, params) => {
  const fbq = window.fbq;
  if (isUndefined(fbq)) return;

  const payload = {
    content_type: 'flight',
    departing_departure_date: params.outboundDepartureDate,
    departing_arrival_date: params.outboundArrivalDate,
    returning_departure_date: params.returnDepartureDate,
    returning_arrival_date: params.returnArrivalDate,
    origin_airport: params.departureStationIata,
    destination_airport: params.arrivalStationIata,
    num_adults: params.numberOfAdults,
    num_children: params.numberOfChildren,
    num_infants: params.numberOfInfants,
    travel_class: 'economy',
    price: params.price?.amount,
    value: params.value?.amount,
    currency: params.price?.currencyCode || params.value?.currencyCode,
  };

  // send only non-undefined params
  fbq('track', event, deepClone(payload));
};

// TODO: get tz info from BE
export const withZone = curry((dateTime) =>
  isUndefined(clarify(dateTime)) ? dateTime : `${dateTime}UTC`
);

export const clarify = curry((dateTime) => dateTime || undefined);
