export const getDefaultState = () => ({
  nextFlight: null,
});

export const mapNextFlightResponse = (response) => {
  let res = null;
  const data = response?.data?.yourNextFlight;

  if (data) {
    const {
      departureDate,
      departureStation,
      arrivalStation,
      recordLocator,
      contactLastName,
      flightNumber,
      canCheckIn,
    } = data;

    res = {
      departureDate,
      departureStation,
      arrivalStation,
      pnr: recordLocator,
      contactLastName,
      flightNumber,
      canCheckIn,
    };
  }

  return res;
};
