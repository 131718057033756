import { TRACKING_LOCATION_ITINERARY } from '~/constants';
import { createAction } from '~/utils/store';
import * as AncillaryService from '~/services/booking/ancillary';
// note ancillaries/actions probably should come before flight-select/actions
//  otherwise problems may occur (not identified yet though)
import * as getters from './getters';
import * as m from './mutation-types';

export const fetchBundleUpsell = async (store, params) => {
  const { commit } = store;
  const response = await AncillaryService._fetchBundleUpsell(params);
  const { bundleUpsells } = response.data;
  commit(m.UPDATE_UPSELL_INFO, { bundleUpsells });
};

export const fetchPostBookingBundleUpsell = async (store) => {
  const { state, commit } = store;
  if (
    !getters.shouldFetchPostBookingBundleUpsell(state) ||
    getters.isPostBookingUpgradeStarted(state)
  )
    return;

  const response = await AncillaryService._fetchPostBookingBundleUpsell();
  const { bundleUpsells } = response.data;
  commit(m.UPDATE_POST_BOOKING_UPSELL_INFO, { bundleUpsells });
};

export const resetPostBookingUpsellInfo = createAction(m.RESET_POST_BOOKING_UPSELL_INFO);

export const setPostUpgradeInfo = (
  store,
  upgradeLocation,
  upgradeBundle,
  price = null
) => {
  const { state, commit } = store;
  const upgradePrice =
    price ||
    (upgradeLocation === TRACKING_LOCATION_ITINERARY
      ? getters.postBookingBundleUpsellPrice(state)
      : getters.upsellPrice(state));
  commit(m.SET_POST_UPGRADE_INFO, {
    upgradeLocation,
    upgradePrice,
    upgradeBundle,
  });
};

export const resetPostUpgradeInfo = createAction(m.UNSET_POST_UPGRADE_INFO);

export const setIsPostBookingUpgradeStarted = createAction(
  m.SET_IS_POST_BOOKING_UPGRADE_STARTED
);

export const unsetIsPostBookingUpgradeStarted = createAction(
  m.UNSET_IS_POST_BOOKING_UPGRADE_STARTED
);
