import {
  ANCILLARIES_TO_DISPLAY,
  MATCH_ICON_TO_ANCILLARY_NAME,
} from '~/components/itinerary-common/constants';
import {
  ANCILLARY_CARRY_ON_BAG,
  ANCILLARY_HAND_BAGGAGE,
  ANCILLARY_SEAT,
} from '~/constants';
import { toCamelCase } from '~/utils/string';
import isNumber from '~/utils/object/is-number';
import propEq from './fp/prop-eq';
import isNotEmpty from './object/is-not-empty';

export const getAncillariesFromServices = (services, seatUnitDesignator = null) => {
  return [ANCILLARY_CARRY_ON_BAG, ANCILLARY_SEAT, ...Object.keys(services)]
    .reduce((acc, ancillaryName) => {
      const { name, labelKey, order } =
        ANCILLARIES_TO_DISPLAY.find(propEq('name', ancillaryName)) || {};
      if (name) {
        acc.push(
          standardizeAncillary({
            name,
            labelKey,
            order,
            labelValue: name === ANCILLARY_SEAT ? seatUnitDesignator : null,
          })
        );
      }
      return acc;
    }, [])
    .sort((ancillary1, ancillary2) => ancillary1.order - ancillary2.order);
};

export const getBaggagesRelatedAncillaries = (ancillaries) => {
  return [ANCILLARY_CARRY_ON_BAG, ...Object.keys(ancillaries)]
    .reduce((acc, ancillaryName) => {
      const { name, labelKey, order } =
        ANCILLARIES_TO_DISPLAY.find(propEq('name', ancillaryName)) || {};
      if (name) {
        acc.push(
          standardizeBaggageAncillary({
            name,
            labelKey,
            order,
          })
        );
      }
      return acc;
    }, [])
    .sort((ancillary1, ancillary2) => ancillary1.order - ancillary2.order);
};

export const standardizeAncillaries = (ancillaries) =>
  ancillaries.map(standardizeAncillary);

const standardizeAncillary = (ancillary) => {
  const name = ancillary.specificType || ancillary.name;
  const icon = MATCH_ICON_TO_ANCILLARY_NAME.get(toCamelCase(name));
  return {
    name,
    labelKey: ancillary.label || ancillary.labelKey,
    count: ancillary.displayCount || getCount(name),
    order: isNumber(ancillary.order) ? ancillary.order : null,
    label: ancillary.labelValue || null,
    icon,
  };
};

const standardizeBaggageAncillary = (ancillary) => {
  const name = ancillary.specificType || ancillary.name;
  const icon = MATCH_ICON_TO_ANCILLARY_NAME.get(toCamelCase(name))?.simple?.name;
  return {
    name,
    labelKey: ancillary.label || ancillary.labelKey,
    count: ancillary.displayCount || getCount(name),
    order: isNumber(ancillary.order) ? ancillary.order : null,
    icon,
  };
};

const getCount = (ancillaryName) => {
  if ([ANCILLARY_HAND_BAGGAGE, ANCILLARY_CARRY_ON_BAG].includes(ancillaryName)) return 1;

  const match = ancillaryName.match(/^\w?bag(\d)$/i);
  return isNotEmpty(match) ? Number(match[1]) : 0;
};
