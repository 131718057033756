import paths, { _paths } from '../paths';

/**
 * @typedef {<T, K extends keyof T>(ps: K[], obj: T) => T[K][]} PropsFn
 */

/**
 * @type PropsFn
 */
export const _props = _paths;

/**
 * Acts as multiple `prop`: array of keys in, array of values out. Preserves
 * order.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/props.js
 *
 * @type PropsFn
 * @example
 *
 *      props(['x', 'y'], {x: 1, y: 2}); //=> [1, 2]
 *      props(['c', 'a', 'b'], {b: 2, a: 1}); //=> [undefined, 1, 2]
 *
 *      const fullName = compose(join(' '), props(['first', 'last']));
 *      fullName({last: 'Bullet-Tooth', age: 33, first: 'Tony'}); //=> 'Tony Bullet-Tooth'
 */
const props = paths;

export default props;
