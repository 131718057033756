import { BUNDLE_MIDDLE, BUNDLE_PLUS } from '~/constants';

export const updateBundleAncillaryServices = (bundles = []) => {
  // Backend cannot send different label keys per bundle, so we have to hack our way into it
  return bundles.map((bundle) => {
    if ([BUNDLE_MIDDLE, BUNDLE_PLUS].includes(bundle.code.toLowerCase())) {
      bundle.ancillaryServices = bundle.ancillaryServices.map((ancillary) => {
        // Premium seats are not included in the WIZZ Go bundle
        if (
          bundle.code.toLowerCase() === BUNDLE_MIDDLE &&
          ancillary === 'bundle-ancillary-seat-selection'
        ) {
          ancillary = 'bundle-ancillary-seat-selection-excluding-premium-seats';
        }

        // Hand baggage is guaranteed to be on board in case of WIZZ Go and Plus
        if (ancillary === 'bundle-ancillary-hand-baggage') {
          ancillary = 'bundle-ancillary-hand-baggage-guaranteed';
        }

        return ancillary;
      });
    }

    return bundle;
  });
};
