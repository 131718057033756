import { FLIGHT_CHANGE_ROUTES } from '~/router/flight-change/constants';

export const getDefaultState = () => ({
  steps: Array.from(FLIGHT_CHANGE_ROUTES.entries()).map(([path, name], index) => ({
    id: name,
    path,
    name,
    order: index,
  })),
  currentStep: {},
  booking: {},
  isWarningModalVisible: false,
  isWarningModalShown: false,
  selectedPassengers: [],
  errors: {
    initializationErrors: [],
    passengerErrors: [],
    seatSelectionErrors: [],
    summaryErrors: [],
    contactErrors: [],
  },
  services: {},
});
