import applySpec from '~/utils/fp/apply-spec';
import collect from '~/utils/fp/collect';
import path from '~/utils/fp/path';
import {
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
  ACCESSIBILITY_ENABLED,
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  BUNDLE_MIDDLE,
  BUNDLE_PLUS,
  SEAT_ASSIGNMENT_RESULT_SUCCESS,
  SERVICE_NAME_WIZZ_FLEX,
  SERVICE_NAME_SEATING_TOGETHER_GUARANTEE,
  ACCESSIBILITY_DISABLED,
  ACCESSIBILITY_ENABLED_READ_ONLY,
  BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP,
  FALLBACK_CURRENCY_CODE,
  SEAT_ASSIGNMENT_RESULT_BLOCKED_BY_SEATING_TOGETHER,
  WIZZ_DOMESTIC_DISCOUNT_CLUB_COUNTRIES,
  ACCESSIBILITY_REQUIRED,
  BAGGAGE,
} from '~/constants';
// eslint-disable-next-line import/no-cycle
import { formatCurrency } from '~/utils/currency';
import curry from '~/utils/fp/curry';
import pathOr from '~/utils/fp/path-or';
import either from '~/utils/fp/either';
import compose from '~/utils/fp/compose';
import complement from '~/utils/fp/complement';
import pathEq from '~/utils/fp/path-eq';
import add from '~/utils/fp/add';
import propEqTrue from '~/utils/fp/prop-eq-true';
import propEq from '~/utils/fp/prop-eq';
import values from '~/utils/fp/values';
import propNotEq from '~/utils/fp/prop-not-eq';
import all from '~/utils/fp/all';
import prop from '~/utils/fp/prop';
import length from '~/utils/fp/length';
import always from '~/utils/fp/always';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import sum from '~/utils/fp/sum';
import isNotNil from '~/utils/object/is-not-nil';
import isNil from '~/utils/object/is-nil';
import hassocPath from '~/utils/object/hassoc-path';
import { BAG_CODE_TO_VARIANT_MAP } from '~/constants/analytics';
import orElse from '~/utils/fp/or-else';
import map from '~/utils/fp/map';
import ifElse from '~/utils/fp/if-else';
import isNotEmpty from '~/utils/object/is-not-empty';
import evolve from '~/utils/fp/evolve';
import assoc from '~/utils/fp/assoc';
import identity from '~/utils/fp/identity';
import pathNotEq from '~/utils/fp/path-not-eq';
import isEmpty from '~/utils/object/is-empty';
import equals from '~/utils/fp/equals';
import omit from '~/utils/fp/omit';
import { isOutboundFlightFlown } from '~/store/modules/itinerary/getters';
// eslint-disable-next-line import/no-cycle
import { isValidDate, toDate } from '~/utils/date';
import {
  FLASH_PROMO_STATUS_ACTIVATED,
  FLASH_PROMO_STATUS_INACTIVE,
  FLASH_PROMO_STATUS_RUNNING,
} from './constants';
import { selectedFlightPriorityBoardingCount, hasChangedAncillaries } from './internal';

const ancillariesProp = curry((prop, state) => path(prop, state.ancillaries));

const ancillariesPropWithFallback = curry((fallbackValue, prop, state) =>
  pathOr(fallbackValue, prop, state.ancillaries)
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const outboundFlight = ancillariesProp('outboundFlight');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const returnFlight = ancillariesProp('returnFlight');

/**
 * @type {(state: State) => Promise}
 */
export const debouncedPostAncillariesPromise = ancillariesProp(
  'debouncedPostAncillariesPromise'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const unsavedAncillaries = ancillariesProp('unsavedAncillaries');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const previouslySelectedOutboundSeatCodes = (state) => {
  return [...ancillariesProp('previouslySelectedSeats.outboundSeats', state)];
};

/**
 * @type {(state: State) => Object<string, any>}
 */
export const previouslySelectedReturnSeatCodes = (state) => {
  return [...ancillariesProp('previouslySelectedSeats.returnSeats', state)];
};

/**
 * @type {(state: State) => Object<string, any>}
 */
export const allUnsavedAncillaries = ancillariesProp('allUnsavedAncillaries');

/**
 * @type {(state: State) => boolean}
 */
export const hasUnsavedAncillaries = compose(hasChangedAncillaries, unsavedAncillaries);

/**
 * @type {(state: State) => boolean}
 */
export const hasNoUnsavedAncillaries = complement(hasUnsavedAncillaries);

/**
 * @type {(flight: Object<string, any>) => Object<string, any>}
 */
const transformFlight = ifElse(
  isNotEmpty,
  evolve({
    baggage: {
      options: map((option) => assoc('variant', codeToVariant(option.code), option)),
    },
  }),
  identity
);

const transformFlights = evolve({
  outboundFlight: transformFlight,
  returnFlight: transformFlight,
});

const codeToVariant = (code) => {
  return BAG_CODE_TO_VARIANT_MAP.get(code);
};

export const passengerAncillaries = compose(
  map(transformFlights),
  orElse([]),
  ancillariesProp('passengers')
);

export const isForcedCurrencyChange = ancillariesProp('isForcedCurrencyChange');

export const isPriorityBoardingAlreadyAdded = ancillariesProp(
  'priorityBoardingAddSource'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
// airportParking's main content comes from its own API call, some with ancillaries GET
// so we need to merge it with the one in ancillaries.services...
export const services = (state) => {
  const ancillaryServices = { ...state.ancillaries.services };

  const airportParking = {
    ...ancillariesAirportParkingService(state),
    ...airportParkingService(state),
  };

  if (isNotEmpty(ancillariesAirportParkingService(state)?.accessibility)) {
    airportParking.accessibility = ancillariesAirportParkingService(state).accessibility;
  }

  if (isNotEmpty(ancillaryServices.outboundFlightServices)) {
    hassocPath(
      'outboundFlightServices.airportParking',
      airportParking,
      ancillaryServices
    );
  }
  if (isNotEmpty(ancillaryServices.returnFlightServices)) {
    hassocPath('returnFlightServices.airportParking', airportParking, ancillaryServices);
  }

  checkAndTransformV2Service(state, ancillaryServices, 'loungeAccess');
  checkAndTransformV2Service(state, ancillaryServices, 'fastTrackSecurity');

  if (isOutboundFlightFlown(state)) {
    return {
      ...ancillaryServices,
      bookingLevelServices: omit(
        ['acordService'],
        ancillaryServices.bookingLevelServices
      ),
    };
  }

  return ancillaryServices;
};

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wizzFlex = (state) =>
  passengerAncillaries(state)?.[0]?.outboundFlight?.[SERVICE_NAME_WIZZ_FLEX] ?? null;

/**
 * @type {(state: State) => Object<string, any>}
 */

export const seatingTogetherGuarantee = (state) =>
  passengerAncillaries(state)?.[0]?.outboundFlight?.[
    SERVICE_NAME_SEATING_TOGETHER_GUARANTEE
  ] ?? null;

export const wizzFlexOptionsByFlights = (state) => ({
  outboundFlight:
    passengerAncillaries(state)?.[0]?.outboundFlight?.[SERVICE_NAME_WIZZ_FLEX]
      ?.options?.[0] ?? null,
  returnFlight:
    passengerAncillaries(state)?.[0]?.returnFlight?.[SERVICE_NAME_WIZZ_FLEX]
      ?.options?.[0] ?? null,
});

export const baggagePrice = (state) => {
  const lowestPrice = passengerAncillaries(state).reduce((minPrice, passenger) => {
    const outboundBaggagePrices =
      passenger.outboundFlight?.baggage?.options
        ?.filter((option) => option.price.amount > 0)
        .map((option) => option.price.amount) || [];

    const returnBaggagePrices =
      passenger.returnFlight?.baggage?.options
        ?.filter((option) => option.price.amount > 0)
        .map((option) => option.price.amount) || [];

    const allBaggagePrices = [...outboundBaggagePrices, ...returnBaggagePrices];

    if (allBaggagePrices.length > 0) {
      const minPassengerPrice = Math.min(...allBaggagePrices);
      return Math.min(minPassengerPrice, minPrice);
    }

    return minPrice;
  }, Number.POSITIVE_INFINITY);

  return lowestPrice !== Number.POSITIVE_INFINITY ? lowestPrice : 0;
};

/**
 * @type {(state: State) => Object<array, any>}
 */
export const firstPassengerBaggageOptions = (state) =>
  passengerAncillaries(state)?.[0]?.outboundFlight?.[BAGGAGE]?.options ?? [];

/**
 * @type {(state: State) => Boolean}
 */
export const hasWizzFlex = compose(isNotEmpty, wizzFlex);

/**
 * @type {(state: State) => boolean}
 */
export const isWizzFlexSelected = ifElse(
  hasWizzFlex,
  compose(prop('selected'), wizzFlex),
  always(false)
);

/**
 * @type {(state: State) => boolean}
 */
export const isWizzFlexSelectable = ifElse(
  hasWizzFlex,
  compose(propEq('accessibility', ACCESSIBILITY_ENABLED), wizzFlex),
  always(false)
);

export const isExclusiveLoungeInOutboundSelected = ancillariesPropWithFallback(
  false,
  'services.outboundFlightServices.exclusiveLounge.selected'
);

export const isExclusiveLoungeInReturnSelected = ancillariesPropWithFallback(
  false,
  'services.returnFlightServices.exclusiveLounge.selected'
);

export const shouldFetchLoungeAccessV2 = (state) => {
  return (
    isServiceEnabledOrReadOnly(
      ancillariesProp('services.outboundFlightServices.loungeAccessV2', state)
    ) ||
    isServiceEnabledOrReadOnly(
      ancillariesProp('services.returnFlightServices.loungeAccessV2', state)
    )
  );
};

export const bundles = (state) => state.ancillaries.bundles;

export const isAncillaryPostPending = (state) => state.ancillaries.isAncillaryPostPending;

export const isRawAncillaryPostPending = (state) =>
  state.ancillaries.isRawAncillaryPostPending;

export const selectedOutboundFlightPriorityBoardingCount = (state) =>
  selectedFlightPriorityBoardingCount(state.ancillaries, 'outboundFlight');

export const selectedReturnFlightPriorityBoardingCount = (state) =>
  selectedFlightPriorityBoardingCount(state.ancillaries, 'returnFlight');

export const isPriorityBoardingServiceAddedOnOutbound = ancillariesPropWithFallback(
  false,
  'services.outboundFlightServices.priorityBoarding.selected'
);

export const isPriorityBoardingServiceAddedOnReturn = ancillariesPropWithFallback(
  false,
  'services.returnFlightServices.priorityBoarding.selected'
);

export const isCarRentalAvailable = (state) => isRentalcarsAvailable(state);

const rentalcarsService = (state) =>
  state.ancillaries?.services?.bookingLevelServices?.carRental;

export const isRentalcarsAvailable = (state) =>
  rentalcarsService(state)
    ? rentalcarsService(state)?.accessibility !== ACCESSIBILITY_DISABLED
    : false;

const carTrawlerService = (state) =>
  state.ancillaries?.services?.bookingLevelServices?.carTrawler;

export const isCarTrawlerAvailable = (state) =>
  carTrawlerService(state)
    ? carTrawlerService(state)?.accessibility !== ACCESSIBILITY_DISABLED
    : false;

export const selectedCarTrawler = (state) => state.ancillaries.selectedCarTrawler;

export const hasSelectedCarTrawler = (state) =>
  isNotEmpty(state.ancillaries.selectedCarTrawler);

export const insuranceService = (state) =>
  state.ancillaries?.services?.bookingLevelServices?.acordService;

export const isInsuranceAvailable = (state) =>
  [ACCESSIBILITY_REQUIRED, ACCESSIBILITY_ENABLED, ACCESSIBILITY_ENABLED_READ_ONLY].some(
    equals(insuranceService(state)?.accessibility)
  );

export const unsupportedCarRentalCurrencies = ancillariesPropWithFallback(
  [],
  'unsupportedCarRentalCurrencies'
);

export const unsupportedCarTrawlerCurrencies = ancillariesPropWithFallback(
  [],
  'unsupportedCarTrawlerCurrencies'
);

export const isPriorityBoardingServiceAddedOnEitherFlight = either(
  isPriorityBoardingServiceAddedOnOutbound,
  isPriorityBoardingServiceAddedOnReturn
);

export const noneOfThePassengersHasPriorityBoardingOnAllFlights = (state) =>
  passengerAncillaries(state).some((passenger) =>
    [passenger[OUTBOUND_FLIGHT], passenger[RETURN_FLIGHT]]
      .filter(Boolean)
      .every((flight) => !flight.priorityBoarding.selected)
  );

export const everyPassengersHasPriorityBoardingOnAllFlights = (state) =>
  passengerAncillaries(state).every((passenger) =>
    [passenger[OUTBOUND_FLIGHT], passenger[RETURN_FLIGHT]]
      .filter(Boolean)
      .every((flight) => flight.priorityBoarding.selected)
  );

const isPriorityBoardingOnFlightAvailable = curry((direction, state) =>
  passengerAncillaries(state).some(
    pathEq(`${direction}Flight.priorityBoarding.accessibility`, ACCESSIBILITY_ENABLED)
  )
);

const isPriorityBoardingOnFlightEnabledReadOnly = curry((direction, state) =>
  passengerAncillaries(state).some(
    pathEq(
      `${direction}Flight.priorityBoarding.accessibility`,
      ACCESSIBILITY_ENABLED_READ_ONLY
    )
  )
);

export const isPriorityBoardingOnOutboundFlightEnabledReadOnly =
  isPriorityBoardingOnFlightEnabledReadOnly(OUTBOUND_FLIGHT_NAME);

export const isPriorityBoardingOnReturnFlightEnabledReadOnly =
  isPriorityBoardingOnFlightEnabledReadOnly(RETURN_FLIGHT_NAME);

export const isPriorityBoardingOnOutboundFlightAvailable =
  isPriorityBoardingOnFlightAvailable(OUTBOUND_FLIGHT_NAME);

export const isPriorityBoardingOnReturnFlightAvailable =
  isPriorityBoardingOnFlightAvailable(RETURN_FLIGHT_NAME);

export const isWizzFlexOnFlightAvailable = curry((direction, state) =>
  passengerAncillaries(state).some(
    pathNotEq(`${direction}Flight.wizzFlex.accessibility`, ACCESSIBILITY_DISABLED)
  )
);

export const isWizzFlexOnFlightReadOnly = curry((direction, state) =>
  passengerAncillaries(state).some(
    pathEq(`${direction}Flight.wizzFlex.accessibility`, ACCESSIBILITY_ENABLED_READ_ONLY)
  )
);

export const isWizzFlexOnFlightIncludedInBundle = curry((direction, state) =>
  passengerAncillaries(state).some(
    pathEq(`${direction}Flight.wizzFlex.isIncludedInBundle`, true)
  )
);

export const isWizzFlexIncludedInBundle =
  isWizzFlexOnFlightIncludedInBundle(OUTBOUND_FLIGHT_NAME) ||
  isWizzFlexOnFlightIncludedInBundle(RETURN_FLIGHT_NAME);

export const isWizzFlexOnOutboundFlightReadOnly =
  isWizzFlexOnFlightReadOnly(OUTBOUND_FLIGHT_NAME);

export const isWizzFlexOnOutboundFlightAvailable =
  isWizzFlexOnFlightAvailable(OUTBOUND_FLIGHT_NAME);

export const isWizzFlexOnReturnFlightAvailable =
  isWizzFlexOnFlightAvailable(RETURN_FLIGHT_NAME);

export const isWizzFlexChosen = (state) => {
  return (
    somePassengerHasWizzFlexOnEitherFlight(state) || isWizzFlexIncludedInBundle(state)
  );
};

export const numberOfWizzFlexSelected = (state) => {
  return passengerAncillaries(state).filter(
    (passenger) => passenger?.outboundFlight?.wizzFlex?.selected
  ).length;
};

export const somePassengerHasWizzFlexOnEitherFlight = (state) =>
  passengerAncillaries(state).some((passenger) =>
    [passenger[OUTBOUND_FLIGHT], passenger[RETURN_FLIGHT]]
      .filter(Boolean)
      .some((flight) => flight.wizzFlex.selected)
  );

export const allPassengerHasWizzFlexOnEitherFlight = (state) =>
  passengerAncillaries(state).every((passenger) =>
    [passenger[OUTBOUND_FLIGHT]]
      .filter(Boolean)
      .every((flight) => flight.wizzFlex.selected)
  );

export const somePassengerHasPriorityBoardingOnEitherFlight = (state) =>
  passengerAncillaries(state).some((passenger) =>
    [passenger[OUTBOUND_FLIGHT], passenger[RETURN_FLIGHT]]
      .filter(Boolean)
      .some((flight) => flight.priorityBoarding.selected)
  );

const passengerAncillariesByDirection = curry((direction, state) => {
  return passengerAncillaries(state).reduce((acc, passenger) => {
    const ancillaries = passenger[`${direction}Flight`] || {};
    acc.push(...Object.values(ancillaries));
    return acc;
  }, []);
});

export const outboundPassengerAncillaries =
  passengerAncillariesByDirection(OUTBOUND_FLIGHT_NAME);

export const returnPassengerAncillaries =
  passengerAncillariesByDirection(RETURN_FLIGHT_NAME);

/**
 * @type {(state: State) => boolean}
 */
const isSeatAssignmentSuccessful = curry((direction, state) =>
  [
    SEAT_ASSIGNMENT_RESULT_SUCCESS,
    SEAT_ASSIGNMENT_RESULT_BLOCKED_BY_SEATING_TOGETHER,
  ].includes(ancillariesProp(`${direction}Flight.seatAssignmentResult`, state))
);

/**
 * @type {(state: State) => string}
 */
export const isOutboundSeatAssignmentSuccessful =
  isSeatAssignmentSuccessful(OUTBOUND_FLIGHT_NAME);

/**
 * @type {(state: State) => string}
 */
export const isReturnSeatAssignmentSuccessful =
  isSeatAssignmentSuccessful(RETURN_FLIGHT_NAME);

/**
 * @type {(state: State) => boolean}
 */
export const isOutboundSeatAssignmentBlockedBySeatingTogheter = compose(
  equals(SEAT_ASSIGNMENT_RESULT_BLOCKED_BY_SEATING_TOGETHER),
  ancillariesProp('outboundFlight.seatAssignmentResult')
);

/**
 * @type {(state: State) => boolean}
 */
export const isReturnSeatAssignmentBlockedBySeatingTogheter = compose(
  equals(SEAT_ASSIGNMENT_RESULT_BLOCKED_BY_SEATING_TOGETHER),
  ancillariesProp('returnFlight.seatAssignmentResult')
);

export const isOutboundAirportCheckInPurchased = compose(
  equals(ACCESSIBILITY_ENABLED_READ_ONLY),
  ancillariesProp('services.outboundFlightServices.airportCheckIn.accessibility')
);

export const isReturnAirportCheckInPurchased = compose(
  equals(ACCESSIBILITY_ENABLED_READ_ONLY),
  ancillariesProp('services.returnFlightServices.airportCheckIn.accessibility')
);

export const isOutboundAutoCheckInPurchased = compose(
  equals(ACCESSIBILITY_ENABLED_READ_ONLY),
  ancillariesProp('services.outboundFlightServices.autoCheckIn.accessibility')
);

export const isReturnAutoCheckInPurchased = compose(
  equals(ACCESSIBILITY_ENABLED_READ_ONLY),
  ancillariesProp('services.returnFlightServices.autoCheckIn.accessibility')
);

export const isAirportParkingAvailable = (state) => {
  return airportParkingService(state).accessibility !== ACCESSIBILITY_DISABLED;
};

const airportParkingService = (state) => {
  return state.ancillaries?.airportParking;
};

const ancillariesAirportParkingService = (state) => {
  return state.ancillaries?.services?.outboundFlightServices?.airportParking;
};

const getExternalServiceV2FlightOption = (state, serviceName, direction) => {
  return state.ancillaries?.[`${serviceName}V2`]?.data?.[`${direction}FlightOption`];
};

export const selectedAirportParkingOperator = (state) => {
  const selectedOperator = state.ancillaries.airportParking.selectedOperator;
  return isNil(selectedOperator) ? [] : selectedOperator;
};

export const plateNumber = ancillariesProp('airportParking.plateNumber');
export const isAirportParkingLoading = ancillariesProp('airportParking.isLoading');
export const airportParkingErrors = ancillariesProp('airportParking.errors');
export const isAirportParkingAlreadyPurchased = (state) => {
  return (
    state.ancillaries?.services?.outboundFlightServices?.airportParking?.accessibility ===
    ACCESSIBILITY_ENABLED_READ_ONLY
  );
};

export const isAirportParkingDisabledInAncillaries = (state) => {
  const airportParkingAccessibility =
    state.ancillaries?.services?.outboundFlightServices?.airportParking?.accessibility;
  return airportParkingAccessibility
    ? airportParkingAccessibility === ACCESSIBILITY_DISABLED
    : true;
};

export const isAirportParkingDisabled = (state) => {
  return state.ancillaries.airportParking.errors.includes('AirportParkingNotAvailable');
};

export const minNonZeroPriorityBoardingPrice = (state) => {
  return passengerAncillaries(state)
    .flatMap((passenger) => [
      passenger[OUTBOUND_FLIGHT]?.priorityBoarding?.options[0]?.price,
      passenger[RETURN_FLIGHT]?.priorityBoarding?.options[0]?.price,
    ])
    .filter((price) => price && price.amount > 0)
    .reduce((minPrice, price) => (price.amount < minPrice.amount ? price : minPrice), {
      amount: Number.POSITIVE_INFINITY,
    });
};

export const outboundSeatPrices = ancillariesProp('outboundFlight.seatPrices');

export const returnSeatPrices = ancillariesProp('returnFlight.seatPrices');

export const selectedServiceNames = (state) => {
  const selectedServiceNames = [];
  [
    state.ancillaries?.services?.outboundFlightServices,
    state.ancillaries?.services?.bookingLevelServices,
    state.ancillaries?.services?.returnFlightServices,
  ].forEach((item) => {
    Object.keys(item).forEach((service) => {
      if (item[service].selected) {
        selectedServiceNames.push(service);
      }
    });
  });
  return selectedServiceNames;
};

export const outboundNumberOfSeatsAssigned = (state) => {
  return passengerAncillaries(state).filter((passenger) =>
    isNotNil(passenger?.outboundFlight?.seatAssignment?.sold)
  ).length;
};

export const returnNumberOfSeatsAssigned = (state) => {
  return passengerAncillaries(state).filter((passenger) =>
    isNotNil(passenger?.returnFlight?.seatAssignment?.sold)
  ).length;
};

export const numberOfSeatsSelected = (state) => {
  return passengerAncillaries(state).filter((passenger) =>
    isNotNil(passenger?.outboundFlight?.seatAssignment?.current)
  ).length;
};

export const baggageSelected = (state) => {
  return passengerAncillaries(state)
    .filter((passenger) => {
      const selected = passenger?.outboundFlight?.baggage?.selected;
      return isNotEmpty(selected) && selected !== 'none';
    })
    .map((passenger) => passenger?.outboundFlight?.baggage?.selected);
};

export const numberOfWizzPrioritySelected = (state) => {
  return passengerAncillaries(state).filter(
    (passenger) => passenger?.outboundFlight?.priorityBoarding?.selected
  ).length;
};

const numberOfOutboundSportsEquipment = (state) => {
  return passengerAncillaries(state).filter((passenger) =>
    propEqTrue('selected', passenger.outboundFlight.sportsEquipment)
  ).length;
};

const numberOfReturnSportsEquipment = (state) => {
  return passengerAncillaries(state).filter((passenger) =>
    propEqTrue('selected', passenger?.returnFlight?.sportsEquipment)
  ).length;
};

export const numberOfSportsEquipment = (state) =>
  add(numberOfOutboundSportsEquipment(state), numberOfReturnSportsEquipment(state));

const numberOfOutboundCheckedInBags = (state) => {
  return passengerAncillaries(state).reduce(
    (acc, { outboundFlight }) =>
      add(
        acc,
        BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP.get(outboundFlight.baggage.selected)
          ?.count ?? 0
      ),
    0
  );
};

const numberOfReturnCheckedInBags = (state) => {
  return passengerAncillaries(state).reduce(
    (acc, { returnFlight }) =>
      add(
        acc,
        BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP.get(returnFlight?.baggage?.selected)
          ?.count ?? 0
      ),
    0
  );
};

const numberOfCheckedInBags = (state) =>
  add(numberOfOutboundCheckedInBags(state), numberOfReturnCheckedInBags(state));

const numberOfPriorityBoardings = (state) =>
  add(
    selectedOutboundFlightPriorityBoardingCount(state),
    selectedReturnFlightPriorityBoardingCount(state)
  );

export const numberOfCheckedInBagsAndPriorityBoardings = (state) =>
  add(numberOfCheckedInBags(state), numberOfPriorityBoardings(state));

export const bundlesMiddle = (state) => {
  return getAncillaryServices(state, BUNDLE_MIDDLE);
};

export const bundlesPlus = (state) => {
  return getAncillaryServices(state, BUNDLE_PLUS);
};

const getAncillaryServices = (state, bundleCode) => {
  const bundle = state.ancillaries.bundles.find((bundle) => bundle.code === bundleCode);
  if (!bundle) return [];
  return bundle.ancillaryServices;
};

export const seatAssignment = (state) => {
  return {
    outbound: ancillariesProp('outboundFlight.currentSeatAssignments')(state),
    return: ancillariesProp('returnFlight.currentSeatAssignments')(state),
  };
};

const outboundAutoCheckIn = ancillariesPropWithFallback(
  null,
  `services.outboundFlightServices.autoCheckIn`
);

const returnAutoCheckIn = ancillariesPropWithFallback(
  null,
  `services.returnFlightServices.autoCheckIn`
);

export const autoCheckIn = applySpec({
  outbound: outboundAutoCheckIn,
  return: returnAutoCheckIn,
});

const outboundAutoCheckInPrice = (state) => outboundAutoCheckIn(state)?.price;

const returnAutoCheckInPrice = (state) => returnAutoCheckIn(state)?.price;

export const isOutboundAutoCheckInSelected = ancillariesPropWithFallback(
  false,
  `services.outboundFlightServices.autoCheckIn.selected`
);

export const isReturnAutoCheckInSelected = ancillariesPropWithFallback(
  false,
  `services.returnFlightServices.autoCheckIn.selected`
);

export const isOutboundAutoCheckInIncludedInBundle = ancillariesPropWithFallback(
  false,
  `services.outboundFlightServices.autoCheckIn.isIncludedInBundle`
);

export const isReturnAutoCheckInIncludedInBundle = ancillariesPropWithFallback(
  false,
  `services.returnFlightServices.autoCheckIn.isIncludedInBundle`
);

const outboundAutoCheckInPriceAmount = compose(
  pathOr(0, 'amount'),
  outboundAutoCheckInPrice
);

const returnAutoCheckInPriceAmount = compose(pathOr(0, 'amount'), returnAutoCheckInPrice);

export const autoCheckInPrice = applySpec({
  amount: compose(
    sum,
    collect([outboundAutoCheckInPriceAmount, returnAutoCheckInPriceAmount])
  ),
  currencyCode: compose(
    pathOr(FALLBACK_CURRENCY_CODE, 'currencyCode'),
    outboundAutoCheckInPrice
  ),
});

export const wdcMembershipUpgradeChoice = (state) => {
  return ancillariesProp('priceDetails.wdcMembershipUpgradeChoice')(state) || null;
};

export const isWizzDomesticClub = ancillariesPropWithFallback(
  null,
  'priceDetails.wdcMembershipUpgradeChoice.isDomestic'
);

export const wizzDomesticClubType = (state) => {
  if (isNil(isWizzDomesticClub(state))) return '';
  const domesticCountry = ancillariesProp(
    'priceDetails.wdcMembershipUpgradeChoice.countryCode'
  )(state);
  return WIZZ_DOMESTIC_DISCOUNT_CLUB_COUNTRIES.get(domesticCountry);
};

export const isWdcMembershipUpgradeChoiceAvailable = compose(
  isNotEmpty,
  wdcMembershipUpgradeChoice
);

export const wdcMembershipPrice = (state) => {
  if (isNil(wdcMembershipUpgradeChoice(state))) return {};
  const { costOfMembership } = wdcMembershipUpgradeChoice(state);
  return costOfMembership ? formatCurrency(costOfMembership) : '';
};

export const wdcPromotedMembershipPrice = (state) => {
  if (isNil(wdcMembershipUpgradeChoice(state))) return {};
  const { promotedCostOfMembership } = wdcMembershipUpgradeChoice(state);
  return promotedCostOfMembership ? formatCurrency(promotedCostOfMembership) : '';
};

export const wdcPromotedMembershipPercentage = (state) => {
  if (isNil(wdcMembershipUpgradeChoice(state))) return {};
  const { costOfMembership, promotedCostOfMembership } =
    wdcMembershipUpgradeChoice(state);
  return Math.round(
    promotedCostOfMembership
      ? 100 - promotedCostOfMembership.amount / (costOfMembership.amount / 100)
      : 0
  );
};

export const totalWdcDiscount = (state) => {
  if (isNil(wdcMembershipUpgradeChoice(state))) return {};
  const { totalDiscount } = wdcMembershipUpgradeChoice(state);
  return totalDiscount ?? null;
};

/**
 * @type {(state: State) => boolean}
 */
export const isWdcMembershipUpgradeDiscountAvailable =
  compose(gt(__, 0), prop('amount'), totalWdcDiscount) || false;

export const shouldFetchFastTrackSecurityV2 = (state) => {
  return (
    isServiceEnabledOrReadOnly(
      ancillariesProp('services.outboundFlightServices.fastTrackSecurityV2', state)
    ) ||
    isServiceEnabledOrReadOnly(
      ancillariesProp('services.returnFlightServices.fastTrackSecurityV2', state)
    )
  );
};

const checkAndTransformV2Service = (state, ancillaryServices, serviceName) => {
  const outboundService = transformV2Service(state, serviceName, OUTBOUND_FLIGHT_NAME);
  hassocPath(`outboundFlightServices.${serviceName}`, outboundService, ancillaryServices);
  setV2DisabledIfEmpty(state, ancillaryServices, serviceName, OUTBOUND_FLIGHT_NAME);

  if (isNotEmpty(ancillaryServices.returnFlightServices)) {
    const returnService = transformV2Service(state, serviceName, RETURN_FLIGHT_NAME);
    hassocPath(`returnFlightServices.${serviceName}`, returnService, ancillaryServices);
    setV2DisabledIfEmpty(state, ancillaryServices, serviceName, RETURN_FLIGHT_NAME);
  }
};

const transformV2Service = (state, serviceName, direction) => {
  const service = {
    ...ancillariesProp(`services.${direction}FlightServices.${serviceName}`, state),
  };
  const serviceV2 = {
    ...ancillariesProp(`services.${direction}FlightServices.${serviceName}V2`, state),
  };

  const versionsLost = {
    isLost: false,
    isV1Available: false,
    isV2Available: false,
    isV1Lost: false,
    isV2Lost: false,
    contactEmail: serviceV2?.contactEmail ?? '',
  };

  if (isServiceEnabledOrReadOnly(service) && !service.isLost) {
    versionsLost.isV1Available = true;
  }

  if (serviceV2?.isLost) {
    versionsLost.isV2Lost = true;
    versionsLost.isLost = true;
  }

  if (service?.isLost && !serviceV2?.isLost) {
    versionsLost.isV1Lost = true;
    versionsLost.isLost = true;
  }

  if (isServiceEnabledOrReadOnly(serviceV2)) {
    const externalServiceV2 = getExternalServiceV2FlightOption(
      state,
      serviceName,
      direction
    );
    Object.assign(service, serviceV2);

    if (isNotEmpty(externalServiceV2)) {
      Object.assign(service, externalServiceV2);
      service.isV2Available = true;
      versionsLost.isV2Available = true;
      if (isServiceReadOnly(serviceV2) && isServiceEnabled(externalServiceV2)) {
        service.accessibility = ACCESSIBILITY_ENABLED_READ_ONLY;
      }
    } else if (isServiceReadOnly(serviceV2)) {
      service.accessibility = ACCESSIBILITY_ENABLED_READ_ONLY;
      service.isV2Available = true;
    } else {
      service.accessibility = ACCESSIBILITY_DISABLED;
    }
  }

  if (versionsLost?.isV1Lost || versionsLost?.isV2Lost) {
    Object.assign(service, versionsLost);
    service.accessibility = ACCESSIBILITY_ENABLED_READ_ONLY;
    service.selected = true;
  }

  return service;
};

const setV2DisabledIfEmpty = (state, ancillaryServices, serviceName, direction) => {
  const v2Service = {
    ...ancillariesProp(`services.${direction}FlightServices.${serviceName}V2`, state),
  };

  if (isServiceEnabledOrReadOnly(v2Service)) {
    const externalServiceV2 = getExternalServiceV2FlightOption(
      state,
      serviceName,
      direction
    );

    if (isEmpty(externalServiceV2)) {
      v2Service.accessibility = ACCESSIBILITY_DISABLED;
      hassocPath(
        `${direction}FlightServices.${serviceName}V2`,
        v2Service,
        ancillaryServices
      );
    }
  }
};

const isServiceEnabled = (service) => service?.accessibility === ACCESSIBILITY_ENABLED;

const isServiceReadOnly = (service) =>
  service?.accessibility === ACCESSIBILITY_ENABLED_READ_ONLY;

const isServiceEnabledOrReadOnly = (service) =>
  isServiceEnabled(service) || isServiceReadOnly(service);

export const isLoungeAccessV2Loading = ancillariesProp('loungeAccessV2.isLoading');

export const isFastTrackSecurityV2Loading = ancillariesProp(
  'fastTrackSecurityV2.isLoading'
);

export const isWdcSavingSet = ancillariesProp('isWdcSavingSet');

const seatingTogetherGuaranteeService = ancillariesPropWithFallback(
  null,
  'services.bookingLevelServices.seatingTogetherGuarantee'
);

export const seatingTogetherGuaranteeServicePrice = compose(
  pathOr(null, 'price'),
  seatingTogetherGuaranteeService
);

export const isSeatingTogetherGuaranteeAncillaryAvailable = compose(
  equals(ACCESSIBILITY_ENABLED),
  pathOr('', 'accessibility'),
  seatingTogetherGuaranteeService
);

export const isSeatingTogetherGuaranteeAncillaryLost = compose(
  pathOr(false, 'isLost'),
  seatingTogetherGuaranteeService
);

export const isSeatingTogetherGuaranteeSelected = compose(
  pathOr(false, 'selected'),
  seatingTogetherGuaranteeService
);

/**
 * @type {(state: State) => boolean}
 */
export const isAnySeatAddedToCart = (state) => {
  const passengersList = ancillariesPropWithFallback(
    [],
    'priceDetails.seats.passengersList',
    state
  );

  return length(passengersList) > 0;
};

export const priceDetails = ancillariesProp('priceDetails');

export const wizzSafeBanners = ancillariesProp('wizzSafeBanners');

export const flashPromotedBags = (state) =>
  firstPassengerBaggageOptions(state).reduce((options, option) => {
    if (isNotNil(option.promotedPrice)) options.push(option.option);
    return options;
  }, []);

export const flashPromotedBagWeight = (state) => {
  const bags = flashPromotedBags(state);
  if (isEmpty(bags)) return null;
  return BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP.get(bags[0])?.weightInKg ?? null;
};

export const flashPromos = ancillariesProp('flashPromo.promotionIds');

export const flashPromoValidUntil = (state) => {
  const date = ancillariesProp('flashPromo.validUntil')(state);
  return isValidDate(date) ? toDate(date) : null;
};

export const isFlashPromoRunning = compose(
  equals(FLASH_PROMO_STATUS_RUNNING),
  ancillariesProp('flashPromo.status')
);

export const isFlashPromoActivated = compose(
  equals(FLASH_PROMO_STATUS_ACTIVATED),
  ancillariesProp('flashPromo.status')
);

export const isFlashPromoInactive = compose(
  equals(FLASH_PROMO_STATUS_INACTIVE),
  ancillariesProp('flashPromo.status')
);

export const isWdcMembershipActivated = ancillariesProp('activatedMemberships.wdc');

export const isPrivilegePassActivated = ancillariesProp(
  'activatedMemberships.privilegePass'
);

export const isEveryAncillaryServicePurchased = (state) => {
  const { bookingLevelServices, outboundFlightServices, returnFlightServices } =
    services(state);

  const filteredBookingLevelServices = Object.fromEntries(
    Object.entries(bookingLevelServices).filter(([key]) =>
      ['airportTransfers', 'acordService', 'seatingTogetherGuarantee'].includes(key)
    )
  );

  const filteredOutboundServices = Object.fromEntries(
    Object.entries(outboundFlightServices).filter(
      ([key]) => key !== 'priorityBoarding' && key !== 'delayWarranty'
    )
  );
  const filteredReturnServices = Object.fromEntries(
    Object.entries(returnFlightServices).filter(
      ([key]) => key !== 'priorityBoarding' && key !== 'delayWarranty'
    )
  );

  return (
    all(
      propNotEq('accessibility', ACCESSIBILITY_ENABLED),
      values(filteredBookingLevelServices)
    ) &&
    all(
      propNotEq('accessibility', ACCESSIBILITY_ENABLED),
      values(filteredOutboundServices)
    ) &&
    all(propNotEq('accessibility', ACCESSIBILITY_ENABLED), values(filteredReturnServices))
  );
};
