import curry from '../curry';
import { _has } from '../has';

/**
 * @typedef {(names: string[], obj: Object<string, any>) => Object<string, any>} OmitFn
 */

/**
 * @type OmitFn
 */
export const _omit = (names, obj) => {
  const indexObj = {};
  for (const name of names) {
    indexObj[name] = 1;
  }

  const result = {};
  for (const prop in obj) {
    if (!_has(prop, indexObj)) {
      result[prop] = obj[prop];
    }
  }

  return result;
};

/**
 * Returns a partial copy of an object omitting the keys specified.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/omit.js
 *
 * @type OmitFn
 * @example
 *
 *      omit(['a', 'd'], {a: 1, b: 2, c: 3, d: 4}); //=> {b: 2, c: 3}
 */
const omit = curry(_omit);

export default omit;
