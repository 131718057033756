import log from 'loglevel';

import {
  BOOKING_TITLE,
  FLOODLIGHT_COUNT_ALLPAGES,
  STEP_PAYMENT,
  STEP_SELECT_FLIGHT,
} from '~/constants';
import {
  ANALYTICS_CURRENCY_CODE,
  ANALYTICS_EXCLUDED_FROM_HASHCHANGE,
  ANALYTICS_EXCLUDED,
  GA_PHONE_NUMBER_EVENT,
  DIMENSION_CLIENT_ID,
  DIMENSION_PROMOTIONS_SEEN,
  METRIC_RESPONSE_END_TIME,
  METRIC_DOM_LOAD_TIME,
  METRIC_WINDOW_LOAD_TIME,
  DIMENSION_WDC_SAVING,
  DIMENSION_STICKY_FOOTER,
  DIMENSION_VAR_99,
  DIMENSION_PRODUCT_PRICE,
  DIMENSION_EVENT_SOURCE,
  DIMENSION_CHEAP_FLIGHT_CONNECTION,
  DIMENSION_CHEAP_FLIGHT_POSITION,
  DIMENSION_CHEAP_FLIGHT_CATEGORY,
  DIMENSION_PLATFORM,
  DIMENSION_USER_WDC_TYPE,
} from '~/constants/analytics';

import { PRODUCT_CUSTOM_DIMENSIONS } from '~/services/ecommerce/constants';
import compose from '~/utils/fp/compose';
import whereEq from '../fp/where-eq';
import isUndefined from '../object/is-undefined';
import { getSessionItem, setSessionItem } from '../storage';
import { removeUtmQueryParamsFromUrl, getPathWithoutLanguage, getHref } from '../browser';
import { localizePath } from '../localization';
import * as BookingUtils from '../booking/booking';
import { trackFloodlightEvent } from '../conversion-tracking/double-click';
import isEmpty from '../object/is-empty';
import { sendGaEvent } from './send-ga-event';
import { getFloodlightId } from './get-floodlight-id';
import { gtag, isGtagNotAvailable, isGtagAvailable } from './gtag';
import { getTrackingId } from './get-tracking-id';
import { gtm } from './gtm';

export { gtag, isGtagNotAvailable, isGtagAvailable, sendGaEvent, getTrackingId };

export const setVar99Dimension = () => {
  setPersistentDimension(DIMENSION_VAR_99[getTrackingId()], true);
  setPersistentGTMDataLayerVariables({
    event: 'bot_akamai',
    bot_akamai: true,
  });
};

export const sendEventOnce = ({ eventArgs, sessionKey, eventFlag } = {}) => {
  const sessionItem = getSessionItem(sessionKey) || [];
  if (eventFlag) {
    const sessionElement = sessionItem.find((itemElement) => itemElement === eventFlag);
    if (sessionElement) return;
    sessionItem.push(eventFlag);
  }
  setSessionItem(sessionKey, sessionItem);
  sendGaEvent(eventArgs);
};

export const getAnalyticsProductName = (product, productMap = []) => {
  const code = product?.data?.code || product.code || '';
  const item = productMap.find(whereEq({ code })) || {};
  return item.name || code;
};

/**
 * @param id        number of dimension
 * @param label     string, custom data
 */
export const setPersistentDimension = (id = 0, label) => {
  gtag('set', { [`dimension${id}`]: label }); // dimensions should be Strings, not Integers
};

export const setPersistentGTMDataLayerVariables = (variableObject = {}) => {
  gtm(variableObject);
};

/**
 * Set transport mechanism
 * https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
 * @param {string} transportMechanism    transport mechanism
 */
export const setTransportMechanism = (transportMechanism = 'beacon') => {
  gtag('set', { transport_type: transportMechanism });
};

/**
 * Set culture code (user language)
 * https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values
 * @param {string} cultureCode    culture code
 */
export const setCultureCode = (cultureCode = window.page.culture) => {
  gtag('set', { language: cultureCode });
};

/**
 * Set currency code
 * https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values
 * @param {string} currencyCode    currency code
 */
export const setCurrencyCode = (currencyCode = ANALYTICS_CURRENCY_CODE) => {
  gtag('set', { currency: currencyCode });
};

/**
 * Set user id
 * @param {string} userIdDimension    custom dimension in GA for user id
 * @param {string} userId             user id
 */
export const setUserId = (userIdDimension, userId) => {
  gtag('set', { user_id: userId });
  // User ID is sent as a custom dimension as well because of Big Query integration
  gtag('set', { [userIdDimension]: userId });
  // for ga4:

  gtm({ wizz_id: userId });
};

/**
 * Set build number
 * @param buildNumberDimension      custom dimension in GA for build number
 * @param buildNumber               build number
 */
export const setBuildNumber = (buildNumberDimension, buildNumber) => {
  gtag('set', { [buildNumberDimension]: buildNumber });
  gtm({ build_number: String(buildNumber) });
};

export const setSearchedRoutes = (searchedRoutesDimension, searchedRoutes) => {
  gtag('set', { [searchedRoutesDimension]: searchedRoutes });
  gtm({ searched_routes: searchedRoutes });
};

export const setSearchedDate = (searchedDateDimension, searchedDate) => {
  gtag('set', { [searchedDateDimension]: searchedDate });
  gtm({ searched_date: searchedDate });
};

/**
 * Set is user subscribed
 * @param dimension      custom dimension in GA for Is user subscribed to newsletter
 * @param isSubscribed               isSubscribed
 */
export const setIsUserSubscribed = (dimension, isSubscribed) => {
  const value = isSubscribed ? 'Yes' : 'No';

  gtag('set', { [dimension]: value });

  gtm({ is_subscribed: value });
};

/**
 * Set user WDC Type
 * @param dimension      custom dimension in GA for user WDC Type
 * @param userWdcType               userWdcType
 */
export const setUserWdcType = (dimension, userWdcType) => {
  gtag('set', { [dimension]: userWdcType });

  gtm({ wdc_status: userWdcType });
};

/**
 * Set promotions
 * @param {string} promotionDimension    custom dimension in GA for promotions
 * @param {Array} promotions             promotions
 */
export const setPromotions = (promotionDimension, promotions) => {
  gtag('set', { [promotionDimension]: promotions.join('|') });
};

/**
 * Reset promotions
 * @param {string} promotionDimension    custom dimension in GA for promotions
 */
export const resetPromotions = (promotionDimension) => {
  gtag('set', { [promotionDimension]: null });
};

/**
 * Tracks pageview
 */
export const trackPageview = () => {
  if (isGtagNotAvailable()) return;

  const page = localizePath(window.location.pathname);

  sendGTMPageView({
    pagePath: page,
    contentGroup: getPathWithoutLanguage(page),
  });

  sendPageView({
    page_path: page,
    content_group1: getPathWithoutLanguage(page),
    event_callback: removeUtmQueryParamsFromUrl,
  });
};

/**
 * Tracks virtual pageview
 */
export const trackVirtualPageview = (
  isGoogleAnalyticsPostRequired = false,
  routeTo,
  onDone = () => {}
) => {
  if (isGtagNotAvailable()) return;

  // Well, `wizzair.com` redirects to `wizzair.com/#/`...
  // but we don't want to send those pageviews twice
  if (window.location.pathname === '/' && window.location.hash === '') return;

  // Pageview is tracked in the 'loadItinerary' action if anything was added to the booking
  if (
    (BookingUtils.isItinerary() || BookingUtils.isNewItinerary()) &&
    isGoogleAnalyticsPostRequired
  )
    return;

  const page = isEmpty(routeTo)
    ? getPagePathForAnalytics(location)
    : getBookingFlowPagePathFor(routeTo);

  sendGTMPageView({
    pagePath: page,
    contentGroup: getPathWithoutLanguage(page),
  });

  sendPageView({
    page_path: page,
    content_group1: getPathWithoutLanguage(page),
    event_callback: removeUtmQueryParamsFromUrl,
  });
  onDone();
};

export const sendPageView = (setup = {}) => {
  const { event_callback: eventCallback, ...rest } = setup;
  const { page_path: pagePath, content_group1: contentGroup1 } = { ...rest };
  if ([pagePath, contentGroup1].some(isUndefined)) {
    log.warn('Please provide both page_path and content_group1 parameters');
  }
  sendGaEvent({
    action: 'page_view',
    payload: {
      event_callback: eventCallback,
      ...rest,
    },
  });
  // pageview events clears the whole gtag configuration, and we don't like it
  // => lets reset everything (why not 🤷‍)
  setDefaultConfig();
};

export const sendGTMPageView = ({ pagePath = '', contentGroup = '' }) => {
  if ([pagePath, contentGroup].some(isUndefined)) {
    log.warn('Please provide both page_path and content_group parameters');
  }

  gtm({
    event: 'pageView',
    page_path: pagePath, // The full URL is required.
    content_group: contentGroup,
  });
};

export const getPagePathForAnalytics = (location) => {
  let page = localizePath(location.pathname) + location.search;
  if (BookingUtils.isBookingFlow()) {
    page += location.hash.replace('#', '');

    // hash handling remains only for local development
    if (
      location.hash.includes(STEP_SELECT_FLIGHT) ||
      location.pathname.includes(`/${BOOKING_TITLE}/${STEP_SELECT_FLIGHT}`)
    ) {
      page = page.slice(
        0,
        Math.max(0, page.indexOf(STEP_SELECT_FLIGHT) + STEP_SELECT_FLIGHT.length)
      );
    }

    page = page.replace(/\/+/g, '/');
    // Remove any kind of query string (which should not be a part of the hash)
    page = page.replace(/\?.*$/, '');
  }

  return page;
};

export const getBookingFlowPagePathFor = (to) => {
  const isPaymentFailedUrl = to.name === STEP_PAYMENT && to.params.substep === 'failed';
  const step = isPaymentFailedUrl ? `${to.name}/failed` : to.name;

  return localizePath(`/booking/${to.name}/${step}`);
};

export const setDefaultConfig = () => {
  const trackingId = getTrackingId();
  const floodlightTrackingId = getFloodlightId();
  gtag('config', trackingId, {
    send_page_view: false,
    anonymize_ip: true,
    custom_map: {
      ...PRODUCT_CUSTOM_DIMENSIONS,
      // https://www.simoahava.com/analytics/add-clientid-to-custom-dimension-gtag-js/
      [DIMENSION_CLIENT_ID[trackingId]]: 'clientId',
      [DIMENSION_WDC_SAVING[trackingId]]: 'hasSavingOnWdc',
      [DIMENSION_USER_WDC_TYPE[trackingId]]: 'wdcStatus',
      [DIMENSION_STICKY_FOOTER[trackingId]]: 'eventLocation',
      [DIMENSION_PRODUCT_PRICE[trackingId]]: 'productPrice',
      [DIMENSION_EVENT_SOURCE[trackingId]]: 'eventSource',
      [DIMENSION_CHEAP_FLIGHT_CONNECTION[trackingId]]: 'airportCodes',
      [DIMENSION_CHEAP_FLIGHT_POSITION[trackingId]]: 'promoPosition',
      [DIMENSION_CHEAP_FLIGHT_CATEGORY[trackingId]]: 'cheapFlightCategory',
      [DIMENSION_PLATFORM[trackingId]]: 'platform',
    },
  });

  // floodlight config
  gtag('config', floodlightTrackingId);
};

export const getPagePathForAnalyticsWithoutLanguage = compose(
  getPathWithoutLanguage,
  getPagePathForAnalytics
);

export const trackServiceLost = (label) => {
  sendGaEvent({ category: 'Payment', action: 'Service lost', label });
};

export const trackPhoneNumber = (category, action, label) =>
  trackEventWithGaConstant(GA_PHONE_NUMBER_EVENT, category, action, label);

const trackEventWithGaConstant = (gaConstant, category, action, label) => {
  const {
    category: { [category]: eventCategory },
    action: { [action]: eventAction },
    label: { [label]: eventLabel },
  } = gaConstant;

  const params = [eventCategory, eventAction, eventLabel];
  if (params.some(isUndefined)) {
    log.error(
      `Cannot track phone number changes coz an event parameter is missing: ${params}`
    );
    return;
  }

  sendGaEvent({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  });
};

export const safeTrackVirtualPageview = (
  hasPromotionToTrack,
  promotionsToTrack,
  isGoogleAnalyticsPostRequired,
  floodlightVariables,
  routeTo = {}
) => {
  if (isExcludedFromHashchange() || isExcluded()) return;

  if (hasPromotionToTrack) {
    setPromotions(DIMENSION_PROMOTIONS_SEEN[getTrackingId()], promotionsToTrack);
  }

  trackVirtualPageview(isGoogleAnalyticsPostRequired, routeTo, () => {
    resetPromotions(DIMENSION_PROMOTIONS_SEEN[getTrackingId()]);
  });

  // track pageView with floodlight
  const { pageUrl } = floodlightVariables;
  trackFloodlightEvent({
    params: { pageUrl },
    sendTo: FLOODLIGHT_COUNT_ALLPAGES,
    isPageRequest: true,
  });
};

export const isExcludedFromHashchange = () => {
  return ANALYTICS_EXCLUDED_FROM_HASHCHANGE.some(
    (page) => window.urls?.[page] && getHref().startsWith(window.urls[page])
  );
};

export const isExcluded = () => {
  return ANALYTICS_EXCLUDED.some(
    (page) => window.urls?.[page] && getHref().startsWith(window.urls[page])
  );
};

// https://philipwalton.com/articles/the-google-analytics-setup-i-use-on-every-site-i-build/#performance-tracking
export const sendNavigationTimingMetrics = () => {
  // Only track performance in supporting browsers.
  if (!(window.performance && window.performance.timing)) {
    return;
  }

  // If the window hasn't loaded, run this function after the `load` event.
  if (document.readyState !== 'complete') {
    window.addEventListener('load', () => sendNavigationTimingMetrics());
    return;
  }

  const timing = window.performance.timing;
  const navigationStart = timing.navigationStart;

  const responseEnd = Math.round(timing.responseEnd - navigationStart);
  const domLoaded = Math.round(timing.domContentLoadedEventStart - navigationStart);
  const windowLoaded = Math.round(timing.loadEventStart - navigationStart);

  const trackingId = getTrackingId();
  if ([responseEnd, domLoaded, windowLoaded].every(isValidNumericValue)) {
    const dimensions = {
      [METRIC_RESPONSE_END_TIME[trackingId]]: responseEnd,
      [METRIC_DOM_LOAD_TIME[trackingId]]: domLoaded,
      [METRIC_WINDOW_LOAD_TIME[trackingId]]: windowLoaded,
    };
    sendGaEvent({
      category: 'Navigation Timing',
      action: 'track',
      nonInteractive: true,
      dimensions,
    });
  }
};

// In some edge cases browsers return very obviously incorrect NT values,
// e.g. 0, negative, or future times. This validates values before sending.
const isValidNumericValue = (value) => value > 0 && value < 1e6;

export const getContentGroup = () => {
  const page = localizePath(location.pathname);
  return getPathWithoutLanguage(page);
};
export const gaEvent = (category, action, label) =>
  sendGaEvent({ category, action, label });
