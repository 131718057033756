import { to } from 'await-to-js';
import {
  STEP_SELECT_FLIGHT,
  DIRECTION_OUTBOUND,
  FLOW_TYPE_NEW_BOOKING,
  WDC_MEMBERSHIP,
  DC_MEMBERSHIP,
} from '~/constants';
import {
  ANALYTICS_CAR_RENTAL_CODE,
  ANALYTICS_CAR_TRAWLER_CODE,
  ANALYTICS_PRODUCT_AIRPORT_CHECK_IN,
  ANALYTICS_PRODUCT_CANCELLATION_INSURANCE,
  BOOKING_FLOW_ANALYTICS_STEPS,
  ECOMMERCE_PRODUCT_TO_ID_MAP,
  ANALYTICS_PRODUCT_WIZZ_PRIORITY,
  ANALYTICS_PRODUCT_SMS,
  ANALYTICS_PRODUCT_TRAVEL_INSURANCE,
  ANALYTICS_PRODUCT_AUTO_CHECK_IN,
  DIMENSION_BIGQUERY_SEARCH_DESTINATION_ID,
  DIMENSION_BIGQUERY_SEARCH_ENDDATE,
  DIMENSION_BIGQUERY_SEARCH_NEW_DESTINATION_TYPE,
  DIMENSION_BIGQUERY_SEARCH_ORIGIN_ID,
  DIMENSION_BIGQUERY_SEARCH_PAX_TYPE,
  DIMENSION_BIGQUERY_SEARCH_STARTDATE,
  DIMENSION_BIGQUERY_SEARCH_TOTAL_PAX,
  GA_ACTION_BEGIN_CHECKOUT,
  GA_ACTION_CHECKOUT_PROGRESS,
  LOYALTY_COOKIE,
  TRIP_PLANNER_DATE,
  TRIP_PLANNER_DURATION,
  TRIP_PLANNER_LEAVING_FROM,
  TRIP_PLANNER_LOOKING_FOR,
  TRIP_PLANNER_PRICE,
  TRIP_VIEW_FLIGHTS,
  ANALYTICS_PRODUCT_WIZZ_FLEX,
  ANALYTICS_PRODUCT_ALLOCATED_SEATING,
  DIMENSION_SEARCHED_ROUTES,
  DIMENSION_SEARCHED_DATE,
  ANALYTICS_PRODUCT_SITTING_TOGETHER,
  ANALYTICS_CURRENCY_CODE,
} from '~/constants/analytics';
import {
  getAnalyticsProductName,
  sendGaEvent,
  sendPageView,
  getTrackingId,
  setDefaultConfig,
  gtag,
  isGtagNotAvailable,
  sendNavigationTimingMetrics,
  getPagePathForAnalyticsWithoutLanguage,
  setSearchedRoutes,
  setSearchedDate,
  sendGTMPageView,
} from '~/utils/analytics';
import { gtm, sendGTMEcommerceEvent } from '~/utils/analytics/gtm';
import { round } from '~/utils/number';
import bannerToAnalytics from '~/utils/banners/banner-to-analytics';
import isEmpty from '~/utils/object/is-empty';
import propNotEq from '~/utils/fp/prop-not-eq';
import propEq from '~/utils/fp/prop-eq';
import pathOr from '~/utils/fp/path-or';
import once from '~/utils/fp/once';
import {
  getLocalItemRaw,
  getSessionItem,
  setSessionItem,
  setLocalItemRaw,
  getFlashItem,
} from '~/utils/storage';
import isObject from '~/utils/object/is-object';
import either from '~/utils/fp/either';
import pathEqTrue from '~/utils/fp/path-eq-true';
import pathEq from '~/utils/fp/path-eq';

import {
  TRIP_PLANNER_DATE_RANGES_GA_NAMES,
  TRIP_PLANNER_DURATIONS_GA_NAMES,
  TRIP_PLANNER_GA_CATEGORY_NAMES,
} from '~/components/trip-planner/constants';

import { localizePath } from '~/utils/localization';
import { getPathWithoutLanguage } from '~/utils/browser';
import {
  SS_LOYAL_CUSTOMER_TRACKED,
  SS_ECOMMERCE_TRACKINGS,
  SS_FLIGHT_SEARCH_SOURCE,
} from '~/constants/session-storage';
import { LS_GEOGA_DEBUG_KEY, LS_IS_LOYAL_CUSTOMER } from '~/constants/local-storage';
import isUndefined from '~/utils/object/is-undefined';
import { differenceInDays } from '~/utils/date';
import * as AssetService from '~/services/asset';
import {
  ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART,
  ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART,
  ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM,
  ENHANCED_ECOMMERCE_EVENT_TYPE_PURCHASE,
  ENHANCED_ECOMMERCE_EVENT_TYPE_SELECT_CONTENT,
  ENHANCED_ECOMMERCE_EVENT_TRACKING_FLAG_MAP,
  ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_PROMOTION,
} from './ecommerce/constants';
import batchQueue from './ecommerce/batch-queue';

export function sendPromoBannerClick(data) {
  if (getLocalItemRaw(LS_GEOGA_DEBUG_KEY)) return;

  const promo = bannerToAnalytics(data);
  sendGaEvent({
    action: ENHANCED_ECOMMERCE_EVENT_TYPE_SELECT_CONTENT,
    payload: {
      promotions: [promo],
    },
  });

  gtm({
    event: 'banner_promotion',
    banner_type: 'hero_banner',
    banner_position: data.rawPosition + 1,
    banner_action: 'click',
    banner_content: data.type,
  });
}

export function sendPromoBannerView(data) {
  if (getLocalItemRaw(LS_GEOGA_DEBUG_KEY)) return;

  const promo = bannerToAnalytics(data);
  sendGaEvent({
    action: ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_PROMOTION,
    payload: {
      promotions: [promo],
    },
  });
}

/**
 * Send enhanced ecommerce events to GA
 *
 * @param {string} eventType GA event type (e.g.: view_item_list, view_item, add_to_cart, remove_from_cart, ...)
 * @param {Array|object} trackable items to track (e.g.: { type, metadata, data: { ..., children: [] } })
 */
const sendEcommerceEvent = (eventType, trackable) => {
  const products = (Array.isArray(trackable) ? trackable : [trackable]).filter(Boolean);
  if (isEmpty(products)) return;

  const formattedSummaryItems = getFormattedSummaryItems(products, eventType);
  const enhancedEcommerceProducts = getEnhancedEcommerceProducts(formattedSummaryItems);

  if (enhancedEcommerceProducts) {
    _sendEnhancedEcommerceItemEvent(enhancedEcommerceProducts, eventType);
  }
};

const getFormattedSummaryItems = (products, eventType) => {
  return products.reduce((acc, product) => {
    if (!isEcommerceTrackingDisabled(product, eventType)) {
      acc.push(formatSummaryItem(product));
    }

    // e.g.: flight fares => fees
    acc.push(...(product.data.children?.map(formatSummaryItem) ?? []));

    return acc;
  }, []);
};

/**
 * Send enhanced ecommerce events to GA4
 *
 * @param {string} eventType GA4 event type (e.g.: view_item, add_to_cart, remove_from_cart, ...)
 * @param {Array|object} trackable items to track (e.g.: { type, metadata, data: { ..., children: [] } })
 */
const sendGa4EcommerceEvent = (eventType, trackable) => {
  const products = (Array.isArray(trackable) ? trackable : [trackable]).filter(Boolean);
  if (isEmpty(products)) return;

  const formattedSummaryItems = getFormattedGa4SummaryItems(products, eventType);
  const enhancedEcommerceProducts =
    getGa4EnhancedEcommerceProducts(formattedSummaryItems);

  if (enhancedEcommerceProducts) {
    _sendGa4EnhancedEcommerceItemEvent(enhancedEcommerceProducts, eventType);
  }
};

const getFormattedGa4SummaryItems = (products, eventType) => {
  return products.reduce((acc, product) => {
    if (!isEcommerceTrackingDisabled(product, eventType, true)) {
      acc.push(formatGa4SummaryItem(product));
    }

    // e.g.: flight fares => fees
    acc.push(...(product.data.children?.map(formatGa4SummaryItem) ?? []));

    return acc;
  }, []);
};

/**
 * Send enhanced ecommerce events to GA without formatting the trackable items
 * (e.g.: comes from the BE or created manually in gift voucher selector)
 *
 * @param {string} eventType GA event type (e.g.: view_item_list, view_item, add_to_cart, remove_from_cart, ...)
 * @param {Array|object} trackable items to track (e.g.: { id, name, category, price, ... })
 */
const sendEcommerceEventWithoutFormatting = (eventType, trackable) => {
  const products = (Array.isArray(trackable) ? trackable : [trackable]).filter(Boolean);
  if (isEmpty(products) || isEcommerceTrackingDisabled(trackable, eventType)) return;

  const enhancedEcommerceProducts = getEnhancedEcommerceProducts(products);
  if (enhancedEcommerceProducts) {
    _sendEnhancedEcommerceItemEvent(enhancedEcommerceProducts, eventType);
  }

  const enhancedEcommerceGa4Products = mapAndGetGa4EnhancedEcommerceProducts(products);
  if (enhancedEcommerceGa4Products) {
    _sendGa4EnhancedEcommerceItemEvent(enhancedEcommerceGa4Products, eventType);
  }
};

/**
 * Send 'view_item' enhanced ecommerce events to GA
 * Product details
 *
 * @param {Array} trackable items to track (e.g.: { type, metadata, data: { ..., children: [] } })
 */
export const sendEcommerceViewItemEvent = (trackable) => {
  sendEcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM, trackable);
  sendGa4EcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM, trackable);
};

/**
 * Send 'view_item' enhanced ecommerce events to GA without formatting the trackable items
 * Product details
 *
 * @param {Array} trackable items to track (e.g.: { id, name, category, price, ... })
 */
export const sendEcommerceViewItemEventWithoutFormatting = (trackable) =>
  sendEcommerceEventWithoutFormatting(ENHANCED_ECOMMERCE_EVENT_TYPE_VIEW_ITEM, trackable);

/**
 * Send 'add_to_cart' enhanced ecommerce events to GA
 * Add to cart
 *
 * @param {Array} trackable items to track (e.g.: { type, metadata, data: { ..., children: [] } })
 */
export const sendEcommerceAddToCartEvent = (trackable) => {
  sendEcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART, trackable);
  sendGa4EcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART, trackable);
};

/**
 * Send 'add_to_cart' enhanced ecommerce events to GA without formatting the trackable items
 * Add to cart
 *
 * @param {Array} trackable items to track (e.g.: { id, name, category, price, ... })
 */
export const sendEcommerceAddToCartEventWithoutFormatting = (trackable) =>
  sendEcommerceEventWithoutFormatting(
    ENHANCED_ECOMMERCE_EVENT_TYPE_ADD_TO_CART,
    trackable
  );

/**
 * Send 'remove_from_cart' enhanced ecommerce events to GA
 * Remove from cart
 *
 * @param {Array} trackable items to track (e.g.: { type, metadata, data: { ..., children: [] } })
 */
export const sendEcommerceRemoveFromCartEvent = (trackable) => {
  sendEcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART, trackable);
  sendGa4EcommerceEvent(ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART, trackable);
};

/**
 * Send 'remove_from_cart' enhanced ecommerce events to GA without formatting the trackable items
 * Remove from cart
 *
 * @param {Array} trackable items to track (e.g.: { id, name, category, price, ... })
 */
export const sendEcommerceRemoveFromCartEventWithoutFormatting = (trackable) =>
  sendEcommerceEventWithoutFormatting(
    ENHANCED_ECOMMERCE_EVENT_TYPE_REMOVE_FROM_CART,
    trackable
  );

export const getProductId = (product, direction = DIRECTION_OUTBOUND) => {
  const { airportCodes } = product.metadata.customDimensions[direction];

  const code = getProductCode(product);

  switch (product.type) {
    case 'flight':
      return `${airportCodes} Flight`;
    case 'fare': {
      const bundle = getBundle(product);
      return `${airportCodes} ${product.data.isWdc ? 'WDC' : 'Normal'} ${bundle} Fare`;
    }
    case 'fareLock':
    case 'wdcMembership':
    case 'wdcRenewal':
    case 'wddcMembership':
      return `${airportCodes} ${code} Fee`;
    case 'voucher':
      return `${code}`;
    case 'ancillary':
    case 'service':
    case 'fee':
      if ([ANALYTICS_CAR_RENTAL_CODE, ANALYTICS_CAR_TRAWLER_CODE].includes(code)) {
        return `${airportCodes} ${code}`;
      }

      return `${airportCodes} ${code} Fee`;
    default:
      return false;
  }
};

const getProductCode = (product) =>
  product.code || product.data.code || product.bundle?.code;

const flight = (product) => product.data.flight || DIRECTION_OUTBOUND;

const getBundle = (product) => {
  const bundle = product.data.fare?.bundle?.code ?? '';
  return bundle.charAt(0).toUpperCase() + bundle.slice(1).toLowerCase();
};

export const getProductName = (product) => {
  switch (product.type) {
    case 'fare': {
      const bundle = getBundle(product);
      return `${product.data.isWdc ? 'WDC' : 'Normal'} ${bundle} Fare`;
    }
    case 'wdcMembership':
    case 'wdcRenewal':
      return 'WDC membership';
    case 'wddcMembership':
      return 'WDDC membership';
    default:
      return getAnalyticsProductName(product, product.metadata?.productMap);
  }
};

export const getExchangedPrice = (price, { exchangeRate = 1 } = {}) => {
  return round(price * exchangeRate);
};

const formatSummaryItem = (item) => {
  let product = {};
  const direction = flight(item);

  product.category = [
    'fee',
    'service',
    'wdcMembership',
    'wddcMembership',
    'wdcRenewal',
    'fareLock',
  ].includes(item.type)
    ? 'ancillary'
    : item.type;
  product.id = getProductId(item, direction);
  product.name = getProductName(item);
  product.brand = getProductCode(item) || '';
  product = { ...product, ...item.metadata.customDimensions[direction] };

  const { membershipPrice, costOfMembership } = item.data;

  let price = ['wdcMembership', 'wddcMembership'].includes(item.type)
    ? membershipPrice?.amount || costOfMembership?.amount
    : item.data.price?.amount;

  price = item.data.analytics?.unitPrice ?? price;

  if (price) {
    const exchangeRate = item.metadata?.exchangeRate ?? 1;
    product.price = round(price * exchangeRate);
  }

  product.variant = item.data.analytics?.variant ?? '';
  product.quantity = item.data.analytics?.quantity ?? 1;
  product.coupon = item.data.analytics?.coupon ?? '';
  product.purchaseLocation = item.data.analytics?.location;
  product.hasSavingOnWdc = item.data.analytics?.hasSavingOnWdc;
  product.direction = direction;
  product.productPrice = product.price;

  return product;
};

const formatGa4SummaryItem = (product) => {
  let item = {};
  const direction = flight(product);

  item.item_category = [
    'fee',
    'service',
    'wdcMembership',
    'wddcMembership',
    'wdcRenewal',
    'fareLock',
  ].includes(product.type)
    ? 'ancillary'
    : product.type;
  item.item_id = getProductId(product, direction);
  item.item_name = getProductName(product);
  item.item_brand = getProductCode(product) || '';
  item = { ...item, ...product.metadata.customDimensions[direction] };

  const { membershipPrice, costOfMembership } = product.data;

  let price = ['wdcMembership', 'wddcMembership'].includes(product.type)
    ? membershipPrice?.amount || costOfMembership?.amount
    : product.data.price?.amount;

  price = product.data.analytics?.unitPrice ?? price;

  if (price) {
    const exchangeRate = product.metadata?.exchangeRate ?? 1;
    item.price = round(price * exchangeRate);
  }

  item.item_variant = product.data.analytics?.variant;
  item.quantity = product.data.analytics?.quantity ?? 1;
  item.coupon = product.data.analytics?.coupon;
  item.purchaseLocation = product.data.analytics?.location;
  item.hasSavingOnWdc = product.data.analytics?.hasSavingOnWdc;
  item.direction = direction;
  item.productPrice = item.price;
  item.pastPrice = product.data.analytics?.pastPrice;

  return item;
};

export const trackBigQueryDimensions = ({
  originId,
  destinationId,
  startDate,
  endDate,
  totalPax,
  paxType,
  destinationType,
}) => {
  const trackingId = getTrackingId();
  const flightSearchSource = getFlashItem(SS_FLIGHT_SEARCH_SOURCE)?.source ?? 'external';

  const dimensions = {
    [DIMENSION_BIGQUERY_SEARCH_ORIGIN_ID[trackingId]]: originId,
    [DIMENSION_BIGQUERY_SEARCH_DESTINATION_ID[trackingId]]: destinationId,
    [DIMENSION_BIGQUERY_SEARCH_STARTDATE[trackingId]]: startDate,
    [DIMENSION_BIGQUERY_SEARCH_ENDDATE[trackingId]]: endDate,
    [DIMENSION_BIGQUERY_SEARCH_TOTAL_PAX[trackingId]]: totalPax,
    [DIMENSION_BIGQUERY_SEARCH_PAX_TYPE[trackingId]]: paxType,
    [DIMENSION_BIGQUERY_SEARCH_NEW_DESTINATION_TYPE[trackingId]]: destinationType,
  };

  const flightType = endDate === 'oneway' ? 'one-way' : 'return';

  const GTMDataLayerVariables = {
    event: 'flight_search',
    search_type: flightSearchSource,
    search_origin: originId,
    search_destination: destinationId,
    search_date_from: startDate,
    search_date_to: endDate,
    pax_number: totalPax,
    flight_type: flightType,
    ...(flightType === 'return' && {
      search_duration: differenceInDays(endDate, startDate),
    }),
  };

  const [category, action, label, nonInteractive, eventLocation] = [
    'BigQuery',
    'track',
    'Search data',
    true,
    getPagePathForAnalyticsWithoutLanguage(location),
  ];

  sendGaEvent({
    category,
    action,
    label,
    nonInteractive,
    dimensions,
    payload: { eventLocation },
  });

  gtm(GTMDataLayerVariables);
};

export const trackSearchedRoutesAndDate = (data) => {
  const searchedRoutes = `${data.originId}-${data.destinationId}`;
  const { searchedDate } = data;
  const trackingId = getTrackingId();

  setSearchedRoutes([DIMENSION_SEARCHED_ROUTES[trackingId]], searchedRoutes);
  setSearchedDate([DIMENSION_SEARCHED_DATE[trackingId]], searchedDate);
};

export const trackTripPlannerSearch = (data, callback = () => {}) => {
  if (isGtagNotAvailable()) return callback();

  const destinationCategory = TRIP_PLANNER_GA_CATEGORY_NAMES.get(data.destinationCategory)
    ? TRIP_PLANNER_GA_CATEGORY_NAMES.get(data.destinationCategory)
    : data.destinationCategory;

  const dateRange = TRIP_PLANNER_DATE_RANGES_GA_NAMES.get(data.dateRange)
    ? TRIP_PLANNER_DATE_RANGES_GA_NAMES.get(data.dateRange)
    : data.dateRange;

  const travelDuration = TRIP_PLANNER_DURATIONS_GA_NAMES.get(data.travel)
    ? TRIP_PLANNER_DURATIONS_GA_NAMES.get(data.travelDuration)
    : data.travelDuration;

  const trackingId = getTrackingId();
  const dimensions = {
    [TRIP_PLANNER_LEAVING_FROM[trackingId]]: data.departureStation,
    [TRIP_PLANNER_LOOKING_FOR[trackingId]]: destinationCategory,
    [TRIP_PLANNER_DATE[trackingId]]: dateRange,
    [TRIP_PLANNER_DURATION[trackingId]]: travelDuration,
    [TRIP_PLANNER_PRICE[trackingId]]: data.priceRange === 0 ? 'Any' : data.priceRange,
  };

  if (data.arrivalStation) {
    dimensions[
      TRIP_VIEW_FLIGHTS[trackingId]
    ] = `${data.departureStation} - ${data.arrivalStation}`;
  }

  const invokeCallback = once(callback);

  sendGaEvent({
    category: 'Trip Planner',
    action: 'Click on route',
    dimensions,
    payload: {
      event_callback: invokeCallback,
    },
  });

  setTimeout(invokeCallback, 2000);
};

export const setLoyalCustomerFlag = () => {
  setLocalItemRaw(LS_IS_LOYAL_CUSTOMER, String(true));
};

/**
 * If user has loyalty flag in local storage, we will set the appropriate Analytics dimension
 */
export const trackLoyalCustomer = () => {
  if (getSessionItem(SS_LOYAL_CUSTOMER_TRACKED)) return;

  setSessionItem(SS_LOYAL_CUSTOMER_TRACKED, true);
  const value = getLocalItemRaw(LS_IS_LOYAL_CUSTOMER) ? 'Yes' : 'No';
  gtag('set', { [LOYALTY_COOKIE[getTrackingId()]]: value });

  gtm({ is_loyal_customer: value });
};

export const measureCheckoutStep = (step, data = {}) => {
  const { products = [], purchaseLocations = {}, hasSavingOnWdc = '' } = data;
  const enhancedEcommerceProducts = getEnhancedEcommerceProducts(products);
  const eventAction =
    step === BOOKING_FLOW_ANALYTICS_STEPS.get(STEP_SELECT_FLIGHT)
      ? GA_ACTION_BEGIN_CHECKOUT
      : GA_ACTION_CHECKOUT_PROGRESS;

  setDefaultConfig();

  let productDetails = addLocationToProducts(
    enhancedEcommerceProducts,
    purchaseLocations
  );
  productDetails = addWdcSavingToProducts(productDetails, hasSavingOnWdc);
  productDetails = addProductPriceToProducts(productDetails);

  sendGaEvent({
    action: eventAction,
    payload: {
      checkout_step: step,
      non_interaction: eventAction === GA_ACTION_BEGIN_CHECKOUT,
      ...(productDetails && { items: productDetails }),
    },
  });
};

export const measureTransaction = (
  data = {},
  eventLabel = 'Itinerary',
  suffix = '-new'
) => {
  const {
    products = [],
    purchaseLocations = {},
    hasSavingOnWdc = '',
    transaction = {},
  } = data;
  const page = localizePath(location.pathname);
  const contentGroup = getPathWithoutLanguage(page);

  if (isEmpty(products)) {
    return sendPageView({
      page_path: page,
      content_group1: contentGroup,
    });
  }

  let enhancedEcommerceProducts = getEnhancedEcommerceProducts(products);

  enhancedEcommerceProducts = addLocationToProducts(
    enhancedEcommerceProducts,
    purchaseLocations
  );

  enhancedEcommerceProducts = addWdcSavingToProducts(
    enhancedEcommerceProducts,
    hasSavingOnWdc
  );

  enhancedEcommerceProducts = addProductPriceToProducts(enhancedEcommerceProducts);

  const purchaseDetails = {
    ...transaction,
    items: enhancedEcommerceProducts,
    event_label: eventLabel,
  };

  setDefaultConfig();
  sendGaEvent({
    action: ENHANCED_ECOMMERCE_EVENT_TYPE_PURCHASE,
    payload: purchaseDetails,
  });

  sendPageView({
    page_path: page,
    content_group1: contentGroup,
  });
  sendNavigationTimingMetrics();
};

export const measureGa4Transaction = (data = {}) => {
  const {
    products = [],
    purchaseLocations = {},
    hasSavingOnWdc = '',
    transaction = {},
    paymentMethod = '',
  } = data;
  const page = localizePath(location.pathname);
  const contentGroup = getPathWithoutLanguage(page);

  if (isEmpty(products)) {
    sendGTMPageView({
      pagePath: page,
      contentGroup,
    });

    return;
  }

  let enhancedEcommerceProducts = mapAndGetGa4EnhancedEcommerceProducts(products);

  enhancedEcommerceProducts = addLocationToProducts(
    enhancedEcommerceProducts,
    purchaseLocations,
    true
  );

  enhancedEcommerceProducts = addWdcSavingToProducts(
    enhancedEcommerceProducts,
    hasSavingOnWdc
  );

  enhancedEcommerceProducts = addProductPriceToProducts(enhancedEcommerceProducts);

  sendGTMEcommerceEvent({
    items: enhancedEcommerceProducts,
    eventType: ENHANCED_ECOMMERCE_EVENT_TYPE_PURCHASE,
    transaction,
    paymentMethod,
  });
  sendGTMPageView({
    pagePath: page,
    contentGroup,
  });
};

const mapAndGetGa4EnhancedEcommerceProducts = (products) => {
  const enhancedEcommerceProducts = products.map((product) => {
    const {
      /* eslint-disable camelcase */
      id: item_id,
      category: item_category,
      brand: item_brand,
      name: item_name,
      variant: item_variant,
      ...restAttributes
      /* eslint-enable camelcase */
    } = product;
    return {
      item_id,
      item_category,
      item_name,
      item_brand,
      item_variant,
      ...restAttributes,
    };
  });

  return getGa4EnhancedEcommerceProducts(enhancedEcommerceProducts);
};

export const measureProductDetails = (item) => {
  if (!Array.isArray(item) && item.data.analytics?.isDisabled) return;

  // if you supply a "batch" property, we will remove that and add the "item" into a queue
  // with a short timeout, so that we can send 'em out in one burst as an array of items
  if (item.batch) {
    batchQueue(item, (item) => sendEcommerceViewItemEvent(item));
  } else {
    sendEcommerceViewItemEvent(item);
  }
};

function isFree(product) {
  return Number(product.price) === 0 || Number(product.price?.amount) === 0;
}

function _sendEnhancedEcommerceItemEvent(products, type) {
  setDefaultConfig();
  sendGaEvent({
    action: type,
    payload: { items: products },
  });
}

const _sendGa4EnhancedEcommerceItemEvent = (products, type) => {
  sendGTMEcommerceEvent({
    items: products,
    eventType: type,
  });
};

export function getEnhancedEcommerceProducts(products) {
  if (!products) return;

  if (Array.isArray(products)) {
    const hasFreeProductsOnly = products.every(isFree);
    if (hasFreeProductsOnly) return;
    const uniqueProducts = [];

    const isProductNonUnique = (product) => {
      return uniqueProducts.some((unique) => {
        if (unique.name === ANALYTICS_PRODUCT_ALLOCATED_SEATING) return false;
        return (
          unique.id === product.id &&
          unique.name === product.name &&
          unique.purchaseLocation === product.purchaseLocation
        );
      });
    };

    products
      .filter((product) => !isFree(product))
      .forEach((product) => {
        if (isProductNonUnique(product)) {
          const matchingProduct = uniqueProducts.find((uniq) => uniq.id === product.id);
          if (matchingProduct.id?.toLowerCase()?.endsWith('fare')) {
            Object.assign(
              matchingProduct,
              incrementQuantity(matchingProduct, Number(product.quantity))
            );
          } else {
            Object.assign(matchingProduct, incrementQuantity(matchingProduct));
          }
        } else {
          uniqueProducts.push({ ...product });
        }
      });

    return uniqueProducts;
  } else {
    if (isFree(products)) return;
    return [products];
  }
}

export function getGa4EnhancedEcommerceProducts(products = []) {
  const hasFreeProductsOnly = products.every(isFree);
  if (hasFreeProductsOnly) return [];

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const isProductNonUnique = (arr, product) => {
    return arr.some((unique) => {
      if (unique.item_name === ANALYTICS_PRODUCT_ALLOCATED_SEATING) return false;
      return (
        unique.item_id === product.item_id &&
        unique.item_name === product.item_name &&
        unique.purchaseLocation === product.purchaseLocation
      );
    });
  };

  return products
    .filter((product) => !isFree(product))
    .reduce((uniqueProducts, currentProduct) => {
      if (isProductNonUnique(uniqueProducts, currentProduct)) {
        const matchingProduct = uniqueProducts.find(
          (uniq) => uniq.item_id === currentProduct.item_id
        );

        isGa4FareProduct(matchingProduct)
          ? Object.assign(
              matchingProduct,
              incrementQuantity(matchingProduct, Number(currentProduct.quantity))
            )
          : Object.assign(matchingProduct, incrementQuantity(matchingProduct));
      } else {
        uniqueProducts.push({ ...currentProduct });
      }
      return uniqueProducts;
    }, []);
}

const isGa4FareProduct = (product) => product.item_id?.toLowerCase()?.endsWith('fare');

const incrementQuantity = (obj, increment = 1) => {
  if (!obj.quantity) return obj;
  return { ...obj, quantity: Number(obj.quantity) + Number(increment) };
};

const addProductPriceToProducts = (products) => {
  if (isEmpty(products)) return products;

  return products.map((product) => ({
    ...product,
    productPrice: round(product.fullFarePriceWithAdministrationFees || product.price),
  }));
};

const addWdcSavingToProducts = (products, hasSavingOnWdc) => {
  if (isEmpty(products)) return products;

  let productsWithWdc = addWdcSaving(products, hasSavingOnWdc, WDC_MEMBERSHIP);
  productsWithWdc = addWdcSaving(productsWithWdc, hasSavingOnWdc, DC_MEMBERSHIP);
  return productsWithWdc;
};

const addWdcSaving = (products, hasSavingOnWdc, productName) => {
  const itemsWithTrackableProducts = products.filter(propEq('name', productName));
  if (isEmpty(itemsWithTrackableProducts)) return products;
  const itemsWithoutTrackableProducts = products.filter(propNotEq('name', productName));
  const trackableProductsWithWdcSaving = itemsWithTrackableProducts.map((product) => ({
    ...product,
    hasSavingOnWdc,
  }));
  return [...itemsWithoutTrackableProducts, ...trackableProductsWithWdcSaving];
};

const addLocationToProducts = (products, purchaseLocations, isGa4 = false) => {
  if (isEmpty(products) || isEmpty(purchaseLocations)) return products;

  const addLocationInt = isGa4 ? addGa4Location : addLocation;

  let productsWithLocation = addLocationInt(
    products,
    purchaseLocations,
    ANALYTICS_PRODUCT_WIZZ_PRIORITY
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_SMS
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    WDC_MEMBERSHIP
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    DC_MEMBERSHIP
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_WIZZ_FLEX
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_CANCELLATION_INSURANCE
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_TRAVEL_INSURANCE
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_AIRPORT_CHECK_IN
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_AUTO_CHECK_IN
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_ALLOCATED_SEATING
  );
  productsWithLocation = addLocationInt(
    productsWithLocation,
    purchaseLocations,
    ANALYTICS_PRODUCT_SITTING_TOGETHER
  );
  return addFareLocation(productsWithLocation, purchaseLocations, isGa4);
};

const addLocation = (products, purchaseLocations, productName, isGa4 = false) => {
  const nameParam = isGa4 ? 'item_name' : 'name';
  const trackableProducts = products.filter(propEq(nameParam, productName));
  if (isEmpty(trackableProducts)) return products;
  const itemsWithoutTrackableProducts = products.filter(
    propNotEq(nameParam, productName)
  );
  const productId = ECOMMERCE_PRODUCT_TO_ID_MAP.get(productName);
  const purchaseLocation = pathOr('', productId, purchaseLocations);

  const trackableProductsWithLocation = trackableProducts.map((product) => ({
    ...product,
    purchaseLocation,
  }));

  return [...itemsWithoutTrackableProducts, ...trackableProductsWithLocation];
};

const addGa4Location = (...args) => {
  return addLocation(...args, true);
};

const addFareLocation = (products, purchaseLocations, isGa4 = false) => {
  const regex = /fare$/i;
  const nameParam = isGa4 ? 'item_name' : 'name';
  const fares = products.filter((product) => regex.test(product[nameParam]));
  const productsWithoutFares = products.filter(
    (product) => !regex.test(product[nameParam])
  );

  const faresWithLocation = fares.map((fare) => {
    const purchaseLocation =
      fare.direction?.toLowerCase() === DIRECTION_OUTBOUND
        ? purchaseLocations.outboundFare
        : purchaseLocations.returnFare;

    return {
      ...fare,
      purchaseLocation,
    };
  });

  return [...productsWithoutFares, ...faresWithLocation];
};

const isEcommerceTrackingDisabled = (trackable, eventType, isGa4 = false) => {
  let isEveryItemTracked = true;
  const flag = ENHANCED_ECOMMERCE_EVENT_TRACKING_FLAG_MAP[eventType];

  const products = [trackable];
  const children = trackable.data?.children ?? [];

  products.push(...children);
  products.forEach((product) => {
    const uniqueId = createUniqueId(product, isGa4);
    const flowType = product.metadata?.flowType ?? FLOW_TYPE_NEW_BOOKING;
    const uniqueStorageKey = `${flowType}-${uniqueId}`;
    const isHandledAlready = !updateOrSaveKey(uniqueStorageKey, flag);
    isEveryItemTracked = isEveryItemTracked && isHandledAlready;
  });

  return (
    isEveryItemTracked ||
    products.some(
      either(pathEqTrue('data.analytics.isDisabled'), pathEq('data.price.amount', 0))
    )
  );
};

const createUniqueId = (products, isGa4) => {
  let data;
  let type;

  if (products.data) {
    data = products.data;
    type = products.type;
  }

  if (products.id) {
    data = products.id.toLowerCase();
    type = products.category.toLowerCase();
  }

  if (products.data && !isObject(data)) {
    data = data.replace(/\s/g, '-');
    return `${data}-${type}${isGa4 ? '-ga4' : ''}`;
  }

  if (products.id && products.id.toLowerCase() === 'voucher') {
    // gift voucher
    data = products.name.replace(/\s/g, '-');
    type = products.id.toLowerCase();
  }

  if (products.name) {
    // fare list, on flight select, before selection
    data = products.name.replace(/\s/g, '-');
    type = products.id.toLowerCase();
    return `${data}-${type}-${products.category}${isGa4 ? '-ga4' : ''}`;
  }

  let item;
  const location = data?.analytics?.location;
  const flight = data?.flight ?? 'one-way';
  const count = data?.count || 1;

  switch (type) {
    case 'fare': {
      const fareFareSellKey = getFareSellKey(data);
      const fareBundle = data.fare?.bundle?.code ?? data.fare?.bundle;
      item = joinUniqueId(
        data.flightSellKey,
        fareFareSellKey,
        flight,
        fareBundle,
        location
      );
      break;
    }
    case 'fareLock':
      if (data?.code) item = data.code;
      break;
    case 'ancillary':
      if (data?.code)
        item = joinUniqueId(flight, data.passengerNumber, data.code, count, location);
      break;
    case 'service':
      if (data?.code) {
        const passenger = data.passengerNumber ?? 0;
        const code =
          data.name !== 'airportTransfers' ? data.code : `${data.code}-${data.option}`;
        item = joinUniqueId(flight, passenger, code, count, location);
      }
      break;
    case 'wdcMembership':
    case 'wddcMembership':
      if (data?.code) item = joinUniqueId(data.code, data.membership, location);
      break;
    case 'fee': {
      const flightSellKey = products.data.flightSellKey;
      const fareSellKey = products.data.fareSellKey;
      const bundle = products.data.bundle;
      const code = products.data.code;
      item = joinUniqueId(code, flight, flightSellKey, fareSellKey, bundle);
      break;
    }
    case 'voucher': {
      const quantity = products.quantity ?? 0;
      const price = products.price ?? 0;
      item = joinUniqueId(data, type, quantity, price);
      break;
    }

    // no default
  }

  return isGa4 ? `${item}-ga4` : item;
};

const joinUniqueId = (...params) => {
  return [...params].filter(Boolean).join('-');
};

const updateOrSaveKey = (uniqueStorageKey, flag) => {
  const trackedItems = getSessionItem(SS_ECOMMERCE_TRACKINGS) || {};
  const existingFlag = trackedItems[uniqueStorageKey];

  // the key has never been tracked
  if (isUndefined(existingFlag)) {
    trackedItems[uniqueStorageKey] = flag;
    setSessionItem(SS_ECOMMERCE_TRACKINGS, trackedItems);
    return true;
  }

  // update flag (e.g.: 0b01 | 0b10 => 0b11)
  const newFlag = existingFlag | flag;

  // the key is already tracked with this flag
  if (newFlag === existingFlag) return false;

  // the key has been tracked but not with this flag
  trackedItems[uniqueStorageKey] = newFlag;
  setSessionItem(SS_ECOMMERCE_TRACKINGS, trackedItems);
  return true;
};

const getFareSellKey = (data) => {
  if (data.fare?.fareSellKey) return data.fare.fareSellKey;
  return data.isWdc
    ? data.fare?.wdcPriceDetails?.fareSellKey
    : data.fare?.regularPriceDetails?.fareSellKey;
};
