export const RESET = 'itinerary/reset';
export const ADD_PNR_TO_UPSOLD_BOOKINGS = 'itinerary/addPnrToUpsoldBookings';
export const RESET_UPSOLD_BOOKINGS = 'itinerary/resetUpsoldBookings';
export const SET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT =
  'itinerary/setLoginRequiredToContinueToPayment';
export const UNSET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT =
  'itinerary/unsetLoginRequiredToContinueToPayment';
export const SET_IS_ADD_SERVICE_VISITED_ONCE = 'itinerary/setIsAddServiceVisitedOnce';
export const UNSET_IS_ADD_SERVICE_VISITED_ONCE = 'itinerary/unsetIsAddServiceVisitedOnce';
export const PARTIALLY_RESET_ITINERARY = 'itinerary/partiallyResetItinerary';
export const UPDATE_ITINERARY_BOOKING = 'itinerary/updateItineraryBooking';
export const INCREMENT_ITINERARY_LOADING_COUNTER =
  'itinerary/incrementItineraryLoadingCounter';
export const DECREMENT_ITINERARY_LOADING_COUNTER =
  'itinerary/decrementItineraryLoadingCounter';
export const ADD_ITINERARY_ERRORS = 'itinerary/addItineraryErrors';
export const ADD_SERVER_ERROR = 'itinerary/addServerError';
export const REMOVE_SERVER_ERRORS = 'itinerary/removeServerError';
export const REMOVE_ITINERARY_ERRORS = 'itinerary/removeItineraryErrors';
export const UPDATE_ITINERARY_CHECK_IN_PASSENGERS =
  'itinerary/updateItineraryCheckInPassengers';
export const UPDATE_ITINERARY_SERVICES = 'itinerary/updateItineraryServices';
export const SET_PREFERRED_INVOICE_TAB = 'itinerary/setPreferredInvoiceTab';
export const SET_ITINERARY_ACTIVE_MODAL = 'itinerary/setItineraryActiveModal';
export const SET_ITINERARY_ACTIVE_SECTION = 'itinerary/setItineraryActiveSection';
export const SET_CURRENT_CURRENCY = 'itinerary/setCurrentCurrency';
export const UPDATE_SEAT_SELECTION_MODAL_VISIBILITY =
  'itinerary/updateSeatSelectionModalVisibility';
export const UPDATE_ITINERARY_SELECTED_PASSENGER =
  'itinerary/updateItinerarySelectedPassenger';
export const ADD_CHECK_IN_PASSENGER = 'itinerary/addCheckInPassenger';
export const REMOVE_CHECK_IN_PASSENGER = 'itinerary/removeCheckInPassenger';
export const UPDATE_CHECK_IN_PASSENGER_PROP = 'itinerary/updateCheckInPassengerProp';
export const HANDLE_CHECKED_IN_PASSENGERS = 'itinerary/handleCheckedInPassengers';
export const RESET_SELECTED_CHECK_IN_PASSENGERS =
  'itinerary/resetSelectedCheckInPassengers';
export const SET_RECENTLY_PURCHASED_ITEMS = 'itinerary/setRecentlyPurchasedItems';
export const SET_RECENTLY_PAID_AMOUNT = 'itinerary/setRecentlyPaidAmount';
export const SET_EMPTY_PAYMENT_OCCURRED_STATUS =
  'itinerary/setEmptyPaymentOccurredStatus';
export const UPDATE_PRICE_DETAILS = 'itinerary/updateItineraryPriceDetails';
export const ADD_PRICE_DETAILS_ERRORS = 'itinerary/addPriceDetailsErrors';
export const RESET_PRICE_DETAILS_ERRORS = 'itinerary/resetPriceDetailsErrors';
export const SET_FLIGHTS_ON_ITINERARY = 'itinerary/setFlightsOnItinerary';
export const SET_IS_BOOKING_DOT_COM_MODAL_VISIBLE =
  'itinerary/setIsBookingDotComModalVisible';
export const UNSET_IS_BOOKING_DOT_COM_MODAL_VISIBLE =
  'itinerary/unsetIsBookingDotComModalVisible';
export const UPDATE_CONTACT_PHONE_NUMBER = 'itinerary/itineraryUpdateContactPhoneNumber';
export const INCREMENT_CONTACT_LOADING_COUNTER =
  'itinerary/incrementContactLoadingCounter';
export const DECREMENT_CONTACT_LOADING_COUNTER =
  'itinerary/decrementContactLoadingCounter';
export const ADD_CONTACT_ERRORS = 'itinerary/addContactErrors';
export const REMOVE_CONTACT_ERRORS = 'itinerary/removeContactErrors';
export const RESET_CONTACT_UPDATE_STATUS = 'itinerary/resetContactUpdateStatus';
export const SET_CHECK_IN_DOCUMENT_RULES_BY_COUNTRIES =
  'itinerary/setCheckInDocumentRulesByCountries';
export const RESET_AVAILABLE_FEEDBACK_STAGES = 'itinerary/resetAvailableFeedbackStages';
export const SET_SCHEDULE_CHANGE_REFUND_AMOUNTS = 'itinerary/setAmountOfRefund';
export const SET_FLIGHT_STATUS_CHANGED_ERRORS = 'itinerary/setFlightStatusChangedErrors';
export const SET_REFUND_AMOUNT_GET_ERRORS = 'itinerary/setRefundAmountGetErrors';
export const SET_FLIGHT_STATUS_CHANGED_STATE = 'itinerary/setFlightStatusChangedState';
export const UPDATE_BANNED_PASSENGERS = 'itinerary/updateBannedPassengers';
export const SET_BANNED_PASSENGER_SEEN = 'itinerary/setBannedPassengerWarningSeen';
export const RESET_BANNED_PASSENGERS = 'itinerary/resetBannedPassengers';
export const SET_SEAT_REFUND_ERRORS = 'itinerary/setSeatRefundErrors';
export const SET_AIRCRAFT_TYPE_CHANGE_TO_REFUNDED =
  'itinerary/setAircraftTypeChangeToRefunded';
export const SET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE =
  'itinerary/setIsUpgradeBundleModalVisible';
export const UNSET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE =
  'itinerary/unsetIsUpgradeBundleModalVisible';
export const SET_IS_FLIGHT_CHANGE_WARNING_MODAL_SHOWN =
  'itinerary/setIsFlightChangeWarningModalShown';
export const SET_DIVIDE_KIND = 'itinerary/setDivideKind';
export const SET_IS_SUCCESSFUL_BOOKING_LOADED = 'itinerary/setIsSuccessfulBookingLoaded';
export const UNSET_IS_SUCCESSFUL_BOOKING_LOADED =
  'itinerary/unsetIsSuccessfulBookingLoaded';
export const SET_IS_SEAT_SELECTION_NEEDED = 'itinerary/setIsSeatSelectionNeeded';
export const RESET_CURRENT_TYPE_AND_SELECTED_PASSENGER =
  'itinerary/resetCurrentTypeAndSelectedPassenger';
export const SET_CURRENT_TYPE = 'itinerary/setCurrentType';
export const SET_SELECTED_CHECK_IN_PASSENGER = 'itinerary/setSelectedCheckInPassenger';
export const RESET_SELECTED_CHECK_IN_PASSENGER =
  'itinerary/resetSelectedCheckInPassenger';
export const SET_CURRENT_FLIGHT = 'itinerary/setCurrentFlight';
export const SET_IS_ADD_BAGS_FLOW = 'itinerary/setIsAddBagsFlow';
export const SELECT_AUTO_CHECK_IN = 'itinerary/selectAutoCheckIn';
export const UNSELECT_AUTO_CHECK_IN = 'itinerary/unselectAutoCheckIn';
export const SET_ADVERTISEMENT_BANNER = 'itinerary/setAdvertisementBanner';
export const SET_AUTO_CALL_METHOD_NAME = 'itinerary/setAutoCallMethodName';
export const SET_SELECTED_TAB_DIRECTION = 'itinerary/setSelectedTabDirection';
export const SET_PASSENGER_SEAT_UNIT_DESIGNATOR =
  'itinerary/setPassengerSeatUnitDesignator';
export const SET_IS_ADD_FLIGHT_EXIT_INTENT_MODAL_SEEN =
  'itinerary/setIsAddFlightExitIntentModalSeen';
export const SET_OUTBOUND_FREE_CHECKIN_START_DATE =
  'itinerary/setOutboundFreeCheckinStartDate';
export const SET_RETURN_FREE_CHECKIN_START_DATE =
  'itinerary/setReturnFreeCheckinStartDate';
export const SET_IS_COMING_FROM_SEAT_MODIFICATION_CI =
  'itinerary/setIsComingFromSeatModificationCI';
