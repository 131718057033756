import curry from '../curry';
import { _slice } from '../slice';

/**
 * @typedef {<T>(fn: (a: T) => boolean, arr: T[]) => T[]} TakeLastWhileFn
 */

/**
 * @type TakeLastWhileFn
 */
export const _takeLastWhile = (fn, arr) => {
  let index = arr.length - 1;
  while (index >= 0 && fn(arr[index])) {
    index -= 1;
  }
  return _slice(index + 1, Number.POSITIVE_INFINITY, arr);
};

/**
 * Returns a new list containing the last `n` elements of a given list, passing
 * each value to the supplied predicate function, and terminating when the
 * predicate function returns `false`. Excludes the element that caused the
 * predicate function to fail. The predicate function is passed one argument:
 * *(value)*.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/takeLastWhile.js
 *
 * @type TakeLastWhileFn
 * @example
 *
 *      const isNotOne = x => x !== 1;
 *
 *      takeLastWhile(isNotOne, [1, 2, 3, 4]); //=> [2, 3, 4]
 *
 *      takeLastWhile(x => x !== 'R' , 'Ramda'); //=> 'amda'
 */
const takeLastWhile = curry(_takeLastWhile);

export default takeLastWhile;
