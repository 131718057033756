import getLocale from '../get-locale';

/**
 *
 * @param {String} path
 * @param {Object} [context=window.language] an obj with a `culture` prop
 * @returns {String}
 */
const _localizePath = (context, path) => {
  path = path.toLowerCase().replace(/\/$/, '');

  const cultureCode = getLocale(context).toLowerCase();

  if (!path.startsWith(`/${cultureCode}`)) {
    path = `/${cultureCode}${path}`;
  }

  return path;
};

export default _localizePath;
