import to from 'await-to-js';
import { analytics } from '~/services/asset';
import * as BookingService from '~/services/booking';
import * as EcommerceService from '~/services/ecommerce';
import { ANALYTICS_FLOW_TYPE_CHECKIN } from '~/services/ecommerce/constants';
import * as analyticsGetters from '~/store/modules/analytics/getters';
import { captureAndLogException } from '~/utils/logging';
import { createAction } from '~/utils/store';
import * as checkInGetters from '../check-in/getters';
import * as m from './mutation-types';

export const update = async ({ commit }) => {
  const [error, response] = await to(analytics());

  if (error) {
    captureAndLogException('Unable to get analytics data.', error);
    return;
  }

  const {
    data: { exchangeRate, map },
  } = response;
  commit(m.UPDATE_ANALYTICS_EXCHANGE_RATE, exchangeRate);
  commit(m.UPDATE_ANALYTICS_PRODUCT_MAP, map);
};

export const setWizzFlexPurchaseLocation = createAction(
  m.SET_WIZZ_FLEX_PURCHASE_LOCATION
);

export const setWdcMembershipPurchaseLocation = createAction(
  m.SET_WDC_MEMBERSHIP_PURCHASE_LOCATION
);

export const setPriorityBoardingPurchaseLocation = createAction(
  m.SET_PRIORITY_BOARDING_PURCHASE_LOCATION
);

export const setSmsNotificationPurchaseLocation = createAction(
  m.SET_SMS_PURCHASE_LOCATION
);

export const setAirportCheckInPurchaseLocation = createAction(
  m.SET_AIRPORT_CHECK_IN_PURCHASE_LOCATION
);

export const setInsurancePurchaseLocation = createAction(
  m.SET_INSURANCE_PURCHASE_LOCATION
);

export const setOutboundFarePurchaseLocation = createAction(
  m.SET_OUTBOUND_FARE_PURCHASE_LOCATION
);

export const setReturnFarePurchaseLocation = createAction(
  m.SET_RETURN_FARE_PURCHASE_LOCATION
);

export const setWdcDiscountSaving = createAction(m.SET_WDC_DISCOUNT_SAVING);

export const setAutoCheckInPurchaseLocation = createAction(
  m.SET_AUTO_CHECK_IN_PURCHASE_LOCATION
);

export const trackCheckInPurchases = async (store) => {
  const { state } = store;

  if (checkInGetters.isEmptyPayment(state)) return;

  const [analyticsErrors, analyticsResponse] = await to(BookingService.getAnalytics());
  if (analyticsErrors) {
    return captureAndLogException(
      'Unable to get analytics data for check-in/payment',
      analyticsErrors
    );
  }

  let analyticalData = analyticsResponse.data?.googleAnalyticalData;
  const transaction = analyticsResponse.data?.googleAnalyticalData.transaction;

  if (!analyticalData || !transaction) {
    return captureAndLogException(
      'analytics or transaction data not found in check-in/payment',
      analyticsErrors
    );
  }

  analyticalData.products.forEach((product) => {
    product.flowType = ANALYTICS_FLOW_TYPE_CHECKIN;
  });

  analyticalData = {
    ...analyticalData,
    purchaseLocations: analyticsGetters.purchaseLocations(state),
    hasSavingOnWdc: analyticsGetters.wdcDiscountSaving(state),
    paymentMethod: analyticsGetters.selectedPaymentOptionForAnalytics(state),
  };

  EcommerceService.measureTransaction(analyticalData, 'Check in');
  EcommerceService.measureGa4Transaction(analyticalData, 'Check in');

  BookingService.analytics();
};

export const setSeatPurchaseLocation = createAction(m.SET_SEAT_PURCHASE_LOCATION);

export const setSittingTogetherPurchaseLocation = createAction(
  m.SET_SITTING_TOGETHER_PURCHASE_LOCATION
);

export const setSelectedPaymentMethodForAnalytics = createAction(
  m.SET_SELECTED_PAYMENT_OPTION
);
