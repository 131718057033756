import * as gat from '~/store/action-types';
import { getState } from './internal';
import mutations from './mutations';
import * as actions from './actions';

const store = {
  state: getState,
  mutations,
  actions: {
    [gat.AFTER_LOGIN]: actions.hideTimeoutModal,
    [gat.AFTER_LOGOUT]: actions.hideTimeoutModal,
  },
};

export default store;
