import { STEP_VOUCHER_SELECTION } from './constants';

export const DEFAULT_MAX_MESSAGE_LENGTH = 140;
export const DEFAULT_MAX_GROUPS = 1;
export const DEFAULT_MIN_AMOUNT = 1;
export const DEFAULT_MAX_AMOUNT = 3;
export const DEFAULT_CURRENCY_CODE = 'EUR';
export const DEFAULT_EUR_VALUE_1 = 0;
export const DEFAULT_EUR_VALUE_2 = 0;
// hohohooo 🎅🏿 after xmas change back to 'default'
export const IMAGE_NAME_DEFAULT = 'coziness';

export const getDefaultAvailableCurrencyValuePairs = () => [
  {
    currencyCode: DEFAULT_CURRENCY_CODE,
    value: DEFAULT_EUR_VALUE_1,
  },
  {
    currencyCode: DEFAULT_CURRENCY_CODE,
    value: DEFAULT_EUR_VALUE_2,
  },
];

export const getDefaultAvailableImages = () => [IMAGE_NAME_DEFAULT];

export const createVoucher = (id) => ({
  id,
  message: '',
  image: IMAGE_NAME_DEFAULT,
  currencyCode: DEFAULT_CURRENCY_CODE,
  value: null,
  valueIndex: null,
  amount: 1,
});

export const getDefaultPaymentCurrencyCodes = () => ['EUR'];

export const getDefaultVoucher = () => ({
  initialized: false,
  maxMessageLength: DEFAULT_MAX_MESSAGE_LENGTH,
  minAmount: DEFAULT_MIN_AMOUNT,
  maxAmount: DEFAULT_MAX_AMOUNT,
  availableCurrencyValuePairs: getDefaultAvailableCurrencyValuePairs(),
  availableImages: getDefaultAvailableImages(),
  currencyCode: DEFAULT_CURRENCY_CODE,
  nextId: 1,
});

export const getDefaultPayment = () => ({
  initialized: false,
  allCurrencyCodes: getDefaultPaymentCurrencyCodes(),
  currencyCodes: getDefaultPaymentCurrencyCodes(),
  currencyCode: DEFAULT_CURRENCY_CODE,
  optionsAreInitialized: false,

  selected: 'creditCardPaymentOption',
  cvv: null,

  options: {
    creditCardPaymentOption: {
      isAvailable: false,
      availableCreditCardTypes: [],
    },

    storedCreditCardPaymentOption: {
      isAvailable: false,
      availableStoredCreditCards: [],
    },
  },

  creditCard: {
    cardNumber: '',
    cardHolderName: '',
    expirationMonth: '',
    expirationYear: '',
    cvv: null,
    saveAsDefaultCard: false,
  },

  storedCreditCard: {
    id: null,
    cardNumber: '',
    expiration: '',
    cvv: null,
    cardType: {
      creditCardTypeCode: '',
    },
  },
});

export const getDefaultContact = () => ({
  // INFO extra over booking/contact response !!
  initialized: false,
  isBillingValid: false,
  isRegisteredForNewsletter: false,

  title: '',
  gender: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  zip: '',
  country: '',
  mobilePhone: '',
  homePhone: '',
  workPhone: '',
  email: '',
  companyName: '',
  taxNumber: '',
  isPrivate: false,

  saveAsDefault: {
    accessibility: '',
    value: true,
  },

  availableBillingOptions: [],
});

export const getDefaultState = () => ({
  currentStep: STEP_VOUCHER_SELECTION,
  loadingCounter: 0,
  maximumNumberOfVoucherGroups: DEFAULT_MAX_GROUPS,
  voucher: getDefaultVoucher(),
  vouchers: [createVoucher(0)],
  contact: getDefaultContact(),
  payment: getDefaultPayment(),
  payments: [],
});
