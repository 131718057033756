export const INITIALIZE = 'flightDisruption/initialize';
export const INCREMENT_LOADING_COUNTER = 'flightDisruption/incrementLoadingCounter';
export const DECREMENT_LOADING_COUNTER = 'flightDisruption/decrementLoadingCounter';
export const SET_ERRORS = 'flightDisruption/setErrors';
export const RESET_ERRORS = 'flightDisruption/resetErrors';
export const SET_FLIGHT_STATUS_CHANGED_STATE =
  'flightDisruption/setFlightStatusChangedState';
export const SET_SHOULD_RESELECT_SEATS_STATE =
  'flightDisruption/setShouldReselectSeatsState';
export const SET_REBOOK_TO_SEAT_FLIGHT = 'flightDisruption/setRebookToSeatFlight';
export const SET_SEAT_SELECTION_NEEDED_PASSENGER_LIST =
  'flightDisruption/setSeatSelectionNeededPassengerList';
export const SET_REFUND_OPTIONS = 'flightDisruption/setRefundOptions';
export const RESET_FLIGHT_DISRUPTION = 'flightDisruption/resetFlightDisruption';
export const SET_SELECTED_REFUND_OPTION = 'flightDisruption/setSelectedRefundOption';
