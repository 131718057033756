/**
 * Returns an array whose elements are arrays corresponding to the enumerable
 * property [key, value] pairs found directly upon object. The ordering of the
 * properties is the same as that given by looping over the property values of
 * the object manually
 *
 * @type {(obj: Object<string, any>) => [string, any][]}
 * @example
 *
 *      entries({a: 1, b: 2, c: 3}); //=> [['a', 1], ['b', 2], ['c', 3]]
 */
const entries = (obj) => Object.entries(obj);

export default entries;
