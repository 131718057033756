export const FLIGHT_CHANGE_PATH = 'flight-change';
export const FLIGHT_CHANGE_NAME = 'flight-change';
export const FLIGHT_CHANGE_STEP_INFORMATION_PATH = 'information';
export const FLIGHT_CHANGE_STEP_INFORMATION_NAME = 'flight-change-information';
export const FLIGHT_CHANGE_STEP_PASSENGERS_PATH = 'passengers';
export const FLIGHT_CHANGE_STEP_PASSENGERS_NAME = 'flight-change-passengers';
export const FLIGHT_CHANGE_STEP_FLIGHT_SELECT_PATH = 'flight-select';
export const FLIGHT_CHANGE_STEP_FLIGHT_SELECT_NAME = 'flight-change-flight-select';
export const FLIGHT_CHANGE_STEP_ADDITIONAL_COSTS_PATH = 'additional-costs';
export const FLIGHT_CHANGE_STEP_ADDITIONAL_COSTS_NAME = 'flight-change-additional-costs';
export const FLIGHT_CHANGE_STEP_SEATS_PATH = 'seats';
export const FLIGHT_CHANGE_STEP_SEATS_NAME = 'flight-change-seats';
export const FLIGHT_CHANGE_STEP_BAGS_AND_SERVICES_PATH = 'bags-and-services';
export const FLIGHT_CHANGE_STEP_BAGS_AND_SERVICES_NAME =
  'flight-change-bags-and-services';
export const FLIGHT_CHANGE_STEP_SUMMARY_PATH = 'summary';
export const FLIGHT_CHANGE_STEP_SUMMARY_NAME = 'flight-change-summary';
export const FLIGHT_CHANGE_STEP_PAYMENT_PATH = 'payment';
export const FLIGHT_CHANGE_STEP_PAYMENT_FAILED_PATH = 'failed';
export const FLIGHT_CHANGE_STEP_PAYMENT_NAME = 'flight-change-payment';
export const FLIGHT_CHANGE_STEP_PAYMENT_FAILED_NAME = 'flight-change-payment-failed';

export const FLIGHT_CHANGE_ROUTES = new Map([
  [FLIGHT_CHANGE_STEP_INFORMATION_PATH, FLIGHT_CHANGE_STEP_INFORMATION_NAME],
  [FLIGHT_CHANGE_STEP_PASSENGERS_PATH, FLIGHT_CHANGE_STEP_PASSENGERS_NAME],
  [FLIGHT_CHANGE_STEP_FLIGHT_SELECT_PATH, FLIGHT_CHANGE_STEP_FLIGHT_SELECT_NAME],
  [FLIGHT_CHANGE_STEP_ADDITIONAL_COSTS_PATH, FLIGHT_CHANGE_STEP_ADDITIONAL_COSTS_NAME],
  [FLIGHT_CHANGE_STEP_SEATS_PATH, FLIGHT_CHANGE_STEP_SEATS_NAME],
  [FLIGHT_CHANGE_STEP_BAGS_AND_SERVICES_PATH, FLIGHT_CHANGE_STEP_BAGS_AND_SERVICES_NAME],
  [FLIGHT_CHANGE_STEP_SUMMARY_PATH, FLIGHT_CHANGE_STEP_SUMMARY_NAME],
  [FLIGHT_CHANGE_STEP_PAYMENT_PATH, FLIGHT_CHANGE_STEP_PAYMENT_NAME],
]);
