import arity from '../arity';

/**
 * Takes a function `f` and a list of arguments, and returns a function `g`.
 * When applied, `g` returns the result of applying `f` to the arguments
 * provided initially followed by the arguments provided to `g`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/partial.js
 *
 * @type {<T extends any[]>(fn: Function, ...boundArgs: T) => Function}
 * @example
 *
 *      let multiply2 = (a, b) => a * b;
 *      let double = partial(multiply2, [2]);
 *      double(2); //=> 4
 *
 *      let greet = (salutation, title, firstName, lastName) =>
 *        salutation + ', ' + title + ' ' + firstName + ' ' + lastName + '!';
 *
 *      let sayHello = partial(greet, ['Hello']);
 *      let sayHelloToMs = partial(sayHello, ['Ms.']);
 *      sayHelloToMs('Jane', 'Jones'); //=> 'Hello, Ms. Jane Jones!'
 */
const partial = (fn, ...boundArgs) => {
  if (boundArgs.length === 0) return fn;
  return arity(Math.max(0, fn.length - boundArgs.length), (...args) =>
    fn(...boundArgs, ...args)
  );
};

export default partial;
