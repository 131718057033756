import curry from '~/utils/fp/curry';
import last from '~/utils/fp/last';
import { _has } from '~/utils/fp/has';
import { _assocPath } from '~/utils/fp/assoc-path';
import isEmpty from '../is-empty';

/**
 * @type import('../../fp/assoc-path').AssocPathFn
 */
export const _hassocPath = (path, value, obj) => {
  if (isEmpty(path)) return obj;
  const splitPath = path.split('.');
  loopish(splitPath, value, obj);
  return obj;
};

const loopish = (splitPath, value, obj) => {
  const [prop, ...newSplitPath] = splitPath;
  if (!prop) return obj;

  if (newSplitPath.length > 0 && last(newSplitPath)) {
    const subObj = _has(prop, obj) ? obj[prop] : {};
    value = _assocPath(newSplitPath.join('.'), value, subObj);
  }

  obj[prop] = value;
  return obj;
};

/**
 * Hybrid assocPath which doesn't create a new top level object but it will
 * create new sub objects along the path.
 *
 * @type import('../../fp/assoc-path').AssocPathFn
 */
const hassocPath = curry(_hassocPath);

export default hassocPath;
