import key from '~/utils/fp/head';
import isNotNumber from '~/utils/object/is-not-number';

const findAssignedSeatCode = (passengerId, seatAssignments) => {
  if (isNotNumber(passengerId)) return null;

  const found = Object.entries(seatAssignments || {}).find(
    ([_, pid]) => pid === passengerId
  );

  const _key = found ? key(found) : null;
  return _key !== 'null' ? _key : null;
};

export default findAssignedSeatCode;
