/**
 * The lower case version of a string.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/toLower.js
 *
 * @type {(str: string) => string}
 * @example
 *
 *      toLowerCase('XYZ'); //=> 'xyz'
 */
const toLowerCase = (str) => str?.toLowerCase();

export default toLowerCase;
