import {
  CREDIT_CARD_TYPE_CODE_VISA,
  CREDIT_CARD_TYPE_CODE_MASTERCARD,
  CREDIT_CARD_TYPE_CODE_MAESTRO,
  CREDIT_CARD_TYPE_CODE_BANCONTACT,
  CREDIT_CARD_TYPE_CODE_UATP,
  CREDIT_CARD_TYPE_CODE_DINERS,
  CREDIT_CARD_TYPE_CODE_DISCOVER,
} from '~/constants';
import has from '~/utils/fp/has';
import propEq from '~/utils/fp/prop-eq';

// reference: https://github.com/braintree/credit-card-type/blob/main/src/lib/card-types.ts
// (we are not using that package since we have some wizz specific cards
// and additional luhn checks, though it would be possible to come up w smg)
//
// WARN: the order is important
const cards = {
  visa: {
    rex: /^4\d*$/,
    lengths: [13, 16, 18, 19], // we think it may be 13 too?!
    id: CREDIT_CARD_TYPE_CODE_VISA,
  },
  mastercard_wizz_hu: {
    rex: /^(554522|516050)/,
    lengths: [16],
    id: CREDIT_CARD_TYPE_CODE_MASTERCARD,
  },
  mastercard_wizz_ro: {
    rex: /^(546915|541644)/,
    lengths: [16],
    id: CREDIT_CARD_TYPE_CODE_MASTERCARD,
  },
  mastercard: {
    rex: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)\d*$/,
    lengths: [16],
    id: CREDIT_CARD_TYPE_CODE_MASTERCARD,
  },
  amex: {
    rex: /^3[47]\d*$/,
    lengths: [15],
    id: false, // not supported
  },
  diners: {
    rex: /^3(0[0-5]|[689])\d*$/,
    lengths: [14, 16, 19],
    id: CREDIT_CARD_TYPE_CODE_DINERS,
  },
  discover: {
    rex: /^(6011|65|64[4-9])\d*$/,
    lengths: [16, 19],
    id: CREDIT_CARD_TYPE_CODE_DISCOVER,
  },
  jcb: {
    // we do not support jcb, but we need it for the detection
    rex: /^(2131|1800|35)\d*$/,
    lengths: [16],
    id: false, // not supported
  },
  bancontact: {
    rex: /^6703/,
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    id: CREDIT_CARD_TYPE_CODE_BANCONTACT,
  },
  maestro: {
    rex: /^(5[06-9]|6[37])\d*$/,
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    id: CREDIT_CARD_TYPE_CODE_MAESTRO,
  },
  uatp: {
    rex: /^1/, // much fuzz
    lengths: [15],
    id: CREDIT_CARD_TYPE_CODE_UATP,
  },
};

/**
 * returns the cardType object (see paymentoptions response,)
 * from `storedCreditCardPaymentOption.availableStoredCreditCards[N].cardType`
 *
 * cardType schema:
 * ```
 * availableCurrencies: [ 'EUR', 'GBP' ... ],
 * creditCardTypeCode: "MA",
 * creditCardTypeName: "Maestro",
 * isSecurityCodeRequired: true,
 * groupBookingPartialPayment: {}
 * ```
 * @param payment
 * @returns {*}
 */
export const getSelectedCreditCardType = (payment) => {
  const paymentOptions = payment.options;
  const cardTypes = paymentOptions.creditCardPaymentOption.availableCreditCardTypes;
  const { cardNumber } = payment.creditCard;
  const cardTypeCode = determineCreditCardTypeCode(cardNumber);
  // select the default card type with most currencies available
  const orderedCards = [...cardTypes].sort(
    (a, b) => a.availableCurrencies.length < b.availableCurrencies.length
  );
  let cardTypeObject = orderedCards[0] || {};

  if (cardTypeCode) {
    const selectedCreditCardType =
      cardTypes.find(propEq('creditCardTypeCode', cardTypeCode)) || {};
    cardTypeObject = selectedCreditCardType;
  }

  return cardTypeObject;
};

export const determineCreditCardTypeCode = (number) => {
  number = (number || '').replace(/\D/g, '');
  if (!number || !luhnCheck(number)) {
    return '';
  }
  for (const key in cards) {
    if (has(key, cards)) {
      const card = cards[key];
      if (card.rex.test(number) && lengthCheck(card.lengths, number)) {
        return card.id;
      }
    }
  }
  return '';
};

const luhnCheck = (number) => {
  if (!number) {
    return false;
  }

  number = number.replace(/\D/g, '');

  const array = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let length = number.length;
  let bit = 1;
  let sum = 0;
  let value;

  while (length) {
    value = Number.parseInt(number.charAt(--length), 10);
    // eslint-disable-next-line no-cond-assign
    sum += (bit ^= 1) ? array[value] : value;
  }

  return sum && sum % 10 === 0;
};

const lengthCheck = (lengths, s) => lengths.map((l) => s.length === l).some((l) => l);
