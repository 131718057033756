export const TRIP_PLANNER_GA_CATEGORY_NAMES = new Map();
TRIP_PLANNER_GA_CATEGORY_NAMES.set('AnyCategory', 'Any category');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('CityGetaway', 'City getaway');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('FamilyHolidays', 'Family Holidays');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('WinterSports', 'Winter Sports');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('AdventureSummerSports', 'Adventure / Sports');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('Romance', 'Romance');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('Beach', 'Beach');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('OffTheBeatenTrack', 'Off The Beaten Track');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('EntertainmentNightlife', 'Entertainment / Nightlife');
TRIP_PLANNER_GA_CATEGORY_NAMES.set('CheapBeer', 'Cheap Beer');

export const TRIP_PLANNER_DATE_RANGES = [
  {
    id: 0,
    name: 'any-time',
  },
  {
    id: 1,
    name: 'next-three-months',
  },
  {
    id: 2,
    name: 'custom-date-range',
  },
];

export const TRIP_PLANNER_DATE_RANGES_GA_NAMES = new Map();
TRIP_PLANNER_DATE_RANGES_GA_NAMES.set(0, 'Any Time');
TRIP_PLANNER_DATE_RANGES_GA_NAMES.set(1, 'Next 3 Months');
TRIP_PLANNER_DATE_RANGES_GA_NAMES.set(2, 'Date Range');

export const TRIP_PLANNER_DURATIONS = [
  {
    id: 0,
    name: '1-3-days',
    minNights: 0,
  },
  {
    id: 1,
    name: '4-8-days',
    minNights: 3, // #18250 4-8 days selection starts from 3 nights
  },
  {
    id: 2,
    name: 'one-week',
    minNights: 7, // #18250 one week selection starts from 7 nights
  },
  {
    id: 3,
    name: '9-30-days',
    minNights: 8, // #18250 9-30 days selection starts from 8 nights
  },
  {
    id: 4,
    name: 'weekend-only',
    minNights: 1,
    maxNights: 4,
  },
];

export const TRIP_PLANNER_DURATIONS_GA_NAMES = new Map();
TRIP_PLANNER_DURATIONS_GA_NAMES.set(0, '1-3 days');
TRIP_PLANNER_DURATIONS_GA_NAMES.set(1, '4-8 days');
TRIP_PLANNER_DURATIONS_GA_NAMES.set(2, 'Week');
TRIP_PLANNER_DURATIONS_GA_NAMES.set(3, '9-30 days');
TRIP_PLANNER_DURATIONS_GA_NAMES.set(4, 'Weekend only (Fri-Mon)');

export const TRIP_PLANNER_ANY_TIME_DATE_RANGE_ID = 0;
export const TRIP_PLANNER_CUSTOM_DATE_RANGE_ID = 2;
export const TRIP_PLANNER_DEFAULT_VISIBLE_RESULTS = 5;

export const TRIP_PLANNER_CATEGORY_ICONS = new Map();
TRIP_PLANNER_CATEGORY_ICONS.set(0, 'icon__hidden');
TRIP_PLANNER_CATEGORY_ICONS.set(1, 'icon__trip_planner__cat_city-break');
TRIP_PLANNER_CATEGORY_ICONS.set(2, 'icon__trip_planner__cat_family');
TRIP_PLANNER_CATEGORY_ICONS.set(3, 'icon__trip_planner__cat_winter-sports');
TRIP_PLANNER_CATEGORY_ICONS.set(4, 'icon__trip_planner__cat_sports');
TRIP_PLANNER_CATEGORY_ICONS.set(5, 'icon__trip_planner__cat_romance');
TRIP_PLANNER_CATEGORY_ICONS.set(6, 'icon__trip_planner__cat_beach');
TRIP_PLANNER_CATEGORY_ICONS.set(7, 'icon__trip_planner__cat_beaten-track');
TRIP_PLANNER_CATEGORY_ICONS.set(8, 'icon__trip_planner__cat_nightlife');
TRIP_PLANNER_CATEGORY_ICONS.set(9, 'icon__trip_planner__cat_cheap-beer');

export const TRIP_PLANNER_CATEGORY_TITLES = new Map();
TRIP_PLANNER_CATEGORY_TITLES.set(1, 'CityGetaway');
TRIP_PLANNER_CATEGORY_TITLES.set(2, 'FamilyHolidays');
TRIP_PLANNER_CATEGORY_TITLES.set(3, 'WinterSports');
TRIP_PLANNER_CATEGORY_TITLES.set(4, 'AdventureSummerSports');
TRIP_PLANNER_CATEGORY_TITLES.set(5, 'Romance');
TRIP_PLANNER_CATEGORY_TITLES.set(6, 'Beach');
TRIP_PLANNER_CATEGORY_TITLES.set(7, 'OffTheBeatenTrack');
TRIP_PLANNER_CATEGORY_TITLES.set(8, 'EntertainmentNightlife');
TRIP_PLANNER_CATEGORY_TITLES.set(9, 'CheapBeer');
