export const UPDATE_ANALYTICS_EXCHANGE_RATE = 'analytics/updateAnalyticsExchangeRate';
export const UPDATE_ANALYTICS_PRODUCT_MAP = 'analytics/updateAnalyticsProductMap';
export const SET_WDC_MEMBERSHIP_PURCHASE_LOCATION =
  'analytics/setWdcMembershipPurchaseLocation';
export const SET_PRIORITY_BOARDING_PURCHASE_LOCATION =
  'analytics/setPriorityBoardingPurchaseLocation';
export const SET_SMS_PURCHASE_LOCATION = 'analytics/setServicePurchaseLocation';
export const SET_AIRPORT_CHECK_IN_PURCHASE_LOCATION =
  'analytics/setAirportCheckInPurchaseLocation';
export const SET_WIZZ_FLEX_PURCHASE_LOCATION = 'analytics/setWizzFlexPurchaseLocation';
export const SET_INSURANCE_PURCHASE_LOCATION =
  'analytics/setAirportCheckInPurchaseLocation';
export const SET_WDC_DISCOUNT_SAVING = 'analytics/setWdcDiscountSaving';
export const SET_AUTO_CHECK_IN_PURCHASE_LOCATION =
  'analytics/setAutoCheckInPurchaseLocation';
export const SET_OUTBOUND_FARE_PURCHASE_LOCATION =
  'analytics/setOutboundFarePurchaseLocation';
export const SET_RETURN_FARE_PURCHASE_LOCATION =
  'analytics/setReturnFarePurchaseLocation';
export const SET_SEAT_PURCHASE_LOCATION = 'analytics/setSeatPurchaseLocation';
export const SET_SITTING_TOGETHER_PURCHASE_LOCATION =
  'analytics/setSittingTogetherPurchaseLocation';
export const SET_SELECTED_PAYMENT_OPTION = 'analytics/setSelectedPaymentOption';
