/* eslint import/no-cycle: "warn" */
import to from 'await-to-js';

import {
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  TRACKING_LOCATION_FLIGHT_SELECT_UPSELL,
} from '~/constants';
import pathEq from '~/utils/fp/path-eq';
import isEmpty from '~/utils/object/is-empty';
import isNotFunction from '~/utils/object/is-not-function';
// todo: do we want dom related stuff in store actions?
import { scrollTo } from '~/utils/dom';
import { capitalize } from '~/utils/string';
import { createAction } from '~/utils/store';
import { apiErrors } from '~/utils/services';
import { captureException } from '~/utils/logging';
import { wizzDiscountClub, startFlightChange } from '~/services/booking';
import {
  isOutboundDirection,
  isReturnDirection,
  isNotPlusBundle,
  isPlusBundle,
} from '~/utils/booking/booking';

import * as EcommerceService from '~/services/ecommerce';
import * as BookingService from '~/services/booking';
import * as bookingActions from '~/store/modules/booking/actions';
import * as analyticsActions from '~/store/modules/analytics/actions';
import * as analyticsGetters from '~/store/modules/analytics/getters';
import * as coreBookingGetters from '~/store/modules/core-booking/getters';
import * as summaryActions from '~/store/modules/summary/actions';
import * as summaryGetters from '~/store/modules/summary/getters';
import * as ancillaryGetters from '~/store/modules/ancillaries/getters';
import * as ancillaryActions from '~/store/modules/ancillaries/actions';
import * as passengersGetters from '~/store/modules/passengers/getters';
import * as passengersActions from '~/store/modules/passengers/actions';
import * as featureGetters from '~/store/modules/feature/getters';
import isNotNil from '~/utils/object/is-not-nil';
import { LoadingCounter, ErrorType, Operation } from '../constants';
import * as getters from '../getters';
import * as m from '../mutation-types';
import * as sync from '../sync';
import {
  _toggleFareType,
  _onLogin,
  _showNextAvailableFlight,
  _submit,
  _searchFlights,
  _showReturnFlights,
  _changeFlight,
  removeWdcMembership,
  selectGroupWdcMembership,
  selectStandardWdcMembership,
  _toggleWizzFlex,
  _setWizzFlex,
  _initialize,
  _selectNextOutboundFlightDate,
  _selectPreviousOutboundFlightDate,
  _selectNextReturnFlightDate,
  _selectPreviousReturnFlightDate,
  trackableOpenedFares,
  mapFareToEcommerceItem,
} from './internal-a';
import {
  _fetchMoreFlightDates,
  _setIsWdcFareTypeSelected,
  coreSubmit,
  _toggleFareLock,
  _selectFare,
  isThereEnoughTimeBeforeDeparture,
  resetReturnSelection,
  resetOutboundSelection,
  resetAllSelection,
  fetchAdditionalFees,
} from './internal-b';
import { removeAndPostWizzFlex, addAndPostWizzFlex, _postSelect } from './internal-c';

export { removeWdcMembership };
export { resetAllSelection } from './internal-b';
export { _postSelect as postSelect } from './internal-c';

/**
 * @type {(actionName: string, action: (store, ...args: any[]) => Promise) => (store: Store, ...args: any[]) => Promise}
 */
const asyncAction = (actionName, action) => {
  if (isEmpty(Operation[actionName]))
    throw new Error(`Operation with name (${actionName}) could not be found!`);
  if (isEmpty(ErrorType[actionName]))
    throw new Error(`Error type with name (${actionName}) could not be found!`);
  if (isEmpty(LoadingCounter[actionName])) {
    throw new Error(`Loading counter with name (${actionName}) could not be found!`);
  }
  if (isNotFunction(action))
    throw new Error('The provided `action` parameter is not a function!');

  return async (store, ...args) => {
    const [error] = await to(callAsyncAction({ actionName, action }, store, ...args));
    if (error) {
      // todo: do we want dom related stuff in store actions?
      return scrollTo('#flight-select-error-notice');
    }
  };
};

/**
 * @type {(options: {actionName: string, action: (store, ...args: any[]) => Promise }, store: Store, ...args: any[]) => Promise}
 */
const callAsyncAction = async ({ actionName, action } = {}, store, ...args) => {
  if (isEmpty(Operation[actionName]))
    throw new Error(`Operation with name (${actionName}) could not be found!`);
  if (isEmpty(ErrorType[actionName]))
    throw new Error(`Error type with name (${actionName}) could not be found!`);
  if (isEmpty(LoadingCounter[actionName])) {
    throw new Error(`Loading counter with name (${actionName}) could not be found!`);
  }
  if (isNotFunction(action))
    throw new Error('The provided `action` parameter is not a function!');

  const { commit } = store;
  commit(m.RESET_ERRORS_OF, ErrorType[actionName]);
  commit(m.INCREMENT_LOADING_COUNTER_OF, LoadingCounter[actionName]);

  let promise = Promise.resolve();
  let error = null;
  // note: action may fail before returning an actual promise and we want to catch
  //  that error as well and store it
  try {
    promise = action(store, ...args);
  } catch (_error) {
    error = _error;
  }

  commit(m.SET_OPERATION_PROMISE_OF, { operation: Operation[actionName], promise });
  [error] = error ? [error, null] : await to(promise);
  commit(m.DECREMENT_LOADING_COUNTER_OF, LoadingCounter[actionName]);

  if (error) {
    commit(m.ADD_ERROR_OF, { type: ErrorType[actionName], error });
    throw error;
  }
};

/**
 * @type {(store: Store, date: string) => Promise}
 */
export const selectOutboundFlightDate = asyncAction(
  Operation.selectOutboundFlightDate,
  (store, date) => {
    return _searchFlights(store, {
      date,
      direction: DIRECTION_OUTBOUND,
      shouldCorrectDateWithNoFlight: true,
    });
  }
);

/**
 * @type {(store: Store, date: string) => Promise}
 */
export const selectReturnFlightDate = asyncAction(
  Operation.selectReturnFlightDate,
  (store, date) => {
    return _searchFlights(store, {
      date,
      direction: DIRECTION_RETURN,
      shouldCorrectDateWithNoFlight: true,
    });
  }
);

/**
 * @type {(store: Store) => Promise}
 */
export const selectNextOutboundFlightDate = asyncAction(
  Operation.selectNextOutboundFlightDate,
  _selectNextOutboundFlightDate
);

/**
 * @type {(store: Store) => Promise}
 */
export const selectPreviousOutboundFlightDate = asyncAction(
  Operation.selectPreviousOutboundFlightDate,
  _selectPreviousOutboundFlightDate
);

/**
 * @type {(store: Store) => Promise}
 */
export const selectNextReturnFlightDate = asyncAction(
  Operation.selectNextReturnFlightDate,
  _selectNextReturnFlightDate
);

/**
 * @type {(store: Store) => Promise}
 */
export const selectPreviousReturnFlightDate = asyncAction(
  Operation.selectPreviousReturnFlightDate,
  _selectPreviousReturnFlightDate
);

/**
 * @type {(store: Store) => void}
 */
export const selectEmailSubscription = createAction(
  m.SET_IS_EMAIL_SUBSCRIPTION_CHECKED_FLAG
);

/**
 * @type {(store: Store) => void}
 */
export const showFareBenefits = createAction(m.SHOW_FARE_BENEFITS);

/**
 * @type {(store: Store) => void}
 */
export const hideFareBenefits = createAction(m.HIDE_FARE_BENEFITS);

export const resetErrors = createAction(m.RESET_ERRORS);

/**
 * @type {(store: Store) => Promise}
 */
export const initialize = asyncAction(Operation.initialize, _initialize);

/**
 * @type {(store: Store) => Promise}
 */
export const toggleWizzFlex = asyncAction(Operation.toggleWizzFlex, _toggleWizzFlex);

/**
 * @type {(store: Store) => Promise}
 */
export const setWizzFlex = asyncAction(Operation.setWizzFlex, _setWizzFlex);

/**
 * @type {(store: Store, flightDate: string) => Promise}
 */
export const showReturnFlights = asyncAction(
  Operation.showReturnFlights,
  _showReturnFlights
);

export const openFlight = (store, payload = {}) => {
  const { commit, state } = store;
  const { direction, flightId } = payload;

  commit(m.OPEN_FLIGHT, { direction, flightId });
  // todo: maybe this is not needed here, and would be enough to call/sync in
  //  `selectFare` caused problems in validationResult in flight select store
  //  component where we had to update the logic to rely on fares instead of flights
  sync.onOpenFlight(store, { direction, flightId });

  const fareItems = trackableOpenedFares(state, direction);
  if (fareItems) EcommerceService.sendEcommerceViewItemEvent(fareItems);
};

// forced GA view_item events in case of bundle upsells ==> needed to be able to track
// purchases that were bought via upsell
export const sendFareViewItemEvent = (store, payload = {}) => {
  const { state } = store;
  const { direction, location, bundle = '' } = payload;

  let flightSellKey, fare;

  if (direction === DIRECTION_OUTBOUND) {
    flightSellKey = getters.selectedOutboundFlight(state).id;
    fare = getters.outboundFareToUpgrade(state, bundle);
  } else {
    flightSellKey = getters.selectedReturnFlight(state).id;
    fare = getters.returnFareToUpgrade(state, bundle);
  }

  const trackableFare = {
    fare,
    flightSellKey,
    direction,
    location,
    state,
  };

  const regularFare =
    getters.isRegularFareTypeAvailable(state) && isNotNil(fare.regularPriceDetails)
      ? mapFareToEcommerceItem({ ...trackableFare, isWdc: false })
      : null;
  const wdcFare =
    getters.isWdcFareTypeAvailable(state) && isNotNil(fare.wdcPriceDetails)
      ? mapFareToEcommerceItem({ ...trackableFare, isWdc: true })
      : null;

  const trackableFares = [
    ...(regularFare ? [regularFare] : []),
    ...(wdcFare ? [wdcFare] : []),
  ];
  EcommerceService.measureProductDetails(trackableFares);
};

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise}
 */
export const selectFare = asyncAction(Operation.selectFare, _selectFare);

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise}
 */
export const changeFlight = asyncAction(Operation.changeFlight, _changeFlight);

/**
 * @type {(store: Store) => Promise}
 */
export const toggleFareType = asyncAction(Operation.toggleFareType, _toggleFareType);

/**
 * @type {(store: Store) => Promise}
 */
export const toggleFareLock = asyncAction(Operation.toggleFareLock, _toggleFareLock);

export const fareLockMeasureDetailsView = ({ state }) => {
  const code = getters.fareLockFeeCode(state);

  if (code) {
    EcommerceService.measureProductDetails({
      type: 'fareLock',
      metadata: analyticsGetters.metadata(state),
      data: {
        name: 'fareLock',
        code,
        count: 1,
      },
    });
  }
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const selectWdcTermsAgreed = (store, value) => {
  const { state, commit } = store;
  commit(m.SET_IS_WDC_TERMS_AGREED_FLAG, value);
  if (value) return;
  if (getters.isWdcPremiumEnabled(state)) return;
  removeWdcMembership(store);
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const highlightWdcPromotion = (store, value) => {
  store.commit(m.SET_IS_WDC_HIGHLIGHTED, value);
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const preselectOutboundBasicBundle = (store, value) => {
  store.commit(m.SET_IS_OUTBOUND_BASIC_PRESELECTED, value);
};

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const preselectReturnBasicBundle = (store, value) => {
  store.commit(m.SET_IS_RETURN_BASIC_PRESELECTED, value);
};

/**
 * @type {(store: Store) => void}
 */
export const addWdcMembership = async (store, shouldPost = false) => {
  const { state, commit } = store;
  const selectedWdcMembership = getters.selectedWdcMembership(state);

  const membership = getters
    .wdcMemberships(state)
    .find((item) => item.membership.includes(selectedWdcMembership));

  if (membership && shouldPost) {
    await wizzDiscountClub({ requestedWdcMembership: membership.membership });
  }

  if (getters.hasSelectedFareOnEitherFlight(state)) {
    changeSelectionToWdcFareType(store);
    if (
      featureGetters.isSplitPaxEnabled(state) &&
      coreBookingGetters.isNewBookingFlow(state)
    ) {
      commit(m.SET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION);
    }
  } else {
    toggleFareType(store);
  }

  summaryActions.addPayableWdcMembership(store, membership);
  commit(m.SET_HAS_PURCHASED_WDC_MEMBERSHIP);
  sync.onAddWdcMembership(store, membership);
};

/**
 * @type {(store: Store, direction: Direction, scrollDirection: string) => Promise}
 */
export const fetchMoreFlightDates = asyncAction(
  Operation.fetchMoreFlightDates,
  _fetchMoreFlightDates
);

/**
 * @type {(store: Store) => Promise}
 */
export const onLogin = asyncAction(Operation.onLogin, _onLogin);

/**
 * @type {(store: Store, direction: Direction) => Promise}
 */
export const showNextAvailableFlight = asyncAction(
  Operation.showNextAvailableFlight,
  _showNextAvailableFlight
);

export const selectMembership = (store, membership) => {
  const { commit } = store;
  commit(m.SELECT_WDC_MEMBERSHIP, membership);
};

export const resetSelectedWdcMembership = (store) =>
  store.commit(m.RESET_SELECTED_WDC_MEMBERSHIP);

export const onSelectWdcMembership = (state, isGroupSelected) => {
  if (isGroupSelected) {
    selectGroupWdcMembership(state);
  } else {
    selectStandardWdcMembership(state);
  }
};

/**
 * @type {(store: Store, payload: {}) => void}
 */
export const selectGroupRequestedFlight = (store, payload = {}) => {
  const { commit, state } = store;
  const { direction, flightId, fareId } = payload;

  summaryActions.resetPayableFlightAndFares(store);
  commit(m.SELECT_GROUP_REQUESTED_FLIGHT, { direction, flightId });

  if (
    isOutboundDirection(direction) &&
    getters.isRoundTrip(state) &&
    getters.selectedReturnFlight(state) &&
    !isThereEnoughTimeBeforeDeparture(
      (getters.selectedOutboundFlight(state) || {}).arrivalDateTime,
      (getters.selectedReturnFlight(state) || {}).departureDateTime
    )
  ) {
    resetReturnSelection(store, DIRECTION_RETURN);
    commit(m.RESET_GROUP_REQUESTED_FLIGHT, DIRECTION_RETURN);
  }

  if (
    isOutboundDirection(direction) &&
    getters.selectedReturnFareId(state) &&
    !getters.returnGroupRequestedFlightId(state)
  ) {
    resetReturnSelection(store, DIRECTION_RETURN);
  } else if (
    isReturnDirection(direction) &&
    getters.selectedOutboundFareId(state) &&
    !getters.outboundGroupRequestedFlightId(state)
  ) {
    resetOutboundSelection(store, DIRECTION_OUTBOUND);
  }

  sync.syncFlightAndFare(store, {
    fareId,
    direction,
    isGroupSeatRequest: true,
    selectedFlightId: flightId,
    isFlightChangeOrRebookFlow: coreBookingGetters.isFlightChangeOrRebookFlow(state),
  });
};

/**
 * @type {(store: Store) => void}
 */
export const selectGetWizzDiscountClub = createAction(
  m.SET_SHOW_WDC_MEMBERSHIP_DETAILS_FLAG
);

/**
 * @type {(store: Store) => Promise}
 */
export const changeSelectionToWdcFareType = (store) => {
  const { state } = store;

  _setIsWdcFareTypeSelected(store);

  const promises = [];
  [DIRECTION_OUTBOUND, ...(getters.isRoundTrip(state) ? [DIRECTION_RETURN] : [])].forEach(
    (direction) => {
      const capitalDirection = capitalize(direction);
      const promise = _selectFare(store, {
        skipWizzFlexInit: true,
        direction,
        fareId: getters[`selected${capitalDirection}Fare`](state).id,
        flightId: getters[`selected${capitalDirection}Flight`](state).id,
      });
      promises.push(promise);
    }
  );

  return Promise.all(promises);
};

/**
 * @type {(store: Store, isValid: boolean) => Promise}
 */
export const submit = async (store, { isValid, isDivideBooking = false }) => {
  const { state } = store;
  if (getters.isUserActionNotAllowed(state)) return;

  const [error] = await to(
    callAsyncAction({ actionName: Operation.submit, action: _submit }, store, isValid)
  );

  if (error) {
    captureException(error);
    const validationCodes = apiErrors(error);
    if (validationCodes.includes('FareSoldOut')) {
      bookingActions.setRestartBookingModalVisibility(true);
    }

    if (coreBookingGetters.isFlightChangeOrRebookFlow(state) && !isDivideBooking) {
      await startFlightChange();
    }

    // todo do we want dom related stuff in store actions?
    return scrollTo('#flight-select-error-notice');
  }
};

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise}
 * this upgrade option is used either on passengers or bundle upsell steps
 */
export const upgradeBundle = async (store, payload = {}) => {
  const { commit, state } = store;
  const { bundlesToUpgrade, purchaseLocation } = payload;

  const seatAssignment = ancillaryGetters.seatAssignment(state);

  commit(m.UNSET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION);

  if (
    bundlesToUpgrade.every(isNotPlusBundle) &&
    getters.isPlusFareBundleSelectedOnEitherFlight(state)
  ) {
    summaryActions.restoreIsWizzFlexSelectedAtFlightSelect(store);
  }

  const isWizzFlexSelectedAtFlightSelect =
    summaryGetters.isWizzFlexSelectedAtFlightSelect(state);
  if (isWizzFlexSelectedAtFlightSelect && bundlesToUpgrade.every(isPlusBundle)) {
    summaryActions.backupIsWizzFlexSelectedAtFlightSelect(store);
    summaryActions.unsetIsWizzFlexSelectedAtFlightSelect(store);
  }

  const selectFarePayloads = [
    DIRECTION_OUTBOUND,
    ...(getters.isRoundTrip(state) ? [DIRECTION_RETURN] : []),
  ].map((direction, index) => {
    const capitalDirection = capitalize(direction);
    const selectedFlightFares = getters[`selected${capitalDirection}FlightFares`](state);
    const fareToUpgrade = selectedFlightFares.find(
      pathEq('bundle.code', bundlesToUpgrade[index])
    );
    const flightId = getters[`selected${capitalDirection}FlightId`](state);
    const fareId = fareToUpgrade
      ? fareToUpgrade.id
      : getters[`selected${capitalDirection}FareId`](state);
    return { direction, flightId, fareId, skipWizzFlexInit: true };
  });

  // note: we need this to avoid triggering of possible multiple select/anci GET calls
  resetAllSelection(store);
  const selectFarePromises = selectFarePayloads.map((payload) => {
    const { direction } = payload;
    if (direction === DIRECTION_OUTBOUND) {
      analyticsActions.setOutboundFarePurchaseLocation(store, purchaseLocation);
    } else {
      analyticsActions.setReturnFarePurchaseLocation(store, purchaseLocation);
    }
    return _selectFare(store, payload);
  });
  await Promise.all(selectFarePromises);

  // note: we need to select wizz flex again if it was previously selected and bundle < plus
  if (isWizzFlexSelectedAtFlightSelect && bundlesToUpgrade.every(isNotPlusBundle)) {
    await addAndPostWizzFlex(store, true);
  }

  // isBundleUpgraded
  commit(m.TOGGLE_IS_BUNDLE_UPGRADED);

  // if seat was selected before, POST it again
  Object.entries(seatAssignment).forEach(([flight, seats]) => {
    Object.entries(seats).forEach(([seatCode, passengerId]) => {
      const selectedSeat = {
        flightName: flight,
        passengerId,
        seatCode,
      };
      ancillaryActions.addSelectSeat(store, selectedSeat);
    });
  });

  await coreSubmit(store);
  return ancillaryActions.postAncillaries(store, { withSummary: true });
};

// this upgrade option is used on flight select step
export const upgradeFareBundle = async (store, payload = {}) => {
  const { state, commit } = store;
  const { bundle, direction } = payload;

  const capitalDirection = capitalize(direction);
  const selectedFlightFares = getters[`selected${capitalDirection}FlightFares`](state);
  const fareToUpgrade = selectedFlightFares.find(pathEq('bundle.code', bundle));
  const flightId = getters[`selected${capitalDirection}FlightId`](state);
  const fareId = fareToUpgrade
    ? fareToUpgrade.id
    : getters[`selected${capitalDirection}FareId`](state);
  const selectFarePayload = {
    direction,
    flightId,
    fareId,
    skipWizzFlexInit: true,
  };

  const isWizzFlexSelectedAtFlightSelect = ancillaryGetters.isWizzFlexSelected(state);
  if (isWizzFlexSelectedAtFlightSelect && isPlusBundle(bundle)) {
    summaryActions.unsetIsWizzFlexSelectedAtFlightSelect(store);
    await removeAndPostWizzFlex(store);
  }

  if (direction === DIRECTION_OUTBOUND) {
    analyticsActions.setOutboundFarePurchaseLocation(
      store,
      TRACKING_LOCATION_FLIGHT_SELECT_UPSELL
    );
  } else {
    analyticsActions.setReturnFarePurchaseLocation(
      store,
      TRACKING_LOCATION_FLIGHT_SELECT_UPSELL
    );
  }

  const fareUpgradePayload = {
    direction,
    bundle,
    upsellPrice: getters[`${direction}UpsellToPriceDifference`](state),
  };
  commit(m.UPDATE_FARE_UPGRADE_DETAILS, fareUpgradePayload);
  await _selectFare(store, selectFarePayload);
  commit(m[`SET_IS_${direction.toUpperCase()}_FARE_UPGRADED`]);
};

export const setWdcAddedOnTargetedModal = createAction(m.SET_WDC_ADDED_ON_TARGETED_MODAL);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const resetContinueWithCurrentWdcMembership = (store) =>
  store.commit(m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setIsContinueWithCurrentWdcMembership = (store) =>
  store.commit(m.SET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);

/*
 * @type {(store: Store) => Promise}
 */
export const _initPassengers = async (store) => {
  const { state, commit } = store;
  commit(m.SET_IS_INIT_PASSENGERS_LOADING, true);
  await _postSelect(state);
  commit(m.SET_IS_POST_SELECT_CALLED_AFTER_FLIGHT_AND_FARE_SELECTION);
  await passengersActions.getPassengers(store);

  passengersActions.syncPassengersFromSavedData(store);
  passengersActions.passengersPrivilegePassActivation(store);

  if (getters.isWdcMembershipAddedToCart(state)) {
    const selectedMembership = getters
      .wdcMemberships(state)
      .find((item) => item.membership.includes(getters.selectedWdcMembership(state)));

    await BookingService.wizzDiscountClub({
      requestedWdcMembership: selectedMembership.membership,
    });
  }

  const passengers = passengersGetters.savedPassengerDetails(state);
  if (isEmpty(passengers)) {
    passengersActions.setSavedPassengerDetails(store);
    await fetchAdditionalFees(store);
  }
  commit(m.SET_IS_INIT_PASSENGERS_LOADING, false);
};

/**
 * @type {(store: Store) => Promise}
 */
export const initPassengers = asyncAction(Operation.initPassengers, _initPassengers);
