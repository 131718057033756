import curry from '../curry';
import { _sortBy } from '../sort-by';

/**
 * @typedef {<T, K extends keyof T>(p: K, arr: T[]) => T[]} SortByPropFn
 */

/**
 * @type SortByPropFn
 */
export const _sortByProp = (p, arr) => _sortBy((item) => item[p], arr);

/**
 * Sorts the list according to the given property.
 *
 * @type SortByPropFn
 * @example
 *
 *      let sortByOrder = sortByProp(prop('order'));
 *      let people = [{ name: 'Alice', order: 2 }, { name: 'Bob', order: 1 }];
 *      sortByOrder(people); //=> [{ name: 'Bob', order: 1 }, { name: 'Alice', order: 2 }]
 */
const sortByProp = curry(_sortByProp);

export default sortByProp;
