/* eslint import/no-cycle: "warn" */
import to from 'await-to-js';
import {
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  DISCOUNT_TYPE_NONE,
  FARE_DISCOUNT_TYPE_NONE,
  FEE_CODES,
  FEE_NAME_INFANT,
  FLOODLIGHT_COUNT_RESULT,
  STEP_SELECT_FLIGHT,
} from '~/constants';
import { SS_FARE_FINDER_PREFER_WDC } from '~/constants/session-storage';
import {
  PRODUCT_VARIANT_FAMILY,
  PRODUCT_VARIANT_NON_FAMILY,
} from '~/constants/analytics';
import isEmpty from '~/utils/object/is-empty';
import isNumber from '~/utils/object/is-number';
import {
  mergeFlightDates,
  markBadDatesInFareChart,
  shiftFareChartMinDates,
} from '~/utils/booking/flight';
import {
  isFlownFlight,
  isOutboundDirection,
  isNotBasicBundle,
} from '~/utils/booking/booking';
import { getFlashItem } from '~/utils/storage';
import { trackFloodlightEvent } from '~/utils/conversion-tracking/double-click';
import pick from '~/utils/fp/pick';
import * as AssetService from '~/services/asset';
import * as SearchService from '~/services/search';

import * as ancillariesGetters from '~/store/modules/ancillaries/getters';
import * as analyticsActions from '~/store/modules/analytics/actions';
import * as analyticsGetters from '~/store/modules/analytics/getters';
import * as bookingExtraInformationActions from '~/store/modules/booking-extra-information/actions';
import * as bookingGetters from '~/store/modules/booking/getters';
import * as coreBookingGetters from '~/store/modules/core-booking/getters';
import * as summaryGetters from '~/store/modules/summary/getters';
import * as flightChangeGetters from '~/store/modules/flight-change/getters';
import * as trackingActions from '~/store/modules/tracking/actions';
// todo we don't want to use the search store here at all
import * as searchGetters from '~/store/modules/search/getters';
import * as userGetters from '~/store/modules/user/getters';
import * as summaryActions from '~/store/modules/summary/actions';
import * as systemActions from '~/store/modules/system/actions';
import * as featureGetters from '~/store/modules/feature/getters';

import propEq from '~/utils/fp/prop-eq';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import { toDefaultFormat, isDayAfter, isDayBefore } from '~/utils/date';
import { LoadingCounter } from '../constants';
import { calculateSearchDates, getClosestPossibleDateForFlight } from '../internal';
import * as getters from '../getters';
import * as m from '../mutation-types';
import * as sync from '../sync';
import {
  removeFareLock,
  coreSubmit,
  convertFlight,
  getFlightDateData,
  fetchWdcMemberships,
  initFareTypeSelection,
  openedFlight,
  fetchPossibleDatesForFlights,
  getFlightSelectSearchData,
  handleMixedFareTypes,
  hasOnlyWdcFares,
  fetchImportantInformation,
  initFareSelection,
} from './internal-b';
import {
  toggleAndPostWizzFlex,
  setAndPostWizzFlex,
  removeAndPostWizzFlex,
} from './internal-c';

/**
 * @type {(nextFlightDateGetter: (state: State) => string, direction: Direction) => (store: Store) => Promise}
 */
// todo: probably we could get rid of direction later as well
const selectFlightDateByGetter = (flightDateGetter, direction) => (store) => {
  const { state } = store;
  return _searchFlights(store, {
    date: flightDateGetter(state),
    direction,
  });
};

export const _selectNextOutboundFlightDate = selectFlightDateByGetter(
  getters.nextOutboundFlightDate,
  DIRECTION_OUTBOUND
);

export const _selectPreviousOutboundFlightDate = selectFlightDateByGetter(
  getters.previousOutboundFlightDate,
  DIRECTION_OUTBOUND
);

export const _selectNextReturnFlightDate = selectFlightDateByGetter(
  getters.nextReturnFlightDate,
  DIRECTION_RETURN
);

export const _selectPreviousReturnFlightDate = selectFlightDateByGetter(
  getters.previousReturnFlightDate,
  DIRECTION_RETURN
);

/**
 * @type {(store: Store) => Promise}
 */
export const _initialize = async (store, payload = {}) => {
  const { commit, state } = store;
  const isWdcFareTypePreferred = getFlashItem(SS_FARE_FINDER_PREFER_WDC);
  commit(m.INITIALIZE, { ...payload, isWdcFareTypePreferred });

  const {
    departureDate,
    returnDate,
    metaSearchSource,
    numberOfAdultPassengers,
    numberOfChildPassengers,
    numberOfInfantPassengers,
    isFlightChangeOrRebookFlow,
  } = payload;
  const numberOfIndependentPassengers = getters.numberOfIndependentPassengers(state);

  sync.syncOnInit(store, {
    departureDate,
    returnDate,
    numberOfIndependentPassengers,
    numberOfAdultPassengers,
    numberOfChildPassengers,
    numberOfInfantPassengers,
  });

  // reset previous selection in case of new flight search in flight change flow.
  if (isFlightChangeOrRebookFlow) {
    summaryActions.resetPayableFlightAndFares(store);
  }

  const [fetchFlightDatesError] = await to(fetchFlightDates(store, true));

  const [_searchFlightsError] = await to(
    _searchFlights(store, {
      shouldCorrectDateWithNoFlight: !isFlightChangeOrRebookFlow,
      metaSearchSource,
    })
  );

  if (!getters.isWdcPremiumEnabled(state)) {
    initOfferedWdcMembership(store);
  }

  commit(m.SET_IS_INITIALIZED);

  analyticsActions.update(store);

  if (fetchFlightDatesError) throw fetchFlightDatesError;
  if (_searchFlightsError) throw _searchFlightsError;
};

/**
 * @type {(store: Store) => Promise}
 */
export const _toggleWizzFlex = (store) => {
  const { state } = store;
  const promise = toggleAndPostWizzFlex(store);

  // note: this is for instant feedback for the user (accidentally anci POST will
  //  remove fare lock)
  if (
    ancillariesGetters.isWizzFlexSelected(state) &&
    summaryGetters.isFareLockAdded(state)
  ) {
    removeFareLock(store);
  }

  return promise;
};

/**
 * @type {(store: Store) => Promise}
 */
export const _setWizzFlex = (store, payload) => {
  const { state } = store;
  const promise = setAndPostWizzFlex(store, payload);

  // note: this is for instant feedback for the user (accidentally anci POST will
  //  remove fare lock)
  if (
    ancillariesGetters.isWizzFlexSelected(state) &&
    summaryGetters.isFareLockAdded(state)
  ) {
    removeFareLock(store);
  }

  return promise;
};

/**
 * @type {(store: Store, direction: Direction) => Promise}
 */
export const _showNextAvailableFlight = (store, direction) => {
  const { state } = store;
  return _searchFlights(store, {
    date: isOutboundDirection(direction)
      ? getters.selectedOutboundFlightDate(state)
      : getters.selectedReturnFlightDate(state),
    direction,
    shouldCorrectDateWithNoFlight: true,
  });
};

/**
 * @type {(store: Store) => void}
 */
export const initOfferedWdcMembership = ({ commit, state }) => {
  commit(m.INIT_OFFERED_WDC_MEMBERSHIP, {
    hasStandardWdcMembership: getters.hasApplicableStandardWdcMembership(state),
    numberOfIndependentPassengers: getters.numberOfIndependentPassengers(state),
  });
};

/**
 * @type {(store: Store, flightDate: string) => Promise}
 */
export const _showReturnFlights = async (store, flightDate) => {
  const { commit, state } = store;
  commit(m.UNSET_IS_ANCILLARIES_GET_CALLED);

  const returnDate = toDefaultFormat(flightDate);
  const setSelectedFlightDatePromise = _setSelectedFlightDate(
    store,
    DIRECTION_RETURN,
    returnDate
  );
  await fetchFlightDates(store, true);

  if (ancillariesGetters.isWizzFlexSelected(state)) {
    // note: must be called before `search`
    await removeAndPostWizzFlex(store);
  }

  const searchFlightsPromise = _searchFlights(store, {
    date: returnDate,
    direction: DIRECTION_RETURN,
    shouldCorrectDateWithNoFlight: true,
  });

  return Promise.all([setSelectedFlightDatePromise, searchFlightsPromise]);
};

/**
 * @type {(store: Store, payload: Object<string, any>) => Promise}
 */
export const _searchFlights = async (store, payload = {}) => {
  const { state, commit } = store;
  const { date, direction, shouldCorrectDateWithNoFlight, metaSearchSource } = payload;
  const directionIsOutbound = direction ? isOutboundDirection(direction) : true;
  let changeFlightPromise = Promise.resolve();

  if (
    getters.isRoundTrip(state) &&
    directionIsOutbound &&
    getters.hasSelectedReturnFare(state) &&
    isDayAfter(date, getters.selectedReturnFlightDate(state))
  ) {
    changeFlightPromise = _changeFlight(store, { direction: DIRECTION_RETURN });
  }

  commit(m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);
  sync.resetFlightAndFare(store, direction);

  commit(m.SET_FLIGHT_DATES_DIFFERENCE);

  let flightDates = {};
  if (date && direction) {
    flightDates = {
      [direction]: date,
      ...(getters.isRoundTrip(state) && {
        [directionIsOutbound ? DIRECTION_RETURN : DIRECTION_OUTBOUND]: directionIsOutbound
          ? getters.selectedReturnFlightDate(state)
          : getters.selectedOutboundFlightDate(state),
      }),
    };
  } else {
    flightDates = {
      [DIRECTION_OUTBOUND]: getters.selectedOutboundFlightDate(state),
      ...(getters.isRoundTrip(state) && {
        [DIRECTION_RETURN]: getters.selectedReturnFlightDate(state),
      }),
    };
  }

  let searchDates = calculateSearchDates(
    getters.flightDatesDifference(state),
    flightDates
  );

  const fetchFlightDatesPromises = [];
  if (shouldCorrectDateWithNoFlight) {
    searchDates = await getDatesWithSureFlights(store, searchDates);
    Object.keys(searchDates).forEach((direction) => {
      if (
        !getters[`${direction}FlightDates`](state).some(
          (day) => day.date === `${searchDates[direction]}T00:00:00`
        )
      ) {
        const promise = fetchFlightDates(store, true, searchDates);
        fetchFlightDatesPromises.push(promise);
      }
    });
  }

  commit(m.INCREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);
  const setSelectedFlightDatePromises = [];
  Object.keys(searchDates).forEach((direction) => {
    const promise = _setSelectedFlightDate(store, direction, searchDates[direction]);
    setSelectedFlightDatePromises.push(promise);
    markBadDatesInFareChart(searchDates.outbound, {
      returnFlights: getters.returnFlightDates(state),
    });
  });

  const isConnectedFlight = coreBookingGetters.isConnectedBookingFlow(state);

  const desiredDatesInfo = {
    ...getFlightSelectSearchData(state, searchDates, metaSearchSource),
    ...(isConnectedFlight && { isConnectedFlight }),
  };

  // out logic with conversion logic which is necessary for the API to be called
  commit(m.INCREMENT_LOADING_COUNTER_OF, LoadingCounter.getFlights);
  const [error, response] = await to(SearchService.getFlights(desiredDatesInfo));
  commit(m.DECREMENT_LOADING_COUNTER_OF, LoadingCounter.getFlights);
  commit(m.DECREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);

  if (error) {
    commit(m.UPDATE_ON_FLIGHT_SEARCH, {
      discountType: DISCOUNT_TYPE_NONE,
      discountPercentage: 0,
      outboundFlights: [],
      returnFlights: [],
    });

    sync.syncOnFlightSearch(store, {
      outboundFlights: [],
      outboundBundles: [],
      returnFlights: [],
      returnBundles: [],
      departureStationCurrencyCode: '',
      arrivalStationCurrencyCode: '',
      isBundleCompositionWarningTextVisible: false,
      isDomestic: false,
      wdcRenewalPrice: null,
      directionIsOutbound: true,
    });

    // note: we have to "handle" errors from parallel promises - even if we throw
    //  and error here - or we could get an unneeded console error
    Promise.all([
      changeFlightPromise,
      ...fetchFlightDatesPromises,
      ...setSelectedFlightDatePromises,
    ]).catch(() => {}); // note here we don't care about further errors

    throw error;
  }

  let {
    outboundFlights,
    returnFlights,
    outboundBundles,
    returnBundles,
    currencyCode: departureStationCurrencyCode,
    arrivalStationCurrencyCode,
    bookingCurrencyRenewalPrice: wdcRenewalPrice,
    discountType,
    discountPercentage,
    isBundleCompositionWarningTextVisible,
    isDomestic,
    isWdcPremiumEnabled,
    isGroup: isGroupBooking,
    outboundCo2Emission,
    returnCo2Emission,
    connectedFlight,
  } = response.data;

  outboundFlights =
    (getters.isOutboundAccessibilityEnabled(state) ? outboundFlights : []) || [];
  returnFlights = returnFlights || [];
  outboundBundles = outboundBundles || [];
  returnBundles = returnBundles || [];
  departureStationCurrencyCode = departureStationCurrencyCode || '';
  arrivalStationCurrencyCode = arrivalStationCurrencyCode || '';
  discountType = discountType || FARE_DISCOUNT_TYPE_NONE;
  discountPercentage = isNumber(discountPercentage) ? discountPercentage : 0;

  const isFlightChangeOrRebookFlow = coreBookingGetters.isFlightChangeOrRebookFlow(state);

  let convertedOutboundFlights = outboundFlights.map(
    convertFlight(state, {
      isFlightChangeOrRebookFlow,
      direction: DIRECTION_OUTBOUND,
      bundles: outboundBundles,
      isGroupBooking,
      ...(connectedFlight && {
        transit: connectedFlight.outboundTransit,
      }),
    })
  );

  let convertedReturnFlights = returnFlights.map(
    convertFlight(state, {
      isFlightChangeOrRebookFlow,
      direction: DIRECTION_RETURN,
      bundles: returnBundles,
      isGroupBooking,
      ...(connectedFlight && {
        transit: connectedFlight.returnTransit,
      }),
    })
  );

  if (isNotEmpty(convertedOutboundFlights) && isNotEmpty(convertedReturnFlights)) {
    [convertedOutboundFlights, convertedReturnFlights] = handleMixedFareTypes(
      convertedOutboundFlights,
      convertedReturnFlights
    );
  }

  commit(m.UPDATE_ON_FLIGHT_SEARCH, {
    discountType,
    discountPercentage,
    wdcRenewalPrice,
    isBundleCompositionWarningTextVisible,
    isDomestic,
    isWdcPremiumEnabled,
    isGroupBooking,
    outboundFlights: convertedOutboundFlights,
    returnFlights: convertedReturnFlights,
    outboundCo2Emission,
    returnCo2Emission,
  });

  // todo we should only call this once and not at every search, flight change/group booking/agency booking excluded
  let fetchWdcMembershipsPromise = Promise.resolve();
  if (!getters.isGroupBooking(state) && !isFlightChangeOrRebookFlow) {
    fetchWdcMembershipsPromise = fetchWdcMemberships(store);
  }

  if (
    coreBookingGetters.isNewBookingFlow(state) ||
    coreBookingGetters.isConnectedBookingFlow(state)
  ) {
    bookingExtraInformationActions.fetchExtraInformation(store);
  }

  initFareTypeSelection(store);

  if (
    hasOnlyWdcFares(convertedOutboundFlights) ||
    hasOnlyWdcFares(convertedReturnFlights)
  ) {
    await fetchFlightDates(store, true);
  }

  commit(m.SET_SYNC_DATA, { outboundFlights, returnFlights });

  sync.syncOnFlightSearch(store, {
    outboundFlights,
    outboundBundles,
    returnFlights,
    returnBundles,
    departureStationCurrencyCode,
    arrivalStationCurrencyCode,
    isBundleCompositionWarningTextVisible,
    isDomestic,
    isWdcPremiumEnabled,
    wdcRenewalPrice,
    directionIsOutbound,
  });

  trackingActions.fbqTrackSearch(store);

  if (!coreBookingGetters.isFlightChangeOrRebookFlow(state)) {
    trackingActions.pintrkTrackSearch(store);
    trackingActions.ttqTrackSearch(store);
  }

  trackFloodlightEvent({
    params: pick(
      [
        'pageUrl',
        'departureStation',
        'arrivalStation',
        'departureDate',
        'returnDate',
        'numberOfPassengers',
        'isUserLoggedIn',
      ],
      analyticsGetters.floodlightVariables(state)
    ),
    sendTo: FLOODLIGHT_COUNT_RESULT,
  });

  return Promise.all([
    changeFlightPromise,
    fetchWdcMembershipsPromise,
    ...fetchFlightDatesPromises,
    ...setSelectedFlightDatePromises,
  ]);
};

export const fetchFlightDates = async (store, overwrite, baseDates = {}) => {
  const { commit, state } = store;
  if (getters.hasRescueFareCode(state)) return;

  const data = getFlightDateData(store, baseDates);
  const outboundDate = data.flightList[0].date;

  shiftFareChartMinDates(data.flightList);

  commit(m.INCREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);
  const response = await AssetService.fareChart(data);
  commit(m.DECREMENT_LOADING_COUNTER_OF, LoadingCounter.datePicker);

  if (isEmpty(response.data)) {
    commit(m.RESET_OUTBOUND_FLIGHT_DATES);
    commit(m.RESET_RETURN_FLIGHT_DATES);
    return;
  }

  const responseData = overwrite
    ? response.data
    : mergeFlightDates(response.data, {
        outboundFlights: getters.outboundFlightDates(state),
        returnFlights: getters.returnFlightDates(state),
      });

  commit(m.SET_PRICE_VISIBLE_ON_FARE_CHART, responseData.showPrices);

  const sortedFareChartData = markBadDatesInFareChart(outboundDate, responseData);
  commit(m.SET_OUTBOUND_FLIGHT_DATES, sortedFareChartData.outboundFlights);
  commit(m.SET_RETURN_FLIGHT_DATES, sortedFareChartData.returnFlights);
};

export const _changeFlight = (store, payload = {}) => {
  const { commit, state } = store;
  const { direction } = payload;
  commit(m.REMOVE_FARE, direction);

  summaryActions.clearPayableFlightFare(store, {
    // remove fare from summary
    type: direction,
  });

  if (summaryGetters.isFareLockAdded(state)) {
    removeFareLock(store);
  }

  if (getters.isContinueWithCurrentWdcMembership(state)) {
    commit(m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);
  }

  let promise = Promise.resolve();
  if (
    ancillariesGetters.isWizzFlexSelected(state) &&
    !featureGetters.isSplitPaxEnabled(state)
  ) {
    promise = setAndPostWizzFlex(store, false);
  }

  sync.resetFlightAndFare(store, direction);
  commit(m[`UNSET_IS_${direction.toUpperCase()}_FARE_UPGRADED`]);

  return promise;
};

export const trackableOpenedFares = (state, direction) => {
  const flight = openedFlight(state, direction);
  if (!flight) return;

  const flightSellKey = flight.id;

  return (flight.fares || []).reduce((acc, fare) => {
    if (isNotNil(fare.regularPriceDetails)) {
      acc.push(
        mapFareToEcommerceItem({ fare, flightSellKey, direction, isWdc: false, state })
      );
    }

    if (isNotNil(fare.wdcPriceDetails)) {
      acc.push(
        mapFareToEcommerceItem({ fare, flightSellKey, direction, isWdc: true, state })
      );
    }

    return acc;
  }, []);
};

export const mapFareToEcommerceItem = (params) => {
  const {
    fare,
    flightSellKey,
    direction,
    isWdc,
    location = STEP_SELECT_FLIGHT,
    state,
  } = params;
  const variant = fare.isFamily ? PRODUCT_VARIANT_FAMILY : PRODUCT_VARIANT_NON_FAMILY;
  const priceDetails = isWdc
    ? fare.wdcPriceDetails || {}
    : fare.regularPriceDetails || {};
  const filteredFees = removeFee(priceDetails.fees, FEE_CODES[FEE_NAME_INFANT]);

  return {
    type: 'fare',
    metadata: analyticsGetters.metadata(state),
    data: {
      flight: direction,
      fare,
      isWdc,
      price: priceDetails.promotionPrice || priceDetails.originalPrice,
      flightSellKey,
      analytics: {
        isDisabled: false,
        location,
        ...(isNotBasicBundle(fare.bundle.code) ? { variant } : {}),
      },
      children: (filteredFees || []).map((fee) =>
        mapFeeToEcommerceItem(
          fee,
          flightSellKey,
          priceDetails.fareSellKey,
          fare.bundle.code,
          direction,
          state
        )
      ),
    },
  };
};

const mapFeeToEcommerceItem = (
  fee,
  flightSellKey,
  fareSellKey,
  bundle,
  direction,
  state
) => {
  const { code, price, promotedPrice, displayCount: count, coupon } = fee;

  return {
    type: 'fee',
    metadata: analyticsGetters.metadata(state),
    data: {
      flight: direction,
      code,
      price: promotedPrice || price,
      flightSellKey,
      fareSellKey,
      bundle,
      analytics: {
        isDisabled: false,
        quantity: count,
        coupon,
      },
    },
  };
};

const removeFee = (fees, feeCode) => {
  const feeIndex = fees?.findIndex(propEq('code', feeCode));
  return fees?.filter((fee, index) => index !== feeIndex);
};

export const _toggleFareType = (store) => {
  const { state, commit } = store;
  commit(m.TOGGLE_FARE_TYPE);
  const isWdcFareTypeSelected = getters.isWdcFareTypeSelected(state);
  const isWdcPremiumEnabled = getters.isWdcPremiumEnabled(state);
  const isContinueWithCurrentWdcMembership =
    getters.isContinueWithCurrentWdcMembership(state);

  if (isWdcFareTypeSelected && summaryGetters.isFareLockAdded(state)) {
    removeFareLock(store);
  }

  if (
    isWdcPremiumEnabled &&
    !isWdcFareTypeSelected &&
    isContinueWithCurrentWdcMembership
  ) {
    commit(m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);
  }

  removeWdcMembership(store);

  summaryActions.resetPayableFlightAndFares(store);
  sync.syncOnToggleFareType(store, isWdcFareTypeSelected);

  return fetchFlightDates(store);
};

export const _onLogin = async (store) => {
  const { commit, state } = store;

  if (getters.hasApplicableWdcMembership(state)) {
    removeWdcMembership(store);
  }

  initFareTypeSelection(store);
  initFareSelection(store);

  let fetchWdcMembershipsPromise = Promise.resolve();
  if (
    (getters.hasApplicableStandardWdcMembership(state) &&
      getters.isGroupWdcMembershipRecommended(state)) ||
    getters.isWdcPremiumEnabled(state)
  ) {
    fetchWdcMembershipsPromise = fetchWdcMemberships(store);
  }

  let submitPromise = Promise.resolve();
  if (getters.isSubmitInProgress(state)) {
    await fetchWdcMembershipsPromise;
    commit(m.UNSET_IS_SUBMIT_IN_PROGRESS);
    submitPromise = _submit(store);
  }

  return Promise.all([fetchWdcMembershipsPromise, submitPromise]);
};

export const _submit = async (store, isValid) => {
  const { commit, state } = store;

  if (!isValid) {
    commit(m.SET_IS_SUBMIT_ATTEMPTED);
    return;
  }

  commit(m.UNSET_IS_SUBMIT_IN_PROGRESS);

  if (getters.hasToBuyWdcMembership(state)) return commit(m.SET_IS_SUBMIT_IN_PROGRESS);

  if (
    userGetters.isNotLoggedIn(state) &&
    (getters.isWdcMembershipAddedToCart(state) ||
      (featureGetters.isSplitPaxEnabled(state) && summaryGetters.isFareLockAdded(state)))
  ) {
    commit(m.SET_IS_SUBMIT_IN_PROGRESS);
    systemActions.showLoginModal(store, { openingSource: 'Booking flow' });
    return;
  }

  sync.syncOnSubmit(store, {
    isLoggedIn: userGetters.isLoggedIn(state),
    isWdcFareTypeSelected: getters.isWdcFareTypeSelected(state),
    isFlightChangeOrRebookFlow: coreBookingGetters.isFlightChangeOrRebookFlow(state),
    isOutboundFareSelected: getters.hasSelectedOutboundFare(state),
    isReturnFareSelected: getters.hasSelectedReturnFare(state),
    isRoundTrip: getters.isRoundTrip(state),
    flightChangedFrom: bookingGetters.flightChangedFrom(state),
  });

  // note: we need to save this before coreSubmit which calls ancillaries which may
  //  affect the value of this in a wrong way
  const isWizzFlexSelected = ancillariesGetters.isWizzFlexSelected(state);
  summaryActions.setIsWizzFlexSelectedAtFlightSelectFlag(store, isWizzFlexSelected);

  await coreSubmit(store);

  commit(m.SET_IS_SUBMITTED);
};

/**
 * @type {(mutation: string) => (store: Store) => void}
 */
const _selectWdcMembership = (mutation) => (store) => {
  removeWdcMembership(store);
  store.commit(mutation);
};

export const selectStandardWdcMembership = _selectWdcMembership(
  m.SELECT_STANDARD_WDC_MEMBERSHIP
);

export const selectGroupWdcMembership = _selectWdcMembership(
  m.SELECT_GROUP_WDC_MEMBERSHIP
);

/**
 * @type {(store: Store) => void}
 */
export const removeWdcMembership = (store) => {
  const { commit, state } = store;
  summaryActions.removePayableWdcMembership(store);
  commit(m.UNSET_IS_WDC_TERMS_AGREED);
  commit(m.UNSET_HAS_PURCHASED_WDC_MEMBERSHIP);

  if (getters.isWdcPremiumEnabled(state)) {
    commit(m.RESET_CONTINUE_WITH_CURRENT_WDC_MEMBERSHIP);
  }

  commit(m.RESET_SELECTED_WDC_MEMBERSHIP);
  sync.onRemoveWdcMembership(store);
};

const getDatesWithSureFlights = async (store, dates) => {
  const { state } = store;
  // todo we don't want to use the search store, we should store this stuff in this store
  let datesForFlight = searchGetters.possibleDatesForFlight(state);
  const isSelectedReturnDateOutOfRange =
    isDayAfter(
      dates[DIRECTION_RETURN],
      datesForFlight[DIRECTION_RETURN][datesForFlight[DIRECTION_RETURN].length - 1]
    ) || isDayBefore(dates[DIRECTION_RETURN], datesForFlight[DIRECTION_RETURN][0]);

  const isSelectedOutboundDateOutOfRange =
    isDayAfter(
      dates[DIRECTION_OUTBOUND],
      datesForFlight[DIRECTION_OUTBOUND][datesForFlight[DIRECTION_OUTBOUND].length - 1]
    ) || isDayBefore(dates[DIRECTION_OUTBOUND], datesForFlight[DIRECTION_OUTBOUND][0]);

  if (
    isEmpty(datesForFlight.outbound) ||
    isSelectedOutboundDateOutOfRange ||
    (getters.isRoundTrip(state) &&
      (isEmpty(datesForFlight.return) || isSelectedReturnDateOutOfRange))
  ) {
    await fetchPossibleDatesForFlights(store, dates);
    datesForFlight = searchGetters.possibleDatesForFlight(state);
  }

  for (const direction of Object.keys(dates)) {
    const hasFlightOnSelectedDate = datesForFlight[direction].includes(
      `${dates[direction]}T00:00:00`
    );

    if (!hasFlightOnSelectedDate && !searchGetters.hasReachedLastDateWithFlight(state)) {
      // todo: this is a quick fix for the case when we are in flight change and the
      //  outbound flight is flown then we shouldn't touch the original date
      // todo: we should process/import the necessary flight change payload in `initialize`
      const originalOutboundFlight =
        flightChangeGetters.outboundFlight(state)?.value ?? null;
      const isOutboundFlightFlown =
        originalOutboundFlight && isFlownFlight(originalOutboundFlight);

      if (!(isOutboundDirection(direction) && isOutboundFlightFlown)) {
        dates[direction] = getClosestPossibleDateForFlight(
          datesForFlight[direction],
          dates[direction]
        );
      }
    }
  }

  return dates;
};

/**
 * @type {(store: Store, direction: Direction, value: string) => Promise}
 */
const _setSelectedFlightDate = (store, direction, value) => {
  const { commit } = store;
  commit(m.SET_SELECTED_FLIGHT_DATE, { direction, value });
  sync.syncFlightDate(store, direction, value);
  return fetchImportantInformation(store, direction);
};
