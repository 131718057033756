import curry from '../curry';
import { _filter } from '../filter';
import { _complement } from '../complement';

/**
 * @typedef {<T>(fn: (value: T) => boolean, arr: T[]) => T[]} RejectFn
 */

/**
 * @type RejectFn
 */
export const _reject = (pred, arr) => _filter(_complement(pred), arr);

/**
 * The complement of [`filter`](#filter).
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/reject.js
 *
 * @type RejectFn
 * @example
 *
 *      const isOdd = (n) => n % 2 === 1;
 *
 *      reject(isOdd, [1, 2, 3, 4]); //=> [2, 4]
 *
 *      reject(isOdd, {a: 1, b: 2, c: 3, d: 4}); //=> {b: 2, d: 4}
 */
const reject = curry(_reject);

export default reject;
