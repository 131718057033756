import add from '../add';

/**
 * Adds together all the elements of a list.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/sum.js
 *
 * @type {<T>(arr: T[]) => number}
 * @example
 *
 *      sum([2, 4, 6, 8, 100, 1]); //=> 121
 */
const sum = (args) => args.reduce(add, 0);

export default sum;
