import { resetState } from './internal';
import * as m from './mutation-types';

export default {
  [m.RESET]: resetState,

  [m.SET_IS_INITIALIZATION_FAILED](state) {
    state.isInitializationFailed = true;
  },

  [m.INITIALIZE](state, payload) {
    Object.assign(state, payload);
  },
};
