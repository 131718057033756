export const SET_IS_SEAT_SELECTION_HAS_UNCONFIRMED_CHANGES_FROM_PREVIOUS_FLAG =
  'volatile/setIsSeatSelectionHasUnconfirmedChangesFromPreviousFlag';
export const SHOW_SEAT_SELECTION_MODAL = 'volatile/showSeatSelectionModal';
export const HIDE_SEAT_SELECTION_MODAL = 'volatile/hideSeatSelectionModal';
export const SET_SEAT_SELECTION_MODAL_VISIBILITY =
  'volatile/setSeatSelectionModalVisibility';
export const SHOW_WDC_TARGETED_MODAL = 'volatile/showWdcTargetedModal';
export const HIDE_WDC_TARGETED_MODAL = 'volatile/hideWdcTargetedModal';
export const SET_WDC_TARGETED_MODAL_VISIBILITY = 'volatile/setWdcTargetedModalVisibility';
export const SHOW_NEWSLETTER_CONSENT_MODAL = 'volatile/showNewsletterConsentModal';
export const HIDE_NEWSLETTER_CONSENT_MODAL = 'volatile/hideNewsletterConsentModal';
export const SET_NEWSLETTER_CONSENT_MODAL_VISIBILITY =
  'volatile/setNewsletterConsentModalVisibility';
export const TOGGLE_USER_MENU = 'volatile/toggleUserMenu';
export const TOGGLE_IS_SUMMARY_ON_MOBILE_VISIBLE =
  'volatile/toggleIsSummaryOnMobileVisible';
export const USER_DATA_LOADING = 'volatile/userDataLoading';
export const AIRPORT_TRANSFER_MODAL_VISIBILITY =
  'volatile/airportTransferModalVisibility';
export const ACORD_SERVICE_MODAL_VISIBILITY = 'volatile/acordServiceModalVisibility';
export const AIRPORT_PARKING_MODAL_VISIBILITY = 'volatile/airportParkingModalVisibility';
export const ACORD_SERVICE_COMPARISON_MODAL_VISIBILITY =
  'volatile/acordServiceComparisonModalVisibility';
export const UPDATE_BOOKING_FLOW_VISIBILITY = 'volatile/updateBookingFlowVisibility';
export const UPDATE_BOOKING_FLOW_LOADED_STATE = 'volatile/updateBookingFlowLoadedState';
export const SET_HAS_TO_REOPEN_UPSELL_MODAL = 'volatile/setHasToReopenUpsellModal';
export const MODIFY_SERVICES_MODAL_VISIBILITY = 'volatile/modifyServicesModalVisibility';
export const SHOW_PAYMENT_FAILED_MODAL = 'volatile/showPaymentFailedModal';
export const HIDE_PAYMENT_FAILED_MODAL = 'volatile/hidePaymentFailedModal';
export const SET_SEAT_SELECTION_EXIT_INTENT_VISIBILITY =
  'volatile/setSeatSelectionExitIntentVisibility';
export const SHOW_PHONE_NUMBER_UPDATE_MODAL = 'volatile/showPhoneNumberUpdateModal';
export const HIDE_PHONE_NUMBER_UPDATE_MODAL = 'volatile/hidePhoneNumberUpdateModal';
export const SET_PHONE_NUMBER_UPDATE_MODAL_VISIBILITY =
  'volatile/setPhoneNumberUpdateModalVisibility';
export const UPDATE_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY =
  'volatile/updateCancellationInsuranceInfoModalVisibility';
export const UPDATE_TRAVEL_INSURANCE_INFO_MODAL_VISIBILITY =
  'volatile/updateTravelInsuranceInfoModalVisibility';
export const UPDATE_TRAVEL_AND_CANCELLATION_INSURANCE_INFO_MODAL_VISIBILITY =
  'volatile/updateTravelAndCancellationInsuranceInfoModalVisibility';
export const SET_IS_MAIN_PAGE = 'volatile/setIsMainPage';
export const SET_NAVIGATION_CLASSLIST = 'volatile/setNavigationClasslist';
export const SET_CAPTCHA_MODAL_VISIBILITY = 'volatile/setCaptchaModalVisibility';
export const HIDE_CAPTCHA_MODAL = 'volatile/hideCaptchaModal';
export const SHOW_CAPTCHA_MODAL = 'volatile/showCaptchaModal';
export const SHOW_PAYMENT_EXIT_INTENT_MODAL = 'volatile/showPaymentExitIntentModal';
export const HIDE_PAYMENT_EXIT_INTENT_MODAL = 'volatile/hidePaymentExitIntentModal';
export const SHOW_ADD_FLIGHT_EXIT_INTENT_MODAL = 'volatile/showAddFlightExitIntentModal';
export const HIDE_ADD_FLIGHT_EXIT_INTENT_MODAL = 'volatile/hideAddFlightExitIntentModal';
export const SET_HERO_BANNER_INFO = 'volatile/setHeroBannerInfo';
export const DISMISS_YELLOW_RIBBON = 'volatile/dismissYellowRibbon';
export const SET_YELLOW_RIBBON_MESSAGE = 'volatile/setYellowRibbonMessage';
export const SET_IS_CAPTCHA_HANDLED = 'volatile/setIsCaptchaHandled';
export const UNSET_IS_CAPTCHA_HANDLED = 'volatile/unsetIsCaptchaHandled';
export const SET_IS_RESCUE_FARE_CODE_VISIBLE = 'volatile/setIsRescueFareCodeVisible';
export const SET_SELECTED_SEARCH_TAB = 'volatile/setSelectedSearchTab';
export const SET_IS_FLIGHT_ADDITION_REQUESTED = 'volatile/setIsFlightAdditionRequested';
export const SET_CALL_CENTER_INFORMATION = 'volatile/setCallCenterInformation';
