import {
  DIRECTION_BOTH,
  DIRECTION_OUTBOUND,
  DIRECTION_RETURN,
  FLOW_TYPE_CHANGE_FLIGHT,
  FLOW_TYPE_CHANGE_NAME,
  FLOW_TYPE_ADD_SERVICES,
  FLOW_TYPE_FARE_LOCK_FINALIZE,
  CHECK_IN_BAG_TYPES,
  FLOW_TYPE_CHECK_IN,
  FLOW_TYPE_CONNECTED_BOOKING,
} from '~/constants';
import { ANALYTICS_CURRENCY_CODE } from '~/constants/analytics';
import {
  BUYER_TYPE_CUSTOMER,
  BUYER_TYPE_AGENCY,
  TRAVEL_TYPE_GROUP,
  TRAVEL_TYPE_NORMAL,
  TRIP_TYPE_RETURN,
  TRIP_TYPE_ONE_WAY,
  ANALYTICS_FLOW_TYPE_CHANGE_FLIGHT,
  ANALYTICS_FLOW_TYPE_CHANGE_NAME,
  ANALYTICS_FLOW_TYPE_ADD_SERVICES,
  ANALYTICS_FLOW_TYPE_CHECKIN,
  ANALYTICS_FLOW_TYPE_NEW_BOOKING,
  ANALYTICS_FLOW_TYPE_FARE_LOCK_FINALIZE,
  ANALYTICS_FLOW_TYPE_CONNECTED_BOOKING,
} from '~/services/ecommerce/constants';
import isNil from '~/utils/object/is-nil';
import isNotNil from '~/utils/object/is-not-nil';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import path from '~/utils/fp/path';
import { getPagePathForAnalytics } from '~/utils/analytics';
import { isBundleHigher } from '~/utils/booking/booking';
import removeQueryString from '~/utils/methods/remove-query-string';
import {
  currentDateAndTime,
  formatDate,
  differenceInDays,
  startOfDay,
  isValidDate,
} from '~/utils/date';
import { ANALYTICS_DATE_FORMAT } from '~/constants/date';
import * as searchGetters from '../search/getters';
import * as userGetters from '../user/getters';
import * as itineraryGetters from '../itinerary/getters';
import * as coreBookingGetters from '../core-booking/getters';
import * as bookingGetters from '../booking/getters';
import * as ancillariesGetters from '../ancillaries/getters';
import * as successfulBookingGetters from '../successful-booking/getters';

export const metadata = (state) => ({
  flowType: coreBookingGetters.flowType(state),
  customDimensions: customDimensions(state),
  exchangeRate: exchangeRate(state),
  productMap: productMap(state),
  purchaseLocations: purchaseLocations(state),
});

export const exchangeRate = (state) => state.analytics.exchangeRate || 1;

export const productMap = (state) => state.analytics.map || [];

export const customDimensions = (state) => ({
  [DIRECTION_BOTH]: customDimensionsByDirection(state, DIRECTION_OUTBOUND),
  [DIRECTION_OUTBOUND]: customDimensionsByDirection(state, DIRECTION_OUTBOUND),
  [DIRECTION_RETURN]: customDimensionsByDirection(state, DIRECTION_RETURN),
});

const customDimensionsByDirection = (state, direction) => ({
  buyerType: buyerType(state),
  travelType: travelType(state),
  tripType: tripType(state),
  passengerType: passengerType(state),
  airportCodes: airportCodes(state, direction),
  dateOfTravel: dateOfTravel(state, direction),
  flowType: flowType(state),
  flightNumber: flightNumber(state, direction),
  daysToGo: daysToGo(state, direction),
  numberOfPassengers: numberOfPassengers(state),
});

const isPostBookingFlow = (state) =>
  !successfulBookingGetters.isSuccessfulBookingInitialized(state) &&
  (coreBookingGetters.isAddServicesFlow(state) ||
    coreBookingGetters.isCheckInFlow(state));

const buyerType = (state) =>
  userGetters.isAgency(state) ? BUYER_TYPE_AGENCY : BUYER_TYPE_CUSTOMER;

const travelType = (state) => {
  const getters = isPostBookingFlow(state) ? itineraryGetters : bookingGetters;
  return getters.isGroupBooking(state) ? TRAVEL_TYPE_GROUP : TRAVEL_TYPE_NORMAL;
};

export const isRoundTrip = (state) => {
  return isPostBookingFlow(state)
    ? itineraryGetters.isRoundTrip(state)
    : isNotEmpty(searchGetters.returnDepartureDate(state)) ||
        bookingGetters.isRoundTrip(state);
};

const tripType = (state) => {
  return isRoundTrip(state) ? TRIP_TYPE_RETURN : TRIP_TYPE_ONE_WAY;
};

const passengerType = (state) => {
  const getters = isPostBookingFlow(state) ? itineraryGetters : bookingGetters;
  const adultCount = getters.numberOfAdults(state) || 1;
  const childCount = getters.numberOfChildren(state);
  const infantCount = getters.numberOfInfants(state);

  let passengerType = adultCount > 1 ? 'Adults' : 'Adult';
  if (childCount > 0 && infantCount > 0) {
    passengerType += childCount > 1 ? ', children' : ', child';
    passengerType += infantCount > 1 ? ' and infants' : ' and infant';
  } else if (childCount > 0) {
    passengerType += childCount > 1 ? ' and children' : ' and child';
  } else if (infantCount > 0) {
    passengerType += infantCount > 1 ? ' and infants' : ' and infant';
  } else {
    passengerType += ' only';
  }

  return passengerType;
};

const flightByDirection = (state, direction = DIRECTION_OUTBOUND) => {
  if (isPostBookingFlow(state)) return itineraryGetters[`${direction}Flight`](state);

  const bookingFlight =
    successfulBookingGetters.booking(state)?.flights[direction] ||
    bookingGetters.flights(state)[direction];
  const departureStation =
    bookingFlight.departureStation ||
    (direction === DIRECTION_OUTBOUND
      ? searchGetters.departureStationIata(state)
      : searchGetters.arrivalStationIata(state));
  const arrivalStation =
    bookingFlight.arrivalStation ||
    (direction === DIRECTION_OUTBOUND
      ? searchGetters.arrivalStationIata(state)
      : searchGetters.departureStationIata(state));

  return {
    departureStation,
    arrivalStation,
    departureDate:
      bookingFlight.departureDateTime ||
      bookingFlight.departureDate ||
      searchGetters[`${direction}DepartureDate`](state),
    carrier: bookingFlight.carrierCode || bookingFlight.carrier,
    flightNumber: bookingFlight.flightNumber,
  };
};

const airportCodes = (state, direction = DIRECTION_OUTBOUND) => {
  const flight = flightByDirection(state, direction);

  if (isNil(flight)) return null;
  const { departureStation, arrivalStation } = flight;

  if (isEmpty(departureStation) || isEmpty(arrivalStation)) return null;
  return `${departureStation}-${arrivalStation}`;
};

const dateOfTravel = (state, direction = DIRECTION_OUTBOUND) => {
  const flight = flightByDirection(state, direction);

  if (isNil(flight)) return null;
  const { departureDate } = flight;

  if (isEmpty(departureDate)) return null;
  return isValidDate(departureDate)
    ? formatDate(ANALYTICS_DATE_FORMAT, departureDate)
    : null;
};

const flowType = (state) => {
  const flowTypeMapping = {
    [FLOW_TYPE_CHANGE_FLIGHT]: ANALYTICS_FLOW_TYPE_CHANGE_FLIGHT,
    [FLOW_TYPE_CHANGE_NAME]: ANALYTICS_FLOW_TYPE_CHANGE_NAME,
    [FLOW_TYPE_ADD_SERVICES]: ANALYTICS_FLOW_TYPE_ADD_SERVICES,
    [FLOW_TYPE_FARE_LOCK_FINALIZE]: ANALYTICS_FLOW_TYPE_FARE_LOCK_FINALIZE,
    [FLOW_TYPE_CHECK_IN]: ANALYTICS_FLOW_TYPE_CHECKIN,
    [ANALYTICS_FLOW_TYPE_CHECKIN]: ANALYTICS_FLOW_TYPE_CHECKIN,
    [FLOW_TYPE_CONNECTED_BOOKING]: ANALYTICS_FLOW_TYPE_CONNECTED_BOOKING,
  };

  return (
    flowTypeMapping[coreBookingGetters.flowType(state)] || ANALYTICS_FLOW_TYPE_NEW_BOOKING
  );
};

const flightNumber = (state, direction = DIRECTION_OUTBOUND) => {
  const flight = flightByDirection(state, direction);

  if (isNil(flight)) return null;
  const { carrier: carrierCode, flightNumber } = flight;

  if (isNil(carrierCode) || isNil(flightNumber)) return null;
  return `${carrierCode}${flightNumber}`;
};

const daysToGo = (state, direction = DIRECTION_OUTBOUND) => {
  const flight = flightByDirection(state, direction);

  if (isNil(flight)) return null;
  const { departureDate } = flight;

  return isValidDate(departureDate)
    ? differenceInDays(startOfDay(departureDate), startOfDay(currentDateAndTime()))
    : null;
};

const numberOfPassengers = (state) => {
  return isPostBookingFlow(state)
    ? itineraryGetters.numberOfPassengersWithoutInfants(state)
    : bookingGetters.numberOfIndependentPassengers(state);
};

// analytics purchase locations
const wizzFlexPurchaseLocation = (state) => state.analytics.wizzFlexPurchaseLocation;

export const wdcMembershipPurchaseLocation = (state) =>
  state.analytics.wdcMembershipPurchaseLocation;

export const priorityBoardingPurchaseLocation = (state) =>
  state.analytics.priorityBoardingPurchaseLocation;

const smsPurchaseLocation = (state) => state.analytics.smsPurchaseLocation;

const airportCheckInPurchaseLocation = (state) =>
  state.analytics.airportCheckInPurchaseLocation;

const insurancePurchaseLocation = (state) => state.analytics.insurancePurchaseLocation;

const autoCheckInPurchaseLocation = (state) =>
  state.analytics.autoCheckInPurchaseLocation;

export const outboundFarePurchaseLocation = (state) =>
  state.analytics.outboundFarePurchaseLocation;

export const returnFarePurchaseLocation = (state) =>
  state.analytics.returnFarePurchaseLocation;

const seatPurchaseLocation = (state) => state.analytics.seatPurchaseLocation;

const sittingTogetherPurchaseLocation = (state) =>
  state.analytics.sittingTogetherPurchaseLocation;

export const purchaseLocations = (state) => {
  return {
    wizzFlex: wizzFlexPurchaseLocation(state),
    priorityBoarding: priorityBoardingPurchaseLocation(state),
    smsNotification: smsPurchaseLocation(state),
    airportCheckIn: airportCheckInPurchaseLocation(state),
    wdcMembership: wdcMembershipPurchaseLocation(state),
    acordService: insurancePurchaseLocation(state),
    autoCheckIn: autoCheckInPurchaseLocation(state),
    outboundFare: outboundFarePurchaseLocation(state),
    returnFare: returnFarePurchaseLocation(state),
    seat: seatPurchaseLocation(state),
    seatingTogetherGuarantee: sittingTogetherPurchaseLocation(state),
  };
};

export const wdcDiscountSaving = (state) => state.analytics.hasSavingOnWdc;

const hasAnyPassengerWithPriorityBoarding = (state) => {
  return isPostBookingFlow(state)
    ? itineraryGetters.somePassengerHasPriorityBoardingOnEitherFlight(state)
    : ancillariesGetters.somePassengerHasPriorityBoardingOnEitherFlight(state);
};

const purchasedBaggagesOfAllPassengers = (state) => {
  return isPostBookingFlow(state)
    ? itineraryPurchasedBaggagesOfAllPassengers(state)
    : bookingPurchasedBaggagesOfAllPassengers(state);
};

const itineraryPurchasedBaggagesOfAllPassengers = (state) => {
  return [
    ...itineraryGetters.outboundPassengerAncillaries(state),
    ...itineraryGetters.returnPassengerAncillaries(state),
  ].reduce((baggageList, ancillary) => {
    if (CHECK_IN_BAG_TYPES.includes(ancillary.type)) {
      baggageList.push(ancillary);
    }
    return baggageList;
  }, []);
};

const bookingPurchasedBaggagesOfAllPassengers = (state) => {
  return [
    ...ancillariesGetters.outboundPassengerAncillaries(state),
    ...ancillariesGetters.returnPassengerAncillaries(state),
  ].reduce((baggageList, ancillary) => {
    if (isNotNil(ancillary) && ancillary.type === 'baggage' && ancillary.selected) {
      const selectedBaggage = ancillary.options.find(
        (option) => option.option === ancillary.selected
      );
      if (selectedBaggage) {
        baggageList.push({
          ...selectedBaggage.price,
          name: ancillary.selected,
        });
      }
    }
    return baggageList;
  }, []);
};

const highestBundle = (state) => {
  const isPostBooking = isPostBookingFlow(state);
  const outboundBundle = isPostBooking
    ? path('bundle.code', itineraryGetters.outboundFlight(state))
    : bookingGetters.outboundBundle(state);

  const returnBundle = isPostBooking
    ? path('bundle.code', itineraryGetters.returnFlight(state))
    : bookingGetters.returnBundle(state);

  const isOutboundFlown = !outboundBundle && returnBundle;
  if (isOutboundFlown) return returnBundle;
  return returnBundle
    ? isBundleHigher(outboundBundle, returnBundle)
      ? outboundBundle
      : returnBundle
    : outboundBundle;
};

const hasFamilyBundle = (state) => {
  const isPostBooking = isPostBookingFlow(state);
  const isOutboundFamilyBundle = isPostBooking
    ? path('bundle.isFamily', itineraryGetters.outboundFlight(state))
    : bookingGetters.isOutboundFamilyBundle(state);
  const isReturnFamilyBundle = isPostBooking
    ? path('bundle.isFamily', itineraryGetters.returnFlight(state))
    : bookingGetters.isReturnFamilyBundle(state);

  return isOutboundFamilyBundle || isReturnFamilyBundle;
};

export const floodlightVariables = (state) => {
  const outboundFlightInformation = flightByDirection(state, DIRECTION_OUTBOUND) || {};
  const returnFlightInformation = flightByDirection(state, DIRECTION_RETURN) || {};
  const hasPriorityBoarding = hasAnyPassengerWithPriorityBoarding(state);
  const baggageList = purchasedBaggagesOfAllPassengers(state);
  const rate = exchangeRate(state);
  const departureDate = outboundFlightInformation.departureDate
    ? formatDate(ANALYTICS_DATE_FORMAT, outboundFlightInformation.departureDate)
    : '';
  const returnDate = returnFlightInformation.departureDate
    ? formatDate(ANALYTICS_DATE_FORMAT, returnFlightInformation.departureDate)
    : '';
  const pagePath = getPagePathForAnalytics(location);
  let pageUrl = `${location.origin}${pagePath.charAt(0) !== '/' ? '/' : ''}${pagePath}`;
  pageUrl = removeQueryString(pageUrl);

  // todo currently there is no way to decide which page is active so we start with the itinerary
  //  and if it's empty check the successful booking, because always that's the first in order
  const pnr = itineraryGetters.pnr(state) || successfulBookingGetters.pnr(state) || '';
  const totalAmount =
    (itineraryGetters.totalPaidAmountAmount(state) ||
      successfulBookingGetters.totalPaidAmount(state)?.amount) * rate || '';

  return {
    pageUrl,
    pnr,
    totalAmount,
    currency: ANALYTICS_CURRENCY_CODE,
    departureStation: outboundFlightInformation.departureStation || '',
    arrivalStation: outboundFlightInformation.arrivalStation || '',
    departureDate,
    returnDate,
    selectedPackagePrice: highestBundle(state) || '',
    numberOfPassengers: numberOfPassengers(state) || '',
    isUserLoggedIn: userGetters.isLoggedIn(state) ? 'Yes' : 'No',
    hasPriorityBoarding: hasPriorityBoarding ? 'Yes' : 'No',
    hasWizzFamily: hasFamilyBundle(state) ? 'Yes' : 'No',
    cabinBaggageType: hasPriorityBoarding ? 'Wizz Priority' : 'Free carry-on bag',
    hasCheckInBaggage: baggageList.length > 0 ? 'Yes' : 'No',
    checkInBaggagePrice:
      baggageList.reduce((sum, { amount }) => sum + amount, 0) * rate || '',
  };
};

export const selectedPaymentOptionForAnalytics = (state) =>
  state.analytics.selectedPaymentOption;
