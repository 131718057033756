import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import * as gat from '~/store/action-types';
import { moduleName, getDefaultState } from './internal';
import * as actions from './actions';
import mutations from './mutations';

const state = () => alter(getSessionItemOr({}, moduleName), getDefaultState());

export default {
  state,
  mutations,
  actions: {
    [gat.RESTART_BOOKING_RESET]: actions.logout,
    [gat.REQUEST_START]: actions.updateLastSeenActive,
  },
};
