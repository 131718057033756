/**
 * Gives a single-word string description of the (native) type of a value,
 * returning such answers as 'Object', 'Number', 'Array', or 'Null'. Does not
 * attempt to distinguish user Object types any further, reporting them all as
 * 'Object'.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/type.js
 *
 * @type {(value: any) => string}
 * @example
 *
 *      type({}); //=> "Object"
 *      type(1); //=> "Number"
 *      type(false); //=> "Boolean"
 *      type('s'); //=> "String"
 *      type(null); //=> "Null"
 *      type([]); //=> "Array"
 *      type(/[A-z]/); //=> "RegExp"
 *      type(new Set()); //=> "Set"
 *      type(new Map()); //=> "Map"
 */
const type = (value) => {
  if (value === null) return 'Null';
  if (value === undefined) return 'Undefined';
  return Object.prototype.toString.call(value).slice(8, -1);
};

export default type;
