import { currentDateAndTime, isBefore } from '~/utils/date';

const isFlownFlight = (flight) => {
  if (!flight) return false;
  // NOTE: unfortunately this needs to handle normal booking flights and AS
  //  booking flights too :(
  const departureDate = flight.departureDateTime || flight.departureDate;
  if (!departureDate) return true;
  return isBefore(departureDate, currentDateAndTime());
};

export default isFlownFlight;
