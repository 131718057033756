import log from 'loglevel';
import axios from 'axios';
import isUndefined from '~/utils/object/is-undefined';
import { decapitalize } from '~/utils/string';
import {
  RETURN_FLIGHT_NAME,
  OUTBOUND_FLIGHT_NAME,
  DIRECTION_RETURN,
  DIRECTION_OUTBOUND,
} from '~/constants';
import * as u from '~/constants/api-endpoint';

export const getQrScanning = () => axios.get(u.CHECK_IN_QR_SCANNING);

export const getWalletLinkGoogle = (payload) => {
  return axios.get(`/${u.CHECK_IN_WALLET_GOOGLE}${payload}`);
};

export const uploadTravelDocumentsScan = (passengerNumber, formData) => {
  if (!(formData instanceof FormData)) {
    throw new Error('Invalid payload format.');
  }
  return axios.post(
    `${u.CHECK_IN_TRAVEL_DOCUMENTS_SCANNING}?passengerNumber=${passengerNumber}`,
    formData
  );
};

export const postPassengerLocator = (passengerNumber, passengerLocatorString) =>
  axios.post(u.CHECK_IN_PASSENGER_LOCATOR, {
    PassengerNumber: passengerNumber,
    PassengerLocatorString: passengerLocatorString,
  });

export const postEuGreenPass = (passengerNumber, euGreenPassString) =>
  axios.post(u.CHECK_IN_EU_GREEN_PASS, {
    PassengerNumber: passengerNumber,
    EuGreenPassString: euGreenPassString,
  });

export const getContactDataVerification = ({ guid, version = 'A' }) =>
  axios.get(u.CHECK_IN_CONTACT_DATA_VERIFICATION, { params: { guid, version } });

export const contactDataVerification = (data) =>
  axios.post(u.CHECK_IN_CONTACT_DATA_VERIFICATION, data);

export function getBoardingCard(url, options) {
  return axios.get(`/${u.CHECKIN_BOARDING_CARD}${url}`, options);
}

export function getCheckIn(data) {
  return axios.get(u.CHECKIN_CHECKIN, { params: data });
}

export function checkInPost(data) {
  return axios.post(u.CHECK_IN_START, data);
}

export function getWarningStep() {
  return axios.get(u.CHECK_IN_WARNING_STEP);
}

export function getPassengersStep() {
  return axios.get(u.CHECK_IN_PASSENGERS_STEP);
}

export function postPassengersStep(data) {
  return axios.post(u.CHECK_IN_POST_PASSENGERS, data);
}

export function getContactDataStep(isAVersion) {
  return axios.get(u.CHECK_IN_CONTACT_DATA_STEP, {
    params: { version: isAVersion ? 'A' : 'B' },
  });
}

export function fetchTravelDocumentsStep() {
  return axios.get(u.CHECK_IN_TRAVEL_DOCUMENTS_STEP);
}

export function fetchAllDoneStep() {
  return axios.get(u.CHECK_IN_ALL_DONE);
}

export function postTravelDocumentsStep(data) {
  return axios.post(u.CHECK_IN_TRAVEL_DOCUMENTS_STEP, { passengers: data });
}

export function postContactDataStep(data) {
  return axios.post(u.CHECK_IN_CONTACT_DATA_STEP, data);
}

export function contactDataValidation(data) {
  return axios.post(u.CHECK_IN_CONTACT_DATA_VALIDATION, data);
}

export function checkIn(data) {
  return axios.post(u.CHECKIN_CHECKIN, data);
}

export function getChangesToAcceptStep() {
  return axios.get(u.CHECK_IN_CHANGES_TO_ACCEPT);
}

export function confirmChangesToAccept(accept) {
  return axios.post(u.CHECK_IN_CHANGES_TO_ACCEPT, { accept });
}

export function postChangesToAcceptStep(data) {
  return axios.post(u.CHECK_IN_CHANGES_TO_ACCEPT, data);
}

export function getCheckInParams() {
  return axios.get(u.CHECK_IN_PARAMS);
}

export function getTravelDocument(data) {
  return axios.get(u.CHECKIN_TRAVEL_DOCUMENT, { params: data }).then((response) => {
    const { isReturnFlight } = data;
    const type = isReturnFlight ? DIRECTION_RETURN : DIRECTION_OUTBOUND;
    const checkInPassengers = response.data.checkinPassengers;
    checkInPassengers.forEach((checkInPassenger) => {
      copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type);
      copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger);
    });
    return response;
  });
}

export function postTravelDocument(data) {
  return axios.post(u.CHECKIN_TRAVEL_DOCUMENT, data);
}

export function validateTravelDocument(data) {
  return axios.post(u.CHECKIN_TRAVEL_DOCUMENT_VALIDATION, data);
}

export function confirmCheckIn(data) {
  return axios.post(u.CHECK_IN_CONFIRM_CHECK_IN, data);
}

export function validateCheckInPassengers(passengers) {
  return axios.post(u.CHECKIN_PASSENGER_VALIDATION, { passengers });
}

function copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type) {
  const removePrefix = (s) => decapitalize(s.replace(type, '')).replace('pDF', 'pdf');

  [
    // these prop names are a bit redundant now
    // in the future backend should rename these
    // `${type}OnlineCheckinEnabled`, // -> onlineCheckinEnabled <-- DEPRECATED in favour of the reasons array
    `${type}HtmlBoardingCardEnabled`, // -> htmlBoardingCardEnabled
    `${type}PDFBoardingCardEnabled`, // -> pdfBoardingCardEnabled
    'isTravelDocumentRequired',
    'visaOrResidencePermitRequiredNationalities',
    'biometricPassportCanReplaceVisaOrResidencePermitNationalities',
    'passport',
    'nationalId',
  ].forEach((prop) => {
    const newPropName = removePrefix(prop);
    const value = response.data[prop];
    if (!isUndefined(value)) {
      checkInPassenger[newPropName] = value;
    } else {
      log.warn(`Prop "${prop}" copy not needed in getSingleCheckIn anymore.`);
    }
    if (checkInPassenger?.infant?.value) {
      checkInPassenger.infant.value[newPropName] = value;
    }
  });
}

function copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger) {
  // new backend response puts the outbound/return no checkin reasons into the passenger
  // object but we still split the passenger per direction, so we need to modify stuff a bit.
  const reasonKey = `${type}NoCheckinReasons`;
  if (checkInPassenger[reasonKey]) {
    checkInPassenger.noCheckInReasons = checkInPassenger[reasonKey];
    delete checkInPassenger.outboundNoCheckinReasons;
    delete checkInPassenger.returnNoCheckinReasons;
  }

  const infant = checkInPassenger.infant;
  if (infant.accessibility === 'required') {
    infant.value.noCheckInReasons = checkInPassenger.noCheckInReasons;
    delete infant.value.outboundNoCheckinReasons;
    delete infant.value.returnNoCheckinReasons;
  }
}

function copyStartDatePropsToCheckInPassenger(checkInDate, checkInPassenger) {
  checkInPassenger.checkInStartDate = checkInDate;
  // TODO: uncomment when old itinerary gets burned up or leave it as is
  // delete checkInPassenger.checkinStartDateOutboundFlight;
  // delete checkInPassenger.checkinStartDateReturnFlight;

  const infant = checkInPassenger.infant;
  if (infant.accessibility === 'required') {
    infant.value.checkInStartDate = checkInDate;
    // TODO: uncomment when old itinerary gets burned up or leave it as is
    // delete infant.value.checkinStartDateOutboundFlight;
    // delete infant.value.checkinStartDateReturnFlight;
  }
}

/**
 * merge data outside checkin user into the checkin user itself
 * (and into his or her infant too)
 * data.visaOrResidencePermitRequiredNationalities
 * --> data.checkinPassengers[0].visaOrResidencePermitRequiredNationalities
 * --> data.checkinPassengers[0].infant.value.visaOrResidencePermitRequiredNationalities
 *
 * @param passengerNumber
 * @param isReturnFlight
 * @returns checkInPassenger
 */
export function getSingleCheckIn(passengerNumber, isReturnFlight = false) {
  const type = isReturnFlight ? DIRECTION_RETURN : DIRECTION_OUTBOUND;
  return getCheckIn({
    passengerNumber,
    isReturnFlight,
  }).then((response) => {
    const checkInPassenger = response.data.checkinPassengers[0];
    copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type);
    copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger);

    return {
      checkInPassenger,
      checkInDocumentRulesByCountries: response.data.travelDocumentRules,
      checkinSteps: response.data.checkinSteps,
      showWarningInformation: response.data.showWarningInformation,
      warningInformationLabels: response.data.warningInformationLabels,
    };
  });
}

export function getMultipleCheckIn(isReturnFlight) {
  const type = isReturnFlight ? 'return' : 'outbound';
  return getCheckIn({
    passengerNumber: null,
    isReturnFlight,
  }).then((response) => {
    const checkInPassengers = response.data.checkinPassengers;
    checkInPassengers.forEach((checkInPassenger) => {
      copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type);
      copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger);
    });

    return {
      checkInPassengers,
      checkInDocumentRulesByCountries: response.data.travelDocumentRules,
      checkinSteps: response.data.checkinSteps,
      showWarningInformation: response.data.showWarningInformation,
      warningInformationLabels: response.data.warningInformationLabels,
    };
  });
}

export function getTravelDocuments(isReturnFlight) {
  const type = isReturnFlight ? 'return' : 'outbound';
  return getTravelDocument({
    passengerNumber: null,
    isReturnFlight,
  }).then((response) => {
    const {
      checkinPassengers,
      passengersWithAddedTravelDocument,
      passengersWithNotAddedTravelDocument,
      showWarningInformation,
      warningInformationLabels,
    } = response.data;
    checkinPassengers.forEach((checkInPassenger) => {
      copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type);
      copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger);
    });
    return {
      checkinPassengers,
      passengersWithAddedTravelDocument,
      passengersWithNotAddedTravelDocument,
      showWarningInformation,
      warningInformationLabels,
    };
  });
}

export function getMultipleCheckInV2(isReturnFlight) {
  const type = isReturnFlight ? RETURN_FLIGHT_NAME : OUTBOUND_FLIGHT_NAME;
  return getCheckIn({
    passengerNumber: null,
    isReturnFlight,
  }).then((response) => {
    const checkInPassengers = response.data.checkinPassengers;
    const checkInDate = response.data.checkinStartDate; // 30 days before SDT

    checkInPassengers.forEach((checkInPassenger) => {
      copyCheckInPropsToCheckInPassenger(response, checkInPassenger, type);
      copyCheckInReasonsPropToCheckInPassenger(type, checkInPassenger);
      copyStartDatePropsToCheckInPassenger(checkInDate, checkInPassenger);
    });

    return {
      passengers: checkInPassengers,
      checkInDocumentRulesByCountries: response.data.travelDocumentRules,
      checkinSteps: response.data.checkinSteps,
      showWarningInformation: response.data.showWarningInformation,
      warningInformationLabels: response.data.warningInformationLabels,
      [`${type}FreeCheckinStartDate`]: response.data.freeCheckinStartDate,
    };
  });
}

export const removePassengersFromCheckin = (passengerNumbers) => {
  return axios({
    url: u.CHECK_IN_DELETE_PASSENGERS,
    method: 'delete',
    data: { passengerNumbers },
  });
};
