import { _equals } from '~/utils/fp/equals';

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/internal/_indexOf.js
 */
const _indexOf = (a, index, list) => {
  let item;
  switch (typeof a) {
    case 'number':
      // need to distinguish between -0, 0. plus need to handle NaN
      if (a === 0 || Number.isNaN(a)) {
        const numToFind = a === 0 ? (Object.is(-0, a) ? -0 : 0) : Number.NaN;
        while (index < list.length) {
          item = list[index];
          if (Object.is(numToFind, item)) return index;
          index += 1;
        }
        return -1;
      }

      // non-zero numbers can utilise Set
      return list.indexOf(a, index);

    // all these types can utilise Set
    case 'string':
    case 'boolean':
    case 'function':
    case 'undefined':
      return list.indexOf(a, index);

    case 'object':
      if (a === null) {
        // null can utilise Set
        return list.indexOf(a, index);
      }

    // no default
  }

  // anything else not covered above, defer to R.equals
  while (index < list.length) {
    if (_equals(list[index], a)) return index;
    index += 1;
  }

  return -1;
};

export default _indexOf;
