import prop from '~/utils/fp/prop';
import equals from '~/utils/fp/equals';
import compose from '~/utils/fp/compose';
import length from '~/utils/fp/length';
import head from '~/utils/fp/head';
import gt from '~/utils/fp/gt';
import __ from '~/utils/fp/__';
import isNotNumber from '~/utils/object/is-not-number';
import {
  rawPassengersFromPassengers,
  checkInStatusesFrom,
} from '~/utils/booking/seat-selection/convert';
import isNotEmpty from '~/utils/object/is-not-empty';
import {
  RETURN_FLIGHT_NAME,
  OUTBOUND_FLIGHT_NAME,
  NEW_SEAT_STATUS_STAND_BY,
} from '~/constants';
import propEq from '~/utils/fp/prop-eq';
import { getPlaceHolderName, getGender } from '~/utils/booking/passenger';

const getCollisions = (passengerNumber, affectedPassengers) =>
  [OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME].filter((direction) =>
    affectedPassengers[`${direction}SeatSelectionNeededPassengerList`].includes(
      passengerNumber
    )
  );

const getPassengersWithCollidingSeats = (passengers, affectedPassengers) =>
  passengers.filter((passenger) =>
    isNotEmpty(getCollisions(passenger.passengerNumber, affectedPassengers))
  );

const noneOfThePassengersNeedReturnSeat = compose(
  gt(__, 0),
  length,
  prop('returnSeatSelectionNeededPassengerList')
);

const noneOfThePassengersNeedOutboundSeat = compose(
  equals(0),
  length,
  prop('outboundSeatSelectionNeededPassengerList')
);

export const getSeatMapPayload = (payload) => {
  const {
    affectedPassengers,
    flights,
    passengers,
    currencyCode,
    isAlignedToTop,
    checkInPassengers,
  } = payload || {};

  const isReturnFlightAffectedOnly =
    noneOfThePassengersNeedReturnSeat(affectedPassengers) &&
    noneOfThePassengersNeedOutboundSeat(affectedPassengers);
  const isOutboundFlightAffectedOnly =
    !noneOfThePassengersNeedReturnSeat(affectedPassengers) &&
    !noneOfThePassengersNeedOutboundSeat(affectedPassengers);
  const passengersWithCollidingSeats = getPassengersWithCollidingSeats(
    passengers,
    affectedPassengers
  );
  const firstPassengerId = isReturnFlightAffectedOnly
    ? head(affectedPassengers.returnSeatSelectionNeededPassengerList)
    : head(affectedPassengers.outboundSeatSelectionNeededPassengerList);

  if (isNotNumber(firstPassengerId)) return null;

  return {
    outboundFlight: isReturnFlightAffectedOnly ? flights.return : flights.outbound,
    returnFlight: !isOutboundFlightAffectedOnly ? flights.return : null,
    checkInStatuses: checkInStatusesFrom(checkInPassengers),
    passengers: rawPassengersFromPassengers(passengersWithCollidingSeats),
    currencyCode,
    selectedPassengerId: firstPassengerId,
    isAlignedToTop,
    rebook: true,
  };
};

export const convertChangedPassenger = (passengers) => (changedPassenger) => {
  const passenger =
    passengers.find(propEq('passengerNumber', changedPassenger.passengerNumber)) || {};

  return {
    originalSeatCode: changedPassenger.originalUnitDesignator || NEW_SEAT_STATUS_STAND_BY,
    // @notes:the business request was that if the there aren't
    // a new seat then we should display 'STBY' without any translation
    newSeatCode: changedPassenger.newUnitDesignator || NEW_SEAT_STATUS_STAND_BY,
    name: getPlaceHolderName(passenger),
    gender: getGender(passenger),
  };
};
