import { BUNDLE_BASIC } from '~/constants';
import propEq from '../fp/prop-eq';
import isNotNil from '../object/is-not-nil';

export const capInfantFeeAmount = (fare, flight, infantFeeAmount) => {
  if ((fare?.bundle || '').toLowerCase() === BUNDLE_BASIC) {
    return fare.discountedPrice.amount < infantFeeAmount
      ? fare.discountedPrice.amount
      : infantFeeAmount;
  }

  const basicFare = flight.fares.find(
    (flightFare) =>
      flightFare.fareSellKey === fare.fareSellKey &&
      flightFare.bundle.toLowerCase() === BUNDLE_BASIC &&
      flightFare.wdc === fare.wdc
  );

  if (basicFare && basicFare?.discountedPrice?.amount < infantFeeAmount) {
    return basicFare?.discountedPrice?.amount;
  }

  return infantFeeAmount;
};

export const isNewConnection = (toStation, fromStationIata) => {
  if (!toStation) return false;
  const connection = toStation.connections.find(propEq('iata', fromStationIata)) || {};
  return Boolean(connection.isNew);
};

export const isAncillaryPromoApplied = (fare, type = null) => {
  // type must be 'regular' or 'wdc'
  // strict check first
  if (type) return Boolean(fare[`${type}PriceDetails`]?.promotion);
  return (
    isNotNil(fare.promotion) ||
    isNotNil(fare.regularPriceDetails?.promotion ?? null) ||
    isNotNil(fare.wdcPriceDetails?.promotion ?? null)
  );
};
