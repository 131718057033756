import Vue from 'vue';
import log from 'loglevel';
import VueI18n from 'vue-i18n';
import { FALLBACK_LOCALE_CODE } from '~/constants';
import isNotNumber from '~/utils/object/is-not-number';
import { LabelFormatter } from '~/utils/localization/label-formatter';
import {
  getLocale,
  isTranslationEnabled,
  mergeLocalizedLabels,
} from '~/utils/localization';
import { isSSRBuild } from '~/utils/ssr/build-type';
import { getInstance, setInstance } from './internal';

export { getInstance as getI18n };

export const init = () => {
  Vue.use(VueI18n);
  setInstance(
    new VueI18n({
      locale: getLocale(),
      fallbackLocale: FALLBACK_LOCALE_CODE,
      messages: { [getLocale()]: getLabels() },
      silentTranslationWarn: true,
      missing: (locale, key) => {
        if ($ENV === 'development') {
          log.warn(`Missing ${locale} label, id: ${key}`);
        }
      },
      formatter: new LabelFormatter(),
    })
  );
  return getInstance();
};

export const update = () => getInstance().setLocaleMessage(getLocale(), getLabels(true));

/**
 * @param {String} labelKey     Base key of label
 * @param {Number} number       Number of items
 * @returns {String}
 */
export const pluralize = (labelKey, number) => {
  const i18n = getInstance();
  if (isNotNumber(number) || number < 0) return labelKey;
  if (number === 0) return i18n.t(`${labelKey}-zero`, [number]);
  if (number > 0 && number <= 1) return i18n.t(`${labelKey}-one`, [number]);
  if (number > 1 && number < 5) return i18n.t(`${labelKey}-few`, [number]);
  return i18n.t(`${labelKey}-many`, [number]);
};

/**
 * Check if the key has a proper localization or not. Used
 * for hiding complete blocks when a localization is not present.
 *
 * @type {(key: string) => boolean}
 */
export const isLocalizationAvailable = (key) => getInstance().te(key) && !isEmptyKey(key);

const isEmptyKey = (key) => {
  const i18n = getInstance();
  return i18n.t(key) === '&nbsp;' || i18n.t(key).trim() === '';
};

const getLabels = (noCache = false) => {
  if (!isSSRBuild) {
    return isTranslationEnabled() && $ENV !== 'test'
      ? mergeLocalizedLabels(window.language, noCache)
      : {};
  }

  const store = require('~/store').getStore();
  const { getLanguage } = require('~/utils/global-value/language');

  return isTranslationEnabled() && $ENV !== 'test'
    ? mergeLocalizedLabels(getLanguage(store.state), noCache)
    : {};
};
