import { STEP_NOTHING } from '~/constants';

export const getDefaultState = () => ({
  carrierCodes: [],
  isDomestic: false,
  isWdcPremiumEnabled: false,
  outboundGroupSeatRequest: false, // no "is" prefix pls
  returnGroupSeatRequest: false,
  bundleUpsellOriginalCommonBundle: [],

  isPremiumSeatRefundLoading: false,
  premiumSeatRefund: {},
  premiumSeatRefundErrors: [],

  flights: {
    outbound: getFlightModel(),
    return: getFlightModel(),
  },

  fares: {
    outbound: getFareModel(),
    return: getFareModel(),
  },

  isAirportTransferModalVisible: false,
  isRestartBookingModalVisible: false,
  isAirportTransferModalDirection: null, // 'arrival' or 'departure'
  servicesRequestedLanguage: '',
  acordService: getAcordServiceModel(),
  hasSelectedCar: false,
  contact: getContactModel(),
  wdc: getWdcModel(),
  payment: getPaymentModel(),
  isSpecialCabinBaggageAppliedForReturn: false,
  isSpecialCabinBaggageAppliedForOutbound: false,
  isUrgencyModalVisible: false,
  isUrgencyModalShowed: false,
  isSingleServiceModalVisible: false,
  isWizzFlexModalVisible: false,
  isWdcSavingModalVisible: false,
  isAcordServiceInfoModalTracked: {
    itinerary: false,
    payment: false,
    services: false,
  },

  fareLockFinalizeData: {
    outboundFlight: null,
    returnFlight: null,
  }, // full payload so far

  changeFlightDisclaimerType: 'no-flex', // we have three disclaimer types calculated by the flight change vm
  changeSeatFlow: false, // essentially a sub-flow
  flightChangedFrom: null, // arbitrary data from itinerary

  preferences: {
    newsletterSubscription: null,
  },

  // for tracking
  // possible locations:
  // 1. add-services
  // 2. contact-payment
  // 3. wdc-auto
  // 4. (registration-modal) - tracked as "registration" all the time
  // 5. (newsletter-page) - not on booking, not relevant
  newsletterSubscriptionLocation: null,
  isNewBooking: false, // Used for Google Analytics tracking
  numberOfIndependentPassengers: 0,
  numberOfInfants: 0,
  adultCount: 0,
  childCount: 0,
  infantCount: 0,
  // current step in the booking flow, goes one way (incremental) only
  // so scrolling back (to the covered sections) will not change it
  step: STEP_NOTHING,
  isWdcStepNecessary: false,
  passengersWithDisabledSameCheckbox: [],
  isLoggedInAfterSelect: false,
  isCancellationInsuranceSelected: false,
  isTravelInsuranceSelected: false,
  isBaggageInsuranceSelected: false,
  isWdcStepAvailable: false,

  isPaymentExitIntentModalSeen: false,
  isFlightSearchInSidebarVisible: false,
  isFlightSearchModalVisible: false,
  isCurrencyChangeWarningDismissed: false,
  isOtaFeeWarningDismissed: false,

  volatile: getVolatileModel(),
});

const getFlightModel = () => ({
  carrierCode: null,
  flightNumber: null,
  flightSellKey: null, // not implemented on itinerary!
  flightCurrencyCode: 'HUF',
  departureDateTime: null, // departureDate on itinerary!
  arrivalDateTime: null, // arrivalDate on itinerary!
  planeType: null,

  wheelchairLimitExceeded: {
    limitExceeded: null,
    unavailableCount: null,
  },

  departureStation: '', // iata only
  arrivalStation: '', // iata only
});

const getFareModel = () => ({
  bundle: null,
  fareSellKey: null,
  fareLockPrice: null,
  price: 0,

  fees: {
    infant: null,
    administration: null,
  },

  basePrice: {
    currencyCode: null,
  },
});

export const getAcordServiceModel = () => ({
  isInsurancePolicyAccepted: false,
  isInsuranceNoneSelected: false,
  isInsuranceForced: false,
});

// will be deep reassigned with data from BookingService.getContact (!!)
export const getContactModel = () => ({
  title: null,
  gender: null,
  firstName: null,
  lastName: null,
  address: null,
  city: null,
  zip: null,
  country: null,
  mobilePhone: null,
  homePhone: null,
  email: null,
  workPhone: null,
  companyName: null,
  taxNumber: null,

  saveAsDefault: {
    accessibility: true,
    value: true,
  },

  isBillingValid: false,
  isPrivate: false,
  isRegisteredForNewsletter: false,
  isCaptchaRequired: false,
  captchaResponse: null,
  availableBillingOptions: [],
});

export const getWdcModel = () => ({
  requiredMembership: null,
  isAdded: false,
  availableMemberships: [],
  shouldSendWdcRenewalRequest: false,
  isWdcSavingPanelVisible: false,
  isWdcSavingSet: false,
  isUpgradedFromSidebar: false,
});

export const getPaymentModel = () => ({
  selected: null, // please don't predefine because that cause some issue (like 8309)
  options: {},
  residualAmount: 0,
  currency: '',
  previousCurrency: '',
  cvv: '',
  isGooglePayReady: false,
  isGooglePayLoading: false,
  isCardInGooglePayAvailable: false,

  corporateCardFeeInfo: {
    isCorporateCardFeeAccepted: false,
    corporateCardFeePercent: null,
    originalAmount: null,
    corporateCardFeeAmount: null,
    newTotalAmount: null,
  },

  creditCard: {
    cardNumber: '',
    cardHolderName: '',
    expirationMonth: '',
    expirationYear: '',
    cvv: '',
  },

  storedCreditCard: {
    id: null,
    cardNumber: '',
    expiration: '',
    cvv: '',
  },

  bankTransferAccount: {},
  voucher: {
    code: null,
  },

  wizzAccount: {
    isRedeemed: false, // wizzaccount has no redeemed at all, jfyi
    amount: 0,
    partialAmount: 0, // from unformatted user input
  },

  groupPartialPayment: {
    selectedPayment: '',
    amount: 0,
    currencyCode: '',
    enabled: false,
    valid: true,
  },

  selectedIdealIssuer: {},
  isPaid: false,
  isDestinationBasedNotificationAccepted: null,
});

const getFeesModel = () => ({
  infant: null,
  distribution: null, // agency related fee
  scraper: null,
});

const getVolatileModel = () => ({
  // note: these are won't be synced to session storage on change
  isPassengerStepPending: false,
  isSelectedFlightPending: false,
  isPaymentStepPending: false,
});

export const resetFlight = (direction, state) => {
  state.flights[direction] = getFlightModel();
  state.flights[direction].departureStation = '';
  state.flights[direction].arrivalStation = '';

  state.fares[direction] = getFareModel();
  state.fares[direction].fees = getFeesModel();
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};
