import { DIRECTION_OUTBOUND, DIRECTION_RETURN } from '~/constants';
import both from '~/utils/fp/both';
import propEq from '~/utils/fp/prop-eq';
import curry from '~/utils/fp/curry';
import isNotEmpty from '~/utils/object/is-not-empty';
import * as bookingMutationTypes from '~/store/modules/booking/mutation-types';
import * as coreBookingMutationTypes from '~/store/modules/core-booking/mutation-types';
import * as searchActions from '~/store/modules/search/actions';
import { flightSelectProp } from '../internal';

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const onOpenFlight = ({ state, commit }, payload = {}) => {
  const { direction, flightId } = payload;
  const flight =
    flightSelectProp(`sync.${direction}Flights`, state).find(
      propEq('flightSellKey', flightId)
    ) || null;
  commit(bookingMutationTypes.SET_FLIGHT, { direction, flight });
};

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const syncOnSubmit = (store, payload = {}) => {
  const { commit } = store;
  const {
    isLoggedIn,
    isWdcFareTypeSelected,
    isFlightChangeOrRebookFlow,
    isOutboundFareSelected,
    isReturnFareSelected,
    isRoundTrip,
    flightChangedFrom,
  } = payload;
  searchActions.setWdcFlag(store, isWdcFareTypeSelected);
  commit(bookingMutationTypes.SET_IS_LOGGED_IN_AFTER_SELECT_FLAG, !isLoggedIn);

  if (
    isFlightChangeOrRebookFlow &&
    isRoundTrip &&
    (!isOutboundFareSelected || !isReturnFareSelected)
  ) {
    const direction = isOutboundFareSelected ? DIRECTION_RETURN : DIRECTION_OUTBOUND;
    const fareSellKey = flightChangedFrom[`${direction}FlightFareSellKey`];

    // notes the flightChangedFrom object doesn't really contains any useful info
    //  so we have to hack the carrier code to trigger GCC accept on payment if it differs
    const carrierCode = flightChangedFrom[`${direction}FlightFlightSellKey`].slice(0, 2);
    const bundle = flightChangedFrom[`${direction}Bundle`];
    const stations = [
      flightChangedFrom.arrivalStation,
      flightChangedFrom.departureStation,
    ];
    if (direction === DIRECTION_RETURN) stations.reverse();

    commit(bookingMutationTypes.SET_FARES, {
      direction,
      fare: {
        bundle,
        fareSellKey,
      },
    });
    commit(bookingMutationTypes.SET_FLIGHT, {
      direction,
      flight: {
        arrivalStation: stations[0],
        departureStation: stations[1],
        arrivalDateTime: null,
        carrierCode,
      },
    });
  }
};

/**
 * @type {(store: Store, membership: string) => void}
 */
export const onAddWdcMembership = (store, membership) => {
  commitUpdateWdc(store, membership, true);
};

/**
 * @type {(store: Store) => void}
 */
export const onRemoveWdcMembership = (store) => {
  commitUpdateWdc(store, null, false);
};

/**
 * @type {(store: Store, membership: string, isAdded: boolean) => void}
 */
const commitUpdateWdc = ({ commit }, requiredMembership, isAdded) => {
  commit(bookingMutationTypes.UPDATE_WDC, { requiredMembership, isAdded });
};

/**
 * @type {(store: Store, response: Object<string, any>) => void}
 */

export const syncOnFetchWdcMemberships = (store, response) => {
  searchActions.initWdc(store, response);
};

/**
 * @type {(store: Store, isWdcFareTypeSelected: boolean) => void}
 */
export const syncFareTypeSelection = (store, isWdcFareTypeSelected) => {
  const { commit, state } = store;
  searchActions.setWdcFlag(store, isWdcFareTypeSelected);
  commit(
    bookingMutationTypes.SET_NEWSLETTER_SUBSCRIPTION_LOCATION,
    isWdcFareTypeSelected ? 'wdc-auto' : null
  );
  commit(
    bookingMutationTypes.SET_NEWSLETTER_SUBSCRIPTION,
    flightSelectProp('isEmailSubscriptionChecked', state)
  );
};

/**
 * @type {(store: Store, payload: { isWdcFareTypeSelected: boolean }) => void}
 */
export const syncOnToggleFareType = (store, isWdcFareTypeSelected) => {
  syncFareTypeSelection(store, isWdcFareTypeSelected);
  resetAllFlightAndFares(store);
};

/**
 * @type {(store: Store) => void}
 */
const resetAllFlightAndFares = (store) =>
  [DIRECTION_OUTBOUND, DIRECTION_RETURN].forEach(resetFlightAndFare(store));

/**
 * @type {(store: Store, direction: Direction) => void}
 */
export const resetFlightAndFare = curry((store, direction) => {
  const { commit } = store;

  if (direction === DIRECTION_OUTBOUND) {
    commit(bookingMutationTypes.RESET_OUTBOUND_FLIGHT);
  } else if (direction === DIRECTION_RETURN) {
    commit(bookingMutationTypes.RESET_RETURN_FLIGHT);
  }
});

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const syncFlightAndFare = ({ commit, state }, payload = {}) => {
  const {
    direction = '',
    isGroupSeatRequest = false,
    selectedFlightId = null,
    selectedFareId = null,
    fareId = '',
    isFlightChangeOrRebookFlow = false,
  } = payload;

  // todo look a more elegant way to determine what is the current bundle
  // since, the fare-sell-keys are not unique (on bundle level) we have to
  // get an other field to make sure we selecting the right fare
  const bundle = fareId.split('-')[1];
  const flight =
    flightSelectProp(`sync.${direction}Flights`, state).find(
      propEq('flightSellKey', selectedFlightId)
    ) || null;
  const fares = flight ? flight.fares : [];
  let fare = isGroupSeatRequest
    ? getMockedFare()
    : fares.find(both(propEq('fareSellKey', selectedFareId), propEq('bundle', bundle))) ||
      null;

  if (isFlightChangeOrRebookFlow && !fare) {
    fare = flight.fares[0];
  }

  commit(bookingMutationTypes.SET_FLIGHT, { direction, flight });
  commit(bookingMutationTypes.SET_FARES, { direction, fare });
  commit(bookingMutationTypes.SET_GROUP_SEAT_REQUEST, {
    direction,
    value: isGroupSeatRequest,
  });
};

const getMockedFare = () => ({
  isGroupSeatRequest: true,
  fareSellKey: '',
  bundle: 'BASIC',
  fareDiscountType: 'none',
  wdc: false,
  availableCount: 140, // ?
  basePrice: { amount: 0 },
  discountedPrice: { amount: 0 },
  administrationFeePrice: { amount: 0 },
});

/**
 * @type {(store: Store, direction: Direction, value: string ) => void}
 */
export const syncFlightDate = (store, direction, value) => {
  if (direction === DIRECTION_OUTBOUND) {
    searchActions.setDepartureDate(store, value);
  } else if (direction === DIRECTION_RETURN) {
    searchActions.setReturnDate(store, value);
  }
};

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const syncOnFlightSearch = (store, payload = {}) => {
  const {
    outboundFlights,
    outboundBundles,
    returnFlights,
    returnBundles,
    departureStationCurrencyCode,
    arrivalStationCurrencyCode,
    isBundleCompositionWarningTextVisible,
    isDomestic,
    isWdcPremiumEnabled,
    wdcRenewalPrice,
    directionIsOutbound,
  } = payload;

  store.commit(coreBookingMutationTypes.RESET_EXTENDED_ANCILLARY);
  searchActions.setIsBundleCompositionWarningTextVisible(
    store,
    isBundleCompositionWarningTextVisible
  );
  searchActions.setIsDomestic(store, isDomestic);
  searchActions.setBookingCurrencyRenewalPrice(store, wdcRenewalPrice);
  searchActions.setFlights(store, {
    direction: DIRECTION_OUTBOUND,
    flights: outboundFlights,
  });
  searchActions.setBundles(store, {
    direction: DIRECTION_OUTBOUND,
    bundles: outboundBundles,
  });
  searchActions.setFlights(store, {
    direction: DIRECTION_RETURN,
    flights: returnFlights,
  });
  searchActions.setBundles(store, {
    direction: DIRECTION_RETURN,
    bundles: returnBundles,
  });

  const hasExtendedAncillary = outboundBundles.some((bundle) =>
    isNotEmpty(bundle.otherLabelKeys)
  );
  if (hasExtendedAncillary) store.commit(coreBookingMutationTypes.SET_EXTENDED_ANCILLARY);

  store.commit(bookingMutationTypes.SET_IS_DOMESTIC, isDomestic);
  store.commit(bookingMutationTypes.SET_IS_WDC_PREMIUM_ENABLED, isWdcPremiumEnabled);
  store.commit(coreBookingMutationTypes.SET_CURRENCY_CODES, {
    bookingCurrencyCode: departureStationCurrencyCode,
    departureCurrencyCode: departureStationCurrencyCode,
    arrivalCurrencyCode: arrivalStationCurrencyCode,
  });

  // in case of separate return leg search we have already set the payment currency and
  // user may have already set it to a different one manually so we don't want to overwrite that choice
  if (!directionIsOutbound) return;

  store.commit(
    coreBookingMutationTypes.SET_PAYMENT_CURRENCY,
    departureStationCurrencyCode
  );
};

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const syncOnInit = (store, payload = {}) => {
  const {
    departureDate,
    returnDate,
    numberOfIndependentPassengers,
    numberOfAdultPassengers,
    numberOfChildPassengers,
    numberOfInfantPassengers,
  } = payload;

  // note: this is necessary for flight change for example
  store.commit(bookingMutationTypes.RESET_OUTBOUND_FLIGHT);
  store.commit(bookingMutationTypes.RESET_RETURN_FLIGHT);

  searchActions.setDepartureDate(store, departureDate);
  searchActions.setReturnDate(store, returnDate);
  store.commit(bookingMutationTypes.SET_PASSENGER_NUMBERS_AND_COUNTS, {
    numberOfIndependentPassengers,
    numberOfAdultPassengers,
    numberOfChildPassengers,
    numberOfInfantPassengers,
  });
};

export const syncAdditionalFees = (store, response) => {
  searchActions.setFees(store, {
    direction: DIRECTION_OUTBOUND,
    fees: response.outbound || {},
  });
  searchActions.setFees(store, {
    direction: DIRECTION_RETURN,
    fees: response.return || {},
  });
};
