import alter from '~/utils/fp/alter';
import { getSessionItemOr } from '~/utils/storage';
import { getDefaultState } from './internal';
import mutations from './mutations';

export const moduleName = 'flightChange';

const state = () => alter(getSessionItemOr({}, moduleName), getDefaultState());

export default {
  state,
  mutations,
};
