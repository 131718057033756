import { createAction } from '~/utils/store';
import * as m from './mutation-types';

export const setGlobalUrls = createAction(m.SET_GLOBAL_URLS);
export const resetGlobalUrls = createAction(m.RESET_GLOBAL_URLS);

export const setGlobalFiles = createAction(m.SET_GLOBAL_FILES);
export const resetGlobalFiles = createAction(m.RESET_GLOBAL_FILES);

export const setGlobalWizz = createAction(m.SET_GLOBAL_WIZZ);

export const setAssetManifest = createAction(m.SET_ASSET_MANIFEST);

export const setIsRtl = createAction(m.SET_IS_RTL);

export const setLanguage = createAction(m.SET_LANGUAGE);

export const setPage = createAction(m.SET_PAGE);

export const setStoredResourceNames = createAction(m.SET_STORED_RESOURCE_NAMES);
export const resetStoredResourceNames = createAction(m.RESET_STORED_RESOURCE_NAMES);

export const setResourceScripts = createAction(m.SET_RESOURCE_SCRIPTS);
export const resetResourceScripts = createAction(m.RESET_RESOURCE_SCRIPTS);

export const setCultures = createAction(m.SET_CULTURES);

export const setStaticAssetUrl = createAction(m.SET_STATIC_ASSET_URL);

export const setBannerUrls = createAction(m.SET_BANNER_URLS);

export const setHomePageBannersData = createAction(m.SET_HOME_PAGE_BANNERS_DATA);
