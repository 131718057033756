import curry from '../curry';
import equals from '../equals';
import { _where } from '../where';
import { _map } from '../map';

/**
 * @typedef {(spec: Object<string, any>, testObj: Object<string, any>) => boolean} WhereEqFn
 */

/**
 * @type WhereEqFn
 */
export const _whereEq = (spec, testObj) => _where(_map(equals, spec), testObj);

/**
 * Takes a spec object and a test object; returns true if the test satisfies
 * the spec, false otherwise. An object satisfies the spec if, for each of the
 * spec's own properties, accessing that property of the object gives the same
 * value (in `equals` terms) as accessing that property of the spec.
 *
 * `whereEq` is a specialization of `where`.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/whereEq.js
 *
 * @type WhereEqFn
 * @example
 *
 *      // pred :: Object -> Boolean
 *      let pred = whereEq({a: 1, b: 2});
 *
 *      pred({a: 1});              //=> false
 *      pred({a: 1, b: 2});        //=> true
 *      pred({a: 1, b: 2, c: 3});  //=> true
 *      pred({a: 1, b: 1});        //=> false
 */
const whereEq = curry(_whereEq);

export default whereEq;
