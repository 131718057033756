import isEmpty from '~/utils/object/is-empty';
import curry from '../curry';
import { _has } from '../has';
import { _assoc } from '../assoc';

/**
 * @typedef {(path: string, value: any, obj: Object<string, any>) => Object<string, any>} AssocPathFn
 */

/**
 * @type AssocPathFn
 */
export const _assocPath = (path, value, obj) => {
  if (isEmpty(path)) return obj;
  const splitPath = path.split('.');
  return loop(splitPath, value, obj);
};

const loop = (splitPath, value, obj) => {
  const [prop, ...newSplitPath] = splitPath;
  if (!prop) return value;

  if (newSplitPath.length > 0) {
    const subObj = _has(prop, obj) ? obj[prop] : {};
    value = loop(newSplitPath, value, subObj);
  }

  return _assoc(prop, value, obj);
};

/**
 * Makes a shallow clone of an object, setting or overriding the nodes required
 * to create the given path, and placing the specific value at the tail end of
 * that path. Note that this copies and flattens prototype properties onto the
 * new object as well. All non-primitive properties are copied by reference.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.22.1/src/assocPath.js
 *
 * @type AssocPathFn
 * @example
 *
 *      assocPath('a.b.c', 42, {a: {b: {c: 0}}}); //=> {a: {b: {c: 42}}}
 *
 *      // Any missing or non-object keys in path will be overridden
 *      assocPath('a.b.c', 42, {a: 5}); //=> {a: {b: {c: 42}}}
 */
const assocPath = curry(_assocPath);

export default assocPath;
