import to from 'await-to-js';
import { createAction } from '~/utils/store';
import { captureAndLogException } from '~/utils/logging';
import * as UserService from '~/services/user';
import { mapNextFlightResponse } from './internal';
import * as m from './mutation-types';

export const reset = createAction(m.RESET);

export const updateNextFlight = async ({ commit }) => {
  const [error, response] = await to(UserService.getNextFlight());
  if (error) return captureAndLogException(error);
  const nextFlight = mapNextFlightResponse(response);
  commit(m.NEXT_FLIGHT_UPDATE, nextFlight);
};
