import isFunction from '~/utils/object/is-function';
import isObject from '~/utils/object/is-object';
import curry from '../curry';

/**
 * @typedef {<T, U>(fn: (value: T) => U, functor: T[] | Object<string, T>) => U[] | Object<string, U>} MapFn
 */

/**
 * @type MapFn
 */
export const _map = (fn, functor) => {
  let res;

  if (isFunction(functor.map)) {
    res = functor.map((el) => fn(el));
  } else if (isObject(functor)) {
    res = Object.keys(functor).reduce((acc, key) => {
      acc[key] = fn(functor[key]);
      return acc;
    }, {});
  }

  return res;
};

/**
 * Takes a function and
 * a [functor](https://github.com/fantasyland/fantasy-land#functor),
 * applies the function to each of the functor's values, and returns
 * a functor of the same shape.
 *
 * Dispatches to the `map` method of the second argument, if present.
 *
 * note: fn will be called as fn(element)
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/map.js
 *  without function support
 *
 * @type MapFn
 * @example
 *
 *      let double = x => x * 2;
 *      map(double, [1, 2, 3]); //=> [2, 4, 6]
 *      map(double, {x: 1, y: 2, z: 3}); //=> {x: 2, y: 4, z: 6}
 */
const map = curry(_map);

export default map;
